import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Actions from "../../../redux/actions";
import {
  getCustomerCouponListSelector, getCustomerCouponListLoading, getCustomerListSelector, getCustomerCouponListCountSelector
} from "../../../redux/reducers/CustomerCouponReducer";
import CustomerCouponModal from "./CustomerCouponModal";
import { ManageCustomerCouponURL } from "../../../utils/service/Endpoints";
import debounce from 'lodash.debounce';


import CustomerCouponDetailmodal from "./CustomerDetailModal";
import { isFieldEmpty } from "../../../utils/validation";
import moment from "moment";
import { UTCDateFormat, UTCEditFormat, UTCViewFormat } from "../../../utils/commonFunctions/dateFormat";
import { isCouponValue } from "../../../utils/commonFunctions/Validations";
import Pagination from "react-js-pagination";
import RoutePath from "../../../utils/routes/RoutePath";
import AppLoader from "../../../components/fields/AppLoader";
import { toast } from "react-toastify";


const CustomerCouponList = () => {
  const dispatch = useDispatch();
  const couponList = useSelector(getCustomerCouponListSelector);
  const totalCount = useSelector(getCustomerCouponListCountSelector)
  const isLoading = useSelector(getCustomerCouponListLoading);
  const customerList = useSelector(getCustomerListSelector);
  const [isOpenCouponModal, setOpenCouponModal] = useState(false);
  const [couponCode, setCouponCode] = useState('');
  const [description, setDescription] = useState('');
  const [couponValue, setCouponValue] = useState('');
  const [offerType, setOfferType] = useState('');
  const [selectedCustomers, setSelectedCustomer] = useState([])
  const [isEditCouponModal, setEditCouponModal] = useState(false);
  const [isSelectAllCustomer, setAllCustomers] = useState(true);
  const [id, setCouponId] = useState("")
  const [validationError, setValidationError] = useState(null)
  const [customerDetailModal, setCustomerDetailModal] = useState(false)
  const [customerDetail, setCustomerDetail] = useState("")
  const [validFrom, setValidFrom] = useState("");
  const [expiryOn, setExpiryOn] = useState("");
  const [activePage, setActivePage] = useState(1)
  const [loading, setLoader] = useState(false)
  const initialState = () => {
    setOpenCouponModal(false)
    setEditCouponModal(false)
    setCouponCode("")
    setDescription("")
    setCouponValue("")
    setOfferType("")
    setCouponId("")
    setSelectedCustomer([])
    setValidationError(null)
    setValidFrom('')
    setExpiryOn('')
  }
  const setInitialState = () => {
    setOpenCouponModal(false)
    setEditCouponModal(false)
    setCouponCode("")
    setDescription("")
    setCouponValue("")
    setOfferType("")
    setCouponId("")
    setSelectedCustomer([])
    setValidationError(null)
    setValidFrom('')
    setExpiryOn('')
    setActivePage(1)
  }
  const OpenCreateModal = (value) => {
    setOpenCouponModal(true)
  }

  const handleChangeCustomers = (obj) => {
    console.log(obj,'dhnbvcc')
    setSelectedCustomer(obj)
  }

  const handleChange = (pageNumber) => {
    setActivePage(pageNumber)
    CustomerCouponPageChange(pageNumber)
  }

  const CustomerCouponPageChange = (offsetValue) => {
    const defaultFilter = {
      page: offsetValue - 1,
      limit: 10
    }
    dispatch(Actions.getCustomerCoupon(defaultFilter));
  }

  const OpenEditModal = (value, obj) => {
    setEditCouponModal(value)
    let { couponCode, description, customers, offerType, value: offerValue,
      id, applicableForAllCustomers, startDateString, endDateString } = obj || {}
    let convertedArray = customers && customers.map(d => (
      { label: d?.customer?.firstName + ' ' + d?.customer?.mobileNumber, value: d?.customer?.id }
    ))
    setCouponCode(couponCode)
    setDescription(description)
    setOfferType(offerType)
    setCouponValue(offerValue)
    setCouponId(id)
    setSelectedCustomer(convertedArray)
    setAllCustomers(applicableForAllCustomers)
    setValidFrom(UTCEditFormat(startDateString))
    setExpiryOn(UTCEditFormat(endDateString))
  }

  const callback = (res) => {
    if (res?.response_code === 0) {
      const defaultFilter = {
        page: 0,
        limit: 10
      }
      setLoader(false)
      setActivePage(1)
      dispatch(Actions.getCustomerCoupon(defaultFilter));
      setInitialState()
    } else {
      setValidationError(res?.response_message)
      setLoader(false)
    }
  }

  const OpenCustomerDetailModal = (value) => {
    setCustomerDetailModal(value)
    setCustomerDetail(value)
  }

  const manageCoupon = (create) => {
    if (selectedCustomers?.length === 0 && !isSelectAllCustomer) {
      setValidationError('Please choose the customers.')
      return;
    } else if (!offerType) {
      setValidationError('Please choose offer type')
      return;
    } else if (!couponValue) {
      setValidationError('Please enter coupon value')
      return;
    } else if (couponValue > 100 && offerType === 'PERCENTAGE') {
      setValidationError('coupon value should be less than 100')
      return;
    } else if (couponValue <= 0) {
      setValidationError('coupon value should not be 0 and negative numbers')
      return;
    } else if (isFieldEmpty(couponCode)) {
      setValidationError('Please enter coupon code')
      return;
    } else if (isCouponValue(couponCode)) {
      setValidationError('Special characters are not allowed')
      return;
    } else if (isFieldEmpty(description)) {
      setValidationError('Please enter description')
      return;
    } else if (!validFrom) {
      setValidationError('Please choose start date.');
      return;
    } else if (!expiryOn) {
      setValidationError('Please choose end date.');
      return;
    } else if (moment(validFrom).isAfter(moment(expiryOn))) {
      setValidationError('Valid start date cannot be before end date.');
      return;
    }
    else if (moment(validFrom).isSameOrAfter(moment(expiryOn))) {
      setValidationError('Valid start date time cannot be same as end date time.');
      return;
    }
    setLoader(true)

    let startDate = UTCViewFormat(validFrom)
    let endDate = UTCViewFormat(expiryOn)

    let params = {
      active: true,
      applicableForAllCustomers: isSelectAllCustomer,
      customerIds: isSelectAllCustomer ? [] : selectedCustomers?.map((o) => o.value),
      couponCode,
      offerType,
      flag: "COUPON",
      description,
      value: couponValue,
      id,
      startDate: validFrom,
      endDate: expiryOn,
      startDateTimeString: startDate,
      endDateTimeString: endDate,
    }
    if (create) {
      dispatch(Actions.createCustomerCoupon(params, callback))
    } else {
      dispatch(Actions.updateCustomerCoupon(params, callback))
    }
  }
  //Delete
  const deleteCallback = (res) => {
    const params = {
      page: activePage - 1,
      limit: 10
    }
    if (res && res.response_code === 0) {
      dispatch(Actions.getCustomerCoupon(params));
      toast.success(res?.response_message, { position: toast.POSITION.TOP_RIGHT })
    } else {
      toast.error(res?.response_message, { position: toast.POSITION.TOP_RIGHT })
    }
  }

  const disableCustomerCouponList = (object, value) => {
    const URL = ManageCustomerCouponURL(object?.id, value)
    dispatch(Actions.deleteCustomersCouponAction(URL, deleteCallback));
  }
  let couponLists = []

  if (couponList && couponList.length > 0) {
    couponLists = couponList
  } else {
    couponLists = []
  }

  const data = React.useMemo(() => couponLists, [couponLists]);



  const searchCustomer = useRef(debounce((value) => {
    let params = {
      limit: 200,
      page: 0,
      name: value
    }
    dispatch(Actions.getAllCustomerListAction(params))
  }, 500));

  useEffect(() => {
    const defaultFilter = {
      page: 0,
      limit: 10
    }
    dispatch(Actions.getCustomerCoupon(defaultFilter));
  }, [dispatch]);
  return (
    <div className="col-lg-10 col-md-12 col-sm-12 ps-lg-4">
      {isLoading && <AppLoader />}
      <div className="row mb-1 align-items-center">
        <div className="col">
          <h1 className="font20 fw-bold mb-2">Customer Coupons
            <small className="fw-normal"></small></h1>
          <nav style={{ marginRight: "--bs-breadcrumb-divider: '/';" }}
            aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href={RoutePath.DASHBOARD_SCREEN_PATH}>Home</a></li>
              <li className="breadcrumb-item">
                <a href={RoutePath.PROMOTION_DASHBOARD}>Promotions</a></li>
              <li className="breadcrumb-item active"
                aria-current="page">Customer Coupons</li>
            </ol>
          </nav>
        </div>
        <div className="col text-end">
          <button
            name=""
            data-bs-toggle="modal"
            data-bs-target="#add-coupon-modal"
            className="btn btn-dark"
            id="OpenCreateModal"
            onClick={() => OpenCreateModal(true)}>Add New
          </button>
        </div>
      </div>


      <div className="row mb-4">
        <div className="col">
          <div id="all-offers"
            className="card card-body rounded-3 border-0 shadow p-4 mb-3 mb-xl-0">
            <div className="table-responsive mb-4">
              <table className="table table-hover table-striped table-borderless">
                <thead>
                  <tr>
                    <th>Offer Id</th>
                    <th>Coupon Code</th>
                    <th>Coupon Value</th>
                    <th>Valid From</th>
                    <th>Valid To</th>
                    <th>Description</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {!isLoading && data && data.map((o, i) => {
                    return <tr>
                      <td>{o?.id}</td>
                      <td className="cursor-pointer"> <p title={o?.couponCode} className="promotion-coupon-desc-wrap">{o?.couponCode}</p> </td>
                      <td>{o?.offerType === "PERCENTAGE" ? `${o?.value}%` : `$${parseFloat(o?.value).toFixed(2)}`}</td>
                      <td> {UTCDateFormat(o?.startDateString)} </td>
                      <td>{UTCDateFormat(o?.endDateString)} </td>
                      <td title={o?.description} className="promotion-coupon-desc-wrap">{o?.description} </td>
                      {!o.status ? <td>
                        <div className="form-check form-switch">
                          <input
                            id="disableCustomerCouponList"
                            onChange={(e) => {
                              e.stopPropagation();
                              disableCustomerCouponList(o, e.target.checked)
                            }}
                            className="form-check-input cursor-pointer"
                            type="checkbox"
                            role="switch"
                            checked={o?.active} />
                        </div></td> : <td className={o.status === 'Expired' ? "text-danger" : 'text-success'}>{o?.status}</td>
                      }
                      <td className="d-flex">
                        <div className="col-auto">
                          <button type="button"
                            data-bs-toggle="modal"
                            id="OpenCustomerDetailModal"
                            data-bs-target="#view-coupon-modal"
                            className="btn btn-sm btn-outline-dark mb-3 mb-md-0"
                            onClick={() => OpenCustomerDetailModal(o)}>View</button>
                        </ div>
                        <div className="col-auto">

                          <button type="button" data-bs-toggle="modal"
                            data-bs-target="#edit-coupon-modal"
                            id="OpenEditModal"
                            className="btn btn-sm btn-outline-dark"
                            onClick={(e) => { e.stopPropagation(); OpenEditModal(true, o) }}>Edit</button>
                        </div>

                      </td>
                    </tr>
                  })}

                </tbody>
              </table>
            </div>
            <div className="text-center">
              {
                (data && data.length > 0 && totalCount > 1) && <Pagination
                  id='Pagination'
                  activePage={activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={totalCount * 10}
                  pageRangeDisplayed={5}
                  onChange={(e) => handleChange(e)}
                />
              }
            </div>
          </div>
        </div>
      </div>
      {
        isOpenCouponModal && <CustomerCouponModal id='CustomerCouponModal'
          manageCoupon={(value) => manageCoupon(value)}
          isOpenCouponModal={isOpenCouponModal}
          setOpenCouponModal={() => initialState()}
          isCreate={true}
          setCouponCode={(e) => setCouponCode(e)}
          couponCode={couponCode}
          description={description}
          setDescription={(e) => setDescription(e)}
          couponValue={couponValue}
          setCouponValue={(e) => setCouponValue(e)}
          offerType={offerType}
          setOfferType={(e) => setOfferType(e)}
          customers={customerList}
          selectedCustomers={selectedCustomers}
          validationError={validationError}
          handleChangeCustomers={(e) => handleChangeCustomers(e)}
          setAllCustomers={(e) => setAllCustomers(e?.target?.checked)}
          isSelectAllCustomer={isSelectAllCustomer}
          setValidationError={setValidationError}
          validFrom={validFrom}
          expiryOn={expiryOn}
          setValidFrom={setValidFrom}
          setExpiryOn={setExpiryOn}
          searchCustomer={searchCustomer}
          isLoading={loading}
        />
      }
      {
        isEditCouponModal && <CustomerCouponModal id='CustomerCoupoEditModal'
          manageCoupon={(value) => manageCoupon(value)}
          isOpenCouponModal={isEditCouponModal}
          setOpenCouponModal={() => initialState()}
          setCouponCode={(e) => setCouponCode(e)}
          couponCode={couponCode}
          description={description}
          setDescription={(e) => setDescription(e)}
          couponValue={couponValue}
          setCouponValue={(e) => setCouponValue(e)}
          offerType={offerType}
          setOfferType={(e) => setOfferType(e)}
          customers={customerList}
          selectedCustomers={selectedCustomers}
          validationError={validationError}
          handleChangeCustomers={(e) => handleChangeCustomers(e)}
          setAllCustomers={(e) => setAllCustomers(e?.target?.checked)}
          isSelectAllCustomer={isSelectAllCustomer}
          setValidationError={setValidationError}
          validFrom={validFrom}
          expiryOn={expiryOn}
          setValidFrom={setValidFrom}
          setExpiryOn={setExpiryOn}
          searchCustomer={searchCustomer}
          isLoading={loading}
        />
      }
      {customerDetailModal &&
        <CustomerCouponDetailmodal
          id='CustomerDetailModal'
          isCustomerCouponDetailModal={customerDetailModal}
          isCloseModal={() => setCustomerDetailModal(false)}
          customerCouponDetails={customerDetail}
        />}

    </div>
  )
};

export default CustomerCouponList;