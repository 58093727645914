import { all, put, takeLatest, select } from "redux-saga/effects";
import { doAuthGet } from "../../utils/service";
import END_POINTS from "../../utils/service/Endpoints";
import Actions from "../actions";

export function* getHitachiActiveList({ params }) {
    let hitachiParams = {
        limit: params?.limit,
        offset: params?.offset
    }
    try {
        const response = yield doAuthGet(END_POINTS.HITACHI_MONITORING_ACTIVE_LIST, hitachiParams);
        if (response) {
            yield put(Actions.getHitachiActiveListSuccess(response));
        }
    }
    catch (error) {
        yield put(Actions.getHitachiActiveListFailure({ error }));
    }

    if (params?.isIntialLoading) {
        try {
            const response = yield doAuthGet(END_POINTS.HITACHI_MONITORING_LIST, hitachiParams);
            const currentData = yield select(Actions.getHitachiMonitoring);
            if (response) {
                if ((currentData?.hitachiMonitoringList?.length !== response?.count) && (params.offset !== 0)) {
                    const data = {
                        count: response?.count,
                        response: [
                            ...currentData.hitachiMonitoringList,
                            ...response?.response
                        ]
                    }
                    yield put(Actions.getHitachiMonitoringListSuccess(data))
                } else {
                    yield put(Actions.getHitachiMonitoringListSuccess(response))
                }
            }
        }
        catch (error) {
            yield put(Actions.getHitachiMonitoringListFailure({ error }));
        }
    }


}


export function* getHitachiMonitoringList({ params }) {
    try {
        const response = yield doAuthGet(END_POINTS.HITACHI_MONITORING_LIST, params);
        const currentData = yield select(Actions.getHitachiMonitoring);
        if (response?.response_code === 0) {
            if ((currentData?.hitachiMonitoringList?.length !== response?.count) && (params.offset !== 0)) {
                const data = {
                    count: response?.count,
                    response: [
                        ...currentData.hitachiMonitoringList,
                        ...response?.response
                    ]
                }
                yield put(Actions.getHitachiMonitoringListSuccess(data))
            } else {
                yield put(Actions.getHitachiMonitoringListSuccess(response))
            }
        } else {
            yield put(Actions.getHitachiMonitoringListFailure());
        }
    }
    catch (error) {
        yield put(Actions.getHitachiMonitoringListFailure({ error }));
    }

}

export default function* hitachiWatcher() {
    yield all([
        takeLatest(Actions.HITACHI_ACTIVE_COUNT_LIST, getHitachiActiveList),
        takeLatest(Actions.HITACHI_MONITORING_LIST, getHitachiMonitoringList),
    ]);
}
