import React from "react";
import Close from '../../../../asset/new/img/modal-close.svg'
import placeholderImage from '../../../../asset/images/placeholder.jpg';
import { UTCDateFormat } from "../../../utils/commonFunctions/dateFormat";


const ComboOfferDetailModal = ({ isCloseModal, modalData }) => {
    return <><div className="modal d-block"
        id="unblock-modal"
        tabIndex="-1"
        aria-labelledby="unblock"
        aria-hidden="true"><div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content rounded-0">
                <img id="close" src={Close} alt="X" data-bs-dismiss="modal" aria-label="Close" className="modal-close" onClick={
                    () => isCloseModal(false)
                } />
                <div className="modal-header rounded-0 align-items-center py-4">
                    <div className="col">
                        <h5 className="modal-title">Combo Offer - ID #{modalData?.id}</h5>
                    </div>
                </div >
                <div className="modal-body font14 p-40" >
                    <div className="row mb-3" >
                        <div className="col-4 fw-bold">Valid From</div >
                        <div className="col-8" >{UTCDateFormat(modalData?.validFrom)}</div >
                    </div >
                    <div className="row mb-3" >
                        <div className="col-4 fw-bold" >Valid To</div >
                        <div className="col-8" > {UTCDateFormat(modalData?.expiryOn)} </div >
                    </div >
                    <div className="row mb-3" >
                        <div className="col-4 fw-bold"> Combo Price </div >
                        <div className="col-8" > 
                        {modalData?.offerType === "PERCENT" ? `${modalData?.offerPrice}%` : `$` + parseFloat(modalData?.offerPrice)?.toFixed(2)}
                       </div >
                    </div >
                    {
                        modalData?.comboItems?.length > 0 && <>
                            <div className="fw-bold pt-4"> Products </div>
                            <hr className="thick" />
                            {
                                modalData?.comboItems?.map((o, index) => {
                                    return (
                                        <div className="row align-items-center mb-3">
                                            <div className="col-auto fw-bold">{index + 1}.</div>
                                            {/* <div className="col-auto">
                                                <img src={o?.imageUrl ? o?.imageUrl : placeholderImage} alt="products"
                                                 width="32" /> </div> */}
                                            <div className="col fw-bold title-case"> {o?.productName?.toLowerCase()} <small className="fw-normal">(SKU {o?.sku})</small> - {o?.quantity} </div>
                                        </div>
                                    )
                                })
                            }

                        </>
                    }
                    <div className="row my-4" >
                        <div className="col" > <a id="onClose" data-bs-dismiss="modal" aria-label="Close" className="btn btn-dark font14 w-100 p-2" onClick={
                            () => isCloseModal(false)
                        } > Close</a > </div >
                    </div >

                </div >

            </div>
        </div>
    </div>
        <div className="modal-backdrop show"></div>
    </>

};

export default ComboOfferDetailModal;