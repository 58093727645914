import React from 'react';
import Close from '../../../asset/new/img/modal-close.svg'
import InputField from '../../components/fields/InputField';
import BoldError from '../../components/fields/BoldError';
import AppLoader from '../../components/fields/AppLoader';

const ManualRefundModal = ({ onClose = () => null,
    title,
    onClick = () => null,
    onChange = () => null,
    show,
    refundableAmount,
    isLoading,
    onCommentChange = () => null,
    subTitle = `Eligible Refund Amount is $`,
    errorMsg
}) => {
    return <>
        <div className="modal d-block" id="add-combo-modal" tabindex="-1" aria-labelledby="add-combo-modal" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content rounded-0">

                    <img src={Close} onClick={() => onClose()} id='close' alt="X" data-bs-dismiss="modal" aria-label="Close" className="modal-close" />

                    <div className="modal-header rounded-0 align-items-center py-4">
                        <div className="col"> <h5 className="modal-title">Initiate Refund</h5> </div>
                    </div>

                    <div className="modal-body font13 p-40">
                        {errorMsg && <BoldError message={errorMsg} />}
                        <div className="row mb-4">
                            {
                                refundableAmount && <p className='mb-1' style={{ textAlign: 'start' }}>{subTitle}{refundableAmount && refundableAmount.toFixed(2)}</p>
                            }
                            <div className="col-6">
                                <label for="from-date" className="form-label fw-bold">Amount</label>
                                <InputField
                                    autoFocus
                                    type={'number'}
                                    placeholder={'Enter the amount'}
                                    onChange={(value) => {
                                        onChange(value)
                                    }} />
                            </div>
                            <p>Note : Don't enter more than two decimal number EX:(5.45)</p>
                        </div>
                        <p className=" mb-2 fw-bold">Comments</p>
                        <div className="col-12 col-lg pe-lg-3">
                            <div className="input-group mb-3 mb-lg-0">
                                <InputField
                                    multiline
                                    style={{ width: '350px' }}
                                    placeholder={'comments'}
                                    onChange={(value) => {
                                        onCommentChange(value)
                                    }} />
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div
                                className="col pe-0" >
                                <span className="btn btn-dark font14 w-100 p-3" id='Refund' onClick={() => onClick(2)}>Refund</span>
                            </div>
                            <div className="col text-end" >
                                <span className="btn btn-outline-dark font14 w-100 p-3" id='Cancel' onClick={() => onClose()}>Cancel</span>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
        {isLoading && <AppLoader />}
        <div className="modal-backdrop show"></div>
    </>
}

export default ManualRefundModal;