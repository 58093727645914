import { cloneDeep } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Actions from '../../../redux/actions';
import { getProductListLoadingSelector, getProductListSelector } from '../../../redux/reducers/ProductsReducer';

import ProductDetails from './ProductDetails';
import search from '../../../../asset/new/img/search.svg';
import placeholderImage from '../../../../asset/images/placeholder.jpg';
import AppLoader from '../../../components/fields/AppLoader';
import EmptyPage from '../../../components/emptyPage';
import { bistroProductListLoadingSelector } from '../../../redux/reducers/BistroProductListReducers';

const defaultFilter = {
    storeId: 1,
    searchKey: '',
    size: 100,
    type: 2,
}

const Products = () => {

    const dispatch = useDispatch();
    const productList = useSelector(getProductListSelector)
    const isLoading = useSelector(getProductListLoadingSelector);
    const isLoadingBistro = useSelector(bistroProductListLoadingSelector)
    const [productDetailModal, setProductDetailModalModal] = useState(false)
    const [productDetails, setProductDetails] = useState("")
    const [apiFilters, setApiFilters] = useState(cloneDeep({
        ...defaultFilter,

    }));
    const manageProductList = useCallback(() => {
        const filterParams = { ...apiFilters };
        if (parseInt(filterParams.type) === 2) {
            dispatch(Actions.getAllProductsListAction(filterParams))
        } else {
            dispatch(Actions.bistroProductListAction(filterParams))
        }
    }, [dispatch, apiFilters]);

    useEffect(() => {
        manageProductList();
    }, [manageProductList])

    const data = React.useMemo(
        () => productList,
        [productList]
    );
    const openProductDetailModal = (value) => {
        setProductDetails(value)
        setProductDetailModalModal(true)

    }

    const handleTypeChange = (value) => {
        const filterType = { ...apiFilters }
        filterType.type = value
        setApiFilters(filterType);
    }

    return <>
        <div className="col ps-lg-4">

            <div className="row mb-3">
                <div className="col">
                    <h1 className="font20 fw-bold">Find product</h1>
                </div>
            </div>

            <form name="">
                <div id="order-options-bar" className="row mb-3">
                    <div className="col-12 col-lg-4 pe-lg-3">
                        <div className="input-group mb-3 mb-lg-0">
                            <input
                                type="text"
                                value={apiFilters.searchKey}
                                onChange={(e) => {
                                    const params = { ...apiFilters }
                                    params.searchKey = e.target.value
                                    setApiFilters(params)
                                }}
                                className="form-control border-end-0"
                                placeholder="Search by Product Name" />
                            <button className="btn btn-outline-dark border-start-0"
                                type="button" id="search-btn"><img src={search} alt="Go" /></button>

                        </div>
                    </div>
                    <div className="col-6 col-sm-3 col-lg-auto pe-0 pe-lg-1">
                        <select id="selectProduct" className="form-select ddownfield mb-3 mb-lg-0 cursor-pointer" value={apiFilters.type}
                            onChange={(e) => handleTypeChange(e?.target?.value)}>
                            <option value={2}>Grocery</option>
                            <option value={1}>Bistro</option>
                        </select>
                    </div>
                </div>
            </form>

            <div className="my-cart-list card scroll card-body rounded-3 border-0 shadow p-4 mb-3 mb-xl-0">

                {data && data.map((o) => {
                    return (

                        <span id='openProduct' data-bs-toggle="modal" data-bs-target="#product-details-modal"
                            onClick={() => openProductDetailModal(o)}>
                            <div className="single-product cursor-pointer">
                                <div className="row align-items-center">
                                    <div className="col-4 col-sm-2 pe-0 pe-lg-4"> <div className="product-thumb">
                                        <img src={o?.imageUrl ? o?.imageUrl + '?$' + Math.random() : placeholderImage} alt="Loading" /> </div>
                                    </div>
                                    <div className="col-8 col-sm-10">
                                        <h3 className="product-name title-case">{o?.productName?.toLowerCase()}</h3>
                                        <span className="font12 fw-bold text-dark">SKU - {o?.sku}</span> </div>
                                    {o?.addOnList?.length > 0 &&
                                        <div className='col-auto customisable'>Customizable
                                        </div>}
                                </div>
                            </div></span>)
                })}
                {
                    (data && data.length === 0 && !isLoading && !isLoadingBistro) && (
                        <EmptyPage
                            content="Product Not Found"
                        />
                    )
                }


                {isLoading && <AppLoader />}
                {isLoadingBistro && <AppLoader />}
            </div>
            {productDetailModal && <ProductDetails id="ProductDetail" title="Product Details" isProductDetailModalModal={productDetailModal} isCloseModal={() => setProductDetailModalModal(false)} productDetails={productDetails} />}
        </div>
    </>
}

export default Products;
