import { doAction, doResponseAction } from ".";

const COMBO_OFFER = 'COMBO_OFFER';
const COMBO_OFFER_SUCCESS = 'COMBO_OFFER_SUCCESS';
const COMBO_OFFER_FAILURE = 'COMBO_OFFER_FAILURE'

const COMBO_OFFER_CREATE = 'COMBO_OFFER_CREATE';
const COMBO_OFFER_CREATE_SUCCESS = 'COMBO_OFFER_CREATE_SUCCESS';
const COMBO_OFFER_CREATE_FAILURE = 'COMBO_OFFER_CREATE_FAILURE';

const COMBO_OFFER_UPDATE = 'COMBO_OFFER_UPDATE';
const COMBO_OFFER_UPDATE_SUCCESS = 'COMBO_OFFER_UPDATE_SUCCESS';
const COMBO_OFFER_UPDATE_FAILURE = 'COMBO_OFFER_UPDATE_FAILURE';

const DELETE_COMBO_OFFER = 'DELETE_COMBO_OFFER';
const DELETE_COMBO_OFFER_SUCCESS = 'DELETE_COMBO_OFFER_SUCCESS';
const DELETE_COMBO_OFFER_FAILURE = 'DELETE_COMBO_OFFER_FAILURE';


const GET_COMBO_BY_ID = 'GET_COMBO_BY_ID';

export {
    COMBO_OFFER,
    COMBO_OFFER_SUCCESS,
    COMBO_OFFER_FAILURE,
    COMBO_OFFER_CREATE,
    COMBO_OFFER_CREATE_SUCCESS,
    COMBO_OFFER_CREATE_FAILURE,
    COMBO_OFFER_UPDATE,
    COMBO_OFFER_UPDATE_SUCCESS,
    COMBO_OFFER_UPDATE_FAILURE,
    DELETE_COMBO_OFFER,
    DELETE_COMBO_OFFER_SUCCESS,
    DELETE_COMBO_OFFER_FAILURE,
    GET_COMBO_BY_ID
}

export const getComboOfferList = (params, callback) => doAction(COMBO_OFFER, params, callback);
export const getComboOfferListSuccess = (data) => doResponseAction(COMBO_OFFER_SUCCESS, data);
export const getComboOfferListFailure = (data) => doResponseAction(COMBO_OFFER_FAILURE, data);

export const createComboOfferList = (params, callback) => doAction(COMBO_OFFER_CREATE, params, callback);
export const createComboOfferListSuccess = (data) => doResponseAction(COMBO_OFFER_CREATE_SUCCESS, data);
export const createComboOfferListFailure = (data) => doResponseAction(COMBO_OFFER_CREATE_FAILURE, data);

export const updateComboOfferList = (params, callback) => doAction(COMBO_OFFER_UPDATE, params, callback);
export const updateComboOfferListSuccess = data => doResponseAction(COMBO_OFFER_UPDATE_SUCCESS, data);
export const updateComboOfferListFailure = data => doResponseAction(COMBO_OFFER_UPDATE_FAILURE, data);

export const deleteComboOfferAction = (params, callback) => doAction(DELETE_COMBO_OFFER, params, callback);
export const deleteComboOfferListSuccess = data => doResponseAction(DELETE_COMBO_OFFER_SUCCESS, data);
export const deleteComboOfferListFailure = data => doResponseAction(DELETE_COMBO_OFFER_FAILURE, data);
export const getComboOfferByIdAction = (params, callback) => doAction(GET_COMBO_BY_ID, params, callback);
