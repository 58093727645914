import Constants from "../Constants";

export const getCookie = (name) => {
    let cookie = {};
    document.cookie.split(';').forEach(function (el) {
        let [k, v] = el.split('=');
        cookie[k.trim()] = v;
    })
    return cookie[name];
}

export const isJSON = (str) => {
    try {
        return (JSON.parse(str) && !!str);
    } catch (e) {
        return false;
    }
}

export const getRoleIdFromCookie = () => {
    const token = isJSON(getCookie('token')) ? JSON.parse(getCookie('token')) : undefined
    if (token) {
        const { roleId } = token?.customer || {}
        return roleId
    }
}

export const ClearCookie = () => {
    document.cookie = `token=; domain=${Constants.COMMON_APP_DOMAINS.cookieDomain}; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    document.cookie = `access_token=; domain=${Constants.COMMON_APP_DOMAINS.cookieDomain}; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    document.cookie = `refresh_token=; domain=${Constants.COMMON_APP_DOMAINS.cookieDomain}; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;

    const uri = new URL(Constants.COMMON_APP_DOMAINS.commonLogin)                         //To redirect common app login screen for SSO integration
    uri.searchParams.set('path', window.location.pathname)
    uri.searchParams.set('origin', Constants.COMMON_APP_DOMAINS.storeManager)
    window.location.assign(uri)
}