import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Actions from '../../redux/actions'
import Constants from '../../utils/Constants'
import {
    getComboOfferArraySelector, getCurrentOrderObjectSelector,
    getFreeGiveArraySelector, getPromotionCombineArraySelector,
    getMultibuyArraySelector
} from '../../redux/reducers/OrderReducer'
import Close from '../../../asset/new/img/modal-close.svg'
import Pending from '../../../asset/new/img/pending.svg'
import Delivered from '../../../asset/new/img/green-tick.svg'
import Declined from '../../../asset/new/img/red-cross.svg'
import AlertNotify from '../../components/fields/NotificationBar';
import moment from 'moment'
import { toast } from 'react-toastify'
import QrCodeScanner from './qrCodeScanner'
import DeclineModal from '../../components/DeclineModal'
import AppLoader from '../../components/fields/AppLoader'
import Alert from '../../components/alert/alert'
import ViewInvoiceModal from '../../components/ViewInvoiceModal'
import MapViewModal from '../daxbotStaus/mapViewModal'
import PartialOrderPopUp from './PartialOrderPopUp'
import { getRoleIdFromCookie } from '../../utils/commonFunctions/cookie'
class OrderDetails extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showOrders: false,
            openQRmodal: false,
            isOpenDeclineModal: false,
            declineTextState: null,
            isOpenAgeResModal: false,
            isOpenTrackModal: false,
            isInvoiceModal: false,
            isSelectedAll: false,
            disableButton: [],
            finalProductArray: [],
            isOpenPartialOrder: false,
            partialReason: null,
            orderID: '',
            errorMessage: null,
            isPartialCheck: false
        }
    }
    componentDidMount() {
        const { ordersData } = this.props
        const callback = (res) => {
            if (res?.response_code === 0) {
                this.setState({ showOrders: true })
            }
        }
        let params = {
            id: ordersData?.orderId
        }
        this.props.getOrderDetails(params, callback)
    }

    renderOrderType(actions, type) {
        switch (actions) {
            case 1:
                return 'WIWO';
            case 2:
                return type === 1 ? "Door Delivery" : "Store Pickup";
            case 3:
                return 'Hybrid';
            case 4:
                return 'Scan & Go';

            default:
                return '';
        }
    }
    openViewInvoice() {
        this.setState({ isInvoiceModal: true })
    }

    _renderOrderDetails() {
        const { invoiceDetails, customerFirstName, customerLastName,
            botOrderId, botStatus, mobileNumber, orderStatus, storeAddress } = this.props.getOrders || {}
        const { deliveryAddress, natureOfOrder } = invoiceDetails || {}
        let address = {};
        if (deliveryAddress) {
            try {
                address = JSON.parse(deliveryAddress)
            } catch (e) {
                console.log(e)

            }
        }
        let { formattedAddress, landmark, street, addresstype } = address || {}
        let storeAddressData = {};
        try {
            storeAddressData = JSON.parse(storeAddress)
        } catch (e) {
            console.log(e)
        }
        return (
            <div>
                <div className="row align-items-center mb-4">
                    <div className="col-auto">
                        <div className="p-3 font16 bg-lgrey text-center border border-dark rounded-circle">
                            {customerFirstName ? `${customerFirstName?.charAt(0)?.toUpperCase()} ${customerLastName?.charAt(0)?.toUpperCase()}`
                                : `${Constants.GUEST_USER.guest?.charAt(0)?.toUpperCase()}  ${Constants.GUEST_USER.user?.charAt(0)?.toUpperCase()}`}
                        </div>
                    </div>
                    <div className="col text-uppercase">
                        {customerFirstName ? `${customerFirstName} ${customerLastName}` : "Guest User"} <br />
                        <strong>{mobileNumber}</strong>
                    </div>
                    {((invoiceDetails?.natureOfOrder === 1) && (orderStatus === 4 || orderStatus === 5) &&
                        botOrderId &&
                        (botStatus !== 'completed')) &&
                        <div className="col-auto text-end">
                            <span className="btn btn-dark text-uppercase"
                                data-bs-toggle="modal"
                                onClick={() => this.trackDaxbot()} >Track</span>
                        </div>}

                </div>

                {natureOfOrder === 1 ? <div>
                    <h3 className="font16 fw-bold mb-2">Delivery Address</h3>
                    <p className='font14 mb-2'>Residence type: {addresstype ? `${addresstype?.type}` : ''}</p>
                    <p className="font14 mb-2"> {street ? `${street},` : ''} {landmark ? `${landmark},` : ''} {formattedAddress}</p></div> : <div>
                    <h3 className="font16 fw-bold mb-2">Pickup Address</h3>
                    <p className="font14 mb-2">  {storeAddressData?.branch_name ? `${storeAddressData?.branch_name},` : ''}
                        {storeAddressData?.address ? `${storeAddressData?.address} ` : ''}
                        {storeAddressData?.zip_code ? `${storeAddressData?.zip_code}.` : ''}</p></div>}
                <div className='col'>
                    <span><b>Order Type: </b>{this.renderOrderType(invoiceDetails?.createdBy, invoiceDetails?.natureOfOrder)}</span>
                </div>
                <hr className="thick" />
            </div>
        )
    }
    trackDaxbot() {
        this.setState({ isOpenTrackModal: true })
    }
    closeQRModal() {
        this.setState({ openQRmodal: false })
    }
    _renderOrderButton() {
        const { orderStatus, invoiceDetails, botOrderId } = this.props.getOrders || {}
        const { natureOfOrder } = invoiceDetails || {}
        return (
            <React.Fragment>
                {orderStatus === 1 && this._renderAcceptDeclineButton()}
                {orderStatus === 2 && this._renderPickupAndScanButton(natureOfOrder)}
                {(orderStatus === 4 || orderStatus === 5) &&
                    this._renderDeliveryButton(orderStatus, botOrderId, natureOfOrder)}
            </React.Fragment >
        )
    }
    _renderAcceptDeclineButton() {
        const { disableButton } = this.state
        return (
            <div className="row mt-5">
                <div className="col-7 pe-0">
                    <button id="Accept"
                        disabled={disableButton?.length === 0 ? true : false}
                        className="btn btn-success font14 w-100 p-3"
                        onClick={() => this.acceptOrders()}>
                        Accept
                    </button>
                </div>
                <div
                    className="col-5 text-end">
                    <button disabled={disableButton?.length !== 0 ? true : false} className="btn btn-outline-danger font14 w-100 p-3"
                        id="Decline"
                        onClick={() => this.declineOrder()}>
                        Decline
                    </button>
                </div>
            </div>
        )
    }

    acceptOrders() {
        this.setState({ isLoading: true, isPartialCheck: false })
        const { getCombinedArray, getFreeGiveOfferArray, getMultibuyOfferArray } = this.props
        getCombinedArray?.forEach((item) => {
            if (item.isSelectedCheck) {
                let quantity = item.newQuantity;
                this.state.finalProductArray.push({
                    invoiceOrderProductDetailsId: item?.productDetails?.invoiceProductDetailsId,
                    quantity: quantity,
                    name: item?.productDetails?.name,
                });
            } else {
                this.setState({ isPartialCheck: true })
            }
        });


        this.props.PromotioncombineArr?.forEach((item) => {
            if (item.isSelectedCheck) {
                let quantity;
                if (item.appliedTimes) {
                    quantity = item.quantity / item.appliedTimes;
                    quantity = quantity * item.newQuantity;
                } else {
                    quantity = item.newQuantity;
                }
                this.state.finalProductArray.push({
                    invoiceOrderProductDetailsId:
                        item.productDetails.invoiceProductDetailsId,
                    quantity: quantity,
                    name: item.productDetails.name,
                });
            } else {
                this.setState({ isPartialCheck: true })
            }
        });

        this.props.getComboOfferArray?.forEach((item) => {
            item?.comboProductGroup.forEach((i) => {
                if (i.isSelectedCheck) {
                    let productid;
                    let productName;
                    let qty;
                    i?.ids.forEach((ids) => {
                        this.props.getOrders?.invoiceOrderProductDetailsList.forEach(
                            (items) => {
                                if (items.invoiceProductDetailsId === ids) {
                                    productid = ids;
                                    productName = items.name;
                                    qty = 1;
                                }
                            },
                        );
                        if (productName) {
                            this.state.finalProductArray.push({
                                invoiceOrderProductDetailsId: productid,
                                quantity: qty,
                                name: productName,
                            });
                        }
                    });
                } else {
                    this.setState({ isPartialCheck: true })
                }
            });
        });

        getMultibuyOfferArray?.forEach((item) => {
            if (item.isSelectedCheck) {
                let productid;
                let productName;
                let qty;
                item?.idWithQuantity.forEach((i) => {
                    this.props.getOrders?.invoiceOrderProductDetailsList?.map(
                        (items) => {
                            let quantity;
                            quantity = i.qty / item?.appliedTimes;
                            quantity = quantity * item?.newQuantity;
                            if (items.invoiceProductDetailsId === i.id) {
                                productid = items.invoiceProductDetailsId;
                                productName = items?.name;
                                qty = quantity;

                                if (productName) {
                                    this.state.finalProductArray.push({
                                        invoiceOrderProductDetailsId: productid,
                                        quantity: qty,
                                        name: productName,
                                    });
                                }
                            }
                        },
                    );
                });
            } else {
                this.setState({ isPartialCheck: true })
            }
        });


        getFreeGiveOfferArray?.forEach((item) => {
            if (item.isSelectedCheck) {
                let productid;
                let productName;
                let qty = item.newQuantity;
                item.idWithQuantity.forEach((ids) => {
                    this.props.getOrders?.invoiceOrderProductDetailsList.forEach(
                        (items) => {
                            if (items?.invoiceProductDetailsId === ids?.id) {
                                productid = items?.invoiceProductDetailsId;
                                productName = items?.name;

                                if (productName) {
                                    this.state.finalProductArray?.push({
                                        invoiceOrderProductDetailsId: productid,
                                        quantity: qty,
                                        name: productName,
                                    });
                                }
                            }
                        },
                    );
                });

                item.freeItemIdsWithQuantities.forEach((i) => {
                    this.props.getOrders?.invoiceOrderProductDetailsList.forEach(
                        (items) => {
                            i?.idWithQuantity?.forEach((freeIds) => {
                                if (freeIds?.id === items?.invoiceProductDetailsId) {
                                    productid = items?.invoiceProductDetailsId;
                                    productName = items?.name;
                                    qty = i?.newQuantity;
                                    if (productName) {
                                        this.state.finalProductArray.push({
                                            invoiceOrderProductDetailsId: productid,
                                            quantity: freeIds.newQuantity,
                                            name: productName,
                                        });
                                    }
                                }
                            });

                        },
                    );
                });
            } else {
                this.setState({ isPartialCheck: true })
            }
        });

        let finalArray = [];
        for (let i = 0; i < this.state.finalProductArray?.length; i++) {
            let index;
            index = finalArray?.findIndex((item) => item?.invoiceOrderProductDetailsId === this.state.finalProductArray[i]?.invoiceOrderProductDetailsId);
            if (index === -1) {
                finalArray.push({ ...this.state.finalProductArray[i] });
            }
            else {
                let updateQty = finalArray[index].quantity + this.state.finalProductArray[i]?.quantity;
                finalArray[index].quantity = updateQty;
            }
        }

        let orderArray = [
            ...this.props.getOrders?.invoiceOrderProductDetailsList,
        ];

        finalArray.forEach((i) => {
            let index;
            index = orderArray.findIndex(
                (item) =>
                    item.invoiceProductDetailsId ===
                    i.invoiceOrderProductDetailsId,
            );
            if (index !== -1) {
                if (orderArray[index].quantity !== i.quantity) {
                    this.setState({ isPartialCheck: true })
                }
            }
        });

        this.setState({
            finalProductArray: finalArray,
        });

        this.setState({ isLoading: false, finalProductArray: finalArray }, () => {
            if (this.state.isPartialCheck) {
                this.setState({ isOpenPartialOrder: true })
            } else {
                this.handelButtonClick(1)
            }
        })
    }

    _renderPickupAndScanButton(natureOfOrder) {
        return (
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <div className="col-11 pe-0">
                    <button className="btn btn-success font14 w-100 p-3" id="PickupAndScanButton"
                        onClick={() => this.handelButtonClick(3)}>
                        {natureOfOrder === 1 ? 'Out for delivery' : 'Ready to pickup'}
                    </button>
                </div>
                <div style={{ marginLeft: '20px' }} />
            </div>
        )
    }
    _renderDeliveryButton(orderStatus, botOrderId) {
        return (
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>

                {(orderStatus !== 5 && !botOrderId) &&
                    <div className="col-11 pe-0">
                        <button className="btn btn-success font14 w-100 p-3"
                            id="openQrScannerModal"
                            onClick={() => this.handelButtonClick(4)}>
                            Confirm delivery
                        </button>
                    </div>}
                <div style={{ marginLeft: '20px' }} />
            </div>
        )
    }
    closeModal() {
        this.setState({ isOpenPartialOrder: false, finalProductArray: [], errorMessage: null })
    }
    handlePartialReason(e) {
        this.setState({ partialReason: e.target.value })
    }
    handelButtonClick(type) {
        const { orderStatus, invoiceOrderDetailsId, invoiceOrderProductDetailsList } = this.props.getOrders || {}
        const { declineTextState, partialReason, isSelectedAll, isPartialCheck } = this.state
        const params = {
            status: orderStatus,
        };
        if (type === 1) {
            params.status = 2;
            params.items = this.state.finalProductArray
            params.orderId = invoiceOrderDetailsId
            params.partialAcceptComment = partialReason ? partialReason : ''
        } else if (type === 2) {
            params.status = 3;
            params.actionComment = declineTextState;
            params.orderId = invoiceOrderDetailsId
        } else if (type === 3) {
            params.status = 4;
            params.id = invoiceOrderDetailsId
        } else if (type === 4) {
            const ageRestricted = invoiceOrderProductDetailsList?.filter((o) => o?.ageRestricted === true)
            if (ageRestricted?.length > 0) {
                this.setState({ isOpenAgeResModal: true })
                return;
            } else {
                params.status = 5
                params.id = invoiceOrderDetailsId
            }

        }
        const callback = (resp) => {
            const { setActivePage } = this.props
            if (resp?.response_code === 0) {
                this.setState({ isOpenDeclineModal: false }, () => {
                    const { apiFilters, roleId } = this.props
                    const filterParams = { ...apiFilters };
                    if (roleId === Constants.ROLES.BISTRO_ASSOCIATE) {
                        filterParams.type = 1
                    } else {
                        filterParams.type = 2
                    }
                    this.props.getOrderList(filterParams)
                    setActivePage(1)
                });
                this.closeModal()
            } else {
                let errorMessage = ''
                if (resp?.error) {
                    errorMessage = resp?.error
                } else {
                    errorMessage = resp?.errorMessage
                }
                toast.error(`${errorMessage}`, { position: toast.POSITION.TOP_RIGHT })
            }
            this.setState({ isLoading: false })
        }
        if (!isPartialCheck) {
            this.props.getOrderDetailsUpdate(params, callback)
            this.setState({ isLoading: true })
        } else if (!isSelectedAll && type === 2) {
            if (!declineTextState) {
                this.setState({ errorMessage: "Please enter the comments." })
            } else {
                this.props.getOrderDetailsUpdate(params, callback)
                this.setState({ isLoading: true })
            }
        } else if (isPartialCheck && type === 1) {
            if (!partialReason) {
                this.setState({ errorMessage: "Please enter the comments." })
            } else {
                this.props.getOrderDetailsUpdate(params, callback)
                this.setState({ isLoading: true })
            }
        } else {
            this.props.getOrderDetailsUpdate(params, callback)
            this.setState({ isLoading: true })
        }
    }
    declineOrder() {
        this.setState({ isOpenDeclineModal: true })
    }

    setDeclineText(value) {
        this.setState({ declineTextState: value })
    }


    confirmAgerestricted() {
        this.setState({ isOpenAgeResModal: false })
        const { invoiceOrderDetailsId } = this.props.getOrders || {}
        const params = {
            status: 5,
            id: invoiceOrderDetailsId
        }

        const callback = (resp) => {
            if (resp?.response_code === 0) {
                this.setState({ isOpenDeclineModal: false }, () => {
                    const { apiFilters, roleId } = this.props
                    const filterParams = { ...apiFilters };
                    if (roleId === Constants.ROLES.BISTRO_ASSOCIATE) {
                        filterParams.type = 1
                    } else {
                        filterParams.type = 2
                    }
                    this.props.getOrderList(filterParams)
                });
                this.closeModal()
                // this.props.onClose()
            } else {
                let errorMessage = ''
                if (resp?.error) {
                    errorMessage = resp?.error
                } else {
                    errorMessage = resp?.errorMessage
                }
                toast.error(`${errorMessage}`, { position: toast.POSITION.TOP_RIGHT })
            }
            this.setState({ isLoading: false })
        }
        this.props.getOrderDetailsUpdate(params, callback)
        this.setState({ isLoading: true })
    }

    isSelectAll(index, quantity, e) {
        const { getCombinedArray } = this.props
        if (getCombinedArray && getCombinedArray.length > 0) {
            getCombinedArray[index].isSelectedCheck = e.target.checked;
            getCombinedArray[index].newQuantity = quantity;
        }
        this.setState({ isSelectedAll: false, isPartialCheck: false })
        this.props.updateFinalArray(getCombinedArray)
        this.isVerified()
    }

    isSelectAllCombo(index, quantity, e) {
        const { getComboOfferArray } = this.props
        if (getComboOfferArray && getComboOfferArray.length > 0) {
            getComboOfferArray[index].isSelectedCheck = e.target.checked;
            getComboOfferArray[index].newComboAppliedTimes = quantity;
            getComboOfferArray[index]?.comboProductGroup.forEach((i) => {
                i.isSelectedCheck = e.target.checked;
            });
        }
        this.setState({ isSelectedAll: false, isPartialCheck: false })
        this.props.updateComboJSON(getComboOfferArray)
        this.isVerified()
    }
    isSelectAllfree(index, quantity, e) {
        const { getFreeGiveOfferArray } = this.props
        if (getFreeGiveOfferArray && getFreeGiveOfferArray.length > 0) {
            getFreeGiveOfferArray[index].isSelectedCheck = e.target.checked;
            getFreeGiveOfferArray[index].newQuantity = quantity;
            getFreeGiveOfferArray[index].freeItemIdsWithQuantities?.forEach((obj, i) => {
                obj?.idWithQuantity.forEach((free, subIndex) => {
                    free.newQuantity = free.qty;
                });
            })
        }
        this.setState({ isSelectedAll: false, isPartialCheck: false })
        this.props.updateFreeItemJSON(getFreeGiveOfferArray)
        this.isVerified()
    }
    isSelectAllMultibuy(index, quantity, e) {
        const { getMultibuyOfferArray } = this.props
        if (getMultibuyOfferArray && getMultibuyOfferArray.length > 0) {
            getMultibuyOfferArray[index].isSelectedCheck = e.target.checked;
            getMultibuyOfferArray[index].newQuantity = quantity;
        }
        this.setState({ isSelectedAll: false, isPartialCheck: false })
        this.props.updateMultibuyArray(getMultibuyOfferArray)
        this.isVerified()
    }
    _renderProductDetails() {
        const { invoiceOrderProductDetailsList, orderStatus } = this.props.getOrders || {}
        return (
            invoiceOrderProductDetailsList?.map((o) => {
                const unfilledQty = o?.quantity - o?.acceptedQuantity;
                return <div className={`card card-body p-0 border-0 pb-2 mb-2`}>
                    <div className="row">
                        <div className="col-sm-10">
                            <h3 className={`font13 mb-2  title-case
                                 ${(orderStatus !== 1 && o?.acceptedQuantity === 0) ? 'strike' : ''}`}
                                title={o?.name?.toLowerCase()}>
                                {o?.acceptedQuantity === 0 ? o?.quantity : o?.acceptedQuantity} x {o?.name?.toLowerCase()}
                                <p>(SKU - {o?.upc}) <span className='text-danger'>{o?.ageRestricted ? `18+` : ""}</span></p>
                                {o?.invoiceProductAddOnMappingList?.length > 0 ? (
                                    <p className="mb-1 font-14" style={{ paddingLeft: '10px' }}>
                                        <p className='font-13 ml-2'><b></b>YOUR CUSTOMIZATION:</p>
                                        {o?.invoiceProductAddOnMappingList?.map((o) => o?.name).join(',')}
                                    </p>) : ''
                                }

                            </h3>

                            {
                                (unfilledQty !== 0 && orderStatus !== 1 && o?.acceptedQuantity !== 0) &&
                                <p style={{ color: 'red', paddingLeft: "10px" }}>Unfulfilled Quantity: {unfilledQty}</p>
                            }

                        </div>

                        <div className="col-sm-2 text-end">
                            ${this._getSumAmount(o, orderStatus)}
                        </div>
                    </div>
                </div>
            })
        )
    }
    _renderCombinedArrayProducts() {
        const { getCombinedArray, getOrders, roleId } = this.props
        const { orderStatus, orderType } = getOrders || {}
        return getCombinedArray && getCombinedArray?.map((params, index) => {
            const { quantity, isSelectedCheck, productDetails, newQuantity } = params || {}
            const { name,
                // amountExcludingTax,
                // totalAmountExcludingTax,
                upc: sku,
                invoiceProductAddOnMappingList,
                ageRestricted,
                acceptedQuantity,
            } = productDetails || {}
            const unfilledQty = quantity - acceptedQuantity;
            return (
                <div>
                    <div className={`card card-body p-0 border-0 pb-2 mb-2`}>
                        <div className="row">
                            {
                                orderStatus === 1 && ((orderType === 2 && roleId === Constants.ROLES.ADMIN || roleId === Constants.ROLES.STORE_MANAGER || roleId === Constants.ROLES.STORE_ASSOCIATE) || (
                                    orderType === 1 && (roleId === Constants.ROLES.BISTRO_ASSOCIATE || roleId === Constants.ROLES.ADMIN || roleId === Constants.ROLES.STORE_MANAGER)
                                )) &&
                                <div className='col-sm-1'>
                                    <input
                                        type="checkbox"
                                        className='cursor-pointer'
                                        id="isCheckedProducts"
                                        onClick={(e) => this.isCheckedProducts(index, quantity, e)}
                                        checked={isSelectedCheck}
                                    />
                                </div>
                            }
                            <div className="col-sm-8">
                                <h3 className={`font13 mb-2 title-case
                                 ${(orderStatus !== 1 && acceptedQuantity === 0) ? 'strike' : ''}`}
                                    title={name?.toLowerCase()}>
                                    {quantity} x {name?.toLowerCase()}
                                    <p>(SKU - {sku}) <span className='text-danger'>{ageRestricted ? `18+` : ""}</span></p>
                                </h3>
                                {invoiceProductAddOnMappingList?.length > 0 ? (
                                    <p className="mb-1 font-14" style={{ paddingLeft: '10px' }}>
                                        <p className='font-13 ml-2'><b>YOUR CUSTOMIZATION:</b></p>

                                        {invoiceProductAddOnMappingList?.map((o) => o?.name).join(',')}
                                    </p>) : ''
                                }

                                {
                                    (unfilledQty !== 0 && acceptedQuantity !== 0 && orderStatus !== 1 && acceptedQuantity !== quantity) &&
                                    <p style={{ color: 'red', paddingLeft: '10px' }}>Unfulfilled Quantity: {unfilledQty}</p>
                                }

                            </div>

                            {
                                orderStatus === 1 && ((orderType === 2 && roleId === Constants.ROLES.ADMIN || roleId === Constants.ROLES.STORE_MANAGER || roleId === Constants.ROLES.STORE_ASSOCIATE) || (
                                    orderType === 1 && (roleId === Constants.ROLES.BISTRO_ASSOCIATE || roleId === Constants.ROLES.ADMIN || roleId === Constants.ROLES.STORE_MANAGER)
                                )) && <div className='col-sm-2'>
                                    {isSelectedCheck === true ? (<div className="order-quantity">
                                        <button className="quantity__minus cursor-pointer" id="minus-btn"
                                            disabled={newQuantity === 1}
                                            onClick={() => this.decrementCombineArray(index, newQuantity, isSelectedCheck)}><span>-</span>
                                        </button>
                                        <input name="quantity" type="text" className="quantity__input"
                                            disabled value={newQuantity} />
                                        <button className="quantity__plus cursor-pointer" id="plus-btn"
                                            disabled={newQuantity === quantity}
                                            onClick={() => this.incrementCombineArray(index, newQuantity, isSelectedCheck)}><span>+</span>
                                        </button>
                                    </div>) : (<div className="order-quantity">

                                    </div>)
                                    }

                                </div >
                            }

                            {/* <div className={`${orderStatus === 1 ? 'col-sm-3 text-end' : 'col-sm-4 text-end'}`}>
                                ${invoiceProductAddOnMappingList?.length > 0 ?
                                    totalAmountExcludingTax?.toFixed(2) : amountExcludingTax?.toFixed(2)}
                            </div> */}
                        </div >
                    </div >
                </div >

            )
        })


    }
    isComboOfferCheck(index, quantity, e, productIndex) {
        const { getComboOfferArray } = this.props
        if (getComboOfferArray && getComboOfferArray.length > 0) {
            getComboOfferArray[index].isSelectedCheck = e.target.checked;
            getComboOfferArray[index].newComboAppliedTimes = quantity;
            getComboOfferArray[index].comboProductGroup[
                productIndex
            ].isSelectedCheck = e.target.checked;
        }
        this.setState({ isSelectedAll: false, isPartialCheck: false })
        this.props.updateComboJSON(getComboOfferArray)
        this.isVerified()
    }
    isFreeGiveOfferCheck(index, quantity, e) {
        const { getFreeGiveOfferArray } = this.props
        if (getFreeGiveOfferArray && getFreeGiveOfferArray.length > 0) {
            getFreeGiveOfferArray[index].isSelectedCheck = e.target.checked;
            getFreeGiveOfferArray[index].newQuantity = quantity;

            getFreeGiveOfferArray[index].freeItemIdsWithQuantities?.forEach((obj, rIndex) => {
                obj?.idWithQuantity.forEach((free, subIndex) => {
                    free.newQuantity = free.qty;
                });
            })
        }
        this.setState({ isSelectedAll: false, isPartialCheck: false })
        this.props.updateFreeItemJSON(getFreeGiveOfferArray)
        this.isVerified()
    }
    isCheckedProducts(index, quantity, e) {
        const { getCombinedArray } = this.props
        if (getCombinedArray && getCombinedArray.length > 0) {
            getCombinedArray[index].isSelectedCheck = e.target.checked;
            getCombinedArray[index].newQuantity = quantity;
        }
        this.setState({ isSelectedAll: false, isPartialCheck: false })
        this.props.updateFinalArray(getCombinedArray)
        this.isVerified()
    }
    incrementCombineArray(index, quantity, check) {
        const { getCombinedArray } = this.props
        if (quantity < getCombinedArray[index].quantity) {
            let qty = quantity + 1;
            if (getCombinedArray && getCombinedArray.length > 0) {
                getCombinedArray[index].isSelectedCheck = check;
                getCombinedArray[index].newQuantity = qty;
            }
            this.props.updateFinalArray(getCombinedArray);
        }
        this.isVerified()

    }

    decrementCombineArray(index, quantity, check) {
        const { getCombinedArray } = this.props
        if (quantity > 1) {
            let qty = quantity - 1;
            if (getCombinedArray && getCombinedArray.length > 0) {
                getCombinedArray[index].isSelectedCheck = check;
                getCombinedArray[index].newQuantity = qty;
            }
            this.props.updateFinalArray(getCombinedArray);
        }
        this.isVerified()
    }

    _verifyAgeRestrictedProduct() {
        const { invoiceOrderProductDetailsList } = this.props.getOrders || {}
        const revealAge = invoiceOrderProductDetailsList?.findIndex((o) => o?.ageRestricted === true)
        return revealAge !== -1 ? <AlertNotify
            isInfo={true}
            message="Please verify the customer age before processing the Age Restricted product!"
        /> : null
    }



    incrementComboArray(index, quantity, check) {
        const { getComboOfferArray } = this.props
        if (quantity < getComboOfferArray[index].comboAppliedTimes) {
            let qty = quantity + 1;
            if (getComboOfferArray && getComboOfferArray.length > 0) {
                getComboOfferArray[index].isSelectedCheck = check;
                getComboOfferArray[index].newComboAppliedTimes = qty;
            }
            this.props.updateComboJSON(getComboOfferArray);
        }
        this.isVerified()
    }
    decrementComboArray(index, quantity, check) {
        const { getComboOfferArray } = this.props
        if (quantity > 1) {
            let qty = quantity - 1;
            if (getComboOfferArray && getComboOfferArray.length > 0) {
                getComboOfferArray[index].isSelectedCheck = check;
                getComboOfferArray[index].newComboAppliedTimes = qty;
            }
            this.props.updateComboJSON(getComboOfferArray);
        }
        this.isVerified()
    }
    _getAddons = (item) => {
        let { invoiceProductAddOnMappingList } = item;
        let itemStr = invoiceProductAddOnMappingList.reduce((accumulator, i) => {
            accumulator = accumulator ? accumulator + ', ' : '';
            return accumulator + i.name;
        }, '');
        return itemStr;
    };

    _getSumAmount = (item, orderStatus) => {
        let productAmount = parseFloat(item.unitPrice);
        let { invoiceProductAddOnMappingList } = item;
        let sum = invoiceProductAddOnMappingList.reduce((a, c) => {
            return a + parseFloat(c.unitPrice);
        }, productAmount);

        return ((orderStatus !== 1 ? item.acceptedQuantity : item.quantity) * sum)?.toFixed(2)
    };


    _renderComboOfferArrayProducts(comboOfferArray) {
        const { roleId } = this.props
        const { invoiceOrderProductDetailsList, orderStatus, orderType } = this.props.getOrders || {}
        let comboUI = []
        for (let i = 0; i < comboOfferArray.length; i++) {
            let productIndex;
            for (let j = 0; j < comboOfferArray?.[i]?.comboProductGroup?.length; j++) {
                let itemStr = '';
                let itemArray = [];
                for (let k = 0; k < comboOfferArray?.[i]?.comboProductGroup[j]?.ids?.length; k++) {
                    productIndex = invoiceOrderProductDetailsList.findIndex(
                        (itm) =>
                            itm.invoiceProductDetailsId ===
                            comboOfferArray?.[i]?.comboProductGroup?.[j]?.ids[k],
                    );

                    if (productIndex !== -1) {
                        itemStr = itemStr ? itemStr + ', ' : '';

                        itemStr = itemStr + invoiceOrderProductDetailsList[productIndex]?.name;
                        itemArray.push(<>
                            <h3 className={`font13 mb-2 title-case ${(orderStatus !== 1) ? 'strike' : ''}`}
                                title={invoiceOrderProductDetailsList?.[productIndex]?.name}>
                                <p>{invoiceOrderProductDetailsList?.[productIndex]?.name?.toLowerCase()} (SKU - {invoiceOrderProductDetailsList?.[productIndex]?.upc})
                                </p>
                            </h3>

                            {invoiceOrderProductDetailsList?.[productIndex]?.invoiceProductAddOnMappingList?.length > 0 ? (
                                < p className="mb-1 font-14" style={{ paddingLeft: '10px' }}>
                                    <p className='font-13 ml-2'><b>YOUR CUSTOMIZATION:</b></p>

                                    {this._getAddons(
                                        invoiceOrderProductDetailsList?.[productIndex],
                                    )}
                                </p>) : ''
                            }

                        </>)
                    }
                }
                if (productIndex !== -1) {
                    comboUI.push(
                        <>
                            <div className="row mb-1">
                                {
                                    orderStatus === 1 && ((orderType === 2 && roleId === Constants.ROLES.ADMIN || roleId === Constants.ROLES.STORE_MANAGER || roleId === Constants.ROLES.STORE_ASSOCIATE) || (
                                        orderType === 1 &&
                                        (roleId === Constants.ROLES.BISTRO_ASSOCIATE || roleId === Constants.ROLES.ADMIN || roleId === Constants.ROLES.STORE_MANAGER)
                                    )) && <div className='col-1'>
                                        <input
                                            type="checkbox"
                                            id="isSelectedCheck"
                                            className="cursor-pointer"
                                            checked={comboOfferArray[i].comboProductGroup[j]
                                                .isSelectedCheck}
                                            onClick={(e) => this.isComboOfferCheck(
                                                i,
                                                comboOfferArray[i]?.comboAppliedTimes,
                                                e,
                                                j,
                                            )}
                                        />
                                    </div>
                                }
                                <div className='col-11'>
                                    {itemArray}
                                </div>
                            </div>
                        </>
                    )
                }


            }
        }
        return (
            <>
                {
                    comboUI?.length > 0 && <>
                        <div className="row mb-1">
                            <div className="col-sm-8 fw-bold text-black">Combo Offer:</div>
                            <div className="col-sm-4 text-end fw-bold"></div>
                        </div>
                        {comboUI}
                    </>
                }

            </>
        )
    }



    decrementFreeGive(index, quantity, check) {
        const { getFreeGiveOfferArray } = this.props
        if (quantity > 1) {
            let qty = quantity - 1;
            if (getFreeGiveOfferArray && getFreeGiveOfferArray.length > 0) {
                getFreeGiveOfferArray[index].isSelectedCheck = check;
                getFreeGiveOfferArray[index].newQuantity = qty;

                getFreeGiveOfferArray[index].idWithQuantity.forEach((paidProduct) => {
                    paidProduct.newQuantity = qty;
                });

                getFreeGiveOfferArray[index].freeItemIdsWithQuantities?.map((o, i) => {
                    return o?.idWithQuantity?.forEach((ids) => {
                        let freeQty = Math.min(
                            ids.qty,
                            o.baseQuantity * qty,
                        );
                        ids.newQuantity = freeQty;
                    });

                })
            }
            this.props.updateFreeItemJSON(getFreeGiveOfferArray);
        }
        this.isVerified()
    }
    incrementFreeGive(index, quantity, check) {
        const { getFreeGiveOfferArray } = this.props
        if (quantity < getFreeGiveOfferArray[index].quantity) {
            let qty = quantity + 1;
            if (getFreeGiveOfferArray && getFreeGiveOfferArray.length > 0) {
                getFreeGiveOfferArray[index].isSelectedCheck = check;
                getFreeGiveOfferArray[index].newQuantity = qty;
                getFreeGiveOfferArray[index].idWithQuantity.forEach((paidProduct) => {
                    paidProduct.newQuantity = qty;
                });

                getFreeGiveOfferArray[index].freeItemIdsWithQuantities?.map((o, i) => {
                    return o?.idWithQuantity?.forEach((ids) => {
                        let freeQty = Math.min(
                            ids.qty,
                            o.baseQuantity * qty,
                        );
                        ids.newQuantity = freeQty;
                    });

                })
            }
            this.props.updateFreeItemJSON(getFreeGiveOfferArray);
        }
        this.isVerified()
    }
    _renderFreeGiveOfferArrayProducts(freeGiveArray) {
        const { roleId } = this.props
        const { invoiceOrderProductDetailsList, orderStatus, orderType } = this.props.getOrders || {}
        return freeGiveArray?.length > 0 ? <>



            {freeGiveArray?.map((freeGiveObj, index) => {
                let paidItemStr = '';
                let productIndex;
                let itemArray = [];

                freeGiveObj.idWithQuantity.forEach((obj) => {
                    productIndex = invoiceOrderProductDetailsList?.findIndex((itm) => itm?.invoiceProductDetailsId === obj?.id);
                });

                if (productIndex !== -1) {
                    paidItemStr = invoiceOrderProductDetailsList[productIndex]?.name;
                } else {
                    return;
                }

                let itemStr = '';
                let itemQty = '';
                for (let j = 0; j < freeGiveObj?.freeItemIdsWithQuantities.length; j++) {
                    let productMissingIndex;
                    freeGiveObj?.freeItemIdsWithQuantities?.[j].idWithQuantity.map((freeIds) => {
                        productMissingIndex = invoiceOrderProductDetailsList?.findIndex((itm) => itm.invoiceProductDetailsId === freeIds?.id);
                    });

                    itemStr = itemStr ? itemStr + ', ' : '';
                    if (productMissingIndex !== -1) {
                        itemStr = itemStr + invoiceOrderProductDetailsList[productMissingIndex]?.name;
                        itemQty = invoiceOrderProductDetailsList[productMissingIndex]?.quantity;
                    }
                    itemArray.push(
                        <>
                            <h3 className={`font13 mb-2 title-case`}
                                title={invoiceOrderProductDetailsList?.[productMissingIndex]?.name}>

                                <p>{invoiceOrderProductDetailsList?.[productMissingIndex]?.name} (SKU - {invoiceOrderProductDetailsList?.[productMissingIndex]?.upc})
                                    <span className='text-danger'>{invoiceOrderProductDetailsList?.[productMissingIndex]?.ageRestricted ? `18+` : ""}</span>
                                </p>
                            </h3>

                            {invoiceOrderProductDetailsList?.[productMissingIndex]?.invoiceProductAddOnMappingList?.length > 0 ? (
                                < p className="mb-1 font-14" style={{ paddingLeft: '10px' }}>
                                    <p className='font-13 ml-2'><b>YOUR CUSTOMIZATION:</b></p>
                                    {this._getAddons(
                                        invoiceOrderProductDetailsList?.[productMissingIndex],
                                    )}
                                </p>) : ''
                            }
                        </>
                    )
                }

                return <>
                    {
                        index === 0 && <div className='row mt-1'>
                            {orderStatus === 1 && <div className='col-sm-2'></div>}
                            <div className='col-sm-11 fw-bold text-black'>Free Give:</div>
                        </div>
                    }

                    <div className="row align-items-center mb-2" key={JSON.stringify(freeGiveObj)}>
                        {
                            orderStatus === 1 && ((orderType === 2 && roleId === Constants.ROLES.ADMIN || roleId === Constants.ROLES.STORE_MANAGER || roleId === Constants.ROLES.STORE_ASSOCIATE) || (
                                orderType === 1 && (roleId === Constants.ROLES.BISTRO_ASSOCIATE || roleId === Constants.ROLES.ADMIN || roleId === Constants.ROLES.STORE_MANAGER)
                            )) && <div className='col-sm-1'>
                                <input
                                    id="freeGiveObj"
                                    type="checkbox"
                                    checked={freeGiveObj?.isSelectedCheck}
                                    className="cursor-pointer"
                                    onClick={(e) => this.isFreeGiveOfferCheck(index, freeGiveObj?.quantity, e)}
                                />
                            </div>
                        }

                        <div className='col-sm-8'>
                            <div className="col-10 title-case">
                                <p><b>Paid:</b></p>
                                <h3 className={`font13 mb-2 title-case`}
                                    title={invoiceOrderProductDetailsList?.[productIndex]?.name}>
                                    <p>{invoiceOrderProductDetailsList?.[productIndex]?.name} (SKU - {invoiceOrderProductDetailsList?.[productIndex]?.upc})
                                        <span className='text-danger'>{invoiceOrderProductDetailsList?.[productIndex]?.ageRestricted ? `18+` : ""}</span>
                                    </p>
                                </h3>

                                {invoiceOrderProductDetailsList?.[productIndex]?.invoiceProductAddOnMappingList?.length > 0 ? (
                                    < p className="mb-1 font-14" style={{ paddingLeft: '10px' }}>
                                        <p className='font-13 ml-2'><b>YOUR CUSTOMIZATION:</b></p>
                                        {this._getAddons(
                                            invoiceOrderProductDetailsList?.[productIndex],
                                        )}
                                    </p>) : ''
                                }
                            </div>
                            <div className="col-10 title-case">
                                <p><b>Free:</b></p>
                                {itemArray}
                            </div>
                        </div>

                        <div className='col-sm-2'>
                            {freeGiveObj?.isSelectedCheck && ((orderType === 2 && roleId === Constants.ROLES.ADMIN || roleId === Constants.ROLES.STORE_MANAGER || roleId === Constants.ROLES.STORE_ASSOCIATE) || (
                                orderType === 1 && (roleId === Constants.ROLES.BISTRO_ASSOCIATE || roleId === Constants.ROLES.ADMIN || roleId === Constants.ROLES.STORE_MANAGER)
                            )) ?
                                <div className="order-quantity">
                                    <button
                                        id="freeGiveObj-minus"
                                        className="quantity__minus cursor-pointer"
                                        disabled={freeGiveObj?.newQuantity === 1}
                                        onClick={() => this.decrementFreeGive(index, freeGiveObj?.newQuantity,
                                            freeGiveObj?.isSelectedCheck)}>
                                        <span>-</span>
                                    </button>
                                    <input
                                        name="quantity"
                                        type="text"
                                        className="quantity__input"
                                        disabled
                                        value={freeGiveObj?.newQuantity} />
                                    <button
                                        id="freeGiveObj-plus"
                                        className="quantity__plus cursor-pointer"
                                        disabled={freeGiveObj?.newQuantity === freeGiveObj?.quantity}
                                        onClick={() => this.incrementFreeGive(index, freeGiveObj?.newQuantity,
                                            freeGiveObj?.isSelectedCheck)}>
                                        <span>+</span>
                                    </button>
                                </div>
                                : ''
                            }
                        </div>
                    </div>
                </>
            })
            }
        </> : null;
    }
    isMultibuyCheck(index, quantity, e) {
        const { getMultibuyOfferArray } = this.props
        if (getMultibuyOfferArray && getMultibuyOfferArray.length > 0) {
            getMultibuyOfferArray[index].isSelectedCheck = e.target.checked;
            getMultibuyOfferArray[index].newQuantity = quantity;
        }
        this.setState({ isSelectedAll: false })
        this.props.updateMultibuyArray(getMultibuyOfferArray)
        this.isVerified()
    }

    incrementMultibuy(index, quantity, check) {
        const { getMultibuyOfferArray } = this.props
        if (quantity < getMultibuyOfferArray[index].appliedTimes) {
            let qty = quantity + 1;
            if (getMultibuyOfferArray && getMultibuyOfferArray.length > 0) {
                getMultibuyOfferArray[index].isSelectedCheck = check;
                getMultibuyOfferArray[index].newQuantity = qty;
            }
            this.props.updateMultibuyArray(getMultibuyOfferArray);
        }
        this.isVerified()
    }
    decrementMultiBuy(index, quantity, check) {
        const { getMultibuyOfferArray } = this.props
        if (quantity > 1) {
            let qty = quantity - 1;
            if (getMultibuyOfferArray && getMultibuyOfferArray.length > 0) {
                getMultibuyOfferArray[index].isSelectedCheck = check;
                getMultibuyOfferArray[index].newQuantity = qty;
            }
            this.props.updateMultibuyArray(getMultibuyOfferArray);
        }
        this.isVerified()
    }

    _renderMultibuyArrayProducts(getMultibuyOfferArray) {
        const { roleId } = this.props
        const { invoiceOrderProductDetailsList, orderStatus, orderType } = this.props.getOrders || {}
        let multiUI = []
        for (let i = 0; i < getMultibuyOfferArray?.length; i++) {
            let itemStr = '';
            let itemArray = [];
            let productIndex;
            getMultibuyOfferArray?.[i]?.idWithQuantity.map((ids) => {
                productIndex = invoiceOrderProductDetailsList?.findIndex(
                    (itm) => itm.invoiceProductDetailsId === ids.id,
                );
                if (productIndex !== -1) {
                    itemStr = invoiceOrderProductDetailsList?.[productIndex]?.name;
                }
                itemArray.push(<>
                    <h3 className={`font13 mb-2  title-case ${(orderStatus !== 1) ? 'strike' : ''}`}
                        title={invoiceOrderProductDetailsList?.[productIndex]?.name}>

                        <p className=' title-case'>{ids?.qty} x {invoiceOrderProductDetailsList?.[productIndex]?.name} (SKU - {invoiceOrderProductDetailsList?.[productIndex]?.upc})
                            <span className='text-danger'>{invoiceOrderProductDetailsList?.[productIndex]?.ageRestricted ? `18+` : ""}</span>
                        </p>
                    </h3>

                    {invoiceOrderProductDetailsList?.[productIndex]?.invoiceProductAddOnMappingList?.length > 0 ? (
                        <p className="mb-1 font-14" style={{ paddingLeft: '10px' }}>
                            <p className='font-13 ml-2'><b>YOUR CUSTOMIZATION:</b></p>
                            {this._getAddons(
                                invoiceOrderProductDetailsList?.[productIndex],
                            )}
                        </p>) : ''
                    }
                </>)

            })
            if (productIndex !== -1) {
                multiUI.push(
                    <>

                        <div className="row mb-2">
                            {
                                orderStatus === 1 && ((orderType === 2 && roleId === Constants.ROLES.ADMIN || roleId === Constants.ROLES.STORE_MANAGER || roleId === Constants.ROLES.STORE_ASSOCIATE) || (
                                    orderType === 1 && (roleId === Constants.ROLES.BISTRO_ASSOCIATE || roleId === Constants.ROLES.ADMIN || roleId === Constants.ROLES.STORE_MANAGER)
                                )) && <div className='col-1'>
                                    <input
                                        id="getMultibuyOfferArray"
                                        type="checkbox"
                                        className="cursor-pointer"
                                        checked={getMultibuyOfferArray?.[i]?.isSelectedCheck}
                                        onClick={(e) => this.isMultibuyCheck(
                                            i,
                                            getMultibuyOfferArray[i]?.appliedTimes,
                                            e,
                                        )}
                                    />
                                </div>
                            }
                            <div className='col-8'>
                                {itemArray}
                            </div>
                            {
                                orderStatus === 1 && ((orderType === 2 && roleId === Constants.ROLES.ADMIN || roleId === Constants.ROLES.STORE_MANAGER || roleId === Constants.ROLES.STORE_ASSOCIATE) || (
                                    orderType === 1 && (roleId === Constants.ROLES.BISTRO_ASSOCIATE || roleId === Constants.ROLES.ADMIN || roleId === Constants.ROLES.STORE_MANAGER)
                                )) &&
                                <div className='col-2'>
                                    {getMultibuyOfferArray?.[i]?.isSelectedCheck ?
                                        <div className="order-quantity">
                                            <button
                                                id="getMultibuyOfferArray-minus"
                                                className="quantity__minus cursor-pointer"
                                                onClick={() => this.decrementMultiBuy(
                                                    i,
                                                    getMultibuyOfferArray[i]?.newQuantity,
                                                    getMultibuyOfferArray[i]?.isSelectedCheck
                                                )}
                                                disabled={getMultibuyOfferArray?.[i]?.newQuantity === 1}
                                            ><span>-</span>
                                            </button>
                                            <input
                                                name="quantity"
                                                type="text"
                                                className="quantity__input"
                                                disabled
                                                value={getMultibuyOfferArray?.[i]?.newQuantity} />
                                            <button
                                                className="quantity__plus cursor-pointer"
                                                id="getMultibuyOfferArray-plus"
                                                onClick={() => this.incrementMultibuy(
                                                    i,
                                                    getMultibuyOfferArray?.[i]?.newQuantity,
                                                    getMultibuyOfferArray?.[i]?.isSelectedCheck,

                                                )}
                                                disabled={getMultibuyOfferArray?.[i]?.appliedTimes === getMultibuyOfferArray?.[i]?.newQuantity}
                                            ><span>+</span>
                                            </button>
                                        </div> : ''
                                    }
                                </div>
                            }
                        </div>
                    </>
                )
            }
        }

        return (
            <>
                {
                    multiUI?.length > 0 && <>
                        <div className="row mb-1">
                            <div className="col-sm-8 fw-bold text-black">MultiBuy:</div>
                            <div className="col-sm-4 text-end fw-bold"></div>
                        </div>
                        {multiUI}
                    </>
                }

            </>
        )
    }


    _renderOffers() {
        const { getComboOfferArray, getFreeGiveOfferArray, getMultibuyOfferArray } = this.props
        if ((getComboOfferArray?.length > 0) || (getFreeGiveOfferArray?.length > 0) || (getMultibuyOfferArray?.length > 0)) {
            return (
                <>
                    {this._renderComboOfferArrayProducts(getComboOfferArray)}
                    {this._renderFreeGiveOfferArrayProducts(getFreeGiveOfferArray)}
                    {this._renderMultibuyArrayProducts(getMultibuyOfferArray)}
                </>
            );
        }
        return null;
    }

    isVerified() {
        const { getCombinedArray, getComboOfferArray, getFreeGiveOfferArray, getMultibuyOfferArray } = this.props

        let comboArrayCheck = [];
        getComboOfferArray?.map((item) => {
            comboArrayCheck = [...comboArrayCheck, ...item.comboProductGroup];
        });

        let combineOffers = getCombinedArray?.concat(
            getFreeGiveOfferArray,
            getMultibuyOfferArray,
            comboArrayCheck,
        );
        let checker;
        checker = combineOffers.every((v) => v.isSelectedCheck === true);
        let disableButton = combineOffers?.filter((o) => o?.isSelectedCheck === true)
        this.setState({ isSelectedAll: checker, disableButton });
    };

    render() {
        const { showOrders, openQRmodal, isOpenDeclineModal, isOpenPartialOrder,
            isOpenAgeResModal, isLoading, isInvoiceModal, isOpenTrackModal, isSelectedAll, isPartialCheck, errorMessage } = this.state
        const { onClose, getCombinedArray, getComboOfferArray, getFreeGiveOfferArray, roleId, getMultibuyOfferArray } = this.props
        const { invoiceDetails, botName, customerComment, orderStatus = '',
            prepared, botOrderId, createdDate,
            invoiceOrderDetailsId, partialAcceptComment, orderType } = this.props.getOrders || {}
        return (
            <React.Fragment>
                {showOrders &&
                    <div className="modal d-block"
                        id="order-details-modal"
                        tabIndex="-1"
                        aria-labelledby="login"
                        aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content rounded-0">
                                <img
                                    src={Close}
                                    alt="X"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    id="Close"
                                    className="modal-close"
                                    onClick={() => onClose()} />

                                <div className="modal-header rounded-0 align-items-center py-4">
                                    <div className="col">
                                        <h5 className="modal-title">Order Details</h5>
                                    </div>
                                    {orderStatus === 1 && <div className="col font13 text-end text-warning">
                                        <img src={Pending} alt="Yes" className="me-1" /> Pending
                                    </div>}
                                    {orderStatus === 5 && <div className="col font13 text-end text-success">
                                        <img src={Delivered} alt="Yes" className="me-1" /> Delivered
                                    </div>} {orderStatus === 2 && <div className="col font13 text-end text-success">
                                        <img src={Delivered} alt="Yes" className="me-1" /> Accepted
                                    </div>}
                                    {orderStatus === 4 && <div className="col font13 text-end text-success">
                                        <img src={Delivered} alt="Yes" className="me-1" /> {invoiceDetails?.natureOfOrder && invoiceDetails?.natureOfOrder === 1 ? 'Out for delivery' : 'Ready to pickup'}
                                    </div>}
                                    {orderStatus === 3 && <div className="col font13 text-end text-danger">
                                        <img src={Declined} alt="Yes" className="me-1" /> Declined
                                    </div>}
                                </div>
                                <div className="modal-body font13 p-40">
                                    {this._renderOrderDetails()}
                                    <div className="row mb-2">
                                        <div className="col">
                                            <h3 className="font16 fw-bold">Bill Details</h3>
                                        </div>
                                        <div className="col-auto text-end font14 fw-bold" id="openViewInvoice" onClick={() => this.openViewInvoice()}>
                                            <span className='cursor-pointer text-primary'
                                            >View Receipt</span>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        {
                                            orderStatus === 1 && ((orderType === 2 && roleId === Constants.ROLES.ADMIN || roleId === Constants.ROLES.STORE_MANAGER || roleId === Constants.ROLES.STORE_ASSOCIATE) || (
                                                orderType === 1 && (roleId === Constants.ROLES.BISTRO_ASSOCIATE || roleId === Constants.ROLES.ADMIN || roleId === Constants.ROLES.STORE_MANAGER)
                                            )) && <>
                                                <div className="col-1">

                                                    <p className="font12 fw-bold cursor-pointer text-primary">
                                                        <input
                                                            type="checkbox"
                                                            id="check"
                                                            onClick={(e) => {
                                                                if (getCombinedArray?.length !== 0) {
                                                                    getCombinedArray?.forEach((item, index) => {
                                                                        this.isSelectAll(
                                                                            index,
                                                                            item.quantity,
                                                                            e
                                                                        )
                                                                    });
                                                                }
                                                                if (getComboOfferArray?.length !== 0) {
                                                                    getComboOfferArray.forEach((item, index) => {
                                                                        this.isSelectAllCombo(
                                                                            index,
                                                                            item.comboAppliedTimes,
                                                                            e
                                                                        );
                                                                    });
                                                                }
                                                                if (getFreeGiveOfferArray?.length !== 0) {
                                                                    getFreeGiveOfferArray?.forEach((item, index) => {
                                                                        this.isSelectAllfree(
                                                                            index,
                                                                            item.quantity,
                                                                            e
                                                                        );

                                                                    });
                                                                }
                                                                if (getMultibuyOfferArray?.length !== 0) {
                                                                    getMultibuyOfferArray.forEach((item, index) => {
                                                                        this.isSelectAllMultibuy(
                                                                            index,
                                                                            item.appliedTimes,
                                                                            e
                                                                        );
                                                                    });
                                                                }
                                                            }}
                                                            checked={isSelectedAll}
                                                            className="cursor-pointer"
                                                        />
                                                    </p>

                                                </div>
                                                <div className='col-auto'>
                                                    <p className='text-primary'><b>SELECT ALL</b></p>
                                                </div>
                                            </>
                                        }
                                    </div>

                                    {orderStatus === 1 && this._renderCombinedArrayProducts()}
                                    {orderStatus === 1 && this._renderOffers()}
                                    {orderStatus !== 1 && this._renderProductDetails()}
                                    <div className="dotted-divider pt-2 mt-2 mb-3">


                                        <div className="row mb-2">
                                            <div className="col">Order no:</div>
                                            <div className="col-auto text-end fw-bold"> {invoiceOrderDetailsId}</div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col">Order date:</div>
                                            <div className="col-auto text-end fw-bold">{moment(createdDate).format('DD-MMM | hh:mm A')}</div>
                                        </div>
                                        {
                                            partialAcceptComment && <div className="row mb-2">
                                                <div className="col">Order Comment:</div>
                                                <div className="col-auto fw-bold"> {partialAcceptComment}</div>
                                            </div>
                                        }
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col">Receipt no:</div>
                                        <div className="col-auto text-end fw-bold"> {invoiceDetails?.invoiceId}</div>
                                    </div>
                                    {this._verifyAgeRestrictedProduct()}

                                    {
                                        (orderStatus === 2 && prepared) ? <AlertNotify
                                            isInfo={true}
                                            message="Food has been prepared, So please collect the order from kitchen team."
                                        /> : null}
                                    {
                                        customerComment && <span><b>Customer Notes:</b> {customerComment}</span>
                                    }
                                    {invoiceDetails?.fulfillmentRestrictionOverridden ? <AlertNotify
                                        isInfo={true}
                                        message="User agreed to process this order during the next business hours."
                                    /> : null}
                                    {
                                        (orderStatus === 4 && invoiceDetails?.natureOfOrder === 1) ?
                                            botName ?
                                                <AlertNotify
                                                    isInfo={true}
                                                    message={`Delivery assigned to ${botName}`}
                                                /> :
                                                <AlertNotify
                                                    isInfo={true}
                                                    message={`Daxbot not available/eligible for this order.Required manual delivery`}
                                                /> : null

                                    }
                                    {(roleId === Constants.ROLES.ADMIN || roleId === Constants.ROLES.STORE_MANAGER || roleId === Constants.ROLES.STORE_ASSOCIATE
                                        && orderType === 2) && this._renderOrderButton()}
                                    {(roleId === Constants.ROLES.BISTRO_ASSOCIATE && orderType === 1) && this._renderOrderButton()}
                                </div>
                            </div>
                        </div>
                        {openQRmodal && <QrCodeScanner
                            id="QrCodeScanner"
                            onFailure={() => this.closeQRModal()}
                            deliverApi={true}
                            openQrScanner={openQRmodal}
                            orderID={this.state.orderID}
                            setOrderDetailsModal={this.props.setOrderDetailsModal}
                            apiFilters={this.props.apiFilters}
                        />}
                        {isOpenDeclineModal && <DeclineModal
                            id="DeclineModal"
                            onClose={() => this.setState({ isOpenDeclineModal: false })}
                            onChange={(e) => this.setDeclineText(e.target.value)}
                            errorMsg="Please enter decline reason"
                            title="Reason for decline the order"
                            confirm="Decline"
                            cancel="Cancel"
                            onClick={(type) => {
                                if (type === 1) {
                                    this.setState({ isOpenDeclineModal: false })
                                } else {
                                    this.handelButtonClick(2)
                                }
                            }}
                        />}
                        {isOpenTrackModal && <MapViewModal
                            id="MapViewModal"
                            trackMap={isOpenTrackModal}
                            onClose={() => this.setState({ isOpenTrackModal: false })}
                            trackId={botOrderId} />}
                        {
                            isInvoiceModal && <ViewInvoiceModal
                                viewInvoiceModal={isInvoiceModal}
                                id="invoiceDetails"
                                setViewInvoiceModal={() => this.setState({ isInvoiceModal: false })}
                                ViewInvoiceDetailsId={invoiceDetails?.invoiceId}
                                viewOrderDetails={this.props.getOrders}
                            />
                        }
                        {isLoading && <AppLoader />}
                        {isOpenAgeResModal &&
                            <Alert
                                id="Alert"
                                title="Info"
                                cancel='Cancel'
                                proceed='Confirm'
                                description='Please verify the customer age before processing the Age Restricted product!'
                                onSuccess={() => this.confirmAgerestricted()}
                                onFailure={() => this.setState({ isOpenAgeResModal: false })}
                            />
                        }
                        {isOpenPartialOrder && <PartialOrderPopUp
                            id="PartialOrderPopUp"
                            onSuccess={() => this.handelButtonClick(1)}
                            onFailure={() => this.closeModal()}
                            productArray={this.state.finalProductArray}
                            isLoading={isLoading}
                            handlePartialReason={(e) => this.handlePartialReason(e)}
                            isSelectedAll={isPartialCheck}
                            errorMessage={errorMessage}
                        />}
                    </div>
                }
                <div className="modal-backdrop show"></div>
            </React.Fragment>
        )
    }
}

export const mapStateToProps = (state) => {
    return {
        getOrders: getCurrentOrderObjectSelector(state),
        getCombinedArray: getPromotionCombineArraySelector(state),
        getComboOfferArray: getComboOfferArraySelector(state),
        getFreeGiveOfferArray: getFreeGiveArraySelector(state),
        getMultibuyOfferArray: getMultibuyArraySelector(state),
        roleId: getRoleIdFromCookie()
    };
};

export default withRouter(connect(mapStateToProps, {
    getOrderDetails: Actions.getOrderDetails,
    updateComboJSON: Actions.comboOfferProductList,
    updateFreeItemJSON: Actions.freeGiveProductList,
    updateFinalArray: Actions.promotionCombine,
    getOrderDetailsUpdate: Actions.getOrderDetailsUpdate,
    getOrderList: Actions.getOrderList,
    updateMultibuyArray: Actions.updateMultibuyArray
})(OrderDetails))
