import Actions from '../../actions';
const initialState = {
    isLoading: false,
    ProductUpdateDetails: {},
    error: false,
}
const updatePlanogramProductReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.UPDATE_PLANOGRAM_PRODUCT: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.UPDATE_PLANOGRAM_PRODUCT_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                ProductUpdateDetails: action.data,
                error: false
            };
        }
        case Actions.UPDATE_PLANOGRAM_PRODUCT_FAILURE: {
            return {
                ...state,
                isLoading: false,
                ProductUpdateDetails: {},
                error: action.error,
            };
        }
        default:
            return state;
    }
};
export const getUpdateLoader = (state) => state?.UPDATE_PLANOGRAM_PRODUCT?.isLoading
export default updatePlanogramProductReducer;