import { doAction, doResponseAction } from './index'

const CREATE_NEW_CHAT = 'CREATE_NEW_CHAT';
const CREATE_NEW_CHAT_SUCCESS = 'CREATE_NEW_CHAT_SUCCESS';
const CREATE_NEW_CHAT_FAILURE = 'CREATE_NEW_CHAT_FAILURE';


const GET_CHAT_MESSAGES_LIST = 'GET_CHAT_MESSAGES_LIST';
const GET_CHAT_MESSAGES_LIST_SUCCESS = 'GET_CHAT_MESSAGES_LIST_SUCCESS';
const GET_CHAT_MESSAGES_LIST_FAILURE = 'GET_CHAT_MESSAGES_LIST_FAILURE';

const GET_ALL_CHATS_LIST = 'GET_ALL_CHATS_LIST';
const GET_ALL_CHATS_LIST_SUCCESS = 'GET_ALL_CHATS_LIST_SUCCESS';
const GET_ALL_CHATS_LIST_FAILURE = 'GET_ALL_CHATS_LIST_FAILURE';

const SAVE_SELECTED_CHAT = 'SAVE_SELECTED_CHAT';
const CLEAR_CHAT_MESSAGES ='CLEAR_CHAT_MESSAGES'

export {
    GET_CHAT_MESSAGES_LIST,
    GET_CHAT_MESSAGES_LIST_SUCCESS,
    GET_CHAT_MESSAGES_LIST_FAILURE,
    CREATE_NEW_CHAT,
    CREATE_NEW_CHAT_SUCCESS,
    CREATE_NEW_CHAT_FAILURE,
    GET_ALL_CHATS_LIST,
    GET_ALL_CHATS_LIST_SUCCESS,
    GET_ALL_CHATS_LIST_FAILURE,
    SAVE_SELECTED_CHAT,
    CLEAR_CHAT_MESSAGES
}

// initiate chat
export const createNewChat = (params, callback) => doAction(CREATE_NEW_CHAT, params, callback)
export const createNewChatSuccess = (data) => doResponseAction(CREATE_NEW_CHAT_SUCCESS, data)
export const createNewChatFailure = (data) => doResponseAction(CREATE_NEW_CHAT_FAILURE, data)

// get chat messages
export const getChatMessagesList = (params, callback) => doAction(GET_CHAT_MESSAGES_LIST, params, callback);
export const getChatMessagesListSuccess = (data) => doResponseAction(GET_CHAT_MESSAGES_LIST_SUCCESS, data);
export const getChatMessagesListFailure = (data) => doResponseAction(GET_CHAT_MESSAGES_LIST_FAILURE, data);

// get all chat messages
export const getAllChatMessagesListAction = (params, callback) => doAction(GET_ALL_CHATS_LIST, params, callback);
export const getAllChatMessagesListSuccess = (data) => doResponseAction(GET_ALL_CHATS_LIST_SUCCESS, data);
export const getAllChatMessagesListFailure = (data) => doResponseAction(GET_ALL_CHATS_LIST_FAILURE, data);

export const selectedChatAction = (data) => doResponseAction(SAVE_SELECTED_CHAT, data);
export const clearChatMessagesList = () => doResponseAction(CLEAR_CHAT_MESSAGES);