import React, { useEffect, useState } from 'react';
import ChatMessagesItem from './ChatMessagesItem';
import sendIcon from '../../../asset/new/img/send.svg'
import { useDispatch, useSelector } from 'react-redux';
import { getChatMessagesList, createNewChat } from '../../redux/actions/ChatsAction';
import { getChatObjectSelector, getSelectedChatSelector } from '../../redux/reducers/ChatsReducer';
import AppLoader from '../../components/fields/AppLoader';
import Constants, { COLORS } from '../../utils/Constants';
import ViewInvoiceModal from '../../components/ViewInvoiceModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment } from '@fortawesome/free-solid-svg-icons';
import { getRoleIdFromCookie } from '../../utils/commonFunctions/cookie';


const ChatConversation = ({ windowHeight, setChatError = () => null }) => {
    const [userMessageState, setUserMessage] = useState('');
    const selectedChat = useSelector(getSelectedChatSelector);
    const { id, invoiceId } = selectedChat;
    const dispatch = useDispatch();
    const messageObject = useSelector(getChatObjectSelector);
    const messageList = messageObject?.chatMessages || [];
    const [isLoading, setIsLoading] = useState(false);
    const [isChatLoading, setChatLoading] = useState(false);
    const roleId = getRoleIdFromCookie();
    const [viewInvoice] = useState('');
    const [viewInvoiceModal, setViewInvoiceModal] = useState(false)
    const reduceWindowHeightBy = roleId === (Constants.ROLES.STORE_MANAGER) || (roleId === Constants.ROLES.ADMIN) ? 100 : 50;
    useEffect(() => {
        setIsLoading(true);
        if (invoiceId) {
            dispatch(getChatMessagesList({ invoiceId }, (resp) => {
                if (typeof resp === 'string') {
                    setChatError(resp);
                } else {
                    setChatError(null);
                }
                setIsLoading(false)
            }))
        }
    }, [dispatch, setChatError, invoiceId])


    const sendMessage = () => {
        setChatLoading(true);
        if (userMessageState.trim().length === 0)
            return
        const params = {
            chatId: id,
            message: userMessageState
        };
        dispatch(createNewChat(params, (response) => {
            if (response.response_code === 0) {
                setChatLoading(false);
            }
        }));
        setUserMessage('')

    }
    const ViewInvoice = () => {
        setViewInvoiceModal(true)
    }

    {

        if (!selectedChat.id) {
            return <div style={{
                display: 'flex', flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
            }}>
                <FontAwesomeIcon
                    color={COLORS.listBackground}
                    icon={faComment} size="10x" />
                <span style={{ color: COLORS.appThemeHover }}>
                    Please click on the chat list item to start messaging
                </span>
            </div>
        } else {
            return <div className="bg-light">
                <div className="row mx-0 p-2 bg-cream">
                    <div className="col-9 fw-bold">
                        {`You are Connected with an Receipt no: ${invoiceId}`}
                    </div>
                    {/* <a className="text-dark" data-bs-toggle="modal"
                data-bs-target="#invoice-modal" href="#">You are Connected with an Invoice No: {invoiceId} View Invoice</a> */}
                    <div className="col text-end">
                        <span className=" cursor-pointer text-primary"
                            data-bs-toggle="modal"
                            id="ViewReceipt"
                            onClick={() => ViewInvoice()}
                        >View Receipt</span> </div>
                </div>

                <div style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    {isLoading ? <div style={{
                        display: 'flex', justifyContent: 'center',
                        alignItems: 'center', height: windowHeight - reduceWindowHeightBy
                    }}>
                        <AppLoader />
                    </div> : <div className="chat-history p-3">
                        {
                            messageList.map(d => <ChatMessagesItem message={d} />)
                        }
                    </div>}
                </div>
                {
                    !isLoading && (roleId === Constants.ROLES.STORE_MANAGER || roleId === Constants.ROLES.ADMIN) && <div className="input-group mb-3 mb-lg-0">
                        <input type="text"
                            className="form-control py-3"
                            id="keyDown"
                            placeholder="Write your message..."
                            onChange={(e) => setUserMessage(e.target.value)}
                            value={userMessageState}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                    sendMessage()
                                }
                            }}
                        />
                        <button className="btn bg-white border-dark" type="button" id="sendMessage" onClick={() => sendMessage()}>
                            <img src={sendIcon} alt="Go" /></button>

                    </div>
                }
                {
                    viewInvoiceModal && <ViewInvoiceModal
                        id="viewInvoiceModal"
                        viewInvoiceModal={viewInvoiceModal}
                        setViewInvoiceModal={() => setViewInvoiceModal(false)}
                        orderDetails={viewInvoice}
                        ViewInvoiceDetailsId={invoiceId}
                    />
                }
            </div >
        }
    }

}


export default ChatConversation;