import Actions from "../actions";

const initialState = {
    isLoading: false,
    qrCodeScanner: [],
    error: false,
    trackError: false
}
const qrCode = (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.QR_CODE_SCANNER: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.QR_CODE_SCANNER_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                qrCodeScanner: action?.data?.response,
                error: false
            };
        }
        case Actions.QR_CODE_SCANNER_FAILURES: {
            return {
                ...state,
                isLoading: false,
                qrCodeScanner: [],
                error: action.data,
            };
        }
        default:
            return state;
    }
};

export default qrCode;

export const qrCodeScannerSelector = state => state?.QR_CODE_SCANNER?.qrCodeScanner;
export const qrCodeScannerLoading = state => state?.QR_CODE_SCANNER?.isLoading;

