import Actions from "../actions";

const initialState = {
    isLoading: false,
    rack: undefined,
    error: false,
}
const PlanogramDetailReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.PLANOGRAM_DETAIL: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.PLANOGRAM_DETAIL_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                rack: action?.data?.response || undefined,
                error: false
            };
        }
        case Actions.PLANOGRAM_DETAIL_FAILURE: {
            return {
                ...state,
                isLoading: false,
                rack: undefined,
                error: action.error,
            };
        }
        default:
            return state;
    }
};

export default PlanogramDetailReducer;

export const getPlanogramDetailSelector = state => state?.PLANOGRAM_DETAIL?.rack;
export const RackType = state => state?.PLANOGRAM_DETAIL?.rack?.rackType;
export const getPlanogramDetailLoading = state => state?.PLANOGRAM_DETAIL?.isLoading;