import { doAction, doResponseAction } from ".";

const INACTIVE_USERS = 'INACTIVE_USERS';
const INACTIVE_USERS_SUCCESS = 'INACTIVE_USERS_SUCCESS';
const INACTIVE_USERS_FAILURE = 'INACTIVE_USERS_FAILURE'

const CUSTOMER_UNBLOCKING_CREATE = 'CUSTOMER_UNBLOCKING_CREATE';
const CUSTOMER_UNBLOCKING_CREATE_SUCCESS = 'CUSTOMER_UNBLOCKING_CREATE_SUCCESS';
const CUSTOMER_UNBLOCKING_CREATE_FAILURE = 'CUSTOMER_UNBLOCKING_CREATE_FAILURE';



export {
    INACTIVE_USERS,
    INACTIVE_USERS_SUCCESS,
    INACTIVE_USERS_FAILURE,

    CUSTOMER_UNBLOCKING_CREATE,
    CUSTOMER_UNBLOCKING_CREATE_SUCCESS,
    CUSTOMER_UNBLOCKING_CREATE_FAILURE,

}

export const getInactiveUsersList = (params, callback) => doAction(INACTIVE_USERS, params, callback);
export const getInactiveUsersListListSuccess = (data) => doResponseAction(INACTIVE_USERS_SUCCESS, data);
export const getInactiveUsersListListFailure = (data) => doResponseAction(INACTIVE_USERS_FAILURE, data);

export const createCustomerUnBlockingList = (params, callback) => doAction(CUSTOMER_UNBLOCKING_CREATE, params, callback);
export const createCustomerUnBlockingSuccess = (data) => doResponseAction(CUSTOMER_UNBLOCKING_CREATE_SUCCESS, data);
export const createCustomerUnBlockingFailure = (data) => doResponseAction(CUSTOMER_UNBLOCKING_CREATE_FAILURE, data);


