import { doAction, doResponseAction } from "../";

const CV_CAMERA_LIST = 'CV_CAMERA_LIST';
const CV_CAMERA_LIST_SUCCESS = 'CV_CAMERA_LIST_SUCCESS';
const CV_CAMERA_LIST_FAILURE = 'CV_CAMERA_LIST_FAILURE';

const CV_PRODUCT_DETAILS = 'CV_PRODUCT_DETAILS';
const CV_PRODUCT_DETAILS_SUCCESS = 'CV_PRODUCT_DETAILS_SUCCESS';
const CV_PRODUCT_DETAILS_FAILURE = 'CV_PRODUCT_DETAILS_FAILURE';

export {
    CV_CAMERA_LIST,
    CV_CAMERA_LIST_SUCCESS,
    CV_CAMERA_LIST_FAILURE,
    CV_PRODUCT_DETAILS,
    CV_PRODUCT_DETAILS_SUCCESS,
    CV_PRODUCT_DETAILS_FAILURE
}
export const Cv_CameraListAction = (params, callback) => doAction(CV_CAMERA_LIST, params, callback);
export const Cv_CameraListSuccessAction = data => doResponseAction(CV_CAMERA_LIST_SUCCESS, data);
export const Cv_CameraListFailureAction = data => doResponseAction(CV_CAMERA_LIST_FAILURE, data);

export const Cv_Product_Details = (params, callback) => doAction(CV_PRODUCT_DETAILS, params, callback);
export const Cv_Product_Details_Success_Action = data => doResponseAction(CV_PRODUCT_DETAILS_SUCCESS, data);
export const Cv_Product_Details_Failure_Action = data => doResponseAction(CV_PRODUCT_DETAILS_FAILURE, data);