import Actions from "../actions";

const initialState = {
    isLoading: false,
    getSubscriptionList: [],
    createSubscriptionList: [],
    error: false,
    createLoading: false,
    subscriptionError: false
}

const SubscriptionReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.SUBSCRIPTION: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.SUBSCRIPTION_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                getSubscriptionList: action?.data?.response || [],
                error: false
            };

        }

        case Actions.SUBSCRIPTION_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                getSubscriptionList: [],
                error: action.error,
            };
        }
        case Actions.SUBSCRIPTION_CREATE: {
            return {
                ...state,
                createLoading: true,
            };
        }
        case Actions.SUBSCRIPTION_CREATE_SUCCESS: {
            return {
                ...state,
                createLoading: false,
                subscriptionError: false
            };

        }

        case Actions.SUBSCRIPTION_CREATE_FAILURE: {
            return {
                ...state,
                createLoading: false,
                createSubscriptionList: [],
                subscriptionError: action.error,
            };
        }

        default:
            return state;
    }
};

export default SubscriptionReducer;

export const getSubscriptionListSelector = state => state?.SUBSCRIPTION?.getSubscriptionList;
export const getSubscriptionListLoadingSelector = state => state?.SUBSCRIPTION?.isLoading;