import React from "react";
import AppDatePicker from "../../../components/fields/AppDatePicker";
import BoldError from "../../../components/fields/BoldError";
import Constants from "../../../utils/Constants";
import Close from '../../../../asset/new/img/modal-close.svg'

const DollarOfferModal = ({ cartGrossValue, manageProduct,
    validFrom,
    expiryOn,
    discount,
    setDiscount,
    setCartGrossValue,
    setValidFrom,
    setExpiryOn,
    setOpenDollarModal,
    isCreate,
    validationError,
    offerType, setOfferType,
    maximumDiscount, setMaximumDiscount,
    setFilterValidationError }) => {

    return (
        <React.Fragment>
            <div className="modal d-block"
                id="unblock-modal"
                tabIndex="-1"
                aria-labelledby="unblock"
                aria-hidden="true"><div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content rounded-0">
                        <img src={Close} alt="X" data-bs-dismiss="modal" aria-label="Close" className="modal-close" id="setOpenDollarModal" onClick={() => {
                            setOpenDollarModal(false)
                        }} />

                        <div className="modal-header rounded-0 align-items-center py-4">
                            <div className="col"> <h5 className="modal-title">{isCreate ? 'Add Dollar Offer' : 'Update Dollar Offer'}</h5> </div>
                        </div>
                        <div className="modal-body font13 p-40">
                            <div className="row mb-4">
                                <div className="col-6">
                                    <label for="offer-type" className="form-label fw-bold">Offer Type</label>
                                    <select id="offer-type" name="" className="form-select"
                                        onChange={(e) => setOfferType(e?.target?.value)}
                                        value={offerType}
                                    >
                                        <option value="">Select offer type</option>
                                        {
                                            Constants?.PRODUCT_DISCOUNTS.map((o) => {
                                                return (
                                                    <option key={''} value={o.value}>{o.key}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="col-6">
                                    <label for="" className="form-label fw-bold">Discount Value</label>
                                    <div className="input-group">
                                        {offerType === 'PERCENT' ? <span className="input-group-text border-dark">%</span> :
                                            <span className="input-group-text border-dark">$</span>}
                                        <input
                                            id="setDiscount"
                                            className="form-control"
                                            defaultValue={discount}
                                            placeholder="Enter Discount"
                                            type="number"
                                            onChange={(e) => setDiscount(e?.target?.value)} />
                                    </div>
                                </div>
                            </div>

                            {offerType === "PERCENT" && <div className="row mb-4">
                                <div className="col-6">
                                    <label for="" className="form-label fw-bold">Max Discount Value</label>
                                    <div className="input-group">
                                        <span className="input-group-text border-dark">$</span>
                                        <input id="setMaximumDiscount" type="text" className="form-control" defaultValue={maximumDiscount}
                                            placeholder="Enter Maximum discount"
                                            onChange={(e) => setMaximumDiscount(e?.target?.value)} />
                                    </div>
                                </div>
                            </div>}

                            <div className="row mb-4">
                                <div className="col-6">
                                    <label className="form-label fw-bold">Cart Value</label>
                                    <div className="input-group">
                                        <input
                                            id="setCartGrossValue"
                                            className="form-control"
                                            type="number"
                                            defaultValue={cartGrossValue}
                                            placeholder="Enter Cart Value"
                                            onChange={(e) => setCartGrossValue(e?.target?.value)} />
                                    </div>
                                </div>
                            </div>


                            <div className="row mb-5">
                                <div className="col-12 col-sm-6">
                                    <label for="from-date" className="form-label fw-bold">Valid From</label>
                                    <AppDatePicker
                                        id='setValidFrom'
                                        value={validFrom}
                                        selected={validFrom}
                                        minDate={new Date()}
                                        placeholder="Valid From"
                                        onChange={(date) => setValidFrom(date)}
                                    />
                                </div>
                                <div className="col-6">
                                    <label for="to-date" className="form-label fw-bold">Valid To</label>
                                    <AppDatePicker
                                        id='setExpiryOn'
                                        value={expiryOn}
                                        selected={expiryOn}
                                        minDate={validFrom ? validFrom : new Date()}
                                        placeholder="Valid To"
                                        onChange={(date) => setExpiryOn(date)}
                                    />
                                </div>
                            </div>
                            {validationError && <BoldError message={validationError} />}
                            <div className="row mb-4">
                                <div className="col-7 pe-0">
                                    <span  id="manageProduct" className="btn btn-success font14 w-100 p-3 cursor-pointer"
                                        onClick={() => manageProduct(isCreate)}>{isCreate ? 'Create' : 'Update'}
                                    </span>
                                </div>
                                <div className="col-5 text-end">
                                    <span id="Cancel" className="btn btn-outline-danger font14 w-100 p-3 cursor-pointer"
                                        onClick={() => setOpenDollarModal(false)}> Cancel
                                    </span>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <div className="modal-backdrop show"></div>
        </React.Fragment>)
};
export default DollarOfferModal;

