import React from "react";
import Close from '../../../../asset/new/img/modal-close.svg'
import { UTCDateFormat } from "../../../utils/commonFunctions/dateFormat";


const MultiBuyDetailModal = ({ isMultiBuyDetailModal, isCloseModal, multiBuyDetails }) => {
    return <>
        <div className="modal d-block"
            id="view-multi-modal"
            tabIndex="-1"
            aria-labelledby="view-multi-modal"
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content rounded-0">
                    <img id="close" src={Close} alt="X" data-bs-dismiss="modal" aria-label="Close" className="modal-close"
                        onClick={() => { isCloseModal(false) }}
                    />
                    <div className="modal-header rounded-0 align-items-center py-4">
                        <div className="col">
                            <h5 className="modal-title">Multi Buy Offer - ID #{multiBuyDetails?.id}</h5>
                        </div>
                    </div>
                    <div className="modal-body font14 p-40">
                        <div className="row mb-3">
                            <div className="col-4 fw-bold"> Product Name </div>
                            <div className="col-8 title-case"> {multiBuyDetails?.productName?.toLowerCase()} <small>(SKU {multiBuyDetails?.sku})</small></div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-4 fw-bold"> Offer Type </div>
                            <div className="col-8"> {multiBuyDetails?.offerType}</div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-4 fw-bold"> Offer Value </div>
                            <div className="col-8"> 
                            {multiBuyDetails?.offerType === "PERCENT" ? `${multiBuyDetails?.offerValue}%` : `$` + parseFloat(multiBuyDetails?.offerValue)?.toFixed(2)}
                          </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-4 fw-bold"> Quantity </div>
                            <div className="col-8"> {multiBuyDetails?.quantity} </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-4 fw-bold"> Valid From </div>
                            <div className="col-8"> {UTCDateFormat(multiBuyDetails?.startDateString)} </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-4 fw-bold">Valid To</div>
                            <div className="col-8"> {UTCDateFormat(multiBuyDetails?.endDateString)} </div>
                        </div>
                        <div className="row my-4">
                            <div className="col"> <span id="onClose" data-bs-dismiss="modal" aria-label="Close"
                                className="btn btn-dark font14 w-100 p-2" onClick={() => { isCloseModal(false) }}> Close</span> </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="modal-backdrop show"></div>
    </>
};

export default MultiBuyDetailModal;