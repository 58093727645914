import React, { useEffect, useState } from 'react';
import { getDaxbotLoadingSelector, getDaxbotSelector, trackDaxbotLoadingSelector } from '../../redux/reducers/GetDaxbotReducers';
import { useDispatch, useSelector } from 'react-redux';
import Actions from '../../redux/actions';
import AppMapView from '../../components/map/AppMapView';
import moment from 'moment';
import { toast } from 'react-toastify';
import AppLoader from "../../components/fields/AppLoader";

const convertLatLngToInt = (v) => v ? parseFloat(v) : null

const DaxbotStatus = () => {
    const dispatch = useDispatch();
    const getDaxbot = useSelector(getDaxbotSelector);
    const daxbotLoading = useSelector(getDaxbotLoadingSelector)
    const trackBotLoading = useSelector(trackDaxbotLoadingSelector)
    const [daxbot, setDaxbot] = useState({})
    const [onceSelect, setOnceSelect] = useState(false)
    useEffect(() => {
        dispatch(Actions.getDaxbotAction());
    }, [dispatch]);

    const daxbotCallback = (res) => {
        const { response } = res || {}
        if (res?.response_code === 0) {
            setDaxbot(response?.response)
        } else {
            toast.error(res?.response_message)
        }
    }

    const trackBot = (botOrderId, name) => {
        setOnceSelect(name)
        dispatch(Actions.trackDaxbotAction(botOrderId, daxbotCallback));
    }
    const centerLatLng = {
        lat: convertLatLngToInt(daxbot?.lat),
        lng: convertLatLngToInt(daxbot?.lon)
    }
    const botLatLng = {
        lat: convertLatLngToInt(daxbot?.bot_status?.location?.lat),
        lng: convertLatLngToInt(daxbot?.bot_status?.location?.lon)
    }
    const renderBotStatus = (botStatus) => {
        let orderBotStatus = ''
        if (botStatus === 'scheduled') {
            orderBotStatus = 'Requested';
        } else if (botStatus === 'confirmed') {
            orderBotStatus = 'Bot allocated';
        } else if (botStatus === 'active') {
            orderBotStatus = 'On the way';
        } else if (botStatus === 'arrived') {
            orderBotStatus = 'Arrived';
        } else if (botStatus === 'successful') {
            orderBotStatus = 'Delivered';
        } else if (botStatus === 'fail_delivery') {
            orderBotStatus = 'Delivery failed';
        } else if (botStatus === 'completed') {
            orderBotStatus = 'Reached Store';
        }
        return orderBotStatus
    }


    const { bot_status, status } = daxbot || {}

    return (<div className="col ps-lg-4" >
        {daxbotLoading && <AppLoader />}
        {trackBotLoading && <AppLoader />}
        <div className="row mb-4" >
            <div className="col" > <h1 className="font20 fw-bold" > Daxbot</h1 > </div >
        </div >
        <div className="row" >
            <div className="col-12 col-lg-2 border-right" >
                {/* <!-- CLICKING ON THESE BUTTONS HERE WILL UPDATE THE MAP AND INFO ON THR RIGHT OF THE PAGE, INSTEAD OF A NEW PAGE --> */}
                {getDaxbot && getDaxbot?.map(({ status, name }) => <button id='Track' className={`btn btn-outline-dark ${onceSelect === name ? 'active' : ''}  p-4 text-center mb-4 `} onClick={() => trackBot(status?.botOrderId, name)}>
                    <h2 className="font20 fw-bold mb-3" > {name} </h2 >
                    <p className="text-success mb-4" id='renderBotStatus'> {renderBotStatus(status?.botStatus)} </p >
                    Track
                </button >)}
            </div >
            <div className="col ps-md-0" >

                {
                    status === 'scheduled' &&
                    <div style={{
                        display: 'flex', justifyContent: 'center', alignItems: 'center',
                        fontWeight: 'bold', fontSize: '16px'
                    }}>Waiting for bot's acceptance.</div>}
                {
                    daxbot?.bot_id && <><AppMapView center={centerLatLng} botLatLng={botLatLng} />
                        {(bot_status && bot_status?.drawer_temp && bot_status?.speed) && <> <div className="row mb-2 text-start">
                            <div className="col">Drawer temperature</div>
                            <div className="col-auto text-end fw-bold"> {bot_status?.drawer_temp?.toFixed(2)} C</div>
                        </div >
                            <div className="row mb-2" >
                                <div className="col" > Running speed</div >
                                <div className="col-auto text-end fw-bold" > {(bot_status?.speed * 2.236936)?.toFixed(2)
                                } mph</div >
                            </div >
                            <div className="row mb-2" >
                                <div className="col" > Start time</div >
                                <div className="col-auto text-end fw-bold" > {moment(bot_status?.start_time).format('MM/DD/YYYY - hh:mm A')}</div >
                            </div >
                            <div className="row mb-2" >
                                <div className="col" > End time</div >
                                <div className="col-auto text-end fw-bold" > {moment(bot_status?.end_time).format('MM/DD/YYYY - hh:mm A')}</div >
                            </div ></>}</>
                }
            </div >
        </div >
    </div >)
}
export default DaxbotStatus;