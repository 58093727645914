import { all, put, select, takeLatest } from "redux-saga/effects";
import { doAuthGet } from "../../utils/service";
import END_POINTS from "../../utils/service/Endpoints";
import Actions from "../actions";

export function* getDashboardSaga({ params, callback }) {
    try {
        const response = yield doAuthGet(END_POINTS.STORE_LIST, params);
        callback && callback(response);
        if (response) {
            yield put(Actions.getDashboardDetailsActionSuccess(response.analytics));
            yield put(Actions.getStoreListSuccess(response.response));
        }
    }
    catch (error) {
        callback && callback(error);
        yield put(Actions.getDashboardDetailsActionFailure({ error }));
    }
    try {
        const response = yield doAuthGet(END_POINTS.GET_NOTIFICATION_LIST, params);
        const currentData = yield select(Actions.getNewNotifications);
        if (response) {
            if ((currentData?.newNotificationsDetails?.length !== response?.count) && (params.offset !== 0)) {
                const data = {
                    count: response?.count,
                    response: [
                        ...currentData.newNotificationsDetails,
                        ...response?.response
                    ]
                }
                yield put(Actions.getNewNotificationsDetailsActionSuccess(data))
            } else {
                yield put(Actions.getNewNotificationsDetailsActionSuccess(response))
            }
        }
    }
    catch (error) {
        yield put(Actions.getNewNotificationsDetailsActionFailure({ error }));
    }
}

export default function* getDashboardListener() {
    yield all([
        takeLatest(Actions.GET_DASHBOARD_DETAILS, getDashboardSaga),
    ]);
}
