import React, { useRef, useState, useEffect, useCallback } from 'react';
import Select from 'react-select';
import debounce from 'lodash.debounce';
import { useDispatch } from 'react-redux'
import Actions from '../../redux/actions';
import { COLORS } from '../../utils/Constants';


export const customSelectStyles = {
    menu: (provided, state) => ({
        ...provided,
    }),
    control: (provided, state) => ({
        ...provided,
        borderColor: COLORS.appThemeHex,
        boxShadow: null,
        display: 'flex',
        flex: 1,
        minWidth: '300px'
    }),
    singleValue: (provided) => ({
        ...provided,
    }),
    option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? 'white' : 'black',
        backgroundColor: state.isSelected ? COLORS.appThemeHex : 'white',
        zIndex: 999
    }),
    valueContainer: (provided, state) => ({
        ...provided,
    }),
};

const ProductsTypeAhead = ({
    productType,
    defaultOptions = null,
    placeholder = '',
    style = {},
    skuId = null,
    onChange = () => null,
    isMulti,
    ...props
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState(defaultOptions || []);
    const dispatch = useDispatch();

    const optionsRef = useRef()

    const searchApi = useCallback((value) => {
        const defaultFilter = {
            storeId: 1,
            page: 0,
            size: 100,
            searchKey: value ? value : ''
        };
        setIsLoading(true);
        let data
        if (optionsRef.current && skuId) {
            data = optionsRef.current?.filter((d) => d.value !== skuId)
            setOptions(data)
        }

        dispatch(Actions.getProductsListForSelectDropdownAction(defaultFilter, (resp) => {
            let data = [];
            if (resp?.products) {
                data = resp?.products.map(d => ({ label: d.productName?.toLowerCase(), value: d.sku, data: d }))
            }
            if (skuId) {
                data = data?.filter((d) => d.value !== skuId)
            }
            setIsLoading(false)
            setOptions(data)
            optionsRef.current = data
        }))

    }, [setIsLoading, setOptions, dispatch, skuId]);
    const searchBistroApi = useCallback((value) => {
        const defaultFilter = {
            storeId: 1,
            page: 0,
            size: 100,
            searchKey: value ? value : ''
        };
        setIsLoading(true);
        let data
        if (optionsRef.current && skuId) {
            data = optionsRef.current?.filter((d) => d.value !== skuId)
            setOptions(data)
        }
        dispatch(Actions.bistroProductListAction(defaultFilter, (resp) => {
            let data = [];
            if (resp?.response) {
                data = resp?.response.response.map(d => ({ label: d.productName?.toLowerCase(), value: d.sku, data: d }))
            }
            if (skuId) {
                data = data?.filter((d) => d.value !== skuId)
            }
            setIsLoading(false)
            setOptions(data)
            optionsRef.current = data
        }))

    }, [setIsLoading, setOptions, dispatch, skuId]);
    const debounceRef = useRef(debounce((value) => {
        if (productType === 'bistro') {
            searchBistroApi(value ? value : '');
        } else if (productType === 'grocery') {
            searchApi(value ? value : '');
        }
    }, 500));

    useEffect(() => {
        if (productType === 'bistro') {
            searchBistroApi();
        } else if (productType === 'grocery') {
            searchApi();
        }
    }, [dispatch])


    return <Select
        id="productstypeahead"
        className="title-case"
        {...props}
        // styles={customSelectStyles}
        placeholder={placeholder}
        isLoading={isLoading}
        isClearable={true}
        isSearchable={true}
        onInputChange={(value) => debounceRef.current(value)}
        options={options}
        isMulti={isMulti}
        onChange={(value) => onChange(value || {})}
    />
}



export default ProductsTypeAhead;