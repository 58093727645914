import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AppButton from '../../components/fields/AppButton';
import BoldError from '../../components/fields/BoldError';
import InputField from '../../components/fields/InputField';
import Actions from '../../redux/actions';
import { history } from '../../redux/store';
import { isValidEmail } from '../../utils/commonFunctions/Validations';
import RoutePath from '../../utils/routes/RoutePath';
import ForgotPassword from './Forgotpassword'
import { currentScreenPath, getLoginLoadingSelector } from '../../redux/reducers/LoginReducer';
import ResetPassword from "./ResetPassword";
import Logo from '../../../asset/images/proglint.png' //TODO LOGIN_LOGO_CHANGE
import Constants from '../../utils/Constants';
import Footer from '../../../asset/images/proglint.svg' //TODO LOGIN_FOOTER_LOGO_CHANGE
import AppLoader from '../../components/fields/AppLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const LoginScreen = ({ children }) => {
    const [emailId, setEmailId] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [forgetModal, setForgotModal] = useState(false)
    const currentScreen = useSelector(currentScreenPath);
    const isLoading = useSelector(getLoginLoadingSelector);
    // const [isVerifyOtpModal, setisVerifyOtpModal] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    // const [forgotEmail, setforgotEmail] = useState("")
    const dispatch = useDispatch();
    const onLoginClick = () => {
        let error = ''
        if (emailId.trim() === '') {
            error = 'Please enter Email Id.'
        } else if (!isValidEmail(emailId)) {
            error = 'Email Id not valid';
        } else if (password.trim() === '') {
            error = 'Please enter password.';
        } else if (password.length < 5) {
            error = 'Password must be more than 5 characters';
        }

        if (error !== '') {
            setError(error);
            return;
        }

        dispatch(Actions.loginAction({ userName: emailId, password }, (resp) => {
            if (resp.response_code && resp.response_code !== 0) {
                setError(resp.response_message);
                return;
            }
            const { customer } = resp || {}
            if (customer && customer?.roleId === Constants.ROLES.BACK_KITCHEN) {
                history.replace(RoutePath.BISTRO_ORDERS);
            } else if (customer && customer?.roleId === Constants.ROLES.BISTRO_DISPLAY) {
                history.replace(RoutePath.BISTRO_CUSTOMER_ORDERS);
            } else {
                history.replace(RoutePath.DASHBOARD_SCREEN_PATH);
            }

        }));
    }

    const openForgetPage = () => {
        dispatch(Actions.updateCurrentScreen('forget'))
    }
    const onCloseModal = () => {
        setForgotModal(false)
    }
    const handleNextKeyUp = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            onLoginClick()
        }
    }
    return (
        <>
            <div id="header-shadow" className="border-bottom shadow-sm">
                <div className="wrap">
                    <header id="header">
                        <div className="">
                            <div className="text-center">
                                <span><img src={Logo} alt="Proglint" className="login_logo" /></span> 
                            </div>
                        </div>
                    </header>
                </div>
            </div>
            <div className="wrap my-5">
                {(currentScreen === 'Login' || !currentScreen) && <div className="row">
                    <div className="col-12 col-lg-5 m-auto">
                        <div className="card card-body p-50">
                            <div className="row align-items-center mb-5">
                                <div className="col">
                                    <h2 className="font28 fw-bold">Login </h2>
                                </div>
                            </div>
                            <BoldError message={error} />
                            <div className="row align-items-center mb-4">
                                <div className="col">
                                    <InputField
                                        defaultValue={emailId}
                                        placeholder="Email Id"
                                        onChange={value => setEmailId(value)} />
                                </div>
                            </div>
                            <div className="input-group mb-4">
                                <input
                                    id='setPassword'
                                    className="form-control"
                                    type={showPassword ? 'text' : 'password'}
                                    name="password"
                                    onChange={(e) => setPassword(e?.target?.value)}
                                    onKeyUp={(event) => handleNextKeyUp(event)}
                                    placeholder="Password"
                                    aria-describedby="view-pass" />
                                <button className="btn border-dark"
                                    onClick={() => setShowPassword(!showPassword)}
                                    type="button"
                                    id="view-pass">
                                    <FontAwesomeIcon
                                        style={{ cursor: 'pointer' }}
                                        icon={showPassword ? faEye : faEyeSlash} />
                                </button>
                            </div>
                            <div className="row mb-4">
                                <div
                                    style={{ cursor: 'pointer' }}
                                    className="col text-end">
                                    <span id='OpenForget' className="text-dark"
                                        onClick={() => openForgetPage()}
                                    >Forgot Password?</span>
                                </div>
                            </div>
                            <div className="text-center pt-4">
                                <AppButton id='onLoginClick' title="Login" onClick={onLoginClick} />
                            </div>

                        </div>

                    </div>
                </div>}
                {currentScreen === 'forget' && <ForgotPassword
                    isModalOpen={forgetModal}
                    onCloseModal={onCloseModal}
                    successEmail={(forgotEmail) => { }}
                />}
                {currentScreen === 'reset' && <ResetPassword />}
            </div>
            <footer id="footer" className="bg-cream">
                <div className="wrap">
                    <div className="text-center">
                        <img src={Footer} alt="N&B Logo" className="footer-logo mb-4" />
                        <div
                            className="font-weight-normal">
                            {Constants.FOOTER_TEXT.text}
                        </div>
                    </div>
                </div>
            </footer>
            {isLoading && <AppLoader />}
        </>
    );
};

export default LoginScreen;