import React from "react";
import { withRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import ModalPopup, { ModalHeader } from "./modal";
import InputField from "./fields/InputField";
import Constants from "../utils/Constants";
import AppLoader from "./fields/AppLoader";

class CreateShelfModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {
                bays: [{
                    products: undefined,
                    bayName: '',
                    bayWidth: ''
                }]
            },
            error: {
                bays: [],
                bay: ''
            },
            width: '',
        };
    }
    componentDidMount() {
        const { isEdit, shelve: { bays = [] } = {} } = this.props;
        if (isEdit)
            this.setState({ fields: { bays: JSON.parse(JSON.stringify(bays)) } });
    }

    removeBays = (i) => {
        const { fields, fields: { bays } } = this.state;
        bays.splice(i, 1)
        this.setState({
            fields: {
                ...fields,
                bays,
            }
        })
    }

    addBays = () => {
        const { fields = {} } = this.state;
        fields.bays.push({
            products: undefined,
            bayName: '',
            bayWidth: ''
        })
        this.setState({ fields })
    }

    validateSingleBays = (name, i,) => {
        let { error, fields: { bays = [] } } = this.state;
        let isFailed = false;
        switch (name) {
            case 'bayName':
                if (!bays[i][name]) {
                    if (error.bays[i])
                        error.bays[i][name] = 'Bay Name is Mandatory';
                    else
                        error.bays[i] = { bayName: 'Bay Name is Mandatory' };
                    isFailed = true
                } else {
                    if (error.bays[i])
                        error.bays[i][name] = '';
                    else
                        error.bays[i] = { bayName: '' };
                }
                break;
            case 'bayWidth':
                if (!bays[i][name]) {
                    if (error.bays[i])
                        error.bays[i][name] = 'Bay Width is Mandatory';
                    else
                        error.bays[i] = { bayWidth: 'Bay Width is Mandatory' };
                    isFailed = true
                } else {
                    if (error.bays[i])
                        error.bays[i][name] = '';
                    else
                        error.bays[i] = { bayWidth: '' };
                }
                break;

        }
        error['bay'] = ''

        this.setState({ error })
        return isFailed
    }

    validateWholeBays = () => {
        let { error, fields: { bays = [] } } = this.state;
        const { width } = this.props;
        let isFailed = false;
        bays.forEach((a, i) => {
            if (!a['bayName']) {
                if (error.bays[i])
                    error.bays[i]['bayName'] = 'Bay Name is Mandatory';
                else
                    error.bays[i] = { bayName: 'Bay Name is Mandatory' };
                isFailed = true
            } else {
                if (error.bays[i])
                    error.bays[i]['bayName'] = '';
                else
                    error.bays[i] = { bayName: '' };
            }
            if (!a['bayWidth']) {
                if (error.bays[i])
                    error.bays[i]['bayWidth'] = 'Bay Width is Mandatory';
                else
                    error.bays[i] = { bayWidth: 'Bay Width is Mandatory' };
                isFailed = true
            } else {
                if (error.bays[i])
                    error.bays[i]['bayWidth'] = '';
                else
                    error.bays[i] = { bayWidth: '' };
            }
        })
        const widthValue = bays.reduce((pVal, cVal) => {
            return pVal + parseInt(cVal.bayWidth)
        }, 0);
        if (widthValue > width) {
            error['bay'] = `Consolidated bay width should be below ${width}${Constants.PLANOGRAM.UNIT}`;
            isFailed = true
        }

        this.setState({ error })
        return isFailed
    }

    handleChangeBays = (value, name, i) => {
        const { fields, fields: { bays } } = this.state;
        bays[i][name] = value
        this.setState({
            fields: {
                ...fields,
                bays,
            }
        }, () => this.validateSingleBays(name, i))
    }

    onSubmit = () => {
        const { onSubmitForm } = this.props;
        const { fields } = this.state;
        if (this.validateWholeBays()) {
            return false;
        }
        onSubmitForm(fields)
    }

    getRemainingBayWidth = () => {
        const { fields: { bays = [] } } = this.state;
        const { width } = this.props;
        const widthValue = bays.reduce((pVal, cVal) => {
            return pVal + (parseInt(cVal.bayWidth) ? parseInt(cVal.bayWidth) : 0)
        }, 0);
        return width - widthValue;
    }

    render() {
        const { isModalOpen, onCloseModal, width, isLoading } = this.props;
        const { fields, error } = this.state;
        return (
            <React.Fragment>
                {isModalOpen && <ModalPopup className="modal-lg" onCloseModal={onCloseModal}>
                    <ModalHeader title="Configure Bay" />
                    <div className="row mb-4">
                        <div className="col">
                            <div className="row">
                                <div className="col"> <p className="fw-bold">Overall Bay Width: {width ? `${width}${Constants.PLANOGRAM.UNIT}` : `-`}</p> </div>
                                <div className="col"> <p className="fw-bold">Remaining Bay Width: {this.getRemainingBayWidth() ? `${this.getRemainingBayWidth()}${Constants.PLANOGRAM.UNIT}` : `0cm`}</p> </div>
                                <div className="col text-end">
                                    <button
                                        id="addcombooffer"
                                        name=""
                                        data-bs-toggle="modal"
                                        data-bs-target="#add-dollar-modal"
                                        className="btn btn-outline-dark btn-sm"
                                        onClick={() => this.addBays()}>+ Add
                                    </button>
                                </div>
                            </div>
                            {error.bay && <p style={{ color: 'red', textAlign: 'center', width: '100%' }}>{error.bay}</p>}
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Bay Name</th>
                                        <th>Width(in {Constants.PLANOGRAM.UNIT})</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {fields.bays.map((o, i) => {
                                        return (<tr className="text-center" key={i}>
                                            <td>
                                                <InputField
                                                    id="bayName"
                                                    style={{ marginTop: '10px' }}
                                                    placeholder="Bay Name"
                                                    value={o['bayName']}
                                                    name="bayName"
                                                    onChange={(value, name) => this.handleChangeBays(value, name, i)}
                                                    error={error?.bays[i]?.bayName}
                                                /></td>
                                            <td><InputField
                                                id="bayWidth"
                                                type={'number'}
                                                style={{ marginTop: '10px' }}
                                                placeholder="Bay Width"
                                                value={o['bayWidth']}
                                                name="bayWidth"
                                                onChange={(value, name) => this.handleChangeBays(value, name, i)}
                                                error={error?.bays[i]?.bayWidth}
                                            /></td>
                                            <td><div id="removeBays" className="c-pointer" onClick={() => this.removeBays(i)}><span className="fa fa-trash" ></span></div></td>
                                        </tr>)
                                    })}
                                    {fields.bays.length === 0 && <tr><td className="text-center" colSpan={3}>No Bays added yet</td></tr>}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col">
                            <button
                                id="onCloseModal"
                                type="button"
                                className="btn btn-outline-dark big-btn w-100 px-5 text-uppercase"
                                onClick={() => onCloseModal()}
                            >
                                Cancel
                            </button>
                        </div>
                        <div className="col">
                            <button
                                id="onSubmit"
                                type="button"
                                className="btn btn-dark big-btn w-100 px-5 text-uppercase"
                                onClick={() => this.onSubmit()}
                            >
                                Proceed
                            </button>
                        </div>
                    </div>
                    {isLoading && <AppLoader />}
                </ModalPopup>
                }
            </React.Fragment>
        );
    }
}

export const mapStateToProps = (state) => {
    return {
    };
};

export default withRouter(connect(mapStateToProps, {})(CreateShelfModal));