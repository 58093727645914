
import { all } from 'redux-saga/effects'
import credentialsListener from './ClientCredentialsSaga';
import loginSagaListener from './LoginSaga';
import ordersListener from './OrderSaga';
import productsListener from './ProductsSaga';
import foodProductsistener from './FoodProductsSaga';
import refundsListener from './RefundsSaga';
import comboOfferListener from './ComboOfferSaga';
import forgotPassword from './ForgotPassword';
import DollarOfferListener from './DollarOfferSaga';
import OfferListListener from './offerListSaga';
import multiBuySaga from './MultiBuySaga';
import freeItemsListener from './FreeItemListSaga';
import customerCouponListener from './CustomerCouponSaga'
import happyHoursItemListener from './HappyHoursItemSaga';
import chatsListener from './ChatsSaga';
import storeListener from './StoreSaga';
import PromotionDashboardOfferListListener from './PromotionDashboardSaga';
import reportTypeListListener from './ReportTypeListSaga';
import getDashboardListener from './GetDashboardDetailsSaga'
import customerUnBlockingListener from './CustomerUnBlockingSaga'
import getNotificationsListener from './NotificationsSaga';
import subscriptionListener from './SubscriptionSaga';
import getDaxbotListener from './GetDaxbotSaga';
import bistroProductListListner from './BistroProductListSaga';
import invoiceDetailsListener from './InvoiceDetailsSaga';
import productGroupListner from './ProductGroupInformationsSaga';
import bistroDisplayListner from './BistroDisplaySaga';
import changePasswordtListner from './ChangePasswordSaga';
import bistroChefListner from './bistrochef/BistroChefSaga';
import invoiceListListner from './InvoiceListSaga';
import HitachiMonitoringListner from './HitachiMonitoringSaga'
import QrCodeAWatcher from './QrCodeSaga';
import StoreAvailabilityListener from './StoreAvailabilitySaga';
import branchListListener from './BranchListSaga';
import PlanogramListener from './PlanogramSaga';
import getCameraListListner from './planogramCv/GetCameraListSaga'
import getCvPlanogramDetailsListner from './planogramCv/GetPlanogramDetailsSaga'
import rackCaptureListner from './planogramCv/RackCaptureSaga'
import autoFillListner from './planogramCv/AutoFillSaga'
import updatePlanogramProductListner from './planogramCv/UpdatePlanogramProductSaga'
export default function* sagaWatchers() {
        yield all([
                loginSagaListener(),
                credentialsListener(),
                ordersListener(),
                productsListener(),
                foodProductsistener(),
                refundsListener(),
                forgotPassword(),
                comboOfferListener(),
                DollarOfferListener(),
                OfferListListener(),
                multiBuySaga(),
                customerCouponListener(),
                freeItemsListener(),
                happyHoursItemListener(),
                chatsListener(),
                storeListener(),
                PromotionDashboardOfferListListener(),
                getDashboardListener(),
                reportTypeListListener(),
                customerUnBlockingListener(),
                getNotificationsListener(),
                subscriptionListener(),
                getDaxbotListener(),
                bistroProductListListner(),
                invoiceDetailsListener(),
                productGroupListner(),
                bistroDisplayListner(),
                changePasswordtListner(),
                bistroChefListner(),
                invoiceListListner(),
                HitachiMonitoringListner(),
                QrCodeAWatcher(),
                StoreAvailabilityListener(),
                branchListListener(),
                PlanogramListener(),
                getCameraListListner(),
                getCvPlanogramDetailsListner(),
                rackCaptureListner(),
                autoFillListner(),
                updatePlanogramProductListner(),
        ]);
}