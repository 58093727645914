import React from 'react'
import { withRouter } from 'react-router-dom'
import Close from '../../../asset/new/img/modal-close.svg'
import AppLoader from '../../components/fields/AppLoader'
class PartialOrderPopUp extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    render() {
        const { productArray, onSuccess, onFailure, isLoading, handlePartialReason, isSelectedAll, errorMessage } = this.props
        return (
            <React.Fragment>
                <div className="modal d-block"
                    id="unblock-modal"
                    tabIndex="-1"
                    aria-labelledby="unblock"
                    aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-body p-50">
                                <img
                                    id='close'
                                    src={Close}
                                    alt="X"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    className="modal-close"
                                    onClick={() => onFailure()} />
                                <form>
                                    <div
                                        className="row mb-4">
                                        <div className="col">
                                            <h3 className="font18 fw-bold mb-3"> SELECTED PRODUCTS</h3>
                                            <p>{productArray && productArray?.map((o, i) => {
                                                return (
                                                    <div className='title-case' key={i}>{o?.name?.toLowerCase()} x {o?.quantity}</div>
                                                )
                                            })}</p>
                                        </div>
                                    </div>
                                    {
                                        isSelectedAll && <div
                                            className="row mb-4">
                                            <div className="col">
                                                <textarea
                                                    id='textReason'
                                                    type="textarea"
                                                    className="form-control"
                                                    placeholder="Type the reason here"
                                                    onChange={(e) => handlePartialReason(e)}
                                                />
                                            </div>
                                            {errorMessage && <p className='errorMsg'>{errorMessage}</p>}
                                        </div>
                                    }

                                    <div className="row mt-5">
                                        <div
                                            id="onClickSuccess" className="col pe-0" onClick={() => onSuccess()}>
                                            <span className="btn btn-success font14 w-100 p-3">Confirm</span> </div>
                                        <div
                                            id="onClickFailure" className="col text-end" onClick={() => onFailure()}>
                                            <span className="btn btn-outline-danger font14 w-100 p-3">Cancel</span> </div>
                                    </div>

                                </form>
                            </div>

                        </div>
                    </div>
                </div>
                {isLoading && <AppLoader />}
                <div className="modal-backdrop show"></div>

            </React.Fragment>
        )
    }
}

export default withRouter(PartialOrderPopUp)