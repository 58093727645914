import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import EmptyPage from '../../components/emptyPage';
import AppLoader from '../../components/fields/AppLoader';
import Actions from '../../redux/actions';
import { newNotificationsCount, newNotificationsSelector, resolvedNotificationsSelector, resolvedNotificationsCount, notificationsLoadingSelector } from '../../redux/reducers/NotificationsReducer';
import Alert from '../../components/alert/alert';
import { ShowDateMoment } from '../../utils/commonComponents/showDate';
import RoutePath from '../../utils/routes/RoutePath';
import { history } from '../../redux/store';
import { BASE_URL } from '../../utils/service/Endpoints';
import placeholderImg from '../../../asset/images/placeholder.jpg';
import { getCookie, isJSON } from '../../utils/commonFunctions/cookie';
import { getSelectedStoreSelector } from '../../redux/reducers/StoreReducer';
import OutOfStock from '../../../asset/images/oos.png';
import PartialOutOfStock from '../../../asset/images/pos.png';
import MisPlaced from '../../../asset/images/misplaced.png';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModalPopup from '../../components/modal';

export const defaultOrderFilters = {
    offset: 0,
    limit: 10,
}

const NotificationScreen = (props) => {
    const newNotificationsDetails = useSelector(newNotificationsSelector);
    const newNotificationCount = useSelector(newNotificationsCount)
    const resolvedNotificationCount = useSelector(resolvedNotificationsCount)
    const isLoading = useSelector(notificationsLoadingSelector);
    const resolvedNotificationsDetails = useSelector(resolvedNotificationsSelector);
    const dispatch = useDispatch();
    const [tabSelector, setTabSelector] = useState(0);
    const [notificationModal, setNotificationModal] = useState(false)
    const [notificationId, setNotificationId] = useState('')
    const [isNilgirisUser, setIsNilgirisUser] = useState(false)
    const storeId = useSelector(getSelectedStoreSelector)?.id;
    const [showImage, setShowImage] = useState(false);
    const [imagePath, setImagePath] = useState();


    const actionType = (actions) => {
        switch (actions) {
            case 1:
                return 'HIGH';
            case 2:
                return 'MED';
            default:
                return 'LOW';
        }
    }
    const actionColor = (color) => {
        switch (color) {
            case 1:
                return '#cd2d2d';
            case 2:
                return '#333';
            default:
                return '#878787';
        }
    }
    const LoadMoreResolved = (page) => {

        const notificationParams = {
            limit: 5,
            offset: page - 1,
            isResolved: 1
        }

        dispatch(Actions.getResolvedNotificationsDetailsAction(notificationParams));
    }
    const LoadMoreNew = (page) => {
        const notificationParams = {
            limit: 5,
            offset: page,
            isResolved: 0
        }

        dispatch(Actions.getNewNotificationsDetailsAction(notificationParams));
    }
    const showMessage = (response) => {
        if (response?.response_code === 0) {
            toast.success('Notification resolved successfully', { position: toast.POSITION.TOP_RIGHT })
        } else {
            toast.error('Error while resolving notification', { position: toast.POSITION.TOP_RIGHT })
        }

    }
    const resolvedUpdate = (o) => {
        if (o?.notifiedBy === 5) {
            history.push(RoutePath.planogramView(o?.rackid))
        } else {
            setNotificationId(o?.id)
            if (notificationModal && notificationId) {
                const data = {
                    "notificationId": notificationId
                };
                dispatch(Actions.updateNotificationResolvedStatus(data, showMessage));
                setNotificationModal(false)
            } else {
                setNotificationModal(true)
            }
        }

    };

    const handleImagePath = (path) =>{
    setShowImage(true); 
    setImagePath(path)
    }
    useEffect(() => {
        const tokenDetails = isJSON(getCookie('token')) ? JSON.parse(getCookie('token')) : undefined
        setIsNilgirisUser(tokenDetails?.customer?.nilgirisUser);
    }, [])

    const getNotifyName = (notificationDetails) => {
        if (notificationDetails.notifiedBy === 1) {
            return 'INTERNAL'
        } else if (notificationDetails.notifiedBy === 2) {
            return 'VISION_CHECKOUT'
        } else if (notificationDetails.notifiedBy === 3) {
            return 'WIWO'
        } else if (notificationDetails.notifiedBy === 4) {
            return 'COLD TRUTH'
        } else if (notificationDetails?.notifiedBy === 5) {
            return 'PLANOGRAM'
        } else {
            return 'GENERAL'
        }
    };


    useEffect(() => {
        const notificationParams = {
            limit: 5,
            offset: 0,
            isResolved: 0
        }
        dispatch(Actions.getNewNotificationsDetailsAction(notificationParams));
    }, [dispatch]);

    const onStatusTypeChange = (id) => {
        setTabSelector(id)
        const notificationParams = {
            limit: 6,
            offset: 0,
            isResolved: id === 0 ? 0 : 1
        }
        if (id === 0) {
            dispatch(Actions.getNewNotificationsDetailsAction(notificationParams));
        } else {
            dispatch(Actions.getResolvedNotificationsDetailsAction(notificationParams));
        }
    }
    return <div className="col ps-lg-4">
        {isLoading && <AppLoader />}
        <div className="row mb-3">
            <div className="col"> <h1 className="font20 fw-bold">Notifications</h1> </div>
        </div>
        <div id="full-notifications" className="card card-body rounded-3 border-0 shadow p-4 mb-3 mb-xl-0 Notification-Scroll">
            <nav>
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                    <button className={`nav-link ${tabSelector === 0 ? 'active' : ''}`} id="new-tab" data-bs-toggle="tab" data-bs-target="#new-panel" type="button" role="tab" aria-controls="nav-home" aria-selected="true" onClick={() => onStatusTypeChange(0)}>New</button>
                    <button className={`nav-link ${tabSelector === 0 ? '' : 'active'}`} id="resolved-tab" data-bs-toggle="tab" data-bs-target="#resolved-panel" type="button" role="tab" aria-controls="nav-profile" aria-selected="false" onClick={() => onStatusTypeChange(1)}>Resolved</button>
                </div>
            </nav>
            <div className="tab-content" id="nav-tabContent">
                {tabSelector === 0 ? <div className="tab-pane fade show active" id="new-panel" role="tabpanel" aria-labelledby="new-tab">
                    {newNotificationsDetails?.length > 0 ? <>
                        {newNotificationsDetails?.map((o) => {
                            return <><div id='resolvedCheck' className="row align-items-center cursor-pointer" >
                                {isNilgirisUser && <div className="col-2" onClick={() => resolvedUpdate(o)}>
                                    <img src={o.outOfStock ? OutOfStock : o.partiallyOutOfStock ? PartialOutOfStock : o.misplaced ? MisPlaced : placeholderImg} className='placeholderImg' />
                                </div>}
                                <div className={`${isNilgirisUser ? 'col-7' : 'col-auto'}`} onClick={() => resolvedUpdate(o)}>
                                    <h5 className="font12 fw-bold mb-2">{o?.title}</h5>
                                    <p className="font14 mb-2">{o?.description}</p>
                                    <h4 className="font12 fw-bold ">{getNotifyName(o)}</h4>
                                </div>
                                {isNilgirisUser && <div className=' col-1 font12 fw-bold text-end'>
                                    <FontAwesomeIcon
                                        onClick={() =>  handleImagePath(o?.imagePath)}
                                        style={{ cursor: 'pointer',fontSize: '2em' }}
                                        icon={faEye} />
                                </div>}
                                <div className={`${isNilgirisUser ? 'col-2' : 'col'} font12 fw-bold text-end`} onClick={() => resolvedUpdate(o)}>
                                    <h4 className="font12 fw-bold"
                                        style={{ color: actionColor(o?.actionType) }}
                                    >{actionType(o?.actionType)}</h4>
                                    <br />
                                    <h4 className="font12 fw-bold">{ShowDateMoment(o?.lastModified)}</h4>
                                </div>
                            </div>
                                <hr className="bg-ltgrey-1 mb-4" /></>
                        })}
                        {
                            (!isLoading && newNotificationsDetails && newNotificationsDetails.length !== newNotificationCount)
                            && <div className="text-center" id='LoadMore'
                                onClick={() => LoadMoreNew(newNotificationsDetails && newNotificationsDetails.length)}>
                                <button className='btn btn-dark p-2 px-5 text-uppercase'>Load more</button>
                            </div>
                        }
                    </> : <EmptyPage
                        content="You don't have any notifications currently"
                    />}
                </div>
                    :
                    <div className="tab-content" id="new-panel" role="tabpanel" aria-labelledby="new-tab">
                        {resolvedNotificationsDetails.length > 0 ? <>
                            {resolvedNotificationsDetails.map((o) => {
                                return (<>
                                    <div className="row align-items-center">
                                        {isNilgirisUser && <div className="col-2">
                                            <img src={o.outOfStock ? OutOfStock : o.partiallyOutOfStock ? PartialOutOfStock : o.misplaced ? MisPlaced : placeholderImg} className='placeholderImg' />
                                        </div>}
                                        <div className={`${isNilgirisUser ? 'col-7' : 'col-auto'}`}>
                                            <h5 className="font12 fw-bold mb-2">{o?.title}</h5>
                                            <p className="font14 mb-2">{o?.description}</p>
                                            <h4 className="font12 fw-bold">{getNotifyName(o)}</h4>
                                        </div>
                                        {isNilgirisUser && <div className=' col-1 font12 fw-bold text-end'>
                                    <FontAwesomeIcon
                                        onClick={() => handleImagePath(o?.imagePath)}
                                        style={{ cursor: 'pointer',fontSize: '2em'}}
                                        icon={faEye} />
                                </div>}
                                        <div className={`${isNilgirisUser ? 'col-2' : 'col'} font12 fw-bold text-end`}>
                                            <h4 className="font12 fw-bold"
                                                style={{ color: actionColor(o?.actionType) }}
                                            >{actionType(o?.actionType)}</h4>
                                            <br />
                                            <h4 className="font12 fw-bold">{ShowDateMoment(o?.lastModified)}</h4>
                                        </div>
                                    </div>
                                    <hr className="bg-ltgrey-1 mb-4"></hr></>
                                )
                            })}
                            {
                                (!isLoading && resolvedNotificationsDetails && resolvedNotificationsDetails.length < resolvedNotificationCount)
                                && <div className="text-center" id="LoadClick"
                                    onClick={() => LoadMoreResolved(resolvedNotificationsDetails && resolvedNotificationsDetails.length)}>
                                    <button className='btn btn-dark p-2 px-5 text-uppercase'>Load more</button>
                                </div>
                            }
                        </> : <EmptyPage
                            content="You don't have any notifications currently"
                        />}
                    </div>}
                {notificationModal &&
                    <Alert
                        id="infoAlert"
                        title="Info"
                        cancel='Cancel'
                        proceed='Resolve'
                        description='Do you want to resolve this notifcation?'
                        onSuccess={() => resolvedUpdate()}
                        onFailure={() => setNotificationModal(false)}
                    />}
            </div>
        </div>
        {showImage && <ModalPopup className="modal-lg" onCloseModal={() => setShowImage(false)}>
            <div className="modal-container">
                    <div>
                        <img src={imagePath ? `${BASE_URL}/resource/planogram/notify_images/${storeId}/${imagePath}` : placeholderImg} className='img_center_popup' />
                    </div>
            </div>
        </ModalPopup>}
    </div>
};


export default NotificationScreen;