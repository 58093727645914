import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from "react-redux";
import Actions from "../../../src/app/redux/actions";
import Constants from '../../app/utils/Constants';
import OrderSummaryOfferDetails from '../../app/containers/order/OrderSummaryOfferDetails';
import InvoiceSummary from '../../app/containers/order/InvoiceSummary';
import Close from '../../asset/new/img/modal-close.svg'
import Pending from '../../asset/new/img/pending.svg'
import Delivered from '../../asset/new/img/green-tick.svg'
import Declined from '../../asset/new/img/red-cross.svg'
import GreyCircle from '../../asset/new/img/grey-circle.svg'
import moment from 'moment';
import AppLoader from './fields/AppLoader';
import ManualRefundModal from '../containers/invoiceList/ManualRefund';
import RefundDetails from '../containers/refund/RefundDetails';

export const SortCheckoutOrders = (a, b) => {
    if (a.type < b.type) {
        return -1;
    }
    if (a.type > b.type) {
        return 1;
    }
    return 0;
}


export const reduceCheckourOrders = (previousValue, currentValue) => {
    if (previousValue.length === 0) {
        if (currentValue.orderType === 1) {
            currentValue.sectionHeader = Constants.FOOTER.bistro;
        } else if (currentValue.orderType === 2) {
            currentValue.sectionHeader = Constants.FOOTER.groceries;
        }
        return [...previousValue, currentValue];
    }

    if (previousValue[previousValue.length - 1].orderType === currentValue.orderType) {
        return [...previousValue, currentValue];
    } else if (previousValue[previousValue.length - 1].orderType !== currentValue.orderType) {
        if (currentValue.orderType === 1) {
            currentValue.sectionHeader = Constants.FOOTER.bistro;
        } else if (currentValue.orderType === 2) {
            currentValue.sectionHeader = Constants.FOOTER.groceries;
        }
        return [...previousValue, currentValue];
    }
};

class ViewInvoiceModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isManualRefund: false,
            refundableAmount: null,
            amountToRefund: null,
            errorMessage: null,
            comment: null,
            isManualRefundLoading: false,
            isHideViewReceipt: false
        }
    }
    componentDidMount() {
        const { ViewInvoiceDetailsId } = this.props;
        const options = {
            invoiceId: ViewInvoiceDetailsId
        }
        this.props.invoiceDetails(options)
    }
    renderOrderStatus(paymentStatus) {
        if (paymentStatus === 0) {
            return <div className="col-auto text-end font14 text-success">
                <img src={Delivered} alt="in progress" className="me-1" /> {Constants.ORDER_SUMMARY.paymentType.completed}
            </div>
        } else if (paymentStatus === 1) {
            return <div className="col-auto text-end font14 text-warning">
                <img src={Pending} alt="in progress" className="me-1" />{Constants.ORDER_SUMMARY.paymentType.pending}
            </div>
        } else if (paymentStatus === 2) {
            return <div className="col-auto text-end font14 text-warning">
                <img src="img/pending.svg" alt="in progress" className="me-1" /> {Constants.ORDER_SUMMARY.paymentType.processing}
            </div>
        } else if (paymentStatus === 3) {
            return <div className="col-auto text-end font14 text-danger">
                <img src={Declined} alt="in progress" className="me-1" />{Constants.ORDER_SUMMARY.paymentType.failed}
            </div>
        } else if (paymentStatus === 4) {
            return <div className="col-auto text-end font14 text-danger">
                <img src={Declined} alt="in progress" className="me-1" />{Constants.ORDER_SUMMARY.paymentType.pendingFailed}
            </div>
        } else if (paymentStatus === 11) {
            return <div className="col-auto text-end font14 text-warning">
                <img src={Pending} alt="in progress" className="me-1" /> {Constants.ORDER_SUMMARY.paymentType.refundInitiated}
            </div>
        } else if (paymentStatus === 12) {
            return <div className="col-auto text-end font14 text-success">
                <img src={Delivered} alt="in progress" className="me-1" />{Constants.ORDER_SUMMARY.paymentType.refundApproved}
            </div>
        } else if (paymentStatus === 13) {
            return <div className="col-auto text-end font14 text-danger">
                <img src={Declined} alt="in progress" className="me-1" /> {Constants.ORDER_SUMMARY.paymentType.refundDenied}
            </div>
        }
    }

    _renderOrderTrackStatus(orderStatus, orderType, natureOfOrder, obj) {
        if (orderStatus === Constants.ORDER_STATUS.PENDING) {
            return (<React.Fragment>
                <div className="col">
                    <img src={Delivered} alt="Done" className="mb-2" />
                    <p className="font12 fw-normal">{Constants.ORDER_SUMMARY.orderTypes.orderPlaced}</p>
                </div>
                <div className="col">
                    <img src={GreyCircle} alt="Pending" />
                    <p className="font12 fw-normal">{orderType === 1 ? Constants.ORDER_SUMMARY.orderTypes.preparing : Constants.ORDER_SUMMARY.orderTypes.packing}</p>
                </div>
                <div className="col">
                    <img src={GreyCircle} alt="Pending" />
                    <p className="font12 fw-normal">{natureOfOrder === 1 ? 'Out for delivery' : "Picked"}</p>
                </div>
                <div className="col">
                    <img src={GreyCircle} alt="Pending" />
                    <p className="font12 fw-normal">{Constants.ORDER_SUMMARY.orderTypes.delivered}</p>
                </div>
            </React.Fragment>)
        } else if (orderStatus === Constants.ORDER_STATUS.COMPLETED) {
            return (<React.Fragment>
                <div className="col">
                    <img src={Delivered} alt="Done" className="mb-2" />
                    <p className="font12 fw-normal">{Constants.ORDER_SUMMARY.orderTypes.orderPlaced}</p>
                </div>
                <div className="col">
                    <img src={Delivered} alt="Pending" className="mb-2" />
                    <p className="font12 fw-normal">{orderType === 1 ? Constants.ORDER_SUMMARY.orderTypes.preparing : Constants.ORDER_SUMMARY.orderTypes.packing}</p>
                </div>
                <div className="col">
                    <img src={GreyCircle} alt="Pending" />
                    <p className="font12 fw-normal">{natureOfOrder === 1 ? 'Out for delivery' : "Picked"}</p>
                </div>
                <div className="col">
                    <img src={GreyCircle} alt="Pending" />
                    <p className="font12 fw-normal">{Constants.ORDER_SUMMARY.orderTypes.delivered}</p>
                </div>
            </React.Fragment>)
        } else if (orderStatus === Constants.ORDER_STATUS.DECLINED) {
            return (<React.Fragment>
                <div className="col">
                    <img src={Delivered} alt="Done" className="mb-2" />
                    <p className="font12 fw-normal">{Constants.ORDER_SUMMARY.orderTypes.orderPlaced}</p>
                </div>
                <div className="col">
                </div>
                <div className="col">
                </div>
                <div className="col">
                    <img src={Declined} style={{ width: '30px', height: '30px' }} alt="Done" className="mb-2" />
                    <p className="font12 fw-normal">{Constants.ORDER_SUMMARY.orderTypes.declined}</p>
                </div>
                {
                    obj?.orderComment &&
                    <p className="mb-1 font12 decline-text "><strong> Decline Reason:</strong>  {obj?.orderComment}</p>

                }
            </React.Fragment>)
        } else if (orderStatus === Constants.ORDER_STATUS.READYTOPICKUP) {
            return (<React.Fragment>
                <div className="col">
                    <img src={Delivered} alt="Done" className="mb-2" />
                    <p className="font12 fw-normal">{Constants.ORDER_SUMMARY.orderTypes.orderPlaced}</p>
                </div>
                <div className="col">
                    <img src={Delivered} alt="Done" className="mb-2" />
                    <p className="font12 fw-normal">{orderType === 1 ? Constants.ORDER_SUMMARY.orderTypes.preparing : Constants.ORDER_SUMMARY.orderTypes.packing}</p>
                </div>
                <div className="col">
                    <img src={Delivered} alt="Done" className="mb-2" />
                    <p className="font12 fw-normal">{natureOfOrder === 1 ? 'Out for delivery' : "Picked"}</p>
                </div>
                <div className="col">
                    <img src={GreyCircle} alt="Pending" />
                    <p className="font12 fw-normal">{Constants.ORDER_SUMMARY.orderTypes.delivered}</p>
                </div>
            </React.Fragment>)
        } else if (orderStatus === Constants.ORDER_STATUS.DELIVERED) {
            return (<React.Fragment>
                <div className="col">
                    <img src={Delivered} alt="Done" className="mb-2" />
                    <p className="font12 fw-normal">{Constants.ORDER_SUMMARY.orderTypes.orderPlaced}</p>
                </div>
                <div className="col">
                    <img src={Delivered} alt="Done" className="mb-2" />
                    <p className="font12 fw-normal">{orderType === 1 ? Constants.ORDER_SUMMARY.orderTypes.preparing : Constants.ORDER_SUMMARY.orderTypes.packing}</p>
                </div>
                <div className="col">
                    <img src={Delivered} alt="Done" className="mb-2" />
                    <p className="font12 fw-normal">{natureOfOrder === 1 ? 'Out for delivery' : "Picked"}</p>
                </div>
                <div className="col">
                    <img src={Delivered} alt="Pending" className="mb-2" />
                    <p className="font12 fw-normal">{Constants.ORDER_SUMMARY.orderTypes.delivered}</p>
                </div>
            </React.Fragment>)
        }
    }

    _renderAddress(natureOfOrder, deliveryAddress, storeAddress) {
        let address = {};
        try {
            address = JSON.parse(deliveryAddress)
        } catch (e) {
            console.log(e)
        }
        let { landmark, street, formattedAddress, addresstype } = address || {}

        let storeAddressData = {};
        try {
            storeAddressData = JSON.parse(storeAddress)
        } catch (e) {
            console.log(e)
        }
        return natureOfOrder === 1 ? <><h3 className="font16 fw-bold mb-2">Delivery Address </h3>
            <p className='font14 mb-2'>Residence type: {addresstype ? `${addresstype?.type}` : ''}</p>
            <p className="font14 mb-2">{street ? `${street},` : ''} {landmark ? `${landmark},` : ''} {formattedAddress}</p>
            <hr className="thick" />
        </>
            : <> <h3 className="font16 fw-bold mb-2">Pickup Address</h3>
                <p className="font14 mb-2">  {storeAddressData?.branch_name ? `${storeAddressData?.branch_name},` : ''}
                    {storeAddressData?.address ? `${storeAddressData?.address} ` : ''}
                    {storeAddressData?.zip_code ? `${storeAddressData?.zip_code}.` : ''}</p>
                <hr className="thick" />
            </>
    }

    getOfferDetailsList(viewInvoice) {
        const { promotionJson } = viewInvoice || {};
        let promotions = [];
        try {
            promotions.push(JSON.parse(promotionJson));
        } catch (e) {
            console.log(e)
        }
        return promotions.length > 0 ? promotions : [];

    }
    getCartObject(invoiceOrderDetailsList) {
        let invoiceOrgOrderList = []
        if (invoiceOrderDetailsList && invoiceOrderDetailsList.length > 0) {
            invoiceOrderDetailsList.forEach((item, index) => {
                invoiceOrgOrderList = [
                    ...invoiceOrgOrderList,
                    ...item?.invoiceOrderProductDetailsList,
                ];
            });
        }
        return invoiceOrgOrderList
    }
    _renderCustomerName(invoiceDetails) {
        return invoiceDetails?.customerFirstName ? <div className="col">
            {invoiceDetails?.customerFirstName?.toUpperCase()} {invoiceDetails?.customerLastName?.toUpperCase()} <br />
            <strong>{invoiceDetails?.mobileNumber}</strong>
        </div> :
            <div className="col"><h5>Guest User</h5></div>
    }

    closeRefundModal() {
        this.setState({ isManualRefund: false, errorMessage: null, amountToRefund: null, comment: null, isManualRefundLoading: false })
    }

    openRefundModal(obj) {
        this.setState({ isManualRefund: true, refundableAmount: obj?.refundableAmount })
    }
    intiateRefund() {
        const { amountToRefund, refundableAmount, comment } = this.state
        if (!amountToRefund) {
            this.setState({ errorMessage: 'Please enter the refund amount.' })
            return;
        } else if (amountToRefund <= 0) {
            this.setState({ errorMessage: 'Please enter valid refund amount' })
            return;
        } else if (amountToRefund > refundableAmount) {
            this.setState({ errorMessage: 'Refundable amount limit is exceeded' })
            return;
        } else if (!comment) {
            this.setState({ errorMessage: 'Please enter the comments' })
            return;
        } else if (comment?.length < 5) {
            this.setState({ errorMessage: 'Please enter the comments more than 5 letters' })
            return;
        } else {
            const callback = (res) => {
                if (res?.response_code === 0) {
                    this.closeRefundModal()
                    const { ViewInvoiceDetailsId } = this.props;
                    const options = {
                        invoiceId: ViewInvoiceDetailsId
                    }
                    this.props.invoiceDetails(options)
                } else if (res?.error) {
                    this.setState({ errorMessage: res?.error })
                } else {
                    this.setState({ errorMessage: res?.response_message })
                }
            }

            const { ViewInvoiceDetailsId } = this.props;
            let obj = {
                invoiceId: ViewInvoiceDetailsId,
                amountToRefund,
                comment
            }
            this.props.manaualRefund(obj, callback)
            this.setState({ isManualRefundLoading: true })
        }
    }
    getRefundAmount(value) {
        this.setState({ amountToRefund: value })
    }
    getComments(value) {
        this.setState({ comment: value })
    }

    renderRefundButton(obj) {
        const { roleId } = this.props.GetRoleId || {}
        let indexofNonCompleted = obj?.invoiceOrderDetailsList &&
            obj?.invoiceOrderDetailsList?.findIndex(i =>
                i.orderStatus !== Constants.ORDER_STATUS.DELIVERED &&
                i.orderStatus !== Constants.ORDER_STATUS.DECLINED,
            );
        let indexofCompleted = obj?.invoiceOrderDetailsList &&
            obj?.invoiceOrderDetailsList?.findIndex(i => i.orderStatus === Constants.ORDER_STATUS.DELIVERED);
        return (
            <>
                {
                    indexofNonCompleted === -1 &&
                    indexofCompleted !== -1 &&
                    obj?.createdBy !== Constants.ORDER_TYPES.CREATED_BY_VISION_CHECKOUT &&
                    obj?.refundableAmount?.toFixed(3) > 0.009 &&
                    (obj?.paymentStatus === 0 || obj?.paymentStatus === 11 || obj?.paymentStatus === 12 ||
                        obj?.paymentStatus === 13) && ((roleId === Constants.ROLES.ADMIN) || (roleId === Constants.ROLES.STORE_MANAGER) || (roleId === Constants.ROLES.STORE_ASSOCIATE)) &&
                    <div className="col-auto text-end fw-bold">
                        {(roleId === Constants.ROLES.ADMIN || roleId === Constants.ROLES.STORE_MANAGER) && <span onClick={() => this.openRefundModal(obj)} className="btn btn-dark font14 w-10 p-2" >Refund</span>}
                    </div>

                }

            </>
        )

    }

    _renderOrderType(actions, type) {
        switch (actions) {
            case 1:
                return 'WIWO';
            case 2:
                return type === 1 ? "Door Delivery" : "Store Pickup";
            case 3:
                return 'Vision Checkout';
            case 4:
                return 'Scan & Go';

            default:
                return '';
        }
    }
    _getSumAmount = (item, orderDetails) => {
        let productAmount = parseFloat(item.unitPrice);
        let { invoiceProductAddOnMappingList } = item;
        let sum = invoiceProductAddOnMappingList.reduce((a, c) => {
            return a + parseFloat(c.unitPrice);
        }, productAmount);

        return ((orderDetails.orderStatus !== 1 ? item.acceptedQuantity : item.quantity) * sum)?.toFixed(2)
    };

    _onViewRefundReceipt = () => {
        this.setState({ isHideViewReceipt: true })
    }

    _onCloseViewRefundReceipt = () => {
        this.setState({ isHideViewReceipt: false })
    }

    onSuccessClose = (id) => {
        const options = {
            invoiceId: id
        }
        this.props.invoiceDetails(options)
    }

    render() {
        const { invoiceDetails, isLoading } = this.props.getInvoiceDetails || {}
        const {
            deliveryAddress, natureOfOrder,
            invoiceOrderDetailsList, storeAddress
        } = invoiceDetails || {}
        const { isManualRefund, refundableAmount, errorMessage, isManualRefundLoading, isHideViewReceipt } = this.state
        const { setViewInvoiceModal, refundData, GetRoleId } = this.props;
        const { roleId } = GetRoleId || {}
        const finalAmount = invoiceOrderDetailsList?.findIndex((o) => o?.orderStatus === 1);
        return (
            <React.Fragment>
                <div className="modal d-block" id="order-details-modal" tabIndex="-1" aria-labelledby="login" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content rounded-0">

                            <img onClick={() => setViewInvoiceModal()} src={Close} alt="X" id='Close'
                                data-bs-dismiss="modal" aria-label="Close" className="modal-close" />

                            <div className="modal-header rounded-0 align-items-center py-4">
                                <div className="col">
                                    <h5 className="modal-title">
                                        {refundData ? "Refund receipt details" : "Receipt details"}</h5> </div>
                            </div>

                            <div className="modal-body font13 p-40">

                                <div className="row align-items-center mb-4">
                                    <div className="col-auto">
                                        <div className="p-3 font16 bg-lgrey text-center border border-dark rounded-circle">{invoiceDetails?.customerFirstName ? `${invoiceDetails?.customerFirstName?.charAt(0)?.toUpperCase()} ${invoiceDetails?.customerLastName?.charAt(0)?.toUpperCase()}` : `${Constants.GUEST_USER.guest?.charAt(0)?.toUpperCase()}  ${Constants.GUEST_USER.user?.charAt(0)?.toUpperCase()}`}</div>
                                    </div>
                                    {this._renderCustomerName(invoiceDetails)}
                                    {this.renderRefundButton(invoiceDetails)}
                                </div>
                                <hr className="thick" />

                                {
                                    invoiceOrderDetailsList && invoiceOrderDetailsList.sort(SortCheckoutOrders)
                                        .reduce(reduceCheckourOrders, []).map((details) => {
                                            return (<>
                                                <div className="row align-items-center mb-2">
                                                    <h2 className="font13 mb-3"><strong>{details.sectionHeader}</strong></h2>
                                                    <div className="col">
                                                        <h3 className="font13 mb-2">Order no</h3>
                                                        <div className="row font12 fw-bold">
                                                            <div className="col-12 col-lg pt-2 pt-lg-0">{details?.invoiceOrderDetailsId}</div>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <h3 className="font13 mb-2">Order Type</h3>
                                                        <div className="row font12 fw-bold">
                                                            <div className="col-12 col-lg pt-2 pt-lg-0">{this._renderOrderType(invoiceDetails?.createdBy, invoiceDetails?.natureOfOrder)}</div>
                                                        </div>
                                                    </div>
                                                    <div className="col ">
                                                        <h3 className="font13 mb-2">Order Date</h3>
                                                        <div className="row font12 fw-bold ">
                                                            <div className="col-12 col-lg pt-2 pt-lg-0">{moment(invoiceDetails?.invoiceDate).format('DD-MMM | h:mm A')}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {invoiceDetails?.createdBy === 2 && <div className="card card-body text-center my-3 py-4">
                                                    <hr className="status-line w-75 m-auto" />
                                                    <div className="row status-row">
                                                        {this._renderOrderTrackStatus(details.orderStatus,
                                                            details.orderType, natureOfOrder, details)}
                                                    </div>
                                                </div>}
                                            </>
                                            )
                                        })}
                                <hr />
                                {invoiceDetails?.createdBy === 2 && this._renderAddress(natureOfOrder, deliveryAddress, storeAddress)}
                                <div className="row mb-4">
                                    <div className="col"> <h3 className="font16 fw-bold">Bill details</h3></div>
                                    {this.renderOrderStatus(invoiceDetails?.paymentStatus)}
                                </div>

                                <div className="pt-3">
                                    {
                                        invoiceOrderDetailsList && invoiceOrderDetailsList.sort(SortCheckoutOrders)
                                            .reduce(reduceCheckourOrders, []).map((details) => {
                                                const { invoiceOrderProductDetailsList: data, customerComment } = details;
                                                return (
                                                    <>
                                                        {data?.map((o) => {
                                                            let unfilledQty = o?.quantity - o?.acceptedQuantity
                                                            return <>
                                                                <div className="card card-body p-0 border-0 pb-2 mb-2">
                                                                    <div className="row">
                                                                        <div className="col">
                                                                            <h3 className={`font13 mb-1 title-case`}>
                                                                                <div className={`${(o?.acceptedQuantity === 0 && details?.orderStatus !== 1) ? 'strike' : ''}`}>
                                                                                    {details?.orderStatus !== 1 && o?.acceptedQuantity !== 0 ? o?.acceptedQuantity : o?.quantity} x {o?.name?.toLowerCase()} (SKU :{o?.upc}) <span className='text-danger'>{o?.ageRestricted ? `18+` : ""}</span>
                                                                                    {o?.invoiceProductAddOnMappingList?.length > 0 ? (
                                                                                        <p className="mb-1 font-14" style={{ paddingLeft: '10px' }}>
                                                                                            <p className='font-13 ml-2'><b></b>YOUR CUSTOMIZATION:</p>
                                                                                            {o?.invoiceProductAddOnMappingList?.map((o) => o?.name).join(',')}
                                                                                        </p>) : ''
                                                                                    }
                                                                                </div>
                                                                                {
                                                                                    (unfilledQty !== 0 && details?.orderStatus !== 1 && o?.acceptedQuantity !== 0) ? <p style={{ color: 'red', paddingLeft: '10px' }}>Unfulfilled Quantity: {unfilledQty}</p> : null
                                                                                }
                                                                            </h3>
                                                                        </div>
                                                                        <div
                                                                            className="col-auto text-end fw-bold">
                                                                            ${this._getSumAmount(o, details)}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        })}
                                                        {
                                                            customerComment && <div><b>Customer Notes:</b> {customerComment}</div>
                                                        }
                                                    </>)
                                            })}
                                    {
                                        invoiceOrderDetailsList && invoiceOrderDetailsList.sort(SortCheckoutOrders)
                                            .reduce(reduceCheckourOrders, []).map((details) => {
                                                const { partialAcceptComment } = details;
                                                return (
                                                    <>
                                                        {
                                                            partialAcceptComment && <div className="row mb-3">
                                                                <div className='col'>
                                                                    <span>
                                                                        <b>{details.orderType === 2 ? "Grocery Comment: " : "Bistro Comment: "}</b>
                                                                        {partialAcceptComment}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        }
                                                    </>
                                                )
                                            })}
                                    {
                                        this.getOfferDetailsList(invoiceDetails).map((offers, index) => {
                                            return <OrderSummaryOfferDetails
                                                cartOffer={offers}
                                                cartItems={this.getCartObject(invoiceOrderDetailsList)}
                                                natureOfOrder={natureOfOrder}
                                            />
                                        })
                                    }
                                </div>


                                <div className="dotted-divider pt-2 mt-2 mb-3">
                                    <div className="row mb-2">
                                        <div className="col">Receipt no</div>
                                        <div className="col-auto text-end"> {invoiceDetails?.invoiceId}</div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col">Items total</div>
                                        <div className="col-auto text-end">
                                            {`$${parseFloat(invoiceDetails?.totalAmountExcludingTax)?.toFixed(2)}`}
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col">Sales Tax</div>
                                        <div className="col-auto text-end">
                                            {`$${parseFloat(invoiceDetails?.totalTaxAmount)?.toFixed(2)}`}
                                        </div>
                                    </div>
                                </div>
                                {
                                    this.getOfferDetailsList(invoiceDetails).map((offers, index) => {
                                        return <InvoiceSummary
                                            cartOffer={offers}
                                            cartItems={this.getCartObject(invoiceOrderDetailsList)}
                                            natureOfOrder={natureOfOrder}
                                        />
                                    })
                                }
                                <div className="row mb-2">
                                    <div className="col fw-bold">{invoiceDetails?.createdBy !== 3 && (finalAmount !== -1 || invoiceDetails?.paymentStatus === 1 || invoiceDetails?.paymentStatus === 2 || invoiceDetails?.paymentStatus === 3 || invoiceDetails?.paymentStatus === 4) ? 'Pending amount' : 'Paid amount'}</div>
                                    <div className="col-auto text-end fw-bold">
                                        {invoiceDetails?.createdBy !== 3 && (finalAmount !== -1 || invoiceDetails?.paymentStatus === 1 || invoiceDetails?.paymentStatus === 2 || invoiceDetails?.paymentStatus === 3 || invoiceDetails?.paymentStatus === 4) ? `$${parseFloat(invoiceDetails?.totalAmount)?.toFixed(2)}` : `$${parseFloat(invoiceDetails?.processedAmount)?.toFixed(2)}`}
                                    </div>
                                </div>

                                {(invoiceDetails?.paymentStatus === 11 || invoiceDetails?.paymentStatus === 12 || invoiceDetails?.paymentStatus === 13)
                                    && invoiceDetails?.refundDetails && !this.props.hideViewRefund && roleId !== Constants.ROLES.BISTRO_ASSOCIATE && roleId !== Constants.ROLES.STORE_ASSOCIATE
                                    && <div className="row mb-2">
                                        <div className="col fw-bold"></div>
                                        <div className="col-auto text-end fw-bold text-primary cursor-pointer" onClick={() => this._onViewRefundReceipt()}>
                                            View Refund Receipt
                                        </div>
                                    </div>}

                                {invoiceDetails?.manualRefunds?.length > 0 && <>
                                    <div className=" dotted-divider pt-2 mt-2 mb-3">
                                        <div className="row mb-2">
                                            <div className="col fw-bold">Initiated Refund</div>
                                        </div>
                                        {invoiceDetails?.manualRefunds?.map((obj, i) => {
                                            return (<React.Fragment key={i}>
                                                <div className="row mb-2">
                                                    <div className="col">{obj?.comment} <br />
                                                        <span>{moment(obj?.lastModified).format('DD - MMM | h:mm a')}</span>
                                                    </div>
                                                    <div className="col-auto text-end"> ${obj?.refundAmount.toFixed(2)}</div>
                                                </div>
                                            </React.Fragment>)
                                        })}
                                    </div>
                                </>}

                            </div>

                        </div>
                    </div>
                </div>
                {isLoading && <AppLoader />}
                {
                    isManualRefund && <ManualRefundModal
                        id="manualRefundModal"
                        show={isManualRefund}
                        onClose={() => this.closeRefundModal()}
                        onChange={(value) => this.getRefundAmount(value)}
                        onCommentChange={(value) => this.getComments(value)}
                        onClick={() => this.intiateRefund()}
                        refundableAmount={refundableAmount}
                        errorMsg={errorMessage}
                        isLoading={isManualRefundLoading}
                    />
                }
                {
                    isHideViewReceipt && <RefundDetails
                        id="RefundDetails"
                        isHideViewReceipt={isHideViewReceipt}
                        invoiceId={invoiceDetails?.refundDetails?.refundDetailsId}
                        onCloseViewRefundReceipt={() => this._onCloseViewRefundReceipt()}
                        onSuccessClose={(invoiceId) => this.onSuccessClose(invoiceId)}
                    />
                }
                <div className="modal-backdrop show"></div>
            </React.Fragment >
        )
    }
}

export const mapStateToProps = (state) => {
    return {
        getInvoiceDetails: state?.INVOICE_DETAILS || {},
        GetRoleId: state?.LOGIN_CREDS?.userInfo || {}
    };
};

export default withRouter(connect(mapStateToProps, {
    invoiceDetails: Actions.invoiceDetails,
    manaualRefund: Actions.manaualRefund
})(ViewInvoiceModal))