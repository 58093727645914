import React from 'react'
import Close from '../../../asset/new/img/modal-close.svg'
class Alert extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    render() {
        const { title, description, proceed, cancel, onSuccess, onFailure, textField } = this.props
        return (
            <React.Fragment>
                <div className="modal d-block"
                    id="unblock-modal"
                    tabIndex="-1"
                    aria-labelledby="unblock"
                    aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-body p-50">
                                <img
                                    id='close'
                                    src={Close}
                                    alt="X"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    className="modal-close"
                                    onClick={() => onFailure()} />
                                <form>
                                    <div
                                        className="row mb-4">
                                        <div
                                            className="col">
                                            {
                                                title && <h3
                                                    className="font18 fw-bold mb-3">{title}</h3>
                                            }
                                            <p>{description}</p>
                                            {textField && <div className="col-12 col-lg pe-lg-3">
                                                <div className="input-group mb-3 mb-lg-0">
                                                    <textarea type="textarea" className="form-control " placeholder="Search by order no" />
                                                </div>
                                            </div>}
                                        </div>
                                    </div>
                                    <div className="row mt-5">
                                        <div
                                          id='onClickSuccess'  className="col pe-0" onClick={() => onSuccess()}>
                                            <span className="btn btn-success font14 w-100 p-3">{proceed}</span> </div>
                                        <div
                                            id="onClickFailure" className="col text-end" onClick={() => onFailure()}>
                                            <span className="btn btn-outline-danger font14 w-100 p-3">{cancel}</span> </div>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="modal-backdrop show"></div>
            </React.Fragment>
        )
    }
}

export default Alert;