import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import Pagination from 'react-js-pagination'
import { useDispatch, useSelector } from 'react-redux'
import Search from '../../../asset/new/img/search.svg'
import AppLoader from '../../components/fields/AppLoader'
import { createBranchListAction, getAllBranchListAction, updateAdminActiveStatus, updateBranchListAction } from '../../redux/actions/BranchListActions'
import { BranchListData, memberCount, memberListLoader } from '../../redux/reducers/BranchListReducer'
import RoutePath from '../../utils/routes/RoutePath'
import BranchDetailsModal from './BranchDetailsModal'
import { BranchModal } from './BranchModal'
import ResetPassword from './ResetPassword'
import { isValidEmail, validatedigitsRegExp, validatelowercaseRegExp, validateminLengthRegExp, validatespecialCharRegExp, validateuppercaseRegExp } from '../../utils/validation'
import EmptyPage from '../../components/emptyPage'
import debounce from 'lodash.debounce'
import Constants from '../../utils/Constants'
import { toast } from 'react-toastify';


const SearchInputField = memo(({ placeholder, onChangeSearchInput, value }) => {

  return (
    <div className="input-group mb-3 mb-lg-0">
      <input type="text" className="form-control border-end-0" value={value}
        id='searchOrder'
        onChange={(value) => {
          onChangeSearchInput(value);
        }}
        placeholder={placeholder} />
      <button className="btn btn-outline-dark border-start-0" type="button" id="search-btn"><img src={Search} alt="Go" /></button>
    </div>
  )
});

const BranchDetails = () => {

  const initialState = {
    firstName: '',
    lastName: '',
    emailId: '',
    password: '',
    employeeId: undefined,
    roleId: undefined,
    poApprover: undefined
  }
  const [selectedStore, setSelectedStore] = useState([])
  const [isShow, setShow] = useState(false)
  const data = useSelector(BranchListData)
  const count = useSelector(memberCount)
  const loader = useSelector(memberListLoader)
  const dispatch = useDispatch()
  const [viewBranchDetail, setViewBranchDetail] = useState('')
  const [openViewModal, setOpenViewModal] = useState(false)
  const [editMemberDetails, setEditMemberDetails] = useState(false)
  const [validationError, setValidationError] = useState('')
  const [resetPasswordModal, setResetPasswordModal] = useState(false)
  const [activePage, setActivePage] = useState(1)
  const [id, setId] = useState('')
  const [password, setPassword] = useState('')
  const [state, setState] = useState(initialState)
  const [searchList, setSearchList] = useState('')

  const defaultFilter = {
    offset: 0,
    limit: 10,
    search: ''
  };
  useEffect(() => {
    dispatch(getAllBranchListAction(defaultFilter))
    setSearchList('')
  }, [])
  const openBranchDetailModal = (obj) => {
    setOpenViewModal(true)
    setViewBranchDetail(obj)
  }
  const _addNewUser = () => {
    setValidationError('')
    setShow(true)
  }
  const roleSelector = (key) => {
    switch (key) {
      case Constants.ROLES.ADMIN:
        return 'Admin';
      case Constants.ROLES.STORE_MANAGER:
        return 'Store Manager';
      case Constants.ROLES.BISTRO_ASSOCIATE:
        return 'Bistro Associate'
      case Constants.ROLES.STORE_ASSOCIATE:
        return 'Store Associate'
      case Constants.ROLES.BACK_KITCHEN:
        return 'Back Kitchen'
      case Constants.ROLES.BISTRO_DISPLAY:
        return 'Bistro Display'
      case Constants.ROLES.FRONT_KITCHEN:
        return 'Front Kitchen'
      default:
        return 'Admin'
    }
  }

  const manageUserList = (isCreate) => {
    if (!state.firstName) {
      setValidationError("Please enter the First Name")
      return;
    }
    else if (!state.lastName) {
      setValidationError('Please enter the Last Name');
      return;
    } else if (!editMemberDetails && !state.emailId) {
      setValidationError('Please enter the email');
      return;
    } else if (!editMemberDetails && !isValidEmail(state.emailId)) {
      setValidationError('Please enter valid email');
      return;
    } else if (!editMemberDetails && !password) {
      setValidationError("Please enter the password")
      return
    } else if (!editMemberDetails && !validateuppercaseRegExp(password)) {
      setValidationError('Please enter valid password')
      return
    } else if (!editMemberDetails && !validatelowercaseRegExp(password)) {
      setValidationError('Please enter valid password')
      return
    } else if (!editMemberDetails && !validatespecialCharRegExp(password)) {
      setValidationError('Please enter valid password')
      return
    } else if (!editMemberDetails && !validatedigitsRegExp(password)) {
      setValidationError('Please enter valid password')
      return
    } else if (!editMemberDetails && !validateminLengthRegExp(password)) {
      setValidationError('Please enter valid password')
      return
    } else if (!editMemberDetails && !state.employeeId) {
      setValidationError('Please enter the employee Id');
      return;
    }else if (editMemberDetails && !state.employeeId) {
      setValidationError('Please enter the employee Id');
      return;
    }
     else if (!parseInt(state.roleId) && parseInt(state.roleId) !==0) {
      setValidationError('Please select the role');
      return;
    } else if (parseInt(state.roleId) !== 0 && selectedStore.length === 0) {
      setValidationError('Please select the store');
      return;
    }
    setValidationError('')
    const storeIds = selectedStore.map(o => o?.value)
    let params = {
      firstName: state.firstName,
      lastName: state.lastName,
      mobileNumber: state.mobileNumber,
      emailId: state.emailId,
      password: password,
      employeeId: state.employeeId,
      roleId: state.roleId,
    }
    if (isCreate) {
      const callback = (response) => {
        const searchFilter = {
          offset: (activePage - 1) * 10,
          limit: 10,
          search: searchList ? searchList : ''
        }
        if (response?.response_code === 0) {
          toast.success('Member created successfully')
          dispatch(getAllBranchListAction(searchFilter))
        } else {
          setValidationError(response?.response_message)
          setState(state)
          setSelectedStore(selectedStore)
          setPassword(password)
          setShow(true)
          return
        }
      }
      params.storeIds = (parseInt(state.roleId) === 0 ? [] : storeIds)
      if (parseInt(state.roleId) == Constants.ROLES.STORE_MANAGER) {
        params.poApprover = state?.poApprover
      }
      dispatch(createBranchListAction(params, callback))
    } else {
      params.id = id
      if (parseInt(state.roleId) == Constants.ROLES.STORE_MANAGER) {
        params.poApprover = state?.poApprover
      }
      params.storeIds = (state.roleId === '0' ? [] : storeIds)
      const searchFilter = {
        offset: (activePage - 1) * 10,
        limit: 10,
        search: searchList ? searchList : ''
      }
      dispatch(updateBranchListAction(params, (response) => {
        if (response?.response_code === 0) {
          toast.success('Member updated successfully')
        } else {
          setValidationError(response?.response_message)
          setState(state)
          setSelectedStore(selectedStore)
          setPassword(password)
          setShow(true)
          return
        }
        dispatch(getAllBranchListAction(searchFilter))
      }))
    }
    setState(initialState)
    setPassword('')
    setSelectedStore([])
    setShow(false)
  }

  const handlePagination = (pageNumber) => {
    setActivePage(pageNumber)
    memberListPageChange(pageNumber)
  }
  const memberListPageChange = (offsetValue) => {
    const filterParams = {
      offset: (offsetValue - 1) * 10,
      limit: 10,
      search: searchList ? searchList : ''
    }
    dispatch(getAllBranchListAction(filterParams))
  }
  const disableAdminUser = (e, obj) => {
    const params = {
      userId: obj.id,
      active: e.target.checked,
    }
    const searchFilter = {
      offset: (activePage - 1) * 10,
      limit: 10,
      search: searchList ? searchList : ''
    }
    dispatch(updateAdminActiveStatus(params, () => {
      dispatch(getAllBranchListAction(searchFilter))
    }))
  }
  const handleSearchChange = useCallback((value) => {
    setSearchList(value)
    setActivePage(1)
    const searchFilter = {
      offset: 0,
      limit: 10,
      search: value
    }
    searchChatByIdRef(searchFilter)
  }, [searchList])

  const searchChatByIdRef = useRef(debounce((searchFilter) => dispatch(getAllBranchListAction(searchFilter)), 1000)).current;

  const editMembers = (obj) => {
    const arr = obj?.storeDetails
    const storeDetails = arr?.map(o => ({ label: o?.store?.branchName, value: o?.store?.id }))
    setValidationError('')
    setEditMemberDetails(true)
    setState(obj)
    setSelectedStore(storeDetails)
    setShow(true)
    setId(obj?.id)
  }
  const openEditModal = (obj) => {
    setResetPasswordModal(true)
    setId(obj?.id)
  }
  const _renderStoreId = (arr) => {
    return arr?.map(o => o?.store?.branchName)?.join(',')
  }
  return (
    <div className="col ps-lg-4">
      {loader && <AppLoader />}
      <div className="row mb-1 align-items-center">
        <div className="col">
          <h1 className="font20 fw-bold mb-2">Members <small className="fw-normal"></small></h1>
          <nav style={{ marginLeft: "--bs-breadcrumb-divider: '/';" }} aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href={RoutePath.DASHBOARD_SCREEN_PATH}>Home</a></li>
              <li className="breadcrumb-item active" aria-current="page">Members</li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="row mb-1 align-items-center">
        <div className='col-4'>
          <SearchInputField
            id="SearchInputField"
            placeholder='Search by Name or Employee ID'
            value={searchList}
            handleSearch={handleSearchChange}
            onChangeSearchInput={(e) => handleSearchChange(e?.target?.value)}
          />
        </div>
        <div className="col text-end"> <button id="modalClick" name="" data-bs-toggle="modal" data-bs-target="#add-dollar-modal" className="btn btn-dark" onClick={() => _addNewUser()}>Add</button> </div>
      </div>
      <div className="row mb-4">
        <div className="col">
          {
            data?.length === 0 ? (<div className="card card-body rounded-3 border-0 shadow p-4 mb-3 mb-xl-0">
              <EmptyPage
                content="Members not found"
              />
            </div>) :
              (< div id="all-offers" className="card card-body rounded-3 border-0 shadow p-4 mb-3 mb-xl-0">
                <div className="table-responsive mb-4">
                  <table className="table table-hover table-striped table-borderless">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Employee ID</th>
                        <th>Role</th>
                        <th>Assigned Store</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        data && data?.map(obj => {
                          return (
                            <tr>
                              <td className="promotion-coupon-desc-wrap title-case">{obj?.firstName + ' ' + obj.lastName}</td>
                              <td className="promotion-coupon-desc-wrap">{obj?.emailId}</td>
                              <td className="promotion-coupon-desc-wrap">{obj?.employeeId}</td>
                              <td className="promotion-coupon-desc-wrap title-case">{roleSelector(obj?.roleId)}</td>
                              <td className="promotion-coupon-desc-wrap title-case">{_renderStoreId(obj?.storeDetails)}</td>
                              <td>
                                <div className="form-check form-switch">
                                  <input className="form-check-input" onChange={(e) => disableAdminUser(e, obj)}
                                    checked={obj?.active} type="checkbox" role="switch" id="switch" />
                                </div>
                              </td>
                              <td className='d-flex'>
                                <div className="col-auto">
                                  <button
                                    type="button" data-bs-toggle="modal"
                                    data-bs-target="#view-multi-modal"
                                    id="openMultibuy"
                                    className="btn btn-sm btn-outline-dark mb-3 mb-md-0"
                                    onClick={() => openBranchDetailModal(obj)}
                                  >View
                                  </button>
                                </div>
                                <div className="col-auto">
                                  <button
                                    type="button" data-bs-toggle="modal" data-bs-target="#edit-multi-modal"
                                    id="EditMul"
                                    className="btn btn-sm btn-outline-dark"
                                    onClick={() => editMembers(obj)}
                                  >Edit
                                  </button>
                                </div>
                                <div className="col-auto">
                                  <button
                                    type="button" data-bs-toggle="modal" data-bs-target="#edit-multi-modal"
                                    id="Multi"
                                    className="btn btn-sm btn-outline-dark"
                                    onClick={() => openEditModal(obj)}
                                  >Reset
                                  </button>
                                </div>
                              </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                </div>
                <div className="text-center">
                  {
                    (data && data?.length > 0) && <Pagination
                      id="pagination"
                      activePage={activePage}
                      itemsCountPerPage={10}
                      totalItemsCount={count}
                      pageRangeDisplayed={5}
                      onChange={(e) => handlePagination(e)}
                    />
                  }
                </div>
              </div>)}
        </div>
      </div>
      {
        isShow && <BranchModal
          id="branchModal"
          password={password}
          setPassword={setPassword}
          value={state}
          manageUserList={(e) => manageUserList(e)}
          isCreate={!editMemberDetails}
          setState={setState}
          setShow={setShow}
          setEditMemberDetails={setEditMemberDetails}
          validationError={validationError}
          initialState={initialState}
          selectedStore={selectedStore}
          setSelectedStore={setSelectedStore}
        />
      }
      {
        openViewModal && <BranchDetailsModal value={state} roleSelector={roleSelector} data={viewBranchDetail} setOpenViewModal={setOpenViewModal} />
      }
      {
        resetPasswordModal && <ResetPassword id={id} searchList={searchList} activePage={activePage} setResetPasswordModal={(e) => setResetPasswordModal(e)} />
      }
    </div >
  )
}

export default BranchDetails