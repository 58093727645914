import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Actions from "../../../redux/actions";
import {
  getMultiBuyListLoadingSelector,
  getMultiBuyListSelector,
  getMultiBuyCountSelector
} from "../../../redux/reducers/MultiBuyReducer";
import {
  getProductListSelector,
} from "../../../redux/reducers/ProductsReducer";
import MultiBuyModal from "./MultiBuyModal";
import moment from "moment";
import { ManageMultiBuyURL } from "../../../utils/service/Endpoints";
import MultiBuyDetailModal from './MultiBuyDetailModal'
import { UTCDateFormat, UTCEditFormat, UTCViewFormat } from "../../../utils/commonFunctions/dateFormat";
import Pagination from "react-js-pagination";
import AppLoader from "../../../components/fields/AppLoader";
import RoutePath from "../../../utils/routes/RoutePath";
import { toast } from "react-toastify";

const defaultFilter = {
  storeId: 1,
  page: 0,
  itemsPerPage: 10
};

const MultiBuy = () => {
  const dispatch = useDispatch();
  const productList = useSelector(getProductListSelector);
  const MultiBuyList = useSelector(getMultiBuyListSelector);
  const isLoading = useSelector(getMultiBuyListLoadingSelector);
  const [isOpenMultiBuyModal, setOpenMultiBuyModal] = useState(false);
  const [offerType, setOfferType] = useState('');
  const [offerValue, setOfferValue] = useState();
  const [expiryOn, setExpiryOn] = useState();
  const [validFrom, setValidFrom] = useState();
  const [products, setProducts] = useState();
  const [quantity, setQuantity] = useState();
  const [validationError, setFilterValidationError] = useState(null);
  const [id, setId] = useState("")
  const [multiBuyDetails, setMultiBuyDetail] = useState("")
  const [multiBuyDetailModal, setMultiBuyDetailModal] = useState(false)
  const [isEditMultiBuyModal, setEditMultiBuyModal] = useState(false);
  const [productName, setProductName] = useState("")
  const [productType, setProductType] = useState('')
  const totalCount = useSelector(getMultiBuyCountSelector)
  const [activePage, setActivePage] = useState(1)
  const OpenMultiBuyModal = (value) => {
    setOpenMultiBuyModal(value);
  };
  const [loading, setLoader] = useState(false)
  const EditMultiBuyModal = (value, obj) => {
    setEditMultiBuyModal(value)
    let { offerType, offerValue, productName, quantity,
      id, startDateString, endDateString, bistroProduct } = obj || {}
    setId(id)
    setOfferType(offerType)
    setOfferValue(offerValue)
    setExpiryOn(UTCEditFormat(endDateString))
    setValidFrom(UTCEditFormat(startDateString))
    setQuantity(quantity)
    setProducts(id)
    setProductName(productName)
    setProductType(bistroProduct)
  }
  const callback = (res) => {
    if (res?.response_code === 0) {
      dispatch(Actions.getAllMultiBuyListAction(defaultFilter));
      setInitialState()
      setLoader(false)
    } else {
      setFilterValidationError(res?.response_message)
      setLoader(false)
    }
  }

  const openMultibuydetailModal = (value) => {
    setMultiBuyDetail(value)
    setMultiBuyDetailModal(true)

  }
  const setInitialState = () => {
    setProductType('')
    setOpenMultiBuyModal(false)
    setEditMultiBuyModal(false)
    setOfferType('')
    setOfferValue('')
    setProducts('')
    setValidFrom('')
    setExpiryOn('')
    setQuantity('')
    setFilterValidationError(null)
    setActivePage(1)
  }
  const initialState = () => {
    setProductType('')
    setOpenMultiBuyModal(false)
    setEditMultiBuyModal(false)
    setOfferType('')
    setOfferValue('')
    setProducts('')
    setValidFrom('')
    setExpiryOn('')
    setQuantity('')
    setFilterValidationError(null)
  }
  useEffect(() => {
    dispatch(Actions.getAllMultiBuyListAction(defaultFilter));
  }, [dispatch]);
  const handleChange = (pageNumber) => {
    setActivePage(pageNumber)
    multibuyPageChange(pageNumber)
  }
  const multibuyPageChange = (offsetValue) => {
    const filterParams = {
      page: offsetValue - 1,
      limit: 10
    }
    dispatch(Actions.getAllMultiBuyListAction(filterParams));
  }
  const selectedProducts = (obj) => {
    setProducts(obj?.value)
  }

  const selectedProductType = (obj) => {
    setProductType(obj)
    setProducts(null)
  }

  const manageMultiBuy = (isCreate) => {
    if (isCreate && productType === '') {
      setFilterValidationError("Please choose product type")
      return;
    } else if (!offerType) {
      setFilterValidationError('Please choose offer type.');
      return;
    } else if (!offerValue) {
      setFilterValidationError('Please enter the offer value.');
      return;
    } else if (offerValue <= 0) {
      setFilterValidationError('CartGrossValue should not be 0 and negative values');
      return;
    } else if (quantity <= 0) {
      setFilterValidationError('Quantity should not be 0 and negative values');
      return;
    } else if (offerType === 'PERCENT' && offerValue > 100) {
      setFilterValidationError('Offer value should be less than 100')
      return;
    } else if (!validFrom) {
      setFilterValidationError('Please choose start date.');
      return;
    } else if (!expiryOn) {
      setFilterValidationError('Please choose end date.');
      return;
    } else if (moment(validFrom).isAfter(moment(expiryOn))) {
      setFilterValidationError('Valid start date cannot be before end date.');
      return;
    } else if (moment(validFrom).isSameOrAfter(moment(expiryOn))) {
      setFilterValidationError('Valid start date time cannot be same as end date time.');
      return;
    } else if (!products && isCreate) {
      setFilterValidationError('Please choose products');
      return;
    } else if (!quantity) {
      setFilterValidationError('Please enter the quantity');
      return;
    } else if (quantity < 2) {
      setFilterValidationError('Quantity must be greater than 1');
      return;
    } else if (quantity >= 10) {
      setFilterValidationError('Quantity should be in single digits');
      return;
    }
    let startDate = UTCViewFormat(validFrom)
    let endDate = UTCViewFormat(expiryOn)
    setLoader(true)
    let params = {
      offerType: offerType,
      offerValue: offerValue,
      expiryOn: expiryOn,
      validFrom: validFrom,
      quantity: quantity,
      active: true,
      startDateTimeString: startDate,
      endDateTimeString: endDate,

    }
    if (isCreate) {
      params.sku = products
      params.bistroProduct = productType === 'bistro' ? true : false
      dispatch(Actions.createMultiBuyAction(params, callback));
    } else {
      params.id = id
      params.bistroProduct = productType
      dispatch(Actions.updateMultiBuyAction(params, callback))
    }

  }
  //delete
  const deleteCallback = (res) => {
    const params = {
      page: activePage - 1,
      limit: 10
    }
    if (res && res.response_code === 0) {
      toast.success(res?.response_message, { position: toast.POSITION.TOP_RIGHT })
      // setActivePage(1)
      dispatch(Actions.getAllMultiBuyListAction(params));
    } else {
      toast.error(res?.response_message, { position: toast.POSITION.TOP_RIGHT })
    }

  }

  const disableMultiBuyList = (object, value) => {
    const URL = ManageMultiBuyURL(object?.id, value)
    dispatch(Actions.deleteMultiBuyAction(URL, deleteCallback));

  }

  let multibuyList = []

  if (MultiBuyList && MultiBuyList.length > 0) {
    multibuyList = MultiBuyList
  } else {
    multibuyList = []
  }

  const data = React.useMemo(() => multibuyList, [multibuyList]);

  return (
    <div className="col ps-lg-4">
      {isLoading && <AppLoader />}
      <div className="row mb-1 align-items-center">
        <div className="col">
          <h1 className="font20 fw-bold mb-2">MultiBuy <small className="fw-normal"></small></h1>
          <nav style={{ marginLeft: "--bs-breadcrumb-divider: '/';" }} aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href={RoutePath.DASHBOARD_SCREEN_PATH}>Home</a></li>
              <li className="breadcrumb-item"><a href={RoutePath.PROMOTION_DASHBOARD}>Promotions</a></li>
              <li className="breadcrumb-item active" aria-current="page">MultiBuy</li>
            </ol>
          </nav>
        </div>
        <div className="col text-end"> <button id="modalClick" name="" data-bs-toggle="modal" data-bs-target="#add-dollar-modal" className="btn btn-dark" onClick={() => OpenMultiBuyModal(true)}>Add New</button> </div>
      </div>
      <div className="row mb-4">
        <div className="col">
          <div id="all-offers" className="card card-body rounded-3 border-0 shadow p-4 mb-3 mb-xl-0">
            <div className="table-responsive mb-4">
              <table className="table table-hover table-striped table-borderless">
                <thead>
                  <tr>
                    <th>OfferId</th>
                    <th>Product</th>
                    <th>Offer Value</th>
                    <th>Quantity</th>
                    <th>Valid From</th>
                    <th>Valid To</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {!isLoading && data && data.map((o) => {
                    return (<tr>
                      <td> {o?.id}</td>
                      <td> <p title={o?.productName}
                        className="promotion-desc-wrap title-case">{o?.productName?.toLowerCase()}</p> </td>
                      <td> {o?.offerType === "PERCENT" ? `${o?.offerValue}%` : `$` + parseFloat(o?.offerValue)?.toFixed(2)} </td>
                      <td> {o?.quantity} </td>
                      <td>{UTCDateFormat(o?.startDateString)} </td>
                      <td>{UTCDateFormat(o?.endDateString)} </td>
                      {/* <td> {o?.quantity} </td> */}
                      {!o.status ? <td>
                        <div className="form-check form-switch" >
                          <input
                            onChange={(e) => {
                              e.stopPropagation();
                              disableMultiBuyList(o, e.target.checked)
                            }} className="form-check-input" type="checkbox" role="switch" id="switch" checked={o?.active} />
                        </div></td> : <td className={o.status === 'Expired' ? "text-danger" : 'text-success'}>{o?.status}</td>
                      }
                      <td className="d-flex">
                        <div className="col-auto">
                          <button onClick={() => openMultibuydetailModal(o)}
                            type="button" data-bs-toggle="modal"
                            data-bs-target="#view-multi-modal"
                            id="openMultibuy"
                            className="btn btn-sm btn-outline-dark mb-3 mb-md-0">View
                          </button>
                        </div>
                        <div className="col-auto">
                          <button
                            onClick={(e) => { e.stopPropagation(); EditMultiBuyModal(true, o) }}
                            type="button" data-bs-toggle="modal" data-bs-target="#edit-multi-modal"
                            id="EditMul"
                            className="btn btn-sm btn-outline-dark">Edit
                          </button>
                        </div>
                      </td>
                    </tr>)
                  })}
                </tbody>
              </table>
            </div>
            <div className="text-center">
              {
                (data && data.length > 0 && totalCount > 1) && <Pagination
                  id="pagination"
                  activePage={activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={totalCount * 10}
                  pageRangeDisplayed={5}
                  onChange={(e) => handleChange(e)}
                />
              }
            </div>
          </div>
        </div>
      </div>

      {isOpenMultiBuyModal && (
        <MultiBuyModal
          id="MultiBuyModal"
          isOpenMultiBuyModal={isOpenMultiBuyModal}
          setOpenMultiBuyModal={() => initialState()}
          setOfferType={(e) => setOfferType(e)}
          setOfferValue={(e) => setOfferValue(e)}
          setExpiryOn={(e) => setExpiryOn(e)}
          setValidFrom={(e) => setValidFrom(e)}
          setProducts={(value) => selectedProducts(value)}
          setQuantity={(e) => setQuantity(e)}
          manageMultiBuy={(e) => manageMultiBuy(e)}
          offerValue={offerValue}
          expiryOn={expiryOn}
          validFrom={validFrom}
          offerType={offerType}
          productList={productList}
          quantity={quantity}
          isCreate={true}
          validationError={validationError}
          setFilterValidationError={setFilterValidationError}
          productType={productType}
          setProductType={selectedProductType}
          isLoading={loading}
        />
      )}
      {
        isEditMultiBuyModal && (
          <MultiBuyModal
            id="EditMulti"
            isOpenMultiBuyModal={isEditMultiBuyModal}
            setOpenMultiBuyModal={() => initialState()}
            setOfferType={(e) => setOfferType(e)}
            setOfferValue={(e) => setOfferValue(e)}
            setExpiryOn={(e) => setExpiryOn(e)}
            setValidFrom={(e) => setValidFrom(e)}
            setProducts={(value) => selectedProducts(value)}
            setQuantity={(e) => setQuantity(e)}
            manageMultiBuy={(e) => manageMultiBuy(e)}
            offerValue={offerValue}
            expiryOn={expiryOn}
            validFrom={validFrom}
            offerType={offerType}
            productList={productList}
            quantity={quantity}
            validationError={validationError}
            productName={productName}
            setFilterValidationError={setFilterValidationError}
            setProductType={selectedProductType}
            isLoading={loading}
          />
        )
      }
      {
        multiBuyDetailModal &&
        <MultiBuyDetailModal
          id='multiBuyDetailModal'
          isMultiBuyDetailModal={multiBuyDetailModal}
          isCloseModal={() => setMultiBuyDetailModal(false)}
          multiBuyDetails={multiBuyDetails} />
      }
    </div>
  );
};
export default MultiBuy;
