export const sort_products = (product_lists, containerSize) => {
    // return product_lists
    try {
        const y_thres = 0.15 //0.04 - 0.11
        let rows = {}
        product_lists.forEach((item) => {
            let found = false;
            const ytl = item.mark.y / containerSize.height
            const ybr = (item.mark.y + item.mark.height) / containerSize.height
            const mean_height = (parseFloat(ybr) + parseFloat(ytl)) / 2
            let rowKeyList = Object.keys(rows)
            for (let row_index in rowKeyList) {
                let row_key = rowKeyList[row_index]
                const cur_row = rows[row_key]
                const sumValue = cur_row.reduce((total, r) => {
                    const ytl = (r.mark.y)  / containerSize.height
                    const ybr = (r.mark.y + item.mark.height) / containerSize.height
                    return total + ((parseFloat(ytl) + parseFloat(ybr)) / 2)
                }, 0);
                const row_mean_height = sumValue / cur_row.length
                if (Math.abs(row_mean_height - mean_height) <= y_thres) {
                    rows[row_key] = [...rows[row_key], item]
                    found = true;
                    break
                }
            }
            if (!found) {
                rows[Object.keys(rows).length] = [item]
            }
        })

        let sorted_list = []
        Object.keys(rows).forEach((o, i) => {
            sorted_list.push({ 'index': i, 'y': rows[i][0].mark['y'] })
        })
        sorted_list = sorted_list.sort((a, b) => (a['y'] - b['y']))
        let product_list = sorted_list.map((o, i) => (rows[o['index']].sort((a, b) => (a.mark.x - b.mark.x)))).flat(1)
        return product_list
    } catch (error) {
        console.log(error)
        return product_lists
    }

}
