import React, { forwardRef } from 'react';
import TimePicker from 'rc-time-picker';
import styled from 'styled-components';

const TIME_FORMAT = 'hh:mm a';

const StyledTimePicker = styled(TimePicker)`
 &.rc-time-picker-panel-select-option-selected{
    background-color: #edeffe !important;
  }
`;
const AppTimePicker = ({ ...props }) => {
    const CustomInput = forwardRef(({ value, onClick }, ref) => (
        <div id='TimePicker' onClick={onClick}>
            {/* <input
                style={{
                    backgroundColor: 'transparent',
                    border: `1px solid ${COLORS.appThemeHex}`,
                    color: COLORS.appThemeHex,
                    marginTop: '0px'
                }}
                defaultValue={value}
                className="input"

                ref={ref}
            /> */}
        </div>

    ));
    return (
        <div>
            <StyledTimePicker
                {...props}
                format={TIME_FORMAT}
                customInput={<CustomInput />}
                showSecond={false}
                hideDisabledOptions
                minuteStep={1}
                use12Hours

            />
        </div>
    );

}

export default AppTimePicker;