import Actions from "../actions";

const initialState = {
    isLoading: false,
    products: [],
    error: false,
    formattedProducts: []
}
const PlanogramProductReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.PLANOGRAM_PRODUCT_LIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.PLANOGRAM_PRODUCT_LIST_SUCCESS: {
            const data = action?.data?.response?.products?.map(d => ({
                label: `${d?.productName} (${d?.sku})`, value: d?.sku
            }));
            return {
                ...state,
                isLoading: false,
                products: action?.data?.response?.products || [],
                formattedProducts: data,
                error: false
            };
        }
        case Actions.PLANOGRAM_PRODUCT_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                products: undefined,
                error: action.error,
            };
        }
        default:
            return state;
    }
};

export default PlanogramProductReducer;

export const getPlanogramProductListSelector = state => state?.PLANOGRAM_PRODUCT_LIST?.products;
export const getPlanogramFormattedProductListSelector = state => state?.PLANOGRAM_PRODUCT_LIST?.formattedProducts;