import Actions from "../actions";

const initialState = {
    isLoading: false,
    productsList: [],
    createProduct: [],
    updateProduct: [],
    error: false,
    createLoading: false,
    createError: false,
    updateLoading: false,
    updateError: false
}
const ProductsReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.PRODUCTS: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.PRODUCTS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                productsList: action?.data?.response?.products || [],
                error: false
            };
        }
        case Actions.PRODUCTS_FAILURE: {
            return {
                ...state,
                isLoading: false,
                productsList: [],
                error: action.error,
            };
        }
        case Actions.CREATE_PRODUCT: {
            return {
                ...state,
                createLoading: true,
            };
        }
        case Actions.CREATE_PRODUCT_SUCCESS: {
            return {
                ...state,
                createLoading: false,
                createProduct: action.data,
                error: false
            };
        }
        case Actions.CREATE_PRODUCT_FAILURE: {
            return {
                ...state,
                createLoading: false,
                error: action.error,
            };
        }
        case Actions.UPDATE_PRODUCT: {
            return {
                ...state,
                updateLoading: true,
            };
        }
        case Actions.UPDATE_PRODUCT_SUCCESS: {
            return {
                ...state,
                updateLoading: false,
                updateProduct: action.data.content,
                error: false
            };
        }
        case Actions.UPDATE_PRODUCT_FAILURE: {
            return {
                ...state,
                updateLoading: false,
                error: action.error,
            };
        }
        case Actions.BISTRO_PRODUCT_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                productsList: action?.data?.response?.response || [],
                error: false
            };
        }
        default:
            return state;
    }
};

export default ProductsReducer;

export const getProductListSelector = state => state?.PRODUCTS?.productsList;
export const getProductListLoadingSelector = state => state?.PRODUCTS?.isLoading;