import React from 'react'
import NoResult from '../../../src/asset/new/img/empty.png'
class EmptyPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    render() {
        const { content } = this.props
        return (
            <React.Fragment>
                <div className="text-center">
                    <img src={NoResult} alt="Nol Results" /><br /><br />
                    <h2 className="font16 mb-2 fw-bold">
                        {content}
                    </h2>
                </div>
            </React.Fragment>
        )
    }
}

export default EmptyPage;