import React from "react";
import AppDatePicker from "../../../components/fields/AppDatePicker";
import BoldError from "../../../components/fields/BoldError";
import CustomerTypeAhead from "../../../components/fields/CustomerTypeAhead";
import Constants from "../../../utils/Constants";
import Close from '../../../../asset/new/img/modal-close.svg'
import AppLoader from "../../../components/fields/AppLoader";


const CustomerCouponModal = ({ manageCoupon, setOpenCouponModal,
    isCreate, setCouponCode, couponCode, description, setDescription, setOfferType,
    customers, couponValue, setCouponValue, offerType, selectedCustomers, handleChangeCustomers,
    validationError, isSelectAllCustomer, setAllCustomers, searchCustomer, validFrom,
    setValidFrom, setExpiryOn, expiryOn, Searchparams, isLoading }) => {

    console.log(customers,'cus')
    return <React.Fragment>
        <div className="modal d-block"
            id="unblock-modal"
            tabIndex="-1"
            aria-labelledby="unblock"
            aria-hidden="true">

            <div tabIndex="-1" aria-labelledby="add-coupon-modal" aria-hidden="true">

                <div className="modal-dialog modal-dialog-centered">
                    {isLoading && <AppLoader />}
                    <div className="modal-content rounded-0">
                        <img src={Close} alt="X" data-bs-dismiss="modal" aria-label="Close" id="Close"
                            className="modal-close" onClick={() => {
                                setOpenCouponModal(false)
                            }} />
                        <div className="modal-header rounded-0 align-items-center py-4">
                            <div className="col">
                                <h5 className="modal-title">{isCreate ? 'Add Customer Coupon' : 'Edit Customer Coupon'}</h5>
                            </div>
                        </div>
                        <div className="modal-body font13 p-40">
                            <div className="row mb-4">
                                {validationError && <BoldError message={validationError} />}
                                <div className="col-6">
                                    <label for="offer-type" className="form-label fw-bold">Offers</label>
                                    <select id="offer-type" name="" className="form-select" onChange={(e) => setOfferType(e?.target?.value)} value={offerType}>
                                        <option
                                            value="">Select offer type</option>
                                        {
                                            Constants.CUSTOMER_COUPON_DISCOUNTS.map((o) => {
                                                return (
                                                    <option key={''} value={o.value}>{o.key}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="col-6">
                                    <label for="" className="form-label fw-bold">Coupon Value</label>
                                    <div className="input-group">
                                        {offerType === 'PERCENTAGE' ? <span className="input-group-text border-dark">%</span> :
                                            <span className="input-group-text border-dark">$</span>}
                                        <input
                                            className="form-control"
                                            id='setCouponValue'
                                            defaultValue={couponValue}
                                            placeholder="Enter Coupon Value"
                                            onChange={(e) => setCouponValue(e?.target?.value)}
                                            type="number"
                                            onInput={offerType === 'PERCENTAGE' ? (e) => {
                                                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 3)
                                            } : ""} />
                                    </div>

                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-12 col-sm-6">
                                    <label for="coupon-code"
                                        className="form-label fw-bold">Coupon Code</label>
                                    {!isCreate ?
                                        <input id="coupon-code1"
                                            type="text" name=""
                                            className="form-control"
                                            defaultValue={couponCode}
                                            placeholder="Enter coupon code"
                                            onChange={(e) => setCouponCode(e)}
                                            disabled /> : <input
                                            id="coupon-code2" type="text" name="" className="form-control"
                                            defaultValue={couponCode}
                                            placeholder="Enter coupon code"
                                            onChange={(e) => setCouponCode(e?.target?.value)}
                                        />}
                                </div>
                                <div className="col-6">
                                    <label for="coupon-code" className="form-label fw-bold">Description</label>
                                    <input id="coupon-code" type="text" name="" className="form-control"
                                        defaultValue={description}
                                        placeholder="Enter description"
                                        onChange={(e) => setDescription(e?.target?.value)} />
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-12 col-sm-6">
                                    <label for="from-date" className="form-label fw-bold">Valid From</label>
                                    <AppDatePicker
                                        id='setValidFrom'
                                        className="cursor-pointer"
                                        value={validFrom}
                                        selected={validFrom}
                                        minDate={new Date()}
                                        placeholder="Valid From"
                                        onChange={(date) => setValidFrom(date)}
                                    />
                                </div>
                                <div className="col-6">
                                    <label for="to-date" className="form-label fw-bold">Valid To</label>
                                    <AppDatePicker
                                        id='setExpiryOn'
                                        value={expiryOn}
                                        className="cursor-pointer"
                                        selected={expiryOn}
                                        minDate={validFrom ? validFrom : new Date()}
                                        placeholder="Valid To"
                                        onChange={(date) => setExpiryOn(date)}
                                    />
                                </div>
                            </div>


                            <div className="row mb-4">
                                <div className="col-auto">
                                    <label for="customer-switch"
                                        className="form-label fw-bold cursor-pointer">Apply for all customers
                                    </label>
                                </div>
                                <div className="col">
                                    <div className="form-check form-switch">
                                        <input
                                            className="form-check-input cursor-pointer"
                                            type="checkbox"
                                            role="switch"
                                            id="customer-switch"
                                            checked={isSelectAllCustomer}
                                            onChange={(e) => setAllCustomers(e)} />
                                    </div>
                                </div>
                            </div>

                            {
                                !isSelectAllCustomer && <div className="mb-3">
                                    <CustomerTypeAhead
                                        id='handleChangeCustomers'
                                        arrayValue={customers}
                                        fields={selectedCustomers}
                                        handleChange={(e) => handleChangeCustomers(e)}
                                        searchCustomer={searchCustomer}
                                    />
                                </div>
                            }

                            <div className="row mb-4">
                                <div className="col-7 pe-0">
                                    <span className="btn btn-success font14 w-100 p-3 cursor-pointer" id="CreateOrUpdate"
                                        onClick={() => manageCoupon(isCreate ? true : false)}>
                                        {isCreate ? 'Create' : 'Update'}
                                    </span>
                                </div>
                                <div className="col-5 text-end" id="Cancel" onClick={() => setOpenCouponModal(false)}>
                                    <span className="btn btn-outline-danger font14 w-100 p-3 cursor-pointer">
                                        Cancel
                                    </span>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div className="modal-backdrop show">
        </div>
    </React.Fragment >
};

export default CustomerCouponModal;