import cloneDeep from "lodash.clonedeep";
import { all, put, takeLatest, select } from "redux-saga/effects";
import { doAuthPut } from "../../../utils/service";
import END_POINTS from "../../../utils/service/Endpoints";
import Actions from "../../actions";
import { bistroDisplayListSelector } from "../../reducers/BistroDisplayReducer";

export function* bistroChefCompleteOrderSaga({ params, callback }) {
    const req = {
        ...params,
        isPrepared: true
    }
    try {
        const response = yield doAuthPut(END_POINTS.CHEF_COMPLETE_ORDER, req);
        if (response.response_code === 0) {
            const bistroOrderList = yield select(bistroDisplayListSelector);
            const bistroOrderListCopy = cloneDeep(bistroOrderList)
            const index = bistroOrderListCopy?.findIndex(f => f.id === response.response.id);
            if (index !== -1) {
                bistroOrderListCopy.splice(index, 1, response.response);
            }
            const temp = { response: bistroOrderListCopy }
            yield put(Actions.bistroDisplayOrderListSuccessAction(temp))
        }
        callback && callback(response)
    }
    catch (error) {
        callback && callback(error)
        // yield put(Actions.bistroDisplayOrderListFailureAction({ error }));
    }
}

export default function* bistroDisplayListner() {
    yield all([
        takeLatest(Actions.BISTRO_CHEF_COMPLETE_ORDER, bistroChefCompleteOrderSaga),
    ]);
}