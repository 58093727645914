import { all, put, takeLatest } from "redux-saga/effects";
import MomentFunctions from "../../utils/commonFunctions/MomentFunctions";
import { clientCredentials } from "../../utils/service";
import END_POINTS from "../../utils/service/Endpoints";
import Actions from "../actions";

export function* getClientCredentials() {
    try {
        const formData = new FormData();
        formData.append('grant_type', 'client_credentials')
        const response = yield clientCredentials(END_POINTS.CLIENT_CREDENTIALS, formData);
        if (response && response.access_token) {
            response.expMilliseconds = MomentFunctions.convertExpirySecToMilliSec(response.expires_in);
        }
        yield put(Actions.clientCredentialsSuccess(response));
    }
    catch (error) {
        yield put(Actions.clientCredentialsFailure({ error }));
    }
}


export default function* credentialsListener() {
    yield all([
        takeLatest(Actions.CLIENT_CREDENTIALS, getClientCredentials),
    ]);
}
