import { all, put, takeLatest } from "redux-saga/effects";
import { doAuthGet, doAuthPost } from "../../utils/service";
import END_POINTS from "../../utils/service/Endpoints";
import Actions from "../actions";

export function* getCustomerBlockingListSaga({ params, callback }) {
    try {
        let response = yield doAuthGet(END_POINTS.INACTIVE_CUSTOMER_LIST, params)
        if (response) {
            yield put(Actions.getInactiveUsersListListSuccess(response));
        }
        if (callback) {
            callback(response);
        }
    } catch (error) {
        callback && callback(error);
        yield put(Actions.getInactiveUsersListListFailure({ error }));
    }
}
export function* CustomerUnBlockingListSaga({ params, callback }) {
    try {
        let response = yield doAuthPost(END_POINTS.UNBLOCKING_USER, params)
        if (callback) {
            callback(response);
        }
    } catch (error) {
        callback && callback(error);
        yield put(Actions.createCustomerUnBlockingFailure({ error }));
    }
}



export default function* customerUnBlockingListener() {
    yield all([
        takeLatest(Actions.INACTIVE_USERS, getCustomerBlockingListSaga),
        takeLatest(Actions.CUSTOMER_UNBLOCKING_CREATE, CustomerUnBlockingListSaga),
    ]);
}

