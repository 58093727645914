import * as loginActions from './LoginAction';
import * as clientActions from './ClientCredentialsAction';
import * as orderActions from './OrdersAction';
import * as productsActions from './ProcuctAction';
import * as foodProductsActions from './FoodProductsAction';
import * as refundActions from './RefundActions';
import * as requestOtp from './RequestOtp'
import * as updatePassword from './UpdatePassword'
import * as verifyOtp from './VerifyOtp'
import * as comboOffer from './ComboOfferListAction';
import * as DollarAction from './DollarOfferAction'
import * as OfferAction from './OfferAction'
import * as multiBuyAction from './MultiBuyAction'
import * as freeItemList from './FreeItemListAction'
import * as customerCouponActions from './CustomerCouponAction';
import * as happyHoursItemAction from './HappyHoursItemAction';
import * as chatsAction from './ChatsAction';
import * as navigationDrawerAction from './NavigationDrawerAction';
import * as storeAction from './StoreAction';
import * as PromotionDashboardAction from './PromotionDashboardAction';
import * as GetDashboardDetailsAction from './GetDashboardDetailsAction';
import * as CustomerUnBlockingAction from './CustomerUnBlockingAction';
import * as ReportListTypeAction from './ReportListTypeAction';
import * as NotificationsAction from './NotificationsAction';
import * as SubscriptionAction from './SubscriptionAction'
import * as GetDaxbotAction from './GetDaxbotAction'
import * as BistroProductList from './BistroProductList'
import * as InvoiceDetailsAction from './InvoiceDetailsAction'
import * as ProductGroupInformation from './ProductGroupInformationAction'
import * as BistroOrderDisplayListAction from './BistroDisplayAction'
import * as ChangePassword from './ChangePassword';
import * as InvoiceListAction from './InvoiceListAction';
import * as HitachiMonitoring from './HitachiMonitoring'
import * as BistroChefActions from './bistroChef/BistroChefAction';
import * as QrCodeAction from './QrCodeAction'
import * as StoreAvailability from './StoreAvailabilityAction'
import * as BranchListAction from './BranchListActions'
import * as Planogram from './PlanogramAction'
import * as CvCameraList from './planogramCV/GetCameraListAction'
import * as GetCvPlanogramDetails from './planogramCV/GetPlanogramDetailsAction'
import * as RackCaptureAction from './planogramCV/RackCaptureAction'
import * as AutoFillAction from './planogramCV/AutoFillAction'
import * as UpdatePlanogramProduct from './planogramCV/UpdatePlanogramProducts'
import * as BaySaveProductListAction from './BaySaveProductListAction'
const Actions = {
    ...loginActions,
    ...clientActions,
    ...orderActions,
    ...productsActions,
    ...foodProductsActions,
    ...refundActions,
    ...requestOtp,
    ...updatePassword,
    ...verifyOtp,
    ...comboOffer,
    ...DollarAction,
    ...OfferAction,
    ...freeItemList,
    ...multiBuyAction,
    ...customerCouponActions,
    ...happyHoursItemAction,
    ...chatsAction,
    ...navigationDrawerAction,
    ...storeAction,
    ...PromotionDashboardAction,
    ...GetDashboardDetailsAction,
    ...ReportListTypeAction,
    ...CustomerUnBlockingAction,
    ...NotificationsAction,
    ...SubscriptionAction,
    ...GetDaxbotAction,
    ...BistroProductList,
    ...InvoiceDetailsAction,
    ...ProductGroupInformation,
    ...BistroOrderDisplayListAction,
    ...ChangePassword,
    ...BistroChefActions,
    ...InvoiceListAction,
    ...HitachiMonitoring,
    ...QrCodeAction,
    ...StoreAvailability,
    ...BranchListAction,
    ...Planogram,
    ...CvCameraList,
    ...GetCvPlanogramDetails,
    ...RackCaptureAction,
    ...AutoFillAction,
    ...UpdatePlanogramProduct,
    ...BaySaveProductListAction
}

export default Actions;

export const doAction = (type, params = {}, callback) => ({ type, params, callback });
export const doResponseAction = (type, data = {}) => ({ type, data });
