import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from "react-redux";
import Actions from "../../redux/actions";
import Constants from '../../utils/Constants';
import moment from 'moment'
import AppButton from '../../components/fields/AppButton';
import ManualRefundModal from './ManualRefund.js';
import AppLoader from '../../components/fields/AppLoader';
import Close from '../../../asset/new/img/modal-close.svg'
import Pending from '../../../asset/new/img/pending.svg'

export const sortCheckoutOrders = (a, b) => {
    if (a.type < b.type) {
        return -1;
    }
    if (a.type > b.type) {
        return 1;
    }
    return 0;
}


export const reduceCheckourOrders = (previousValue, currentValue) => {
    if (previousValue.length === 0) {
        if (currentValue.orderType === 1) {
            currentValue.sectionHeader = Constants.FOOTER.bistro;
        } else if (currentValue.orderType === 2) {
            currentValue.sectionHeader = Constants.FOOTER.groceries;
        }
        return [...previousValue, currentValue];
    }

    if (previousValue[previousValue.length - 1].orderType === currentValue.orderType) {
        return [...previousValue, currentValue];
    } else if (previousValue[previousValue.length - 1].orderType !== currentValue.orderType) {
        if (currentValue.orderType === 1) {
            currentValue.sectionHeader = Constants.FOOTER.bistro;
        } else if (currentValue.orderType === 2) {
            currentValue.sectionHeader = Constants.FOOTER.groceries;
        }
        return [...previousValue, currentValue];
    }
};

class InvoiceDetails extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isManualRefund: false,
            refundableAmount: null,
            amountToRefund: null,
            errorMessage: null,
            comment: null
        }
    }
    componentDidMount() {
        const { params } = this.props.match || {}
        const options = {
            invoiceId: params?.id
        }
        this.props.invoiceDetails(options)
    }
    renderOrderStatus(paymentStatus) {
        if (paymentStatus === 0) {
            return <span
                className="payment-green">{Constants.ORDER_SUMMARY.paymentType.completed}</span>
        } else if (paymentStatus === 1) {
            return <span
                className="payment-metalic">{Constants.ORDER_SUMMARY.paymentType.pending}</span>
        } else if (paymentStatus === 2) {
            return <span
                className="payment-blue">{Constants.ORDER_SUMMARY.paymentType.processing}</span>
        } else if (paymentStatus === 3) {
            return <span
                className="payment-red">{Constants.ORDER_SUMMARY.paymentType.failed}</span>
        } else if (paymentStatus === 4) {
            return <span
                className="payment-red">{Constants.ORDER_SUMMARY.paymentType.pendingFailed}</span>
        } else if (paymentStatus === 11) {
            return <span
                className="payment-gray">{Constants.ORDER_SUMMARY.paymentType.refundInitiated}</span>
        } else if (paymentStatus === 12) {
            return <span
                className="payment-green">{Constants.ORDER_SUMMARY.paymentType.refundApproved}</span>
        } else if (paymentStatus === 13) {
            return <span
                className="payment-red">{Constants.ORDER_SUMMARY.paymentType.refundDenied}</span>
        }
    }
    _renderAddress(natureOfOrder, deliveryAddress) {
        let address = {};
        try {
            address = JSON.parse(deliveryAddress)
        } catch (e) {
            console.log(e)
        }
        let { landmark, street, formattedAddress } = address || {}
        return natureOfOrder === 1 ? <><h3 className="font16 fw-bold mb-2">Delivery address</h3>
            <p className="font14 mb-2">{street ? `${street},` : ''} {landmark ? `${landmark},` : ''} {formattedAddress}</p> </> : <>
            <h3 className="font16 fw-bold mb-2">Pickup address</h3>
            <p className="font14 mb-2">5 Polaris Way, Aliso Viejo, CA 92656</p>
        </>
    }

    getOfferDetailsList(viewInvoice) {
        const { promotionJson } = viewInvoice || {};
        let promotions = [];
        try {
            promotions.push(JSON.parse(promotionJson));
        } catch (e) {
            console.log(e)
        }
        return promotions.length > 0 ? promotions : [];

    }
    getCartObject(invoiceOrderDetailsList) {
        let invoiceOrgOrderList = []
        if (invoiceOrderDetailsList && invoiceOrderDetailsList.length > 0) {
            invoiceOrderDetailsList.forEach((item, index) => {
                invoiceOrgOrderList = [
                    ...invoiceOrgOrderList,
                    ...item?.invoiceOrderProductDetailsList,
                ];
            });
        }
        return invoiceOrgOrderList
    }
    _renderCustomerName(invoiceDetails) {
        return invoiceDetails?.customerFirstName ? <h5>{invoiceDetails?.customerFirstName}
            {invoiceDetails?.customerLastName}</h5> :
            <h5>Customer Name: Guest User</h5>
    }

    closeRefundModal() {
        this.setState({ isManualRefund: false })
    }

    openRefundModal(obj) {
        this.setState({ isManualRefund: true, refundableAmount: obj?.refundableAmount })
    }
    intiateRefund() {
        const { amountToRefund, refundableAmount, comment } = this.state
        if (!amountToRefund) {
            this.setState({ errorMessage: 'Please enter the refund amount.' })
            return;
        } else if (amountToRefund <= 0) {
            this.setState({ errorMessage: 'Please enter valid refund amount' })
            return;
        } else if (amountToRefund > refundableAmount) {
            this.setState({ errorMessage: 'Refundable amount limit is exceeded' })
            return;
        } else if (!comment) {
            this.setState({ errorMessage: 'Please enter the comments' })
            return;
        } else if (comment?.length < 5) {
            this.setState({ errorMessage: 'Please enter the comments more than 5 letters' })
            return;
        } else {
            const callback = (res) => {
                if (res?.response_code === 0) {
                    window.location.reload();
                } else if (res?.error) {
                    this.setState({ errorMessage: res?.error })
                } else {
                    this.setState({ errorMessage: res?.response_message })
                }
            }

            const { params } = this.props.match || {}
            let obj = {
                invoiceId: params?.id,
                amountToRefund,
                comment
            }
            this.props.manaualRefund(obj, callback)
        }
    }
    getRefundAmount(value) {
        this.setState({ amountToRefund: value })
    }
    getComments(value) {
        this.setState({ comment: value })
    }

    renderRefundButton(obj) {
        const { roleId } = this.props.GetRoleId || {}
        let indexofNonCompleted = obj?.invoiceOrderDetailsList &&
            obj?.invoiceOrderDetailsList?.findIndex(i =>
                i.orderStatus !== Constants.ORDER_STATUS.DELIVERED &&
                i.orderStatus !== Constants.ORDER_STATUS.DECLINED,
            );
        let indexofCompleted = obj?.invoiceOrderDetailsList &&
            obj?.invoiceOrderDetailsList?.findIndex(i => i.orderStatus === Constants.ORDER_STATUS.DELIVERED);
        return (
            <>
                {
                    indexofNonCompleted === -1 &&
                    indexofCompleted !== -1 &&
                    obj?.createdBy !== Constants.ORDER_TYPES.CREATED_BY_VISION_CHECKOUT &&
                    obj?.refundableAmount?.toFixed(3) > 0.009 &&
                    (obj?.paymentStatus === 0 || obj?.paymentStatus === 11 || obj?.paymentStatus === 12 ||
                        obj?.paymentStatus === 13) && roleId === Constants.ROLES.STORE_MANAGER || roleId === Constants.ROLES.ADMIN &&
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <AppButton title={"Refund"}
                            onClick={() => this.openRefundModal(obj)} />
                    </div>

                }

            </>
        )

    }
    _renderOrderType(actions, type) {
        switch (actions) {
            case 1:
                return 'WIWO';
            case 2:
                return type?.natureOfOrder === 1 ? "Door Delivery" : "Store Pickup";
            case 3:
                return 'Hybrid';
            case 4:
                return 'Scan & Go';

            default:
                return '';
        }
    }
    render() {
        const { invoiceDetails, isLoading } = this.props.getInvoiceDetails || {}
        const { setViewInvoiceModal, InvoiceDetails } = this.props
        const {
            deliveryAddress, natureOfOrder,
            invoiceOrderDetailsList
        } = InvoiceDetails || {}
        const { isManualRefund, refundableAmount, errorMessage } = this.state
        return (
            <React.Fragment>
                {/* <div className="inner-container">
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            <h3>{`Invoice number #${invoiceDetails?.invoiceId || ''}`}</h3>
                            <div style={{ marginTop: '10px', marginLeft: '20px' }}>
                                <OrderStatusBadge status={invoiceDetails?.orderStatus}
                                    natureOfOrder={invoiceDetails?.natureOfOrder} />
                            </div>
                        </div>
                        {this.renderRefundButton(invoiceDetails)}
                    </div>
                    <div style={{ textAlign: "left" }}>
                        <p>
                            <b>Order Type: </b>{this._renderOrderType(invoiceDetails?.createdBy, natureOfOrder)}
                        </p>
                        <p>
                            <b>{Constants.ORDER_SUMMARY.orderDetails.orderStatus}
                                {this.renderOrderStatus(invoiceDetails?.paymentStatus)}</b>
                        </p>
                        {this._renderCustomerName(invoiceDetails)}
                        {
                            invoiceDetails?.mobileNumber && <h5>Phone Number: <b>{invoiceDetails?.mobileNumber}</b></h5>
                        }
                        <h5>Order Date : {moment(invoiceDetails?.invoiceDate).format('MM/DD/YYYY - hh:mm A')}</h5>


                        {invoiceDetails?.createdBy === 2 && this._renderAddress(natureOfOrder, deliveryAddress)}
                        {invoiceDetails?.refundDetails && (
                            <>
                                <p><b>Refund Details</b></p>
                                <p>Refund no : {invoiceDetails?.refundDetails?.refundDetailsId}</p>
                                <p>Requested date: {moment(invoiceDetails?.refundDetails?.createdDate).format('MM/DD/YYYY - hh:mm A')}</p>
                                <p>Requested amount: $ {invoiceDetails?.refundDetails?.totalAmount?.toFixed(2)}</p>
                                {
                                    invoiceDetails?.refundDetails?.actionComment && <p>Decline Reason: <b>{invoiceDetails?.refundDetails?.actionComment}</b></p>
                                }
                            </>)}
                    </div>
                    {
                        invoiceOrderDetailsList && invoiceOrderDetailsList.sort(sortCheckoutOrders)
                            .reduce(reduceCheckourOrders, []).map((details) => {
                                const { invoiceOrderProductDetailsList: data, customerComment } = details;
                                return customerComment && <span><b>Customer Notes:</b> {customerComment}</span>
                            })
                    }
                    <div style={{ marginTop: '20px', display: 'flex', flex: 1, textAlign: 'left' }}>
                        <table className="table my-5">
                            {
                                invoiceOrderDetailsList && invoiceOrderDetailsList.sort(sortCheckoutOrders)
                                    .reduce(reduceCheckourOrders, []).map((details) => {
                                        const { invoiceOrderProductDetailsList: data, customerComment } = details;
                                        return (
                                            <>
                                                {details.sectionHeader &&
                                                    <>
                                                        <tr>
                                                            <td colSpan="6">
                                                                <h4
                                                                    style={{
                                                                        fontWeight: '600',
                                                                        fontSize: '16px'
                                                                    }}>{details?.sectionHeader}
                                                                </h4>
                                                            </td>
                                                            <td colSpan="6"> <h4
                                                                style={{
                                                                    textAlign: 'right',
                                                                    fontWeight: '600',
                                                                    fontSize: '16px'
                                                                }}>  Order number : {details?.invoiceOrderDetailsId}</h4></td>
                                                        </tr>
                                                        <tr>
                                                            <th colspan="2">Name</th>
                                                            <th colspan="2">SKU</th>
                                                            <th colspan="2">Addons</th>
                                                            <th colspan="2">Age restricted</th>
                                                            <th colspan="2" style={{ textAlign: "right" }}>Price</th>
                                                        </tr>
                                                        {data?.map((o) => {
                                                            return <>
                                                                <tbody>
                                                                    <tr>
                                                                        <td className='title-case' colspan="2">{o.quantity} x {o.name?.toLowerCase()}
                                                                            {
                                                                                o?.refundQuantity > 0 && (
                                                                                    <>
                                                                                        <p style={{ marginTop: '10px' }}>Refund Quantity: {o?.refundQuantity}</p>
                                                                                        <p> Refund Reason: {RefundOptions(o?.refundType)}</p>
                                                                                    </>
                                                                                )
                                                                            }
                                                                        </td>
                                                                        <td colspan="2">{o?.upc}</td>
                                                                        <td colspan="2">{o?.invoiceProductAddOnMappingList?.length > 0 ? o?.invoiceProductAddOnMappingList?.map((o) => o?.name).join(',') : 'No add ons'}</td>
                                                                        <td colspan="2"><div style={{ color: 'red' }}>{o?.ageRestricted ? `18+` : ""}</div></td>
                                                                        <td colspan="2" style={{ textAlign: 'right' }}> ${o?.invoiceProductAddOnMappingList.length > 0 ? o.totalAmountExcludingTax : o?.amountExcludingTax.toFixed(2)}</td>
                                                                    </tr>
                                                                </tbody>

                                                            </>
                                                        })}

                                                    </>

                                                }

                                            </>)
                                    })}


                            <tbody>
                                {this.getOfferDetailsList(invoiceDetails).map((offers, index) => {
                                    return <InvoiceSummary
                                        cartOffer={offers}
                                        natureOfOrder={natureOfOrder}
                                    />
                                })}
                                <>
                                    <tr style={{ borderStyle: 'hidden' }}>
                                        <td colSpan="8" style={{ textAlign: 'right' }}> <b>{Constants.ORDER_SUMMARY.net} </b>: </td>
                                        <td colSpan="4" style={{ textAlign: 'left' }}> {`$ ${parseFloat(invoiceDetails?.totalAmountExcludingTax)?.toFixed(2)}`}</td>
                                    </tr>
                                    <tr style={{ borderStyle: 'hidden' }}>
                                        <td colSpan="8" style={{ textAlign: 'right' }}> <b>{Constants.ORDER_SUMMARY.vat} </b>: </td>
                                        <td colSpan="4" style={{ textAlign: 'left' }}>{`$ ${parseFloat(invoiceDetails?.totalTaxAmount)?.toFixed(2)}`}</td>
                                    </tr>
                                </>
                                {
                                    this.getOfferDetailsList(invoiceDetails).map((offers, index) => {
                                        return <OrderSummaryOfferDetails
                                            cartOffer={offers}
                                            cartItems={this.getCartObject(invoiceOrderDetailsList)}
                                            natureOfOrder={natureOfOrder}
                                        />
                                    })
                                }
                            </tbody>

                            <tbody>
                                <tr style={{ borderStyle: 'hidden' }}>
                                    <td colspan="8" style={{ textAlign: 'right' }}> <b>{Constants.ORDER_SUMMARY.total} </b>: </td>
                                    <td colspan="4" style={{ textAlign: 'left' }}>{`$${parseFloat(invoiceDetails?.totalAmount)?.toFixed(2)}`}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div style={{ marginTop: '20px', textAlign: 'left' }}>
                        <div>
                            {
                                invoiceDetails?.manualRefunds?.length > 0 && <>
                                    <h4>Initiated Refund: </h4>
                                    <div className='card'
                                        style={{ width: '100%' }}>
                                        <div className='card-body' style={{ padding: '10px' }}>
                                            <div className='row'>
                                                <div className='col-sm-4'>
                                                    <p>Refund Amount</p>
                                                </div>
                                                <div className='col-sm-4'>
                                                    <p>Comments</p>
                                                </div>
                                                <div className='col-sm-4'>
                                                    <p>Created Date & Time</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ border: '10px', borderTop: '4px solid #eee' }}></div>
                                        {invoiceDetails?.manualRefunds?.map((obj, i) => {
                                            return (
                                                <>
                                                    <div className='card-body' key={i} style={{ padding: '10px' }}>
                                                        <div className='row'>
                                                            <div className='col-sm-4'>
                                                                <p>${obj?.refundAmount}</p>
                                                            </div>
                                                            <div className='col-sm-4'>
                                                                <p className='desc-wrap' title={obj?.comment}>{obj?.comment}</p>
                                                            </div>
                                                            <div className='col-sm-4'>
                                                                <p>{moment(obj?.lastModified).format('MM/DD/YYYY - hh:mm A')}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        invoiceDetails?.manualRefunds?.length > 1 && <div style={{ border: '10px', borderTop: '4px solid #eee' }}></div>}
                                                </>
                                            )
                                        })}
                                    </div>
                                </>
                            }
                        </div>

                    </div>
                </div> */}
                {/* <!-- Order Details Modal  --> */}
                <div className="modal d-block" id="order-details-modal" tabIndex="-1" aria-labelledby="login" aria-hidden="true">
                    {/* <div className="modal fade" id="order-details-modal" tabIndex="-1" aria-labelledby="login" aria-hidden="true"> */}
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content rounded-0">

                            <img src={Close} alt="X" data-bs-dismiss="modal" aria-label="Close" id='close' className="modal-close" onClick={() => setViewInvoiceModal()} />

                            <div className="modal-header rounded-0 align-items-center py-4">
                                <div className="col"> <h5 className="modal-title">Order Details</h5> </div>
                                <div className="col font13 text-end text-warning">
                                    <img src={Pending} alt="Yes" className="me-1" /> Pending
                                </div>
                            </div>

                            <div className="modal-body font13 p-40">

                                <div className="row align-items-center mb-4">
                                    <div className="col-auto">
                                        <div className="p-3 font16 bg-lgrey text-center border border-dark rounded-circle">UN</div>
                                    </div>
                                    <div className="col">
                                        {InvoiceDetails?.customerFirstName ? `${InvoiceDetails?.customerFirstName} ${InvoiceDetails?.customerLastName} ` : 'Guest User'} <br />
                                        <strong>{InvoiceDetails?.mobileNumber}</strong>
                                    </div>
                                    <div className="col-auto text-end">
                                        <a className="btn btn-dark text-uppercase" data-bs-toggle="modal" data-bs-target="#tracking-modal" href="#" >Track</a>
                                    </div>
                                </div>
                                <h3 className="font16 fw-bold mb-2">Order Type: </h3>
                                <p className="font14 mb-2">{this._renderOrderType(InvoiceDetails?.createdBy, natureOfOrder)}</p>
                                {InvoiceDetails?.createdBy === 2 && this._renderAddress(natureOfOrder, deliveryAddress)}
                                {/* <h3 className="font16 fw-bold mb-2">Delivery address</h3>
                                <p className="font14 mb-3"><strong>Delivery Mode</strong> - XYZ </p> */}
                                <hr className="thick" />
                                <div className="row mb-4">
                                    <div className="col"> <h3 className="font16 fw-bold">Bill details</h3></div>
                                    <div className="col-auto text-end font14 fw-bold"> <a data-bs-toggle="modal" data-bs-target="#invoice-modal" href="#">View Invoice</a> </div>
                                </div>

                                {
                                    invoiceOrderDetailsList && invoiceOrderDetailsList.sort(sortCheckoutOrders)
                                        .reduce(reduceCheckourOrders, []).map((details) => {
                                            const { invoiceOrderProductDetailsList: data, customerComment } = details;
                                            return (
                                                data.map((o) => {
                                                    return (<div className="card card-body p-0 border-0 border-bottom pb-2 mb-2">
                                                        <div className="row">
                                                            <div className="col-auto"> <img src="img/products/1.png" alt="" className="product-thumb small" /> </div>
                                                            <div className="col">
                                                                <h3 className="font13 mb-1"> {o?.name}</h3>
                                                                <p className="mb-1"> <strong>Quantity x Price:</strong> 1 X $20.99 </p>
                                                                <p className="mb-1"> <strong>YOUR CUSTOMIZATION:</strong> Extra Cheese + Sauce Dip + Garlic Bread </p>
                                                                <p className="mb-1 font12 fw-bold "> SKU - 968686897686</p>
                                                            </div>
                                                            <div className="col-auto text-end fw-bold"> $20.99</div>
                                                        </div>
                                                    </div>)
                                                })
                                            )
                                        })}
                                <div className="dotted-divider pt-2 mt-2 mb-3">
                                    <div className="row mb-2">
                                        <div className="col">Receipt no</div>
                                        <div className="col-auto text-end">{InvoiceDetails?.invoiceId}</div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col">Order no</div>
                                        <div className="col-auto text-end"> {InvoiceDetails?.invoiceOrderDetailsList.map((o) => o?.invoiceOrderDetailsId)}</div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col">Order date</div>
                                        <div className="col-auto text-end">  {moment(InvoiceDetails?.invoiceDate).format('MM/DD/YYYY - hh:mm A')}</div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col">Sub Total</div>
                                        <div className="col-auto text-end"> ${parseFloat(InvoiceDetails?.totalAmountExcludingTax)?.toFixed(2)}</div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col">Sales Tax</div>
                                        <div className="col-auto text-end"> ${parseFloat(InvoiceDetails?.totalTaxAmount)?.toFixed(2)} </div>
                                    </div>
                                </div>

                                <div className="row mb-4">
                                    <div className="col fw-bold">Total</div>
                                    <div className="col-auto text-end fw-bold"> ${parseFloat(InvoiceDetails?.totalAmount)?.toFixed(2)} </div>
                                </div>

                                <div className="row mt-5">
                                    <div className="col-7 pe-0"> <a className="btn btn-success font14 w-100 p-3" href="#"> Accept</a> </div>
                                    <div className="col-5 text-end"> <a className="btn btn-outline-danger font14 w-100 p-3" href="#"> Decline</a> </div>
                                </div>

                            </div>

                        </div>
                        {/* </div> */}
                    </div>

                </div>
                <div classNameName="modal-backdrop show"></div>

                {isLoading && <div style={{
                    position: 'absolute',
                    inset: '0px',
                    backgroundColor: 'rgb(23 23 23 / 74%)',
                    width: '100%',
                    zIndex: '100000',
                    height: '100%'
                }} >
                    <div style={{ display: 'flex', flex: 1, justifyContent: 'center', marginTop: '20px' }}>
                        <AppLoader />
                    </div>
                </div>}
                {
                    isManualRefund && <ManualRefundModal
                        id="manualRefundModal"
                        show={isManualRefund}
                        onClose={() => this.closeRefundModal()}
                        onChange={(value) => this.getRefundAmount(value)}
                        onCommentChange={(value) => this.getComments(value)}
                        onClick={() => this.intiateRefund()}
                        refundableAmount={refundableAmount}
                        errorMsg={errorMessage}
                    />
                }
            </React.Fragment >
        )
    }
}

export const mapStateToProps = (state) => {
    return {
        getInvoiceDetails: state?.INVOICE_DETAILS || {},
        GetRoleId: state?.LOGIN_CREDS?.userInfo || {}
    };
};

export default withRouter(connect(mapStateToProps, {
    invoiceDetails: Actions.invoiceDetails,
    manaualRefund: Actions.manaualRefund
})(InvoiceDetails))