import Actions from "../actions";

const initialState = {
    isLoading: false,
    foodProductsList: [],
    error: false
}
const FoodProductsReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.FOOD_PRODUCTS: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.FOOD_PRODUCTS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                foodProductsList: action.data,
                error: false
            };
        }
        case Actions.FOOD_PRODUCTS_FAILURE: {
            return {
                ...state,
                isLoading: false,
                foodProductsList: [],
                error: action.error,
            };
        }
        default:
            return state;
    }
};

export default FoodProductsReducer;

export const getFoodProductListSelector = state => state?.FOOD_PRODUCTS?.foodProductsList;
export const getFoodProductListLoadingSelector = state => state?.FOOD_PRODUCTS?.isLoading;