import React from "react";

const PlanogramButton = ({onSaveChanges, onCancel, onAddNewRack}) => {
    return (
        <div className='planogram-button'>
            <button
                type="button"
                className="btn btn-success big-btn w-100 px-5 text-uppercase"
                onClick={() => onSaveChanges()}
            >
                Save Changes
            </button>
            <button
                type="button"
                className="btn btn-secondary big-btn w-100 px-5 text-uppercase"
                onClick={() => onCancel()}
            >
                Cancel
            </button>
            <button
                type="button"
                className="btn btn-outline-dark big-btn w-100 px-5 text-uppercase"
                onClick={() => onAddNewRack()}
            >
                Add New Rack
            </button>
        </div>
    );
}

export default PlanogramButton;