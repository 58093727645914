import { doAction, doResponseAction } from ".";

const QR_CODE_SCANNER = 'QR_CODE_SCANNER';
const QR_CODE_SCANNER_SUCCESS = 'QR_CODE_SCANNER_SUCCESS';
const QR_CODE_SCANNER_FAILURE = 'QR_CODE_SCANNER_FAILURE'
export const getqrCodeScanner = store => store['QR_CODE_SCANNER']

export {
    QR_CODE_SCANNER,
    QR_CODE_SCANNER_SUCCESS,
    QR_CODE_SCANNER_FAILURE,
}

export const qrCodeScannerAction = (params, callback) => doAction(QR_CODE_SCANNER, params, callback);
export const qrCodeScannerActionSuccess = (data) => doResponseAction(QR_CODE_SCANNER_SUCCESS, data);
export const qrCodeScannerActionFailure = (data) => doResponseAction(QR_CODE_SCANNER_FAILURE, data);