import { doAction, doResponseAction } from ".";

const STORE_LIST = 'STORE_LIST';
const STORE_LIST_SUCCESS = 'STORE_LIST_SUCCESS';
const STORE_LIST_FAILURE = 'STORE_LIST_FAILURE'

const CUSTOMER_COUNT_UPDATE = 'CUSTOMER_COUNT_UPDATE';
const CUSTOMER_COUNT_UPDATE_SUCCESS = 'CUSTOMER_COUNT_UPDATE_SUCCESS';
const CUSTOMER_COUNT_UPDATE_FAILURE = 'CUSTOMER_COUNT_UPDATE_FAILURE';

const STORE_OPEN_AND_CLOSE_UPDATE = 'STORE_OPEN_AND_CLOSE_UPDATE';
const STORE_OPEN_AND_CLOSE_UPDATE_SUCCESS = 'STORE_OPEN_AND_CLOSE_UPDATE_SUCCESS';
const STORE_OPEN_AND_CLOSE_UPDATE_FAILURE = 'STORE_OPEN_AND_CLOSE_UPDATE_FAILURE';

const SET_SELECTED_STORE = 'SET_SELECTED_STORE';
const DASHBOARD_TITLE_NAVIGATION = 'DASHBOARD_TITLE_NAVIGATION'
const SELECTED_STORE = 'SELECTED_STORE';
export {
    STORE_LIST,
    STORE_LIST_SUCCESS,
    STORE_LIST_FAILURE,
    CUSTOMER_COUNT_UPDATE,
    CUSTOMER_COUNT_UPDATE_SUCCESS,
    CUSTOMER_COUNT_UPDATE_FAILURE,
    STORE_OPEN_AND_CLOSE_UPDATE,
    STORE_OPEN_AND_CLOSE_UPDATE_SUCCESS,
    STORE_OPEN_AND_CLOSE_UPDATE_FAILURE,
    SET_SELECTED_STORE,
    DASHBOARD_TITLE_NAVIGATION,
    SELECTED_STORE
}

export const getStoreList = (params, callback) => doAction(STORE_LIST, params, callback);
export const getStoreListSuccess = (data) => doResponseAction(STORE_LIST_SUCCESS, data);
export const getStoreListFailure = (data) => doResponseAction(STORE_LIST_FAILURE, data);

export const updateCustomerCount = (params, callback) => doAction(CUSTOMER_COUNT_UPDATE, params, callback);
export const updateCustomerCountSuccess = data => doResponseAction(CUSTOMER_COUNT_UPDATE_SUCCESS, data);
export const updateCustomerCountFailure = data => doResponseAction(CUSTOMER_COUNT_UPDATE_FAILURE, data);

export const updateStoreOpenAndClose = (params, callback) => doAction(STORE_OPEN_AND_CLOSE_UPDATE, params, callback);
export const updateStoreOpenAndCloseSuccess = (data) => doResponseAction(STORE_OPEN_AND_CLOSE_UPDATE_SUCCESS, data);
export const updateStoreOpenAndCloseFailure = (data) => doResponseAction(STORE_OPEN_AND_CLOSE_UPDATE_FAILURE, data);

export const setStoreInformation = (data) => doResponseAction(SET_SELECTED_STORE, data);
export const setDashboardTitleId = (data) => doResponseAction(DASHBOARD_TITLE_NAVIGATION, data);
export const setSelectedStore = (data) => doResponseAction(SELECTED_STORE, data);