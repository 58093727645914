import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import Actions from '../../redux/actions';
import makeAnimated from 'react-select/animated';
const animatedComponents = makeAnimated();

const AssignedStore = (props) => {
  const dispatch = useDispatch()
  const [options, setOptions] = useState([]);
  const searchApi = () => {
    const defaultFilter = {
      page: 0,
      size: 100,
    };
    dispatch(Actions.getStoreListForSelectDropdownAction(defaultFilter, (res) => {
      handleOptions(res)
    }))
  }

  const handleOptions = (res) => {
    const value = res?.map(obj => ({ label: obj.branchName, value: obj.id, data: obj }))
    setOptions(value)
  }
  useEffect(() => {
    searchApi()
  }, [])

  return <Select
    id="AssignedStore"
    {...props}
    components={animatedComponents}
    closeMenuOnSelect={false}
    placeholder={props.placeholder}
    isClearable={true}
    // isSearchable={true}
    isMulti={props?.isMulti}
    options={options}
    value={props?.value}
    onChange={(value) => props.onChange(value || {})}
  />
}

export default AssignedStore