import { all, put, takeLatest } from "redux-saga/effects";
import { doPromotionGet, doPromotionPost } from "../../utils/service";
import END_POINTS from "../../utils/service/Endpoints";

import Actions from "../actions";

export function* MultiBuySaga({ params }) {
    try {
        const response = yield doPromotionGet(END_POINTS.MULTI_BUY_LIST, params);
        if (response) {
            yield put(Actions.getAllMultiBuyListSuccessAction(response));
        }
    }
    catch (error) {
        yield put(Actions.getAllMultiBuyListFailureAction({ error }));
    }
}
export function* createMultiBuyList({ params, callback }) {
    try {
        const response = yield doPromotionPost(END_POINTS.CREATE_MULTI_BUY_LIST, params);
        if (response) {
            yield put(Actions.createMultiBuyActionSuccess(response));
        }
        callback && callback(response);
    }
    catch (error) {
        callback && callback({error});
        yield put(Actions.createMultiBuyActionFailure({ error }));
    }
}
export function* updateMultiBuy({ params, callback }) {
    try {
        const response = yield doPromotionPost(END_POINTS.UPDATE_MULTI_BUY_LIST, params);
        if (response) {
            yield put(Actions.updateMultiBuyActionSuccess(response));
        }
        callback && callback(response);
    }
    catch (error) {
        callback && callback(error);
        yield put(Actions.updateMultiBuyActionFailure({ error }));
    }
}
export function* deleteMultiBuyListSaga({ params, callback }) {
    try {
        const response = yield doPromotionPost(params);
        if (response) {
            callback && callback(response)
            yield put(Actions.deleteMultiBuyActionSuccess(response));
        }
    }
    catch (error) {
        callback && callback({error})
        yield put(Actions.deleteMultiBuyActionFailure({ error }));
    }
}
export default function* MultiBuyListener() {
    yield all([
        takeLatest(Actions.MULTI_BUY, MultiBuySaga),
        takeLatest(Actions.CREATE_MULTI_BUY, createMultiBuyList),
        takeLatest(Actions.UPDATE_MULTI_BUY, updateMultiBuy),
        takeLatest(Actions.DELETE_MULTI_BUY, deleteMultiBuyListSaga),
    ]);
}
