import { all, put, takeLatest } from "redux-saga/effects";
import { doCvPost,doGet } from "../../../utils/service";
import END_POINTS from "../../../utils/service/Endpoints";
import Actions from "../../actions";

export function* getCvPlanogramDetailsSaga({ callback, params }) {
    try {
        const response = yield doCvPost(END_POINTS.GET_PLANOGRAM_DETAILS, params)
        if (response) {
            callback && callback(response)
            yield put(Actions.getCvPlanogramDetailsSuccess(response));
        }
    } catch (error) {
        if (callback) {
            callback({ error })
            yield put(Actions.getCvPlanogramDetailsFailure({ error }));
        }
    }
}

export function* getPlanogramAnalytics({ callback, params }) {
    try {
        const response = yield doGet(END_POINTS.PLANOGRAM_ANALYTICS, params)
        if (response) {
            callback && callback(response)
            yield put(Actions.getPlanogramAnalyticsSuccess(response));
        }
    } catch (error) {
        if (callback) {
            callback({ error })
            yield put(Actions.getPlanogramAnalyticsFailure({ error }));
        }
    }
}

export default function* getCvPlanogramDetailsListner() {
    yield all([
        takeLatest(Actions.GET_CV_PLANOGRAM_DETAILS, getCvPlanogramDetailsSaga),
        takeLatest(Actions.GET_PLANOGRAM_ANALYTICS, getPlanogramAnalytics),

    ]);
}