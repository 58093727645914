import Actions from "../actions";

const initialState = {
    isLoading: false,
    invoiceDetails: [],
    error: false,
    maualRefundLoading: false,
    manualRefundError: false
}
const OfferReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.INVOICE_DETAILS: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.INVOICE_DETAILS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                invoiceDetails: action?.data?.response || [],
                error: false
            };
        }
        case Actions.INVOICE_DETAILS_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }

        case Actions.MANUAL_REFUND: {
            return {
                ...state,
                maualRefundLoading: true,
            };
        }
        case Actions.MANUAL_REFUND_SUCCESS: {
            return {
                ...state,
                maualRefundLoading: false,
                manualRefundError: false
            };
        }
        case Actions.MANUAL_REFUND_FAILURE: {
            return {
                ...state,
                maualRefundLoading: false,
                manualRefundError: action.error,
            };
        }
        default:
            return state;
    }
};

export default OfferReducer;

export const getInvoiceDetailsSelector = state => state?.INVOICE_DETAILS;
export const getInvoiceDetailsLoadingSelector = state => state?.INVOICE_DETAILS?.isLoading;