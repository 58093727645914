import { useState } from 'react';
import bayimage from '../../asset/images/placeholder.jpg';
import outOfStock from '../../asset/images/oos.png';
import partiallyOutOfStock from '../../asset/images/pos.png';

export const PlanogramProduct = ({ products, productDetails, styles, bay, onRemoveProduct, title = '', dragHandleProps }) => {
    const [isFocused, setFocus] = useState(false)
    return (
        <div className={`planogram-assigned-product ${isFocused ? 'active' : ''}`} onMouseEnter={() => setFocus(true)} onMouseLeave={() => setFocus(false)} title={title} {...dragHandleProps}>
            <img className={`card-img-top ${((products?.outOfStock || products?.partiallyOutOfStock) && !bay) ? "outOfStockImage" : ""}`} src={productDetails?.imageUrl ? productDetails.imageUrl : bayimage} style={styles} alt="Card image" />
            {
                (products?.outOfStock && !bay) ? <img className='outofstock-img' src={outOfStock} alt="outoffstock" /> : (products?.partiallyOutOfStock && !bay) ? <img className='outofstock-img' src={partiallyOutOfStock} alt="partiallyOutOfStock" /> : null
            }
        </div>
    )
}

export const PlanogramProductDraggable = ({ product, provided, snapshot, getItemStyle, ProductComponent, item, index, onRemoveProduct }) => {
    const [isFocused, setFocus] = useState(false)
    return (
        <div className={(product?.outOfStock) ? 'draggable-container-outofStock draggable-container' : product?.partiallyOutOfStock ? "draggable-container-partial draggable-container" : `draggable-container ${isFocused ? 'active' : ''}`} onMouseEnter={() => setFocus(true)} onMouseLeave={() => setFocus(false)} >
            <div
                style={getItemStyle(
                    snapshot.isDragging,
                    provided.draggableProps.style
                )}
            >
                <div
                    className={`draggable-item ${isFocused ? '' : 'draggable-hidden'}`}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}>
                    <i className="fas fa-bars" ></i>
                </div>
                {isFocused && <span className="planogram-assigned-close" onClick={() => onRemoveProduct(index)}><span className="fas fa-trash" ></span></span>}
                {ProductComponent(item.productDetails, index)}
            </div>
        </div>
    )
}