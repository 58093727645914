import Actions from '../../actions';
const initialState = {
    isLoading: false,
    CameraList: [],
    productList: [],
    cvProductList: [],
    rackDetail: {},
    error: false,
}
const CameraListsReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.CV_CAMERA_LIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.CV_CAMERA_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                CameraList: action.data?.response?.cameraList,
                productList: action.data?.response?.productList,
                rackDetail: action.data?.response?.rackDetail,
                error: false
            };
        }
        case Actions.CV_CAMERA_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                CameraList: [],
                productList: [],
                rackDetail: {},
                error: action.error,
            };
        }
        case Actions.CV_PRODUCT_DETAILS: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.CV_PRODUCT_DETAILS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                cvProductList: action.data?.response,
                error: false
            };
        }
        case Actions.CV_PRODUCT_DETAILS_FAILURE: {
            return {
                ...state,
                isLoading: false,
                cvProductList: [],
                error: action.error,
            };
        }
        default:
            return state;
    }
};

export const getCameraList = (state) => state?.GET_CAMERA_LIST?.CameraList
export const getProductList = (state) => state?.GET_CAMERA_LIST?.productList
export const getCvProductList = (state) => state?.GET_CAMERA_LIST?.cvProductList
export const getRackList = (state) => state?.GET_CAMERA_LIST?.rackDetail
export const getCameraLoader = (state) => state?.GET_CAMERA_LIST?.isLoading
export default CameraListsReducer;