import { doAction, doResponseAction } from ".";

const ORDERS = 'ORDER';
const ORDERS_SUCCESS = 'ORDERS_SUCCESS';
const ORDER_FAILURE = 'ORDER_FAILURE'

const ORDER_DETAILS = 'ORDER_DETAILS';
const ORDER_DETAILS_SUCCESS = 'ORDER_DETAILS_SUCCESS';
const ORDER_DETAILS_FAILURE = 'ORDER_DETAILS_FAILURE'

const ORDER_DETAILS_UPDATE = 'ORDER_DETAILS_UPDATE';
const ORDER_DETAILS_UPDATE_SUCCESS = 'ORDER_DETAILS_UPDATE_SUCCESS';
const ORDER_DETAILS_UPDATE_FAILURE = 'ORDER_DETAILS_UPDATE_FAILURE';

const ORDER_PROMOTION_COMBINE_ARRAY = 'ORDER_PROMOTION_COMBINE_ARRAY';
const COMBO_OFFER_PRODUCT_LIST_ARRAY = 'COMBO_OFFER_PRODUCT_LIST_ARRAY';
const FREE_GIVE_PRODUCT_LIST_ARRAY = 'FREE_GIVE_PRODUCT_LIST_ARRAY';

const PARTIAL_ORDER_ACCEPT = 'PARTIAL_ORDER_ACCEPT';
const PARTIAL_ORDER_ACCEPT_SUCCESS = 'PARTIAL_ORDER_ACCEPT_SUCCESS';
const PARTIAL_ORDER_ACCEPT_FAILURE = 'PARTIAL_ORDER_ACCEPT_FAILURE'


const GET_SOCKET_ORDERS = 'GET_SOCKET_ORDERS'
const UPDATE_MULTI_BUY_ARRAY_LIST = 'UPDATE_MULTI_BUY_ARRAY_LIST'

const UPDATE_CURRENT_ORDERS_LOCATION = 'UPDATE_CURRENT_ORDERS_LOCATION'

export {
    ORDERS,
    ORDERS_SUCCESS,
    ORDER_FAILURE,
    ORDER_DETAILS,
    ORDER_DETAILS_SUCCESS,
    ORDER_DETAILS_FAILURE,
    ORDER_DETAILS_UPDATE,
    ORDER_DETAILS_UPDATE_SUCCESS,
    ORDER_DETAILS_UPDATE_FAILURE,
    ORDER_PROMOTION_COMBINE_ARRAY,
    COMBO_OFFER_PRODUCT_LIST_ARRAY,
    FREE_GIVE_PRODUCT_LIST_ARRAY,
    PARTIAL_ORDER_ACCEPT,
    PARTIAL_ORDER_ACCEPT_SUCCESS,
    PARTIAL_ORDER_ACCEPT_FAILURE,
    GET_SOCKET_ORDERS,
    UPDATE_MULTI_BUY_ARRAY_LIST,
    UPDATE_CURRENT_ORDERS_LOCATION
}

export const getOrderList = (params, callback) => doAction(ORDERS, params, callback);
export const getOrderListSuccess = (data) => doResponseAction(ORDERS_SUCCESS, data);
export const getOrderListFailure = (data) => doResponseAction(ORDER_FAILURE, data);

export const getOrderDetails = (params, callback) => doAction(ORDER_DETAILS, params, callback);
export const getOrderDetailsSuccess = data => doResponseAction(ORDER_DETAILS_SUCCESS, data);
export const getOrderDetailsFailure = data => doResponseAction(ORDER_DETAILS_FAILURE, data);

export const getOrderDetailsUpdate = (params, callback) => doAction(ORDER_DETAILS_UPDATE, params, callback);
export const getOrderDetailsUpdateSuccess = data => doResponseAction(ORDER_DETAILS_UPDATE_SUCCESS, data);
export const getOrderDetailsUpdateFailure = data => doResponseAction(ORDER_DETAILS_UPDATE_FAILURE, data);

export const partialOrderAccept = (params, callback) => doAction(PARTIAL_ORDER_ACCEPT, params, callback);
export const partialOrderAcceptSuccess = data => doResponseAction(PARTIAL_ORDER_ACCEPT_SUCCESS, data);
export const partialOrderAcceptFailure = data => doResponseAction(PARTIAL_ORDER_ACCEPT_FAILURE, data);

export const promotionCombine = data => doResponseAction(ORDER_PROMOTION_COMBINE_ARRAY, data);
export const comboOfferProductList = data => doResponseAction(COMBO_OFFER_PRODUCT_LIST_ARRAY, data);
export const freeGiveProductList = data => doResponseAction(FREE_GIVE_PRODUCT_LIST_ARRAY, data);

export const getSocketOrders = (params, callback) => doAction(GET_SOCKET_ORDERS, params, callback);

export const updateMultibuyArray = data => doResponseAction(UPDATE_MULTI_BUY_ARRAY_LIST, data);

export const updatedCurrentOrderLocation = data => doResponseAction(UPDATE_CURRENT_ORDERS_LOCATION, data);

