import { all, put, takeLatest, takeEvery } from "redux-saga/effects";
import { doAuthPut, doAuthGet } from "../../utils/service";
import END_POINTS from "../../utils/service/Endpoints";
import Actions from "../actions";

export function* StoreListSaga({ params, callback }) {
    try {
        const response = yield doAuthGet(END_POINTS.STORE_LIST, params);
        callback && callback(response);
        if (response) {
            yield put(Actions.getStoreListSuccess(response.response));
            yield put(Actions.getDashboardDetailsActionSuccess(response.analytics));
            yield put(Actions.getDashboardContent(response?.employeeId))
        }
    }
    catch (error) {
        callback && callback(error);
        yield put(Actions.getStoreListFailure({ error }));
    }
}

export function* updateCustomerCountSaga({ params, callback }) {
    try {
        let response = yield doAuthPut(END_POINTS.UPDATE_CUSTOMER_COUNT_API, params)
        if (response?.response_code === 0) {
            yield put(Actions.updateCustomerCountSuccess(response.response));
            yield put(Actions.getStoreList());
        }
        if (callback) {
            callback(response);
        }
    } catch (error) {
        yield put(Actions.updateCustomerCountSuccess({ error }))
    }
}

export function* updateStoreOpenAndCloseSaga({ params, callback }) {
    try {
        let response = yield doAuthPut(END_POINTS.UPDATE_STORE_OPEN_CLOSE_API, params)
        if (response?.response_code === 0) {
            // yield put(Actions.setStoreInformation(response.response));
            yield put(Actions.updateStoreOpenAndCloseSuccess(response.response));
        }
        if (callback) {
            callback(response);
        }
    } catch (error) {
        yield put(Actions.updateStoreOpenAndCloseFailure({ error }))
    }
}

export default function* storeListener() {
    yield all([
        takeLatest(Actions.STORE_LIST, StoreListSaga),
        takeLatest(Actions.CUSTOMER_COUNT_UPDATE, updateCustomerCountSaga),
        takeEvery(Actions.STORE_OPEN_AND_CLOSE_UPDATE, updateStoreOpenAndCloseSaga),
    ]);
}
