import Actions from "../actions";

const initialState = {
    isLoading: false,
    bayProductList:[],
    error: false
}
const BayProductListSave = (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.BAY_SAVE_PRODUCTLIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.BAY_SAVE_PRODUCTLIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                bayProductList: action.data || [],
                error: false
            };
        }
        case Actions.BAY_SAVE_PRODUCTLIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                bayProductList: [],
                error: action.error,
            };
        }
        default:
            return state;
    }
};

export default BayProductListSave;

export const getBayProductListSelector = state => state?.SAVE_BAY_PRODUCTLIST?.bayProductList;
export const getBayProductListLoadingSelector = state => state?.SAVE_BAY_PRODUCTLIST?.isLoading;