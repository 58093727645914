import React from "react";

export const Dropdowns = (props) => {
    //It is handle change for the drop down
    const handleChange = (e) => {
        props.onChange(e.target.value);
    };
    //It is delete the annotation.
    const onDelete = () => {
        let { onDelete } = props;
        onDelete();
    };
    return (
        <>
            <div style={{ display: "flex", flex: "1", width: "5px" }}>
                <select className="form-select selectionMenu" placeholder="Select" aria-label="Default select example" onChange={(e) => handleChange(e)} value={props.inputLabel}>
                    <option value={0}>Select Product</option>
                    {props.optionsProps?.length > 0 && props.optionsProps?.map((item, index) => {
                        return (
                            <option value={item.value} key={index} >
                                {item.text}
                            </option>
                        )
                    })}
                </select>
                <div
                    className="col col-md-4"
                    style={{
                        float: "right",
                        background: "white",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "35px",
                        height: "32px",
                        color: "white",
                        fontSize: "12px",
                        backgroundColor: "#3b5bdb",
                        borderRadius: "0 5px 5px 0",
                        cursor: "pointer",
                        transition: "backgroundColor 0.5s, color 0.5s"
                    }}
                    onClick={onDelete} ><i className="fas fa-trash fa-lg" aria-hidden="true"></i>
                </div>
            </div>
        </>
    )
}