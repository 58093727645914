import React from "react";
import Close from '../../../../asset/new/img/modal-close.svg'
import { UTCDateFormat } from "../../../utils/commonFunctions/dateFormat";


const DollarDetailModal = ({ isCloseModal, dollarOffeDetails }) => {
    return <><div className="modal d-block"
        id="unblock-modal"
        tabIndex="-1"
        aria-labelledby="unblock"
        aria-hidden="true"><div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content rounded-0">
                <img src={Close} alt="X" data-bs-dismiss="modal" aria-label="Close" id="isCloseModal" className="modal-close" onClick={
                    () => isCloseModal(false)
                } />
                <div className="modal-header rounded-0 align-items-center py-4">
                    <div className="col"> <h5 className="modal-title">Dollar Offer ID #{dollarOffeDetails?.id}</h5> </div >
                </div >
                <div className="modal-body font14 p-40" >
                    <div className="row mb-3" >
                        <div className="col-4 fw-bold" > Cart Value </div >
                        <div className="col-8" > ${parseFloat(dollarOffeDetails?.cartGrossValue).toFixed(2)}</div >
                    </div >
                    <div className="row mb-3" >
                        <div className="col-4 fw-bold" > Discount </div >
                        <div className="col-8" > {
                            dollarOffeDetails?.offerType === "DOLLAR" ? `$` + parseFloat(dollarOffeDetails?.discount)?.toFixed(2) : `${dollarOffeDetails?.discount}%`
                        } </div >
                    </div >
                    <div className="row mb-3" >
                        <div className="col-4 fw-bold" > Offer Type </div >
                        <div className="col-8" > {dollarOffeDetails?.offerType} </div >
                    </div >
                    {dollarOffeDetails?.maxDiscount && <div className="row mb-3" >
                        <div className="col-4 fw-bold" > Max Discount </div >
                        <div className="col-8" > ${dollarOffeDetails?.maxDiscount}  </div >
                    </div >}

                    <div className="row mb-3" >
                        <div className="col-4 fw-bold" > Valid From </div >
                        <div className="col-8" > {UTCDateFormat(dollarOffeDetails?.startDateString)} </div >
                    </div >
                    <div className="row mb-3" >
                        <div className="col-4 fw-bold" > Valid To </div >
                        <div className="col-8" > {UTCDateFormat(dollarOffeDetails?.endDateString)} </div >
                    </div >
                    <div className="row my-4" >
                        <div className="col" > <span data-bs-dismiss="modal" id="Close" aria-label="Close" className="btn btn-dark font14 w-100 p-2" onClick={
                            () => isCloseModal(false)
                        } > Close</span > </div >
                    </div >

                </div >

            </div>
        </div>
    </div>
        <div className="modal-backdrop show"></div>
    </>

};

export default DollarDetailModal;