import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllChatMessagesListAction } from '../../redux/actions/ChatsAction';
import { getAllChatsListSelector, getSelectedChatSelector } from '../../redux/reducers/ChatsReducer';
import ChatConversation from './ChatConversation';
import ChatListItems from './ChatListItems';
import { COLORS } from '../../utils/Constants';
import debounce from 'lodash.debounce';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment } from '@fortawesome/free-solid-svg-icons';
import searchIcon from '../../../asset/new/img/search.svg'
function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}


const ChatsScreen = () => {
    const dispatch = useDispatch();
    const chatList = useSelector(getAllChatsListSelector);
    const [error, setError] = useState();
    const { height } = useWindowDimensions();
    const selectedChat = useSelector(getSelectedChatSelector);
    const windowHeight = height - 70;
    const [chatError, setChatError] = useState(null);

    useEffect(() => {
        setChatError(null)
    }, [selectedChat]);

    const getAllChats = useCallback((invoiceId) => {
        let params = { invoiceId };
        dispatch(getAllChatMessagesListAction(params, (response) => {
            if (response.response_code === 0) {
                setError(response.response.length === 0 ? 'No Data Found' : null)
            }
        }));
    }, [dispatch]);

    const searchChatByIdRef = useRef(debounce((value) => getAllChats(value || ''), 1000)).current;

    useEffect(() => {
        getAllChats('')
    }, [getAllChats])

    return (
        <div className="col ps-lg-4">
            <div className="row mb-3">
                <div className="col"> <h1 className="font20 fw-bold">Chat</h1> </div>
            </div>
            <div className="row">

                <div id="chatting-list" className="col-3 px-0 ps-1">
                    <form name="">
                        <div id="order-options-bar" className="row mb-3">
                            <div className="col-12 col-lg pe-lg-3">
                                <div className="input-group mb-3 mb-lg-0">
                                    <input
                                        id='searchScreen'
                                        type="search"
                                        className="form-control font12 border-end-0"
                                        placeholder="Search by Receipt no"
                                        onChange={(e) => searchChatByIdRef(e.target.value)}
                                    />
                                    <button className="btn btn-outline-dark border-start-0"
                                        type="button" id="search-btn"><img src={searchIcon}
                                            alt="Go" /></button>
                                </div>
                            </div>
                        </div>
                    </form>

                    <div className="tab-content pt-4" id="chat-content">
                        {error ? <div style={{
                            height: '100%', justifyContent: 'center',
                            alignItems: 'center', display: 'flex'
                        }}>{error}</div>
                            : <span
                            >
                                {chatList?.map((chat, i) => <ChatListItems key={i} chat={chat} />)}
                            </span>}

                    </div>

                </div>
                {selectedChat && chatList?.length > 0 && !chatError ?  <div id="chatting-window" className="col-9">
                    <ChatConversation windowHeight={windowHeight} setChatError={setChatError} />
                </div> : <div style={{
                    display: 'flex', flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column'
                }}>
                    <FontAwesomeIcon
                        color={COLORS.listBackground}
                        icon={faComment} size="10x" />
                    <span style={{ color: COLORS.appThemeHover }}>
                        Please click on the chat list item to start messaging
                    </span>
                </div>}
            </div>
        </div>);
}

export default ChatsScreen;