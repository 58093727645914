import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Actions from '../../redux/actions';
import { getInvoiceListLoading, getInvoiceListSelector, getInvoiceListCountSelector } from '../../redux/reducers/InvoiceListReducer';
import { getDashboardTitleId } from '../../redux/reducers/StoreReducer';
import InputField from '../../components/fields/InputField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import cloneDeep from 'lodash.clonedeep';
import { getQueryParams } from '../../utils/commonFunctions/EncodeDecodeBase64';
import { useLocation } from 'react-router';
import Pagination from "react-js-pagination";
import ViewInvoiceModal from '../../components/ViewInvoiceModal';
import OrderSummaryOfferDetails from '../order/OrderSummaryOfferDetails';
import AppLoader from '../../components/fields/AppLoader';
import Search from '../../../asset/new/img/search.svg'
import InvoiceDetails from './InvoiceDetails';
import EmptyPage from '../../components/emptyPage';
import DateFilter from '../../components/DateFilter';
import { ShowDateMoment } from '../../utils/commonComponents/showDate';
import Constants from '../../utils/Constants';
import { getRoleIdFromCookie } from '../../utils/commonFunctions/cookie';


export const defaultInvoiceFilters = {
    purchaseType: '',
    offset: 0,
    limit: 5,
    startDate: '',
    endDate: '',
    invoiceId: ''
}

const DEFAULT_FILTER_STATUS = [
    {
        id: '',
        name: 'ALL'
    },
    {
        id: 1,
        name: 'WIWO'
    },
    {
        id: 2,
        name: 'E-COM'
    },
    {
        id: 3,
        name: 'HYBRID'
    },
    {
        id: 4,
        name: 'SCAN & GO'
    },
    {
        id: 5,
        name: 'MANUAL REFUND'
    },

]
export const deliveryMode = (actions, type) => {
    switch (actions) {
        case 1:
            return 'WIWO';
        case 2:
            return type?.natureOfOrder === 1 ? "Door Delivery" : "Store Pickup";
        case 3:
            return 'Hybrid';
        case 4:
            return 'Scan & Go';

        default:
            return '';
    }
}
const DATE_FORMAT = 'YYYY-MM-DD';

const SearchInputField = memo(({ isPlaceholder, onChangeSearchInput, value }) => {

    return <div className="col-12 col-lg-6 pe-lg-3">
        <div className="input-group mb-3 mb-lg-0">
            <InputField
                id="searchOrder"
                key="Search_id"
                placeholder={'Search by Receipt no or Order no'}
                value={value}
                onChange={(value) => {
                    onChangeSearchInput(value);
                }}
                type={"number"} />
            <button className="btn btn-outline-dark border-start-0"
                type="button" id="search-btn">
                <img src={Search} alt="Go" />
            </button>
        </div>
    </div>
});


const InvoiceList = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const queryParams = getQueryParams(location.search);
    const searchId = queryParams.get('searchId');
    const pageNumber = queryParams.get('page');
    const invoiceList = useSelector(getInvoiceListSelector);
    const invoiceListCount = useSelector(getInvoiceListCountSelector)
    const dashboardTitleId = useSelector(getDashboardTitleId);
    const roleId = getRoleIdFromCookie();
    const isLoading = useSelector(getInvoiceListLoading);
    const [apiFilters, setApiFilters] = useState(cloneDeep({
        ...defaultInvoiceFilters,
        invoiceId: searchId || ''
    }));
    const [filterValidationError, setFilterValidationError] = useState(null);
    const [invoiceDetailsModal, setInvoiceDetailsModal] = useState(false)
    const [openDateFilterModal, setDateFilterModal] = useState(false)
    const [dashboardTileId, setDashboardTileId] = useState(null)
    const [InvoiceDetail, setInvoiceDetails] = useState({});
    const [activePage, setActivePage] = useState(1)
    const [filterType, setFilterType] = useState()
    const [modalFilters, setModalFilters] = useState({
        purchaseType: apiFilters.purchaseType,
        startDate: apiFilters.startDate,
        endDate: apiFilters.endDate,
    });

    const fetchList = useCallback(() => {
        const filterParams = { ...apiFilters };
        filterParams.purchaseType = filterType
        if (filterParams.startDate) {
            filterParams.startDate = moment(filterParams.startDate).format(DATE_FORMAT);
        }
        if (filterParams.endDate) {
            filterParams.endDate = moment(filterParams.endDate).format(DATE_FORMAT);
        }
        if (filterParams?.invoiceId?.trim().length === 0) {
            delete filterParams.invoiceId;
        }
        const pageNumbers = isNaN(parseInt(pageNumber)) ? 1 : parseInt(pageNumber)
        filterParams.offset = pageNumbers * 5 - 5
        if (!!dashboardTitleId) {
            setFilterType(dashboardTitleId)
            setDashboardTileId(dashboardTitleId)
            filterParams.purchaseType = dashboardTitleId
            dispatch(Actions.getInvoiceList(filterParams))
            let params = {
                selectedTitleId: undefined
            }
            dispatch(Actions.setDashboardTitleId(params))
        } else {
            dispatch(Actions.getInvoiceList(filterParams))
        }
    }, [dispatch, apiFilters]);


    useEffect(() => {
        const filterParams = { ...apiFilters };
        if (roleId === Constants.ROLES.BISTRO_ASSOCIATE) {
            if (filterParams.startDate) {
                filterParams.startDate = moment(filterParams.startDate).format(DATE_FORMAT);
            }
            if (filterParams.endDate) {
                filterParams.endDate = moment(filterParams.endDate).format(DATE_FORMAT);
            }
            if (filterParams?.invoiceId?.trim().length === 0) {
                delete filterParams.invoiceId;
            }
            const pageNumbers = isNaN(parseInt(pageNumber)) ? 1 : parseInt(pageNumber)
            filterParams.offset = pageNumbers * 5 - 5
            dispatch(Actions.getInvoiceList(filterParams))
        } else {
            fetchList();

        }
    }, [fetchList]);

    const onChangeSearchInput = (value) => {
        const filterParams = { ...apiFilters };
        filterParams.invoiceId = value;
        if (roleId === Constants.ROLES.BISTRO_ASSOCIATE) {
            filterParams.typeQ = 1;
        }
        filterParams.offset = 0
        setActivePage(1)
        setApiFilters(filterParams)
    }
    const data = React.useMemo(
        () => invoiceList,
        [invoiceList]
    );

    const closeModal = () => {
        setFilterValidationError('')
        setDateFilterModal(false)
    }

    const clearFilter = () => {
        setModalFilters(s => ({
            ...s,
            startDate: null,
            endDate: null,
            purchaseType: null
        }))
        setFilterType('')
        setFilterValidationError(null);
        modalFilters.offset = 0
        setActivePage(1)
        setApiFilters(f => ({ ...f, ...modalFilters }))
        setDateFilterModal(false)
    }

    const onStatusTypeChange = (id) => {
        setFilterType(id)
        setModalFilters(s => ({
            ...s,
            purchaseType: id
        }))
    }

    const dateFilterModalOpen = () => {
        setDateFilterModal(true)
    }

    const onFilterDone = () => {
        if (!modalFilters.startDate && modalFilters.endDate) {
            setFilterValidationError('Please select start date.');
            return;
        }

        if (modalFilters.startDate && !modalFilters.endDate) {
            setFilterValidationError('Please select end date.');
            return;
        }
        if (moment(modalFilters.startDate).isAfter(moment(modalFilters.endDate))) {
            setFilterValidationError('End date cannot be before start date.');
            return;
        }
        setFilterValidationError(null);
        modalFilters.offset = 0
        setActivePage(1)
        setApiFilters(f => ({ ...f, ...modalFilters }))
        setDateFilterModal(false)
    }

    const onDateChange = (date, type) => {
        if (type === 1) {
            setModalFilters(s => ({
                ...s,
                startDate: date,
            }))
        } else {
            setModalFilters(s => ({
                ...s,
                endDate: date,
            }))
        }
    }
    const openInvoiceDetailsModal = (InvoiceDetails) => {
        setInvoiceDetails(InvoiceDetails)
        setInvoiceDetailsModal(true)
    }


    const handleChange = (pageNumber) => {
        setActivePage(pageNumber)
        orderListPageChange(pageNumber * 5 - 5)
    }

    const getOfferDetailsList = () => {
        const { invoiceOrderDetailsList = [] } = InvoiceDetails || {};
        const invoices = invoiceOrderDetailsList && invoiceOrderDetailsList[0]
        const { invoiceDetails, invoiceOrderProductDetailsList } = invoices || {}
        let promotions = {};
        try {
            promotions = JSON.parse(invoiceDetails?.promotionJson);
        } catch (e) {
            console.log(e)
        }
        return <OrderSummaryOfferDetails
            cartOffer={promotions}
            cartItems={invoiceOrderProductDetailsList}
            natureOfOrder={invoiceDetails?.natureOfOrder}
        />
    }

    const orderListPageChange = (offsetValue) => {
        let filterParams = { ...apiFilters };
        if (filterParams.startDate) {
            filterParams.startDate = moment(filterParams.startDate).format(DATE_FORMAT);
        }
        if (filterParams.endDate) {
            filterParams.endDate = moment(filterParams.endDate).format(DATE_FORMAT);
        }
        if (filterParams?.invoiceId?.trim().length === 0) {
            delete filterParams.invoiceId;
        }
        filterParams.offset = offsetValue
        if (!!dashboardTileId) {
            setApiFilters(s => ({
                ...s,
                purchaseType: dashboardTileId,
            }))
            dispatch(Actions.getInvoiceList(filterParams))
            setDashboardTileId(null)
        } else {
            dispatch(Actions.getInvoiceList(filterParams))
        }
    }
    return <div className="col ps-lg-4">
        <div className="row mb-3">
            <div className="col"> <h1 className="font20 fw-bold">Receipt list</h1> </div>
        </div>
        <form name="">
            <div id="order-options-bar" className="row mb-3">
                <SearchInputField
                    id='SearchInputField'
                    value={apiFilters.invoiceId}
                    onChangeSearchInput={onChangeSearchInput}
                />
                <div className="d-flex align-items-center justify-content-end col-3 col-sm-3 col-lg-6 cursor-pointer text-end">
                    <div className=''>
                        <span>Filter</span>
                        <FontAwesomeIcon
                            id="openDateFilterModal"
                            onClick={() => dateFilterModalOpen()}
                            icon={faFilter} size="10m" />
                    </div>
                </div>

            </div>
        </form>
        <div className="row mb-4">
            <div className="col">
                <div id="orders-list" className="card card-body rounded-3 
                border-0 shadow p-4 mb-3 mb-xl-0">
                    {data && data.map((o) => {
                        const finalAmount = o?.invoiceOrderDetailsList?.findIndex((o) => o?.orderStatus === 1);
                        return (<><a className="single-order cursor-pointer" id="openInvoiceDetailsModal"
                            data-bs-toggle="modal" data-bs-target="#order-details-modal"
                            onClick={() => openInvoiceDetailsModal(o)} >
                            <div className="row align-items-center">
                                <div className="col">
                                    <h2 className="font13 mb-2"><strong>{o?.customerFirstName?.toUpperCase()} {o?.customerLastName?.toUpperCase()}</strong></h2>
                                    <h3 className="font13 mb-2">Receipt no: <strong>{o?.invoiceId}</strong></h3>
                                    <div className="row font12 fw-bold">
                                        <div className="col-6 col-lg font-weight-normal">Order No: <strong>{o?.invoiceOrderDetailsList.map(item => item?.orderId).join(", ")}</strong></div>
                                        <div className="col-6 col-lg">
                                            {ShowDateMoment(o?.invoiceDate)}
                                        </div>
                                        <div className="col-6 col-lg text-end text-lg-start">{deliveryMode(o?.createdBy, o)}</div>
                                        <div className="col-12 col-lg pt-2 pt-lg-0"> Total: {finalAmount !== -1 ? `$${parseFloat(o?.totalAmount)?.toFixed(2)}` : `$${parseFloat(o?.processedAmount)?.toFixed(2)}`}</div>
                                    </div>
                                </div>
                            </div>
                            <hr className="bg-ltgrey-1 mb-4" /></a>
                        </>
                        )
                    })}
                    {
                        (data && data.length === 0 && !isLoading) && (
                            <EmptyPage
                                content="Receipt Not Found"
                            />
                        )
                    }

                    <div className="row align-items-center">
                        <div className="col">
                            {
                                data && data.length !== 0 && invoiceListCount > 5 && <Pagination
                                    id="Pagination"
                                    activePage={activePage}
                                    itemsCountPerPage={5}
                                    totalItemsCount={invoiceListCount}
                                    pageRangeDisplayed={5}
                                    onChange={(e) => handleChange(e)}
                                />
                            }
                        </div>
                    </div>
                    {isLoading && <AppLoader />}
                </div>
            </div>
        </div>
        {
            openDateFilterModal && <DateFilter
                id="DateFilter"
                onDateChange={(date, option) => onDateChange(date, option)}
                startDate={modalFilters?.startDate}
                endDate={modalFilters?.endDate}
                title="DATE RANGE"
                proceed="Apply Filter"
                cancel="Close"
                clearFilter={() => clearFilter()}
                onSuccess={() => onFilterDone()}
                onFailure={() => closeModal()}
                errorMessage={filterValidationError}
                renderStatus={() => {
                    return (
                        <>
                            <div className="col-12">
                                <select id="category" className="form-select cursor-pointer"
                                    value={filterType} onChange={(e) => {
                                        onStatusTypeChange(e?.target?.value)
                                        setFilterType(e?.target?.value)
                                    }} >
                                    {DEFAULT_FILTER_STATUS?.map((d) => {
                                        return (
                                            <option value={d.id}>{d?.name}</option>)
                                    })}

                                </select>
                            </div>
                        </>
                    )
                }
                }
            />
        }
        {
            invoiceDetailsModal && <ViewInvoiceModal viewInvoiceModal={invoiceDetailsModal}
                id="ViewInvoiceModal"
                setViewInvoiceModal={() => setInvoiceDetailsModal(false)}
                viewOrderDetails={InvoiceDetail}
                getOfferDetailsList={getOfferDetailsList()}
                ViewInvoiceDetailsId={InvoiceDetail && InvoiceDetail?.invoiceId}
            />
        }
    </div >
}

export default InvoiceList;