import React from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
const animatedComponents = makeAnimated();

const CustomerTypeAhead = ({ handleChange, arrayValue, fields, searchCustomer }) => {
    let convertArray = arrayValue?.map(d => ({ label: d.firstName + ' ' + d.mobileNumber, value: d.id }))
    return <Select
        id='Select'
        closeMenuOnSelect={false}
        components={animatedComponents}
        isMulti
        value={fields}
        options={convertArray}
        onChange={handleChange}
        onInputChange={(value) => searchCustomer.current(value)}
    />

    


}



export default CustomerTypeAhead;