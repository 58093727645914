import moment from 'moment';

export const isTokenExpired = (milliseconds) => {
    return milliseconds && moment(milliseconds - 10000).isBefore(moment());
}


export const convertExpirySecToMilliSec = (expSec) => {
    return moment().add(expSec, 'seconds').valueOf();
}

const MomentFunctions = {
    isTokenExpired,
    convertExpirySecToMilliSec
}

export default MomentFunctions;