import moment from 'moment';
import React from 'react';
import AppModal from '../../components/modal/AppModal';

const HitachiMonitoringModal = ({ onClose = () => null,
    title,
    show,
    hitachiDetails,
    renderSessionStatus = () => null,
    navInvoiceScreen = () => null
}) => {
    return <AppModal show={show} id="AppModal"
        onClose={onClose}
        title={title}
    >
        <div style={{
            margin: '-10px 20px 20px',
            alignItems: 'flex-start',
        }}>
            <div className='row'>
                <div className='col-sm-8'>
                    <h4 style={{ textAlign: 'start' }}><b>Session Details</b></h4>
                </div>
                <div className='col-sm-4'>
                    <h4 style={{ textAlign: 'start' }}><b>{renderSessionStatus()}</b></h4>
                </div>
            </div>
        </div>
        <div style={{
            display: 'flex', margin: '-10px 20px 20px',
            alignItems: 'flex-start',
            flexDirection: 'column'
        }}>
            <p>Session ID: {hitachiDetails?.session_id}</p>
            {hitachiDetails?.invoice_id && <p>Receipt ID:
                <span id='navInvoiceScreen'
                    className="text-underline cursor-pointer"
                    onClick={(e) =>
                        navInvoiceScreen(e, hitachiDetails?.invoice_id)}>
                    {hitachiDetails?.invoice_id}</span></p>}
            {hitachiDetails?.order_id && <p>Order no: {hitachiDetails?.order_id}</p>}
            <p>Email: {hitachiDetails?.email}</p>
            <p>Mobile : {hitachiDetails?.mobile}</p>
            <p>Entry Time : {moment(hitachiDetails?.entry_time).format('MM/DD/YYYY - hh:mm:ss A')}</p>
            <p>Exit Time : {moment(hitachiDetails?.exit_time).format('MM/DD/YYYY - hh:mm:ss A')}</p>
            {
                hitachiDetails?.skus_from_hitachi &&
                <p><b>Invoicing SKU'S:</b> {hitachiDetails?.skus_from_hitachi}</p>
            }
            {
                hitachiDetails?.skus_invalid_pim?.length > 0 &&
                <p><b>SKU not in PIM:</b>{hitachiDetails?.skus_invalid_pim}</p>
            }
            {hitachiDetails?.exit_response && <p><b>Exit Response:</b> {hitachiDetails?.exit_response}</p>}

        </div>
    </AppModal>
}

export default HitachiMonitoringModal;