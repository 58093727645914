import moment from "moment";
import React from "react";
import Close from '../../../../asset/new/img/modal-close.svg'

const HappyHoursDetailModal = ({  isCloseModal, happyHoursDetails }) => {
    return <>
        <div className="modal d-block"
            id="view-multi-modal"
            tabIndex="-1"
            aria-labelledby="view-multi-modal"
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content rounded-0">
                    <img src={Close} alt="X" id="Close" data-bs-dismiss="modal" aria-label="Close" className="modal-close"
                        onClick={() => { isCloseModal(false) }}
                    />
                    <div className="modal-header rounded-0 align-items-center py-4">
                        <div className="col"> <h5 className="modal-title">Happy Hours Offer ID #{happyHoursDetails?.id}</h5> </div>
                    </div>
                    <div className="modal-body font14 p-40">

                        <div className="row mb-3">
                            <div className="col-4 fw-bold"> Product Name </div>
                            <div className="col-8 title-case"> {happyHoursDetails?.productName?.toLowerCase()} <small>(SKU {happyHoursDetails?.sku})</small></div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-4 fw-bold"> Offer Type </div>
                            <div className="col-8"> {happyHoursDetails?.offer?.offerType} </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-4 fw-bold"> Offer Value </div>
                            <div className="col-8">
                                {happyHoursDetails?.offer?.offerType === "PERCENT" ? `${happyHoursDetails?.offer?.offerValue}%` : `$` + parseFloat(happyHoursDetails?.offer?.offerValue)?.toFixed(2)}
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-4 fw-bold">Valid From </div>
                            <div className="col-8"> {moment(happyHoursDetails?.startDateString, 'YYYY-DD-MM').format('MM/DD/YYYY')}</div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-4 fw-bold">Valid To </div>
                            <div className="col-8"> {moment(happyHoursDetails?.endDateString, 'YYYY-DD-MM').format('MM/DD/YYYY')}</div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-4 fw-bold"> Begining Time</div>
                            <div className="col-8"> {happyHoursDetails?.startTime} </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-4 fw-bold">End Time </div>
                            <div className="col-8"> {happyHoursDetails?.endTime}</div>
                        </div>

                        {/* <div className="row mb-3">
                            <div className="col-4 fw-bold"> Status </div>
                            <div className="col-8 text-success"> Live </div>
                        </div> */}


                        <div className="row my-4">
                            <div className="col"> <a id="isCloseModal" onClick={() => { isCloseModal(false) }} data-bs-dismiss="modal" aria-label="Close" className="btn btn-dark font14 w-100 p-2" > Close</a> </div>
                        </div>
                        {/* <div style={{ display: 'flex', flexDirection: 'column', margin: '5px 16px' }}>
                            <div style={{ display: 'flex', marginTop: '10px', flexDirection: 'row' }}>
                                <Label style={{ minWidth: '100px' }}>Product Name :</Label>
                                <div style={{ minWidth: '300px' }}>
                                    <Label style={{ minWidth: '100px' }} className="title-case">{happyHoursDetails.productName?.toLowerCase()}</Label>

                                </div>
                            </div>
                            <div style={{ display: 'flex', marginTop: '10px', flexDirection: 'row' }}>
                                <Label style={{ minWidth: '100px' }}>SKU :</Label>
                                <div style={{ minWidth: '300px' }}>
                                    <Label style={{ minWidth: '100px' }}>{happyHoursDetails.sku}</Label>

                                </div>
                            </div>
                            <div style={{ display: 'flex', marginTop: '10px', flexDirection: 'row' }}>
                                <Label style={{ minWidth: '100px' }}>Offer Value :</Label>
                                <div style={{ minWidth: '300px' }}>
                                    <Label style={{ minWidth: '100px' }}>{happyHoursDetails.offer.offerValue}</Label>

                                </div>
                            </div>
                            <div style={{ display: 'flex', marginTop: '10px', flexDirection: 'row' }}>
                                <Label style={{ minWidth: '100px' }}>Offer Type :</Label>
                                <div style={{ minWidth: '300px' }}>
                                    <Label style={{ minWidth: '100px' }}>{happyHoursDetails.offer.offerType}</Label>

                                </div>
                            </div>


                            <div style={{ display: 'flex', marginTop: '10px', flexDirection: 'row' }}>
                                <Label style={{ minWidth: '100px' }}>Valid from:</Label>
                                <div style={{ minWidth: '300px' }}>
                                    <Label style={{ minWidth: '100px' }}>{moment(happyHoursDetails?.startDateString, 'YYYY-DD-MM').format('YYYY-MM-DD')}</Label>

                                </div>
                            </div>

                            <div style={{ display: 'flex', marginTop: '10px', flexDirection: 'row' }}>
                                <Label style={{ minWidth: '100px' }}>Expiry on:</Label>
                                <div style={{ minWidth: '300px' }}>
                                    <Label style={{ minWidth: '100px' }}>{moment(happyHoursDetails?.endDateString, 'YYYY-DD-MM').format('YYYY-MM-DD')}</Label>

                                </div>
                            </div>
                            <div style={{ display: 'flex', marginTop: '10px', flexDirection: 'row' }}>
                                <Label style={{ minWidth: '100px' }}>Begining Time:</Label>
                                <div style={{ minWidth: '300px' }}>
                                    <Label style={{ minWidth: '100px' }}>{happyHoursDetails?.startTime}</Label>

                                </div>
                            </div>

                            <div style={{ display: 'flex', marginTop: '10px', flexDirection: 'row' }}>
                                <Label style={{ minWidth: '100px' }}>End Time:</Label>
                                <div style={{ minWidth: '300px' }}>
                                    <Label style={{ minWidth: '100px' }}>{happyHoursDetails?.endTime}</Label>

                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>


            </div>
        </div>
        <div className="modal-backdrop show"></div>
    </>
};

export default HappyHoursDetailModal;

{/* <AppModal
    id="AppModal"
    title="Happy Hours Details"
    onClose={() => {
        isCloseModal(false)
    }}
    show={isHappyHoursDetailModal}

>
</AppModal> */}