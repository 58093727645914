import { doAction, doResponseAction } from ".";

const SUBSCRIPTION = 'SUBSCRIPTION';
const SUBSCRIPTION_LIST_SUCCESS = 'SUBSCRIPTION_LIST_SUCCESS';
const SUBSCRIPTION_LIST_FAILURE = 'SUBSCRIPTION_LIST_FAILURE'

const SUBSCRIPTION_CREATE = 'SUBSCRIPTION_CREATE';
const SUBSCRIPTION_CREATE_SUCCESS = 'SUBSCRIPTION_CREATE_SUCCESS';
const SUBSCRIPTION_CREATE_FAILURE = 'SUBSCRIPTION_CREATE_FAILURE';



export {
    SUBSCRIPTION,
    SUBSCRIPTION_LIST_SUCCESS,
    SUBSCRIPTION_LIST_FAILURE,

    SUBSCRIPTION_CREATE,
    SUBSCRIPTION_CREATE_SUCCESS,
    SUBSCRIPTION_CREATE_FAILURE,

}

export const getSubscriptionList = (params, callback) => doAction(SUBSCRIPTION, params, callback);
export const getSubscriptionListSuccess = (data) => doResponseAction(SUBSCRIPTION_LIST_SUCCESS, data);
export const getSubscriptionListFailure = (data) => doResponseAction(SUBSCRIPTION_LIST_FAILURE, data);

export const createSubscriptionList = (params, callback) => doAction(SUBSCRIPTION_CREATE, params, callback);
export const createSubscriptionSuccess = (data) => doResponseAction(SUBSCRIPTION_CREATE_SUCCESS, data);
export const createSubscriptionFailure = (data) => doResponseAction(SUBSCRIPTION_CREATE_FAILURE, data);


