import Actions from "../actions";

const initialState = {
    isLoading: false,
    storeAvailability: [],
    update: {},
    error: false,
}
const storeAvailability = (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.STORE_AVAILABILITY: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.STORE_AVAILABILITY_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                storeAvailability: action.data?.response || [],
                error: false
            };
        }
        case Actions.STORE_AVAILABILITY_FAILURE: {
            return {
                ...state,
                storeAvailability: [],
                isLoading: false
            };
        }
        case Actions.UPDATE_STORE_AVAILABILITY: {
            return {
                ...state,
                isLoading: true,
                error:false
            };
        }
        case Actions.UPDATE_STORE_AVAILABILITY_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                update: action.data?.response || {},
                error: false
            };
        }
        case Actions.UPDATE_STORE_AVAILABILITY_FAILURE: {
            return {
                ...state,
                update: [],
                isLoading: false
            };
        }
        default:
            return state;
    }
};

export default storeAvailability;

export const getStoreAvailabilitySelector = state => state?.STORE_AVAILABILITY?.storeAvailability;
export const getStoreAvailabilityLoading = state => state?.STORE_AVAILABILITY?.isLoading;
