import Actions from "../actions";

const initialState = {
    isLoading: false,
    refundsList: [],
    error: false,
    totalCount: undefined,
    refundDetails: {},
    isRefundLoading: false,
    isRefundError: false
}
const RefundsReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.GET_REFUNDS: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.GET_REFUNDS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                refundsList: action.data?.response || [],
                totalCount: action.data?.count,
                error: false
            };
        }
        case Actions.GET_REFUNDS_FAILURE: {
            return {
                ...state,
                isLoading: false,
                refundsList: [],
                error: action.error,
            };
        }

        case Actions.GET_REFUND_DETAILS: {
            return {
                ...state,
                isRefundLoading: true,
            };
        }
        case Actions.GET_REFUND_DETAILS_SUCCESS: {
            return {
                ...state,
                isRefundLoading: false,
                refundDetails: action.data?.response || {},
                isRefundError: false
            };
        }
        case Actions.GET_REFUND_DETAILS_FAILURE: {
            return {
                ...state,
                isRefundLoading: false,
                refundDetails: {},
                isRefundError: action.error,
            };
        }
        case Actions.UPDATE_SELECTED_PRODUCTS: {
            return {
                ...state,
                isRefundLoading: false,
                refundDetails: action.data || {},
                isRefundError: false
            };
        }
        default:
            return state;
    }
};

export default RefundsReducer;

export const getRefundsListSelector = state => state?.REFUNDS?.refundsList;
export const getRefundsCountSelector = state => state?.REFUNDS?.totalCount;
export const getRefundsListLoadingSelector = state => state?.REFUNDS?.isLoading;
export const getRefundDetailSelector = state => state?.REFUNDS?.refundDetails