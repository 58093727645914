import React, { useState, useEffect } from 'react';
import { getDashboardListSelector } from '../../redux/reducers/GetDashboardDetailsReducer';
import { getSelectedStoreSelector, getCustomerCountUpdateLoading } from '../../redux/reducers/StoreReducer';
import { useDispatch, useSelector } from 'react-redux';
import Actions from '../../redux/actions';
import StoreThumb from '../../../asset/new/img/banner_new.png'
import DashboardStore from './DashboardStore';
import { getUserInfoSelector } from '../../redux/reducers/LoginReducer';
import Minus from '../../../asset/new/img/minus.svg'
import Plus from '../../../asset/new/img/plus.svg'
import { newNotificationsSelector } from '../../redux/reducers/NotificationsReducer';
import Alert from '../../components/alert/alert';
import moment from 'moment';
import { useHistory } from 'react-router';
import RoutePath from '../../utils/routes/RoutePath';
import { getStoreAvailabilityLoading } from '../../redux/reducers/StoreAvailabilityReducers';
import 'react-datepicker/dist/react-datepicker.css'
import AppLoader from '../../components/fields/AppLoader';
import { toast } from 'react-toastify';
import Constants from '../../utils/Constants';
import { getCookie,ClearCookie, getRoleIdFromCookie,isJSON } from '../../utils/commonFunctions/cookie';

const DashboardScreen = () => {
    const selectedStore = useSelector(getSelectedStoreSelector);
    const dashboardDetails = useSelector(getDashboardListSelector);
    const userInfo = useSelector(getUserInfoSelector)
    const storeAvailabilityLoading = useSelector(getStoreAvailabilityLoading)
    const { id: storeId, customerLimit, scangoAndgoEnabled } = selectedStore || {};
    const newNotificationsDetails = useSelector(newNotificationsSelector);
    const isLoading = useSelector(getCustomerCountUpdateLoading);
    const [scanAndGoModal, setScanAndGoModal] = useState(false);
    const history = useHistory()
    const [visitorCount, setVisitorCount] = useState(customerLimit || null);
    const roleId = getRoleIdFromCookie()
    const [loading, setLoader] = useState(false)
    const [isNilgirisUser,setIsNilgirisUser]=useState(false)
    const [notificationFilters, setNotificationFilters] = useState({
        limit: 20,
        offset: 0,
        isResolved: 0
    });
    const dispatch = useDispatch()
    const size = 3
    const notifications = newNotificationsDetails?.slice(0, size)

    useEffect(() => {
        const tokenDetails = isJSON(getCookie('token')) ? JSON.parse(getCookie('token')) : undefined
        setIsNilgirisUser(tokenDetails?.customer?.nilgirisUser);
    }, [])

    useEffect(() => {
        const callback = (resp) => {
            setLoader(false)
            if (resp?.response_code === 0) {
                const { store } = (resp?.response && resp?.response[storeId ? storeId - 1 : 0]) || {}
                setVisitorCount(store?.customerLimit)
                setLoader(false)
            } else {
                setLoader(false)
            }
        }
        dispatch(Actions.getDashboardDetailsAction(notificationFilters, callback));
        setLoader(true)
    }, [dispatch]);

    const onVisitor = (value) => {
        const getCurrentRole = getRoleIdFromCookie()
        if (!getCurrentRole && getCurrentRole !== Constants.ROLES.ADMIN) {
            ClearCookie()
        }
        if (value === 'plus') {
            setVisitorCount(v => v + 1);
        } else if (value === 'minus') {
            setVisitorCount(v => v - 1);
        }
    }

    const onUpdateVisitorCount = () => {
        const getCurrentRole = getRoleIdFromCookie()
        if (!getCurrentRole && getCurrentRole !== Constants.ROLES.ADMIN) {
            ClearCookie()
        }
        let params = {
            id: storeId,
            customerLimit: visitorCount
        }
        const callback = (res) => {
            if (res?.response_code === 0) {
                toast.success("Store visitor updated")
            } else {
                toast.error(res?.response_message)
            }
        }
        dispatch(Actions.updateCustomerCount(params, callback));
    }

    const openScanAndGoModal = (scan, type) => {
        const getCurrentRole = getRoleIdFromCookie()
        if (!getCurrentRole && getCurrentRole !== Constants.ROLES.ADMIN) {
            ClearCookie()
        }
        if ((!scan && type === 'enable') || (scan && type == 'disable')) {
            setScanAndGoModal(true)
        }
    }

    const openNotification = () => {
        const getCurrentRole = getRoleIdFromCookie()
        if (!getCurrentRole && getCurrentRole !== Constants.ROLES.ADMIN) {
            ClearCookie()
        }
        history.push(RoutePath.NOTIFICATION)
    }
    const onScanAndGoStatus = (value) => {
        let params = {
            id: storeId,
            scangoAndgoEnabled: value
        }
        dispatch(Actions.updateStoreOpenAndClose(params, () => {
            dispatch(Actions.getStoreList());
        }));
        setScanAndGoModal(false)
    }
    const getNotifyName = (notificationDetails) => {

        if (notificationDetails.notifiedBy === 1) {
            return 'INTERNAL'
        } else if (notificationDetails.notifiedBy === 2) {
            return 'VISION_CHECKOUT'
        } else if (notificationDetails.notifiedBy === 3) {
            return 'WIWO'
        } else if (notificationDetails.notifiedBy === 4) {
            return 'COLD TRUTH'
        } else {
            return 'GENERAL'
        }
    };
    const actionType = (actions) => {

        switch (actions) {
            case 1:
                return 'HIGH';
            case 2:
                return 'MED';
            default:
                return 'LOW';
        }
    }
    const actionColor = (color) => {
        switch (color) {
            case 1:
                return '#cd2d2d';
            case 2:
                return '#333';
            default:
                return '#878787';
        }
    }


    const handleLinkChange = (params, id) => {
        const getCurrentRole = getRoleIdFromCookie()
        if (!getCurrentRole && getCurrentRole !== Constants.ROLES.ADMIN) {
            ClearCookie()
        }
        let selectionIdParams = {
            selectedTitleId: id
        }
        dispatch(Actions.setDashboardTitleId(selectionIdParams))
        switch (params) {
            case 'invoice':
                history.push(RoutePath.INVOICE_LIST)
                break;
            case 'orders':
                history.push(RoutePath.ORDERS_PATH)
                break;
            case 'refund':
                history.push(RoutePath.REFUND_SCREEN_PATH)
                break;
            case 'customerunblocking':
                history.push(RoutePath.CUSTOMER_UNBLOCKING)
                break;
            case 'notification':
                history.push(RoutePath.NOTIFICATION)
                break;
        }
    }
    return <div className="col ps-lg-4">
        <div className="row mb-4">
            <div className="col-12 col-md-6 col-xl-5">
                {(storeAvailabilityLoading || loading) && < AppLoader />}
                <div id="store-info" className="card card-body rounded-3 border-0 shadow p-4 mb-3 mb-xl-0">
                    <h2 className="font15 fw-bold text-uppercase mb-3">Store Information</h2>
                    <div className="row">
                        <div className="col font13 ps-0 lh-lg">
                            Store Code : {selectedStore?.branchName} <br />
                            Staff Duty : {userInfo?.firstName}{' '}{userInfo?.lastName} <br />
                            Employee Id : {dashboardDetails?.employeeId} <br />
                            Store Area : {selectedStore?.location}
                        </div>
                        <div className="col-auto">
                            <img src={StoreThumb} alt="UST" className="rounded-3 img-fluid mb-2" />
                        </div>
                    </div>

                </div>

            </div>

            <div className="col-12 col-md-6 col-xl-4">
                {<DashboardStore />}
            </div>

            {(roleId === Constants.ROLES.ADMIN || (roleId === Constants.ROLES.STORE_MANAGER && !isNilgirisUser)) &&
                <div className="col-12 col-md-6 col-xl-3">
                    <div id="store-status"
                        className="card card-body rounded-3 border-0 shadow p-4 mb-3 mb-xl-0 ">
                        <h2 className="font15 fw-bold text-uppercase mb-4">Scan & Go</h2>
                        <form>
                            <div className="form-check mb-4">
                                <input className="form-check-input me-3 me-xl-4 cursor-pointer"
                                    type="radio" name="open-close"
                                    id="storeopen"
                                    onClick={(e) => openScanAndGoModal(scangoAndgoEnabled, 'enable')}
                                    checked={scangoAndgoEnabled ? true : false} />
                                <label className="form-check-label" for="store-open">
                                    <h5 className="font13 fw-bold text-success">Enable</h5>
                                    <p className="font10">Check this mark to enable.</p>
                                </label>
                            </div>

                            <div className="form-check mb-3">
                                <input className="form-check-input me-3 me-xl-4 cursor-pointer"
                                    type="radio" name="open-close" id="storeclose"
                                    onClick={(e) => openScanAndGoModal(scangoAndgoEnabled, 'disable')}
                                    checked={scangoAndgoEnabled ? false : true} />
                                <label className="form-check-label" for="store-close">
                                    <h5 className="font13 fw-bold text-danger">Disable</h5>
                                    <p className="font10">Check this mark to disable.</p>
                                </label>
                            </div>
                        </form>
                    </div>

                </div>
            }
        </div>
        {(roleId === Constants.ROLES.ADMIN || (roleId === Constants.ROLES.STORE_MANAGER && !isNilgirisUser)) && <div className="row mb-4">
            <div className="col">

                <div id="set-max-cap" className="card card-body rounded-3 border-0 shadow p-4 mb-3 mb-xl-0">

                    <div className="row align-items-center">
                        <div className="col-sm-2 col-6">
                            <h2 className="font16 fw-bold text-uppercase"> Set Max Visitor </h2>
                        </div>
                        <div className="col-sm-2 col-6">
                            <div className="input-group quantity">
                                <button className="btn px-1" type="button"
                                    id='visitorCount'
                                    disabled={visitorCount === 0 ? true : false}
                                    onClick={() => onVisitor('minus')}>
                                    <img src={Minus} alt="-" width="12" /> </button>
                                <input type="text"
                                    className="form-control font16 px-1 text-center" value={visitorCount} />
                                <button id='visitorCountPlus' className="btn px-1" type="button" onClick={() => onVisitor('plus')}>
                                    <img src={Plus} alt="+" height="12" /> </button>
                            </div>
                        </div>
                        <div className="col-sm-3 col-12 mt-sm-0 mt-3">
                            <button className="btn btn-dark p-2 px-5 text-uppercase w-100" id='onUpdateVisitor'
                                name="" onClick={onUpdateVisitorCount}>Update</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>}

        <div className="row mb-4">
            {(roleId === Constants.ROLES.ADMIN || roleId === Constants.ROLES.STORE_MANAGER) &&

                <div className="col-12 col-lg-6 col-xl-7">

                    <div id="latest-notifications" className="card card-body rounded-3 border-0 shadow p-4 mb-3 mb-xl-0">
                        <h2 className="font15 fw-bold text-uppercase mb-5">LATEST NOTIFICATIONS</h2>
                        {notifications?.map((o) => {
                            return (<><div className="row align-items-center">
                                <div className="col-auto">
                                    <h5 className="font12 fw-bold mb-2">{o?.title}</h5>
                                    <p className="font14">{o?.description}</p>
                                    <h4 className='font12 fw-bold' >{getNotifyName(o)}</h4>
                                </div>
                                <div className="col font12 fw-bold text-end">
                                    <h4 className="font12 fw-bold"
                                        style={{ color: actionColor(o?.actionType) }}
                                    >{actionType(o?.actionType)}</h4>
                                    <br />
                                    <h4 className='font12 fw-bold'>{moment(o?.createdDate).format('DD-MMM | h:mm a')}</h4>
                                </div>
                            </div>
                                <hr className='bg-ltgrey-1 mb-4' />
                            </>
                            )

                        })}
                        {notifications.length > 0 ? < div className="row">
                            <div className="col-12 col-xl-12">
                                <button onClick={openNotification} id='clickOpenNotification' className="w-100 btn btn-dark p-2 px-5 text-uppercase mt-1" name="">View all notifications</button>
                            </div>
                        </div> : < div className="row">
                            <h5>You don't have any notifications currently</h5>
                        </div>
                        }
                    </div>

                </div>
            }
            {!isNilgirisUser &&
                < div className="col-12 col-lg-6 col-xl-5">

                    <div id="analytics" className="card card-body rounded-3 border-0 shadow p-4 mb-3 mb-xl-0">
                        <h2 className="font15 fw-bold text-uppercase mb-4">Analytics</h2>

                        <div className="row  g-2 font11 text-center">
                            <div id='Requested' className="col-6 col-md-4 cursor-pointer" onClick={() => handleLinkChange('orders', 1)}>
                                <div className="card card-body border-0 bg-pgreen px-2">
                                    <h3 className="font40 fw-bold">{roleId === Constants.ROLES.STORE_ASSOCIATE ? dashboardDetails?.GroceryPendingOrders : dashboardDetails?.pendingOrdersCount}</h3> Requested <br />orders
                                </div>
                            </div>
                            <div id='in-progress' className="col-6 col-md-4 cursor-pointer" onClick={() => handleLinkChange('orders', 2)}>
                                <div className="card card-body border-0 bg-ppurple px-2">
                                    <h3 className="font40 fw-bold">{roleId === Constants.ROLES.STORE_ASSOCIATE ? dashboardDetails?.GroceryInProgress : dashboardDetails?.inProgress}</h3> Orders <br /> in-progress
                                </div>
                            </div>
                            <div id='deliver' className="col-6 col-md-4 cursor-pointer" onClick={() => handleLinkChange('orders', 4)}>
                                <div className="card card-body border-0 bg-porange px-2">
                                    <h3 className="font40 fw-bold ">{roleId === Constants.ROLES.STORE_ASSOCIATE ? dashboardDetails?.GroceryYetToDelivery : dashboardDetails?.yetToDelivery}</h3> Yet to <br /> deliver
                                </div>
                            </div>
                            {(roleId === Constants.ROLES.ADMIN || roleId === Constants.ROLES.STORE_MANAGER) && <>

                                <div id='WIWO' className="col-6 col-md-4 cursor-pointer" onClick={() => handleLinkChange('invoice', 1)}>
                                    <div className="card card-body bg-white px-2">
                                        <h3 className="font40 fw-bold mb-3">{dashboardDetails?.wiwoSaleCount}</h3> WIWO <br /> orders
                                    </div>
                                </div>
                                <div id='Hybrid' className="col-6 col-md-4 cursor-pointer" onClick={() => handleLinkChange('invoice', 3)}>
                                    <div className="card card-body bg-white px-2" >
                                        <h3 className="font40 fw-bold mb-3">{dashboardDetails?.hybridSaleCount}</h3> Hybrid <br /> orders
                                    </div>
                                </div>
                                <div id='Online' className="col-6 col-md-4 cursor-pointer" onClick={() => handleLinkChange('invoice', 2)}>
                                    <div className="card card-body bg-white px-2" >
                                        <h3 className="font40 fw-bold mb-3">{dashboardDetails?.onlineSalesCount}</h3> Online <br /> orders
                                    </div>
                                </div>

                                <div id='refund' className="col-6 col-md-4 cursor-pointer" onClick={() => handleLinkChange('refund')}>
                                    <div className="card card-body bg-white px-2">
                                        <h3 className="font40 fw-bold ">{dashboardDetails?.pendingRefund}</h3> Refund <br /> requests
                                    </div>
                                </div>
                                <div id='notification' className="col-6 col-md-4 cursor-pointer" onClick={() => handleLinkChange('notification')}>
                                    <div className="card card-body bg-white px-2">
                                        <h3 className="font40 fw-bold mb-3">{dashboardDetails?.notificationCount}</h3> Notifications
                                    </div>
                                </div>
                                <div id='customerunblocking' className="col-6 col-md-4 cursor-pointer" onClick={() => handleLinkChange('customerunblocking')}>
                                    <div className="card card-body bg-white px-2">
                                        <h3 className="font40 fw-bold ">{dashboardDetails?.blockedCustomersCount}</h3> Blocked customers
                                    </div>
                                </div>
                            </>
                            }
                        </div>
                    </div>
                </div>}
        </div>
        {
            scanAndGoModal && <Alert
                id="scanAndGo"
                title={"Confirm"}
                proceed={"Yes"}
                cancel={"No"}
                description={`Do you want to ${selectedStore?.scangoAndgoEnabled ? 'Disable' : 'Enable'} SCAN & GO ?`}
                onSuccess={() =>
                    selectedStore?.scangoAndgoEnabled ?
                        onScanAndGoStatus(false) : onScanAndGoStatus(true)}
                onFailure={() => setScanAndGoModal(false)}
            />
        }
        {
            (isLoading) && <AppLoader />
        }

    </div >
};


export default DashboardScreen;