import { all, put, takeEvery } from "redux-saga/effects";
import { doAuthGet } from "../../utils/service";
import END_POINTS from "../../utils/service/Endpoints";
import Actions from "../actions";

export function* bistroDisplayList() {
    try {
        const response = yield doAuthGet(END_POINTS.BISTRO_DISPLAY_CHEF_LIST);
        if (response) {
            yield put(Actions.bistroDisplayOrderListSuccessAction(response));
        }
    }
    catch (error) {
        yield put(Actions.bistroDisplayOrderListFailureAction({ error }));
    }
}

export function* bistroCustomerDisplayList() {
    try {
        const response = yield doAuthGet(END_POINTS.BISTRO_DISPLAY_CUSTOMER_LIST);
        if (response) {
            yield put(Actions.bistroDisplayCustomerOrdersSuccessAction(response));
        }
    }
    catch (error) {
        yield put(Actions.bistroDisplayCustomerOrdersFailureAction({ error }));
    }
}

export default function* bistroDisplayListner() {
    yield all([
        takeEvery(Actions.BISTRO_DISPLAY_ORDER_LIST, bistroDisplayList),
        takeEvery(Actions.BISTRO_DISPLAY_CUSTOMER_ORDERS, bistroCustomerDisplayList),
    ]);
}
