import React from "react";
import Stepper from "react-stepper-horizontal/lib/Stepper";

export const ProgressBar = (props) => {
    const steps = [{
        title: 'Select Camera',
        onClick: (e) => {
            e.preventDefault()
            props.updateStep(1)
        }
    }, {
        title: 'Annotate Rack',
        onClick: (e) => {
            e.preventDefault()
            props.updateStep(2)
        }
    }, {
        title: 'Update Planogram',
        onClick: (e) => {
            e.preventDefault()
            props.updateStep(3)
        }
    }]
    return (
        <div className="stepper-main">
            <Stepper
                lineMarginOffset={10}
                circleTop={0}
                size={40}
                circleFontSize={18}
                completeBarColor={"#000"}
                completeColor={"#000"}
                activeColor={"#000000"}
                activeBorderColor={"#ffffff"}
                steps={steps}
                activeStep={props.currentStep}
                alternativeLabel />
        </div>
    )
}