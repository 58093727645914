import { doAction, doResponseAction } from ".";

const GET_NEW_NOTIFICATIONS = 'GET_NEW_NOTIFICATIONS';
const GET_NEW_NOTIFICATIONS_SUCCESS = 'GET_NEW_NOTIFICATIONS_SUCCESS';
const SET_NEW_NOTIFICATIONS_DATA = 'SET_NEW_NOTIFICATIONS_DATA';
const GET_NEW_NOTIFICATIONS_FAILURE = 'GET_NEW_NOTIFICATIONS_FAILURE';
const GET_RESOLVED_NOTIFICATIONS = 'GET_RESOLVED_NOTIFICATIONS';
const GET_RESOLVED_NOTIFICATIONS_SUCCESS = 'GET_RESOLVED_NOTIFICATIONS_SUCCESS';
const GET_RESOLVED_NOTIFICATIONS_FAILURE = 'GET_RESOLVED_NOTIFICATIONS_FAILURE';
const GET_NEW_NOTIFICATION_BY_ID = 'GET_NEW_NOTIFICATION_BY_ID';
const APPEND_NEW_NOTIFICATIONS_SUCCESS = 'APPEND_NEW_NOTIFICATIONS_SUCCESS';
const APPEND_NEW_NOTIFICATIONS_FAILURE = 'APPEND_NEW_NOTIFICATIONS_FAILURE';
const APPEND_RESOLVED_NOTIFICATIONS = 'APPEND_RESOLVED_NOTIFICATIONS';
const REMOVE_NEW_NOTIFICATIONS = 'REMOVE_NEW_NOTIFICATIONS';
const UPDATE_NOTIFICATIONS_RESOLVED_STATUS = 'UPDATE_NOTIFICATIONS_RESOLVED_STATUS';
const UPDATE_NOTIFICATIONS_RESOLVED_STATUS_SUCCESS = 'UPDATE_NOTIFICATIONS_RESOLVED_STATUS_SUCCESS';
const UPDATE_NOTIFICATIONS_RESOLVED_STATUS_FAILURE = 'UPDATE_NOTIFICATIONS_RESOLVED_STATUS_FAILURE';
const APPEND_NEW_NOTIFICATIONS_ARRAY = 'APPEND_NEW_NOTIFICATIONS_ARRAY';
const APPEND_RESOLVED_NOTIFICATIONS_ARRAY = 'APPEND_RESOLVED_NOTIFICATIONS_ARRAY';
const UPDATE_LATEST_NOTIFICATION_ID_LIST = 'UPDATE_LATEST_NOTIFICATION_ID_LIST';
const RESET_LATEST_NOTIFICATION_ID = 'RESET_LATEST_NOTIFICATION_ID';

export {
    GET_NEW_NOTIFICATIONS,
    GET_NEW_NOTIFICATIONS_SUCCESS,
    SET_NEW_NOTIFICATIONS_DATA,
    GET_NEW_NOTIFICATIONS_FAILURE,
    GET_RESOLVED_NOTIFICATIONS,
    GET_RESOLVED_NOTIFICATIONS_SUCCESS,
    GET_RESOLVED_NOTIFICATIONS_FAILURE,
    APPEND_NEW_NOTIFICATIONS_ARRAY,
    GET_NEW_NOTIFICATION_BY_ID,
    APPEND_NEW_NOTIFICATIONS_SUCCESS,
    APPEND_NEW_NOTIFICATIONS_FAILURE,
    APPEND_RESOLVED_NOTIFICATIONS,
    APPEND_RESOLVED_NOTIFICATIONS_ARRAY,
    REMOVE_NEW_NOTIFICATIONS,
    UPDATE_NOTIFICATIONS_RESOLVED_STATUS,
    UPDATE_NOTIFICATIONS_RESOLVED_STATUS_SUCCESS,
    UPDATE_NOTIFICATIONS_RESOLVED_STATUS_FAILURE,
    UPDATE_LATEST_NOTIFICATION_ID_LIST,
    RESET_LATEST_NOTIFICATION_ID,

}
export const getNewNotifications = store => store['NOTIFICATIONS']

export const getNewNotificationsDetailsAction = (params, callback) => doAction(GET_NEW_NOTIFICATIONS, params, callback);
export const getNewNotificationsDetailsActionSuccess = (data) => doResponseAction(GET_NEW_NOTIFICATIONS_SUCCESS, data);
export const setNewNotificationData = (data) => doResponseAction(SET_NEW_NOTIFICATIONS_DATA, data);
export const getNewNotificationsDetailsActionFailure = (data) => doResponseAction(GET_NEW_NOTIFICATIONS_FAILURE, data);
export const getResolvedNotificationsDetailsAction = (params, callback) => doAction(GET_RESOLVED_NOTIFICATIONS, params, callback);
export const getResolvedNotificationsDetailsActionSuccess = (data) => doResponseAction(GET_RESOLVED_NOTIFICATIONS_SUCCESS, data);
export const getResolvedNotificationsDetailsActionFailure = (data) => doResponseAction(GET_RESOLVED_NOTIFICATIONS_FAILURE, data);
export const updateNotificationResolvedStatus = (params, callback) => doAction(UPDATE_NOTIFICATIONS_RESOLVED_STATUS, params, callback);
export const updateNotificationResolvedStatusFailure = (data) => doResponseAction(UPDATE_NOTIFICATIONS_RESOLVED_STATUS_FAILURE, data);

export const removeNewNotificationsAction = (data) => doResponseAction(REMOVE_NEW_NOTIFICATIONS, data);
export const appendNewNotificationsArrayAction = (data) => doResponseAction(APPEND_NEW_NOTIFICATIONS_ARRAY, data);
export const appendResolvedNotificationsArrayAction = (data) => doResponseAction(APPEND_RESOLVED_NOTIFICATIONS_ARRAY, data);
export const appendResolvedNotificationsAction = (data) => doResponseAction(APPEND_RESOLVED_NOTIFICATIONS, data);


export const getNewNotificationDetailsByIdAction = (params, callback) => doAction(GET_NEW_NOTIFICATION_BY_ID, params, callback);
export const appendNewNotificationsActionSuccess = (data) => doResponseAction(APPEND_NEW_NOTIFICATIONS_SUCCESS, data);
export const appendNewNotificationsActionFailure = (data) => doResponseAction(APPEND_NEW_NOTIFICATIONS_FAILURE, data);


export const updateNewNotificationIDList = (data) => doResponseAction(UPDATE_LATEST_NOTIFICATION_ID_LIST, data);
export const resetNewNotificationIDList = () => doResponseAction(RESET_LATEST_NOTIFICATION_ID);
