import Actions from "../actions";

const initialState = {
    isLoading: false,
    productGroupInfo: [],
    error: false
}
const productGroupInformation = (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.PRODUCT_GROUP_INFORMATIONS: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.PRODUCT_GROUP_INFORMATIONS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                productGroupInfo: action.data?.response?.response || [],
                error: false
            };
        }
        case Actions.PRODUCT_GROUP_INFORMATIONS_FAILURE: {
            return {
                ...state,
                isLoading: false,
                productGroupInfo: [],
                error: action.error,
            };
        }
        default:
            return state;
    }
};

export default productGroupInformation;

export const productGroupInfoSelector = state => state?.PRODUCT_GROUP_INFORMATIONS?.productGroupInfo;
export const productGroupInfoLoadingSelector = state => state?.PRODUCT_GROUP_INFORMATIONS?.isLoading;