import { all, put, takeEvery } from "redux-saga/effects";
import { doAuthPost } from "../../utils/service";
import END_POINTS from "../../utils/service/Endpoints";
import Actions from "../actions";

export function* changePasswordSaga({ params, callback }) {
    try {
        const response = yield doAuthPost(END_POINTS.CHANGE_PASSWORD, params);
        if (response) {
            yield put(Actions.changePasswordSuccessAction(response));
            yield put(Actions.loginSuccessAction(response?.response));
        }
        callback && callback(response);
    }
    catch (error) {
        callback && callback(error);
        yield put(Actions.changePasswordFailureAction({ error }));
    }
}

export default function* changePasswordtListner() {
    yield all([
        takeEvery(Actions.CHANGE_PASSWORD, changePasswordSaga),
    ]);
}
