import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import AppButton from "../../components/fields/AppButton";
import Actions from "../../redux/actions";
import BoldError from "../../components/fields/BoldError";
import { toast } from "react-toastify";
import PasswordCheckPopover from "../../components/fields/PasswordCheckPopover";
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { validatedigitsRegExp, validatelowercaseRegExp, validateminLengthRegExp, validatespecialCharRegExp, validateuppercaseRegExp } from "../../utils/validation";


class ResetPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newPassword: "",
            reTypePassword: "",
            passwordValid: false,
            errorMessage: null,
            showPassword: false,
            showPopover: false
        };
    }
    resetPassword() {
        const { reTypePassword, passwordValid, newPassword } = this.state;
        let error = null;
        if (!newPassword) {
            this.setState({ errorMessage: "Please enter the password" })
            return;
        } else if (!reTypePassword) {
            this.setState({ errorMessage: 'Please Retype the password' });
            return;
        } else if (!validateuppercaseRegExp(newPassword)) {
            this.setState({ errorMessage: 'Please enter valid password' });
            return
        } else if (!validatelowercaseRegExp(newPassword)) {
            this.setState({ errorMessage: 'Please enter valid password' });
            return
        } else if (!validatespecialCharRegExp(newPassword)) {
            this.setState({ errorMessage: 'Please enter valid password' });
            return
        } else if (!validatedigitsRegExp(newPassword)) {
            this.setState({ errorMessage: 'Please enter valid password' });
            return
        } else if (!validateminLengthRegExp(newPassword)) {
            this.setState({ errorMessage: 'Please enter valid password' });
            return
        } else if (reTypePassword !== newPassword) {
            this.setState({ errorMessage: 'Password and Re-type passowrd does not match.' });
            return
        }
        const callback = (response) => {
            let { response_code, response_message } = response
            if (response_code === 0) {
                this.setState({ errorMessage: null });
                toast.success('Password got updated')
            } else {
                this.setState({ errorMessage: response_message })
            }
        }
        let params = {
            password: reTypePassword,
            type: 2
        }

        this.props.updateNewPassword(params, callback)
    }

    openLoginPage() {
        this.props.updateCurrentScreen('Login')
    }
    openForgetPage() {
        this.props.updateCurrentScreen('forget')
    }
    handleEyeIcon() {
        this.setState({ showPassword: !this.state.showPassword })
    }
    render() {
        const { errorMessage, newPassword, reTypePassword } = this.state;
        // const { currentScreen } = this.props.getForgetEmail;
        return (
            <> <div className="row">
                <div className="col-12 col-lg-5 m-auto">
                    <div className="card card-body p-50">
                        <div className="row align-items-center mb-4">
                            <div className="col">
                                <h2 className="font28 fw-bold">Reset Password </h2>
                            </div>
                        </div>
                        <BoldError message={errorMessage} />
                        <form>
                            <div className="row mb-4">
                                <div className="col"> Please enter your new password with which you would like to access</div>
                            </div>
                            <div className="row align-items-center mb-4">
                                <div className="input-group mb-4">
                                    {this.state.showPopover && <PasswordCheckPopover value={this.state.newPassword} />}
                                    <input
                                        id='Password'
                                        className="form-control"
                                        type={this.state.showPassword ? 'text' : 'password'}
                                        name="password"
                                        onFocus={() => this.setState({ showPopover: true })}
                                        onBlur={() => this.setState({ showPopover: false })}
                                        onChange={(e) => this.setState({ newPassword: e.target.value })}
                                        placeholder="New Password"
                                        aria-describedby="view-pass" />
                                    <button className="btn border-dark"
                                        onClick={() => this.handleEyeIcon()}
                                        type="button"
                                        id="view-pass">
                                        <FontAwesomeIcon
                                            style={{ cursor: 'pointer' }}
                                            icon={this.state.showPassword ? faEye : faEyeSlash} />
                                    </button>
                                </div>
                            </div>
                            <div className="row mb-4">
                                <div className="input-group mb-4">
                                    <input
                                        id='setPassword'
                                        className="form-control"
                                        type={this.state.showPassword ? 'text' : 'password'}
                                        name="password"
                                        onChange={(e) => this.setState({ reTypePassword: e.target.value })}
                                        placeholder="Retype Password"
                                        aria-describedby="view-pass" />
                                    <button className="btn border-dark"
                                        onClick={() => this.handleEyeIcon()}
                                        type="button"
                                        id="view">
                                        <FontAwesomeIcon
                                            style={{ cursor: 'pointer' }}
                                            icon={this.state.showPassword ? faEye : faEyeSlash} />
                                    </button>
                                </div>
                                {this.state.reTypePassword && this.state.newPassword !== this.state.reTypePassword && <p style={{ color: 'red', fontWeight: 24 }}>Password does not match</p>}
                            </div>
                            <div className="text-center pt-4 mb-4">
                                <AppButton title="RESET PASSWORD" onClick={() => this.resetPassword()} />
                            </div>
                            <div className="text-start">
                                <span className="text-dark cursor-pointer" id="openLoginPage" onClick={() => this.openLoginPage()}><strong>Back to login</strong></span>
                            </div>
                            <div className="text-start">
                                {/* <span className="text-dark" id="openLoginPage" onClick={() => this.setResetPasswordModal(false)}><strong>Reset</strong></span> */}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            </>
        );
    }
}


export const mapStateToProps = (state) => {
    return {
    };
};

export default withRouter(connect(mapStateToProps, {
    updateNewPassword: Actions.updatePassword,
    updateCurrentScreen: Actions.updateCurrentScreen,
})(ResetPassword))

