const PLANOGRAM_VIEW = '/planogram/rack'
// eslint-disable-next-line import/no-anonymous-default-export
export default {
    LOGIN_SCREEN_PATH: '/',
    DASHBOARD_SCREEN_PATH: '/dashboard',
    STORE_AVAILABILITY: '/storeavailability',
    ORDERS_PATH: '/orders',
    NAV_MANAGE_EXP_COLAPS: '/manage',
    MANAGE_PRODUCTS: '/products',
    MANAGE_FOOD_PRODUCTS: '/manage/foodproducts',
    NAV_PROMOTION_EXP_COLAPS: '/promotion',
    PRODUCTS: '/promotion/products',
    PROMOTION_DASHBOARD: '/promotion/dashboard',
    CUSTOMER_COUPON: '/promotion/customer/coupon',
    FREE_ITEMS: '/promotion/freeitems',
    HAPPY_HOURS_ITEM: '/promotion/happyhoursitem',
    COMBO_OFFER: '/promotion/combooffer',
    PRODUCT_DISCOUNT: '/promotion/productdiscount',
    MAP_PRODUCT: '/promotion/mapproduct',
    ORDERS_DETAILS: '/orders/details/:id',
    REFUND_SCREEN_PATH: '/refund',
    DOLLAR: '/promotion/dollar',
    MULTI_BUY: '/promotion/multiBuy',
    CHATS_SCREEN: '/chats',
    REPORT: '/report',
    CUSTOMER_UNBLOCKING: '/customerunblocking',
    PLANOGRAM: '/planogram',
    PLANOGRAM_VIEW,
    NOTIFICATION: '/notification',
    MEMBERS:'/members',
    CHANGE_PASSWORD: '/changepassword',
    SUBSCRIPTION: '/subscription',
    DAXBOT_STATUS: '/daxbotstatus',
    REFUND_DETAILS: '/refund/details/:id',
    VIEW_MAP: '/viewmap/:id',
    BISTRO_ORDERS: '/chef/orders',
    BISTRO_CUSTOMER_ORDERS: '/bistro/display/orders',
    INVOICE_LIST: '/invoiceList',
    INVOICE_DETAILS: '/invoice/details/:id',
    NAV_MONITORING_PATH: '/monitoring',
    HITACHI_MONITORING: '/monitoring/hitachi',
    REPORT_DETAILS: '/report/details',
    PLANOGRAM_REPORT: '/inventory/report',
    planogramView: (id) => `${PLANOGRAM_VIEW}?id=${id}`
}