import { doAction, doResponseAction } from ".";

const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';

const LOGOUT = 'LOGOUT';
const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

const REFRESH_TOKEN_ACTION = 'REFRESH_TOKEN_ACTION'
export {
    CHANGE_PASSWORD,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAILURE,
    LOGOUT,
    LOGOUT_FAILURE,
    LOGOUT_SUCCESS,
    REFRESH_TOKEN_ACTION
}

// CHANGE_PASSWORD
export const changePasswordAction = (params, callback) => doAction(CHANGE_PASSWORD, params, callback);
export const changePasswordSuccessAction = data => doResponseAction(CHANGE_PASSWORD_SUCCESS, data);
export const changePasswordFailureAction = data => doResponseAction(CHANGE_PASSWORD_FAILURE, data);

// LOGOUT
export const logoutAction = (callback) => doAction(LOGOUT, callback);
export const logoutSuccessAction = (data) => doResponseAction(LOGOUT_SUCCESS, data);
export const logoutFailureAction = (data) => doResponseAction(LOGOUT_FAILURE, data);


export const getRefreshToken = (params) => doAction(REFRESH_TOKEN_ACTION, params)