import debounce from 'lodash.debounce';
import React, { useRef, useState } from 'react';
import InputField from './InputField';
import PasswordCheckPopover, {
    ONE_CAPITAL_LETTER,
    ONE_LETTER,
    ONE_NUMBER,
    testRegex
} from './PasswordCheckPopover';

const PasswordInput = ({
    showPassword,
    error = null,
    defaultValue = '',
    onChange = () => null,
    isPasswordValid = () => null,
    showEyeIcon = false,
    hidePopover = false,
    placeholder = '',
    ...props
}) => {

    const [showPasswordPopup, setShowPassword] = useState(false);
    const [text, setText] = useState('');

    const debounceRef = useRef(debounce((value) => {
        if (testRegex(ONE_LETTER, value) && testRegex(ONE_CAPITAL_LETTER, value)
            && testRegex(ONE_NUMBER, value) && value.length > 7) {
            isPasswordValid(true)
        } else {
            isPasswordValid(false)
        }
    }, 500));


    return <>
        <InputField
            {...props}
            className="form-control"
            type={showPassword ? 'text' : 'password'}
            name="password"
            placeholder={placeholder}
            defaultValue={defaultValue}
            onFocus={() => setShowPassword(true)}
            onBlur={() => setShowPassword(false)}
            showEyeIcon={showEyeIcon}
            error={error}
            onChange={(value,name) => {
                setText(value)
                onChange(value,name);
                debounceRef.current(value)
            }}
        />

        {!hidePopover && showPasswordPopup &&
            <PasswordCheckPopover value={text} />}
    </>
}

export default PasswordInput;