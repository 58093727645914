import React from "react";
import Constants from "../../../utils/Constants";
import AppDatePicker from '../../../components/fields/AppDatePicker';
import BoldError from "../../../components/fields/BoldError";
import { faDollarSign, faPercent } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProductsTypeAhead from "../../../components/fields/ProductsTypeAhead";
import Close from '../../../../asset/new/img/modal-close.svg'
import AppLoader from "../../../components/fields/AppLoader";

const MultiBuyModal = ({ manageMultiBuy, setOpenMultiBuyModal, isCreate, offerValue, setOfferValue,
    setExpiryOn, setValidFrom, setProducts, setOfferType, offerType, expiryOn, validFrom, quantity, setQuantity,
    validationError, productName, setProductType, productType, isLoading }) => {

        console.log(productType,'typeMulti')

    return <React.Fragment>
        <div className="modal d-block"
            id="add-multi-modal"
            tabIndex="-1"
            aria-labelledby="unblock"
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                {isLoading && <AppLoader />}
                <div className="modal-content rounded-0">
                    <img id="close" src={Close} alt="X" data-bs-dismiss="modal"
                        aria-label="Close" className="modal-close" onClick={() => {
                            setOpenMultiBuyModal(false)
                        }} />
                    <div className="modal-header rounded-0 align-items-center py-4">
                        <div className="col">
                            <h5 className="modal-title">
                                {isCreate ? 'Add Multi Buy' : 'Edit Multi Buy'}</h5>
                        </div>
                    </div>
                    <div className="modal-body font13 p-40" >
                        {validationError && <BoldError message={validationError} />}
                        {isCreate &&
                            <div className="row mb-4">
                                <div className="col">
                                    <label for="category" className="form-label fw-bold">Select Product Category</label>
                                    <select
                                        id="category" name=""
                                        className="form-select"
                                        value={productType}
                                        onChange={(e) => {
                                            setProductType(e?.target?.value)
                                        }
                                        }>
                                        <option value="">Select Product type</option>
                                        {
                                            Constants.PRODUCT_TYPE.map((o) => {
                                                return (
                                                    <option value={o.value}>{o.KEY}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>}

                        <div className="row mb-4">
                            <div className="col-6">
                                <label for="offer-type" className="form-label fw-bold">Offer Type</label>
                                <select
                                    value={offerType}
                                    id="offer-type"
                                    name=""
                                    className="form-select"
                                    onChange={(e) => setOfferType(e?.target?.value)}>
                                    <option value="">Select Offer Type</option>
                                    {
                                        Constants.PRODUCT_DISCOUNTS.map((o) => {
                                            return (
                                                <option value={o.value}>{o.key}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className="col-6">
                                <label for="" className="form-label fw-bold">Offer Value</label>
                                <div className="input-group">
                                    {offerType === 'PERCENT' ? <span className="input-group-text border-dark">%</span> : <span className="input-group-text border-dark">$</span>}
                                    <input type="number" className="form-control"
                                        id="Offer"
                                        defaultValue={offerValue}
                                        placeholder="Enter Offer Value "
                                        onChange={(e) => setOfferValue(e?.target?.value)}
                                        suffixIcon={<FontAwesomeIcon
                                            // size="xs"
                                            icon={offerType === 'PERCENT' ?
                                                faPercent : offerType === 'DOLLAR' ? faDollarSign : null} />}
                                        onInput={offerType === 'PERCENT' ? (e) => {
                                            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 3)
                                        } : ""}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col-12 col-sm-6">
                                <label for="from-date" className="form-label fw-bold">Valid From</label>
                                <div>
                                    <AppDatePicker
                                        selected={validFrom}
                                        id="StartDate"
                                        placeholder="Valid From"
                                        minDate={new Date()}
                                        onChange={(date) => setValidFrom(date)}
                                    />
                                </div>
                            </div>

                            <div className="col-6">
                                <label for="to-date" className="form-label fw-bold">Valid To</label>
                                <div >
                                    <AppDatePicker
                                        selected={expiryOn}
                                        id="EndDate"
                                        placeholder="Valid To"
                                        minDate={validFrom ? validFrom : new Date()}
                                        onChange={(date) => setExpiryOn(date)}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-6">
                                <label className="form-label fw-bold">Quantity</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="number"
                                    defaultValue={quantity}
                                    placeholder="Enter Quantity"
                                    onChange={(e) => setQuantity(e?.target?.value)}
                                />
                            </div>
                        </div>

                        {isCreate &&
                            <div className="row mb-5">
                                <div className="col">
                                    <label for="product-list" className="form-label fw-bold">Select Product(s)</label>
                                    <ProductsTypeAhead
                                        productType={productType}
                                        key={productType}
                                        style={{ margin: '10px' }}
                                        placeholder="Select Products"
                                        onChange={setProducts}
                                    />
                                </div>
                            </div>}
                        {!isCreate &&
                            <div className="row mb-3">
                                <div className="col-4 fw-bold"> Product Name </div>
                                <div className="col-8 title-case"> {productName?.toLowerCase()} </div>
                            </div>}
                        <div className="row mb-4">
                            <div className="col-7 pe-0"> <button className="btn btn-success font14 w-100 p-3 "
                                id="manageClick" onClick={() => manageMultiBuy(isCreate)}>{isCreate ? 'Create' : 'Update'}</button> </div>
                            <div id="openClick" onClick={() => setOpenMultiBuyModal(false)} className="col-5 text-end">
                                <button className="btn btn-outline-danger font14 w-100 p-3">
                                    Cancel</button> </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="modal-backdrop show"></div>
    </React.Fragment >
};

export default MultiBuyModal;