import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from "react-redux";
import Actions from '../../redux/actions';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync, faSearch } from '@fortawesome/free-solid-svg-icons';
import { COLORS } from '../../utils/Constants';
import HitachiMonitoringModal from './HitachDetailsModal';
import InputField from '../../components/fields/InputField';
import RoutePath from '../../utils/routes/RoutePath';
import NourisonLoader from '../../../asset/images/loader.gif'
class HitachiMonitoring extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            activePage: 1,
            limit: 30,
            offset: 0,
            hitachiDetails: null,
            openHitachiModal: false
        }
    }

    componentDidMount() {
        const { limit, offset } = this.state
        const params = {
            limit,
            offset,
            isIntialLoading: true
        }
        this.props.getHitachiActiveList(params)
    }

    renderSessionStatus(status, active) {
        if (status === 0) {
            return <p style={{ color: 'red' }}>Unable to enter</p>
        } else if (status === 1) {
            return active ? <p style={{ color: 'blue' }}>Inside the store</p> :
                <p style={{ color: 'red' }}>Unable to enter</p>
        } else if (status === 2) {
            return <p style={{ color: 'green' }}>Exits the store</p>
        }
    }

    refreshActivePage() {
        const { limit, offset } = this.state
        const params = {
            limit,
            offset
        }
        this.props.getHitachiActiveList(params)
    }

    refreshMonitoringPage() {
        const { limit, offset } = this.state
        const params = {
            limit,
            offset
        }
        this.props.getHitachiMonitoringList(params)
    }

    loadMore(page) {
        const { limit } = this.state
        const params = {
            limit,
            offset: page,
        }
        this.props.getHitachiMonitoringList(params)
    }
    openHitachiDetails(obj) {
        this.setState({ openHitachiModal: true, hitachiDetails: obj })
    }
    closeHitachiModal() {
        this.setState({ openHitachiModal: false })
    }
    onChangeId(searchKey) {
        const { limit, offset } = this.state
        const params = {
            limit,
            offset,
            searchKey
        }
        this.props.getHitachiMonitoringList(params)
    }
    renderColorCode(status, invalidPim, invoiceId, active) {
        let activeColor = ''
        switch (status) {
            case 0:
                activeColor = '#f7caca'
                break;
            case 1:
                activeColor = active ? '#ADD8E6' : '#f7caca'
                break;
            case 2:
                activeColor = invalidPim ? '#f6f692' : invoiceId ? '#c1f6c1' : '#FFFFFF'
                break;
            default:
                activeColor = '#FFFFFF'
                break;
        }
        return activeColor
    }
    navInvoiceScreen(e, invoiceId) {
        e.stopPropagation();
        const win = window.open(RoutePath.INVOICE_DETAILS.replace(':id', invoiceId), "_blank");
        win.focus();
    }
    render() {
        const { openHitachiModal, hitachiDetails } = this.state
        const { hitachiActiveCount, hitachiMonitoringList,
            hitachiMonitoringLoading, hitachiMonitoringCount, isLoading } = this.props.getHitachiList || {}
        return (
            <div>
                {
                    hitachiActiveCount &&
                    <div className='sticky-table '>
                        <div className='dashboard-store-info-container justify-content-end'>
                            <div className="store-info-card">
                                <div className="dashboard-store-info-inner">
                                    <h5 className="p1">Active Customer: <span className="p2">{hitachiActiveCount}</span>
                                        <span id="refreshPage"
                                            className='cursor-pointer'
                                            style={{ marginLeft: '10px' }}
                                            onClick={() => this.refreshActivePage()}>
                                            <FontAwesomeIcon icon={faSync} color={COLORS.appThemeHex} />
                                        </span>
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <div style={{ textAlign: 'left', padding: '10px' }}>

                    <div className="">
                        <div className="search-box d-flex">
                            <InputField
                                id='InputField'
                                key="Search_id"
                                placeholder={'Search'}
                                // value={value}
                                onChange={(value) => this.onChangeId(value)}
                                type={"number"}
                                suffixIcon={<FontAwesomeIcon icon={faSearch} />} />
                        </div>

                        <div className="">
                            <div class="search-box">
                                <InputField
                                    id='InputField'
                                    key="Search_id"
                                    placeholder={'Search'}
                                    // value={value}
                                    onChange={(value) => this.onChangeId(value)}
                                    suffixIcon={<FontAwesomeIcon icon={faSearch} />} />
                            </div>
                            <div className="table-responsive mt-3">
                                <table className="text-center table ">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Session ID</th>
                                            <th>Receipt ID</th>
                                            <th>Order no</th>
                                            <th>Email</th>
                                            <th>Mobile</th>
                                            <th>Entry</th>
                                            <th>Exit</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            hitachiMonitoringList && hitachiMonitoringList.map((obj, i) => {
                                                return (
                                                    <tr id='openHitachiDetails'
                                                        className='cursor-pointer'
                                                        onClick={() => this.openHitachiDetails(obj)}
                                                        style={{ backgroundColor: this.renderColorCode(obj?.entry_status, obj?.skus_invalid_pim, obj?.invoice_id, obj?.active) }}>
                                                        <td className="">
                                                            {
                                                                obj?.skus_from_hitachi && <i className="fas fa-file-invoice" aria-hidden="true"></i>
                                                            }
                                                        </td>
                                                        <td className="">{obj?.session_id}</td>
                                                        <td className="">{obj?.invoice_id ? <span id='invoiceId'
                                                            onClick={(e) => this.navInvoiceScreen(e, obj?.invoice_id)}
                                                            className="text-underline">{obj?.invoice_id}</span> : ''}</td>
                                                        <td className="">{obj?.order_id}</td>
                                                        <td className="">{obj?.email}</td>
                                                        <td className="">{obj?.mobile}</td>
                                                        <td className="">{moment(obj?.entry_time).format('DD-MM-YYYY hh:mm:ss A')}</td>
                                                        <td className="">{moment(obj?.exit_time).format('DD-MM-YYYY hh:mm:ss A')}</td>
                                                        <td className="">
                                                            <i className="fa fa-info-circle" aria-hidden="true"></i>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>

                            </div>
                        </div>
                        <div class="table-responsive mt-3">
                            <table class="text-center table ">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Session ID</th>
                                        <th>Invoice ID</th>
                                        <th>Order ID</th>
                                        <th>Email</th>
                                        <th>Mobile</th>
                                        <th>Entry</th>
                                        <th>Exit</th>
                                        <th></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                        hitachiMonitoringList && hitachiMonitoringList.map((obj, i) => {
                                            return (
                                                <tr
                                                    key={i}
                                                    className='cursor-pointer'
                                                    onClick={() => this.openHitachiDetails(obj)}
                                                    style={{ backgroundColor: this.renderColorCode(obj?.entry_status, obj?.skus_invalid_pim, obj?.invoice_id, obj?.active) }}
                                                >
                                                    {
                                                        obj?.skus_from_hitachi ? <td> <span><i className="fa fa-file-invoice"></i></span> </td> :
                                                            <td></td>
                                                    }

                                                    <td>{obj?.session_id}</td>
                                                    <td>{obj?.invoice_id ? <span id='invoiceId' onClick={(e) => this.navInvoiceScreen(e, obj?.invoice_id)} className="text-underline">{obj?.invoice_id}</span> : ''}</td>
                                                    <td>{obj?.order_id}</td>
                                                    <td>{obj?.email}</td>
                                                    <td>{obj?.mobile}</td>
                                                    <td>{moment(obj?.entry_time).format('DD-MM-YYYY hh:mm:ss A')}</td>
                                                    <td>{moment(obj?.exit_time).format('DD-MM-YYYY hh:mm:ss A')}</td>
                                                    <td>
                                                        <span><i className="fa fa-info-circle" aria-hidden="true"></i></span>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            <div>
                                {
                                    (!hitachiMonitoringLoading && hitachiMonitoringList?.length === 0) && <p
                                        style={{ textAlign: 'center' }}>No sessions found</p>
                                }
                            </div>
                        </div>
                    </div>

                </div>
                {(hitachiMonitoringLoading || isLoading) && <div className='nnp-loader'>
                    <img src={NourisonLoader} className="" style={{ width: '160px' }} />
                </div>}

                {
                    (!hitachiMonitoringLoading && hitachiMonitoringList?.length !== hitachiMonitoringCount) && (hitachiMonitoringList?.length > 0) &&
                    <div className='d-flex justify-content-center' id='LoadMore'
                        onClick={() => this.loadMore(hitachiMonitoringList?.length)}>
                        <button className='btn btn-sm btn-load'>Load more ..</button>
                    </div>
                }

                {
                    openHitachiModal && <HitachiMonitoringModal
                        id='hitachiDetailsModal'
                        show={openHitachiModal}
                        onClose={() => this.closeHitachiModal()}
                        hitachiDetails={hitachiDetails}
                        renderSessionStatus={() => this.renderSessionStatus(hitachiDetails?.entry_status, hitachiDetails?.active)}
                        navInvoiceScreen={(e, invoiceId) => this.navInvoiceScreen(e, invoiceId)}
                    />
                }
            </div>
        )
    }
}

export const mapStateToProps = (state) => {
    return {
        getHitachiList: state?.HITACHI_MONITORING || {}
    };
};

export default withRouter(connect(mapStateToProps, {
    getHitachiActiveList: Actions.getHitachiActiveList,
    getHitachiMonitoringList: Actions.getHitachiMonitoringList
})(HitachiMonitoring))
