import React, { useEffect } from "react";
import AppMapView from "../../components/map/AppMapView";
import { trackDaxbotSelector } from '../../redux/reducers/GetDaxbotReducers';
import { useSelector, useDispatch } from 'react-redux';
import moment from "moment";
import Actions from '../../redux/actions';
import Close from '../../../asset/new/img/modal-close.svg'

const convertLatLngToInt = (v) => v ? parseFloat(v) : null


const MapViewModal = ({onClose, trackId }) => {
    const daxbot = useSelector(trackDaxbotSelector)
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(Actions.trackDaxbotAction(trackId));
    }, [dispatch]);

    const centerLatLng = {
        lat: convertLatLngToInt(daxbot?.lat),
        lng: convertLatLngToInt(daxbot?.lon)
    }
    const botLatLng = {
        lat: convertLatLngToInt(daxbot?.bot_status?.location?.lat),
        lng: convertLatLngToInt(daxbot?.bot_status?.location?.lon)
    }
    const renderBotStatus = () => {
        const { status } = daxbot || {}
        let orderBotStatus = ''
        if (status === 'scheduled') {
            orderBotStatus = 'Requested';
        } else if (status === 'confirmed') {
            orderBotStatus = 'Bot allocated';
        } else if (status === 'active') {
            orderBotStatus = 'On the way';
        } else if (status === 'arrived') {
            orderBotStatus = 'Arrived';
        } else if (status === 'successful') {
            orderBotStatus = 'Delivered';
        } else if (status === 'fail_delivery') {
            orderBotStatus = 'Delivery failed';
        } else if (status === 'completed') {
            orderBotStatus = 'Reached Store';
        }
        return orderBotStatus
    }
    const { bot_status } = daxbot || {}
    return (
        <div className="modal d-block" id="order-details-modal" tabindex="-1" aria-labelledby="login" aria-hidden="true">
            <div className="modal-dialog modal-lg modal-dialog-centered">
                <div className="modal-content">

                    <div className="modal-body">
                        <img src={Close} alt="X" data-bs-dismiss="modal" aria-label="Close" className="modal-close" onClick={() => onClose()} />

                        <h2 className="font20 fw-bold mb-4">Track Order #983</h2>
                        <div className="fw-bold text-center my-3 py-4">
                            {renderBotStatus()}
                        </div>
                        <div style={{ height: '400px', position: 'relative', margin: '10px', }}>
                            <AppMapView center={centerLatLng} botLatLng={botLatLng} /></div>
                        <div className="card card-body border-0 p-0 bill-details my-5 mb-lg-3 mt-lg-0">
                            {(bot_status && bot_status?.drawer_temp && bot_status?.speed) && <>                            <h3 className="font16 fw-bold mb-1">Tracking Details</h3>
                                <div className="row mb-2 text-start">
                                    <div className="col">Drawer temperature</div>
                                    <div className="col-auto text-end fw-bold">{bot_status?.drawer_temp?.toFixed(2)} C</div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col">Running speed</div>
                                    <div className="col-auto text-end fw-bold"> {(bot_status?.speed * 2.236936)?.toFixed(2)} mph</div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col">Start time</div>
                                    <div className="col-auto text-end fw-bold"> {moment(bot_status?.start_time).format('MM/DD/YYYY - hh:mm A')}</div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col">End time</div>
                                    <div className="col-auto text-end fw-bold"> {moment(bot_status?.end_time).format('MM/DD/YYYY - hh:mm A')}</div>
                                </div></>}

                        </div>


                    </div>
                </div>
            </div>
        // </div >
    )
};

export default MapViewModal;