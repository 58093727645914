import Actions from "../actions";

const initialState = {
    isLoading: false,
    orderDetailsLoading: false,
    orderDetailsObject: {},
    orderDetailsUpdateLoading: false,
    orderList: [],
    error: false,
    orderDetailError: false,
    totalCount: undefined,
    promotionCombineArray: [],
    comboOfferArray: [],
    freeGiveArray: [],
    partialOrderUpdateLoading: false,
    multibuyArray: [],
    currentLocation: null
}
const OrdersReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.ORDERS: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.ORDERS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                orderList: action.data?.response || [],
                totalCount: action.data?.count,
                error: false
            };
        }
        case Actions.ORDER_FAILURE: {
            return {
                ...state,
                orderList: [],
                isLoading: false
            };
        }

        case Actions.ORDER_DETAILS: {
            return {
                ...state,
                orderDetailsLoading: true,
                orderDetailsObject: {}
            }
        }

        case Actions.ORDER_DETAILS_SUCCESS: {
            return {
                ...state,
                orderDetailsLoading: false,
                orderDetailsObject: action.data?.offersArray || [],
                promotionCombineArray: action.data?.finalList || [],
                comboOfferArray: action.data?.comboOfferArrayList || [],
                freeGiveArray: action.data?.freeGiveArrayList || [],
                multibuyArray: action.data?.multiBuyArrayList || []
            }
        }

        case Actions.ORDER_DETAILS_FAILURE: {
            return {
                ...state,
                orderDetailsLoading: false,
            }
        }

        case Actions.ORDER_DETAILS_UPDATE: {
            return {
                ...state,
                orderDetailsUpdateLoading: true,
            }
        }
        case Actions.ORDER_DETAILS_UPDATE_SUCCESS: {
            return {
                ...state,
                orderDetailsObject: action.data,
                orderDetailsUpdateLoading: false,
            }
        }
        case Actions.ORDER_DETAILS_UPDATE_FAILURE: {
            return {
                ...state,
                orderDetailsUpdateLoading: false,
                orderDetailError: action.data
            }
        }
        case Actions.PARTIAL_ORDER_ACCEPT: {
            return {
                ...state,
                partialOrderUpdateLoading: true,
            }
        }
        case Actions.PARTIAL_ORDER_ACCEPT_SUCCESS: {
            return {
                ...state,
                error:false,
                partialOrderUpdateLoading: false,
            }
        }
        case Actions.PARTIAL_ORDER_ACCEPT_FAILURE: {
            return {
                ...state,
                partialOrderUpdateLoading: false,
            }
        }
        case Actions.ORDER_PROMOTION_COMBINE_ARRAY: {
            return {
                ...state,
                promotionCombineArray: action.data
            }
        }
        case Actions.COMBO_OFFER_PRODUCT_LIST_ARRAY: {
            return {
                ...state,
                comboOfferArray: action.data
            }
        }
        case Actions.FREE_GIVE_PRODUCT_LIST_ARRAY: {
            return {
                ...state,
                freeGiveArray: action.data
            }
        }
        case Actions.UPDATE_MULTI_BUY_ARRAY_LIST: {
            return {
                ...state,
                multibuyArray: action.data
            }
        }
        case Actions.UPDATE_CURRENT_ORDERS_LOCATION: {
            return {
                ...state,
                currentLocation: action.data
            }
        }
        case Actions.LOGOUT: {
            return {
                ...initialState
            };
        }
        default:
            return state;
    }
};

export default OrdersReducer;

export const getOrderListSelector = state => state?.ORDERS?.orderList;
export const getOrderListLoading = state => state?.ORDERS?.isLoading;
export const getOrderListCountSelector = state => state?.ORDERS?.totalCount
export const getPromotionCombineArraySelector = state => state?.ORDERS?.promotionCombineArray
export const getComboOfferArraySelector = state => state?.ORDERS?.comboOfferArray
export const getFreeGiveArraySelector = state => state?.ORDERS?.freeGiveArray
export const getCurrentOrderObjectSelector = state => state?.ORDERS?.orderDetailsObject
export const getMultibuyArraySelector = state => state?.ORDERS?.multibuyArray
export const currentOrderLocation = state => state?.ORDERS?.currentLocation
