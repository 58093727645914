import { all, put, takeLatest } from "redux-saga/effects";
import { doPromotionGet, doPromotionPost, } from "../../utils/service";
import END_POINTS from "../../utils/service/Endpoints";
import Actions from "../actions";

export function* getComboOfferListSaga({ params, callback }) {
    try {
        let response = yield doPromotionGet(END_POINTS.COMBO_OFFERS_LIST, params)
        if (response) {
            yield put(Actions.getComboOfferListSuccess(response));
        }
        if (callback) {
            callback(response);
        }
    } catch (error) {
        callback && callback(error);
        yield put(Actions.getComboOfferListFailure({ error }));
    }
}
export function* createComboOfferListSaga({ params, callback }) {
    try {
        let response = yield doPromotionPost(END_POINTS.CREATE_COMBO_OFFERS_LIST, params)
        if (response) {
            yield put(Actions.createComboOfferListSuccess(response))
        }
        if (callback) {
            callback(response);
        }
    } catch (error) {
        callback && callback(error);
        yield put(Actions.createComboOfferListFailure({ error }));
    }
}
export function* updateComboOfferListSaga({ params, callback }) {
    try {
        let response = yield doPromotionPost(END_POINTS.UPDATE_COMBO_OFFERS_LIST, params)
        if (response) {
            yield put(Actions.updateComboOfferListSuccess(response))
        }
        callback && callback(response);
    } catch (error) {
        callback && callback(error);
        yield put(Actions.updateComboOfferListFailure({ error }))
    }
}

export function* deleteComboOfferSaga({ params, callback }) {
    try {
        let response = yield doPromotionPost(params);
        if (response) {
            yield put(Actions.deleteComboOfferListSuccess(response));
        }
        callback && callback(response);
    } catch (error) {
        yield put(Actions.deleteComboOfferListFailure({ error }));
        callback && callback(error);
    }
}

export default function* comboOfferListener() {
    yield all([
        takeLatest(Actions.COMBO_OFFER, getComboOfferListSaga),
        takeLatest(Actions.COMBO_OFFER_UPDATE, updateComboOfferListSaga),
        takeLatest(Actions.COMBO_OFFER_CREATE, createComboOfferListSaga),
        takeLatest(Actions.DELETE_COMBO_OFFER, deleteComboOfferSaga),
    ]);
}

