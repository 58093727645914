import React from 'react';
import moment from 'moment'
import Constants from '../../utils/Constants';

export const getDateTime = (createdDate) => {
    let dateTime = '';
    if (
        moment(moment().format('YYYY-MM-DD')).isSame(
            moment(createdDate).format('YYYY-MM-DD'),
        )
    ) {
        dateTime = moment(createdDate).format('hh:mm a');
    } else if (
        moment(moment().subtract(1, 'day').format('YYYY-MM-DD')).isSame(
            moment(createdDate).format('YYYY-MM-DD'),
        )
    ) {
        dateTime = Constants.CHAT.STRING_YESTERDAY;
    } else {

        dateTime = moment(createdDate).format('MM/DD/YYYY');
    }
    return dateTime;
};
const ChatMessagesItem = ({ message }) => {
    return <ul className="clearfix">
        <li className={`${message.isCustomer === 2 ? 'sender' : 'receiver'}`}>
            <p>{message.message}</p>
            <span className="time">
                {/* {(moment(moment().format('YYYY-MM-DD')).isSame(
                    moment(message?.lastModified).format('YYYY-MM-DD'),
                ) } */}
                {getDateTime(message?.lastModified)}
            </span>
        </li>
    </ul>
}

export default ChatMessagesItem