import { all, put, takeLatest } from "redux-saga/effects";
import { doCvPost } from "../../../utils/service";
import END_POINTS from "../../../utils/service/Endpoints";
import Actions from "../../actions";

export function* updatePlanogramProductSaga({ callback, params }) {
    try {
        const response = yield doCvPost(END_POINTS.UPDATE_PLANOGRAM, params)
        if (response) {
            callback && callback(response)
            yield put(Actions.updataPlanogramProduct_Success(response));
        }
    } catch (error) {
        if (callback) {
            callback({ error })
            yield put(Actions.updataPlanogramProduct_Failure({ error }));
        }
    }
}

export default function* updatePlanogramProductListner() {
    yield all([
        takeLatest(Actions.UPDATE_PLANOGRAM_PRODUCT, updatePlanogramProductSaga),
    ]);
}