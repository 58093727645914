import { doAction, doResponseAction } from ".";

const CUSTOMER_COUPON = 'CUSTOMER_COUPON';
const CUSTOMER_COUPON_SUCCESS = 'CUSTOMER_COUPON_SUCCESS';
const CUSTOMER_COUPON_FAILURE = 'CUSTOMER_COUPON_FAILURE'

const CREATE_CUSTOMER_COUPON = 'CREATE_CUSTOMER_COUPON';
const CREATE_CUSTOMER_COUPON_SUCCESS = 'CREATE_CUSTOMER_COUPON_SUCCESS';
const CREATE_CUSTOMER_COUPON_FAILURE = 'CREATE_CUSTOMER_COUPON_FAILURE'

const UPDATE_CUSTOMER_COUPON = 'UPDATE_CUSTOMER_COUPON';
const UPDATE_CUSTOMER_COUPON_SUCCESS = 'UPDATE_CUSTOMER_COUPON_SUCCESS';
const UPDATE_CUSTOMER_COUPON_FAILURE = 'UPDATE_CUSTOMER_COUPON_FAILURE';

const CUSTOMERS_LIST = 'CUSTOMERS_LIST';
const CUSTOMERS_LIST_SUCCESS = 'CUSTOMERS_LIST_SUCCESS';
const CUSTOMERS_LIST_FAILURE = 'CUSTOMERS_LIST_FAILURE';

const DELETE_CUSTOMERS_COUPON_LIST = 'DELETE_CUSTOMERS_COUPON_LIST';
const DELETE_CUSTOMERS_LIST_SUCCESS = 'DELETE_CUSTOMERS_LIST_SUCCESS';
const DELETE_CUSTOMERS_LIST_FAILURE= 'DELETE_CUSTOMERS_LIST_FAILURE';



export {
    CUSTOMER_COUPON,
    CUSTOMER_COUPON_SUCCESS,
    CUSTOMER_COUPON_FAILURE,

    CREATE_CUSTOMER_COUPON,
    CREATE_CUSTOMER_COUPON_SUCCESS,
    CREATE_CUSTOMER_COUPON_FAILURE,

    UPDATE_CUSTOMER_COUPON,
    UPDATE_CUSTOMER_COUPON_SUCCESS,
    UPDATE_CUSTOMER_COUPON_FAILURE,

    CUSTOMERS_LIST,
    CUSTOMERS_LIST_SUCCESS,
    CUSTOMERS_LIST_FAILURE,

    DELETE_CUSTOMERS_COUPON_LIST,
    DELETE_CUSTOMERS_LIST_SUCCESS,
    DELETE_CUSTOMERS_LIST_FAILURE,
    
}

export const getCustomerCoupon = (params, callback) => doAction(CUSTOMER_COUPON, params, callback);
export const getCustomerCouponSuccess = (data) => doResponseAction(CUSTOMER_COUPON_SUCCESS, data);
export const getCustomerCouponFailure = (data) => doResponseAction(CUSTOMER_COUPON_FAILURE, data);

export const createCustomerCoupon = (params, callback) => doAction(CREATE_CUSTOMER_COUPON, params, callback);
export const createCustomerCouponSuccess = data => doResponseAction(CREATE_CUSTOMER_COUPON_SUCCESS, data);
export const createCustomerCouponFailure = data => doResponseAction(CREATE_CUSTOMER_COUPON_FAILURE, data);

export const updateCustomerCoupon = (params, callback) => doAction(UPDATE_CUSTOMER_COUPON, params, callback);
export const updateCustomerCouponSuccess = data => doResponseAction(UPDATE_CUSTOMER_COUPON_SUCCESS, data);
export const updateCustomerCouponFailure = data => doResponseAction(UPDATE_CUSTOMER_COUPON_FAILURE, data);


export const getAllCustomerListAction = (params, callback) => doAction(CUSTOMERS_LIST, params, callback);
export const getAllCustomerListSuccessAction = data => doResponseAction(CUSTOMERS_LIST_SUCCESS, data);
export const getAllCustomerListFailureAction = data => doResponseAction(CUSTOMERS_LIST_FAILURE, data);

export const deleteCustomersCouponAction  = (params, callback) => doAction(DELETE_CUSTOMERS_COUPON_LIST, params, callback);
export const deleteCustomerCouponSuccess = data => doResponseAction(DELETE_CUSTOMERS_LIST_SUCCESS, data);
export const deleteCustomerCouponFailure = data => doResponseAction(DELETE_CUSTOMERS_LIST_FAILURE, data);