import { doAction, doResponseAction } from "..";

const AUTOFILL = 'AUTOFILL';
const AUTOFILL_SUCCESS = 'AUTOFILL_SUCCESS';
const AUTOFILL_FAILURE = 'AUTOFILL_FAILURE';

export {
    AUTOFILL,
    AUTOFILL_SUCCESS,
    AUTOFILL_FAILURE,
}

export const autoFill = (params, callback) => doAction(AUTOFILL, params, callback);
export const autoFill_Success = data => doResponseAction(AUTOFILL_SUCCESS, data);
export const autoFill_Failure = data => doResponseAction(AUTOFILL_FAILURE, data);