import React, { useState } from 'react';
import AppModal from '../modal/AppModal';
import AppButton from '../fields/AppButton';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedStoreSelector } from '../../redux/reducers/StoreReducer';
import Actions from '../../redux/actions';


const StoreCapacityModal = ({
    onClose = () => null,
    title,
    show,
}) => {
    const dispatch = useDispatch();

    const selectedStore = useSelector(getSelectedStoreSelector);
    const { id: storeId, customerLimit } = selectedStore || {};

    const [visitorCount, setVisitorCount] = useState(customerLimit || 0);


    const onVisitor = (value) => {
        if (value === 'plus') {
            setVisitorCount((v) => v + 1);
        } else if (value === 'minus') {
            setVisitorCount((v) => v - 1);
        }
    }

    const onUpdateVisitorCount = () => {
        let params = {
            id: storeId,
            customerLimit: visitorCount
        }
        dispatch(Actions.updateCustomerCount(params));
        onClose();
    }


    return <AppModal show={show}
        onClose={onClose}
        title={title}
        renderFooter={() => {
            return <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <AppButton color="gray" title="Close" onClick={() => onClose()} />
                <AppButton title="Update" onClick={() => onUpdateVisitorCount()} />
            </div>
        }}
    >
        <h4>Set Max Store Capacity</h4>
        <div className="number">
            <button className="minus" id="minus" disabled={visitorCount === 0 ? true : false} onClick={() => onVisitor('minus')}>-</button>
            <input type="text" value={visitorCount} />
            <button className="plus" id="plus" onClick={() => onVisitor('plus')}>+</button>
        </div>
    </AppModal>
}

export default StoreCapacityModal;