import React from 'react';
import BoldError from '../fields/BoldError';
const Modal = require("react-bootstrap-modal");

const AppModal = ({ show, onClose = () => null, errorMessage = null, children, title = '', renderFooter }) => {
    return <div>
        <Modal
            attentionClass=""
            onHide={onClose}
            show={show} aria-labelledby="ModalHeader">
            <Modal.Header>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginLeft: '10px', marginRight: '10px' }}>
                    <h4>{title}</h4>
                    <button style={{ marginBottom: '5px' }} className="close" aria-label="Close Modal">
                        <span aria-hidden="true" id="onClose" onClick={() => onClose()}>×</span>
                    </button>
                </div>
            </Modal.Header>
            <Modal.Body className="login text-center">

                <BoldError message={errorMessage} />
                {children}
            </Modal.Body>
            {renderFooter && renderFooter() && <Modal.Footer>
                {renderFooter()}
            </Modal.Footer>}
        </Modal>
    </div>
}

export default AppModal;