import React from 'react'
import { withRouter } from 'react-router-dom'
import Close from '../../asset/new/img/modal-close.svg'
import AppDatePicker from './fields/AppDatePicker'
import BoldError from './fields/BoldError'
class DatePicker extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    render() {
        const { title, proceed, cancel, onSuccess, errorMessage,
            onFailure, startDate, endDate, onDateChange, clearFilter, renderStatus } = this.props
        return (
            <React.Fragment>
                <div className="modal d-block"
                    id="unblock-modal"
                    tabIndex="-1"
                    aria-labelledby="unblock"
                    aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-body p-50">
                                <img
                                    id='close'
                                    src={Close}
                                    alt="X"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    className="modal-close"
                                    onClick={() => onFailure()} />
                                <div
                                    className="row mb-4">
                                    {errorMessage && <BoldError message={errorMessage} />}
                                    <div
                                        className="col">
                                        {
                                            title && <h3
                                                className="font18 fw-bold mb-3">{title}</h3>
                                        }

                                    </div>
                                    <div
                                        className="col-auto text-end cursor-pointer">
                                        <h5 id='clearFilter' className="font18 fw-bold mb-3 text-primary"
                                            onClick={() => clearFilter()}>Clear</h5>

                                    </div>
                                </div>
                                <div className="row mb-4">
                                    {renderStatus()}
                                </div>
                                <div className="row mb-4">
                                    <div
                                        className="col-sm-6">
                                        <AppDatePicker
                                            id='startDate'
                                            selected={startDate}
                                            onChange={date => onDateChange(date, 1)}
                                            maxDate={new Date()}
                                            notValidTime={true}
                                            placeholder={'From'}
                                        />
                                    </div>
                                    <div className='col-sm-6'>
                                        <AppDatePicker
                                            id='endDate'
                                            selected={endDate}
                                            onChange={date => onDateChange(date, 2)}
                                            minDate={startDate}
                                            maxDate={new Date()}
                                            notValidTime={true}
                                            placeholder={'To'}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-5">
                                    <div id='onClickSuccess' className="col pe-0" onClick={() => onSuccess()}>
                                        <span className="btn btn-success font14 w-100 p-3">
                                            {proceed}</span> </div>
                                    <div className="col text-end" id="onClickFailure" onClick={() => onFailure()}>
                                        <span className="btn btn-outline-danger 
                                        font14 w-100 p-3">{cancel}</span> </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="modal-backdrop show"></div>
            </React.Fragment >
        )
    }
}

export default withRouter(DatePicker)