import React from 'react';
import { faComment, faHandHoldingUsd, faInfoCircle, faStore, faDumbbell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import { getAllChatMessagesListSuccess, getChatMessagesListSuccess, selectedChatAction } from "../../../redux/actions/ChatsAction";
import { getAllChatsListSelector, getSelectedChatSelector, updateChatObjectSelector } from "../../../redux/reducers/ChatsReducer";
import { getAccessTokenSelector, getTokenSelector } from "../../../redux/reducers/LoginReducer";
import { history, store } from "../../../redux/store";
import Constants, { COLORS } from "../../Constants";
import RoutePath from "../../routes/RoutePath";
import { StartSocketConnect, stopSocketConnect } from './SocketConnection';
import Actions from '../../../redux/actions';
import cloneDeep from 'lodash.clonedeep';
import { currentOrderLocation } from "../../../redux/reducers/OrderReducer";
import { getRefundsListSelector, getRefundsCountSelector } from "../../../redux/reducers/RefundReducer";
import { getQueryParams } from '../../commonFunctions/EncodeDecodeBase64';
import attentionbell from "../../../../asset/new/sound/attentionbell.wav"
import { getSelectedStoreData, getStoreListSelector } from "../../../redux/reducers/StoreReducer";
import { setStoreInformation } from "../../../redux/actions/StoreAction";
import { getRoleIdFromCookie } from '../../commonFunctions/cookie';
import { getNewNotificationsDetailsAction } from '../../../redux/actions/NotificationsAction';

const storeSelector = (fn) => { return fn(store.getState()); };
const dispatchToStore = (fn) => store.dispatch(fn)

export const handleToastMessage = (socketMessageObj, toastId) => {
    const selectedStoreId = storeSelector(getSelectedStoreData);
    const selectedStore = storeSelector(getStoreListSelector);
    if (selectedStoreId?.value !== socketMessageObj?.storeId) {  //multiStore
        if (history?.location?.pathname === RoutePath.CHATS_SCREEN) {
            const result = selectedStore?.find(obj => parseInt(obj?.store?.branchId) === parseInt(socketMessageObj?.storeId));
            dispatchToStore(setStoreInformation(result?.store));
            window.location.reload();
        }
        const result = selectedStore?.find(obj => parseInt(obj?.store?.branchId) === parseInt(socketMessageObj?.storeId));
        dispatchToStore(setStoreInformation(result?.store));
    }
    toast.dismiss(toastId);
    if (socketMessageObj?.type === 'newChat') {
        const { chatContent } = socketMessageObj || {};
        dispatchToStore(selectedChatAction(chatContent));
        const getAllChatList = storeSelector(getAllChatsListSelector);
        const objToSelect = getAllChatList?.find(chats => chats.id === chatContent?.id);
        if (!objToSelect) {
            const tempArray = getAllChatList ? getAllChatList : [];
            const chatsArray = [chatContent, ...tempArray];
            dispatchToStore(selectedChatAction(chatContent));
            dispatchToStore(getAllChatMessagesListSuccess(chatsArray));
        } else {
            dispatchToStore(selectedChatAction(chatContent));
        }
        history.push(RoutePath.CHATS_SCREEN);
    }
    if (socketMessageObj?.type === 'newChatMessage') {
        const selectedChat = storeSelector(getSelectedChatSelector);
        const getAllChatList = storeSelector(getAllChatsListSelector);
        if (history?.location?.pathname === RoutePath.CHATS_SCREEN) {
            if (selectedChat?.id !== socketMessageObj?.chatId) {
                const objToSelect = getAllChatList?.find(chats => chats.id === socketMessageObj?.chatId)
                if (objToSelect) {
                    dispatchToStore(selectedChatAction(objToSelect));
                }
            }
        } else {
            const objToSelect = getAllChatList?.find(chats => chats.id === socketMessageObj?.chatId)
            if (objToSelect)
                dispatchToStore(selectedChatAction(objToSelect));
            history.push(RoutePath.CHATS_SCREEN);
        }
    }

    if (socketMessageObj?.type === 'storeManagerNewOrder') {
        const userRoleId = getRoleIdFromCookie();
        // const audio = new Audio('https://drive.google.com/uc?export=download&id=1M95VOpto1cQ4FQHzNBaLf0WFQglrtWi7');
        // audio.play();
        // if 2 dont go to grocery page
        if (userRoleId === 2 && socketMessageObj?.orderType === 2) {
            return;
        }
        const queryParams = `?searchId=${socketMessageObj?.invoiceOrderDetailsId}&type=${socketMessageObj?.orderType}`;
        history.push(`${RoutePath.ORDERS_PATH}${queryParams}`);
        // force reload
        window.location.reload();

    }

    if (socketMessageObj?.type === 'storeManagerRefundNewOrder') {
        const userRoleId = getRoleIdFromCookie();
        if (userRoleId !== 2) {
            const queryParams = `?searchId=${socketMessageObj?.refundDetailsId}`;
            history.push(`${RoutePath.REFUND_SCREEN_PATH}${queryParams}`);
        }
    }

    if (socketMessageObj?.type === 'cvPlanogram') {
        history.push(RoutePath.NOTIFICATION);
    }

    if (socketMessageObj?.type === 'foodPrepared') {
        const id = socketMessageObj?.orderDetails?.invoiceOrderDetailsId;
        if (id) {
            const queryParams = `?searchId=${id}&type=${socketMessageObj?.orderType}`;
            history.push(`${RoutePath.ORDERS_PATH}${queryParams}`);
            window.location.reload();
        }
    }

};

export const updateScreenOnTheFly = (socketMessageObj) => {
    const { location } = history;

    if (socketMessageObj?.type === 'newChat') {
        const getAllChatList = storeSelector(getAllChatsListSelector);
        const currentObjectIndex = getAllChatList?.findIndex(s => s.id === socketMessageObj?.chatContent?.id)
        if (currentObjectIndex === -1) {
            dispatchToStore(Actions.getAllChatMessagesListSuccess([socketMessageObj.chatContent, ...getAllChatList]))
        }
    }
    // update the value if the user chat and the incoming chatid are same
    const selectedChat = storeSelector(getSelectedChatSelector);
    if (socketMessageObj?.type === 'newChatMessage' &&
        location.pathname === RoutePath.CHATS_SCREEN
        && selectedChat?.id === socketMessageObj?.chatId) {
        const getAllChatList = storeSelector(getAllChatsListSelector);
        const chatObject = storeSelector(updateChatObjectSelector);
        const { messageContent } = socketMessageObj || {}
        const tempChatObj = cloneDeep(chatObject);
        const { chatMessages } = tempChatObj || {}
        chatMessages.reverse();
        chatMessages.push(messageContent);
        chatMessages.reverse();
        tempChatObj.chatMessages = chatMessages;
        const currentObjectIndex = getAllChatList?.findIndex(s => s.id === socketMessageObj?.chatId)
        if (currentObjectIndex !== -1) {
            let chatList = cloneDeep(getAllChatList);
            chatList[currentObjectIndex].lastModified = socketMessageObj?.messageContent?.lastModified;
            chatList[currentObjectIndex].chatMessage.message = socketMessageObj?.message;
            if (currentObjectIndex !== 0) {
                const deletedObject = chatList[currentObjectIndex];
                chatList.splice(currentObjectIndex, 1);
                chatList = [deletedObject, ...chatList];
            }
            dispatchToStore(getAllChatMessagesListSuccess(chatList));
        }
        dispatchToStore(getChatMessagesListSuccess(tempChatObj))
    } else {
        const getAllChatList = storeSelector(getAllChatsListSelector);
        const currentObjectIndex = getAllChatList?.findIndex(s => s.id === socketMessageObj?.chatId)
        if (currentObjectIndex !== -1) {
            let chatList = cloneDeep(getAllChatList);
            chatList[currentObjectIndex].lastModified = socketMessageObj?.messageContent?.lastModified;
            if (chatList[currentObjectIndex].chatMessage) {
                chatList[currentObjectIndex].chatMessage.message = socketMessageObj?.message;
            }
            if (currentObjectIndex !== 0) {
                const deletedObject = chatList[currentObjectIndex];
                chatList.splice(currentObjectIndex, 1);
                chatList = [deletedObject, ...chatList];
            }
            dispatchToStore(getAllChatMessagesListSuccess(chatList));
        }
    }


    if (socketMessageObj?.type === 'cvPlanogram' && location.pathname === RoutePath.NOTIFICATION) {
        const notificationParams = {
            limit: 5,
            offset: 0,
            isResolved: 0
        }
        dispatchToStore(getNewNotificationsDetailsAction(notificationParams));

        // window.location.reload()
        // dispatchToStore(setNewNotificationData(socketMessageObj?.notifications));
    }


    // store manager new order
    // check on same page
    if (socketMessageObj?.type === 'storeManagerNewOrder' && location.pathname === RoutePath.ORDERS_PATH) {
        // make a copy
        const queryParams = getQueryParams(location.search);
        const currentLocation = storeSelector(currentOrderLocation);
        const customer = storeSelector(getTokenSelector)?.customer;
        const { roleId } = customer || {};
        if (!queryParams.get('searchId')) {
            let defaultOrderFilters = {
                status: 7,
                type: socketMessageObj?.orderType,
                startDate: '',
                endDate: '',
                invoiceOrderDetailsId: ''
            }
            if (roleId === Constants.ROLES.ADMIN || roleId === Constants.ROLES.STORE_MANAGER
                || roleId === Constants.ROLES.STORE_ASSOCIATE) {
                dispatchToStore(Actions.getOrderList(defaultOrderFilters))
            } else {
                defaultOrderFilters['type'] = 1
                dispatchToStore(Actions.getOrderList(defaultOrderFilters))
            }
        }
    }

    if (socketMessageObj?.type === 'storeManagerRefundNewOrder' && location.pathname === RoutePath.REFUND_SCREEN_PATH) {
        const queryParams = getQueryParams(location.search);
        if (!queryParams.get('refundId')) {
            dispatchToStore(Actions.getSocketRefundList({ id: socketMessageObj?.refundDetailsId }, (resp) => {
                const data = resp.response;
                const refundDetailsList = storeSelector(getRefundsListSelector);
                const count = storeSelector(getRefundsCountSelector)
                let tempOrderList = cloneDeep(refundDetailsList);
                if (data.refundDetailsId === socketMessageObj?.refundDetailsId) {
                    tempOrderList = [data, ...tempOrderList];
                    const datas = {
                        count: count,
                        response: tempOrderList
                    }
                    // update screen
                    dispatchToStore(Actions.getAllRefundsListsSuccessAction(datas))
                }
            }));
        }
    }

    if (socketMessageObj?.type === 'newAlertNotification') {
        dispatchToStore(Actions.updateNewNotificationIDList({ id: socketMessageObj.notificationId }));
    }

    const customer = storeSelector(getTokenSelector)?.customer;
    const { roleId } = customer || {};
    if (socketMessageObj?.type === 'newOrder' && roleId === Constants.ROLES.BISTRO_CHEF) {
        dispatchToStore(Actions.bistroDisplayOrderListAction());
    } else if (socketMessageObj?.type === 'newOrder' && roleId === Constants.ROLES.BISTRO_MANAGER && location.pathname === RoutePath.ORDERS_PATH) {
        window.location.reload();
    }

    if (socketMessageObj?.type === 'foodPrepared' && location.pathname === RoutePath.ORDERS_PATH) {
        // make a copy
        const queryParams = getQueryParams(location.search);
        const currentLocation = storeSelector(currentOrderLocation);
        if (!queryParams.get('searchId')) {
            let defaultOrderFilters = {
                status: 7,
                type: socketMessageObj?.orderType,
                startDate: '',
                endDate: '',
                invoiceOrderDetailsId: ''
            }
            if (roleId === Constants.ROLES.BISTRO_MANAGER) {
                if (currentLocation === socketMessageObj?.orderType) {
                    dispatchToStore(Actions.getOrderList(defaultOrderFilters))
                }
            } else {
                defaultOrderFilters['type'] = 2
                dispatchToStore(Actions.getOrderList(defaultOrderFilters))
            }
        }
    }

};


export const showToast = (socketMessageObj) => {

    const { location } = history;
    const selectedChat = storeSelector(getSelectedChatSelector);
    const isInSameChat = (socketMessageObj?.type === 'newChatMessage' &&
        location.pathname === RoutePath.CHATS_SCREEN
        && selectedChat?.id === socketMessageObj?.chatId);
    if (socketMessageObj?.type === 'newChatMessage') {
        if (!isInSameChat) showToastMessage(socketMessageObj);
    } else if (socketMessageObj?.type !== 'analyticsUpdate') {
        if (socketMessageObj?.message) {
            showToastMessage(socketMessageObj);
        }
    }

    updateScreenOnTheFly(socketMessageObj);
};

export const RenderIcons = ({ socketMessageObj }) => {
    switch (socketMessageObj?.type) {
        case 'newChat':
            return <FontAwesomeIcon icon={faComment} size="lg" color={COLORS.appThemeHex} />;
        case 'newChatMessage':
            return <FontAwesomeIcon icon={faComment} size="lg" color={COLORS.appThemeHex} />;
        case 'storeManagerNewOrder':
            return <FontAwesomeIcon icon={faStore} size="lg" color={COLORS.appThemeHex} />;
        case 'storeManagerRefundNewOrder':
            return <FontAwesomeIcon icon={faHandHoldingUsd} size="lg" color={COLORS.appThemeHex} />;
        case 'newAlertNotification':
            return <FontAwesomeIcon icon={faDumbbell} size="lg" color={COLORS.appThemeHex} />;
        default:
            return <FontAwesomeIcon icon={faInfoCircle} size="lg" color={COLORS.appThemeHex} />;
    }
}


export const showToastMessage = socketMessageObj => {
    const toastId = Date.now();
    const audio = new Audio(attentionbell);
    if (socketMessageObj?.type === 'storeManagerNewOrder') {
        audio.play();
    }
    return toast(<><span style={{ marginRight: '10px' }}><RenderIcons socketMessageObj={socketMessageObj} /></span>{socketMessageObj.message}</>, {
        position: toast.POSITION.TOP_RIGHT,
        closeOnClick: () => null,
        onClick: () => handleToastMessage(socketMessageObj, toastId),
        toastId,
        progressStyle: {
            background: COLORS.appThemeHover,
        }
    });
}

export const resetAllNotificationCounter = () => {
    dispatchToStore(Actions.resetNewNotificationIDList());
};


export default function inititlizeSocketConnection() {
    const accessToken = storeSelector(getAccessTokenSelector);
    if (accessToken) {
        stopSocketConnect();
        resetAllNotificationCounter();
        StartSocketConnect(showToast)
    } else {
        stopSocketConnect();
    }
}