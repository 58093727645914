import { doAction, doResponseAction } from ".";

const BISTRO_DISPLAY_ORDER_LIST = 'BISTRO_DISPLAY_ORDER_LIST';
const BISTRO_DISPLAY_ORDER_LIST_SUCCESS = 'BISTRO_DISPLAY_ORDER_LIST_SUCCESS';
const BISTRO_DISPLAY_ORDER_LIST_FAILURE = 'BISTRO_DISPLAY_ORDER_LIST_FAILURE';


const BISTRO_DISPLAY_CUSTOMER_ORDERS = 'BISTRO_DISPLAY_CUSTOMER_ORDERS';
const BISTRO_DISPLAY_CUSTOMER_ORDERS_SUCCESS = 'BISTRO_DISPLAY_CUSTOMER_ORDERS_SUCCESS';
const BISTRO_DISPLAY_CUSTOMER_ORDERS_FAILURE = 'BISTRO_DISPLAY_CUSTOMER_ORDERS_FAILURE';

const BISTRO_DISPLAY_SOCKET_SUCCESS = 'BISTRO_DISPLAY_SOCKET_SUCCESS';

export {
    BISTRO_DISPLAY_ORDER_LIST,
    BISTRO_DISPLAY_ORDER_LIST_SUCCESS,
    BISTRO_DISPLAY_ORDER_LIST_FAILURE,

    BISTRO_DISPLAY_CUSTOMER_ORDERS,
    BISTRO_DISPLAY_CUSTOMER_ORDERS_SUCCESS,
    BISTRO_DISPLAY_CUSTOMER_ORDERS_FAILURE,
    BISTRO_DISPLAY_SOCKET_SUCCESS
}

export const bistroDisplayOrderListAction = (params, callback) => doAction(BISTRO_DISPLAY_ORDER_LIST, params, callback);
export const bistroDisplayOrderListSuccessAction = data => doResponseAction(BISTRO_DISPLAY_ORDER_LIST_SUCCESS, data);
export const bistroDisplayOrderListFailureAction = data => doResponseAction(BISTRO_DISPLAY_ORDER_LIST_FAILURE, data);

export const bistroDisplayCustomerOrdersAction = (params, callback) => doAction(BISTRO_DISPLAY_CUSTOMER_ORDERS, params, callback);
export const bistroDisplayCustomerOrdersSuccessAction = data => doResponseAction(BISTRO_DISPLAY_CUSTOMER_ORDERS_SUCCESS, data);
export const bistroDisplayCustomerOrdersFailureAction = data => doResponseAction(BISTRO_DISPLAY_CUSTOMER_ORDERS_FAILURE, data);

export const bistroDisplaySocketOrder = data => doResponseAction(BISTRO_DISPLAY_SOCKET_SUCCESS, data);