import { doAction, doResponseAction } from "..";

const GET_CV_PLANOGRAM_DETAILS = 'GET_CV_PLANOGRAM_DETAILS';
const GET_CV_PLANOGRAM_DETAILS_SUCCESS = 'GET_CV_PLANOGRAM_DETAILS_SUCCESS';
const GET_CV_PLANOGRAM_DETAILS_FAILURE = 'GET_CV_PLANOGRAM_DETAILS_FAILURE';
const SET_ANNOTATION_PLANOGRAM = 'SET_ANNOTATION_PLANOGRAM'

const GET_PLANOGRAM_ANALYTICS = 'GET_PLANOGRAM_ANALYTICS';
const GET_PLANOGRAM_ANALYTICS_SUCCESS = 'GET_PLANOGRAM_ANALYTICS_SUCCESS';
const GET_PLANOGRAM_ANALYTICS_FAILURE = 'GET_PLANOGRAM_ANALYTICS_FAILURE';
export {
    GET_CV_PLANOGRAM_DETAILS,
    GET_CV_PLANOGRAM_DETAILS_SUCCESS,
    GET_CV_PLANOGRAM_DETAILS_FAILURE,
    SET_ANNOTATION_PLANOGRAM,

    GET_PLANOGRAM_ANALYTICS,
    GET_PLANOGRAM_ANALYTICS_SUCCESS,
    GET_PLANOGRAM_ANALYTICS_FAILURE
}

export const getCvPlanogramDetails = (params, callback) => doAction(GET_CV_PLANOGRAM_DETAILS, params, callback);
export const getCvPlanogramDetailsSuccess = data => doResponseAction(GET_CV_PLANOGRAM_DETAILS_SUCCESS, data);
export const getCvPlanogramDetailsFailure = data => doResponseAction(GET_CV_PLANOGRAM_DETAILS_FAILURE, data);
export const setAnnotaionPlanogram = (data) => doResponseAction(SET_ANNOTATION_PLANOGRAM, data);

export const getPlanogramAnalytics = (params, callback) => doAction(GET_PLANOGRAM_ANALYTICS, params, callback);
export const getPlanogramAnalyticsSuccess = data => doResponseAction(GET_PLANOGRAM_ANALYTICS_SUCCESS, data);
export const getPlanogramAnalyticsFailure = data => doResponseAction(GET_PLANOGRAM_ANALYTICS_FAILURE, data);