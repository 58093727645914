import { doAction, doResponseAction } from ".";

const STORE_AVAILABILITY = 'STORE_AVAILABILITY';
const STORE_AVAILABILITY_SUCCESS = 'STORE_AVAILABILITY_SUCCESS';
const STORE_AVAILABILITY_FAILURE = 'STORE_AVAILABILITY_FAILURE';

const UPDATE_STORE_AVAILABILITY = 'UPDATE_STORE_AVAILABILITY';
const UPDATE_STORE_AVAILABILITY_SUCCESS = 'UPDATE_STORE_AVAILABILITY_SUCCESS';
const UPDATE_STORE_AVAILABILITY_FAILURE = 'UPDATE_STORE_AVAILABILITY_FAILURE';


export {
    UPDATE_STORE_AVAILABILITY,
    UPDATE_STORE_AVAILABILITY_SUCCESS,
    UPDATE_STORE_AVAILABILITY_FAILURE,
    STORE_AVAILABILITY,
    STORE_AVAILABILITY_SUCCESS,
    STORE_AVAILABILITY_FAILURE
}

export const getStoreAvailability = (params, callback) => doAction(STORE_AVAILABILITY, params, callback);
export const getStoreAvailabilitySuccessAction = data => doResponseAction(STORE_AVAILABILITY_SUCCESS, data);
export const getStoreAvailabilityFailureAction = data => doResponseAction(STORE_AVAILABILITY_FAILURE, data);

export const updateStoreAvailable = (params, callback) => doAction(UPDATE_STORE_AVAILABILITY, params, callback);
export const updateStoreAvailableSuccessAction = data => doResponseAction(UPDATE_STORE_AVAILABILITY_SUCCESS, data);
export const updateStoreAvailableFailureAction = data => doResponseAction(UPDATE_STORE_AVAILABILITY_FAILURE, data);