import Actions from '../../actions'
const initialState = {
    isLoading: false,
    error: false
}
// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.UPDATE_PASSWORD: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.UPDATE_PASSWORD_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: false
            };
        }
        case Actions.UPDATE_PASSWORD_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        default:
            return state;
    }
};
