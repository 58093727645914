import Actions from "../actions";

const initialState = {
    isLoading: false,
    HappyHoursItemList: [],
    HappyHoursItemListDelete: [],
    error: false,
    HappyHoursItemListUpdateLoading: false,
    updateError: false,
    deleteLoading: false,
    deleteError: false,
    totalCount: undefined
}

const HappyHoursItemReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.HAPPY_HOURS_ITEM: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.HAPPY_HOURS_ITEM_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                HappyHoursItemList: action?.data?.response || [],
                totalCount: action.data?.totalPages,
                error: false
            };

        }

        case Actions.HAPPY_HOURS_ITEM_FAILURE: {
            return {
                ...state,
                isLoading: false,
                HappyHoursItemList: [],
                error: action.data,
            };
        }

        case Actions.HAPPY_HOURS_ITEM_UPDATE: {
            return {
                ...state,
                HappyHoursItemListUpdateLoading: true,
            }
        }
        case Actions.HAPPY_HOURS_ITEM_UPDATE_SUCCESS: {
            return {
                ...state,
                HappyHoursItemListUpdateLoading: false,
            }
        }
        case Actions.HAPPY_HOURS_ITEM_UPDATE_FAILURE: {
            return {
                ...state,
                HappyHoursItemListUpdateLoading: false,
                updateError: action.data
            }
        }
        case Actions.HAPPY_HOURS_ITEM_DELETE: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.HAPPY_HOURS_ITEM_DELETE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                HappyHoursItemListDelete: action?.data?.response || [],
                deleteError: false
            };
        }
        case Actions.HAPPY_HOURS_ITEM_DELETE_FAILURE: {
            return {
                ...state,
                isLoading: false,
                deleteError: action.data,
            };
        }
        default:
            return state;
    }
};

export default HappyHoursItemReducer;

export const getHappyHoursItemListSelector = state => state?.HAPPY_HOURS_ITEM?.HappyHoursItemList;
export const getHappyHoursItemCountSelector = state => state?.HAPPY_HOURS_ITEM?.totalCount;
export const getHappyHoursItemListLoadingSelector = state => state?.HAPPY_HOURS_ITEM?.isLoading;