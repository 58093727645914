import Actions from '../actions'
const initialState = {
    isNavOpen: true,
}
const NavigationDrawerReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.IS_OPEN_NAVIGATION_DRAWER: {
            return {
                ...state,
                isNavOpen: action.data,
            };
        }
        default:
            return state;
    }
};


export default NavigationDrawerReducer;

export const getIsNavigationOpenSelector = state => state?.NAV_BAR?.isNavOpen;
