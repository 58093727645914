import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getSelectedStoreSelector, getStoreListSelector } from '../../redux/reducers/StoreReducer';
import Hamburger from '../../../asset/new/img/hamburger.svg'
import Logo from '../../../asset/cv.png'      //TODO HEADER_MAIN_LOGO
import SmallLogo from '../../../asset/cv.png' //TODO HEADER_SMALL_LOGO
import Logout from '../../../asset/new/img/logout.png'
import NilgirisLogo from '../../../asset/images/niligiris_logo.png'
import Alert from '../../components/alert/alert';
import Home from '../../../asset/new/img/menu/home-active.svg'
import emailInvoice from '../../../asset/new/img/menu/emailInvoice.svg'
import FindProduct from '../../../asset/new/img/menu/FindProduct.png'
import Orders from '../../../asset/new/img/menu/orders.svg'
import Promotions from '../../../asset/new/img/menu/promotions.svg'
import Reports from '../../../asset/new/img/menu/reports.svg'
import PlanogramIcon from '../../../asset/new/img/menu/planogram.svg'
import Refund from '../../../asset/new/img/menu/refund.svg'
import Blocked from '../../../asset/new/img/menu/blocked.svg'
import Chat from '../../../asset/new/img/menu/chat.svg'
import Settings from '../../../asset/new/img/menu/settings.svg'
import { history } from '../../redux/store';
import RoutePath from '../../utils/routes/RoutePath';
import Actions from '../../redux/actions';
import Select from 'react-select';
import Constants, { COLORS } from '../../utils/Constants';
import StorePopUp from '../modal/StorePopUp';
import { getCookie, getRoleIdFromCookie, isJSON } from '../../utils/commonFunctions/cookie';
export const customStyles = {
    menu: (provided,) => ({
        ...provided,

    }),
    control: (provided) => ({
        ...provided,
        background: 'white',
        borderColor: COLORS.appThemeHex,
        minHeight: '30px',
        height: '30px',
        boxShadow: null,
        cursor: 'pointer',
    }),
    singleValue: (provided) => ({
        ...provided,
        color: 'black',
        cursor: 'pointer',
        placeholderColor: 'black',
    }),
    option: (provided, state) => ({
        ...provided,
        fontSize: '12px',
        color: state.isSelected ? 'white' : 'black',
        cursor: 'pointer',
        backgroundColor: state.isSelected ? '#000' : 'white',
    }),
    valueContainer: (provided) => ({
        ...provided,
        height: '30px',
        padding: '0 6px',
        color: 'white',
        cursor: 'pointer',
        fontSize: '14px',
    }),
    input: (provided) => ({
        ...provided,
        width: '80px',
        cursor: 'pointer',
        color: ''
    }),
    indicatorSeparator: () => ({
        cursor: 'pointer',
        display: 'none',
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        cursor: 'pointer',
        height: '30px',
    }),
};



const Header = () => {
    const storeList = useSelector(getStoreListSelector);
    const [defaultSelectedValue, setDefaultSelectedValue] = useState({});
    const selectedStore = useSelector(getSelectedStoreSelector);
    const [hambergerOpen, setHambergerOpen] = useState(false)
    const [isLogoutModal, setLogOutModal] = useState(false)
    const [showStorePopUp, setShowStorePopUp] = useState(false)
    const roleId = getRoleIdFromCookie()
    const [userDetails, setUserDetails] = useState({})
    const [isNilgirisUser, setIsNilgirisUser] = useState(false)

    const headerRef = useRef(null);
    const dispatch = useDispatch();

    const storeData = React.useMemo(() => {
        return storeList?.map((storeObj) => {
            const { store } = storeObj
            return (
                { label: store.branchName, value: store.id, data: store }
            )
        }) || [];
    }, [storeList]);

    const logout = (roleId) => {
        localStorage.clear()
        dispatch(Actions.logoutAction())
        document.cookie = `token=; domain=${Constants.COMMON_APP_DOMAINS.cookieDomain}; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
        document.cookie = `access_token=; domain=${Constants.COMMON_APP_DOMAINS.cookieDomain}; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
        document.cookie = `refresh_token=; domain=${Constants.COMMON_APP_DOMAINS.cookieDomain}; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
        document.cookie = `roleId=${roleId}; domain=${Constants.COMMON_APP_DOMAINS.cookieDomain}; Path=/;`;
        const uri = new URL(Constants.COMMON_APP_DOMAINS.commonLogin)                         //To redirect common app login screen for SSO integration
        uri.searchParams.set('path', window.location.pathname)
        uri.searchParams.set('origin', Constants.COMMON_APP_DOMAINS.storeManager)
        window.location.assign(uri)
    }


    useEffect(() => {
        const tokenDetails = isJSON(getCookie('token')) ? JSON.parse(getCookie('token')) : undefined
        setUserDetails(tokenDetails?.customer)
        setIsNilgirisUser(tokenDetails?.customer?.nilgirisUser);
    }, [])

    const handleStoreChange = useCallback((storeInfo) => {
        const data = { label: `${storeInfo?.branchName?.toLowerCase()} (${storeInfo?.address?.toLowerCase()})`, value: storeInfo?.id } //multiStore
        dispatch(Actions.setStoreInformation(storeInfo));
        if (isNilgirisUser) {
            dispatch(Actions.setSelectedStore(14))
        } else {
            dispatch(Actions.setSelectedStore(data))
        }
    }, [dispatch])
    useEffect(() => {

        const selectedObject = storeData?.find(s => s.data.id ===
            selectedStore?.id);
        const storeInfo = selectedObject || storeData[0];

        if (storeInfo) {
            handleStoreChange(storeInfo.data);
            setDefaultSelectedValue(storeInfo);
        }
        document.addEventListener('mousedown', handleClickOutside);
    }, [storeData, selectedStore, handleStoreChange]);


    useEffect(() => {
        if (Object.keys(defaultSelectedValue).length === 0) {
            const { location } = history
            const { pathname } = location || {}
            if (pathname !== RoutePath.DASHBOARD_SCREEN_PATH) {
                dispatch(Actions.getStoreList());
            }
        }
    })

    const handledHamberger = (hambergerOpen) => {
        setHambergerOpen(hambergerOpen);
    }

    const handleClickOutside = (event) => {
        if (headerRef && !headerRef?.current?.contains(event.target)) {
            setHambergerOpen(false);
        }
    }
    const openDashboardScreen = () => {
        history.push(RoutePath.DASHBOARD_SCREEN_PATH)
    }
    const handleActiveChange = (params) => {
        switch (params) {
            case 'home':
                history.replace(RoutePath.DASHBOARD_SCREEN_PATH)
                break;
            case 'orders':
                history.replace(RoutePath.ORDERS_PATH)
                break;
            case 'receipt':
                history.replace(RoutePath.INVOICE_LIST)
                break;
            case 'refund':
                history.replace(RoutePath.REFUND_SCREEN_PATH)
                break;
            case 'products':
                history.replace(RoutePath.MANAGE_PRODUCTS)
                break;
            case 'storeavailability':
                history.replace(RoutePath.STORE_AVAILABILITY)
                break;
            case 'reports':
                history.replace(RoutePath.REPORT)
                break;
            case 'notification':
                history.replace(RoutePath.NOTIFICATION)
                break;
            case 'planogram':
                history.replace(RoutePath.PLANOGRAM)
                break;
            case 'daxbotstatus':
                history.replace(RoutePath.DAXBOT_STATUS)
                break;
            case 'customerunblocking':
                history.replace(RoutePath.CUSTOMER_UNBLOCKING)
                break;
            case 'promotionDashboard':
                history.replace(RoutePath.PROMOTION_DASHBOARD)
                break;
            case 'chat':
                history.replace(RoutePath.CHATS_SCREEN)
                break;
            case 'settings':
                history.replace(RoutePath.CHANGE_PASSWORD)
                break;
            case 'branches':
                history.replace(RoutePath.BRANCHES)
                break;
            default:
                history.replace(RoutePath.DASHBOARD_SCREEN_PATH)
                break;
        }
        handledHamberger(false)
    }


    const openPim = () => {
        window.open(Constants.COMMON_APP_DOMAINS.PIMlogin, '_blank')
    }


    const _renderPimButton = () => {
        return (
            <div className="dropdown" style={{ marginRight: '10px' }}>
                <button id='openPim' onClick={() => openPim()} className="btn redirect" type="button">
                    Go to PIM
                </button>
            </div>
        )
    }

    const { location } = history
    const { pathname } = location || {}
    return <div id="header-shadow" className="shadow-dreamy sticky-nav px-5">
        <div className="d-flex align-items-center justify-content-between py-3">
            <div>
                {
                    (userDetails?.roleId === Constants.ROLES.ADMIN || userDetails?.roleId === Constants.ROLES.STORE_MANAGER || userDetails?.roleId === Constants.ROLES.BISTRO_ASSOCIATE || userDetails?.roleId === Constants.ROLES.STORE_ASSOCIATE) &&
                    <div className="col-auto d-block d-lg-none" id="hamberger" onClick={() => handledHamberger(true)}>
                        <img src={Hamburger} alt="menu" className="menu-icon" />
                    </div>
                }
                {
                    (userDetails?.roleId === Constants.ROLES.ADMIN ||
                        (userDetails?.roleId === Constants.ROLES.STORE_MANAGER && !isNilgirisUser) ||
                        userDetails?.roleId === Constants.ROLES.STORE_ASSOCIATE ||
                        userDetails?.roleId === Constants.ROLES.BISTRO_ASSOCIATE) ? <div className="col-auto d-none d-lg-block">
                        <div onClick={() => setShowStorePopUp(storeList?.length > 1 ? true : false)}>
                            <Select
                                id="handleStoreChange"
                                onChange={(value) => {
                                    setDefaultSelectedValue(value);
                                    handleStoreChange(value.data);   
                                }}
                                value={defaultSelectedValue}
                                options={storeData}
                                isDisabled={true}
                                styles={customStyles}
                            />
                        </div>
                    </div> : null
                }

            </div>
            <div>
                <a><img src={isNilgirisUser ? NilgirisLogo : Logo} alt="UST" className="logo d-none d-lg-block" /></a>
                <a><img id='openDashboardScreen' onClick={openDashboardScreen} src={isNilgirisUser? NilgirisLogo: SmallLogo} alt="Proglint" className="mob-logo me-2 d-block d-lg-none" /></a>
            </div>

            <div>

                <div className=" d-flex align-items-center justify-content-between">
                    {(userDetails?.roleId === Constants.ROLES.ADMIN || userDetails?.roleId === Constants.ROLES.STORE_MANAGER || userDetails?.roleId === Constants.ROLES.BISTRO_ASSOCIATE) && _renderPimButton()}

                    <span id="logOutModal-2" className="bell d-none d-lg-inline-block ml-4
                     cursor-pointer" onClick={() => setLogOutModal(true)}>
                        <img src={Logout} alt="bell" /> </span>
                </div>
            </div>

        </div>


        <div className={hambergerOpen ? "offcanvas-menu shadow show" : "offcanvas-menu shadow"} ref={headerRef}>
            <div id='handledHamberger' className="close-offcanvas cursor-pointer" onClick={() => handledHamberger(false)}>close</div>

            <nav>
                <span id='home' className={`${pathname === RoutePath.DASHBOARD_SCREEN_PATH ? 'menu-active' : ''} cursor-pointer`} onClick={() => handleActiveChange('home')} >
                    <img src={Home} alt="home" className="me-3" /> Home</span>
                {!isNilgirisUser && <span id='orders' className={`${pathname === RoutePath.ORDERS_PATH ? 'menu-active' : ''} cursor-pointer`} onClick={() => handleActiveChange('orders')}> <img src={Orders} alt="orders" className="me-3" /> Orders</span>}
                {!isNilgirisUser && <span id='receipt' className={`${pathname === RoutePath.INVOICE_LIST ? 'menu-active' : ''} cursor-pointer`} onClick={() => handleActiveChange('receipt')}> <img src={emailInvoice} alt="orders" className="me-3 sideNav-img" /> Receipt list</span>}
                {(userDetails?.roleId === Constants.ROLES.ADMIN || (userDetails?.roleId === Constants.ROLES.STORE_MANAGER)) && <span id='products' className={`${pathname === RoutePath.MANAGE_PRODUCTS ? 'menu-active' : ''} cursor-pointer`} onClick={() => handleActiveChange('products')}> <img src={FindProduct} alt="products" className="me-3" /> Find product</span>}
                {(userDetails?.roleId === Constants.ROLES.ADMIN || (userDetails?.roleId === Constants.ROLES.STORE_MANAGER && !isNilgirisUser)) && <span id='storeavailability' className={`${pathname === RoutePath.STORE_AVAILABILITY ? 'menu-active' : ''} cursor-pointer`} onClick={() => handleActiveChange('storeavailability')}><img src={Home} alt="storeavailability" className="me-3" />Store timing</span>}
                {(userDetails?.roleId === Constants.ROLES.ADMIN || (userDetails?.roleId === Constants.ROLES.STORE_MANAGER && !isNilgirisUser)) && <span id='promotionDashboard' className={`${pathname === RoutePath.PROMOTION_DASHBOARD ? 'menu-active' : ''} cursor-pointer`} onClick={() => handleActiveChange('promotionDashboard')}> <img src={Promotions} alt="promotions" className="me-3" /> Promotions</span>}
                {(userDetails?.roleId === Constants.ROLES.ADMIN || (userDetails?.roleId === Constants.ROLES.STORE_MANAGER && !isNilgirisUser)) && <span id='reports' className={`${pathname === RoutePath.REPORT ? 'menu-active' : ''} cursor-pointer`} onClick={() => handleActiveChange('reports')}> <img src={Reports} alt="reports" className="me-3" /> Reports</span>}
                {(userDetails?.roleId === Constants.ROLES.ADMIN || userDetails?.roleId === Constants.ROLES.STORE_MANAGER) && <span id='planogram' className={`${pathname === RoutePath.PLANOGRAM ? 'menu-active' : ''} cursor-pointer`} onClick={() => handleActiveChange('planogram')}> <img src={PlanogramIcon} alt="planogram" className="me-3" /> Planogram</span>}
                {(userDetails?.roleId === Constants.ROLES.ADMIN || (userDetails?.roleId === Constants.ROLES.STORE_MANAGER && !isNilgirisUser)) && <span id='refund' className={`${pathname === RoutePath.REFUND_SCREEN_PATH ? 'menu-active' : ''} cursor-pointer`} onClick={() => handleActiveChange('refund')}> <img src={Refund} alt="refunds" className="me-3" />  Refund requests</span>}
                {(userDetails?.roleId === Constants.ROLES.ADMIN || (userDetails?.roleId === Constants.ROLES.STORE_MANAGER && !isNilgirisUser)) && <span id='customerunblocking' className={`${pathname === RoutePath.CUSTOMER_UNBLOCKING ? 'menu-active' : ''} cursor-pointer`} onClick={() => handleActiveChange('customerunblocking')}> <img src={Blocked} alt="blocked" className="me-3" /> Blocked customers</span>}
                {!isNilgirisUser && <span id='chat' className={`${pathname === RoutePath.CHATS_SCREEN ? 'menu-active' : ''} cursor-pointer`} onClick={() => handleActiveChange('chat')}> <img src={Chat} alt="chat" className="me-3" /> Chat</span>}
                {(userDetails?.roleId === Constants.ROLES.ADMIN || userDetails?.roleId === Constants.ROLES.STORE_MANAGER || userDetails?.roleId === Constants.ROLES.STORE_ASSOCIATE) && <span id='notification' className={`${pathname === RoutePath.NOTIFICATION ? 'menu-active' : ''} cursor-pointer`} onClick={() => handleActiveChange('notification')}> <img src={Settings} alt="settings" className="me-3" /> Notification</span>}
                <span id='settings' className={`${pathname === RoutePath.CHANGE_PASSWORD ? 'menu-active' : ''} cursor-pointer`} onClick={() => handleActiveChange('settings')}> <img src={Settings} alt="settings" className="me-3" /> Settings</span>
                {userDetails?.roleId === 0 && <span id='branches' className={`${pathname === RoutePath.BRANCHES ? 'menu-active' : ''} cursor-pointer`} onClick={() => handleActiveChange('branches')}> <img src={Blocked} alt="settings" className="me-3" /> Members</span>}
                <span id='Logouts' className="logout" onClick={() => setLogOutModal(true)} >Logout</span>
            </nav>
            {isLogoutModal && <Alert
                id="alert"
                description={Constants.LOG_OUT.desc}
                proceed={Constants.LOG_OUT.proceed}
                cancel={Constants.LOG_OUT.cancel}
                onSuccess={() => logout(roleId)}
                onFailure={() => setLogOutModal(false)}
            />}
            {showStorePopUp && <StorePopUp
                onCloseModal={() => setShowStorePopUp(false)}
                isModalOpen={showStorePopUp}
            />}
        </div>
    </div>
}

export default Header;