import { doAction, doResponseAction } from ".";

const FREE_ITEMS = 'FREE_ITEMS';
const FREE_ITEMS_SUCCESS = 'FREE_ITEMS_SUCCESS';
const FREE_ITEMS_FAILURE = 'FREE_ITEMS_FAILURE';

const FREE_ITEMS_CREATE = 'FREE_ITEMS_CREATE';
const FREE_ITEMS_CREATE_SUCCESS = 'FREE_ITEMS_CREATE_SUCCESS';
const FREE_ITEMS_CREATE_FAILURE = 'FREE_ITEMS_CREATE_FAILURE';

const FREE_ITEMS_UPDATE = 'FREE_ITEMS_UPDATE';
const FREE_ITEMS_UPDATE_SUCCESS = 'FREE_ITEMS_UPDATE_SUCCESS';
const FREE_ITEMS_UPDATE_FAILURE = 'FREE_ITEMS_UPDATE_FAILURE';

const DELETE_FREE_ITEMS = 'DELETE_FREE_ITEMS';
const DELETE_FREE_ITEMS_SUCCESS = 'DELETE_FREE_ITEMS_SUCCESS';
const DELETE_FREE_ITEMS_FAILURE = 'DELETE_FREE_ITEMS_FAILURE'


export {
    FREE_ITEMS,
    FREE_ITEMS_SUCCESS,
    FREE_ITEMS_FAILURE,
    FREE_ITEMS_CREATE,
    FREE_ITEMS_CREATE_SUCCESS,
    FREE_ITEMS_CREATE_FAILURE,
    FREE_ITEMS_UPDATE,
    FREE_ITEMS_UPDATE_SUCCESS,
    FREE_ITEMS_UPDATE_FAILURE,
    DELETE_FREE_ITEMS,
    DELETE_FREE_ITEMS_SUCCESS,
    DELETE_FREE_ITEMS_FAILURE
}


export const getFreeItemsListAction = (params, callback) => doAction(FREE_ITEMS, params, callback);
export const getFreeItemsListSuccessAction = data => doResponseAction(FREE_ITEMS_SUCCESS, data);
export const getFreeItemsListFailureAction = data => doResponseAction(FREE_ITEMS_FAILURE, data);

export const createFreeItemsList = (params, callback) => doAction(FREE_ITEMS_CREATE, params, callback);
export const createFreeItemsListSuccess = (data) => doResponseAction(FREE_ITEMS_CREATE_SUCCESS, data);
export const createFreeItemsListFailure = (data) => doResponseAction(FREE_ITEMS_CREATE_FAILURE, data);

export const updateFreeItemsList = (params, callback) => doAction(FREE_ITEMS_UPDATE, params, callback);
export const updateFreeItemsListSuccess = data => doResponseAction(FREE_ITEMS_UPDATE_SUCCESS, data);
export const updateFreeItemsListFailure = data => doResponseAction(FREE_ITEMS_UPDATE_FAILURE, data);

export const deleteFreeItemsList = (params, callback) => doAction(DELETE_FREE_ITEMS, params, callback);
export const deleteFreeItemsListSuccess = (data) => doResponseAction(DELETE_FREE_ITEMS_SUCCESS, data);
export const deleteFreeItemsListFailure = (data) => doResponseAction(DELETE_FREE_ITEMS_FAILURE, data);
