export const convertPlanogramFormat = (Rack) => {
    try {
        const { rackType = 1 } = Rack;
        return rackType === 3 ? convertPlanogramFruitRackFormat(Rack) : convertPlanogramRackFormat(Rack)
    } catch (e) {
        console.log(e)
    }
    return {}

}

export const convertPlanogramRackFormat = (Rack) => {
    try {
        const { id, rackName, width, height, depth, rackType = 1 } = Rack;
        return {
            id,
            rackName,
            width,
            height,
            depth,
            type: rackType,
            shelves: Rack.shelves.map((shelve) => {
                const { id, shelveName, shelveHeight, products } = shelve;
                return ({
                    id,
                    shelveName,
                    shelveHeight,
                    products: products.map((product) => {
                        const { id, productView } = product;
                        const { name, description, imageUrl, upc, widthInCm, heightInCm, depthInCm, ageRestricted, veg } = product.productDetails || {};
                        return {
                            id,
                            name,
                            description,
                            imageUrl: imageUrl + '?' + (new Date()).getDate(), // : 'https://d2gg6xxnuvt68a.cloudfront.net/pim/upc_0000004', //: 'https://d2w9m16hs9jc37.cloudfront.net/3d/new_man/shirt/STD/699_fabric/folded/body+button_close_standard.png',
                            upc,
                            widthInCm,
                            heightInCm,
                            depthInCm,
                            ageRestricted,
                            veg,
                            productView,
                        }
                    })
                })
            })

        }
    } catch (e) {
        console.log(e)
    }
    return {}

}

export const convertPlanogramFruitRackFormat = (Rack) => {
    try {
        const { id, rackName, width, height, depth, rackType = 1 } = Rack;
        return {
            id,
            rackName,
            width,
            height,
            depth,
            type: rackType,
            shelves: Rack.shelves.map((shelve) => {
                const { id, shelveName, shelveHeight, bays } = shelve;
                return ({
                    id,
                    shelveName,
                    shelveHeight,
                    products: bays.map((bay) => {
                        const { id, productView, bayWidth } = bay;
                        const { name, description, imageUrl, upc, widthInCm, heightInCm, depthInCm, ageRestricted, veg } = bay.productDetails || {};
                        return {
                            bay: {
                                bayWidth
                            },
                            id,
                            name,
                            description,
                            imageUrl: imageUrl + '?' + (new Date()).getDate(), // : 'https://d2gg6xxnuvt68a.cloudfront.net/pim/upc_0000004', //: 'https://d2w9m16hs9jc37.cloudfront.net/3d/new_man/shirt/STD/699_fabric/folded/body+button_close_standard.png',
                            upc,
                            widthInCm,
                            heightInCm,
                            depthInCm,
                            ageRestricted,
                            veg,
                            productView,
                        }
                    })
                })
            })

        }
    } catch (e) {
        console.log(e)
    }
    return {}

}

export const convertStorePlanogramFormat = (Store) => {
    try {
        const { storeId, storeWidth, storeLength, storeHeight } = Store;
        const initPosition = {
            id: -1,
            rackName: 'dummy',
            width: 1,
            height: 1,
            depth: 1,
            type: -1,
            xAxis: '0',
            yAxis: '0',
            shelves: [],
        }
        const storeLengthPercentage = (storeLength * 100) / storeWidth
        const endPositionY = (storeLengthPercentage * 6.5).toString()
        const endPosition = {
            id: -2,
            rackName: 'dummy',
            width: 1,
            height: 1,
            depth: 1,
            type: -1,
            xAxis: '649',
            yAxis: endPositionY,
            shelves: [],
        }
        let rack = Store.rack.map((Rack) => {
            const { id, rackName, width, height, depth, rackType = 1, xAxis, yAxis, zAxis, rackPosition } = Rack;
            const rackWidth = (parseFloat(width)  / ( parseFloat(storeWidth) * 100)) * 650
            const rackDepth = (parseFloat(depth) / parseFloat(width)  ) * (rackWidth)
            const rackHeight = (parseFloat(height) / parseFloat(width)  ) * (rackWidth)
            return {
                id,
                rackName,
                width: rackWidth,
                height: rackHeight,
                depth: rackDepth,
                // width,
                // height,
                // depth,
                type: rackType,
                xAxis,
                yAxis,
                zAxis,
                rackPosition,
                shelves: Rack.shelves.map((shelve) => {
                    const { id, shelveName, shelveHeight, products, bays } = shelve;
                    const shelveHeightData = (parseFloat(shelveHeight) / parseFloat(width)  ) * (rackWidth)

                    return ({
                        id,
                        shelveName,
                        shelveHeight: shelveHeightData,
                        products: rackType !== 3 ? products.map((product) => {
                            const { id, productView } = product;
                            const { name, description, imageUrl, upc, widthInCm, heightInCm, depthInCm, ageRestricted, veg } = product.productDetails || {};
                            const productWidth = (parseFloat(widthInCm) / parseFloat(width)  ) * (rackWidth)
                            const productDepth = (parseFloat(depthInCm) / parseFloat(width)  ) * (rackWidth)
                            const productHeight = (parseFloat(heightInCm) / parseFloat(width)  ) * (rackWidth)
                            return {
                                id,
                                name,
                                description,
                                imageUrl: imageUrl + '?' + (new Date()).getDate(), // : 'https://d2gg6xxnuvt68a.cloudfront.net/pim/upc_0000004', //: 'https://d2w9m16hs9jc37.cloudfront.net/3d/new_man/shirt/STD/699_fabric/folded/body+button_close_standard.png',
                                upc,
                                widthInCm: productWidth,
                                heightInCm: productHeight,
                                depthInCm: productDepth,
                                ageRestricted,
                                veg,
                                productView,
                            }
                        }) :  bays.map((bay) => {
                            const { id, productView, bayWidth } = bay;
                            const { name, description, imageUrl, upc, widthInCm, heightInCm, depthInCm, ageRestricted, veg } = bay.productDetails || {};
                            return {
                                bay: {
                                    bayWidth
                                },
                                id,
                                name,
                                description,
                                imageUrl: imageUrl + '?' + (new Date()).getDate(), // : 'https://d2gg6xxnuvt68a.cloudfront.net/pim/upc_0000004', //: 'https://d2w9m16hs9jc37.cloudfront.net/3d/new_man/shirt/STD/699_fabric/folded/body+button_close_standard.png',
                                upc,
                                widthInCm,
                                heightInCm,
                                depthInCm,
                                ageRestricted,
                                veg,
                                productView,
                            }
                        })
                    })
                })
            }
        })
        rack.push(initPosition)
        rack.push(endPosition)
        return {
            storeId,
            storeWidth,
            storeLength,
            storeHeight,
            rack,
        }
    } catch (e) {
        console.log(e)
    }
    return {}

}
