import { doAction, doResponseAction } from ".";

const INVOICE_DETAILS = 'INVOICE_DETAILS';
const INVOICE_DETAILS_SUCCESS = 'INVOICE_DETAILS_SUCCESS';
const INVOICE_DETAILS_FAILURE = 'INVOICE_DETAILS_FAILURE'

const MANUAL_REFUND = 'MANUAL_REFUND';
const MANUAL_REFUND_SUCCESS = 'MANUAL_REFUND_SUCCESS';
const MANUAL_REFUND_FAILURE = 'MANUAL_REFUND_FAILURE'
export {
    INVOICE_DETAILS,
    INVOICE_DETAILS_SUCCESS,
    INVOICE_DETAILS_FAILURE,

    MANUAL_REFUND,
    MANUAL_REFUND_SUCCESS,
    MANUAL_REFUND_FAILURE
}

export const invoiceDetails = (params, callback) => doAction(INVOICE_DETAILS, params, callback);
export const invoiceDetailsSuccess = (data) => doResponseAction(INVOICE_DETAILS_SUCCESS, data);
export const invoiceDetailsFailure = (data) => doResponseAction(INVOICE_DETAILS_FAILURE, data);

export const manaualRefund = (params, callback) => doAction(MANUAL_REFUND, params, callback);
export const manaualRefundSuccess = (data) => doResponseAction(MANUAL_REFUND_SUCCESS, data);
export const manaualRefundFailure = (data) => doResponseAction(MANUAL_REFUND_FAILURE, data);