import { all, put, takeLatest } from "redux-saga/effects";
import { doCvGet } from "../../../utils/service";
import END_POINTS from "../../../utils/service/Endpoints";
import Actions from "../../actions";

export function* getCameraListSaga({ params, callback }) {
    try {
        const response = yield doCvGet(END_POINTS.GET_CAMERA_LIST, params)
        if (response) {
            callback && callback(response)
            yield put(Actions.Cv_CameraListSuccessAction(response));
        }
    } catch (error) {
        if (callback) {
            callback({ error })
            yield put(Actions.Cv_CameraListFailureAction({ error }));
        }
    }
}

export function* getProductDetailsSaga({ params, callback }) {
    try {
        const response = yield doCvGet(END_POINTS.GET_PRODUCT_DETAILS, params)
        if (response) {
            callback && callback(response)
            yield put(Actions.Cv_Product_Details_Success_Action(response));
        }
    } catch (error) {
        if (callback) {
            callback({ error })
            yield put(Actions.Cv_Product_Details_Failure_Action({ error }));
        }
    }
}

export default function* getCameraListListner() {
    yield all([
        takeLatest(Actions.CV_CAMERA_LIST, getCameraListSaga),
        takeLatest(Actions.CV_PRODUCT_DETAILS, getProductDetailsSaga)
    ]);
}