import { all, put, takeLatest } from "redux-saga/effects";
import { doAuthGet, doAuthPut } from "../../utils/service";
import END_POINTS from "../../utils/service/Endpoints";
import Actions from "../actions";

export function* StoreAvailability({ params, callback }) {
    try {
        const response = yield doAuthGet(END_POINTS.STORE_AVAILABILITY, params);
        if (response) {
            yield put(Actions.getStoreAvailabilitySuccessAction(response));
        }
        callback && callback(response);
    }
    catch (error) {
        callback && callback(error);
        yield put(Actions.getStoreAvailabilityFailureAction({ error }));
    }
}
export function* UpdateStoreAvailability({ params, callback }) {
    try {
        const response = yield doAuthPut(END_POINTS.UPDATE_AVAILABILITY, params);
        if (response) {
            yield put(Actions.updateStoreAvailableSuccessAction(response));
        }
        callback && callback(response);
    }
    catch (error) {
        callback && callback(error);
        yield put(Actions.updateStoreAvailableFailureAction({ error }));
    }
}
export default function* StoreAvailabilityListener() {
    yield all([
        takeLatest(Actions.STORE_AVAILABILITY, StoreAvailability),
        takeLatest(Actions.UPDATE_STORE_AVAILABILITY, UpdateStoreAvailability),

    ]);
}
