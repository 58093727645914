import { doAction, doResponseAction } from ".";

const PRODUCT_GROUP_INFORMATIONS = 'PRODUCT_GROUP_INFORMATIONS';
const PRODUCT_GROUP_INFORMATIONS_SUCCESS = 'PRODUCT_GROUP_INFORMATIONS_SUCCESS';
const PRODUCT_GROUP_INFORMATIONS_FAILURE = 'PRODUCT_GROUP_INFORMATIONS_FAILURE';


export {
    PRODUCT_GROUP_INFORMATIONS,
    PRODUCT_GROUP_INFORMATIONS_SUCCESS,
    PRODUCT_GROUP_INFORMATIONS_FAILURE
}

export const productGroupInformationAction = (params, callback) => doAction(PRODUCT_GROUP_INFORMATIONS, params, callback);
export const productGroupInformationSuccessAction = data => doResponseAction(PRODUCT_GROUP_INFORMATIONS_SUCCESS, data);
export const productGroupInformationFailureAction = data => doResponseAction(PRODUCT_GROUP_INFORMATIONS_FAILURE, data);

