import { doAction, doResponseAction } from ".";

const BISTRO_PRODUCT_LIST = 'BISTRO_PRODUCT_LIST';
const BISTRO_PRODUCT_LIST_SUCCESS = 'BISTRO_PRODUCT_LIST_SUCCESS';
const BISTRO_PRODUCT_LIST_FAILURE = 'BISTRO_PRODUCT_LIST_FAILURE';


export {
    BISTRO_PRODUCT_LIST,
    BISTRO_PRODUCT_LIST_SUCCESS,
    BISTRO_PRODUCT_LIST_FAILURE
}

export const bistroProductListAction = (params, callback) => doAction(BISTRO_PRODUCT_LIST, params, callback);
export const bistroProductListSuccessAction = data => doResponseAction(BISTRO_PRODUCT_LIST_SUCCESS, data);
export const bistroProductListtFailureAction = data => doResponseAction(BISTRO_PRODUCT_LIST_FAILURE, data);

