import React from "react";
import { withRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import Close from '../../../asset/new/img/modal-close.svg'

class PlanogramReportPopUp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }


    render() {
        const { items, closePopUp, isOutOfStock ,rackName } = this.props
        return (
            <React.Fragment>
                <div className="modal d-block" id="order-details-modal" tabIndex="-1" aria-labelledby="login" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg">
                        <div className="modal-content rounded-0">
                            <img src={Close} alt="X" id='Close'
                                data-bs-dismiss="modal" aria-label="Close" className="modal-close" onClick={() => closePopUp()} />
                            <div className="modal-header rounded-0 align-items-center">
                                <h5 className="modal-title">
                                    {!isOutOfStock ? "Partial Stock" : "Out of Stock"} in {rackName}</h5>
                            </div>

                            <div className="modal-body font13 p-10">
                                {
                                    items?.length > 0 && <div class="ms-4 plano_custom" role="alert">
                                        <ol type="1" className='misplacedBullet'>
                                            {items.map((questionlist, index) =>
                                                <li className="mb-4" key={index}>{questionlist}</li>
                                            )}
                                        </ol>
                                    </div>
                                }

                                {
                                    (items && items.length === 0) && (
                                        <div className="text-center">
                                            <h2 className="font16 mb-2 fw-bold">No products found </h2>
                                        </div>
                                       
                                    )
                                }
                            </div>

                        </div>
                    </div>
                </div>
                <div className="modal-backdrop show"></div>
            </React.Fragment >
        );
    }
}

export const mapStateToProps = (state) => {
    return {};
};

export default withRouter(connect(mapStateToProps, {})(PlanogramReportPopUp));