import Actions from "../actions";

const initialState = {
    isLoadingNew: false,
    isLoadingResolved: false,
    newNotificationsDetails: [],
    resolvedNotificationsDetails: [],
    newNotificationIDList: [],
    error: false,
    newNotificationCount: null,
    resolvedNotificationCount: null
}
const NotificationsReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.GET_NEW_NOTIFICATIONS: {
            return {
                ...state,
                isLoading: true
            };
        }
        case Actions.GET_NEW_NOTIFICATIONS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                newNotificationsDetails: action.data?.response,
                newNotificationCount: action.data?.count,
                error: false
            };
        }
        case Actions.SET_NEW_NOTIFICATIONS_DATA: {
            return {
                ...state,
                isLoading: false,
                newNotificationsDetails: [...action.data, ...state?.newNotificationsDetails],
                newNotificationCount: state?.newNotificationCount + 1,
                error: false
            };
        }
        case Actions.GET_NEW_NOTIFICATIONS_FAILURE: {
            return {
                ...state,
                isLoading: false,
                newNotificationsDetails: [],
                error: action.error,
            };
        }
        case Actions.GET_RESOLVED_NOTIFICATIONS: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.GET_RESOLVED_NOTIFICATIONS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                resolvedNotificationsDetails: action.data?.response,
                resolvedNotificationCount: action.data?.count,
                error: false
            };
        }
        case Actions.GET_RESOLVED_NOTIFICATIONS_FAILURE: {
            return {
                ...state,
                isLoading: false,
                resolvedNotificationsDetails: [],
                error: action.error,
            };
        }
        case Actions.APPEND_NEW_NOTIFICATIONS_ARRAY: {
            return {
                ...state,
                isLoading: false,
                newNotificationsDetails: [...state.newNotificationsDetails, ...action.data],
                error: action.error,
            };
        }
        case Actions.APPEND_NEW_NOTIFICATIONS: {
            return {
                ...state,
                isLoading: false,
                newNotificationsDetails: [action.data, ...state.newNotificationsDetails],
                error: action.error,
            };
        }
        case Actions.APPEND_RESOLVED_NOTIFICATIONS: {
            return {
                ...state,
                isLoading: false,
                resolvedNotificationsDetails: [action.data, ...state.resolvedNotificationsDetails],
                error: action.error,
            };
        }
        case Actions.APPEND_RESOLVED_NOTIFICATIONS_ARRAY: {
            return {
                ...state,
                isLoading: false,
                resolvedNotificationsDetails: [...state.resolvedNotificationsDetails, ...action.data],
                error: action.error,
            };
        }
        case Actions.REMOVE_NEW_NOTIFICATIONS: {
            const newNotificationList = state.newNotificationsDetails.filter(notification => notification.id !== action.data.id);
            return {
                ...state,
                isLoading: false,
                newNotificationsDetails: newNotificationList,
                error: action.error,
            };
        }
        case Actions.UPDATE_LATEST_NOTIFICATION_ID_LIST: {
            const list = state.newNotificationIDList || [];
            return {
                ...state,
                newNotificationIDList: [...list, action.data?.id]
            };
        }
        case Actions.RESET_LATEST_NOTIFICATION_ID: {
            return {
                ...state,
                newNotificationIDList: []
            };
        }
        default:
            return state;
    }
};

export default NotificationsReducer;

export const newNotificationsSelector = state => state?.NOTIFICATIONS?.newNotificationsDetails;
export const newNotificationsCount = state => state?.NOTIFICATIONS?.newNotificationCount
export const notificationsLoadingSelector = state => state?.NOTIFICATIONS?.isLoading;
export const resolvedNotificationsSelector = state => state?.NOTIFICATIONS?.resolvedNotificationsDetails;
export const resolvedNotificationsCount = state => state?.NOTIFICATIONS?.resolvedNotificationCount
export const newNotificationIdListSelector = state => state?.NOTIFICATIONS?.newNotificationIDList;
export const newNotificationCountSelector = state => state?.NOTIFICATIONS?.newNotificationIDList?.length || 0;
