import { takeLatest, all, put, select } from 'redux-saga/effects';
import Actions from '../actions';
import { doAuthGet, doAuthPost } from "../../utils/service";
import END_POINTS from "../../utils/service/Endpoints";
import { updateChatObjectSelector } from '../reducers/ChatsReducer';

export function* createNewChatSaga({ params, callback }) {
    try {
        const response = yield doAuthPost(END_POINTS.SEND_CHAT_REPLY, params);
        if (response.response_code === 0) {
            const newMessageObject = response.response;
            const existingChatObj = yield select(updateChatObjectSelector);
            if (existingChatObj.chatMessages) {
                existingChatObj.chatMessages.unshift(newMessageObject);
                yield put(Actions.getChatMessagesListSuccess(existingChatObj));
            } else {
                yield put(Actions.getChatMessagesListSuccess(existingChatObj));
            }
        }
        if (callback) {
            callback(response)
        }
    } catch (error) {
        if (callback) {
            callback(error)
        }
        yield put(Actions.createNewChatFailure(error));
    }
}


export function* getChatMessagesSaga({ params, callback }) {
    try {
        const response = yield doAuthGet(END_POINTS.GET_CHAT_DETAILS, params);
        if (response.response_code === 0) {
            yield put(Actions.getChatMessagesListSuccess(response.response));
        }
        if (callback) {
            callback(response)
        }
    } catch (error) {
        if (callback) {
            callback(error)
        }
        yield put(Actions.getChatMessagesListFailure(error));
    }
}

export function* getAllChatsListSaga({ params, callback }) {
    try {
        const response = yield doAuthGet(END_POINTS.GET_ALL_CHATS, params);
        if (response.response_code === 0) {
            yield put(Actions.getAllChatMessagesListSuccess(response.response));
        }
        if (callback) {
            callback(response)
        }
    } catch (error) {
        if (callback) {
            callback(error)
        }
        yield put(Actions.getAllChatMessagesListFailure(error));
    }
}


export default function* chatsListener() {
    yield all([
        takeLatest(Actions.CREATE_NEW_CHAT, createNewChatSaga),
        takeLatest(Actions.GET_CHAT_MESSAGES_LIST, getChatMessagesSaga),
        takeLatest(Actions.GET_ALL_CHATS_LIST, getAllChatsListSaga)
    ]);
}
