import React from 'react';
import alertTriangle from '../../../asset/images/alert-triangle.svg'
const BoldError = ({ message, isDanger }) => {
    return message ? <div className={`${isDanger ? 'alert alert-danger alert-prod-danger' : 'alert alert-danger'}`} role="alert">
        {isDanger ? <div className='d-flex align-item-center'>
            <img src={alertTriangle} width="30px" alt='Error' />
            <p className='ml-2 mt-2'>{message}</p>
        </div> : <span>{message}</span>}
    </div> : null;
}

export default BoldError;