import React, { useState } from "react";
import { useDispatch } from "react-redux";
import BoldError from "../../components/fields/BoldError";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import Close from '../../../asset/new/img/modal-close.svg'
import { getAllBranchListAction, updatePasswordAction } from "../../redux/actions/BranchListActions";
import PasswordCheckPopover from "../../components/fields/PasswordCheckPopover";
import { toast } from 'react-toastify';
import { validatedigitsRegExp, validatelowercaseRegExp, validateminLengthRegExp, validatespecialCharRegExp, validateuppercaseRegExp } from "../../utils/validation";



const ResetPassword = (props) => {
    const [state, setState] = useState({
        newPassword: "",
        reTypePassword: "",
        validationError: ''
    })
    const [showPopover, setShowPopover] = useState(false)
    const [showPassword, setShowPassword] = useState('')
    const dispatch = useDispatch()
    const validatePass = () => {
        if (!state.newPassword) {
            setState({ ...state, validationError: "Please enter the password" })
            return;
        } else if (!state.reTypePassword) {
            setState({ ...state, validationError: 'Please Retype the password' });
            return;
        } else if (!validateuppercaseRegExp(state.newPassword)) {
            setState({ ...state, validationError: 'Please enter valid password' });
            return
        } else if (!validatelowercaseRegExp(state.newPassword)) {
            setState({ ...state, validationError: 'Please enter valid password' });
            return
        } else if (!validatespecialCharRegExp(state.newPassword)) {
            setState({ ...state, validationError: 'Please enter valid password' });
            return
        } else if (!validatedigitsRegExp(state.newPassword)) {
            setState({ ...state, validationError: 'Please enter valid password' });
            return
        } else if (!validateminLengthRegExp(state.newPassword)) {
            setState({ ...state, validationError: 'Please enter valid password' });
            return
        }
        else {
            setState({ ...state, validationError: '' })
            handleReset()
        }
    }
    const handleReset = () => {
        const params = {
            userId: props.id,
            updatePassword: state.reTypePassword
        }
        const searchFilter = {
            offset: (props.activePage - 1) * 10,
            limit: 10,
            search: props.searchList
        }
        dispatch(updatePasswordAction(params, (response) => {
            if (response.response_code === 0) {
                toast.success('Password updated successfully')
                dispatch(getAllBranchListAction(searchFilter))
            } else {
                toast.error(response.response_message)
                dispatch(getAllBranchListAction(searchFilter))
            }
        }))
        props.setResetPasswordModal(false)
    }
    const handleCancel = () => {
        props.setResetPasswordModal(false)
    }
    return (
        <>
            <div className="modal d-block"
                id="view-multi-modal"
                tabIndex="-1"
                aria-labelledby="view-multi-modal"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content rounded-0">
                        <img id="close" src={Close} alt="X" data-bs-dismiss="modal" aria-label="Close"
                            className="modal-close" onClick={() => props.setResetPasswordModal(false)} />
                        <div className="modal-header rounded-0 align-items-center mx-auto py-4">
                            <div className="col">
                                <h2 className="font28 fw-bold">Reset Password </h2>
                            </div>
                        </div>
                        <div className="modal-body font14 p-40">
                            {state.validationError && <BoldError message={state.validationError} />}
                            <div className="row mb-4">
                                <div className="col"> Please enter your new password with which you would like to access</div>
                            </div>
                            <div className="row align-items-center mb-4">
                                <div className="input-group mb-4">
                                    {showPopover && <PasswordCheckPopover value={state.newPassword} />}
                                    <input
                                        id='Password'
                                        className="form-control"
                                        type={showPassword ? 'text' : 'password'}
                                        name="password"
                                        onFocus={() => setShowPopover(true)}
                                        onBlur={() => setShowPopover(false)}
                                        onChange={(e) => setState({ ...state, newPassword: e.target.value })}
                                        placeholder="New Password"
                                        aria-describedby="view-pass" />
                                    <button className="btn border-dark"
                                        onClick={() => setShowPassword(!showPassword)}
                                        type="button"
                                        id="view-pass">
                                        <FontAwesomeIcon
                                            style={{ cursor: 'pointer' }}
                                            icon={showPassword ? faEye : faEyeSlash} />
                                    </button>
                                </div>
                            </div>
                            <div className="row mb-4">
                                <div className="input-group mb-4">
                                    <input
                                        id='setPassword'
                                        className="form-control"
                                        type={showPassword ? 'text' : 'password'}
                                        name="password"
                                        onChange={(e) => setState({ ...state, reTypePassword: e.target.value })}
                                        placeholder="Retype Password"
                                        aria-describedby="view-pass" />
                                    <button className="btn border-dark"
                                        onClick={() => setShowPassword(!showPassword)}
                                        type="button"
                                        id="view">
                                        <FontAwesomeIcon
                                            style={{ cursor: 'pointer' }}
                                            icon={showPassword ? faEye : faEyeSlash} />
                                    </button>
                                </div>
                                {state.reTypePassword && state.newPassword !== state.reTypePassword && <p style={{ color: 'red', fontWeight: 24 }}>Password does not match</p>}
                            </div>
                            <div className="row mb-4">
                                <div className="col-6 pe-0"> <button className="btn btn-success font14 w-100 p-3 " disabled={state.newPassword !== state.reTypePassword}
                                    id="manageClick" onClick={() => validatePass()}>Update</button> </div>
                                <div className="col-6 text-end">
                                    <button id="openClick" className="btn btn-outline-danger font14 w-100 p-3" onClick={() => handleCancel()}>
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop show"></div>
        </>
    )
}

export default ResetPassword