import React, { useState, useCallback } from 'react';
import Constants from '../../../utils/Constants';
import AppDatePicker from '../../../components/fields/AppDatePicker';
import Actions from '../../../redux/actions';
import { useDispatch } from 'react-redux';
import ProductsTypeAhead from '../../../components/fields/ProductsTypeAhead';
import BoldError from '../../../components/fields/BoldError';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import { UTCViewFormat } from '../../../utils/commonFunctions/dateFormat';
import Close from '../../../../asset/new/img/modal-close.svg'
import placeholderImage from '../../../../asset/images/placeholder.jpg';
import AppLoader from '../../../components/fields/AppLoader';

export const DEFAULT_FREE_ITEM = { sku: '', editQuantity: 1 };

const defaultState = {
    sku: 0,
    freeItemSkusWithQuantities: [
        { ...DEFAULT_FREE_ITEM }
    ],
    offer: {
        offerType: 'FREE_ITEMS',
        offerValue: 1,
        expiryOn: null,
        validFrom: null
    }
}
const FreeItemsModal = ({ modalData = null, onClose = () => null }) => {
    const inititlizeData = modalData?.sku ? modalData : defaultState;
    const optionsData = modalData?.sku ? [{ label: modalData.productName, value: modalData.sku }] : null
    const [freeItemsObject, setFreeItemsObject] = useState(inititlizeData)
    const [productType, setProductType] = useState('')
    const [loader, setLoader] = useState(false)
    const dispatch = useDispatch();
    const [validationError, setValidationError] = useState(null);

    const onCreateFreeItems = () => {
        const params = cloneDeep(freeItemsObject);
        params.paidItemSku = params.sku;
        delete params.sku;
        const offers = { ...params.offer }
        if (!modalData.id && productType === '') {
            setValidationError('Please choose product type')
            return;
        }
        if (params.paidItemSku === 0) {
            setValidationError('Please select a Product');
            return;
        }

        if (!offers.validFrom) {
            setValidationError('Please choose valid from date.');
            return;
        }

        if (!offers.expiryOn) {
            setValidationError('Please choose valid to date.');
            return;
        }
        if (moment(offers.validFrom).isAfter(moment(offers.expiryOn))) {
            setValidationError('Valid from date cannot be before valid to date.');
            return;
        }
        if (moment(offers.validFrom).isSameOrAfter(moment(offers.expiryOn))) {
            setValidationError('Valid start date time cannot be same as end date time.');
            return;
        }
        if (freeItemsObject.freeItemSkusWithQuantities.some(item => item.errorMessage)) {
            setValidationError('Please check the free items product list.');
            return;
        }
        if (freeItemsObject?.freeItemSkusWithQuantities?.length >= 11) {
            setValidationError("Free item must be EQUAL TO or LESS THAN 10");
            return;
        }


        if (freeItemsObject.freeItemSkusWithQuantities.some(item => !item?.editQuantity ||
            item?.editQuantity === 0)
        ) {
            setValidationError('Please check the free items quantity list.');
            return;
        }

        if (freeItemsObject.freeItemSkusWithQuantities.some(item => item.sku === params.paidItemSku)) {
            setValidationError('Please use multi buy to create same product offer');
            return;
        }


        const items = [...freeItemsObject.freeItemSkusWithQuantities];
        params.offer = offers;
        const filterFreeItems = items.filter(s => s.sku !== '');
        const freeItemSkusWithQuantities = filterFreeItems.map(d => ({ sku: d.sku, quantity: d.editQuantity, bistroProduct: d?.bistroProduct }))

        if (freeItemSkusWithQuantities?.length === 0) {
            setValidationError('Please add free items');
            return;
        }


        params.freeItemSkusWithQuantities = freeItemSkusWithQuantities;
        params.active = true;
        delete params.product;
        let startDate = UTCViewFormat(offers?.validFrom)
        let endDate = UTCViewFormat(offers?.expiryOn)
        if (modalData?.sku) {
            const { bistroProduct } = params || {}
            params.bistroProduct = bistroProduct
        } else {
            params.bistroProduct = productType === 'bistro' ? true : false
        }
        params.startDateTimeString = startDate
        params.endDateTimeString = endDate
        let createOrUpdate = Actions.createFreeItemsList;
        if (modalData?.sku) {
            createOrUpdate = Actions.updateFreeItemsList;
        }
        setLoader(true)
        dispatch(createOrUpdate(params, (resp) => {
            if (resp.response_code === 0) {
                setLoader(false)
                onClose(true)
            } else {
                setValidationError(resp.response_message)
                setLoader(false)
            }
        }));
    }

    const offerQuantity = (value, index) => {
        const dataObj = { ...freeItemsObject };
        const dataList = [...dataObj.freeItemSkusWithQuantities];
        dataList[index].editQuantity = parseInt(value, 10);
        dataObj.freeItemSkusWithQuantities = dataList;
        setFreeItemsObject(dataObj);
    }


    const addProducts = (value, index) => {
        const dataObj = { ...freeItemsObject };
        const dataList = dataObj?.freeItemSkusWithQuantities ?
            [...dataObj.freeItemSkusWithQuantities] : [];

        if (!value.data) {
            dataList[index] = { ...DEFAULT_FREE_ITEM };
            dataObj.freeItemSkusWithQuantities = [...dataList];
            setFreeItemsObject(dataObj);
            return;
        }

        value.data.editQuantity = 1;
        if (dataList.some(d => d.sku === value.data.sku)) {
            value.data.errorMessage = 'Item alreadyselected.'
        } else {
            value.data.errorMessage = null;
        }
        value.data.bistroProduct = productType === 'bistro' ? true : false
        dataList[index] = value.data;
        if (!dataList[index + 1] && dataList.length < 10)
            dataList.push({ ...DEFAULT_FREE_ITEM });
        dataObj.freeItemSkusWithQuantities = [...dataList];
        setFreeItemsObject(dataObj);
    }

    const onClickRemove = useCallback((index) => {
        const dataObj = { ...freeItemsObject };
        const dataList = dataObj?.freeItemSkusWithQuantities ? [...dataObj.freeItemSkusWithQuantities] : [];
        dataList.splice(index, 1);
        dataObj.freeItemSkusWithQuantities = dataList;
        setFreeItemsObject(dataObj);
    }, [freeItemsObject, setFreeItemsObject]);

    const selectMainProduct = (value) => {
        const id = value?.data?.sku || 0;
        setFreeItemsObject({
            ...freeItemsObject,
            sku: id
        })
    }
    const selectedProductType = (obj) => {
        setProductType(obj)
        setFreeItemsObject({
            ...freeItemsObject,
            sku: 0
        })
    }
    return <>
        <div className="modal dg-block" id="add-free-modal" tabIndex="-1" aria-labelledby="add-free-modal" aria-hidden="true">
            {loader && < AppLoader />}
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content rounded-0">

                    <img id='onClose' src={Close} alt="X" onClick={() => onClose(false)} data-bs-dismiss="modal" aria-label="Close" className="modal-close" />

                    <div className="modal-header rounded-0 align-items-center py-4">
                        <div className="col"> <h5 className="modal-title">{modalData.id ? modalData.viewMode ? "Free Items" : " Edit Free Item" : "Add Free Item"}</h5> </div>
                    </div>
                    <div className="modal-body font13 p-40">
                        <BoldError message={validationError} />
                        {(!modalData.id && !modalData.viewMode) &&
                            <div className="row mb-4">
                                <div className="col">
                                    <label for="category" className="form-label fw-bold">Product Type</label>
                                    <select value={productType} id="category" name="" className="form-select"
                                        onChange={(e) => {
                                            selectedProductType(e?.target?.value);
                                            setFreeItemsObject(inititlizeData);
                                        }
                                        }>
                                        <option value=""> Select Product Type </option>
                                        {
                                            Constants.PRODUCT_TYPE.map((o) => {
                                                return (
                                                    <option value={o.value}>{o.KEY}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>}

                        {(modalData.id) &&
                            <div className="row mb-3">
                                <div className="col-3 fw-bold">Product name</div>
                                <div className="col-9"> {modalData?.productName?.toLowerCase()}</div>
                            </div>}
                        {(!modalData.id && !modalData.viewMode) &&
                            <div className="row mb-4">
                                <div className="col">
                                    <label for="main-product"
                                        className="form-label fw-bold">Select Product</label>
                                    <ProductsTypeAhead
                                        key={productType}
                                        id="selectMainProduct"
                                        defaultOptions={optionsData}
                                        defaultValue={optionsData}
                                        productType={productType}
                                        placeholder="Select Products"
                                        onChange={selectMainProduct}
                                    />
                                </div>
                            </div>}

                        <div className="row mb-4">
                            <div className="col-12 col-sm-6">
                                <label for="from-date" className="form-label fw-bold">Valid From</label>
                                <AppDatePicker
                                    id="AppDatePicker"
                                    placeholder="Valid From"
                                    className="form-control cursor-pointer"
                                    minDate={new Date()}
                                    selected={freeItemsObject.offer.validFrom}
                                    onChange={(value) => setFreeItemsObject({
                                        ...freeItemsObject,
                                        offer: {
                                            ...freeItemsObject.offer,
                                            validFrom: value
                                        }
                                    })}
                                />
                            </div>
                            <div className="col-6">
                                <label for="to-date" className="form-label fw-bold">Valid To</label>
                                <AppDatePicker
                                    id="EditAppDatePicker"
                                    placeholder="Valid To"
                                    className="form-control cursor-pointer"
                                    selected={freeItemsObject.offer.expiryOn}
                                    minDate={freeItemsObject?.offer?.validFrom ? freeItemsObject?.offer?.validFrom : new Date()}
                                    onChange={(value) => setFreeItemsObject({
                                        ...freeItemsObject,
                                        offer: {
                                            ...freeItemsObject.offer,
                                            expiryOn: value
                                        }
                                    })}
                                />
                            </div>
                        </div>

                        {
                            freeItemsObject?.sku && freeItemsObject?.sku !== 0 ? <>
                                <h4 className="font13 fw-bold mt-5 text-uppercase">Free Items</h4>

                                <hr className="thick" />
                                {!modalData.viewMode && !modalData.id &&
                                    <div className="row align-items-center mb-3 fw-bold">
                                        <div className="col-auto"> # </div>
                                        <div className="col-auto ps-0"> Image </div>
                                        <div className="col px-0"> Select Free Item </div>
                                        <div className="col-auto" >Quantity </div>
                                        <div className="col-auto px-0"> &nbsp; </div>
                                    </div>
                                }
                                {freeItemsObject?.freeItemSkusWithQuantities?.map((products, index) => {
                                    return (<>

                                        {(modalData.id) &&
                                            <div className="row align-items-center mb-3">
                                                <div className="col-auto fw-bold">{index + 1}.</div>
                                                {/* <div className="col-auto">
                                                    <img
                                                        src={products?.imageUrl ? products?.imageUrl : placeholderImage}
                                                        width="32" alt="products" />
                                                </div> */}
                                                <div className="col fw-bold"> {products?.productName?.toLowerCase()} <small className="fw-normal">(SKU {products?.sku})</small> - {products?.editQuantity}</div>
                                            </div>
                                        }

                                        {(!modalData.viewMode && !modalData.id) && <>
                                            <div key={index + 1 !== freeItemsObject?.freeItemSkusWithQuantities.length ? products.sku : index}
                                                className="row align-items-center mb-3">
                                                <div className="col-auto fw-bold">{index + 1}.</div>
                                                <div className="col-auto ps-0 text-center">
                                                    <img
                                                        src={products?.imageUrl ? products?.imageUrl : placeholderImage}
                                                        width="32"
                                                        alt="products" />
                                                </div>
                                                {modalData.viewMode ?
                                                    <div className="col px-0">
                                                        <select id="product-list" value={products.productName} name="" className="form-select">
                                                            <option selected value={products.productName}>{products.productName}</option>
                                                        </select>
                                                    </div> : null}
                                                {!modalData.viewMode &&
                                                    <div className="col px-0">
                                                        <ProductsTypeAhead
                                                            id="ProductsTypeAhead"
                                                            key={productType}
                                                            defaultOptions={products?.productName ? [{ label: products?.productName, value: products?.sku, data: products }] : null}
                                                            defaultValue={products?.productName ? [{ label: products?.productName, value: products?.sku, data: products }] : null}
                                                            productType={productType}
                                                            placeholder="Select Free Items"
                                                            isDisabled={modalData.id ? true : false}
                                                            onChange={v => addProducts(v, index)}
                                                            skuId={freeItemsObject?.sku}
                                                        />
                                                    </div>}
                                                {
                                                    modalData?.viewMode ?
                                                        <div className="col-auto">
                                                            <select id="free-q1"
                                                                value={products?.editQuantity} name="" className="form-select">
                                                            </select>
                                                        </div> :
                                                        <div className="col-auto">
                                                            <div className="col-auto">
                                                                <select
                                                                    id="free-q2"
                                                                    onChange={(e) => offerQuantity(e.target.value, index)}
                                                                    name="" className="form-select">
                                                                    <option value="1">1</option>
                                                                    <option value="2">2</option>
                                                                    <option value="3">3</option>
                                                                    <option value="4">4</option>
                                                                    <option value="5">5</option>
                                                                    <option value="6">6</option>
                                                                    <option value="7">7</option>
                                                                    <option value="8">8</option>
                                                                    <option value="9">9</option>
                                                                    <option value="10">10</option>
                                                                </select>
                                                            </div>
                                                            {/* <InputField
                                                                id="offerQuantity"
                                                                type="number"
                                                                value={products?.editQuantity}
                                                                disabled={modalData.viewMode || modalData.id}
                                                                onChange={(value) => offerQuantity(value, index)}
                                                            /> */}
                                                            {/* <div style={{ position: 'absolute' }}>
                                                                {!products?.editQuantity ||
                                                                    products?.editQuantity === 0 ? <p className="errorMsg">Invalid quantity</p> : null}
                                                            </div> */}
                                                        </div>
                                                }
                                                {index !== 0 &&
                                                    <div id="onDataRemove" className="col-auto fw-bold px-0 cursor-pointer" onClick={() => onClickRemove(index)}> <span className="text-dark">X</span></div>
                                                }
                                            </div>
                                        </>}
                                    </>
                                    )
                                })}

                            </> : null}
                        {!modalData.viewMode && <>
                            <div className="row mt-5 mb-4">
                                <div id="onCreateFree" className="col-7 pe-0" onClick={() => onCreateFreeItems()}> <span className="btn btn-success font14 w-100 p-3 cursor-pointer" > {!modalData?.sku ? "Create" : "Update"}</span> </div>
                                <div id='onCloseonFreeItems' className="col-5 text-end" onClick={() => onClose(false)}> <span className="btn btn-outline-danger font14 w-100 p-3 cursor-pointer" > Cancel</span> </div>
                            </div>
                        </>}
                    </div>

                </div>
            </div>
        </div>
        <div className="modal-backdrop show"></div>
    </>

}


export default FreeItemsModal;