import { all, put, takeEvery } from "redux-saga/effects";
import { doGET, doPost, doPut } from "../../utils/service";
import END_POINTS from "../../utils/service/Endpoints";
import Actions from "../actions";

export function* adminUsersSaga({params}){
    try{
        const response=yield doGET(END_POINTS.GET_ADMIN_USERS,params)
        if(response){
            yield put(Actions.getAllBranchListSuccessAction(response))
        }
    }catch(error){
        yield put(Actions.getAllBranchListFailureAction(error))
    }
}
export function* createAdminUsersSaga({params,callback}){
    try{
        const response=yield doPost(END_POINTS.ADD_ADMIN_USER,params)
        if(response){
            yield put(Actions.createBranchListActionSuccess(response.response))
            callback && callback(response)
        }
    }catch(error){
        yield put(Actions.createBranchListActionFailure(error))
    }
}
export function* updateAdminUsersSaga({params,callback}){
    try{
        const response=yield doPut(END_POINTS.UPDATE_ADMIN_USER,params)
        if(response){
            yield put(Actions.updateBranchListActionSuccess(response.response))
            callback && callback(response)
        }
    }catch(error){
        yield put(Actions.createBranchListActionFailure(error))
    }
}
export function* updateAdminActiveStatusSaga({params,callback}){
    try{
        const response=yield doPut(END_POINTS.UPDATE_ADMIN_ACTIVE_STATUS,params)
        if(response){
            yield put(Actions.updateAdminActiveStatusSuccess(response.response))
            callback && callback()
        }
    }catch(error){
        yield put(Actions.updateAdminActiveStatusFailure(error))
    }
}
export function* updateAdminPasswordSaga({params,callback}){
    try{
        const response=yield doPut(END_POINTS.UPDATE_ADMIN_PASSWORD,params)
        if(response){
            yield put(Actions.updatePasswordActionSuccess(response.response))
            callback && callback(response)
        }
    }catch(error){
        yield put(Actions.updatePasswordActionFailure(error))
        callback && callback(error)
    }
}
export default function* branchListListener(){
    yield all([
        takeEvery(Actions.BRANCH_LIST,adminUsersSaga),
        takeEvery(Actions.CREATE_BRANCH_LIST,createAdminUsersSaga),
        takeEvery(Actions.UPDATE_BRANCH_LIST,updateAdminUsersSaga),
        takeEvery(Actions.UPDATE_ADMIN_ACTIVE_STATUS,updateAdminActiveStatusSaga),
        takeEvery(Actions.UPDATE_ADMIN_PASSWORD,updateAdminPasswordSaga),
    ])
}