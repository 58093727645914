import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ModalPopup, { ModalFooter, ModalHeader } from "../../components/modal";
import StoreTypeAhead from "./StoreTypeAhead";
import Action from "../../redux/actions";
import { getSelectedStoreData } from "../../redux/reducers/StoreReducer";
class StorePopUp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedStore: {},
            errorMessage: "",
            defaultSelectedStore: "",
            isOpenConfirmationModal: false,
            buttonDisable: true
        };
    }
    componentDidMount() {
        const selectedStoreList = this.props.getSelectedStoreList || {};
        this.setState({ selectedStore: selectedStoreList })
    }
    onCloseModal() {
        this.setState({ isOpenConfirmationModal: false })
    }
    selectedStoreData = (value) => {
        const selectedStoreList = this.props.getSelectedStoreList || {};
        if (!value.hasOwnProperty("label")) {
            this.setState({ errorMessage: "Please select store" })
            this.setState({ buttonDisable: true })
        } else if (selectedStoreList?.value === value?.value) {
            this.setState({ errorMessage: "You are selecting the same store" })
            this.setState({ buttonDisable: true })
        } else {
            this.setState({ errorMessage: "" })
            this.setState({ buttonDisable: false })
        }
        this.setState({ selectedStore: value });
    }

    sendSelectedStore = () => {
        const selectedStoreList = this.props.getSelectedStoreList || {};
        const { selectedStore } = this.state;
        if (selectedStore.hasOwnProperty("label")) {
            this.props.selectedStoreList(selectedStore);
            this.props.setStoreInformation(selectedStore?.data)
            this.props.onCloseModal();
            this.props.selectedChatAction({})
            this.props.clearChatMessagesList()
            setTimeout(() => {
                window.location.reload();
            }, 500);
        }
        else if (!selectedStore) {
            this.setState({ errorMessage: "Please select store" })
        } else if (!selectedStore.hasOwnProperty("label")) {
            this.setState({ errorMessage: "Please select store" })
        } else if (selectedStoreList) {
            this.props.onCloseModal();
        }
    };
    render() {
        const { isModalOpen, onCloseModal } = this.props;
        const selectedStoreList = this.props.getSelectedStoreList || {};
        const { errorMessage, buttonDisable } = this.state;
        return (
            <React.Fragment>
                {isModalOpen && <ModalPopup id="ModalPopup" onCloseModal={onCloseModal}>
                    <ModalHeader title="Select Store" />
                    {errorMessage && (
                        <div className="alert alert-danger" role="alert">
                            {errorMessage}
                        </div>
                    )}
                    <div className="row mb-4">
                        <div className="col">
                            <label for="main-product"
                                className="form-label fw-bold"></label>
                            <StoreTypeAhead
                                // defaultOptions={optionsData}
                                defaultValue={selectedStoreList}
                                placeholder="Select Store"
                                onChange={this.selectedStoreData}
                            />
                        </div>
                    </div>

                    <ModalFooter onSuccess={() => this.sendSelectedStore()} buttonName="PROCEED" buttonDisable={buttonDisable} />
                </ModalPopup>
                }
            </React.Fragment>
        );
    }
}

export const mapStateToProps = (state) => {
    return {
        getSelectedStoreList: getSelectedStoreData(state),
    };
};

export default withRouter(
    connect(mapStateToProps, {
        selectedStoreList: Action.setSelectedStore,
        setStoreInformation: Action.setStoreInformation,
        selectedChatAction:Action.selectedChatAction,
        clearChatMessagesList:Action.clearChatMessagesList
    })(StorePopUp)
);