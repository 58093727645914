import Constants from "../Constants"
import moment from 'moment'
export const ShowDateMoment = (date) => {
    return (moment(moment().format('YYYY-MM-DD')).isSame(
        moment(date).format(
            'YYYY-MM-DD',
        ),
    ) &&
        moment
            .utc(date)
            .local()
            .startOf('seconds')
            .fromNow()) ||
        (moment(
            moment().subtract(1, 'day').format('YYYY-MM-DD'),
        ).isSame(
            moment(date).format(
                'YYYY-MM-DD',
            ),
        ) &&
            Constants.CHAT.STRING_YESTERDAY) ||
        moment(date).format(
            'DD-MMM | h:mm a',
        )
}