import { all, put, takeLatest } from "redux-saga/effects";
import { doPromotionGet, doPromotionPost } from "../../utils/service";
import END_POINTS from "../../utils/service/Endpoints";
import Actions from "../actions";

export function* getHappyHoursItemListSaga({ params, callback }) {
    try {
        let response = yield doPromotionGet(END_POINTS.HAPPY_HOURS_ITEM, params)
        if (response) {
            yield put(Actions.getHappyHoursItemListSuccess(response));
        } else {
            put(Actions.getHappyHoursItemListFailure({ error: 'No Data found' }))
        }
        if (callback) {
            callback(response);
        }
    } catch (error) {
        yield put(Actions.getHappyHoursItemListFailure({ error }))
    }
}
export function* createHappyHoursItemListSaga({ params, callback }) {
    try {
        let response = yield doPromotionPost(END_POINTS.CREATE_HAPPY_HOURS_ITEM, params)
        if (response) {
            yield put(Actions.createHappyHoursItemListSuccess(response));
        } else {
            put(Actions.createHappyHoursItemListFailure({ error: 'No Data found' }))
        }
        if (callback) {
            callback(response);
        }
    } catch (error) {
        yield put(Actions.createHappyHoursItemListFailure({ error }))
    }
}
export function* updateHappyHoursItemLListSaga({ params, callback }) {
    try {
        let response = yield doPromotionPost(END_POINTS.UPDATE_HAPPY_HOURS_ITEM, params)
        if (response) {
            yield put(Actions.updateHappyHoursItemListSuccess(response));
        }
        

        if (callback) {
            callback(response);
        }
    } catch (error) {
        yield put(Actions.updateHappyHoursItemListFailure({ error }))
    }
}
export function* deleteHappyHoursItemLListSaga({ params, callback }) {
    try {
        const response = yield doPromotionPost(params);
        if (response) {
            yield put(Actions.deleteHappyHoursItemListSuccess(response));
        }
        callback && callback(response);
    }
    catch (error) {
        callback && callback(error);
        yield put(Actions.deleteHappyHoursItemListFailure({ error }));
    }
}
export default function* HappyHoursItemListener() {
    yield all([
        takeLatest(Actions.HAPPY_HOURS_ITEM, getHappyHoursItemListSaga),
        takeLatest(Actions.HAPPY_HOURS_ITEM_CREATE, createHappyHoursItemListSaga),
        takeLatest(Actions.HAPPY_HOURS_ITEM_UPDATE, updateHappyHoursItemLListSaga),
        takeLatest(Actions.HAPPY_HOURS_ITEM_DELETE, deleteHappyHoursItemLListSaga),



    ]);
}