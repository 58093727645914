import Actions from '../../actions';
const initialState = {
    isLoading: false,
    PlanogramDetails: {},
    error: false,
}
const getCvPlanogramDetailsReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.GET_CV_PLANOGRAM_DETAILS: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.GET_CV_PLANOGRAM_DETAILS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                PlanogramDetails: action.data,
                error: false
            };
        }
        case Actions.GET_CV_PLANOGRAM_DETAILS_FAILURE: {
            return {
                ...state,
                isLoading: false,
                PlanogramDetails: {},
                error: action.error,
            };
        }

        case Actions.GET_PLANOGRAM_ANALYTICS: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.GET_PLANOGRAM_ANALYTICS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                planogramAnalytics: action.data?.response || {},
                error: false
            };
        }
        case Actions.GET_PLANOGRAM_ANALYTICS_FAILURE: {
            return {
                ...state,
                isLoading: false,
                planogramAnalytics: {},
                error: action.error,
            };
        }

        default:
            return state;
    }
};
export const getPlanogramDetails = (state) => state?.GET_CV_PLANOGRAM_DETAILS?.PlanogramDetails
export const getRackDetailsLoader = (state) => state?.GET_CV_PLANOGRAM_DETAILS?.isLoading
export const getPlanogramAnalytics = (state) => state?.GET_CV_PLANOGRAM_DETAILS?.planogramAnalytics

export default getCvPlanogramDetailsReducer;