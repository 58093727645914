import React from "react";
import { withRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import ModalPopup from "../components/modal";
import { convertPlanogramFormat, convertStorePlanogramFormat } from "../utils/commonFunctions/Planogram";
import AppLoader from "./fields/AppLoader";

class Planogram3DModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: props.isModalOpen
        };
    }

    componentDidMount() {
        setTimeout(() => {
            const { Rack, Store } = this.props;
            if (Rack) {
                document.getElementById('iframe_large').contentWindow.postMessage({ message: convertPlanogramFormat(Rack) }, "*")
            } else if (Store) {
                document.getElementById('iframe_large').contentWindow.postMessage({ message: convertStorePlanogramFormat(Store) }, "*")
            }
        }, 1000)
    }

    render() {
        const { isModalOpen, onCloseModal, srcUrl } = this.props;
        const { isLoading } = this.state
        return (
            <React.Fragment>
                {isModalOpen && <ModalPopup className="modal-xl planogram-modal" onCloseModal={onCloseModal} >
                    <div className="row">
                        <div className="col-sm-12">
                            <div id='planogram-3d-view-large' className='rack-canvas'>
                                <iframe id="iframe_large" onLoad={() => this.setState({ isLoading: false })} src={srcUrl} style={{ width: '100%', height: '100%', boxShadow: 'rgb(0 0 0 / 16%) 0px 1px 4px' }}></iframe>
                            </div>
                        </div>
                    </div>
                </ModalPopup>
                }
                {
                    isLoading && <AppLoader />
                }
            </React.Fragment>
        );
    }
}

export const mapStateToProps = (state) => {
    return {
    };
};

export default withRouter(connect(mapStateToProps, {})(Planogram3DModal));