import cloneDeep from 'lodash.clonedeep';
import Actions from '../actions'
const initialState = {
    chatMessages: {},
    isLoading: false,
    error: false,
    allChatsList: [],
    allChatsError: null,
    selectedChat: null
}
const ChatsReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.GET_CHAT_MESSAGES_LIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.GET_CHAT_MESSAGES_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                chatMessages: action.data,
                error: false
            };
        }
        case Actions.GET_CHAT_MESSAGES_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case Actions.GET_ALL_CHATS_LIST:
            return {
                ...state,
                isAllChatsLoading: true
            }
        case Actions.GET_ALL_CHATS_LIST_SUCCESS:
            return {
                ...state,
                allChatsList: action.data,
                isAllChatsLoading: false
            }
        case Actions.GET_ALL_CHATS_LIST_FAILURE:
            return {
                ...state,
                allChatsError: action.error,
                isAllChatsLoading: false
            }
        case Actions.SAVE_SELECTED_CHAT:
            return {
                ...state,
                selectedChat: action.data
            }
        case Actions.CLEAR_CHAT_MESSAGES:
            return {
                ...state,
                chatMessages: {}
            }
        case Actions.LOGOUT: {
            return {
                ...initialState
            };
        }
        default:
            return state;
    }
};


export const updateChatObjectSelector = (state) => {
    return state?.CHATS?.chatMessages || {}
};

export const getChatObjectSelector = (state) => {
    const data = state?.CHATS?.chatMessages;
    if (data?.chatMessages) {
        const newRefData = cloneDeep(data);
        newRefData.chatMessages.reverse();
        return newRefData;
    }
};
export default ChatsReducer;

export const isChatListLoadingSelector = (state) => state?.CHATS?.isLoading;

export const getAllChatsListSelector = state => state?.CHATS?.allChatsList || [];
export const getIsAllChatsLoadingSelector = s => s?.CHATS?.isAllChatsLoading;

export const getSelectedChatSelector = state => state?.CHATS?.selectedChat;