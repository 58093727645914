import { doAction, doResponseAction } from ".";

const GET_DAXBOT = 'GET_DAXBOT';
const GET_DAXBOT_SUCCESS = 'GET_DAXBOT_SUCCESS';
const GET_DAXBOT_FAILURE = 'GET_DAXBOT_FAILURE'

const TRACK_DAXBOT = 'TRACK_DAXBOT';
const TRACK_DAXBOT_SUCCESS = 'TRACK_DAXBOT_SUCCESS';
const TRACK_DAXBOT_FAILURE = 'TRACK_DAXBOT_FAILURE'
export {
    GET_DAXBOT,
    GET_DAXBOT_SUCCESS,
    GET_DAXBOT_FAILURE,
    TRACK_DAXBOT,
    TRACK_DAXBOT_SUCCESS,
    TRACK_DAXBOT_FAILURE
}

export const getDaxbotAction = (params, callback) => doAction(GET_DAXBOT, params, callback);
export const getDaxbotActionSuccess = (data) => doResponseAction(GET_DAXBOT_SUCCESS, data);
export const getDaxbotActionFailure = (data) => doResponseAction(GET_DAXBOT_FAILURE, data);

export const trackDaxbotAction = (params, callback) => doAction(TRACK_DAXBOT, params, callback);
export const trackDaxbotActionSuccess = (data) => doResponseAction(TRACK_DAXBOT_SUCCESS, data);
export const trackDaxbotActionFailure = (data) => doResponseAction(TRACK_DAXBOT_FAILURE, data);

