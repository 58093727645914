import Actions from "../actions";

const initialState = {
    isLoading: false,
    comboOfferList: [],
    error: false,
    createComboOfferList: [],
    deleteComboOffer: [],
    createLoading: false,
    updateLoading: false,
    createError: false,
    updateError: false,
    deleteLoading: false,
    deleteError: false,
    totalCount: undefined
}

const ComboOfferReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.COMBO_OFFER: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.COMBO_OFFER_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                comboOfferList: action?.data?.response || [],
                totalCount: action.data?.totalPages,
                error: false
            };

        }

        case Actions.COMBO_OFFER_FAILURE: {
            return {
                ...state,
                isLoading: false,
                comboOfferList: [],
                error: action.error,
            };
        }
        case Actions.COMBO_OFFER_CREATE: {
            return {
                ...state,
                createLoading: true,
            };
        }
        case Actions.COMBO_OFFER_CREATE_SUCCESS: {
            return {
                ...state,
                createLoading: false,
                createComboOfferList: action?.data?.response || [],
                createError: false
            };

        }

        case Actions.COMBO_OFFER_CREATE_FAILURE: {
            return {
                ...state,
                createLoading: false,
                createComboOfferList: [],
                createError: action.data,
            };
        }
        case Actions.COMBO_OFFER_UPDATE: {
            return {
                ...state,
                updateLoading: true,
            }
        }
        case Actions.COMBO_OFFER_UPDATE_SUCCESS: {
            return {
                ...state,
                updateLoading: false,
            }
        }
        case Actions.COMBO_OFFER_UPDATE_FAILURE: {
            return {
                ...state,
                updateLoading: false,
                updateError: action.data
            }
        }
        case Actions.DELETE_COMBO_OFFER: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.DELETE_COMBO_OFFER_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                deleteError: false
            };
        }
        case Actions.DELETE_COMBO_OFFER_FAILURE: {
            return {
                ...state,
                isLoading: false,
                deleteError: action.data,
            };
        }
        default:
            return state;
    }
};

export default ComboOfferReducer;

export const getComboOfferListSelector = state => state?.COMBO_OFFER?.comboOfferList;
export const getComboOfferCountSelector = state => state?.COMBO_OFFER?.totalCount;
export const getComboOfferListLoadingSelector = state => state?.COMBO_OFFER?.isLoading;
export const getComboOfferCreateLoadingSelector = state => state?.COMBO_OFFER?.createLoading;