import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import BoldError from '../../components/fields/BoldError';
import Actions from '../../redux/actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import PasswordInput from '../../components/fields/PasswordInput';
import Constants from '../../utils/Constants';


const ChangePassword = () => {
    const dispatch = useDispatch()
    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [validationerror, setValidationError] = useState('')
    const [showPassword, setShowPassword] = useState(false)

    const initialState = () => {
        setOldPassword('')
        setNewPassword('')
        setConfirmPassword('')
        setValidationError('')
    }
    const updatePassword = () => {
        if (!oldPassword) {
            setValidationError('Please enter current password.')
        } else if (oldPassword.length < 5) {
            setValidationError('Old Password must be more than 5 characters')
        } else if (!newPassword) {
            setValidationError('Please enter new password.')
        } else if (newPassword.length < 5) {
            setValidationError('New Password must be more than 5 characters')
        } else if (!confirmPassword) {
            setValidationError('Please enter confirm password.')
        } else if (confirmPassword.length < 5) {
            setValidationError('Confirm Password must be more than 5 characters')
        } else if (confirmPassword !== newPassword) {
            setValidationError('Password does not match')
        }
        else {
            const params = {
                oldPassword,
                newPassword,
                type: 2
            }
            const callback = (res) => {
                if (res.response_code === 0) {
                    initialState();
                    console.log(oldPassword, "oldPasswordoldPasswordoldPassword");
                    toast.success('Password got updated')
                    const { response } = res;
                    const { access_token } = response || {}
                    if (response?.access_token) {
                        const data = JSON.stringify(response)
                        let expiration_date = new Date();
                        expiration_date.setMonth(expiration_date.getMonth() + 1);
                        document.cookie = `token=${data};domain=${Constants.COMMON_APP_DOMAINS.cookieDomain};path=/;Expires=${expiration_date}`
                        document.cookie = `access_token=${response.access_token};domain=${Constants.COMMON_APP_DOMAINS.cookieDomain};path=/; Expires=${expiration_date};`
                        document.cookie = `refresh_token=${response.refresh_token};domain=${Constants.COMMON_APP_DOMAINS.cookieDomain};path=/;Expires=${expiration_date}`
                    }
                }
                else {
                    toast.error(res)
                }

            }
            dispatch(Actions.changePasswordAction(params, callback));
        }
    }
    return (
        <>
            <div className="col ps-lg-4">
                <div className="row mb-4">
                    <div className="col"> <h1 className="font20 fw-bold">Settings</h1> </div>
                </div>
                <div className="card card-body rounded-3 border-0 shadow p-4 mb-3 mb-xl-0">
                    <span><b>Change password</b></span>
                    <div className="row mt-2">
                        <div className="col col-md-4">
                            {validationerror && <BoldError message={validationerror} />}

                            <div className="input-group mb-4">
                                <PasswordInput
                                    className="form-control"
                                    showPassword={showPassword}
                                    name="password"
                                    id='setOldPassword'
                                    value={oldPassword}
                                    onChange={(e) => setOldPassword(e)}
                                    placeholder="Enter current password"
                                    aria-describedby="view-pass" />
                                <button className="btn border-dark"
                                    id='setEyeOldPassword'
                                    onClick={() => setShowPassword(!showPassword)}
                                    type="button"
                                >
                                    <FontAwesomeIcon
                                        style={{ cursor: 'pointer' }}
                                        icon={showPassword ? faEye : faEyeSlash} />
                                </button>
                            </div>
                            <div className="input-group mb-4">
                                <PasswordInput
                                    id='setNewPassword'
                                    className="form-control"
                                    showPassword={showPassword}
                                    name="password"
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e)}
                                    placeholder="Enter new password"
                                    aria-describedby="view-pass" />
                                <button className="btn border-dark"
                                    onClick={() => setShowPassword(!showPassword)}
                                    type="button"
                                    id="setEyeNewPassword">
                                    <FontAwesomeIcon
                                        style={{ cursor: 'pointer' }}
                                        icon={showPassword ? faEye : faEyeSlash} />
                                </button>
                            </div>
                            <div className="input-group mb-4">
                                <PasswordInput
                                    id='setConfirmPassword'
                                    className="form-control"
                                    showPassword={showPassword}
                                    name="password"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e)}
                                    placeholder="Confirm new password"
                                    aria-describedby="view-pass" />
                                <button className="btn border-dark"
                                    onClick={() => setShowPassword(!showPassword)}
                                    type="button"
                                    id="setEyeConfirmPassword">
                                    <FontAwesomeIcon
                                        style={{ cursor: 'pointer' }}
                                        icon={showPassword ? faEye : faEyeSlash} />
                                </button>
                            </div>
                        </div>

                    </div>
                    <div className="row mb-3">
                        <div className="col col-md-5">
                            <button id='updatePassword'
                                className="btn btn-dark big-btn text-uppercase"
                                onClick={() => updatePassword()}>Update Password</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ChangePassword;