import React from "react";
import Close from '../../../../asset/new/img/modal-close.svg'
import { UTCDateFormat } from "../../../utils/commonFunctions/dateFormat";

const CustomerDetailModal = ({ isCloseModal, customerCouponDetails }) => {

    const { customers } = customerCouponDetails || {}
    const allCustomers = customers && customers.map(o => `${o?.customer?.firstName} ${o?.customer?.lastName}`).join(', ');
    return <React.Fragment><div className="modal d-block"
        id="unblock-modal"
        tabIndex="-1"
        aria-labelledby="unblock"
        aria-hidden="true">
        <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content rounded-0">

                <img src={Close} alt="X" data-bs-dismiss="modal" aria-label="Close" id="Close" className="modal-close" onClick={() => isCloseModal(false)} />

                <div className="modal-header rounded-0 align-items-center py-4">
                    <div className="col">
                        <h5 className="modal-title">Customer Coupon ID #{customerCouponDetails?.id}</h5>
                    </div>
                </div>

                <div className="modal-body font14 p-40">

                    <div className="row mb-3">
                        <div className="col-4 fw-bold"> Offer Type </div>
                        <div className="col-8"> {customerCouponDetails.offerType} </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-4 fw-bold"> Coupon Value </div>
                        <div className="col-8">{customerCouponDetails?.offerType === "PERCENTAGE" ? `${customerCouponDetails?.value}%` : `$${parseFloat(customerCouponDetails?.value).toFixed(2)}`} </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-4 fw-bold"> Coupon Code </div>
                        <div className="col-8">{customerCouponDetails.couponCode}</div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-4 fw-bold"> Description </div>
                        <div className="col-8"> {customerCouponDetails.description} </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col-4 fw-bold"> Valid From: </div>
                        <div className="col-8"> {UTCDateFormat(customerCouponDetails?.startDateString)} </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-4 fw-bold">Valid To: </div>
                        <div className="col-8"> {UTCDateFormat(customerCouponDetails?.endDateString)} </div>
                    </div>
                    {
                        customerCouponDetails?.applicableForAllCustomers ? <div className="row mb-3">
                            <div className="col-4 fw-bold"> Customers </div>
                            <div className="col-8"> All </div>
                        </div> : <div className="row mb-3">
                            <div className="col-4 fw-bold"> Customers </div>
                            <div className="col-8">{allCustomers}</div>
                        </div>}

                    <div className="row my-4">
                        <div className="col"> <span data-bs-dismiss="modal" aria-label="Close"
                            className="btn btn-dark font14 w-100 p-2" id="isCloseModal"
                            onClick={() => isCloseModal(false)}> Close</span>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>
        <div className="modal-backdrop show"></div>

    </React.Fragment>
};

export default CustomerDetailModal;