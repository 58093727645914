import { doAction, doResponseAction } from ".";

const HAPPY_HOURS_ITEM = 'HAPPY_HOURS_ITEM';
const HAPPY_HOURS_ITEM_SUCCESS = 'HAPPY_HOURS_ITEM_SUCCESS';
const HAPPY_HOURS_ITEM_FAILURE = 'HAPPY_HOURS_ITEM_FAILURE'

const HAPPY_HOURS_ITEM_CREATE = 'HAPPY_HOURS_ITEM_CREATE';
const HAPPY_HOURS_ITEM_CREATE_SUCCESS = 'HAPPY_HOURS_ITEM_CREATE_SUCCESS';
const HAPPY_HOURS_ITEM_CREATE_FAILURE = 'HAPPY_HOURS_ITEM_CREATE_FAILURE';

const HAPPY_HOURS_ITEM_UPDATE = 'HAPPY_HOURS_ITEM_UPDATE';
const HAPPY_HOURS_ITEM_UPDATE_SUCCESS = 'HAPPY_HOURS_ITEM_UPDATE_SUCCESS';
const HAPPY_HOURS_ITEM_UPDATE_FAILURE = 'HAPPY_HOURS_ITEM_UPDATE_FAILURE';

const HAPPY_HOURS_ITEM_DELETE = 'HAPPY_HOURS_ITEM_DELETE';
const HAPPY_HOURS_ITEM_DELETE_SUCCESS = 'HAPPY_HOURS_ITEM_DELETE_SUCCESS';
const HAPPY_HOURS_ITEM_DELETE_FAILURE = 'HAPPY_HOURS_ITEM_DELETE_FAILURE';

export {
    HAPPY_HOURS_ITEM,
    HAPPY_HOURS_ITEM_SUCCESS,
    HAPPY_HOURS_ITEM_FAILURE,
    HAPPY_HOURS_ITEM_CREATE,
    HAPPY_HOURS_ITEM_CREATE_SUCCESS,
    HAPPY_HOURS_ITEM_CREATE_FAILURE,
    HAPPY_HOURS_ITEM_UPDATE,
    HAPPY_HOURS_ITEM_UPDATE_SUCCESS,
    HAPPY_HOURS_ITEM_UPDATE_FAILURE,
    HAPPY_HOURS_ITEM_DELETE,
    HAPPY_HOURS_ITEM_DELETE_SUCCESS,
    HAPPY_HOURS_ITEM_DELETE_FAILURE

}

export const getHappyHoursItemList = (params, callback) => doAction(HAPPY_HOURS_ITEM, params, callback);
export const getHappyHoursItemListSuccess = (data) => doResponseAction(HAPPY_HOURS_ITEM_SUCCESS, data);
export const getHappyHoursItemListFailure = (data) => doResponseAction(HAPPY_HOURS_ITEM_FAILURE, data);

export const createHappyHoursItemList = (params, callback) => doAction(HAPPY_HOURS_ITEM_CREATE, params, callback);
export const createHappyHoursItemListSuccess = (data) => doResponseAction(HAPPY_HOURS_ITEM_CREATE_SUCCESS, data);
export const createHappyHoursItemListFailure = (data) => doResponseAction(HAPPY_HOURS_ITEM_CREATE_FAILURE, data);

export const updateHappyHoursItemList = (params, callback) => doAction(HAPPY_HOURS_ITEM_UPDATE, params, callback);
export const updateHappyHoursItemListSuccess = data => doResponseAction(HAPPY_HOURS_ITEM_UPDATE_SUCCESS, data);
export const updateHappyHoursItemListFailure = data => doResponseAction(HAPPY_HOURS_ITEM_UPDATE_FAILURE, data);

export const deleteHappyHoursItemList = (params, callback) => doAction(HAPPY_HOURS_ITEM_DELETE, params, callback);
export const deleteHappyHoursItemListSuccess = (data) => doResponseAction(HAPPY_HOURS_ITEM_DELETE_SUCCESS, data);
export const deleteHappyHoursItemListFailure = (data) => doResponseAction(HAPPY_HOURS_ITEM_DELETE_FAILURE, data);