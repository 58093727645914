import moment from "moment"

export const DateFormat = (date) => {
    return moment(date).format('YYYY-MM-DD')
}
export const DateTimeFormat = (date) => {
    return moment(date).format('MM/DD/YYYY h:mm a')
}
export const DateRailwayTimeFormat = (date) => {
    return moment(date).format('MM/DD/YYYY HH:mm a')
}

export const UTCViewFormat = (date) => {
    return moment(date).format("yyyy-DD-MM HH:mm");
}
export const UTCDateFormat = (date) => {
    return moment(date, 'YYYY-DD-MM h:mm A').format("MM/DD/YYYY h:mm A");
}

export const UTCEditFormat = (date) => {
    return moment(date, 'YYYY-DD-MM HH:mm A').toDate()
}