const NAME = "FORGOT_PASSWORD";
export const REQUEST_OTP = `${NAME}_REQUEST_OTP`;
export const REQUEST_OTP_SUCCESS = `${NAME}_REQUEST_OTP_SUCCESS`;
export const REQUEST_OTP_FAILURE = `${NAME}_REQUEST_OTP_FAILURE`;


export const requestOtpAction = (data, callback) => {
    return {
        type: REQUEST_OTP,
        data,
        callback
    }
}

export const requestOtpSuccess = (response, data) => {
    return {
        type: REQUEST_OTP_SUCCESS,
        response,
        data
    }
}

export const requestOtpFailure = (error) => {
    return {
        type: REQUEST_OTP_FAILURE,
        error
    }
}