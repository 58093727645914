import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { PlanogramProductDraggable } from './PlanogramProduct';

// fake data generator
const getItems = list =>
    list?.map(k => ({
        ...k,
        id: `item-${k.id}`,
    }));

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    // padding: grid * 2,
    // margin: `0 ${grid}px 0 0`,

    // change background colour if dragging
    // background: isDragging ? 'lightgreen' : 'grey',

    // styles we need to apply on draggables
    ...draggableStyle,
});

const getListStyle = isDraggingOver => ({
    // background: isDraggingOver ? 'lightblue' : 'lightgrey',
    display: 'flex',
    // padding: grid,
    overflow: 'auto',
});

export class PlanogramDraggableList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: getItems(props.list),
            isFocused: false,
        };
        this.onDragEnd = this.onDragEnd.bind(this);
    }

    onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        const { list = [] } = this.props;
        const items = getItems(list)

        const reorderItems = reorder(
            items,
            result.source.index,
            result.destination.index
        )?.map((o) => ({ ...o, id: o.id.replace('item-', '') }));

        this.props.updateOrderOfTheItem(reorderItems)
    }

    // Normally you would want to split things out into separate components.
    // But in this example everything is just done in one place for simplicity
    render() {
        const { ProductComponent, list = [], onRemoveProduct } = this.props;
        const { isFocused } = this.state;
        const items = getItems(list)
        return (
            <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId="droppable" direction="horizontal">
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                            {...provided.droppableProps}
                        >
                            {items?.map((item, index) => (
                                <Draggable key={item.id} draggableId={item.id} index={index}>
                                    {(provided, snapshot) => (
                                        <PlanogramProductDraggable product={this.props.list[index]} provided={provided} snapshot={snapshot} getItemStyle={getItemStyle} item={item} ProductComponent={ProductComponent} index={index} onRemoveProduct={onRemoveProduct} />
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        );
    }
}
