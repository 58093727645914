import { all, put, takeLatest } from "redux-saga/effects";
import { doGet, doPost, doPut, } from "../../utils/service";
import END_POINTS from "../../utils/service/Endpoints";

import Actions from "../actions";

export function* getPlanogram({ params, callback }) {
    try {
        const response = yield doGet(END_POINTS.PLANOGRAM_DETAIL, params);
        if (response) {
            yield put(Actions.getPlanogramSuccessAction(response));
            if(callback) callback(response)
        } else {
            yield put(Actions.getPlanogramFailureAction({error: {}}));
            if(callback) callback({error: {}})
        }
    }
    catch (error) {
        yield put(Actions.getPlanogramFailureAction({ error }));
        if(callback) callback({error})
    }
}

export function* saveRackDetail({ params, callback }) {
    try {
        const response = yield doPost(END_POINTS.PLANOGRAM_ADD_RACK, params);
        if (response) {
            yield callback(response);
        } else {
            yield callback({ error: { message: 'Something went wrong' } });
        }
    }
    catch (error) {
        yield callback({ error });
    }
}

export function* getPlanogramDetail({ params, callback }) {
    try {
        const response = yield doGet(END_POINTS.VIEW_RACK, params);
        if (response) {
            yield put(Actions.getPlanogramDetailSuccess(response));
            callback && callback(response)
        } else {
            yield put(Actions.getPlanogramDetailFailure({ error: {} }));
            callback && callback({ error: {} })
        }
    }
    catch (error) {
        yield put(Actions.getPlanogramDetailFailure({ error }));
        callback && callback({ error })
    }
}

export function* saveBayDetail({ params, callback }) {
    try {
        const response = yield doPost(END_POINTS.PLANOGRAM_ADD_BAY, params);
        if (response) {
            yield callback(response);
        } else {
            yield callback({ error: { message: 'Something went wrong' } });
        }
    }
    catch (error) {
        yield callback({ error });
    }
}

export function* getPlanogramProductList({ params, callback }) {
    try {
        const response = yield doGet(END_POINTS.PLANOGRAM_PRODUCT_LIST, params);
        if (response) {
            callback && callback(response)
            yield put(Actions.getPlanogramProductListSuccess(response));
        } else {
            yield put(Actions.getPlanogramProductListFailure({ error: {} }));
            callback && callback({ error: {} });
        }
    }
    catch (error) {
        yield put(Actions.getPlanogramProductListFailure({ error }));
        callback && callback({ error });
    }
}

export function* assignProductToBay({ params, callback }) {
    try {
        const response = yield doPost(END_POINTS.PLANOGRAM_PRODUCT_ASSIGN, params);
        if (response) {
            yield callback(response);
        } else {
            yield callback({ error: { message: 'Something went wrong' } });
        }
    }
    catch (error) {
        yield callback({ error });
    }
}

export function* assignProductToShelve({ params, callback }) {
    try {
        const response = yield doPost(END_POINTS.PLANOGRAM_PRODUCT_ASSIGN_TO_SHELVE, params);
        if (response) {
            yield callback(response);
        } else {
            yield callback({ error: { message: 'Something went wrong' } });
        }
    }
    catch (error) {
        yield callback({ error });
    }
}

export function* unAssignProductToBay({ params, callback }) {
    try {
        const response = yield doPost(END_POINTS.PLANOGRAM_PRODUCT_UNASSIGN, params);
        if (response) {
            yield callback(response);
        } else {
            yield callback({ error: { message: 'Something went wrong' } });
        }
    }
    catch (error) {
        yield callback({ error });
    }
}

export function* updateRackDetail({ params, callback }) {
    try {
        const response = yield doPut(END_POINTS.PLANOGRAM_UPDATE_RACK, params);
        if (response) {
            yield callback(response);
        } else {
            yield callback({ error: { message: 'Something went wrong' } });
        }
    }
    catch (error) {
        yield callback({ error });
    }
}

export default function* PlanogramListener() {
    yield all([
        takeLatest(Actions.PLANOGRAM, getPlanogram),
        takeLatest(Actions.PLANOGRAM_ADD_RACK, saveRackDetail),
        takeLatest(Actions.PLANOGRAM_DETAIL, getPlanogramDetail),
        takeLatest(Actions.PLANOGRAM_ADD_BAY, saveBayDetail),
        takeLatest(Actions.PLANOGRAM_PRODUCT_LIST, getPlanogramProductList),
        takeLatest(Actions.PLANOGRAM_ADD_PRODUCT_TO_BAY, assignProductToBay),
        takeLatest(Actions.PLANOGRAM_ADD_PRODUCT_TO_SHELVES, assignProductToShelve),
        takeLatest(Actions.PLANOGRAM_REMOVE_PRODUCT_TO_BAY, unAssignProductToBay),
        takeLatest(Actions.PLANOGRAM_UPDATE_RACK, updateRackDetail),
    ]);
}
