import Actions from "../actions";

const initialState = {
    isLoading: false,
    storeList: [],
    error: false,
    selectedStore: null,
    customerCountUpdateLoading: false,
    customerOpenAndCloseUpdateLoading: false,
    storeClosingError: false,
    selectedTitleId: undefined,
    selectedStoreData: [],

}

const StoreReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.STORE_LIST: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case Actions.STORE_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                storeList: action?.data,
                error: false
            };
        }
        case Actions.STORE_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                storeList: [],
                error: action.error,
            };
        }

        case Actions.CUSTOMER_COUNT_UPDATE: {
            return {
                ...state,
                customerCountUpdateLoading: true,
            }
        }
        case Actions.CUSTOMER_COUNT_UPDATE_SUCCESS: {
            return {
                ...state,
                customerCountUpdateLoading: false,
                selectedStore: action.data

            }
        }
        case Actions.CUSTOMER_COUNT_UPDATE_FAILURE: {
            return {
                ...state,
                customerCountUpdateLoading: false,
            }
        }
        case Actions.STORE_OPEN_AND_CLOSE_UPDATE: {
            return {
                ...state,
                customerOpenAndCloseUpdateLoading: true,
            }
        }
        case Actions.STORE_OPEN_AND_CLOSE_UPDATE_SUCCESS: {
            return {
                ...state,
                customerOpenAndCloseUpdateLoading: false,
            }
        }
        case Actions.STORE_OPEN_AND_CLOSE_UPDATE_FAILURE: {
            return {
                ...state,
                customerOpenAndCloseUpdateLoading: false,
                storeClosingError: false,
            }
        }
        case Actions.SET_SELECTED_STORE: {
            return {
                ...state,
                selectedStore: action.data,
            }
        }
        case Actions.DASHBOARD_TITLE_NAVIGATION: {
            return {
                ...state,
                selectedTitleId: action.data.selectedTitleId,
            }
        }
        case Actions.SELECTED_STORE: {
            return {
                ...state,
                selectedStoreData: action.data,
            }
        }
        case Actions.LOGOUT: {
            return {
                ...initialState
            };
        }
        default:
            return state;
    }
};

export default StoreReducer;

export const getStoreListSelector = state => state?.STORE_LIST?.storeList;
export const getSelectedStoreSelector = state => state?.STORE_LIST?.selectedStore;
export const getStoreListLoadingSelector = state => state?.STORE_LIST?.isLoading;
export const getDashboardTitleId = state => state?.STORE_LIST?.selectedTitleId;
export const getSelectedStoreData = state => state?.STORE_LIST?.selectedStoreData;
export const getCustomerCountUpdateLoading=state=>state?.STORE_LIST?.customerCountUpdateLoading