import Actions from "../actions";

const initialState = {
    isLoading: false,
    dashboardOfferList:[],
    error: false
}
const DahboardOfferReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.DASHBOARD_OFFER_LIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.DASHBOARD_OFFER_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                dashboardOfferList: action.data.response || [],
                error: false
            };
        }
        case Actions.DASHBOARD_OFFER_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                dashboardOfferList: [],
                error: action.error,
            };
        }
        default:
            return state;
    }
};

export default DahboardOfferReducer;

export const getDashboardOfferListSelector = state => state?.DASHBOARD_OFFER_LIST?.dashboardOfferList;
export const getDashboardOfferListLoadingSelector = state => state?.DASHBOARD_OFFER_LIST?.isLoading;