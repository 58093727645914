import Actions from "../actions";

const initialState = {
    isLoading: false,
    offerList:[],
    error: false
}
const OfferReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.OFFER_LIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.OFFER_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                offerList: action.data || [],
                error: false
            };
        }
        case Actions.OFFER_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                offerList: [],
                error: action.error,
            };
        }
        default:
            return state;
    }
};

export default OfferReducer;

export const getOfferListSelector = state => state?.OFFER_LIST?.offerList;
export const getOfferListLoadingSelector = state => state?.OFFER_LIST?.isLoading;