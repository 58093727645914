import React from 'react';
import Constants, { COLORS } from '../../utils/Constants';
import placeholderImage from '../../../asset/images/placeholder.jpg';

export const Title = ({ children, ...props }) => {
    return <h4 {...props}>{children}</h4>
}

export const Label = ({ children, ...props }) => {
    return <h5 {...props} style={{ textAlign: 'start', ...props.style }}>{children}</h5>
}

export const TableCellContainer = ({ children }) => {
    return <div style={{
        display: 'flex', height: '30px',
        margin: '5px 0px',
        alignItems: 'center'
    }}>{children}</div>
}

export const GridAddRowButton = ({ onClick = () => null }) => {
    return <button className="btn btn-primary" style={{ padding: '5px 20px', borderRadius: '30px' }} onClick={onClick}>
        <i className="fas fa-plus" aria-hidden="true"></i> Add
    </button>
}

export const PlaceholderImage = ({ src, style = {}, ...props }) => {
    return <img alt="" src={src || placeholderImage} {...props} />
}

export const OrderStatusBadge = ({ status, natureOfOrder, prepared }) => {
    let orderStatus = '';
    if (status === 7) {
        orderStatus = 'Ongoing';
    } else if (status === 1) {
        orderStatus = 'Pending'
    } else if (status === 2) {
        orderStatus = 'Accepted'
    } else if (status === 3) {
        orderStatus = 'Declined';
    } else if (status === 4) {
        orderStatus = natureOfOrder && natureOfOrder === 1 ? 'Out for delivery' : 'Ready to pickup';
    } else if (status === 5) {
        orderStatus = 'Delivered';
    } else if (status === 6) {
        orderStatus = 'All';
    }
    return orderStatus !== '' && <span
        style={{
            border: `1px solid ${COLORS.appThemeHover}`,
            padding: '3px 15px',
            color: COLORS.appThemeHover,
            borderRadius: '15px',
            fontWeight: 600
        }}>{orderStatus}</span>
}
export const InvoiceStatusBadge = ({ purchaseType, natureOfOrder, prepared }) => {
    let orderStatus = '';
    if (purchaseType === 1) {
        orderStatus = 'WIWO';
    } else if (purchaseType === 2) {
        orderStatus = 'E-COM'
    } else if (purchaseType === 3) {
        orderStatus = 'HYBRID';
    } else if (purchaseType === 4) {
        orderStatus = 'SCAN & GO';
    } else if (purchaseType === 5) {
        orderStatus = 'MANUAL_REFUND';
    } else if (purchaseType === '') {
        orderStatus = 'ALL';
    }
    return orderStatus !== '' && <span
        style={{
            border: `1px solid ${COLORS.appThemeHover}`,
            padding: '3px 15px',
            color: COLORS.appThemeHover,
            borderRadius: '15px',
            fontWeight: 600
        }}>{orderStatus}</span>
}

export const RefundStatusBadge = ({ status }) => {
    let orderStatus = '';
    if (status === Constants.REFUND_STATUS.PENDING) {
        orderStatus = 'Requested';
    } else if (status === Constants.REFUND_STATUS.PROCESSING) {
        orderStatus = 'Processing'
    } else if (status === Constants.REFUND_STATUS.REFUND) {
        orderStatus = 'Refund';
    } else if (status === Constants.REFUND_STATUS.REFUND_INIT) {
        orderStatus = 'Initiated';
    } else if (status === Constants.REFUND_STATUS.REFUND_COMPLETED) {
        orderStatus = 'Completed';
    } else if (status === Constants.REFUND_STATUS.INPROCESS) {
        orderStatus = 'Inprocess';
    } else if (status === Constants.REFUND_STATUS.APPROVED) {
        orderStatus = 'Accepted';
    } else if (status === Constants.REFUND_STATUS.REJECT) {
        orderStatus = 'Declined';
    }
    if (orderStatus === 'Accepted') {
        return <span
            style={{
                border: `1px solid ${"green"}`,
                padding: '3px 15px',
                color: "green",
                borderRadius: '15px',
                fontWeight: 600
            }}>{orderStatus}</span>
    }
    else if (orderStatus === 'Declined') {
        return <span
            style={{
                border: `1px solid ${"red"}`,
                padding: '3px 15px',
                color: "red",
                borderRadius: '15px',
                fontWeight: 600
            }}>{orderStatus}</span>
    }
    if (orderStatus === "Requested") {
        return <span
            style={{
                border: `1px solid ${"gray"}`,
                padding: '3px 15px',
                color: "gray",
                borderRadius: '15px',
                fontWeight: 600
            }}>{orderStatus}</span>
    }

}

