import { all, put, takeLatest } from "redux-saga/effects";
import { doAuthGet, doAuthPost } from "../../utils/service";
import END_POINTS from "../../utils/service/Endpoints";
import Actions from "../actions";

export function* getSubscriptionListSaga({ params, callback }) {
    try {
        let response = yield doAuthGet(END_POINTS.GET_SUBSCRIPTION_LIST, params)

        if (response) {
            yield put(Actions.getSubscriptionListSuccess(response));
        }
        if (callback) {
            callback(response);
        }
    } catch (error) {
        callback && callback(error);
        yield put(Actions.getSubscriptionListFailure({ error }));
    }
}
export function* createSubscription({ params, callback }) {
    try {
        let response = yield doAuthPost(END_POINTS.CREATE_SUBSCRIPTION_LIST, params)
        if (callback) {
            callback(response);
        }
    } catch (error) {
        yield put(Actions.createSubscriptionFailure({ error }));
        callback && callback(error);
    }
}



export default function* SubscriptionListener() {
    yield all([
        takeLatest(Actions.SUBSCRIPTION, getSubscriptionListSaga),
        takeLatest(Actions.SUBSCRIPTION_CREATE, createSubscription),
    ]);
}

