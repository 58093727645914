import { all, put, takeLatest, takeEvery } from "redux-saga/effects";
import { doPromotionPost, doAuthGet } from "../../utils/service";
import END_POINTS from "../../utils/service/Endpoints";
import Actions from "../actions";

export function* getProductsForDropDownSearch({ params, callback }) {
    try {
        const response = yield doAuthGet(END_POINTS.PRODUCT_LIST, params);
        if (response) {
            callback && callback(response?.response)
            yield put(Actions.getAllProductsListSuccessAction(response));

        }
    }
    catch (error) {
        yield put(Actions.getAllProductsListFailureAction({ error }));
        callback && callback([])
    }
}
export function* getStoreListForDropDownSearch({ params, callback }) {
    try {
        const response = yield doAuthGet(END_POINTS.GET_ADMIN_STORE_LIST, params);
        if (response) {
            callback && callback(response?.response)
        }
    }
    catch (error) {
        callback && callback([])
    }
}

export function* ProductsSaga({ params }) {
    try {
        const response = yield doAuthGet(END_POINTS.PRODUCT_LIST, params);
        if (response) {
            yield put(Actions.getAllProductsListSuccessAction(response));
        }
    }
    catch (error) {
        yield put(Actions.getAllProductsListFailureAction({ error }));
    }
}
export function* createProduct({ params, callback }) {
    try {
        const response = yield doPromotionPost(END_POINTS.CREATE_PRODUCT, params);
        if (response) {
            yield put(Actions.createProductActionSuccess(response));
        }
        callback && callback(response);
    }
    catch (error) {
        callback && callback(error);
        yield put(Actions.createProductActionFailure({ error }));
    }
}
export function* updateProduct({ params, callback }) {
    try {
        const response = yield doPromotionPost(END_POINTS.UPDATE_PRODUCT, params);
        if (response) {
            yield put(Actions.updateProductActionSuccess(response));
        }
        callback && callback(response);
    }
    catch (error) {
        callback && callback(error);
        yield put(Actions.updateProductActionFailure({ error }));
    }
}

export default function* productsListener() {
    yield all([
        takeLatest(Actions.PRODUCTS, ProductsSaga),
        takeLatest(Actions.CREATE_PRODUCT, createProduct),
        takeLatest(Actions.UPDATE_PRODUCT, updateProduct),
        takeEvery(Actions.PROCUTS_SELECT_SEARCH, getProductsForDropDownSearch),
        takeEvery(Actions.GET_ADMIN_STORE_LIST, getStoreListForDropDownSearch)
    ]);
}
