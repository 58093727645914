import React, { useRef, useState, useEffect, useCallback } from 'react';
import Select from 'react-select';
import debounce from 'lodash.debounce';
import { useDispatch, useSelector } from 'react-redux'
import Actions from '../../redux/actions';
import { getPlanogramFormattedProductListSelector } from '../../redux/reducers/PlanogramProductReducer';

const PlanogramTypeAhead = ({
    productType,
    defaultOptions = null,
    placeholder = '',
    style = {},
    skuId = null,
    onChange = () => null,
    isMulti,
    fields,
    ...props
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const options = useSelector(getPlanogramFormattedProductListSelector);
    const dispatch = useDispatch();
    const optionsRef = useRef()
    const searchApi = useCallback((value) => {
        const defaultFilter = {
            size: 100, storeId: 1, searchKey: value
        };
        setIsLoading(true);
        dispatch(Actions.getPlanogramProductList(defaultFilter, (resp) => {
            let data = [];
            if (resp) {
                data = resp?.response?.products?.map(d => ({
                    label: `${d?.productName} (${d?.sku})`, value: d?.sku
                }));
            }
            setIsLoading(false)
            optionsRef.current = data
        }))
    }, [setIsLoading, dispatch]);
    const debounceRef = useRef(debounce((value) => {
        searchApi(value ? value : '');
    }, 500));
    useEffect(() => {
        searchApi();
    }, [dispatch])
    return <Select
        id="productstypeahead"
        className="title-case"
        {...props}
        placeholder={'Search Products'}
        isClearable={true}
        isSearchable={true} 
        onInputChange={(value) => debounceRef.current(value)}
        options={options}
        isMulti={false}
        value={fields}
        isLoading={isLoading}
        onChange={(value) => onChange(value || {})}
    />
}
export default PlanogramTypeAhead;


