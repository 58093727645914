import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardOfferListSelector, getDashboardOfferListLoadingSelector } from '../../../redux/reducers/PromotionDashboardReducer';
import Actions from "../../../redux/actions";
import RoutePath from "../../../utils/routes/RoutePath";
import AppLoader from "../../../components/fields/AppLoader";

const PromotionDashboard = () => {
    const dispatch = useDispatch();
    const DashboardOfferList = useSelector(getDashboardOfferListSelector);
    const isLoading = useSelector(getDashboardOfferListLoadingSelector);
    const [url, SetUrl] = useState()
    useEffect(() => {
        dispatch(Actions.getDashboardOfferListAction());
    }, [dispatch]);

    const filtertotalOfferArray = DashboardOfferList?.find((o) => o?.Total_Offer)
    const filterOfferListArray = DashboardOfferList?.filter(item => item?.offer_type);
    const viewPromotion = (offer_type) => {
        if (offer_type === "COMBO_OFFER") {
            SetUrl(RoutePath.COMBO_OFFER)
        } else if (offer_type === 'MULTI_BUY') {
            SetUrl(RoutePath.MULTI_BUY)
        } else if (offer_type === "FREE_ITEMS") {
            SetUrl(RoutePath.FREE_ITEMS)
        } else if (offer_type === "DOLLAR_OFF") {
            SetUrl(RoutePath.DOLLAR)
        } else if (offer_type === "HAPPY_HOURS") {
            SetUrl(RoutePath.HAPPY_HOURS_ITEM)
        } else if (offer_type === "CUSTOMER_COUPON") {
            SetUrl(RoutePath.CUSTOMER_COUPON)
        }

    }
    return (
        <>
            <div className="col ps-lg-4">
                <div className="row mb-1 align-items-center">
                    <div className="col">
                        {filtertotalOfferArray?.Total_Offer > 0 ?
                            <h1 className="font20 fw-bold mb-2">Promotions <small className="fw-normal">({filtertotalOfferArray?.Total_Offer})</small></h1>
                            :
                            <h1 className="font20 fw-bold mb-2">Promotions</h1>}
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/dashboard">Home</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Promotions</li>
                        </ol>
                        {/* </div> */}
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col">

                        <div key={''} id="all-offers" className="card card-body rounded-3 border-0 shadow p-4 mb-3 mb-xl-0">
                            {filterOfferListArray?.map((object) => {
                                return (
                                    <>
                                        <div className="row align-items-center">
                                            {object?.count > 0 ?
                                                <div className="col fw-bold"> {object?.offer_type} ({object?.count})</div>
                                                :
                                                <div className="col fw-bold"> {object?.offer_type}</div>
                                            }

                                            <div className="col-auto text-end"> <a id="viewPro" className="btn btn-sm btn-outline-dark" onClick={() => viewPromotion(object?.offer_type)} href={url}>View</a> </div>
                                        </div>
                                        <hr className="bg-ltgrey-1 mb-3" />
                                    </>
                                )
                            })}
                        </div>

                    </div>
                </div>
            </div>
            {isLoading && <AppLoader />}
        </>
    )
};


export default PromotionDashboard;