import React from 'react'
import Close from '../../../asset/new/img/modal-close.svg'
import Constants from '../../utils/Constants'


const BranchDetailsModal = (props) => {
    const { data, setOpenViewModal, roleSelector } = props
    const storeName = () => {
        return data?.storeDetails.map(o => o?.store?.branchName).join(',')
    }
    return (
        <>
            <div className="modal d-block"
                id="view-multi-modal"
                tabIndex="-1"
                aria-labelledby="view-multi-modal"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content rounded-0">
                        <img id="close" src={Close} alt="X" data-bs-dismiss="modal" aria-label="Close"
                            className="modal-close" onClick={() => setOpenViewModal(false)}
                        />
                        <div className="modal-header rounded-0 align-items-center py-4">
                            <div className="col">
                                <h5 className="modal-title">Branch Details</h5>
                            </div>
                        </div>
                        <div className="modal-body font14 p-40">
                            <div className="row mb-3">
                                <div className="col-4 fw-bold">Full Name</div>
                                <div className="col-8 title-case"> {data?.firstName + "\n" + data?.lastName}</div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-4 fw-bold"> Email</div>
                                <div className="col-8"> {data?.emailId}</div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-4 fw-bold"> Employee ID</div>
                                <div className="col-8">
                                    {data?.employeeId}
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-4 fw-bold"> Role</div>
                                <div className="col-8"> {roleSelector(data?.roleId)} </div>
                            </div>
                            {data?.roleId === Constants.ROLES.STORE_MANAGER && <div className="row mb-3">
                                <div className="col-4 fw-bold"> PO Approve status</div>
                                <div className="col-8"> {data?.poApprover ? 'Yes': 'No'} </div>
                            </div>}
                            {data?.roleId !== 0 && <div className="row mb-3">
                                <div className="col-4 fw-bold"> Assigned Store </div>
                                <div className="col-8">{storeName()} </div>
                            </div>}
                            <div className="row my-4">
                                <div className="col"> <a id="onClose" data-bs-dismiss="modal" aria-label="Close"
                                    className="btn btn-dark font14 w-100 p-2" onClick={() => setOpenViewModal(false)}> Close</a> </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop show"></div>
        </>

    )
}

export default BranchDetailsModal