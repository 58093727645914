import { all, put, takeLatest } from "redux-saga/effects";
import { doAuthGet, doAuthPost, doAuthDownload } from "../../utils/service";
import END_POINTS from "../../utils/service/Endpoints";
import Actions from "../actions";

export function* ReportTypeListSaga({ params, callback }) {
    try {
        const response = yield doAuthGet(END_POINTS.REPORT_TYPE_LIST, params);
        callback && callback(response);
        if (response) {
            yield put(Actions.getreportTypeListSuccessAction(response));
        }
    }
    catch (error) {
        callback && callback(error);
        yield put(Actions.getreportTypeListFailureAction({ error }));
    }
}


export function* SalesReportSaga({ params, callback }) {
    try {
        const response = yield doAuthGet(END_POINTS.SALES_REPORT, params);
        callback && callback(response);
        if (response) {
            yield put(Actions.getSalesReportSuccessAction(response));
        }
    }
    catch (error) {
        callback && callback(error);
        yield put(Actions.getSalesReportFailureAction({ error }));
    }
}

export function* downloadReportSaga({ params, callback }) {
    try {
        const response = yield doAuthPost(END_POINTS.DOWNLOAD_REPORT, params);
        callback && callback(response);
        if (response) {
            yield put(Actions.downloadReportSuccessAction(response));
        }
    }
    catch (error) {
        callback && callback({ error });
        yield put(Actions.downloadReportFailureAction({ error }));
    }
}
export function* getReportSaga({ params, callback }) {
    try {
        yield doAuthDownload(params.url, params.filename);
        callback && callback(true);
    } catch (error) {
        callback && callback(error);
        yield put(Actions.getReportFailureAction({ error }));
    }
}

export default function* reportTypeListListener() {
    yield all([
        takeLatest(Actions.REPORT_LIST_TYPE, ReportTypeListSaga),
        takeLatest(Actions.GET_SALES_REPORT, SalesReportSaga),
        takeLatest(Actions.DOWNLOAD_REPORT, downloadReportSaga),
        takeLatest(Actions.GET_REPORT, getReportSaga)
    ]);
}
