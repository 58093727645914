import React, { useEffect, useState } from 'react';
import { bistroDisplayListSelector, bistroChefDisplayLoader } from '../../redux/reducers/BistroDisplayReducer';
import { useDispatch, useSelector } from 'react-redux';
import Actions from '../../redux/actions';
const BistoChef = () => {
    const orderList = useSelector(bistroDisplayListSelector);
    const isLoading = useSelector(bistroChefDisplayLoader)
    const [isDisableButton, setDisableButton] = useState();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(Actions.bistroDisplayOrderListAction());
    }, [dispatch]);


    const setPrepared = ({ id }) => {
        setDisableButton(id)
        const callback = (res) => {
            if (res?.response_code === 0) {
                dispatch(Actions.bistroDisplayOrderListAction());
            }
        }
        dispatch(Actions.bistroChefCompleteOrderAction({ orderId: id }, callback))
    };

    const data = React.useMemo(
        () => orderList,
        [orderList]
    );

    useEffect(() => {
        const interval = setInterval(() => {
            dispatch(Actions.bistroDisplayOrderListAction());
        }, 15000);
        return () => clearInterval(interval);
    }, [dispatch]);

    const prepareBistro = (checked, index, selectedProduct) => {
        const params = {
            checked,
            index,
            selectedProduct
        }

        dispatch(Actions.modifyBistroProductAction(params))
    }

    return <div style={{ margin: '0px 16px 0px 16px' }}>
        {
            data && data?.length > 0 &&
            <div className='bistro'>
                <div style={{ marginTop: '20px' }}>
                    <div className='bb-table'>
                        {
                            data && data?.map((o, index) => {
                                let nonCompletedIndex = o?.invoiceOrderProductDetailsList?.findIndex(product => !product.isBaked || product?.acceptedQuantity === 0)
                                return (
                                    <div className='w-47  card-analytics-dashboard bb-box p-5'>
                                        <div className='d-flex justify-content-between'>
                                            <h1 className="card-text-font4 my-3">Order no : #{o?.id}</h1>
                                            <button
                                                id='prepared'
                                                className='btn bistro_completed'
                                                disabled={nonCompletedIndex !== -1 || isDisableButton === o?.id}
                                                onClick={() => setPrepared(o)}>Completed
                                            </button>
                                        </div>
                                        <p className="bb-item">Items:</p>
                                        {
                                            o?.invoiceOrderProductDetailsList?.map((j, pIndex) => {
                                                const { details, acceptedQuantity } = j || {}
                                                if (acceptedQuantity === 0) {
                                                    return null
                                                }
                                                return (
                                                    <>
                                                        <div className='check-box '>
                                                            <input type="checkbox"
                                                                id='checkbox'
                                                                className='cursor-pointer'
                                                                checked={j?.isBaked}
                                                                onChange={(event) => prepareBistro(event.target.checked, index, pIndex)} />
                                                            <span className='bb-sku'>
                                                                {acceptedQuantity} x {details?.name}
                                                                <b> SKU : ({details?.upc})</b>
                                                            </span>
                                                        </div>
                                                        {
                                                            j?.invoiceOrderProductAddOnMappingDetailsList?.length > 0 &&

                                                            <div>
                                                                <p className="bb-cus">Customization :</p>
                                                                <p className='bb-sku-cus'>{
                                                                    j?.invoiceOrderProductAddOnMappingDetailsList?.map((k) => {
                                                                        return <>{k?.details?.name}
                                                                            <b> SKU:({k?.details?.upc})</b>, <br />
                                                                        </>
                                                                    })
                                                                }</p>
                                                            </div>
                                                        }
                                                    </>
                                                )
                                            })
                                        }
                                        {
                                            o?.customerComment && <div><span><b>Customer Notes:</b> {o?.customerComment}</span></div>
                                        }

                                    </div>
                                )

                            })
                        }
                    </div>
                </div>
            </div>
        }
        {
            (data && data.length === 0 && !isLoading) &&
            <div style={{ position: 'absolute', top: '50vh', left: '40vw' }}>
                <h1>No Orders Found</h1>
            </div>
        }
    </div>
};


export default BistoChef