import { all, put, takeLatest } from "redux-saga/effects";
import { doPromotionGet } from "../../utils/service";
import END_POINTS from "../../utils/service/Endpoints";
import Actions from "../actions";

export function* getAllFoodProductsSaga({ params, callback }) {
    try {
        const response = yield doPromotionGet(END_POINTS.CREATE_PRODUCT, params);
        if (response && response.response_code === 0) {
            yield put(Actions.getAllFoodProductsListSuccessAction(response.response));
        }
        callback && callback(response);
    }
    catch (error) {
        callback && callback(error);
        yield put(Actions.getAllFoodProductsListFailureAction({ error }));
    }
}

export default function* foodProductsistener() {
    yield all([
        takeLatest(Actions.FOOD_PRODUCTS, getAllFoodProductsSaga),
    ]);
}
