import React, { useState } from 'react';
import Close from '../../asset/new/img/modal-close.svg'

const DeclineModal = ({ onClose = () => null,
    title,
    description,
    id,
    onClick = () => null,
    onChange = () => null,
    show,
    subTitle = "Please enter the reason for declining the order",
    errorMsg,
    confirm,
    cancel
}) => {
    const [text, setText] = useState('');
    const [showError, setShowError] = useState(false)
    return <React.Fragment>
        <div className="modal d-block"
            id="unblock-modal"
            tabIndex="-1"
            aria-labelledby="unblock"
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body p-50">
                        <img
                            src={Close}
                            alt="X"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            className="modal-close"
                            onClick={onClose} />
                        <form>
                            <div
                                className="row mb-4">
                                <div
                                    className="col">
                                    <h3 className="font18 fw-bold mb-3">{title}</h3>
                                    <p className="font14 mb-3">{description}</p>
                                    <div className="col-12 col-lg pe-lg-3">
                                        <div className="input-group mb-3 mb-lg-0">
                                            <textarea id='setText' type="textarea" className="form-control " placeholder="Type your Reason here" onChange={(value) => {
                                                setText(value)
                                                onChange(value)
                                            }} />
                                        </div>
                                    </div>{showError && <div style={{ color: 'red', justifyItems: 'center' }}><p>{errorMsg}</p></div>}
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div id='col '
                                    className="col pe-0" onClick={() => ""}>
                                    <span id='setShowError' className="btn btn-success font14 w-100 p-3"
                                        onClick={() => {
                                            if (text?.length === 0) {
                                                setShowError(true);
                                            } else {
                                                onClick(2);
                                            }
                                        }}
                                    >{confirm}</span> </div>
                                <div id='onClose'
                                    className="col text-end" onClick={() => onClose()}>
                                    <span className="btn btn-outline-danger font14 w-100 p-3">Cancel</span> </div>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </div>
        <div className="modal-backdrop show"></div>
    </React.Fragment>
}

export default DeclineModal;