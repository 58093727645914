import { doAction, doResponseAction } from ".";

const INVOICE_LIST = 'INVOICE_LIST';
const INVOICE_LIST_SUCCESS = 'INVOICE_LIST_SUCCESS';
const INVOICE_LIST_FAILURE = 'INVOICE_LIST_FAILURE'

export {
    INVOICE_LIST,
    INVOICE_LIST_SUCCESS,
    INVOICE_LIST_FAILURE,
}

export const getInvoiceList = (params, callback) => doAction(INVOICE_LIST, params, callback);
export const getInvoiceListSuccess = (data) => doResponseAction(INVOICE_LIST_SUCCESS, data);
export const getInvoiceListFailure = (data) => doResponseAction(INVOICE_LIST_FAILURE, data);
