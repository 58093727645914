import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Actions from "../../../redux/actions";
import {
    getHappyHoursItemListLoadingSelector,
    getHappyHoursItemListSelector,
    getHappyHoursItemCountSelector
} from "../../../redux/reducers/HappyHoursItemReducer";
import {
    getProductListSelector,
} from "../../../redux/reducers/ProductsReducer";
import moment from "moment";
import HappyHoursModal from "./HappyHoursModal";
import Constants from "../../../utils/Constants";
import { ManageHappyHoursURL } from "../../../utils/service/Endpoints";
import HappyHoursDetailModal from './HappyHoursDetailModal'
import { UTCEditFormat, UTCViewFormat } from "../../../utils/commonFunctions/dateFormat";
import Pagination from "react-js-pagination";
import RoutePath from "../../../utils/routes/RoutePath";
import AppLoader from "../../../components/fields/AppLoader";
import { toast } from "react-toastify";

const defaultFilter = {
    storeId: 1,
    page: 0,
    itemsPerPage: 10
};

const HappyHoursList = () => {
    const dispatch = useDispatch();
    const productList = useSelector(getProductListSelector);
    const HappyHoursItemList = useSelector(getHappyHoursItemListSelector);
    const isLoading = useSelector(getHappyHoursItemListLoadingSelector);
    const [isOpenHappyHoursItemModal, setOpenHappyHoursItemModal] = useState(false);
    const totalCount = useSelector(getHappyHoursItemCountSelector)
    const [offerType, setOfferType] = useState('');
    const [offerValue, setOfferValue] = useState();
    const [products, setProducts] = useState([]);
    const [activePage, setActivePage] = useState(1)
    const [startTime, setStartTime] = useState("10:50:00");
    const [endTime, setEndTime] = useState();
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();

    const [id, setHappyHourId] = useState();
    const [isEditHappyHoursModal, setEditHappyHoursItemModal] = useState(false);
    const [validationError, setFilterValidationError] = useState(null);
    const [happyHoursDetailModal, setHappyHoursDetailModal] = useState(false)
    const [happyHoursDetails, sethappyHoursDetails] = useState("")
    const [productName, setProductName] = useState("")
    const [productType, setProductType] = useState('')
    const [loading, setLoader] = useState(false)

    const OpenHappyHoursItemModal = (value) => {
        setOpenHappyHoursItemModal(value);
    };
    const EditHappyHoursModal = (value, obj) => {
        let { endDateString, endTime, startDateString, offer = {}, product,
            startTime, id, productName, bistroProduct } = obj || {}
        setEndDate(UTCEditFormat(endDateString))
        setStartDate(UTCEditFormat(startDateString))
        setEndTime(endTime)
        setStartTime(startTime)
        setProducts(product?.id)
        setOfferType(offer?.offerType)
        setOfferValue(offer?.offerValue)
        setHappyHourId(id)
        setEditHappyHoursItemModal(value)
        setProductName(productName)
        setProductType(bistroProduct)
    }

    const openHappyHoursDetailModal = (value) => {
        sethappyHoursDetails(value)
        setHappyHoursDetailModal(true)
    }

    const callback = (res) => {
        if (res?.response_code === 0) {
            dispatch(Actions.getHappyHoursItemList(defaultFilter));
            setInitialState()
            setLoader(false)
        } else {
            setFilterValidationError(res?.response_message)
            setLoader(false)
        }
    }
    const setInitialState = () => {
        setOpenHappyHoursItemModal(false)
        setEditHappyHoursItemModal(false)
        setProductType('')
        setOfferType('')
        setOfferValue('')
        setProducts('')
        setStartTime('')
        setEndTime('')
        setStartDate('')
        setEndDate('')
        setHappyHourId('')
        setFilterValidationError(null)
        setActivePage(1)
    }
    const initialState = () => {
        setOpenHappyHoursItemModal(false)
        setEditHappyHoursItemModal(false)
        setProductType('')
        setOfferType('')
        setOfferValue('')
        setProducts('')
        setStartTime('')
        setEndTime('')
        setStartDate('')
        setEndDate('')
        setHappyHourId('')
        setFilterValidationError(null)
        // setActivePage(1)
    }
    const selectedProductType = (obj) => {
        setProductType(obj)
        setProducts('')
    }
    useEffect(() => {
        dispatch(Actions.getHappyHoursItemList(defaultFilter));
    }, [dispatch]);


    const handleChange = (pageNumber) => {
        setActivePage(pageNumber)
        happyHoursPageChange(pageNumber)
    }

    const happyHoursPageChange = (page) => {
        const filterParams = {
            page: page - 1,
            limit: 10
        }
        dispatch(Actions.getHappyHoursItemList(filterParams));
    }

    const manage = (isCreate) => {
        if (isCreate && productType === '') {
            setFilterValidationError('Please select product type');
        } else if (products?.length === 0) {
            setFilterValidationError('Please choose any products.');
            return;
        } else if (!offerType) {
            setFilterValidationError('Please choose offer type.');
            return;
        } else if (!offerValue) {
            setFilterValidationError('Please enter the offer value.');
            return;
        }
        else if (offerValue <= 0) {
            setFilterValidationError('Offer value should not be in 0 and negative numbers');
            return;
        } else if (offerType === 'PERCENT' && offerValue > 100) {
            setFilterValidationError('Offer value should be less than 100')
            return;
        } else if (!startDate) {
            setFilterValidationError('Please choose start date.');
            return;
        } else if (!endDate) {
            setFilterValidationError('Please choose end date.');
            return;
        } else if (moment(startDate).isAfter(moment(endDate))) {
            setFilterValidationError('Valid start date cannot be before end date.');
            return;
        }
        else if (!startTime) {
            setFilterValidationError('Please choose start time');
            return;
        } else if (!endTime) {
            setFilterValidationError('Please choose end time');
            return;
        } else if (startTime > endTime) {
            setFilterValidationError('Start time is not higher than end time');
            return
        }
        let validFrom = UTCViewFormat(startDate)
        let expiryOn = UTCViewFormat(endDate)
        let params = {
            offer: {
                offerType,
                offerValue,
            },
            endDate: endDate,
            startDate: startDate,
            startTime,
            endTime,
            active: true,
            startDateTimeString: validFrom,
            endDateTimeString: expiryOn,
        }
        setLoader(true)
        if (isCreate) {
            params.sku = products
            params.bistroProduct = productType === 'bistro' ? true : false
            dispatch(Actions.createHappyHoursItemList(params, callback));
        } else {
            params.id = id
            params.bistroProduct = productType
            dispatch(Actions.updateHappyHoursItemList(params, callback));
        }

    }

    //delete
    const deleteCallback = (res) => {
        const params = {
            page: activePage - 1,
            limit: 10
        }
        if (res && res.response_code === 0) {
            toast.success(res?.response_message, { position: toast.POSITION.TOP_RIGHT })
            // setActivePage(1)
            dispatch(Actions.getHappyHoursItemList(params));
        } else {
            toast.error(res?.response_message, { position: toast.POSITION.TOP_RIGHT })
        }
    }
    const disableHappyHoursItemList = (object, value) => {
        const URL = ManageHappyHoursURL(object?.id, value)
        dispatch(Actions.deleteHappyHoursItemList(URL, deleteCallback));
    }

    let happyHours = []

    if (HappyHoursItemList && HappyHoursItemList.length > 0) {
        happyHours = HappyHoursItemList
    } else {
        happyHours = []
    }

    const data = React.useMemo(() => happyHours, [happyHours]);


    const selectedProducts = (obj) => {
        setProducts(obj?.value)
    }

    return (
        <div className="col ps-lg-4">
            {isLoading && <AppLoader />}
            <div className="row mb-1 align-items-center">
                <div className="col">
                    <h1 className="font20 fw-bold mb-2">Happy Hours <small className="fw-normal"></small></h1>
                    <nav style={{ marginLeft: "--bs-breadcrumb-divider: '/';" }} aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href={RoutePath.DASHBOARD_SCREEN_PATH}>Home</a></li>
                            <li className="breadcrumb-item"><a href={RoutePath.PROMOTION_DASHBOARD}>Promotions</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Happy Hours</li>
                        </ol>
                    </nav>
                </div>
                <div className="col text-end"> <button id="addNew" name="" data-bs-toggle="modal" data-bs-target="#add-dollar-modal" className="btn btn-dark" onClick={() => OpenHappyHoursItemModal(true)}>Add New</button> </div>
            </div>
            <div className="row mb-4">
                <div className="col">
                    <div id="all-offers" className="card card-body rounded-3 border-0 shadow p-4 mb-3 mb-xl-0">
                        <div className="table-responsive mb-4">
                            <table className="table table-hover table-striped table-borderless">
                                <thead>
                                    <tr>
                                        <th>OfferId</th>
                                        <th>Product</th>
                                        <th>Validity</th>
                                        <th>Happy Hours</th>
                                        <th>Offer Value</th>
                                        <th>Status</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!isLoading && data && data.map((o) => {
                                        return <tr>
                                            <td>{o?.id} </td>
                                            <td className="cursor-pointer"> <p title={o?.productName} className="promotion-desc-wrap title-case">{o?.productName?.toLowerCase()}</p> </td>
                                            <td>{moment(o?.startDateString, 'YYYY-DD-MM').format("MM/DD/YYYY")} to {moment(o?.endDateString, 'YYYY-DD-MM').format("MM/DD/YYYY")}</td>
                                            <td> {o?.startTime} to {o?.endTime} </td>
                                            <td> {o?.offer?.offerType === "PERCENT" ? `${o?.offer?.offerValue}%` : `$` + parseFloat(o?.offer?.offerValue)?.toFixed(2)}</td>
                                            {!o.status ? <td>
                                                <div className="form-check form-switch">
                                                    <input
                                                        onChange={(e) => {
                                                            e.stopPropagation();
                                                            disableHappyHoursItemList(o, e.target.checked)
                                                        }}
                                                        className="form-check-input" type="checkbox" role="switch" id="switch" checked={o?.active} />
                                                </div></td> : <td className={o.status === 'Expired' ? "text-danger" : 'text-success'}>{o?.status}</td>
                                            }
                                            <td className="d-flex">
                                                <div className="col-auto">
                                                    <button
                                                        onClick={() => openHappyHoursDetailModal(o)}
                                                        id="viewHappyHours"
                                                        type="button" data-bs-toggle="modal"
                                                        data-bs-target="#view-happy-modal"
                                                        className="btn btn-sm btn-outline-dark mb-3 mb-md-0">View
                                                    </button>
                                                </div>
                                                <div className="col-auto">
                                                    <button
                                                        onClick={(e) => { e.stopPropagation(); EditHappyHoursModal(true, o) }}
                                                        type="button" data-bs-toggle="modal" data-bs-target="#edit-happy-modal"
                                                        id="editHappyHours"
                                                        className="btn btn-sm btn-outline-dark">Edit</button>
                                                </div>
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div className="text-center">
                            {
                                (data && data.length > 0 && totalCount > 1) && <Pagination
                                    id="Pagination"
                                    activePage={activePage}
                                    itemsCountPerPage={10}
                                    totalItemsCount={totalCount * 10}
                                    pageRangeDisplayed={5}
                                    onChange={(e) => handleChange(e)}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
            {isOpenHappyHoursItemModal && (
                <HappyHoursModal
                    id="HappyHoursModal"
                    isOpenHappyHoursItemModal={isOpenHappyHoursItemModal}
                    setOpenHappyHoursItemModal={() => initialState()}
                    setOfferType={(e) => setOfferType(e)}
                    setOfferValue={(e) => setOfferValue(e)}
                    setProducts={(value) => selectedProducts(value)}

                    setStartTime={(e) => setStartTime(e?.target?.value)}
                    setEndTime={(e) => setEndTime(e?.target?.value)}
                    startTime={startTime}
                    endTime={endTime}

                    setStartDate={(e) => setStartDate(e)}
                    setEndDate={(e) => setEndDate(e)}
                    startDate={startDate}
                    endDate={endDate}
                    productType={productType}
                    setProductType={selectedProductType}
                    products={products}
                    offerValue={offerValue}
                    manage={(value) => manage(value)}
                    offerType={offerType}
                    productList={productList}
                    isCreate={true}
                    hoursOption={Constants.HOURS_OPTION}
                    validationError={validationError}
                    setFilterValidationError={setFilterValidationError}
                    isLoading={loading}
                />
            )}
            {
                isEditHappyHoursModal && (
                    <HappyHoursModal
                        id="EditHappyHoursModal"
                        isOpenHappyHoursItemModal={isEditHappyHoursModal}
                        setOpenHappyHoursItemModal={() => initialState()}
                        setOfferType={(e) => setOfferType(e)}
                        setOfferValue={(e) => setOfferValue(e)}
                        setProducts={(e) => selectedProducts(e?.target?.value)}

                        setStartTime={(e) => setStartTime(e?.target?.value)}
                        setEndTime={(e) => setEndTime(e?.target?.value)}
                        startTime={startTime}
                        endTime={endTime}

                        setStartDate={(e) => setStartDate(e)}
                        setEndDate={(e) => setEndDate(e)}
                        startDate={startDate}
                        endDate={endDate}
                        productName={productName}
                        products={products}
                        offerValue={offerValue}
                        manage={(value) => manage(value)}
                        offerType={offerType}
                        productList={productList}
                        hoursOption={Constants.HOURS_OPTION}
                        validationError={validationError}
                        setFilterValidationError={setFilterValidationError}
                        isLoading={loading}
                    />
                )
            }
            {
                happyHoursDetailModal && <HappyHoursDetailModal id="DetailModal" isHappyHoursDetailModal={happyHoursDetailModal} isCloseModal={() => setHappyHoursDetailModal(false)} happyHoursDetails={happyHoursDetails} />
            }
        </div>
    );
};

export default HappyHoursList;