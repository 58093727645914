import React, {useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Actions from "../../redux/actions";
import {
  getCustomerUnBlockListLoadingSelector,
  getCustomerUnBlockListSelector,
  getCustomerUnBlockCountSelector
} from "../../redux/reducers/CustomerUnBlockingReducer";
import DeclineModal from '../../components/DeclineModal';
import { cloneDeep } from 'lodash';
import { toast } from "react-toastify";
import Pagination from "react-js-pagination";
import search from '../../../asset/new/img/search.svg'
import EmptyPage from "../../components/emptyPage";
import AppLoader from "../../components/fields/AppLoader";
import ViewInvoiceModal from "../../components/ViewInvoiceModal";
const defaultFilter = {
  page: 0,
  searchKey: '',
  size: 10
};

const CustomerUnBlocking = () => {
  const dispatch = useDispatch();
  const allCustomerUnBlockList = useSelector(getCustomerUnBlockListSelector);
  const [activePage, setActivePage] = useState(1)
  const isLoading = useSelector(getCustomerUnBlockListLoadingSelector);
  const [isCustomerUnBlockingModal, setCustomerUnBlockingModal] = useState(false);
  const [customerUnBlockingTextState, setCustomerUnBlockingReason] = useState('');
  const [customerBlockedId, setCustomerId] = useState('');
  const [customerBlockedName, setCustomerName] = useState('');
  const totalCount = useSelector(getCustomerUnBlockCountSelector)
  const [invoiceId, setInvoiceId] = useState();
  const [viewInvoiceModal, setViewInvoiceModal] = useState(false)
  const [apiFilters, setApiFilters] = useState(cloneDeep({
    ...defaultFilter,

  }));
  const customerBlockedList = useCallback(() => {
    const filterParams = { ...apiFilters };
    dispatch(Actions.getInactiveUsersList(filterParams));
  }, [dispatch, apiFilters]);


  useEffect(() => {
    customerBlockedList();
  }, [customerBlockedList]);

 
  const handlePageNumberChange = (pageNumber) => {
    setActivePage(pageNumber)
    apiFilters.page = (pageNumber - 1)
    InactiveUsersListPageChange();
  }


  const InactiveUsersListPageChange = () => {
    dispatch(Actions.getInactiveUsersList(apiFilters))
  }
  

  const callback = (res) => {
    const { response_code } = res
    if (response_code === 0) {
      toast.success(res?.response_message, { position: toast.POSITION.TOP_RIGHT })
      setCustomerUnBlockingModal(false)
      dispatch(Actions.getInactiveUsersList(defaultFilter));
      setActivePage(1)
    }
    else {
      toast.error(res?.response_message, { position: toast.POSITION.TOP_RIGHT })
    }
  }
  const unCustomerBlocking = () => {
    const params = {
      customerId: customerBlockedId,
      message: customerUnBlockingTextState
    }
    dispatch(Actions.createCustomerUnBlockingList(params, callback))
  }

  const customerUnBlockedId = (obj) => {
    const { id, firstName, lastName } = obj
    setCustomerUnBlockingModal(true)
    setCustomerId(id)
    setCustomerName(firstName + ' ' + lastName)
  }
  const data = React.useMemo(() => allCustomerUnBlockList, [allCustomerUnBlockList]);

  const ViewInvoice = (value, invoiceid) => {
    setInvoiceId(invoiceid)
    setViewInvoiceModal(value)
  }

  return (

    <div className="col ps-lg-4">
      <div className="row mb-3">
        <div className="col"> <h1 className="font20 fw-bold">Blocked customers</h1> </div>
      </div>
      <form name="">
        <div id="order-options-bar" className="row mb-3">
          <div className="col-12 col-lg-4 pe-lg-3">
            <div className="input-group mb-3 mb-lg-0">
              <input type="text"
                id="setApiFilters"
                className="form-control border-end-0"
                placeholder="Search"
                value={apiFilters.searchKey}
                onChange={(e) => {
                  const params = { ...apiFilters }
                  params.searchKey = e.target.value
                  params.page = 0
                  setActivePage(1)
                  setApiFilters(params)
                }} />
              <button className="btn btn-outline-dark border-start-0" type="button" id="search-btn"><img src={search} alt="Go" /></button>
            </div>
          </div>
        </div>
      </form>
      <div className="my-cart-list card card-body rounded-3 border-0 shadow p-4 mb-3 mb-xl-0">
        {data && data.map((o) => {
          return (
            <div className="single-product pb-3 mb-3">
              <div className="row align-items-center">
                <div className="col-auto pe-2"> <div className="p-3 font16 bg-lgrey text-dark text-center border border-dark rounded-circle">{o?.firstName?.charAt(0)?.toUpperCase()}{o?.lastName?.charAt(0)?.toUpperCase()}</div> </div>
                <div className="col"> <h3 className="product-name">{o.firstName} {o.lastName}</h3> <span className="font14 fw-bold text-dark">{o.emailId}</span> </div>
                {o?.invoiceid && <div className="col-auto price"> Invoice ID: {o?.invoiceid}</div>}
                <div className="col-auto price"> <button id="customerUnBlockedId" type="button" className="btn btn-outline-dark" data-bs-toggle="modal" data-bs-target="#unblock-modal" onClick={() => customerUnBlockedId(o)}>Unblock</button> </div>
                {o?.invoiceid && <span onClick={() => ViewInvoice(true, o?.invoiceid)} class="cursor-pointer text-primary fw-bold font12 text-end">View Receipt</span>}
              </div>
            </div>
          )
        })}

        <div className="row align-items-center">
          <div className="col">
            {
              data && data.length !== 0 && totalCount > 10 && <Pagination
                id='Pagination'
                activePage={activePage}
                itemsCountPerPage={10}
                totalItemsCount={totalCount}
                pageRangeDisplayed={5}
                onChange={(e) => handlePageNumberChange(e)} />
            }
          </div>
        </div>
        {
          (data && data.length === 0 && !isLoading) && (
            <EmptyPage
              content="No Customer Found"
            />
          )
        }
      </div>
      {
        isCustomerUnBlockingModal && <DeclineModal
          show={isCustomerUnBlockingModal}
          id='DeclineModal'
          title={`Unblock Customer`}
          description={`Would you like to unblock "${customerBlockedName}" ?`}
          onClose={() => setCustomerUnBlockingModal(false)}
          onChange={(e) => setCustomerUnBlockingReason(e.target.value)}
          subTitle="Reason"
          errorMsg="Please enter the reason"
          confirm="Unblock"
          cancel="Cancel"
          onClick={(type) => {
            if (type === 1) {
              setCustomerUnBlockingModal(false)
            }
            else if (type === 2) {
              unCustomerBlocking()
            }
          }}
        />
      }
      {
        viewInvoiceModal && <ViewInvoiceModal viewInvoiceModal={viewInvoiceModal}
          id="invoiceDetails"
          setViewInvoiceModal={() => setViewInvoiceModal(false)}
          ViewInvoiceDetailsId={invoiceId}
          hideViewRefund={true}
        />
      }
      {isLoading && <AppLoader />}
    </div>

  );
};
export default CustomerUnBlocking;
