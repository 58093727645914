import { doAction, doResponseAction } from ".";

const HITACHI_ACTIVE_COUNT_LIST = 'HITACHI_ACTIVE_COUNT_LIST';
const HITACHI_ACTIVE_COUNT_LIST_SUCCESS = 'HITACHI_ACTIVE_COUNT_LIST_SUCCESS';
const HITACHI_ACTIVE_COUNT_LIST_FAILURE = 'HITACHI_ACTIVE_COUNT_LIST_FAILURE'

const HITACHI_MONITORING_LIST = 'HITACHI_MONITORING_LIST';
const HITACHI_MONITORING_LIST_SUCCESS = 'HITACHI_MONITORING_LIST_SUCCESS';
const HITACHI_MONITORING_LIST_FAILURE = 'HITACHI_MONITORING_LIST_FAILURE'

export const getHitachiMonitoring = store => store['HITACHI_MONITORING']

export {
    HITACHI_ACTIVE_COUNT_LIST,
    HITACHI_ACTIVE_COUNT_LIST_SUCCESS,
    HITACHI_ACTIVE_COUNT_LIST_FAILURE,
    HITACHI_MONITORING_LIST,
    HITACHI_MONITORING_LIST_SUCCESS,
    HITACHI_MONITORING_LIST_FAILURE
}

export const getHitachiActiveList = (params, callback) => doAction(HITACHI_ACTIVE_COUNT_LIST, params, callback);
export const getHitachiActiveListSuccess = (data) => doResponseAction(HITACHI_ACTIVE_COUNT_LIST_SUCCESS, data);
export const getHitachiActiveListFailure = (data) => doResponseAction(HITACHI_ACTIVE_COUNT_LIST_FAILURE, data);

export const getHitachiMonitoringList = (params, callback) => doAction(HITACHI_MONITORING_LIST, params, callback);
export const getHitachiMonitoringListSuccess = (data) => doResponseAction(HITACHI_MONITORING_LIST_SUCCESS, data);
export const getHitachiMonitoringListFailure = (data) => doResponseAction(HITACHI_MONITORING_LIST_FAILURE, data);

