import { doAction, doResponseAction } from "..";

const UPDATE_PLANOGRAM_PRODUCT = 'UPDATE_PLANOGRAM_PRODUCT';
const UPDATE_PLANOGRAM_PRODUCT_SUCCESS = 'UPDATE_PLANOGRAM_PRODUCT_SUCCESS';
const UPDATE_PLANOGRAM_PRODUCT_FAILURE = 'UPDATE_PLANOGRAM_PRODUCT_FAILURE';

export {
    UPDATE_PLANOGRAM_PRODUCT,
    UPDATE_PLANOGRAM_PRODUCT_SUCCESS,
    UPDATE_PLANOGRAM_PRODUCT_FAILURE,
}

export const updataPlanogramProduct = (params, callback) => doAction(UPDATE_PLANOGRAM_PRODUCT, params, callback);
export const updataPlanogramProduct_Success = data => doResponseAction(UPDATE_PLANOGRAM_PRODUCT_SUCCESS, data);
export const updataPlanogramProduct_Failure = data => doResponseAction(UPDATE_PLANOGRAM_PRODUCT_FAILURE, data);