import { doAction, doResponseAction } from ".";
//LIST
const MULTI_BUY = 'MULTI_BUY';
const MULTI_BUY_SUCCESS = 'MULTI_BUY_SUCCESS';
const MULTI_BUY_FAILURE = 'MULTI_BUY_FAILURE';

//CREATE
const CREATE_MULTI_BUY = 'CREATE_MULTI_BUY';
const CREATE_MULTI_BUY_SUCCESS = 'CREATE_MULTI_BUY_SUCCESS';
const CREATE_MULTI_BUY_FAILURE = 'CREATE_MULTI_BUY_FAILURE'

//UPDATE
const UPDATE_MULTI_BUY = 'UPDATE_MULTI_BUY';
const UPDATE_MULTI_BUY_SUCCESS = 'UPDATE_MULTI_BUY_SUCCESS';
const UPDATE_MULTI_BUY_FAILURE = 'UPDATE_MULTI_BUY_FAILURE'

//DELETE
const DELETE_MULTI_BUY = 'DELETE_MULTI_BUY';
const DELETE_MULTI_BUY_SUCCESS = 'DELETE_MULTI_BUY_SUCCESS';
const DELETE_MULTI_BUY_FAILURE = 'DELETE_MULTI_BUY_FAILURE'

export {
    MULTI_BUY,
    MULTI_BUY_SUCCESS,
    MULTI_BUY_FAILURE,
    CREATE_MULTI_BUY,
    CREATE_MULTI_BUY_SUCCESS,
    CREATE_MULTI_BUY_FAILURE,
    UPDATE_MULTI_BUY,
    UPDATE_MULTI_BUY_SUCCESS,
    UPDATE_MULTI_BUY_FAILURE,
    DELETE_MULTI_BUY,
    DELETE_MULTI_BUY_SUCCESS,
    DELETE_MULTI_BUY_FAILURE
}

//LIST
export const getAllMultiBuyListAction = (params, callback) => doAction(MULTI_BUY, params, callback);
export const getAllMultiBuyListSuccessAction = data => doResponseAction(MULTI_BUY_SUCCESS, data);
export const getAllMultiBuyListFailureAction = data => doResponseAction(MULTI_BUY_FAILURE, data);

// CREATE
export const createMultiBuyAction  = (params, callback) => doAction(CREATE_MULTI_BUY, params, callback);
export const createMultiBuyActionSuccess= (data) => doResponseAction(CREATE_MULTI_BUY_SUCCESS, data);
export const createMultiBuyActionFailure = (data) => doResponseAction(CREATE_MULTI_BUY_FAILURE, data);

//UPDATE
export const updateMultiBuyAction  = (params, callback) => doAction(UPDATE_MULTI_BUY, params, callback);
export const updateMultiBuyActionSuccess= (data) => doResponseAction(UPDATE_MULTI_BUY_SUCCESS, data);
export const updateMultiBuyActionFailure = (data) => doResponseAction(UPDATE_MULTI_BUY_FAILURE, data);

//DELETE
export const deleteMultiBuyAction  = (params, callback) => doAction(DELETE_MULTI_BUY, params, callback);
export const deleteMultiBuyActionSuccess= (data) => doResponseAction(DELETE_MULTI_BUY_SUCCESS, data);
export const deleteMultiBuyActionFailure = (data) => doResponseAction(DELETE_MULTI_BUY_FAILURE, data);