import { all, put, takeLatest } from "@redux-saga/core/effects";
import { doAuthGet, doAuthPut } from "../../utils/service";
import END_POINTS from "../../utils/service/Endpoints";
import Actions from "../actions";


export function* getOrderListSaga({ params, callback }) {
    try {
        let response = yield doAuthGet(END_POINTS.GET_ALL_ORDERS_API, params)
        if (response?.response_code === 0) {
            yield put(Actions.getOrderListSuccess(response));
        } else {
            put(Actions.getOrderListFailure({ error: 'No Data found' }))
        }
        if (callback) {
            callback(response);
        }
    } catch (error) {
        yield put(Actions.getOrderListFailure({ error }))
    }
}

export function* getOrderDetailsByIdSaga({ params, callback }) {
    try {
        let response = yield doAuthGet(END_POINTS.GET_ORDER_DETAILS_BY_ID_API, params)
        if (response?.response_code === 0) {
            let comboOfferArrayList = []
            let freeGiveArrayList = []
            let multiBuyArrayList = []
            let finalList = []
            const { invoiceDetails, invoiceOrderProductDetailsList } = response?.response || {}
            const offersArray = response?.response || {}
            if (invoiceDetails?.promotionJson) {
                let offers = JSON.parse(invoiceDetails?.promotionJson);
                let subscriptionsArr = [];
                if (offers?.subscriptions?.length > 0) {
                    offers.subscriptions.map((i) => {
                        return [...i.products];
                    });

                }
                let productwithoutArr =
                    offers?.productsWithoutOffer !== null
                        ? offers?.productsWithoutOffer
                        : [];
                let combineArray = [
                    ...productwithoutArr,
                    ...offers?.happyHours,
                    ...subscriptionsArr,
                ]
                for (let i = 0; i < combineArray?.length; i++) {
                    let index;
                    combineArray[i]?.idWithQuantity?.forEach((o) => {
                        index = invoiceOrderProductDetailsList?.findIndex((item) => item?.invoiceProductDetailsId === o?.id);
                        if (index !== -1) {
                             finalList.push({
                                ...combineArray[i],
                                productDetails: invoiceOrderProductDetailsList?.[index],
                                quantity: o?.qty
                            });
                        }
                    });

                }
                comboOfferArrayList = offers?.comboOffer
                freeGiveArrayList = offers?.newFreeGive?.map((o) => {
                    return {
                        ...o,
                        newQuantity: o?.quantity,
                        isSelectedCheck: false
                    }
                })

                multiBuyArrayList = offers?.multiBuy?.map((o) => {
                    return {
                        ...o,
                        newQuantity: o?.appliedTimes,
                        isSelectedCheck: false
                    }
                })

            }
            const data = {
                finalList,
                comboOfferArrayList,
                freeGiveArrayList,
                offersArray,
                multiBuyArrayList
            }
            yield put(Actions.getOrderDetailsSuccess(data));
            callback && callback(response);
        }

    } catch (error) {
        callback && callback(error);
        yield put(Actions.getOrderDetailsFailure({ error }))
    }
}


export function* updateOrderByIdSaga({ params, callback }) {
    try {
        let response = yield doAuthPut((params.status === 2 || params.status === 3) ? END_POINTS.PARTIAL_ORDER : END_POINTS.UPDATE_ORDER_DETAILS_API, params)
        callback && callback(response);
        if (response?.response_code === 0) {
            yield put(Actions.getOrderDetailsUpdateSuccess(response?.response));
        }
    } catch (error) {
        callback && callback({ error });
        yield put(Actions.getOrderDetailsUpdateFailure({ error }))
    }
}



export function* partialOrderAcceptSaga({ params, callback }) {
    try {
        let response = yield doAuthPut(END_POINTS.PARTIAL_ORDER, params)
        callback && callback(response);
        if (response?.response_code === 0) {
            yield put(Actions.partialOrderAcceptSuccess(response.response));
        }
    } catch (error) {
        callback && callback({ error });
        yield put(Actions.partialOrderAcceptFailure({ error }))
    }
}


export function* getSocketOrders({ params, callback }) {
    try {
        let response = yield doAuthGet(END_POINTS.GET_ORDER_DETAILS_BY_ID_API, params)
        if (response?.response_code === 0) {
            callback && callback(response);
        }
    } catch (error) {
        callback && callback({ error });
    }
}

export default function* credentialsListener() {
    yield all([
        takeLatest(Actions.ORDERS, getOrderListSaga),
        takeLatest(Actions.ORDER_DETAILS, getOrderDetailsByIdSaga),
        takeLatest(Actions.ORDER_DETAILS_UPDATE, updateOrderByIdSaga),
        takeLatest(Actions.PARTIAL_ORDER_ACCEPT, partialOrderAcceptSaga),
        takeLatest(Actions.GET_SOCKET_ORDERS, getSocketOrders)
    ]);
}
