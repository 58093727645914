import React from 'react';


class OrderSummaryOfferDetails extends React.Component {
    _renderHeaders = (title, subtitle) => {
        return <div className="row align-items-center mb-1">
            <div className="col"><p><b className="font13 text-black">{title}</b> </p> </div>
            <div className="col-auto text-end fw-bold "> {subtitle}  </div>
        </div>
    }


    _renderComboOffer = () => {
        return this.props.cartOffer?.comboOffer?.length > 0 ? <>
            {this._renderHeaders('Combo Offer:', '')}
            {this.props.cartOffer.comboOffer.map(comboOfferObj => {
                const comboProducts = comboOfferObj && comboOfferObj.skus?.map(sku => this.props.cartItems?.find(c => c.upc === sku?.toString()))
                const itemStr = comboProducts && comboProducts?.map(c => c?.name?.toLowerCase()).join(', ')
                return <div className="row align-items-center mb-2" key={JSON.stringify(comboOfferObj) + itemStr}>
                    <div className="col-sm-8 title-case"> {comboOfferObj?.comboAppliedTimes}
                        {'x '}
                        {itemStr} <strong className="text-success">(${comboOfferObj?.totalComboPrice?.toFixed(2)})</strong>
                    </div>
                    <div className="col text-end fw-bold text-success">- ${comboOfferObj?.discount?.toFixed(2)}</div>
                </div>
            })}
        </> : null;
    };

    _renderFreeGive = () => {
        return this.props.cartOffer?.freeGive?.length > 0 ? <>
            {this._renderHeaders('Free Give:', '')}
            {this.props.cartOffer?.freeGive?.map(freeGiveObj => {
                const itemStr = freeGiveObj?.freeItemIdsWithQuantities?.reduce((accumulator, currentObj) => {
                    const cartObject = this.props.cartItems?.find(c => c.upc === currentObj?.sku)
                    accumulator = accumulator ? accumulator + ', ' : '';
                    return accumulator + currentObj?.quantity + 'x ' + cartObject?.name?.toLowerCase();
                }, '');
                const cartFreeItemObject = this.props.cartItems?.find(c => c.upc === freeGiveObj?.sku)
                return <React.Fragment key={JSON.stringify(freeGiveObj)}>
                    <div className="row mb-3">
                        <div className="col">
                            {
                                itemStr && <>

                                    <div className="col title-case">
                                        <strong>Paid Item:</strong> <br />{cartFreeItemObject?.name?.toLowerCase()}
                                    </div>
                                    <div className="col title-case">
                                        <strong>Free:</strong> <br />{itemStr?.trim().length > 0 && <div>{itemStr?.toLowerCase()}</div>}
                                    </div>
                                </>
                            }

                        </div>
                        {itemStr && <div className="col text-end">
                            <strong
                                className="text-success">- ${freeGiveObj?.subTotalDiscount?.toFixed(2)}</strong>
                        </div>}
                    </div>
                </React.Fragment>
                // <div className="row align-items-center mb-2" key={JSON.stringify(freeGiveObj)}>
                //     <div className="col title-case">{cartObject?.name?.toLowerCase()}</div>
                //     <div className="col"> <strong>Paid Item:</strong> {cartObject?.name?.toLowerCase()}</div>
                //     {
                //         itemStr ? (
                //             <div className="col title-case">Free: {itemStr?.trim().length > 0 && <>{itemStr}</>}</div>
                //         ) : (
                //             <div className="col"> </div>
                //         )
                //     }
                //     <div className="col text-end fw-bold"> ${freeGiveObj?.discount?.toFixed(2)}</div>
                // </div>
            })}
        </> : null;
    };



    _renderMultiBuy = () => {
        return this.props.cartOffer?.multiBuy?.length > 0 ? <>
            {this._renderHeaders('Multi Buy:', '')}
            {this.props.cartOffer.multiBuy?.map(multiBuyObj => {
                const cartObject = this.props.cartItems?.find(c => c?.upc === multiBuyObj?.sku)
                return <div className="row align-items-center mb-2">
                    <div className="col title-case">{multiBuyObj?.quantity} {'x '} {cartObject?.name?.toLowerCase()}</div>
                    <div className="col text-end fw-bold text-success">- ${multiBuyObj.discount.toFixed(2)}</div>
                </div>
            })}
        </> : null
    }


    _renderHappyHours = () => {
        return this.props.cartOffer?.happyHours?.length > 0 ? <>
            {this._renderHeaders('Happy Hours:', '')}
            {this.props.cartOffer.happyHours?.map(happyHoursObj => {
                const cartObject = this.props.cartItems?.find(c => c?.upc === happyHoursObj?.sku)
                return <>
                    <div className="row align-items-center mb-2" key={JSON.stringify(happyHoursObj)}>
                        <div className="col title-case">{cartObject?.name?.toLowerCase()}</div>
                        <div className="col text-end fw-bold text-success">- ${happyHoursObj?.discount?.toFixed(2)}</div>
                    </div>
                </>
            })}
        </> : null;
    };



    _renderSubscription = () => {
        return this.props.cartOffer?.subscriptions?.length > 0 ? <>
            {this._renderHeaders('Subscriptions:', '')}
            {this.props.cartOffer.subscriptions.map(subscriptionObj => {
                return <div className="row align-items-center mb-2">
                    <div className="col title-case">{subscriptionObj?.name?.toLowerCase()}</div>
                    <div className="col text-end fw-bold text-success">- ${subscriptionObj?.discount.toFixed(2)}</div>
                </div>
            })}
        </> : null;

    }




    _renderOffers = () => {
        if (
            this.props.cartOffer &&
            ((this.props.cartOffer.comboOffer &&
                this.props.cartOffer?.comboOffer?.length > 0) ||
                (this.props.cartOffer.freeGive &&
                    this.props.cartOffer?.freeGive?.length > 0) ||
                (this.props.cartOffer.multiBuy &&
                    this.props.cartOffer?.multiBuy?.length > 0) ||
                (this.props.cartOffer.happyHours &&
                    this.props.cartOffer?.happyHours?.length > 0) ||
                (this.props.cartOffer.subscriptions &&
                    this.props.cartOffer?.subscriptions?.length > 0) ||
                (this.props.cartOffer?.dollarOff) ||
                (this.props.cartOffer?.couponCode))
        ) {
            return (
                <><div className={this.props.cartOffer?.subscriptions?.length > 0 ||
                    this.props.cartOffer?.comboOffer?.length > 0 ||
                    this.props.cartOffer?.freeGive?.length > 0 ||
                    this.props.cartOffer?.multiBuy?.length > 0 ||
                    this.props.cartOffer?.happyHours?.length > 0 ? "top-dotted-divider pt-2 mt-2 mb-3" : ""}>
                    {this._renderSubscription()}
                    {this._renderComboOffer()}
                    {this._renderFreeGive()}
                    {this._renderMultiBuy()}
                    {this._renderHappyHours()}
                </div>
                </>
            );
        }
        return null;
    };

    render() {
        return this._renderOffers()
    }
}


export default OrderSummaryOfferDetails;