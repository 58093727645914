import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import StoreCapacityModal from '../../components/dashboard/StoreCapacityModal';
import { getStoreListLoadingSelector, getSelectedStoreSelector, } from '../../redux/reducers/StoreReducer';
import Actions from '../../redux/actions';
import AppLoader from '../../components/fields/AppLoader';
import Constants, { COLORS } from '../../utils/Constants';
import Alert from '../../components/alert/alert';
import { getRoleIdFromCookie, isJSON , getCookie } from '../../utils/commonFunctions/cookie';


const DashboardStore = () => {
    const dispatch = useDispatch();
    const selectedStore = useSelector(getSelectedStoreSelector);
    const isLoading = useSelector(getStoreListLoadingSelector);
    const [openStoreModal, setOpenStoreModal] = useState(false);
    const [storeCapacityModal, setStoreCapacityModal] = useState(false);
    const { id: storeId, storeAvailableStatus } = selectedStore || {};
    const [isNilgirisUser,setIsNilgirisUser]=useState(false)
    const roleId = getRoleIdFromCookie()
    const storeStatusCallBack = (res) => {
        if (res?.response_code === 0) {
            setOpenStoreModal(true)
        }
    }
    const openStoreStatusModal = () => {
        dispatch(Actions.getStoreList("", storeStatusCallBack));
    }

    useEffect(() => {
        const tokenDetails = isJSON(getCookie('token')) ? JSON.parse(getCookie('token')) : undefined
        setIsNilgirisUser(tokenDetails?.customer?.nilgirisUser);
    }, [])

    const onUpdateStoreStatus = (value) => {
        let params = {
            id: storeId,
            storeAvailableStatus: value
        }
        dispatch(Actions.updateStoreOpenAndClose(params, () => {
            dispatch(Actions.getStoreList());
        }));
        setOpenStoreModal(false)
    }


    const MainRender = () => {
        return <div style={{ display: 'flex' }}>
            {(roleId === Constants.ROLES.ADMIN || (roleId === Constants.ROLES.STORE_MANAGER && !isNilgirisUser )) &&
                <div id="store-status" className="card card-body rounded-3 border-0 shadow p-4 mb-3 mb-xl-0">
                    <h2 className="font15 fw-bold text-uppercase mb-4">Store Status</h2>
                    <form>
                        <div className="form-check mb-4">
                            {storeAvailableStatus === 1 ? <input className="form-check-input me-3 me-xl-4 cursor-pointer" type="radio"
                                name="open-close" id="store-open" checked /> : <input className="form-check-input me-3 me-xl-4 cursor-pointer" type="radio"
                                    name="open-close" id="store-open" onClick={openStoreStatusModal} />}
                            <label className="form-check-label" for="store-open">
                                <h5 className="font13 fw-bold text-success">Open</h5>
                                <p className="font10">Check mark as store open.</p>
                            </label>
                        </div>

                        <div className="form-check mb-3">
                            {storeAvailableStatus === 2 ? <input className="form-check-input me-3 me-xl-4 cursor-pointer" type="radio"
                                name="open-close" id="store-close" checked /> : <input className="form-check-input me-3 me-xl-4 cursor-pointer" type="radio"
                                    name="open-close" id="store-close" onClick={openStoreStatusModal} />}
                            <label className="form-check-label" for="store-close">
                                <h5 className="font13 fw-bold text-danger">Closed</h5>
                                <p className="font10">Check mark as store close.</p>
                            </label>
                        </div>

                    </form>
                    {
                        openStoreModal && <Alert
                            id="Alert"
                            title={"Confirm"}
                            proceed={"Yes"}
                            cancel={"No"}
                            description={"Do you want to change the store status?"}
                            onSuccess={() => selectedStore?.storeAvailableStatus === 1 ?
                                onUpdateStoreStatus(2) : onUpdateStoreStatus(1)}
                            onFailure={() => setOpenStoreModal(false)} />
                    }
                    {storeCapacityModal && <StoreCapacityModal
                        id="StoreCapacityModal"
                        show={storeCapacityModal}
                        onClose={() => setStoreCapacityModal(false)}
                    />}


                </div>
            }

        </div>
    }

    return <div style={{ position: 'relative', }}>
        <MainRender />
        {isLoading && <div style={{
            top: 0, bottom: 0, left: 0, right: 30,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            backgroundColor: COLORS.loadingBackround
        }}>
            <AppLoader size="30px" />
        </div>}
    </div>


}

export default DashboardStore;