import moment from 'moment';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import AppDatePicker from '../../../components/fields/AppDatePicker';
import BoldError from '../../../components/fields/BoldError';
import ProductsTypeAhead from '../../../components/fields/ProductsTypeAhead';
import Actions from '../../../redux/actions';
import Constants from '../../../utils/Constants';
import { UTCViewFormat } from "../../../utils/commonFunctions/dateFormat";
import Close from '../../../../asset/new/img/modal-close.svg'
import placeholderImage from '../../../../asset/images/placeholder.jpg';
import AppLoader from '../../../components/fields/AppLoader';
const defaultParams = {
    offer: {
        offerType: 'COMBO_OFFER',
        offerValue: '',
        expiryOn: null,
        validFrom: null
    },
    products: [],
    productSkus: [],
    offerPrice: '',
    expiryOn: null,
    validFrom: null,
};

const ComboOfferModal = ({ modalData = null, onClose = () => null, view }) => {
    const initialData = modalData.id ? modalData : defaultParams
    const [productsObject, setProductsObject] = useState(initialData);
    const [loader, setLoader] = useState(false)
    const [validationError, setFilterValidationError] = useState(null);
    const [productType, setProductType] = useState('')
    const dispatch = useDispatch();
    const createNewCombo = () => {

        const params = { ...productsObject };
        if (!modalData.id && productType === '') {
            setFilterValidationError('Please choose product type')
            return;
        }
        if (!params.validFrom) {
            setFilterValidationError('Please choose valid from.');
            return;
        }

        if (!params.expiryOn) {
            setFilterValidationError('Please choose expiry on date.');
            return;
        }
        if (!params.offerPrice) {
            setFilterValidationError('Please enter combo price.');
            return;
        } if (params.offerPrice <= 0) {
            setFilterValidationError('OfferPrice should not be in 0 and negative numbers');
            return;
        }
        if (moment(params.validFrom).isAfter(moment(params.expiryOn))) {
            setFilterValidationError('Valid from date cannot be before expiry on date.');
            return;
        }
        if (moment(params.validFrom).isSameOrAfter(moment(params.expiryOn))) {
            setFilterValidationError('Valid start date time cannot be same as end date time.');
            return;
        }
        if (params?.productSkus?.length === 0) {
            setFilterValidationError('Please choose a product.');
            return;
        }

        if (params?.productSkus?.length === 1) {
            setFilterValidationError('Please choose one or more product.');
            return;
        }
        const productTypes = productType === 'bistro' ? true : false
        var products = params?.productSkus && params?.productSkus.map(o => ({ productSku: o.value, bistroProduct: productTypes }));
        let startDate = UTCViewFormat(params.validFrom)
        let endDate = UTCViewFormat(params.expiryOn)
        params.products = products;
        params.offer.validFrom = params.validFrom;
        params.offer.expiryOn = params.expiryOn;
        params.offer.offerValue = params.offerPrice;
        params.active = true
        params.bistroProduct = productType === 'bistro' ? true : false
        params.startDateTimeString = startDate
        params.endDateTimeString = endDate

        if (modalData?.id) {
            const { bistroProduct } = params || {}
            params.bistroProduct = bistroProduct
        } else {
            params.bistroProduct = productType === 'bistro' ? true : false
        }
        let insertOrUpdate = Actions.createComboOfferList;
        if (modalData.id) {
            insertOrUpdate = Actions.updateComboOfferList;
        }
        setLoader(true)
        dispatch(insertOrUpdate(params, (response) => {
            if (response.response_code !== 0) {
                setFilterValidationError(response.response_message);
                setLoader(false)
            } else {
                onClose(true);
                setLoader(false)
            }
        }));
    }
    const addProducts = (value) => {
        const dataObj = { ...productsObject };
        dataObj.productSkus = value ? value : []
        setProductsObject(dataObj);
    }
    return <React.Fragment>
        <div className="modal d-block"
            id="unblock-modal"
            tabIndex="-1"
            aria-labelledby="unblock"
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                {loader && <AppLoader />}
                <div className="modal-content rounded-0">
                    <img id='modalClose' src={Close} alt="X"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        className="modal-close" onClick={() => onClose(false)} />

                    <div className="modal-header rounded-0 align-items-center py-4">
                        <div className="col">
                            <h5 className="modal-title">
                                {modalData.id ? modalData.viewMode ? "Combo Offer" : 'Edit Combo Offer' : 'Add New Combo Offer'}</h5>
                        </div>
                    </div>
                    <div className="modal-body font13 p-40">
                        {validationError && <BoldError message={validationError} />}
                        {(!modalData.id && !modalData.viewMode) && <div className="row mb-4">
                            <div className="col">
                                <label for="category" className="form-label fw-bold">Product Type</label>
                                <select
                                    id="category"
                                    name="productType"
                                    className="form-select"
                                    value={productType}
                                    onChange={(e) => {
                                        setProductType(e?.target?.value);
                                        setProductsObject(initialData);
                                    }
                                    }
                                >
                                    <option value=""> - Select Product type - </option>
                                    {
                                        Constants.PRODUCT_TYPE.map((o) => {
                                            return (
                                                <option key={''} value={o.value}>{o.KEY}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>}

                        <div className="row mb-4">
                            <div className="col-6">
                                <label for="from-date" className="form-label fw-bold">Valid From</label>
                                <AppDatePicker
                                    id="AppDatePicker"
                                    minDate={new Date()}
                                    disabled={modalData.viewMode}
                                    selected={productsObject.validFrom}
                                    onChange={(value) => setProductsObject({
                                        ...productsObject,
                                        validFrom: value
                                    })}
                                    placeholder={'Valid From'}
                                />
                            </div>
                            <div className="col-6">
                                <label for="to-date" className="form-label fw-bold">Valid To</label>
                                <AppDatePicker
                                    id="EditAppDatePicker"
                                    minDate={productsObject?.validFrom ? productsObject?.validFrom : new Date()}
                                    selected={productsObject.expiryOn}
                                    onChange={(value) => setProductsObject({
                                        ...productsObject,
                                        expiryOn: value
                                    })}
                                    placeholder={'Valid To'}
                                />
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-6">
                                <label className="form-label fw-bold">Combo Price</label>
                                <div className="input-group">
                                    <span className="input-group-text border-dark">$</span>
                                    <input type="number" className="form-control"
                                        id="OffePrice"
                                        placeholder="Enter Combo Price"
                                        disabled={modalData.viewMode}
                                        value={productsObject.offerPrice}
                                        onChange={(e) => setProductsObject({
                                            ...productsObject,
                                            offerPrice: e.target.value
                                        })}
                                    />
                                </div>
                            </div>
                        </div>

                        {(!modalData.id && !modalData.viewMode) && <div className="row mb-5">
                            <div className="col">
                                <label for="product-list"
                                    className="form-label fw-bold">Choose Products</label>
                                <ProductsTypeAhead
                                    id="ProductsTypeAhead"
                                    key={productType}
                                    productType={productType}
                                    style={{ margin: '10px' }}
                                    placeholder="Select Product"
                                    isMulti={true}
                                    onChange={addProducts}
                                    disabled
                                />
                            </div>
                        </div>}
                        {
                            modalData?.productSkus?.length > 0 && modalData?.id && <>
                                <div className="fw-bold pt-4"> Products </div>
                                <hr className="thick" />
                                {
                                    modalData?.productSkus?.map((o, index) => {
                                        return (
                                            <div className="row align-items-center mb-3">
                                                <div className="col-auto fw-bold">{index + 1}.</div>
                                                {/* <div className="col-auto">
                                                    <img src={o?.imageUrl ? o?.imageUrl : placeholderImage} width="32" />
                                                 </div> */}
                                                <div className="col fw-bold title-case"> {o?.productName?.toLowerCase()} <small className="fw-normal">(SKU {o?.sku})</small></div>
                                            </div>
                                        )
                                    })
                                }

                            </>
                        }

                        <div className="row mb-4">
                            <div id='createNew' className="col-7 pe-0" onClick={() => createNewCombo()}>
                                <span className="btn btn-success cursor-pointer font14 w-100 p-3">{modalData.id ? 'Update' : 'Create'}</span>
                            </div>
                            <div id='onClose' className="col-5 text-end" onClick={() => onClose(false)}>
                                <span className="btn btn-outline-danger font14 w-100 p-3 cursor-pointer"> Cancel</span>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
        <div className="modal-backdrop show"></div>
    </React.Fragment>
}

export default ComboOfferModal;