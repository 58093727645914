import { all, put, takeLatest } from "@redux-saga/core/effects";
import { doPromotionGet, doPromotionPost } from "../../utils/service";
import END_POINTS from "../../utils/service/Endpoints";
import Actions from "../actions";

export function* getCustomerCouponList({ params }) {
    try {
        let response = yield doPromotionGet(END_POINTS.COUPON_LIST, params)
        if (response) {
            yield put(Actions.getCustomerCouponSuccess(response));
        }
    } catch (error) {
        yield put(Actions.getCustomerCouponFailure({ error }))
    }

    try {
        let response = yield doPromotionGet(END_POINTS.CUSTOMER_LIST, params = {
            limit: 400,
            offset: 0,
            name: ''
        })
        if (response) {
            if (response?.response_code === 0) {
                yield put(Actions.getAllCustomerListSuccessAction(response));
            } else {
                yield put(Actions.getAllCustomerListFailureAction())
            }
        }
    } catch (error) {
        yield put(Actions.getAllCustomerListFailureAction({ error }))
    }

}

export function* getCustomerList({ callback, params }) {
    try {
        let response = yield doPromotionGet(END_POINTS.CUSTOMER_LIST, params)
        if (response) {
            if (response?.response_code === 0) {
                yield put(Actions.getAllCustomerListSuccessAction(response));
            } else {
                yield put(Actions.getAllCustomerListFailureAction())
            } 
            callback && callback(response)
        }

    } catch (error) {
        yield put(Actions.getAllCustomerListFailureAction({ error }))
    }
}

export function* createCustomerCoupon({ params, callback }) {
    try {
        const response = yield doPromotionPost(END_POINTS.CUSTOMER_COUPON_CREATE, params);
        if (response) {
            yield put(Actions.createCustomerCouponSuccess(response));
        }
        callback && callback(response);
    }
    catch (error) {
        callback && callback(error);
        yield put(Actions.createCustomerCouponFailure({ error }));
    }
}
export function* updateCustomerCoupon({ params, callback }) {
    try {
        const response = yield doPromotionPost(END_POINTS.CUSTOMER_COUPON_UPDATE, params);
        if (response) {
            yield put(Actions.updateCustomerCouponSuccess(response));
        }
        callback && callback(response);
    }
    catch (error) {
        callback && callback(error);
        yield put(Actions.updateCustomerCouponFailure({ error }));
    }
}
export function* deleteCustomerCouponSaga({ params, callback }) {
    try {
        let response = yield doPromotionPost(params);
        if (response) {
            yield put(Actions.deleteCustomerCouponSuccess(response));
        }
        callback && callback(response);
    } catch (error) {
        yield put(Actions.deleteCustomerCouponFailure({ error }));
        callback && callback(error);
    }
}
export default function* customerCouponsListener() {
    yield all([
        takeLatest(Actions.CUSTOMER_COUPON, getCustomerCouponList),
        takeLatest(Actions.CUSTOMERS_LIST, getCustomerList),
        takeLatest(Actions.CREATE_CUSTOMER_COUPON, createCustomerCoupon),
        takeLatest(Actions.UPDATE_CUSTOMER_COUPON, updateCustomerCoupon),
        takeLatest(Actions.DELETE_CUSTOMERS_COUPON_LIST, deleteCustomerCouponSaga)

    ]);
}
