import Actions from "../actions";

const initialState = {
    isLoading: false,
    planogram: [],
    error: false,
}
const PlanogramReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.PLANOGRAM: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.PLANOGRAM_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                planogram: action?.data?.response?.rack || [],
                store: action?.data?.response || {},
                error: false
            };
        }
        case Actions.PLANOGRAM_FAILURE: {
            return {
                ...state,
                isLoading: false,
                planogram: [],
                error: action.error,
            };
        }
        default:
            return state;
    }
};

export default PlanogramReducer;

export const getPlanogramSelector = state => state?.PLANOGRAM?.planogram;
export const getPlanogramStoreSelector = state => state?.PLANOGRAM?.store;
export const getPlanogramLoading = state => state?.PLANOGRAM?.isLoading