import React, { useEffect } from 'react';
import { bistroCustomerDisplayListSelector, bistroCustomerDisplayLoader } from '../../redux/reducers/BistroDisplayReducer';
import { useDispatch, useSelector } from 'react-redux';
import Actions from '../../redux/actions';
const BistroDisplay = () => {
    const orderList = useSelector(bistroCustomerDisplayListSelector);
    const isLoading = useSelector(bistroCustomerDisplayLoader)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(Actions.bistroDisplayCustomerOrdersAction());
    }, [dispatch]);
    const data = React.useMemo(
        () => orderList,
        [orderList]
    );
    useEffect(() => {
        const interval = setInterval(() => {
            dispatch(Actions.bistroDisplayCustomerOrdersAction());
        }, 15000);
        return () => clearInterval(interval);
    }, [dispatch]);
    return <div className=''>
        {
            data && data?.length > 0 &&
            <div className='dashboard-OrderSummary-container'>
                <div className='d-flex flex-wrap mt-4 justify-content-center'>

                    {
                        data && data?.map((o) => {
                            return (
                                <div className="card-analytics-dashboard">
                                    <h6 className="card-text-font4">Order no #{o?.id}</h6>
                                    {
                                        o?.orderStatus === 2 ? <span className="card-text" style={{ color: 'green' }}>
                                            Preparing
                                        </span> : <span className="card-text" style={{ color: 'red' }}>
                                            Ready to pickup
                                        </span>
                                    }

                                </div>
                            )
                        })
                    }
                </div>
            </div>

        }
        {
            (data && data.length === 0 && !isLoading) && <div style={{ position: 'absolute', top: '50vh', left: '40vw' }}>
                <h1>No Orders Found</h1>
            </div>
        }
    </div>
};


export default BistroDisplay;