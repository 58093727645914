import { doAction, doResponseAction } from ".";

const DASHBOARD_OFFER_LIST = 'DASHBOARD_OFFER_LIST';
const DASHBOARD_OFFER_LIST_SUCCESS = 'DASHBOARD_OFFER_LIST_SUCCESS';
const DASHBOARD_OFFER_LIST_FAILURE = 'DASHBOARD_OFFER_LIST_FAILURE'

export {
    DASHBOARD_OFFER_LIST,
    DASHBOARD_OFFER_LIST_SUCCESS,
    DASHBOARD_OFFER_LIST_FAILURE,
}

export const getDashboardOfferListAction  = (params, callback) => doAction(DASHBOARD_OFFER_LIST, params, callback);
export const getDashboardOfferListActionSuccess= (data) => doResponseAction(DASHBOARD_OFFER_LIST_SUCCESS, data);
export const getDashboardOfferActionFailure = (data) => doResponseAction(DASHBOARD_OFFER_LIST_FAILURE, data);