import React, { useEffect, useState } from "react";
import Close from '../../asset/new/img/modal-close.svg';
import { ProgressBar } from "./ProgressBar";
import { ReactPictureAnnotation, defaultShapeStyle } from "react-picture-annotation";
import { Dropdowns } from '../components/AnnotationDropDown';
import { useSelector, useDispatch } from "react-redux";
import { getCameraList, getProductList, getRackList, getCvProductList } from "../redux/reducers/planogramCV/GetCameraListReducer";
import Actions from "../redux/actions";
import { toast } from "react-toastify";
import AppLoader from "./fields/AppLoader";
import CreateCanva from "./cvPlanogram/CreateCanva";
import { RackType, getPlanogramDetailSelector } from "../redux/reducers/PlanogramDetailReducer";
import { getRackInformation } from "../redux/reducers/planogramCV/RackCaptureReducer";
import { getSelectedStoreSelector, } from '../redux/reducers/StoreReducer';
import check from "../../asset/images/tick.png"
import warning from "../../asset/images/alert-triangle.svg"
import { sort_products } from "../utils";
export const PlanogramPopup = (props) => {
    const dispatch = useDispatch();
    const [currentStep, updateCurrentStep] = useState(0);
    const [imageSize, setImageSize] = useState({
        width: 0,
        height: 0
    });
    const [rackId, setRackId] = useState(0)
    const [containerSize, setContainerSize] = useState({
        width: 0,
        height: 0
    });
    const [productData, setProductData] = useState([])
    const [duplicate, setDuplicateData] = useState([])
    const [deleteButton, setDeleteButton] = useState(false)
    const [triggerChildDelete, setTriggerChildDelete] = useState(false)
    const [productLength, setProductLenght] = useState(0)
    const [frameId, setFrameId] = useState(0)
    const cameraList = useSelector(getCameraList);
    const rackData = useSelector(getRackList);
    const [cabinetID, setCabinetId] = useState(rackData?.camera ? rackData.camera : '');
    const [fetchFrame, setFetchFrame] = useState(undefined);
    const [EnableNext, setEnableNext] = useState(false);
    const [unalignedProducts, setUnalignedProducts] = useState([]);
    const [productAnnotationData, setProductAnnotationData] = useState([]);
    const [rackAnnotationData, setRackAnnotationData] = useState([]);
    const [currentAnoation, setCurrentAnotation] = useState('')
    const [isLoading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState('')
    const [checkAnnotation, setCheckAnnotation] = useState(false)
    const productListData = useSelector(getProductList);
    const cvProductListData = useSelector(getCvProductList);
    const [showinstruction, setShowInstruction] = useState(productListData)
    const rackType = useSelector(RackType);
    const planogramDetail = useSelector(getPlanogramDetailSelector);
    const prepopulateRackData = useSelector(getRackInformation)
    const selectedStore = useSelector(getSelectedStoreSelector) || {};
    
    //To make a progress bar to change the state and operation
    const updateStep = (step) => {
        updateCurrentStep(step)
    }
    useEffect(() => {
        setCabinetId(rackData?.camera ? rackData.camera : '')
    }, [rackData]);

    const syncPlanogramWithCvObjects = (autofilldata = []) => {
        if (planogramDetail.rackType === 3) {
            const shelveList = planogramDetail.shelves.map((o) => o.bays).flat(1)
            const sortedProducts = sort_products(autofilldata.sort((a, b) => a.id - b.id), containerSize)
            const unalignedProducts = []
            shelveList.forEach((o, i) => {
                if (sortedProducts[i] && (parseInt(sortedProducts[i].comment) === o.cvProductId)) {
                    sortedProducts[i].positionId = o.id
                    sortedProducts[i].product_group_id = o.cvGroupId
                } else {
                    if (o.productDetails !== null) {
                        unalignedProducts.push(o)
                    }
                }
            })
            setUnalignedProducts(unalignedProducts)
            setProductAnnotationData(sortedProducts)
        } else {
            const shelveList = planogramDetail.shelves.map((o) => o.products).flat(1)
            const sortedProducts = sort_products(autofilldata.sort((a, b) => a.id - b.id), containerSize)
            const unalignedProducts = []
            shelveList.forEach((o, i) => {
                if (sortedProducts[i] && (parseInt(sortedProducts[i].comment) === o.cvProductId)) {
                    sortedProducts[i].positionId = o.id
                    sortedProducts[i].product_group_id = o.cvGroupId
                } else {
                    unalignedProducts.push(o)
                }
            })
            setUnalignedProducts(unalignedProducts)
            setProductAnnotationData(sortedProducts)
        }
    }
    //To change the progress bar previous and next step and API call
    const previousStep = () => {
        if (currentStep !== 0) {
            updateStep(currentStep - 1)
        }
        setCabinetId(cabinetID)
    }

    const getProductArray = (response) => {
        let productArray = [];
        cvProductListData?.forEach((product, index) => {
            productArray.push({
                key: index,
                value: product?.cvProductId,
                text: product?.productName
            })
        })
        return productArray
    }
    const nextStep = () => {
        if (currentStep !== 2) {
            if (currentStep === 0) {
                const getFrameCallback = (response) => {
                    setLoading(false)
                    if (response?.response_code === 0) {
                        setImageUrl(response?.response?.url)
                        setFrameId(response?.response?.frame_id)
                        setRackId(props.rackId)
                        updateStep(currentStep + 1)
                        if (!productListData && !rackData) {
                            setLoading(true)
                        }

                    }
                    else {
                        toast.error(response?.response_message)
                    }
                }
                let newFrame = productListData && rackData && (cabinetID === rackData.camera) ? false : true
                setLoading(true)
                dispatch(Actions.getCvPlanogramDetails({ cameraId: cabinetID, rackId: props.rackId, newFrame: newFrame, rackType: rackType }, getFrameCallback))

            }
            else if (currentStep === 1) {
                if (rackAnnotationData.length > 0) {
                    if (productListData && rackData && !fetchFrame) {
                        // setProductAnnotationData(productDataTopixel(productListData))
                        syncPlanogramWithCvObjects(productDataTopixel(productListData))
                        setProductData(getProductArray())
                        const prepopulatedData = duplicate.map((o) => {
                            return [((o[0] / containerSize?.width) * imageSize.width), ((o[1] / containerSize.height) * imageSize.height)]
                        })
                        let initialData = [...duplicate.flat()]
                        let currentData = [...rackAnnotationData.flat()]
                        let dataUpdated = false
                        if(initialData.length === 8) {
                            if(currentData.length === 8) {
                                initialData.forEach((o, i) => {
                                    if(o !== currentData[i]) {
                                        dataUpdated = true
                                    }
                                })
                            }
                        }
                        
                        if (!dataUpdated) {
                            updateStep(currentStep + 1)
                        } else {
                            // setProductAnnotationData([])
                            syncPlanogramWithCvObjects([])
                            setRackAnnotation(prepopulatedData, !dataUpdated)
                        }
                    }
                    else {
                        setProductData(getProductArray())
                        setRackAnnotation()
                    }
                }
            }
        }
        else {
            updateAnnotation()
        }
    }

    //The prepopuplate data for the rack to convert into pixel for the orginal data
    const orginalRackDataToPixel = (rackdata) => {
        let orginalData = [
            [(rackdata?.tl_x * imageSize?.width), (rackdata?.tl_y * imageSize?.height)],
            [(rackdata?.tr_x * imageSize?.width), (rackdata?.tr_y * imageSize?.height)],
            [(rackdata?.bl_x * imageSize?.width), (rackdata?.bl_y * imageSize?.height)],
            [(rackdata?.br_x * imageSize?.width), (rackdata?.br_y * imageSize?.height)],
        ]
        return orginalData
    }

    //The prepopuplate data for the ProductDetails to convert into pixel
    const productDataTopixel = (product) => {
        let data = [];
        product?.forEach((item, index) => {
            data.push({
                comment: item?.training_id,
                id: index,
                mark: {
                    x: item?.xtl * imageSize.width,
                    y: item?.ytl * imageSize.height,
                    width: (item?.xbr * imageSize.width) - (item?.xtl * imageSize.width),
                    height: (item?.ybr * imageSize.height) - (item?.ytl * imageSize.height)
                }
            })
        })
        return data
    }

    const autoFillHandler = () => {
        let params = {
            cameraId: cabinetID ,
            rackId: rackId
        }
        const callback = (response) => {
            setLoading(false)
            let autofilldata = [];
            if (response?.response_code === 0) {
                let { contents } = response?.response || {}
                setProductLenght(contents?.productdetails.length)
                contents?.productdetails?.map((annotation, index) => {
                    autofilldata.push({
                        comment: annotation?.product_id,
                        id: index,
                        mark: {
                            x: annotation?.xtl * imageSize.width,
                            y: annotation?.ytl * imageSize.height,
                            width: (annotation?.xbr * imageSize.width) - (annotation?.xtl * imageSize.width),
                            height: (annotation?.ybr * imageSize.height) - (annotation?.ytl * imageSize.height)
                        }
                    })
                })
                // setProductAnnotationData(autofilldata)
                syncPlanogramWithCvObjects(autofilldata)
            }
            else {
                toast.error(response?.response_message)
            }
        }
        dispatch(Actions.autoFill(params, callback))
        setLoading(true)
    }

    //It is send the rack_id and the co-ordinate in the backend formet
    const setRackAnnotation = (data, checkRackAnnotaion) => {
        const callback = (response) => {
            setLoading(false)
            if (response?.response_code === 0) {
                updateStep(currentStep + 1)
                toast.success(response?.response_message)
                setProductData(getProductArray(response)) //To make the drop down for select the product if it need(optional) in autofill API
            }
            else {
                toast.error(response?.response_message)
            }
        }
        const commonObjects = (checkRackAnnotaion ? data : rackAnnotationData)?.map(([xtl, ytl]) => ({ xtl, ytl }));
        const parmas = {
            cameraId: cabinetID,
            rackId: rackId,
            storeId: selectedStore.id || 1,
            frameId: frameId,
            rackType: rackType,
            top_left:
            {
                x: `${parseFloat(commonObjects[0]?.xtl / containerSize.width).toFixed(5)}`,
                y: `${parseFloat(commonObjects[0]?.ytl / containerSize.height).toFixed(5)}`
            },
            top_right:
            {
                x: `${parseFloat(commonObjects[1]?.xtl / containerSize.width).toFixed(5)}`,
                y: `${parseFloat(commonObjects[1]?.ytl / containerSize.height).toFixed(5)}`
            },
            bottom_right:
            {
                x: `${parseFloat(commonObjects[2]?.xtl / containerSize.width).toFixed(5)}`,
                y: `${parseFloat(commonObjects[2]?.ytl / containerSize.height).toFixed(5)}`
            },
            bottom_left:
            {
                x: `${parseFloat(commonObjects[3]?.xtl / containerSize.width).toFixed(5)}`,
                y: `${parseFloat(commonObjects[3]?.ytl / containerSize.height).toFixed(5)}`
            }

        }
        setLoading(true)
        dispatch(Actions.rack_Capture(parmas, callback))

    }

    //Select the camera for dropdown
    const selectCabinet = (event) => {
        if (event.target.value !== 0) {
            setCabinetId(event.target.value)
        }
    }

    //To update the product annoation given by the autofill
    const updateAnnotation = () => {
        const updateCallback = (response) => {
            setLoading(false)
            if (response?.response_code === 3) {
                toast.error(response?.response_message)
            }
            else {
                toast.success("Updated Successfully")
                props.close(false)
            }
        }
        let parmas;
        let planogram = [];
        let planogramObjectdata;
        productAnnotationData && productAnnotationData.forEach((o, index) => {
            planogramObjectdata = {
                product_id: o?.comment,
                product_group_id: o?.product_group_id,
                position_id: o?.positionId,
                product_name: o?.mark?.text,
                position: index,
                xtl: `${parseFloat(o?.mark?.x / imageSize.width).toFixed(5)}`,
                ytl: `${parseFloat(o?.mark?.y / imageSize.height).toFixed(5)}`,
                xbr: `${(parseFloat(o?.mark?.x + o?.mark?.width) / imageSize.width).toFixed(5)}`,
                ybr: `${(parseFloat(o?.mark?.y + o?.mark?.height) / imageSize.height).toFixed(5)}`,
            }
            planogram.push(planogramObjectdata)
            parmas = {
                rackId: rackId,
                product_details: planogram
            }
        })
        dispatch(Actions.updataPlanogramProduct(parmas, updateCallback))
        setLoading(true)

    }
    const selectedCurrentAnotation = (selectedData, productAnnotationData) => {
        setCurrentAnotation(selectedData)
    }

    const checkAnnotationData = (anoData) => {
        function isComment(box) {
            return ('comment' in box)
        }
        setCheckAnnotation(!anoData.every(isComment))
    }
    const handleAnnotationChange = (anoData) => {
        checkAnnotationData(productAnnotationData)
        // setProductAnnotationData(anoData)
        syncPlanogramWithCvObjects(anoData)
    }
    //sort the co-ordinates for find the top-left,top-right,bottom-right,bottom-left
    const sortAnnotationData = (array) => {
        let top_left, bottom_right, bottom_left, top_right;
        const positionYSort = [...array].sort((a, b) => a[1] - b[1]);
        const positionXScort = [...positionYSort].sort((a, b) => a[0] - b[0]);
        positionYSort[0][0] < positionYSort[1][0] ? top_left = positionYSort[0] : top_left = positionYSort[1]
        positionXScort[2][1] > positionXScort[3][1] ? bottom_right = positionXScort[2] : bottom_right = positionXScort[3]
        positionYSort[2][0] < positionYSort[3][0] ? bottom_left = positionYSort[2] : bottom_left = positionYSort[3]
        let array2 = [
            top_left, bottom_right, bottom_left
        ]
        top_right = array.filter(function (obj) { return array2.indexOf(obj) == -1; });
        const sortedAnnotationData = [
            top_left,
            top_right[0],
            bottom_right,
            bottom_left
        ]
        return sortedAnnotationData
    }


    //It is to get the rack co-ordinates from child component(createCanva) to parenet component
    const getRackData = (data, imageSize) => {
        setImageSize(imageSize)
        if (data.length === 4) {
            setEnableNext(false)
            setRackAnnotationData(sortAnnotationData(data))
        }
        else {
            setEnableNext(true)
            setRackAnnotationData([])
            setShowInstruction("")
        }

    }


    //Fetch current frame for in the step 2
    const fetchCurrentFrame = () => {
        setFetchFrame(true)
        // setProductAnnotationData([])
        syncPlanogramWithCvObjects([])
        setRackAnnotationData([])
        const callback = (response) => {
            if (response?.response_code === 0) {
                setImageUrl(response?.response?.url)
                setFrameId(response?.response?.frame_id)
                productListData = []
                rackData = []
                setLoading(false)
            }
            else {
                toast.error(response?.response?.response_message)
            }
        }
        setLoading(true)
        dispatch(Actions.getCvPlanogramDetails({ cameraId: cabinetID, rackId: props.rackId, newFrame: true }, callback))
    }
    const undo = () => {
        productAnnotationData.splice(-1)
        // setProductAnnotationData([...productAnnotationData])
        syncPlanogramWithCvObjects([...productAnnotationData])
    }
    const getStep3Style = (currentStep, height) => {
        if (currentStep === 2) {
            return { height: `${height}px` }
        }
        return {}
    }
    const handleDelete = () => {
        setTriggerChildDelete(true)
    }
    return (
        <>
            {
                <img id="imageData"
                    className="img-fluid"
                    style={{ display: 'none' }}
                    src={imageUrl}
                    onLoad={(data) => {
                        const offsetWidth = document.getElementsByClassName('subModel-body2')[0]?.offsetWidth;
                        const percentageWidth = offsetWidth / 100
                        const imageHeightPercent = (data.target.height / data.target.width) * 100
                        setContainerSize({
                            width: offsetWidth,
                            height: percentageWidth * imageHeightPercent,
                        })
                        setImageSize({
                            width: (data.target.width),
                            height: (data.target.height),
                        })
                        setLoading(false)
                        if (productData && rackData) {
                            const rackDataToPixel = (rackdata) => {
                                let data = [
                                    [(rackdata?.tl_x * offsetWidth), (rackdata?.tl_y * (percentageWidth * imageHeightPercent))],
                                    [(rackdata?.tr_x * offsetWidth), (rackdata?.tr_y * (percentageWidth * imageHeightPercent))],
                                    [(rackdata?.br_x * offsetWidth), (rackdata?.br_y * (percentageWidth * imageHeightPercent))],
                                    [(rackdata?.bl_x * offsetWidth), (rackdata?.bl_y * (percentageWidth * imageHeightPercent))],
                                ]
                                return data
                            }
                            setDuplicateData(rackDataToPixel(rackData))
                            setRackAnnotationData(rackDataToPixel(rackData))
                        }
                    }}
                    alt=""
                />
            }
            <div className="modal d-block"
                id="view-multi-modal"
                tabIndex="-1"
                aria-labelledby="view-multi-modal"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-xl planogram-div">
                    <div className="modal-content rounded-0">
                        <img id="close"
                            src={Close}
                            alt="X"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            className="modal-close"
                            onClick={() =>
                                props.close(false)
                            } />
                        <div className="modal-header rounded-0 align-items-center py-4 px-4">
                            <div className="col">
                                <h5 className="modal-title">
                                    {
                                        currentStep === 0 ? 'Select Camera' : currentStep === 1 ? 'Annotate Rack' : 'Annotate Product'
                                    }</h5>
                            </div>
                        </div>
                        <div className="modal-body font14 p-40">
                            <div className={
                                `${currentStep === 0 ? "subModel-body1"
                                    : currentStep === 1 ? "subModel-body2"
                                        : "subModel-body3"}`} >
                                <ProgressBar updateStep={updateStep} currentStep={currentStep} /> {/*----- Progress Bar----- */}

                                {
                                    (currentStep === 1) ?
                                        <div className="row mb-4">
                                            <div className={(deleteButton && rackAnnotationData?.length > 0 && (productListData && rackData)) ? "col-6" : "col-9"}></div>
                                            {
                                                (deleteButton && rackAnnotationData?.length > 0) &&
                                                <div className="col-3">
                                                    <button
                                                        id="onClose"
                                                        data-bs-dismiss="modal"
                                                        className="btn font14 p-2 deleteButton" onClick={handleDelete}>
                                                        Delete
                                                    </button>
                                                </div>
                                            }
                                            {
                                                (productListData && rackData) &&
                                                <div className="col-3">
                                                    <button
                                                        id="onClose"
                                                        data-bs-dismiss="modal"
                                                        className="btn btn-dark font14 p-2 nextButton" onClick={fetchCurrentFrame}>
                                                        Fetch Current Frame
                                                    </button>
                                                </div>
                                            }
                                        </div> : ""
                                }
                                {
                                    currentStep === 1 ?
                                        <div className="alert alert-warning" role="alert">
                                            <ul className="stepTwoInstruction">
                                                <li className="mb-4">
                                                    <img src={(showinstruction) || rackAnnotationData.length !== 0 ? check : warning} alt="checkimage" width={"20px"} height={"20px"} />
                                                    <span className={(showinstruction) || rackAnnotationData.length !== 0 ? 'checkData' : "instructionWarning"}>Mark all 4 corners of the rack before proceed next.</span>
                                                </li>
                                                <li>
                                                    If you want to fetch the current frame. Click <b>Fetch Current Frame</b> button before mark all 4 corners.
                                                </li>
                                            </ul>
                                        </div> : currentStep === 2 ?
                                            <>
                                                <div className="alert alert-warning" role="alert">
                                                    {
                                                        rackType !== 3 ?
                                                            <ul className="autofillInstruction">
                                                                <li>
                                                                    If you are first time configuring the Rack click Autofill and Make sure all the products are captured in right position.
                                                                </li>
                                                                <li>
                                                                    If Rack already configured you want to update it. Make sure all the products are captured in right position.
                                                                </li>
                                                            </ul> :
                                                            <ul className="autofillInstruction">
                                                                <li>
                                                                    If you are first time configuring the Rack Mark all the products position and Make sure all the products are in right position.
                                                                </li>
                                                                <li>
                                                                    If Rack already configured you want to update it. Mark all the products position and Make sure all the products are in right position.
                                                                </li>
                                                            </ul>
                                                    }

                                                </div>

                                            </> : <></>
                                }
                                {currentStep === 0 ?
                                    <div className="row">
                                        <div className="col-6">
                                            <select className="form-select selectionMenu1" aria-label="Default select example" value={cabinetID} onChange={(event) => selectCabinet(event)}>
                                                <option value={''}>Select Camera</option>
                                                {(cameraList && cameraList.length > 0) && cameraList?.map((item, index) => {
                                                    return (
                                                        <option value={item.id.toString()} key={index} >
                                                            {item.camera_name}
                                                        </option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    : currentStep === 1 ?
                                        <CreateCanva
                                            image={imageUrl}
                                            width={imageSize?.width}
                                            height={imageSize?.height}
                                            handleSetRackData={getRackData}
                                            rackId={rackId}
                                            prepopulate={productListData && rackData ? true : false}
                                            rackCoordinates={rackAnnotationData}
                                            orginalRackCoordinates={orginalRackDataToPixel(rackData)}
                                            fetchNewFrame={fetchFrame}
                                            triggerDelete={triggerChildDelete}
                                            changeDeleteState={() => setTriggerChildDelete(false)}
                                            deleteButton={() => setDeleteButton(true)}
                                            productData={() => setProductAnnotationData([])}
                                            newFrame={() => setFetchFrame(undefined)}
                                        />
                                        :
                                        <div style={getStep3Style(currentStep, containerSize?.height)}>
                                            <ReactPictureAnnotation
                                                inputElement={(value, onChangeDropdown, onDeleteDropdown) => {
                                                    return (
                                                        <Dropdowns
                                                            inputLabel={value}
                                                            onChange={onChangeDropdown}
                                                            onDelete={onDeleteDropdown}
                                                            optionsProps={productData}
                                                        />
                                                    );
                                                }}
                                                image={imageUrl}
                                                onSelect={(selectedId) => selectedCurrentAnotation(selectedId, productAnnotationData)}
                                                onChange={(data) => handleAnnotationChange(data)}
                                                width={containerSize?.width}
                                                height={containerSize?.height}
                                                scrollSpeed={0}
                                                annotationStyle={{
                                                    ...defaultShapeStyle,
                                                    shapeStrokeStyle: "#2193ff",
                                                    transformerBackground: "black"
                                                }}
                                                annotationData={productAnnotationData}
                                            />
                                        </div>
                                }
                            </div>
                            {currentStep === 2 && (<div className="row mt-3">
                                <div className="col-12">
                                    {unalignedProducts.length > 0 && <div className="alert alert-danger" role="alert">
                                        <ul className="autofillInstruction">
                                            {
                                                unalignedProducts.map((unalignedProduct, index) => {
                                                    return (<li key={index}>
                                                        {unalignedProduct.productDetails.name}
                                                    </li>)
                                                })
                                            }
                                        </ul>
                                    </div>}
                                </div>
                            </div>)}
                            {
                                currentStep === 2 ?
                                    <div className="row autofillButton buttons">
                                        <div className={productAnnotationData?.length > 0 && rackType !== 3 ? "col-6" : "col-9"}></div>
                                        {
                                            productAnnotationData?.length > 0 &&
                                            <div className="col-3">
                                                <button
                                                    id="onClose"
                                                    ata-bs-dismiss="modal"
                                                    className="btn btn-dark font14 w-100 p-2"
                                                    disabled={currentStep === 0 ? true : false}
                                                    onClick={() => undo()}>
                                                    Undo
                                                </button>
                                            </div>
                                        }
                                        {
                                            rackType !== 3 &&
                                            <div className="col-3 autofill">
                                                <button
                                                    id="onClose"
                                                    ata-bs-dismiss="modal"
                                                    className="btn btn-dark font14 w-100 p-2"
                                                    disabled={currentStep === 0 ? true : false}
                                                    onClick={() => autoFillHandler()}>
                                                    Autofill
                                                </button>
                                            </div>
                                        }
                                    </div> : ""
                            }
                            <div className={`${currentStep === 1 || rackType === 3 ? " row my-4 buttons" : "row my-4 next-previous-button"}`}>
                                <div className="col-6"></div>
                                {
                                    currentStep === 0 ? <div className="col-9"></div> :
                                        <div className="col-3 popupButton"> <button id="onClose" data-bs-dismiss="modal" className="btn btn-dark font04 p-2 previousButton" onClick={() => previousStep()}> Previous</button> </div>
                                }
                                <div className="col-3 popupButton">
                                    <button id="onClose"
                                        data-bs-dismiss="modal"
                                        className="btn btn-dark font14 p-2 nextButton"
                                        disabled={(cabinetID !== '' ? false : true) || (currentStep === 1 && rackAnnotationData?.length === 0) || (currentStep === 2 && productAnnotationData?.length === 0) || (currentStep === 2 && unalignedProducts?.length > 0) || (currentStep === 2 && checkAnnotation) || (currentStep === 1 && EnableNext)}
                                        onClick={() => nextStep()}>{currentStep === 2 ? "Update" : "Next"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {isLoading && <AppLoader />}
            </div>
            <div className="modal-backdrop show"></div>
        </>
    )
}