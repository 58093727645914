import React from 'react'
import { withRouter } from 'react-router-dom'
import Gree from '../../../asset/new/img/modal-close.svg'
import QrReader from 'react-qr-scanner'
import Actions from '../../redux/actions'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import BoldError from '../../components/fields/BoldError'
import AppLoader from '../../components/fields/AppLoader'
import { getUserRoleSelector } from '../../redux/reducers/LoginReducer';

class QrCodeScanner extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            delay: 300,
            qrCode: 'No qrCode',
            orderDetailsModal: false,
            orderDetailsData: {},
            openQrScanner: this.props.openQrScanner,
            error: '',
            scanButton: false,
            initScan: true,
            isLoading: false
        }
    }

    handleScan(data) {
        const { deliverApi, setOrderdetailsData, setScanButton, setOrderDetailsModal, setDeliverBtn, orderID} = this.props
        const params = { qrCode: data?.text, currentOrderId: orderID }
        const callback = (resp) => {
            if (resp?.response_code === 0) {
                this.props.onFailure()
                if (!deliverApi) {
                    setDeliverBtn(false)
                    setScanButton(true)
                    setOrderdetailsData(resp?.response)
                    setOrderDetailsModal(true)
                    this.setState({ isLoading: false })
                    this.props.onFailure()
                } else {
                    const orderCallback = (res) => {
                        if (res?.response_code === 0) {
                            const { apiFilters, roleId } = this.props
                            const filterParams = { ...apiFilters };
                            if (roleId === 2) {
                                filterParams.type = 1
                            } else {
                                filterParams.type = 2
                            }
                            this.props.getOrderLists(filterParams)
                            this.setState({ isLoading: false })
                            this.props.onFailure()
                            setOrderDetailsModal(false)
                            toast.success(` Order succesfully delivered`)
                            this.props.onClose()

                        } else {
                            toast.error(res?.response_message, {
                                position: toast.POSITION.TOP_RIGHT,
                            });
                            this.setState({ isLoading: false })
                            this.setState({ error: res?.response_message })
                        }
                    }
                    const params = {
                        id: resp?.response?.invoiceOrderDetailsId,
                        status: 5
                    };
                    this.props.OrderDetailsUpdate(params, orderCallback)
                }
            } else if (resp?.response_message) {
                this.setState({ error: resp?.response_message, isLoading: false })
                toast.error(resp?.response_message, { position: toast.POSITION.TOP_RIGHT });

            } else {
                this.setState({ isLoading: false })
                toast.error(resp, { position: toast.POSITION.TOP_RIGHT });
            }
            this.setState({ isLoading: false })
        }
        if (data) {
            this.setState({ isLoading: true })
            if (this.state.initScan) {
                this.props.qrcode(params, callback)
                this.setState({ initScan: false })
            }
            const timer = setTimeout(() => { this.setState({ initScan: true }) }, 2000)
            return () => clearTimeout(timer)
        }


    }
    closeOrderDetailsModal() {
        this.setState({ orderDetailsModal: false })
    }
    handleError(err) {
        console.error(err)
    }
    render() {
        const { onFailure } = this.props
        const { error, isLoading } = this.state
        const previewStyle = {
            height: 260,
            width: 320,
        }
        return (
            <React.Fragment>
                <div className="modal d-block"
                    id="unblock-modal"
                    tabindex="-1"
                    aria-labelledby="unblock"
                    aria-hidden="true">
                    {isLoading && <AppLoader />}
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-body p-50">
                                <img
                                    src={Gree}
                                    alt="X"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    className="modal-close"
                                    id="Close"
                                    onClick={() => onFailure()} />
                                <h3>Scan the Qr Code in Scanner</h3>
                                <center>
                                    <QrReader
                                        style={previewStyle}
                                        delay={this.state.delay}
                                        onError={this.handleError}
                                        id='onScan'
                                        onScan={(e) => this.handleScan(e)} />
                                </center>
                                {error && <BoldError message={error} />}
                            </div>

                        </div>
                    </div>
                </div>
                <div className="modal-backdrop show"></div>
            </React.Fragment>
        )
    }
}
export const mapStateToProps = (state) => {
    return {
        QrCodeScannerLoading: Actions.getqrCodeScanner(state),
        roleId: getUserRoleSelector(state)
    };
};

export default withRouter(connect(mapStateToProps, {
    qrcode: Actions.qrCodeScannerAction,
    OrderDetailsUpdate: Actions.getOrderDetailsUpdate,
    orderDetails: Actions.getOrderDetails,
    getOrderLists: Actions.getOrderList
})(QrCodeScanner));