import { doAction,doResponseAction } from ".";

const BRANCH_LIST='BRANCH_LIST';
const BRANCH_LIST_SUCCESS='BRANCH_LIST_SUCCESS';
const BRANCH_LIST_FAILURE='BRANCH_LIST_FAILURE';

//CREATE
const CREATE_BRANCH_LIST='CREATE_BRANCH_LIST';
const CREATE_BRANCH_LIST_SUCCESS='CREATE_BRANCH_LIST_SUCCESS';
const CREATE_BRANCH_LIST_FAILURE='CREATE_BRANCH_LIST_FAILURE'

//UPDATE
const UPDATE_BRANCH_LIST='UPDATE_BRANCH_LIST';
const UPDATE_BRANCH_LIST_SUCCESS='UPDATE_BRANCH_LIST_SUCCESS';
const UPDATE_BRANCH_LIST_FAILURE='UPDATE_BRANCH_LIST_FAILURE';

const UPDATE_ADMIN_ACTIVE_STATUS='UPDATE_ADMIN_ACTIVE_STATUS';
const UPDATE_ADMIN_ACTIVE_STATUS_SUCCESS='UPDATE_ADMIN_ACTIVE_STATUS_SUCCESS';
const UPDATE_ADMIN_ACTIVE_STATUS_FAILURE='UPDATE_ADMIN_ACTIVE_STATUS_FAILURE';
const GET_ADMIN_STORE_LIST='GET_ADMIN_STORE_LIST';
const UPDATE_ADMIN_PASSWORD='UPDATE_ADMIN_PASSWORD';
const UPDATE_ADMIN_PASSWORD_SUCCESS='UPDATE_ADMIN_PASSWORD_SUCCESS';
const UPDATE_ADMIN_PASSWORD_FAILURE='UPDATE_ADMIN_PASSWORD_FAILURE';

export{
    BRANCH_LIST,
    BRANCH_LIST_SUCCESS,
    BRANCH_LIST_FAILURE,
    CREATE_BRANCH_LIST,
    CREATE_BRANCH_LIST_SUCCESS,
    CREATE_BRANCH_LIST_FAILURE,
    UPDATE_BRANCH_LIST,
    UPDATE_BRANCH_LIST_SUCCESS,
    UPDATE_BRANCH_LIST_FAILURE,
    UPDATE_ADMIN_ACTIVE_STATUS,
    UPDATE_ADMIN_ACTIVE_STATUS_SUCCESS,
    UPDATE_ADMIN_ACTIVE_STATUS_FAILURE,
    GET_ADMIN_STORE_LIST,
    UPDATE_ADMIN_PASSWORD,
    UPDATE_ADMIN_PASSWORD_SUCCESS,
    UPDATE_ADMIN_PASSWORD_FAILURE
}

export const getAllBranchListAction = (params, callback) => doAction(BRANCH_LIST, params, callback);
export const getAllBranchListSuccessAction = data => doResponseAction(BRANCH_LIST_SUCCESS, data);
export const getAllBranchListFailureAction = data => doResponseAction(BRANCH_LIST_FAILURE, data);

// CREATE
export const createBranchListAction  = (params, callback) => doAction(CREATE_BRANCH_LIST, params, callback);
export const createBranchListActionSuccess= (data) => doResponseAction(CREATE_BRANCH_LIST_SUCCESS, data);
export const createBranchListActionFailure = (data) => doResponseAction(CREATE_BRANCH_LIST_FAILURE, data);

//UPDATE
export const updateBranchListAction  = (params, callback) => doAction(UPDATE_BRANCH_LIST, params, callback);
export const updateBranchListActionSuccess= (data) => doResponseAction(UPDATE_BRANCH_LIST_SUCCESS, data);
export const updateBranchListActionFailure = (data) => doResponseAction(UPDATE_BRANCH_LIST_FAILURE, data);

export const updateAdminActiveStatus  = (params, callback) => doAction(UPDATE_ADMIN_ACTIVE_STATUS, params, callback);
export const updateAdminActiveStatusSuccess= (data) => doResponseAction(UPDATE_ADMIN_ACTIVE_STATUS_SUCCESS, data);
export const updateAdminActiveStatusFailure = (data) => doResponseAction(UPDATE_ADMIN_ACTIVE_STATUS_FAILURE, data);

export const updatePasswordAction  = (params, callback) => doAction(UPDATE_ADMIN_PASSWORD, params, callback);
export const updatePasswordActionSuccess= () => doResponseAction(UPDATE_ADMIN_PASSWORD_SUCCESS);
export const updatePasswordActionFailure= () => doResponseAction(UPDATE_ADMIN_PASSWORD_FAILURE);

