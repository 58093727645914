import Actions from "../actions";

const initialState = {
    isLoading: false,
    dashboardDetails: [],
    employeeId: '',
    error: false
}
const GetDashboardDetails = (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.GET_DASHBOARD_DETAILS: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.GET_DASHBOARD_DETAILS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                dashboardDetails: action.data || [],
                error: false
            };
        }
        case Actions.GET_DASHBOARD_DETAILS_FAILURE: {
            return {
                ...state,
                isLoading: false,
                dashboardDetails: [],
                error: action.error,
            };
        }
        case Actions.GET_DASHBOARD_CONTENT: {
            return {
                ...state,
                isLoading: false,
                employeeId: action.data || null,
                error: false
            };
        }
        case Actions.LOGOUT: {
            return {
                ...initialState
            };
        }
        default:
            return state;
    }
};

export default GetDashboardDetails;

export const getDashboardListSelector = state => state?.GET_DASHBOARD_DETAILS?.dashboardDetails;
export const getEmployeeId = state => state?.GET_DASHBOARD_DETAILS?.employeeId;
export const getDashboardLoadingSelector = state => state?.GET_DASHBOARD_DETAILS?.isLoading;