import { all, put, takeLatest } from "redux-saga/effects";
import { doPromotionGet, doPromotionPost } from "../../utils/service";
import END_POINTS from "../../utils/service/Endpoints";
import Actions from "../actions";

export function* FreeItemSaga({ params, callback }) {
    try {
        const response = yield doPromotionGet(END_POINTS.FREE_ITEMS, params);

        if (response) {
            yield put(Actions.getFreeItemsListSuccessAction(response));
        }
        callback && callback(response);
    }
    catch (error) {
        callback && callback(error);
        yield put(Actions.getAllFoodProductsListFailureAction({ error }));
    }
}

export function* createFreeItemsListSaga({ params, callback }) {
    try {
        let response = yield doPromotionPost(END_POINTS.CREATE_FREE_ITEMS_LIST, params)
        callback && callback(response);
        yield put(Actions.createFreeItemsListSuccess(response))
    } catch (error) {
        yield put(Actions.createFreeItemsListFailure({ error }));
        callback && callback(error);
    }
}


export function* updateFreeItemsListSaga({ params, callback }) {
    try {
        let response = yield doPromotionPost(END_POINTS.UPDATE_FREE_ITEMS_LIST, params)
        if (response) {
            callback && callback(response);
            yield put(Actions.updateFreeItemsListSuccess(response.response));
        }
    } catch (error) {
        yield put(Actions.updateFreeItemsListFailure({ error })) 
        callback && callback(error);
    }
}

export function* deleteFreeItemsListSaga({ params, callback }) {
    try {
        const response = yield doPromotionPost(params);
        if (response) {
            callback && callback(response);
            yield put(Actions.deleteFreeItemsListSuccess(response));
        }
    }
    catch (error) {
        callback && callback(error);
        yield put(Actions.deleteFreeItemsListFailure({ error }));
    }
}

export default function* freeItemsListener() {
    yield all([
        takeLatest(Actions.FREE_ITEMS, FreeItemSaga),
        takeLatest(Actions.FREE_ITEMS_UPDATE, updateFreeItemsListSaga),
        takeLatest(Actions.FREE_ITEMS_CREATE, createFreeItemsListSaga),
        takeLatest(Actions.DELETE_FREE_ITEMS, deleteFreeItemsListSaga),
    ]);
}
