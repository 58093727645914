import Actions from '../../actions';
const initialState = {
    isLoading: false,
    AutoFillDetails: {},
    error: false,
}
const autoFillReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.AUTOFILL: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.AUTOFILL_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                AutoFillDetails: action.data,
                error: false
            };
        }
        case Actions.AUTOFILL_FAILURE: {
            return {
                ...state,
                isLoading: false,
                AutoFillDetails: {},
                error: action.error,
            };
        }
        default:
            return state;
    }
};
export const getAutofillLoader = (state) => state?.AUTOFILL_REDUCER?.isLoading
export const getAutofillData = (state) => state?.AUTOFILL_REDUCER?.AutoFillDetails
export default autoFillReducer;