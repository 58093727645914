import Actions from "../actions";

const initialState = {
    isLoading: false,
    hitachiActiveList: [],
    error: false,
    hitachiMonitoringLoading: false,
    hitachiMonitoringError: false,
    hitachiMonitoringList: [],
    hitachiMonitoringCount: undefined,
    hitachiActiveCount: undefined
}
const OfferReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.HITACHI_ACTIVE_COUNT_LIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.HITACHI_ACTIVE_COUNT_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                hitachiActiveList: action?.data?.response || [],
                hitachiActiveCount: action?.data?.count,
                error: false
            };
        }
        case Actions.HITACHI_ACTIVE_COUNT_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                hitachiActiveList: [],
                error: action.error,
            };
        }

        case Actions.HITACHI_MONITORING_LIST: {
            return {
                ...state,
                hitachiMonitoringLoading: true,
            };
        }
        case Actions.HITACHI_MONITORING_LIST_SUCCESS: {
            return {
                ...state,
                hitachiMonitoringLoading: false,
                hitachiMonitoringList: action.data?.response || [],
                hitachiMonitoringCount: action?.data?.count,
                hitachiMonitoringError: false
            };
        }
        case Actions.HITACHI_MONITORING_LIST_FAILURE: {
            return {
                ...state,
                hitachiMonitoringLoading: false,
                hitachiMonitoringError: action.error,
            };
        }
        default:
            return state;
    }
};

export default OfferReducer;

export const getHitachiActiveCountSelector = state => state?.HITACHI_ACTIVE_COUNT_LIST?.hitachiActiveCount;
export const getHitachiMonitoringListSelector = state => state?.HITACHI_MONITORING_LIST;