import React from "react";
import { withRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import AppLoader from "./fields/AppLoader";
import ModalPopup, { ModalHeader } from "./modal";

class UnAssignProductModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const { isModalOpen, onCloseModal, onSubmitForm, isLoading,title ,header} = this.props;
        return (
            <React.Fragment>
                {isModalOpen && <ModalPopup className="modal-lg" onCloseModal={onCloseModal}>
                    <ModalHeader title={header} />
                    <div className="modal-container">
                        <div className="row mb-4">
                            <p>{title}</p>
                        </div>
                        <div className="row mt-5">
                            <div className="col">
                                <button
                                    id="onCloseModal"
                                    type="button"
                                    className="btn btn-outline-dark big-btn w-100 px-5 text-uppercase"
                                    onClick={() => onCloseModal()}
                                >
                                    Cancel
                                </button>
                            </div>
                            <div className="col">
                                <button
                                    id="submit"
                                    type="button"
                                    className="btn btn-dark big-btn w-100 px-5 text-uppercase"
                                    onClick={() => onSubmitForm()}
                                >
                                    Sure
                                </button>
                            </div>
                        </div>
                    </div>
                    {isLoading && <AppLoader />}
                </ModalPopup>
                }
            </React.Fragment>
        );
    }
}

export default withRouter(UnAssignProductModal);