import { put, takeLatest, select } from "redux-saga/effects";
import { doAuthGet, doAuthPost } from "../../utils/service";
import END_POINTS from "../../utils/service/Endpoints";
import Actions from "../actions";

export function* getNewNotificationSaga({ params, callback }) {
    try {
        const response = yield doAuthGet(END_POINTS.GET_NOTIFICATION_LIST, params);
        const currentData = yield select(Actions.getNewNotifications);
        if (response) {
            if ((currentData?.newNotificationsDetails?.length !== response?.count) && (params.offset !== 0)) {
                const data = {
                    count: response?.count,
                    response: [
                        ...currentData.newNotificationsDetails,
                        ...response?.response
                    ]
                }
                yield put(Actions.getNewNotificationsDetailsActionSuccess(data))
            } else {
                yield put(Actions.getNewNotificationsDetailsActionSuccess(response))
            }
        }
        callback && callback(response);
    }
    catch (error) {
        callback && callback(error);
        yield put(Actions.getNewNotificationsDetailsActionFailure({ error }));
    }
}

export function* getResolvedNotificationSaga({ params, callback }) {
    try {
        const response = yield doAuthGet(END_POINTS.GET_NOTIFICATION_LIST, params);
        const currentData = yield select(Actions.getNewNotifications);
        if (response) {
            if ((currentData?.resolvedNotificationsDetails?.length !== response?.count) && (params.offset !== 0)) {
                const data = {
                    count: response?.count,
                    response: [
                        ...currentData.resolvedNotificationsDetails,
                        ...response?.response
                    ]
                }
                yield put(Actions.getResolvedNotificationsDetailsActionSuccess(data))
            } else {
                yield put(Actions.getResolvedNotificationsDetailsActionSuccess(response))
            }
        }
        callback && callback(response);
    }
    catch (error) {
        callback && callback(error);
        yield put(Actions.getResolvedNotificationsDetailsActionFailure({ error }));
    }
}

export function* updateNotificationResolvedStatusSaga({ params, callback }) {
    try {
        const response = yield doAuthPost(END_POINTS.RESOLVE_NOTIFICATION, params);
        if (response) {
            const removedStatus = yield put(Actions.removeNewNotificationsAction(response.response));
            if (removedStatus) {
                // const appendStatus = yield put(Actions.appendResolvedNotificationsAction(response.response))
            }
        }
        callback && callback(response);
    }
    catch (error) {
        callback && callback(error);
        yield put(Actions.updateNotificationResolvedStatusFailure({ error }));
    }
}

export function* getNewNotificationByIdSaga({ params, callback }) {
    try {
        const response = yield doAuthGet(END_POINTS.GET_NOTIFICATION_BY_ID, params);
        if (response) {
            yield put(Actions.appendNewNotificationsActionSuccess(response.response));
        }
        callback && callback(response);
    }
    catch (error) {
        callback && callback(error);
        yield put(Actions.appendNewNotificationsActionFailure({ error }));
    }
}

export default function* getNotificationsListener() {
    yield takeLatest(Actions.GET_NEW_NOTIFICATIONS, getNewNotificationSaga);
    yield takeLatest(Actions.GET_RESOLVED_NOTIFICATIONS, getResolvedNotificationSaga);
    yield takeLatest(Actions.UPDATE_NOTIFICATIONS_RESOLVED_STATUS, updateNotificationResolvedStatusSaga);
    yield takeLatest(Actions.GET_NEW_NOTIFICATION_BY_ID, getNewNotificationByIdSaga);
}
