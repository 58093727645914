import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Actions from "../../redux/actions";
import { toast } from 'react-toastify';
import { getUserInfoSelector } from '../../redux/reducers/LoginReducer';
import BoldError from "../../components/fields/BoldError";
import AppDatePicker from '../../components/fields/AppDatePicker'
import {
    getReportTypeListSelector, getReportTypeListLoadingSelector,
    getViewReportListLoadingSelector, getSalesReportSelector
} from "../../redux/reducers/ReportTypeListReducers";
import { DateFormat } from '../../utils/commonFunctions/dateFormat';
import AppLoader from '../../components/fields/AppLoader';
import moment from 'moment'
const ReportsScreen = () => {
    const dispatch = useDispatch();
    const reportTypes = useSelector(getReportTypeListSelector);
    const reportTypeList = useSelector(getSalesReportSelector);
    const userInfo = useSelector(getUserInfoSelector)
    const [fromDate, setFromDate] = useState(null)
    const [toDate, setToDate] = useState(null)
    const [viewReport, setViewReport] = useState(false)
    const isLoading = useSelector(getReportTypeListLoadingSelector);
    const reportViewLoading = useSelector(getViewReportListLoadingSelector);
    const [validation, setValidation] = useState(null)
    const [reportType, setReportType] = useState("")
    const [onShowSalesReport, setOnShowSalesReport] = useState(false)
    useEffect(() => {
        dispatch(Actions.getreportTypeListAction());
    }, [dispatch]);

    const reportCallBack = (res) => {
        if (res?.response_code === 0) {
            dispatch(Actions.getReportAction({ url: res?.response, filename: `${reportType}${moment().format('DD-MM-YYYY HH:mm:ss')}` }));
            setValidation("")
        } else if (res.error) {
            toast.error(res.error, { position: toast.POSITION.TOP_RIGHT })

        }
    }

    // download report

    const onSubmitSalesReport = () => {
        if (!fromDate) {
            setValidation('Please choose from date')
        } else if (!toDate) {
            setValidation('Please choose to date')
        } else if (moment(fromDate).isAfter(moment(toDate))) {
            setValidation('Start date should not be greater than End date.');
            return;
        } else {
            let params = {
                fromDate: DateFormat(fromDate),
                toDate: DateFormat(toDate),
                reportType: reportType,
            }
            dispatch(Actions.downloadReportAction(params, reportCallBack));
        }
    }

    const onSubmitReport = () => {
        if (!fromDate) {
            setValidation('Please choose from date')
        } else if (!toDate) {
            setValidation('Please choose to date')
        } else if (!reportType) {
            setValidation("Please Select Report Type")
        } else if (moment(fromDate).isAfter(moment(toDate))) {
            setValidation('From date cannot be before to date.');
            return;
        } else {
            let params = {
                reportType: reportType,
                action: 'DOWNLOAD'
            }
            if (fromDate) {
                params['fromDate'] = DateFormat(fromDate)
            }

            if (toDate) {
                params['toDate'] = DateFormat(toDate)
            }
            dispatch(Actions.downloadReportAction(params, reportCallBack));

        }
    }

    const getReportType = (type) => {
        if (type === "DAILY_SALES_REPORT") {
            setOnShowSalesReport(true)
        } else {
            setOnShowSalesReport(false)
            setViewReport(false)
        }
        setReportType(type)
    }

    //Clear Input Fields
    const clear = () => {
        setFromDate(null);
        setToDate(null);
        setReportType("");
        setValidation("")
        setOnShowSalesReport(false)
        setViewReport(false)
    }

    const onViewReport = () => {
        if (!fromDate) {
            setValidation('Please choose from date')
        } else if (!toDate) {
            setValidation('Please choose to date')
        } else if (moment(fromDate).isAfter(moment(toDate))) {
            setValidation('Start date should not be greater than End date.');
            return;
        } else {
            let params = {};
            if (fromDate) {
                params['startDate'] = DateFormat(fromDate)
            }

            if (toDate) {
                params['endDate'] = DateFormat(toDate)
            }
            const callback = (res) => {
                if (res?.response_code === 0) {
                    setViewReport(true)
                    setValidation("")
                } else if (res.error) {
                    toast.error(res.error, { position: toast.POSITION.TOP_RIGHT })
                } else {
                    toast.error(res, { position: toast.POSITION.TOP_RIGHT })
                }
            }
            dispatch(Actions.getSalesReportAction(params, callback));

        }
    }


    return (
        <React.Fragment>
            <div className="col-10 ps-lg-4">
                <div className="row mb-3">
                    <div className="col"> <h1 className="font20 fw-bold">Reports</h1> </div>
                </div>
                <div id="report-options-bar" className="card card-body rounded-3 border-0 shadow p-4 mb-3 mb-xl-0">
                    {validation && <BoldError message={validation} />}
                    <form name="">
                        <div className="row mb-3 align-items-center">
                            <div className="col-12 pb-3">
                                <div className='row'>
                                    <div className='col-8 col-sm-4'>Select the dates for report</div>
                                    <div className='col-4 col-sm-8 text-end pl-2'>
                                        <span className='cursor-pointer fw-bold' id='clear' onClick={clear}>Clear</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-sm-4 col-lg-4">
                                <AppDatePicker
                                    selected={fromDate}
                                    placeholder="From"
                                    maxDate={new Date()}
                                    onChange={(date) => setFromDate(date)}
                                    notValidTime={true}
                                    id='FormDatePicker'
                                />
                            </div>
                            <div className="col-6 col-sm-4 col-lg-4">
                                <AppDatePicker
                                    selected={toDate}
                                    placeholder="To"
                                    maxDate={new Date()}
                                    onChange={(date) => setToDate(date)}
                                    notValidTime={true}
                                    minDate={fromDate}
                                    id='ToDatePicker'
                                />
                            </div>
                            <div className="col-12 col-sm-4 col-lg-4 mt-sm-0 mt-3">
                                <select name="" className="form-select"
                                    id="reportType"
                                    value={reportType}
                                    onChange={(e) => getReportType(e?.target?.value)}>
                                    <option value="">Choose any one of the report type</option>
                                    {reportTypes && reportTypes?.map((item) => {
                                        return (
                                            <option value={item?.report_type}>{item?.report_type}</option>)
                                    })}
                                </select>
                            </div>
                            {!onShowSalesReport ? <div className="row justify-content-end mt-sm-5 mt-3">
                                <div className="col-8 col-lg-3">
                                    <button type="button" className="btn btn-dark mb-3 w-100" onClick={() => onSubmitReport()}
                                        id="onSubmitReport" >Download Report</button>
                                </div>
                            </div> :
                                <div className="row justify-content-end mt-sm-5 mt-3">

                                    <div className="col-8 col-lg-3">
                                        <button type="button" className="btn btn-dark mb-3 w-100" onClick={() => onViewReport()}
                                            id="viewSubmitReport">View Report</button>
                                    </div>
                                    {viewReport && <div className="col-4 col-lg-2">
                                        <button className="btn btn-dark w-100" type="button" onClick={() => onSubmitSalesReport()}
                                            id="onSendEmail">Download</button>
                                    </div>}
                                </div>}
                        </div>
                    </form>
                    {viewReport && <>
                        <div className='Notification-Scroll'>
                            <div className='d-flex justify-content-between mt-5 mb-4'>
                            </div>

                            <div className="table-responsive sales-report mb-4">
                                <table className="table table-striped">
                                    <h2 className="fw-bold font18">OVERALL</h2>
                                    <thead>
                                        <tr>
                                            <th scope="col"></th>
                                            {reportTypeList?.weeklySalesDataList?.map((o, i) => {
                                                return (
                                                    <th scope="col" className='text-center'>
                                                        <span className='d-flex justify-content-center'></span>  {o?.date}
                                                    </th>
                                                )
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody className='text-center'>
                                        <tr>
                                            <th>Sales</th>
                                            {reportTypeList?.weeklySalesDataList?.map((o) => {
                                                return (<>
                                                    <td>{o?.sales?.toFixed(2)}</td>
                                                </>
                                                )
                                            })}
                                        </tr>
                                        <tr>
                                            <th>Transactions</th>
                                            {reportTypeList?.weeklySalesDataList?.map((o) => {
                                                return (<>
                                                    <td>{o?.transaction}</td>
                                                </>
                                                )
                                            })}
                                        </tr>
                                        <tr>
                                            <th>Sales per Transaction</th>
                                            {reportTypeList?.weeklySalesDataList?.map((o) => {
                                                return (<>
                                                    <td>{o?.average?.toFixed(2)}</td>
                                                </>
                                                )
                                            })}
                                        </tr>
                                        <tr>
                                            <th>Increase/decrease from prior year</th>
                                            {reportTypeList?.weeklySalesDataList?.map((o) => {
                                                return (<>
                                                    <td>{parseFloat(o?.overAllAverage)?.toFixed(2)}%</td>
                                                </>
                                                )
                                            })}
                                        </tr>
                                        <tr>
                                            <th>Sales prior year</th>
                                            {reportTypeList?.previousYearSales?.map((o) => {
                                                return (<>
                                                    <td>{o?.sales?.toFixed(2)}</td>
                                                </>
                                                )
                                            })}
                                        </tr>
                                        <tr>
                                            <th>Transactions Prior Year</th>
                                            {reportTypeList?.previousYearSales?.map((o) => {
                                                return (<>
                                                    <td>{o?.transaction}</td>
                                                </>
                                                )
                                            })}
                                        </tr>
                                        <tr>
                                            <th>Sales per Transaction prior year</th>
                                            {reportTypeList?.previousYearSales?.map((o) => {
                                                return (<>
                                                    <td>{o?.average?.toFixed(2)}</td>
                                                </>
                                                )
                                            })}
                                        </tr>
                                    </tbody>
                                    <span>&nbsp;</span>
                                    <span>&nbsp;</span>
                                    <h2 className="fw-bold font18">WIWO</h2>
                                    <thead>
                                        <tr>
                                            <th scope="col">Sales</th>
                                            {reportTypeList?.wiwoSalesData?.map((o) => {
                                                return (<>
                                                    <th scope="col" className='text-center'>{o?.sales?.toFixed(2)}</th>
                                                </>
                                                )
                                            })}

                                        </tr>
                                    </thead>
                                    <tbody className='text-center'>
                                        <tr>
                                            <th>Increase/decrease from prior year</th>
                                            {reportTypeList?.wiwoSalesData?.map((o) => {
                                                return (<>
                                                    <td>{parseFloat(o?.overAllAverage)?.toFixed(2)}%</td>
                                                </>
                                                )
                                            })}
                                        </tr>
                                        <tr>
                                            <th>Sales prior year</th>
                                            {reportTypeList?.previousYearWiwoSalesData?.map((o) => {
                                                return (<>
                                                    <td>{o?.sales?.toFixed(2)}</td>
                                                </>
                                                )
                                            })}
                                        </tr>
                                    </tbody>
                                    <span>&nbsp;</span>
                                    <span>&nbsp;</span>
                                    <h2 className="fw-bold font18">Bistro</h2>
                                    <thead>
                                        <tr>
                                            <th scope="col">Sales</th>
                                            {reportTypeList?.bistroSalesData?.map((o) => {
                                                return (<>
                                                    <th scope="col" className='text-center'>{o?.sales?.toFixed(2)}</th>
                                                </>
                                                )
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody className='text-center'>
                                        <tr>
                                            <th>Increase/decrease from prior year</th>
                                            {reportTypeList?.bistroSalesData?.map((o) => {
                                                return (<>
                                                    <td>{parseFloat(o?.overAllAverage)?.toFixed(2)}%</td>
                                                </>
                                                )
                                            })}
                                        </tr>
                                        <tr>
                                            <th>Sales prior year</th>
                                            {reportTypeList?.previousYearBistroSalesData?.map((o) => {
                                                return (<>
                                                    <td>{o?.sales?.toFixed(2)}</td>
                                                </>
                                                )
                                            })}
                                        </tr>
                                    </tbody>
                                    <span>&nbsp;</span>
                                    <span>&nbsp;</span>
                                    <h2 className="fw-bold font18">Hybrid minus Bistro</h2>
                                    <thead>
                                        <tr>
                                            <th scope="col">Sales</th>
                                            {reportTypeList?.vcGrocerySalesData?.map((o) => {
                                                return (<>
                                                    <th scope="col" className='text-center'>{o?.sales?.toFixed(2)}</th>
                                                </>
                                                )
                                            })}

                                        </tr>
                                    </thead>
                                    <tbody className='text-center'>
                                        <tr>
                                            <th>Increase/decrease from prior year</th>
                                            {reportTypeList?.vcGrocerySalesData?.map((o) => {
                                                return (<>
                                                    <td>{parseFloat(o?.overAllAverage)?.toFixed(2)}%</td>

                                                </>
                                                )
                                            })}
                                        </tr>
                                        <tr>
                                            <th>Sales prior year</th>
                                            {reportTypeList?.previousYearSales?.map((o) => {
                                                return (<>
                                                    <td>{o?.sales?.toFixed(2)}</td>
                                                </>
                                                )
                                            })}
                                        </tr>
                                    </tbody>
                                    <span>&nbsp;</span>
                                    <span>&nbsp;</span>
                                    <h2 className="fw-bold font18">Refund</h2>
                                    <thead>
                                        <tr>
                                            <th scope="col">Refunds</th>
                                            {reportTypeList?.refundDetailsList?.map((o) => {
                                                return (<>
                                                    <th scope="col" className='text-center'>{o?.sales?.toFixed(2)}</th>
                                                </>
                                                )
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody className='text-center'>
                                        <tr>
                                            <th>Refunds Prior Year</th>
                                            {reportTypeList?.previousYearRefundDetailsList?.map((o) => {
                                                return (<>
                                                    <td>{o?.sales?.toFixed(2)}</td>
                                                </>
                                                )
                                            })}
                                        </tr>
                                        <tr>
                                            <th>Refunds % of total Sales</th>
                                            {reportTypeList?.refundDetailsList?.map((o) => {
                                                return (<>
                                                    <td>{parseFloat(o?.overAllAverage)?.toFixed(2)}%</td>
                                                </>
                                                )
                                            })}
                                        </tr>
                                    </tbody>
                                    <span>&nbsp;</span>
                                    <span>&nbsp;</span>
                                    {/* <thead>
                                        <tr>
                                            <th scope="col">Inventory Adjustment</th>
                                            <th scope="col" className='text-center'>0</th>
                                            <th scope="col" className='text-center'>0</th>
                                            <th scope="col" className='text-center'>0</th>
                                            <th scope="col" className='text-center'>0</th>
                                            <th scope="col" className='text-center'>0</th>
                                            <th scope="col" className='text-center'>0</th>
                                            <th scope="col" className='text-center'>0</th>
                                        </tr>
                                    </thead>
                                    <tbody className='text-center'>
                                        <tr>
                                            <th>Inventory Adjustment Prior Year</th>
                                            <td>0</td>
                                            <td>0</td>
                                            <td>0</td>
                                            <td>0</td>
                                            <td>0</td>
                                            <td>0</td>
                                            <td>0</td>
                                        </tr>
                                        <tr>
                                            <th>Inventory Adjustment % of total Sales</th>
                                            <td>0.00%</td>
                                            <td>0.00%</td>
                                            <td>0.00%</td>
                                            <td>0.00%</td>
                                            <td>0.00%</td>
                                            <td>0.00%</td>
                                            <td>0.00%</td>
                                        </tr>
                                    </tbody> */}
                                </table>
                            </div>
                            <div className="table-responsive sales-report mb-4">
                                <h4 className="fw-bold font18">Grocery Item Performance</h4>
                                <table class="table table-striped table-report">
                                    <thead>
                                        <tr>
                                            <th className='text-start fw-bold' scope="col" >Top 25 Items</th>
                                            <th className='text-center fw-bold' scope="col" >Sales</th>
                                            <th className='text-start fw-bold' scope="col" >Name</th>
                                            <th className='text-center fw-bold' scope="col">Price</th>
                                            <th className='text-center fw-bold' scope="col" >Cost</th>
                                            <th className='text-center fw-bold' scope="col" >Margin</th>
                                        </tr>
                                    </thead>
                                    <tbody className='text-center'>
                                        {reportTypeList?.groceryTop25Items?.map((o) => {
                                            return (<tr>
                                                <td className='text-start'>{o?.upc}</td>
                                                <td className='text-center'>{o?.quantity}</td>
                                                <td className='text-start'>{o?.name}</td>
                                                <td className='text-center'>{o?.amount.toFixed(2)}</td>
                                                <td className='text-center'>{o?.cost?.toFixed(2)}</td>
                                                <td className='text-center'>{parseFloat(o?.margin)?.toFixed(2)}</td>
                                            </tr>
                                            )
                                        })}
                                    </tbody>
                                    <span>&nbsp;</span>
                                    <span>&nbsp;</span>
                                    <thead>
                                        <tr>
                                            <th className='text-start fw-bold' scope="col" >Bottom 25 Items</th>
                                            <th className='text-center fw-bold' scope="col" >Sales</th>
                                            <th className='text-start fw-bold' scope="col" >Name</th>
                                            <th className='text-center fw-bold' scope="col">Price</th>
                                            <th className='text-center fw-bold' scope="col" >Cost</th>
                                            <th className='text-center fw-bold' scope="col" >Margin</th>
                                        </tr>
                                    </thead>
                                    <tbody className='text-center'>
                                        {reportTypeList?.groceryBottom25Items?.map((o) => {
                                            return (<tr>
                                                <td className='text-start'>{o?.upc}</td>
                                                <td className='text-center'>{o?.quantity}</td>
                                                <td className='text-start'>{o?.name}</td>
                                                <td className='text-center'>{o?.amount?.toFixed(2)}</td>
                                                <td className='text-center'>{o?.cost?.toFixed(2)}</td>
                                                <td className='text-center'>{parseFloat(o?.margin)?.toFixed(2)}</td>
                                            </tr>
                                            )
                                        })}
                                    </tbody>
                                    <span>&nbsp;</span>
                                    <span>&nbsp;</span>

                                    <thead>
                                        <tr><td colspan="6"><h4 className="text-start fw-bold font18">Bistro Item Performance</h4></td></tr>
                                        <tr>
                                            <th className='text-start fw-bold' scope="col" >Top 25 Items</th>
                                            <th className='text-center fw-bold' scope="col" >Sales</th>
                                            <th className='text-start fw-bold' scope="col" >Name</th>
                                            <th className='text-center fw-bold' scope="col">Price</th>
                                            <th className='text-center fw-bold' scope="col" >Cost</th>
                                            <th className='text-center fw-bold' scope="col" >Margin</th>
                                        </tr>
                                    </thead>
                                    <tbody className='text-center'>
                                        {reportTypeList?.bistroTop25Items?.map((o) => {
                                            return (<tr>
                                                <td className='text-start'>{o?.upc}</td>
                                                <td className='text-center'>{o?.quantity}</td>
                                                <td className='text-start'>{o?.name}</td>
                                                <td className='text-center'>{o?.amount?.toFixed(2)}</td>
                                                <td className='text-center'>{o?.cost?.toFixed(2)}</td>
                                                <td className='text-center'>{parseFloat(o?.margin)?.toFixed(2)}</td>
                                            </tr>
                                            )
                                        })}
                                    </tbody>
                                    <span>&nbsp;</span>
                                    <span>&nbsp;</span>
                                    <thead>
                                        <tr>
                                            <th className='text-start fw-bold' scope="col" >Bottom 25 Items</th>
                                            <th className='text-center fw-bold' scope="col" >Sales</th>
                                            <th className='text-start fw-bold' scope="col" >Name</th>
                                            <th className='text-center fw-bold' scope="col">Price</th>
                                            <th className='text-center fw-bold' scope="col" >Cost</th>
                                            <th className='text-center fw-bold' scope="col" >Margin</th>
                                        </tr>
                                    </thead>
                                    <tbody className='text-center'>
                                        {reportTypeList?.bistroBottom25Items?.map((o) => {
                                            return (<tr>
                                                <td className='text-start'>{o?.upc}</td>
                                                <td className='text-center'>{o?.quantity}</td>
                                                <td className='text-start'>{o?.name}</td>
                                                <td className='text-center'>{o?.amount?.toFixed(2)}</td>
                                                <td className='text-center'>{o?.cost?.toFixed(2)}</td>
                                                <td className='text-center'>{parseFloat(o?.margin)?.toFixed(2)}</td>
                                            </tr>
                                            )
                                        })}
                                    </tbody>
                                    <span>&nbsp;</span>
                                    <span>&nbsp;</span>
                                    <thead>
                                        <tr>
                                            <th className='text-center fw-bold' scope="col" >Category Performance</th>
                                            <th className='text-center fw-bold' scope="col" >Sales</th>
                                            <th className='text-center fw-bold' scope="col" >Sales prior year</th>
                                            <th className='text-center fw-bold' scope="col">Increase/Decrease</th>
                                            {/* <th className='text-center fw-bold' scope="col" >Percent of total sales</th> */}
                                        </tr>
                                    </thead>
                                    <tbody className='text-center'>
                                        {reportTypeList?.categoryDetailsList?.map((o) => {
                                            return (<tr>
                                                <td className='text-center'>{o?.name}</td>
                                                <td className='text-center'>{parseFloat(o?.sales)?.toFixed(2)}</td>
                                                <td className='text-center'>{parseFloat(o?.oldSales)?.toFixed(2)}</td>
                                                <td className='text-center'>{parseFloat(o?.average)?.toFixed(2)}%</td>
                                            </tr>
                                            )
                                        })}
                                    </tbody>
                                    <span>&nbsp;</span>
                                    <span>&nbsp;</span>
                                    <thead>
                                        <tr>
                                            <th className='text-center fw-bold' scope="col" >Refund Request</th>
                                            <th className='text-center fw-bold' scope="col" >Invoice ID</th>
                                            <th className='text-center fw-bold' scope="col" >Amount</th>
                                            <th className='text-start fw-bold' scope="col">Reason</th>
                                            <th className='text-center fw-bold' scope="col" >Mode of Purchase</th>
                                            <th className='text-center fw-bold' scope="col" >Refund Issuer</th>
                                        </tr>
                                    </thead>
                                    <tbody className='text-center'>
                                        {reportTypeList?.refundDetails?.map((o) => {
                                            return (<tr>
                                                <td className='text-center'>{o?.id}</td>
                                                <td className='text-center'>{o?.invoice}</td>
                                                <td className='text-center'>{parseFloat(o?.amount)?.toFixed(2)}</td>
                                                <td title={o?.reason} className='text-start report-desc-wrap'>{o?.reason}</td>
                                                <td className='text-center'>{o?.purchase}</td>
                                                <td className='text-center'>{userInfo?.firstName}{userInfo?.lastName}</td>
                                            </tr>
                                            )
                                        })}
                                    </tbody>
                                    <span>&nbsp;</span>
                                    <span>&nbsp;</span>
                                    <thead>
                                        <tr>
                                            <th className='text-start fw-bold' scope="col" >Refund based on Reason</th>
                                            <th className='text-center fw-bold' scope="col" >Wrong Products</th>
                                            <th className='text-center fw-bold' scope="col" >Damaged Products</th>
                                            <th className='text-center fw-bold' scope="col">Wrong Amount</th>
                                            <th className='text-center fw-bold' scope="col" >Others</th>
                                            <th className='text-center fw-bold' scope="col" >Manual Refunds</th>
                                        </tr>
                                    </thead>
                                    <tbody className='text-center'>
                                        <tr>
                                            <td className='text-start'>Quantity</td>
                                            <td className='text-center'>{reportTypeList?.refundBasedOnReason?.wrongProduct?.quantity}</td>
                                            <td className='text-center'>{reportTypeList?.refundBasedOnReason?.damagedProduct?.quantity}</td>
                                            <td className='text-center'>{reportTypeList?.refundBasedOnReason?.wrongAmount?.quantity}</td>
                                            <td className='text-center'>{reportTypeList?.refundBasedOnReason?.others?.quantity}</td>
                                            <td className='text-center'>{reportTypeList?.refundBasedOnReason?.manualRefund?.quantity}</td>

                                        </tr>
                                        <tr>
                                            <td className='text-start'>Amount</td>
                                            <td className='text-center'>{reportTypeList?.refundBasedOnReason?.wrongProduct?.amount?.toFixed(2)}</td>
                                            <td className='text-center'>{reportTypeList?.refundBasedOnReason?.damagedProduct?.amount?.toFixed(2)}</td>
                                            <td className='text-center'>{reportTypeList?.refundBasedOnReason?.wrongAmount?.amount?.toFixed(2)}</td>
                                            <td className='text-center'>{reportTypeList?.refundBasedOnReason?.others?.amount?.toFixed(2)}</td>
                                            <td className='text-center'>{reportTypeList?.refundBasedOnReason?.manualRefund?.amount?.toFixed(2)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </>
                    }
                </div>

                {(isLoading || reportViewLoading) && <AppLoader />}


            </div >
        </React.Fragment >
    )
}
export default ReportsScreen;