import React, { useEffect } from 'react';
import ScrollToTop from './app/utils/commonComponents/ScrollToTop';
import AppRoutes from './app/utils/routes/AppRoutes';
import { useDispatch } from 'react-redux';

import Header from './app/components/header';
import { ErrorBoundary } from 'react-error-boundary';
import NavigationMenus from './app/components/navigaition/NavigationMenus';
import inititlizeSocketConnection from './app/utils/service/socket/HandleSocketMessages';
import { stopSocketConnect } from './app/utils/service/socket/SocketConnection';
import Constants from './app/utils/Constants';
import Actions from './app/redux/actions';
import { getCookie, isJSON } from './app/utils/commonFunctions/cookie';


export const OurFallbackComponent = ({ error, resetErrorBoundary }) => {
    return (
        <div>
            <h1>An error occurred: {error.message}</h1>
            <button onClick={resetErrorBoundary}>Sorry something went wrong. Please refresh the page</button>
        </div>
    );
};

const RoutePagesConfig = () => {
    const dispatch = useDispatch()
    const navingatedURL = new URL(Constants.COMMON_APP_DOMAINS.commonLogin)
    navingatedURL.searchParams.set('path', window.location.pathname)
    navingatedURL.searchParams.set('origin', Constants.COMMON_APP_DOMAINS.storeManager)  //Set Default SSO Navigation


    const token = isJSON(getCookie('token')) ? JSON.parse(getCookie('token')) : undefined

    if (!token) {
        window.location.assign(navingatedURL)
    }

    useEffect(() => {
        const customerData = isJSON(getCookie('token')) ? JSON.parse(getCookie('token')) : undefined
        if (customerData) {
            dispatch(Actions.loginSuccessAction(customerData))
        }
        if (token) {
            inititlizeSocketConnection();
        } else {
            stopSocketConnect();
        }
    }, [token])


    const { customer } = token || {}

    return (
        <ErrorBoundary FallbackComponent={OurFallbackComponent}>
            <React.Fragment>
                {token && <>
                    <Header />
                    <ScrollToTop />
                    {
                        ((customer?.roleId === Constants.ROLES.BACK_KITCHEN)
                            || (customer?.roleId === Constants.ROLES.BISTRO_DISPLAY)
                            || (customer?.roleId === Constants.ROLES.FRONT_KITCHEN)) ?
                            <AppRoutes /> : <NavigationMenus />
                    }
                </>}
            </React.Fragment>
        </ErrorBoundary>
    )
}

export default RoutePagesConfig;
