import React, { useRef, useState, useEffect, useCallback } from 'react';
import Select from 'react-select';
import debounce from 'lodash.debounce';
import { useDispatch } from 'react-redux'
import Actions from '../../redux/actions';
import { COLORS } from '../../utils/Constants';


export const customSelectStyles = {
    menu: (provided) => ({
        ...provided,
    }),
    control: (provided) => ({
        ...provided,
        borderColor: COLORS.appThemeHex,
        boxShadow: null,
        display: 'flex',
        flex: 1,
        minWidth: '300px'
    }),
    singleValue: (provided) => ({
        ...provided,
    }),
    option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? 'white' : 'black',
        backgroundColor: state.isSelected ? COLORS.appThemeHex : 'white',
        zIndex: 999
    }),
    valueContainer: (provided) => ({
        ...provided,
    }),
};

const StoreTypeAhead = ({
    defaultOptions = null,
    placeholder = '',
    onChange = () => null,
    isMulti,
    ...props
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState(defaultOptions || []);
    const dispatch = useDispatch();

    const optionsRef = useRef()

    const searchApi = useCallback((value) => {
        const defaultFilter = {
            page: 0,
            size: 100,
            searchKey: value ? value : ''
        };
        setIsLoading(true);
        dispatch(Actions.getStoreList(defaultFilter, (resp) => {
            let data = [];
            if (resp?.response) {
                data = resp?.response?.map(d =>
                (
                    {
                        label: `${d?.store?.branchName?.toLowerCase()} (${d?.store?.address?.toLowerCase()})`, value: d?.store?.id, data: d?.store

                    }
                )
                );

            }
            setIsLoading(false)
            setOptions(data)
            optionsRef.current = data
        }))

    }, [setIsLoading, setOptions, dispatch]);

    const debounceRef = useRef(debounce((value) => {
        searchApi(value ? value : '');
    }, 500));

    useEffect(() => {
        searchApi();
    }, [dispatch])


    return <Select
        id="productstypeahead"
        className="title-case"
        {...props}
        placeholder={placeholder}
        isLoading={isLoading}
        isClearable={true}
        isSearchable={true}
        onInputChange={(value) => debounceRef.current(value)}
        options={options}
        isMulti={isMulti}
        onChange={(value) => onChange(value || {})}
    />
}



export default StoreTypeAhead;