import Actions from "../actions";

const initialState = {
    isLoading: false,
    getDaxbot: [],
    trackDaxbot: [],
    error: false,
    trackLoading: false,
    trackError: false
}
const Getdaxbot = (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.GET_DAXBOT: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.GET_DAXBOT_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                getDaxbot: action?.data?.response,
                error: false
            };
        }
        case Actions.GET_DAXBOT_FAILURE: {
            return {
                ...state,
                isLoading: false,
                getDaxbot: [],
                error: action.data,
            };
        } case Actions.TRACK_DAXBOT: {
            return {
                ...state,
                trackLoading: true,
            };
        }
        case Actions.TRACK_DAXBOT_SUCCESS: {
            return {
                ...state,
                trackLoading: false,
                trackDaxbot: action?.data?.response?.response,
                error: false
            };
        }
        case Actions.TRACK_DAXBOT_FAILURE: {
            return {
                ...state,
                trackLoading: false,
                trackError: action.data,
            };
        }
        default:
            return state;
    }
};

export default Getdaxbot;

export const getDaxbotSelector = state => state?.GET_DAXBOT?.getDaxbot;
export const getDaxbotLoadingSelector = state => state?.GET_DAXBOT?.isLoading;
export const trackDaxbotSelector = state => state?.GET_DAXBOT?.trackDaxbot;
export const trackDaxbotLoadingSelector = state => state?.GET_DAXBOT?.trackLoading;
