export const isValidEmail = (email) => {
    const validEmailRegex = RegExp(/^[a-zA-Z0-9+.]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/)
    return validEmailRegex.test(email);
}

export const isFieldEmpty = (field) => {
    return (!field || field.trim().length === 0)
}

export const uppercaseRegExp = /(?=.*?[A-Z])/;
export const lowercaseRegExp = /(?=.*?[a-z])/;
export const digitsRegExp = /(?=.*?\d)/;
export const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
export const minLengthRegExp = /.{8,}/;
export const whiteSpaceValidation = (str) => {
    const validEmailRegex = RegExp('^[a-zA-Z0-9_]*$')
    return validEmailRegex.test(str);
}

export const validateName = (name) => {
    let re = /^[a-zA-Z ẞßÉäöüÄÖÜùûüÿàâæéèêëïîôœÙÛÜŸÀÂÆÉÈÊËÏÎÔŒ]{1,250}$/;
    return re.test(String(name).toLowerCase());
}

export const validateuppercaseRegExp = (name) => {
    let re =/(?=.*?[A-Z])/;
    return re.test(String(name));
}
export const validatelowercaseRegExp = (name) => {
    let re =  /(?=.*?[a-z])/;
    return re.test(String(name));
}
export const validatedigitsRegExp = (name) => {
    let re =/(?=.*?\d)/;
    return re.test(name);
}
export const validatespecialCharRegExp = (name) => {
    let re =/(?=.*?[#?!@$%^&*-])/;
    return re.test(name);
}
export const validateminLengthRegExp = (name) => {
    let re = /.{8,}/;
    return re.test(name);
}
