import { doAction, doResponseAction } from "..";

const RACK_CAPTURE = 'RACK_CAPTURE';
const RACK_CAPTURE_SUCCESS = 'RACK_CAPTURE_SUCCESS';
const RACK_CAPTURE_FAILURE = 'RACK_CAPTURE_FAILURE';

export {
    RACK_CAPTURE,
    RACK_CAPTURE_SUCCESS,
    RACK_CAPTURE_FAILURE,
}

export const rack_Capture = (params, callback) => doAction(RACK_CAPTURE, params, callback);
export const rack_Capture_Success = data => doResponseAction(RACK_CAPTURE_SUCCESS, data);
export const rack_Capture_Failure = data => doResponseAction(RACK_CAPTURE_FAILURE, data);