import { doAction, doResponseAction } from ".";

const REPORT_LIST_TYPE = 'REPORT_LIST_TYPE';
const REPORT_LIST_TYPE_SUCCESS = 'REPORT_LIST_TYPE_SUCCESS';
const REPORT_LIST_TYPE_FAILURE = 'REPORT_LIST_TYPE_FAILURE';

const GET_SALES_REPORT = 'GET_SALES_REPORT';
const GET_SALES_REPORT_SUCCESS = 'GET_SALES_REPORT_SUCCESS';
const GET_SALES_REPORT_FAILURE = 'GET_SALES_REPORT_FAILURE';
//DOWNLOAD
const DOWNLOAD_REPORT = 'DOWNLOAD_REPORT';
const DOWNLOAD_REPORT_SUCCESS = 'DOWNLOAD_REPORT_SUCCESS';
const DOWNLOAD_REPORT_FAILURE = 'DOWNLOAD_REPORT_FAILURE';
//GET REPORT
const GET_REPORT = 'GET_REPORT';
const GET_REPORT_SUCCESS = 'GET_REPORT_SUCCESS';
const GET_REPORT_FAILURE = 'GET_REPORT_FAILURE';
export {
    REPORT_LIST_TYPE,
    REPORT_LIST_TYPE_SUCCESS,
    REPORT_LIST_TYPE_FAILURE,
    GET_SALES_REPORT,
    GET_SALES_REPORT_SUCCESS,
    GET_SALES_REPORT_FAILURE,
    DOWNLOAD_REPORT,
    DOWNLOAD_REPORT_SUCCESS,
    DOWNLOAD_REPORT_FAILURE,
    GET_REPORT,
    GET_REPORT_SUCCESS,
    GET_REPORT_FAILURE
}

export const getreportTypeListAction = (params, callback) => doAction(REPORT_LIST_TYPE, params, callback);
export const getreportTypeListSuccessAction = data => doResponseAction(REPORT_LIST_TYPE_SUCCESS, data);
export const getreportTypeListFailureAction = data => doResponseAction(REPORT_LIST_TYPE_FAILURE, data);


export const getSalesReportAction = (params, callback) => doAction(GET_SALES_REPORT, params, callback);
export const getSalesReportSuccessAction = data => doResponseAction(GET_SALES_REPORT_SUCCESS, data);
export const getSalesReportFailureAction = data => doResponseAction(GET_SALES_REPORT_FAILURE, data);

//DOWNLOAD
export const downloadReportAction = (params, callback) => doAction(DOWNLOAD_REPORT, params, callback);
export const downloadReportSuccessAction = data => doResponseAction(DOWNLOAD_REPORT_SUCCESS, data);
export const downloadReportFailureAction = data => doResponseAction(DOWNLOAD_REPORT_FAILURE, data);
//GET REPORT
export const getReportAction = (params, callback) => doAction(GET_REPORT, params, callback);
export const getReportSuccessAction = data => doResponseAction(GET_REPORT_SUCCESS, data);
export const getReportFailureAction = data => doResponseAction(GET_REPORT_FAILURE, data);

