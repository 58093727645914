import { all, put, takeLatest } from "redux-saga/effects";
import { doCvPost } from "../../../utils/service";
import END_POINTS from "../../../utils/service/Endpoints";
import Actions from "../../actions";

export function* autoFillSaga({ callback, params }) {
    try {
        const response = yield doCvPost(END_POINTS.RACK_AUTOFILL, params)
        if (response) {
            callback && callback(response)
            yield put(Actions.autoFill_Success(response));
        }
    } catch (error) {
        if (callback) {
            callback({ error })
            yield put(Actions.autoFill_Failure({ error }));
        }
    }
}

export default function* autoFillListner() {
    yield all([
        takeLatest(Actions.AUTOFILL, autoFillSaga),
    ]);
}