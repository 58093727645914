import { all, put, takeLatest } from "redux-saga/effects";
import { doAuthGet, doAuthPost } from "../../utils/service";
import END_POINTS from "../../utils/service/Endpoints";
import Actions from "../actions";

export function* invoiceDetailsSaga({ params, callback }) {
    try {
        const response = yield doAuthGet(END_POINTS.INVOICE_DETAIL, params);
        if (response) {
            callback && callback(response)
            yield put(Actions.invoiceDetailsSuccess(response));
        }
    }
    catch (error) {
        callback && callback(error)
        yield put(Actions.invoiceDetailsFailure(error));
    }
}


export function* manualRefundSaga({ params, callback }) {
    try {
        const response = yield doAuthPost(END_POINTS.MANAUAL_REFUND, params);
        if (response) {
            callback && callback(response)
            yield put(Actions.manaualRefundSuccess(response));
        }
    }
    catch (error) {
        callback && callback(error)
        yield put(Actions.manaualRefundFailure(error));
    }
}

export default function* invoiceDetailsListeners() {
    yield all([
        takeLatest(Actions.INVOICE_DETAILS, invoiceDetailsSaga),
        takeLatest(Actions.MANUAL_REFUND, manualRefundSaga),
    ]);
}
