import React from "react";
import { withRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import ModalPopup, { ModalHeader } from "./modal";
import InputField from "./fields/InputField";
import Actions from "../redux/actions";
import { getPlanogramProductListSelector } from "../redux/reducers/PlanogramProductReducer";
import { Label } from "./fields/AppComponents";
import PlanogramTypeAhead from "./fields/PlanogramTypeAhead";
import AppLoader from "./fields/AppLoader";

class AssignProductModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inputSearchString: '',
            showNoResultsText: 'No Product Found.',
            fields: {},
            error: {},
            width: '',
        };
    }
    z
    componentDidMount() {
        const { bay } = this.props
        let { fields } = this.state
        if (bay?.productDetails) {
            const selectedProducts = {
                label: bay?.productDetails?.name,
                value: bay?.productDetails?.upc
            }
            fields['product'] = selectedProducts
            fields['quantity'] = bay?.quantity 
            fields['frontCapacity'] = bay?.frontCapacity
            fields['currentItemCount'] = bay?.currentItemCount
            fields['minStock'] = bay?.minStock
            this.setState({ fields })
        }

    }

    handleOnSelect = (item) => {
        this.handleChange(item, 'product')
    }

    handleChange = (value, name) => {
        const { fields } = this.state;
        this.setState({
            fields: {
                ...fields,
                [name]: value
            }
        }, () => this.validateForm([name]))

    }

    validateForm = (field) => {
        let { error, fields } = this.state;
        let isFailed = false;
        field.forEach((a) => {
            switch (a) {
                case 'product':
                    if (!fields[a]) {
                        error[a] = 'Product is Mandatory';
                        isFailed = true
                    } else
                        error[a] = '';
                    break;
                case 'quantity':
                    if (!fields[a]) {
                        isFailed = true
                        error[a] = 'Max Capacity is Mandatory';
                    } else
                        error[a] = '';
                    break;
                case 'frontCapacity':
                    if (!fields[a]) {
                        isFailed = true
                        error[a] = 'Front Capacity is Mandatory';
                    } else if (parseInt(fields['quantity']) < (parseInt(fields[a]))) {
                        error[a] = 'Front capacity is not higher than maximum capacity'
                        isFailed = true
                    } else
                        error[a] = '';
                    break;
                case 'currentItemCount':
                    if (!fields[a]) {
                        isFailed = true
                        error[a] = 'Current Item Count is Mandatory';
                    } else if (parseInt(fields['quantity']) < (parseInt(fields[a]))) {
                        error[a] = 'Current item is not higher than maximum capacity'
                        isFailed = true
                    } else
                        error[a] = '';
                    break;
                case 'minStock':
                    if (!fields[a]) {
                        isFailed = true
                        error[a] = 'Min Stock field is Mandatory';
                    } else if (parseInt(fields['quantity']) < (parseInt(fields[a]))) {
                        error[a] = 'Minimum stock is not higher than maximum capacity'
                        isFailed = true
                    } else
                        error[a] = '';
                    break;
                default:
                    break;
            }
        })

        this.setState({ error })
        return isFailed
    }

    handleOnFocus = () => {
        console.log('Focused')
    }

    onSubmit = () => {
        const { fields } = this.state;
        const { bay } = this.props;

        if (this.validateForm(['product', 'quantity', 'frontCapacity', 'currentItemCount', 'minStock'])) {
            return false;
        }

        this.props.onSubmitForm({ fields, bay })
    }




    render() {
        const { isModalOpen, onCloseModal, isLoading } = this.props || {};
        const { error, fields } = this.state;
        return (
            <React.Fragment>
                {isModalOpen && <ModalPopup className="modal-lg" onCloseModal={onCloseModal}>
                    <ModalHeader title="Assign Product" />
                    <div className="modal-container">
                        <div className="row mb-4">
                            <div className="col-12">
                                <div style={{ display: 'flex', marginTop: '10px', flexDirection: 'column' }}>
                                    <Label style={{ minWidth: '100px' }}>Select Product</Label>

                                    <div style={{ marginTop: '10px' }}>
                                        <PlanogramTypeAhead
                                            id='handleChangeCustomers'
                                            onChange={(e) => this.handleOnSelect(e)}
                                            fields={fields['product']}
                                        />
                                    </div>
                                    {error.product && <p className="text-danger">{error.product}</p>}
                                </div>
                            </div>
                            <div className="col-12">
                                <div style={{ display: 'flex', marginTop: '10px', flexDirection: 'column' }}>
                                    <Label style={{ minWidth: '100px' }}>Max Capacity</Label>
                                    <InputField
                                        id="quantity"
                                        type={'number'}
                                        style={{ marginTop: '10px' }}
                                        placeholder="Enter Max Capacity"
                                        value={fields['quantity']}
                                        name="quantity"
                                        onChange={(value, name) => this.handleChange(value, name)}
                                        error={error['quantity']}
                                    />
                                </div>
                            </div>
                            <div className="col-12">
                                <div style={{ display: 'flex', marginTop: '10px', flexDirection: 'column' }}>
                                    <Label style={{ minWidth: '100px' }}>Front Face Capacity</Label>
                                    <InputField
                                        id="frontCapacity"
                                        type={'number'}
                                        style={{ marginTop: '10px' }}
                                        placeholder="Enter Front Face Capacity"
                                        value={fields['frontCapacity']}
                                        name="frontCapacity"
                                        onChange={(value, name) => this.handleChange(value, name)}
                                        error={error['frontCapacity']}
                                    />
                                </div>
                            </div>
                            <div className="col-12">
                                <div style={{ display: 'flex', marginTop: '10px', flexDirection: 'column' }}>
                                    <Label style={{ minWidth: '100px' }}>Current Item Count</Label>
                                    <InputField
                                        id="currentItemCount"
                                        type={'number'}
                                        style={{ marginTop: '10px' }}
                                        placeholder="Enter Current Item Count"
                                        value={fields['currentItemCount']}
                                        name="currentItemCount"
                                        onChange={(value, name) => this.handleChange(value, name)}
                                        error={error['currentItemCount']}
                                    />
                                </div>
                            </div>
                            <div className="col-12">
                                <div style={{ display: 'flex', marginTop: '10px', flexDirection: 'column' }}>
                                    <Label style={{ minWidth: '100px' }}>Min Stock required</Label>
                                    <InputField
                                        id="minStock"
                                        type={'number'}
                                        style={{ marginTop: '10px' }}
                                        placeholder="Enter Min Stock Required"
                                        value={fields['minStock']}
                                        name="minStock"
                                        onChange={(value, name) => this.handleChange(value, name)}
                                        error={error['minStock']}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col">
                            <button
                                id="onCloseModal"
                                type="button"
                                className="btn btn-outline-dark big-btn w-100 px-5 text-uppercase"
                                onClick={() => onCloseModal()}
                            >
                                Cancel
                            </button>
                        </div>
                        <div className="col">
                            <button
                                id="submit"
                                type="button"
                                className="btn btn-dark big-btn w-100 px-5 text-uppercase"
                                onClick={() => this.onSubmit()}
                            >
                                Assign
                            </button>
                        </div>
                    </div>
                    {isLoading && <AppLoader />}
                </ModalPopup>
                }
            </React.Fragment>
        );
    }
}

export const mapStateToProps = (state) => {
    return {
        products: getPlanogramProductListSelector(state)
    };
};

export default withRouter(connect(mapStateToProps, {
    getProductList: Actions.getPlanogramProductList
})(AssignProductModal));