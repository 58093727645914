import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Actions from '../../redux/actions';
import { getDashboardTitleId } from '../../redux/reducers/StoreReducer';
import {
    getOrderListLoading,
    getOrderListSelector,
    getOrderListCountSelector
} from '../../redux/reducers/OrderReducer';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import cloneDeep from 'lodash.clonedeep';
import { getQueryParams } from '../../utils/commonFunctions/EncodeDecodeBase64';
import { useLocation } from 'react-router';
import Search from '../../../asset/new/img/search.svg'
import PendingImg from '../../../asset/new/img/pending.svg'
import DeclinedImg from '../../../asset/new/img/red-cross.svg'
import DeliveredImg from '../../../asset/new/img/green-tick.svg'
import Pagination from "react-js-pagination";
import OrderDetails from './OrderDetails';
import AppLoader from '../../components/fields/AppLoader';
import QrCodeScanner from './qrCodeScanner';
import EmptyPage from '../../components/emptyPage';
import DateFilter from '../../components/DateFilter';
import Qrimage from '../../../asset/new/img/qrImage.png'
import { ShowDateMoment } from '../../utils/commonComponents/showDate';
import { history } from '../../redux/store';
import RoutePath from '../../utils/routes/RoutePath';
import Constants from '../../utils/Constants';
import { getRoleIdFromCookie } from '../../utils/commonFunctions/cookie';

export const defaultOrderFilters = {
    status: 7,
    offset: 0,
    limit: 5,
    type: 2,
    startDate: '',
    endDate: '',
    invoiceOrderDetailsId: ''
}
export const defaultOrderBistro = {
    status: 7,
    offset: 0,
    limit: 5,
    type: 1,
    startDate: '',
    endDate: '',
    invoiceOrderDetailsId: ''
}

const DEFAULT_FILTER_STATUS = [
    {
        id: 7,
        name: 'OnGoing'
    },
    {
        id: 1,
        name: 'Pending'
    },
    {
        id: 2,
        name: 'Accepted'
    },
    {
        id: 3,
        name: 'Declined'
    },
    {
        id: 4,
        name: 'Ready to pickup'
    },
    {
        id: 5,
        name: 'Delivered'
    },
    {
        id: 6,
        name: 'All'
    },

]
const DATE_FORMAT = 'YYYY-MM-DD';

const SearchInputField = memo(({ isPlaceholder, onChangeSearchInput, value }) => {

    return (
        <div className="input-group mb-3 mb-lg-0">
            <input type="number" className="form-control border-end-0" value={value}
                id='searchOrder'
                onChange={(value) => {
                    onChangeSearchInput(value);
                }} placeholder="Search by Order no" />
            <button className="btn btn-outline-dark border-start-0" type="button" id="search-btn"><img src={Search} alt="Go" /></button>
        </div>
    )
});


const OrderScreen = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const queryParams = getQueryParams(location.search);
    const searchId = queryParams.get('searchId');
    const typeQ = queryParams.get('type');
    const totalCount = useSelector(getOrderListCountSelector)
    const orderList = useSelector(getOrderListSelector);
    const roleId = getRoleIdFromCookie();
    const dashboardTitleId = useSelector(getDashboardTitleId);
    const isLoading = useSelector(getOrderListLoading);
    const [apiFilters, setApiFilters] = useState(cloneDeep({
        ...defaultOrderFilters,
        type: typeQ || 2,
        invoiceOrderDetailsId: searchId || ''
    }));
    const [filterValidationError, setFilterValidationError] = useState(null);
    const [filterType, setFilterType] = useState()
    const [activePage, setActivePage] = useState(1)
    const [orderDetailsModal, setOrderDetailsModal] = useState(false)
    const [orderDetailsData, setOrderdetailsData] = useState('')
    const [openQrScanner, setQrScanner] = useState(false)
    const [deliverBtn, setDeliverBtn] = useState(false)
    const [openDateFilterModal, setDateFilterModal] = useState(false)
    const [InvoiceOrderDetailsId, setInvoiceOrderDetailsId] = useState()
    const [scanButton, setScanButton] = useState()
    const [modalFilters, setModalFilters] = useState({
        status: apiFilters.status,
        startDate: apiFilters.startDate,
        endDate: apiFilters.endDate,
    });

    const fetchList = useCallback((orderId) => {
        const filterParams = { ...apiFilters };
        if (filterParams.startDate) {
            filterParams.startDate = moment(filterParams.startDate).format(DATE_FORMAT);
        }
        if (filterParams.endDate) {
            filterParams.endDate = moment(filterParams.endDate).format(DATE_FORMAT);
        }
        if (filterParams?.invoiceOrderDetailsId?.trim().length === 0) {
            delete filterParams.invoiceOrderDetailsId;
        }
        if (!!dashboardTitleId) {
            setApiFilters(s => ({
                ...s,
                status: dashboardTitleId,
            }))
            setFilterType(dashboardTitleId)
            dispatch(Actions.getOrderList(filterParams));
            let params = {
                selectedTitleId: undefined
            }
            dispatch(Actions.setDashboardTitleId(params))
        } else {
            dispatch(Actions.getOrderList(filterParams));
        }
        if (searchId) {
            history.replace(RoutePath.ORDERS_PATH)
        }
    }, [dispatch, apiFilters]);

    const handleChange = (pageNumber) => {
        setActivePage(pageNumber)
        // replaceHistory()
        orderListPageChange(pageNumber * 5 - 5)
    }

    const orderListPageChange = (pageNumber) => {
        const filterParams = { ...apiFilters };
        if (filterParams.startDate) {
            filterParams.startDate = moment(filterParams.startDate).format(DATE_FORMAT);
        }
        if (filterParams.endDate) {
            filterParams.endDate = moment(filterParams.endDate).format(DATE_FORMAT);
        }
        if (filterParams?.invoiceOrderDetailsId?.trim().length === 0) {
            delete filterParams.invoiceOrderDetailsId;
        }
        if (roleId === 2) {
            filterParams.type = 1
        }
        filterParams.offset = pageNumber
        if (!!dashboardTitleId) {
            setApiFilters(s => ({
                ...s,
                status: dashboardTitleId,
            }))
            dispatch(Actions.getOrderList(filterParams));
            let params = {
                selectedTitleId: undefined
            }
            dispatch(Actions.setDashboardTitleId(params))
        } else {
            dispatch(Actions.getOrderList(filterParams));
        }
    }

    const onFilterDone = () => {
        if (!modalFilters.startDate && modalFilters.endDate) {
            setFilterValidationError('Please select start date.');
            return;
        }

        if (modalFilters.startDate && !modalFilters.endDate) {
            setFilterValidationError('Please select end date.');
            return;
        }
        if (moment(modalFilters.startDate).isAfter(moment(modalFilters.endDate))) {
            setFilterValidationError('End date cannot be before start date.');
            return;
        }
        setFilterValidationError(null);
        setApiFilters(f => ({ ...f, ...modalFilters }))
        setDateFilterModal(false)
    }

    useEffect(() => {
        const filterParams = { ...apiFilters };
        if (roleId === 2) {
            filterParams.type = 1
            if (filterParams.startDate) {
                filterParams.startDate = moment(filterParams.startDate).format(DATE_FORMAT);
            }
            if (filterParams.endDate) {
                filterParams.endDate = moment(filterParams.endDate).format(DATE_FORMAT);
            }
            if (filterParams?.invoiceOrderDetailsId?.trim().length === 0) {
                delete filterParams.invoiceOrderDetailsId;
            }
            if (!!dashboardTitleId) {
                setApiFilters(s => ({
                    ...s,
                    status: dashboardTitleId,
                }))
                setFilterType(dashboardTitleId)
                dispatch(Actions.getOrderList(filterParams));
                let params = {
                    selectedTitleId: undefined
                }
                dispatch(Actions.setDashboardTitleId(params))
            } else {
                dispatch(Actions.getOrderList(filterParams));
            }
        } else {
            fetchList();

        }
    }, [fetchList]);

    const openQrScannerModal = () => {
        setDeliverBtn(true)
        setQrScanner(true)
    }
    const togleChange = (value) => {
        const filterParams = { ...apiFilters }
        filterParams.type = value
        filterParams.limit = 5
        setApiFilters(filterParams)
        setModalFilters(s => ({
            ...s,
            offset: 0
        }))
        setActivePage(1)
        dispatch(Actions.updatedCurrentOrderLocation(value))
        if (searchId) {
            history.replace(RoutePath.ORDERS_PATH)
        }
    }


    const onChangeSearchInput = (value) => {
        setInvoiceOrderDetailsId(value)
        const filterParams = { ...apiFilters };
        filterParams.invoiceOrderDetailsId = value;
        if (roleId === 2) {
            filterParams.type = 1;
        }
        setActivePage(1)
        filterParams.offset = 0
        setApiFilters(filterParams)
    }
    const data = React.useMemo(
        () => orderList,
        [orderList]
    );
    const closeModal = () => {
        setFilterValidationError('')
        setDateFilterModal(false)
    }

    const onStatusTypeChange = (id) => {
        setModalFilters(s => ({
            ...s,
            status: id,
            offset: 0,
        }))
        setActivePage(1)
        setFilterType(id)
    }

    const openModal = () => {
        setModalFilters({
            status: apiFilters.status,
            endDate: apiFilters.endDate,
            startDate: apiFilters.startDate
        })
        setDateFilterModal(true)
    }

    const clearFilter = () => {
        setModalFilters(s => ({
            ...s,
            startDate: null,
            endDate: null,
        }))
        setFilterType(7)
        setFilterValidationError(null);
        setActivePage(1)
        setApiFilters(f => ({
            ...f,
            startDate: null,
            endDate: null,  
            status: 7,
        }))
        // setDateFilterModal(false)
    }
    const onDateChange = (date, type) => {
        if (type === 1) {
            setModalFilters(s => ({
                ...s,
                startDate: date,
                offset: 0
            }))
        } else {
            setModalFilters(s => ({
                ...s,
                endDate: date,
                offset: 0
            }))
        }
        setActivePage(1)
    }


    const openOrderDetails = (orderDetails) => {
        setDeliverBtn(true)

        setOrderDetailsModal(true)
        setOrderdetailsData(orderDetails)
    }
    const OrderStatusLabel = (status, natureOfOrder, prepared) => {
        let orderStatus = '';
        let orderStatusImg = '';
        let statusOfClass = '';
        if (status === 7) {
            orderStatus = 'Ongoing';
            orderStatusImg = ''
            statusOfClass = 'text-success'
        } else if (status === 1) {
            orderStatus = 'Pending'
            statusOfClass = 'text-warning'
            orderStatusImg = PendingImg
        } else if (status === 2) {
            orderStatus = 'Accepted'
            statusOfClass = 'text-success'
            orderStatusImg = DeliveredImg
        } else if (status === 3) {
            orderStatus = 'Declined';
            statusOfClass = 'text-danger'
            orderStatusImg = DeclinedImg
        } else if (status === 4) {
            orderStatus = natureOfOrder && natureOfOrder === 1 ? 'Out for delivery' : 'Ready to pickup';
            orderStatusImg = DeliveredImg
            statusOfClass = 'text-success'
        } else if (status === 5) {
            orderStatus = 'Delivered';
            statusOfClass = 'text-success'
            orderStatusImg = DeliveredImg
        } else if (status === 6) {
            orderStatus = 'All';
            statusOfClass = 'text-success'
            orderStatusImg = ''
        }
        return orderStatus !== '' &&
            <div className={`col-12 col-lg-4 font13 text-center text-lg-end ${statusOfClass}`}>
                <img src={orderStatusImg} alt="Yes"
                    className="me-1" /> {orderStatus}
            </div>
    }
    return (
        <div className="col ps-lg-4">
            <div className="row mb-3">
                <div className="col"> <h1 className="font20 fw-bold">Orders</h1> </div>
                <div className="col-auto text-end">
                    <h1 className="font20 fw-bold cursor-pointer" id="openQrScannerModal"
                        onClick={openQrScannerModal}>
                        <img height={'30px'} src={Qrimage}></img></h1>
                </div>
            </div >
            <form name="">
                <div id="order-options-bar" className="row mb-sm-0 mb-3">
                    <div className="col-12 col-lg-4">
                        <SearchInputField value={apiFilters?.invoiceOrderDetailsId}
                            id="SearchInputField"
                            onChangeSearchInput={(e) => onChangeSearchInput(e?.target?.value)} />
                    </div>
                    {(roleId === Constants.ROLES.ADMIN || roleId === Constants.ROLES.STORE_MANAGER) &&
                        <div className="col-9 col-sm-3 col-lg-2 pe-0 pe-lg-1 cursor-pointer">
                            <select className="form-select ddownfield  mb-lg-0" value={apiFilters.type}
                                id="togleChange"
                                onChange={(e) => togleChange(e?.target?.value)}>
                                <option value={1}>Bistro</option>
                                <option value={2}>Grocery</option>
                            </select>
                        </div>}
                    <div className={`d-flex align-items-center justify-content-end col-3 col-sm-3 ${(roleId === 2 || roleId === 3) ? "col-lg-8" : "col-lg-6"} cursor-pointer text-end`}>
                        <div className=''>
                            <span>Filter</span>
                            <FontAwesomeIcon
                                id='OpenModal'
                                onClick={openModal}
                                icon={faFilter} size="10m" />
                        </div>
                    </div>
                </div>
            </form>
            <div className="row mb-4">
                <div className="col">
                    <div id="orders-list"
                        className="card card-body rounded-3 border-0 shadow p-4 mb-3 mb-xl-0">
                        {orderList?.map((o) => {
                            const { invoiceOrderProductDetailsList = [] } = o || {}
                            const getProductsName = invoiceOrderProductDetailsList &&
                                invoiceOrderProductDetailsList?.map((o) => o?.name?.toLowerCase())

                            return (<div className="single-order cursor-pointer"
                                data-bs-toggle="modal" data-bs-target="#order-details-modal" id="openOrderDetails"
                                onClick={() => openOrderDetails(o)} >
                                <div className="row align-items-center">
                                    <div className="col-sm-8">
                                        <h2 className="font13 mb-2"><strong>{o?.customerFirstName?.toUpperCase()} {o?.customerLastName?.toUpperCase()}</strong></h2>
                                        <h3 className="font13 mb-2">Order no: <strong>{o?.invoiceOrderDetailsId}</strong></h3>
                                        <p className="font14 mb-2  title-case" title={getProductsName}>
                                            {getProductsName.join(',')}</p>
                                        <div className="row font12 fw-bold">
                                            <div className="col-6 col-lg">{ShowDateMoment(o?.createdDate)}</div>
                                            <div className="col-6 col-lg text-end text-lg-start">{o?.invoiceDetails?.natureOfOrder === 1 ? "Door Delivery" : "Store Pickup"}</div>
                                            <div className="col-12 col-lg pt-2 pt-lg-0"> Total: {o?.orderStatus === 1 ? `$${parseFloat(o?.invoiceDetails?.totalAmount)?.toFixed(2)}` : `$${parseFloat(o?.invoiceDetails?.processedAmount)?.toFixed(2)}`} </div>
                                        </div>
                                    </div>
                                    {OrderStatusLabel(o?.orderStatus, o?.invoiceDetails?.natureOfOrder)}
                                </div>
                                <hr className="bg-ltgrey-1 mb-4" />
                            </div>
                            )
                        })}
                        <div className="row align-items-center">
                            <div className="col">
                                {
                                    data && data.length !== 0 && totalCount > 5 && <Pagination
                                        id="Pagination"
                                        activePage={activePage}
                                        itemsCountPerPage={5}
                                        totalItemsCount={totalCount}
                                        pageRangeDisplayed={5}
                                        onChange={(e) => handleChange(e)}
                                    />
                                }
                                {
                                    (data && data.length === 0 && !isLoading) && (
                                        <EmptyPage
                                            content="Orders not found"
                                        />
                                    )
                                }
                            </div>
                        </div>
                        {isLoading && <AppLoader />}
                    </div>
                    {
                        openQrScanner
                        && <QrCodeScanner
                            id="QrCodeScanner"
                            setQrScanner={setQrScanner}
                            onFailure={() => setQrScanner(false)}
                            setDeliverBtn={setDeliverBtn}
                            setOrderDetailsModal={setOrderDetailsModal}
                            setScanButton={setScanButton}
                            setOrderdetailsData={setOrderdetailsData}
                            apiFilters={apiFilters}
                            onClose={() => setOrderDetailsModal(false)}
                        />
                    }
                    {orderDetailsModal && <OrderDetails
                        id="OrderDetails"
                        onClose={() => setOrderDetailsModal(false)}
                        setOrderdetailsData={setOrderdetailsData}
                        setOrderDetailsModal={setOrderDetailsModal}
                        apiFilters={apiFilters}
                        ordersData={orderDetailsData}
                        deliverBtn={deliverBtn}
                        orderDetailsModal={orderDetailsModal}
                        scanButton={scanButton}
                        setActivePage={setActivePage}
                    />}

                    {
                        openDateFilterModal && <DateFilter
                            id="DateFilter"
                            onDateChange={(date, option) => onDateChange(date, option)}
                            startDate={modalFilters?.startDate}
                            endDate={modalFilters?.endDate}
                            title="DATE RANGE"
                            proceed="Apply Filter"
                            cancel="Close"
                            clearFilter={() => clearFilter()}
                            onSuccess={() => onFilterDone()}
                            onFailure={() => closeModal()}
                            errorMessage={filterValidationError}
                            renderStatus={() => {
                                return <>
                                    <div className="col-12">
                                        <select id="category" className="form-select cursor-pointer"
                                            value={filterType} onChange={(e) => {
                                                setFilterType(e?.target?.value)
                                                onStatusTypeChange(e?.target?.value)
                                            }} >
                                            {DEFAULT_FILTER_STATUS?.map((d) => {
                                                return (
                                                    <option value={d.id}>{d?.name}</option>)
                                            })}

                                        </select>
                                    </div>
                                </>
                            }}
                        />
                    }
                </div >
            </div >
        </div >
    )
}

export default OrderScreen;