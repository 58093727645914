import { all, put, takeLatest } from "redux-saga/effects";
import { doAuthGet } from "../../utils/service";
import END_POINTS from "../../utils/service/Endpoints";
import Actions from "../actions";

export function* getDaxbotSaga({ params, callback }) {
    try {
        const response = yield doAuthGet(END_POINTS.GET_DAXBOT, params);
        if (response) {
            yield put(Actions.getDaxbotActionSuccess(response));
        }
        callback && callback(response);
    }
    catch (error) {
        callback && callback(error);
        yield put(Actions.getDaxbotActionFailure({ error }));
    }
}
export function* trackDaxbotSaga({ params, callback }) {
    try {
        const response = yield doAuthGet(END_POINTS.TRACK_DAXBOT + params);
        if (response) {
            yield put(Actions.trackDaxbotActionSuccess(response));
        }
        callback && callback(response);
    }
    catch (error) {
        callback && callback(error);
        yield put(Actions.trackDaxbotActionFailure({ error }));
    }
}

export default function* getDaxbotListener() {
    yield all([
        takeLatest(Actions.GET_DAXBOT, getDaxbotSaga),
        takeLatest(Actions.TRACK_DAXBOT, trackDaxbotSaga)
    ]);
}
