import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import CreateRackModal from '../../components/CreateRackModal'
import EmptyPage from '../../components/emptyPage'
import Action from '../../redux/actions'
import { getPlanogramLoading, getPlanogramSelector, getPlanogramStoreSelector } from '../../redux/reducers/PlanogramReducer'
import Draggable from 'react-draggable';
import PlanogramButton from '../../components/PlanogramButton'
import RoutePath from '../../utils/routes/RoutePath'
import AppLoader from '../../components/fields/AppLoader'
import { toast } from "react-toastify";
import Constants from '../../utils/Constants'
import { convertStorePlanogramFormat } from '../../utils/commonFunctions/Planogram'
import { PLANOGRAM_IFRAME_STORE_URL } from '../../utils/service/Endpoints'
import Planogram3DModal from '../../components/Planogram3DModal'

class ManagePlanogram extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isEdit: false,
            isRackModal: false,
            planogram: [],
            isSavePlanogramLoading: false,
            percentagePx: 0,
            is3DMaximized: false,
        }
    }
    planogramDetailCallback = (response) => {
        if (response.error) {
            return false
        }
        setTimeout(() => {
            document.getElementById('iframe_store').contentWindow.postMessage({ message: convertStorePlanogramFormat(response.response) }, "*")
        }, 1000)
    }
    componentDidMount() {
        this.props.getPlanogramDetail({}, this.planogramDetailCallback);
        const { planogram } = this.props;
        this.setState({ planogram: [...planogram] })
        this.resizeHeight();
        window.addEventListener('resize', this.resizeHeight)
    }

    resizeHeight = () => {
        setTimeout(() => {
            const storeWidth = document.getElementById("storeContainer").offsetWidth
            this.setState({ percentagePx: (storeWidth / 100) })
        }, 200)
    }

    componentDidUpdate(previousProps) {
        const { planogram = [] } = this.props;
        if (planogram !== previousProps.planogram) {
            this.setState({ planogram: [...planogram] })
        }
    }

    onAddRack = (rack) => {
        const { planogram } = this.state;
        planogram.push(rack);
        this.setState({ planogram, isRackModal: false, isEdit: true })
    }

    dragStop = (e, d, i) => {
        let { planogram } = this.state;
        let { store } = this.props;
        const xAxis = (parseFloat(d.x) * Constants.PLANOGRAM.STORE_WIDTH) / this.getContainerWidth(true)
        const yAxis = (parseFloat(d.y) * this.getStoreStaticHeight(store)) / this.getContainerHeight(store, true)
        planogram[i] = {
            ...planogram[i],
            xAxis,
            yAxis,
        }
        this.setState({ isRackModal: false, planogram });
    };

    onAddNewRack = () => {
        this.setState({ isRackModal: true });
    }

    onCancel = () => {
        const { planogram } = this.props;
        this.setState({ planogram: [...planogram], isEdit: false })
    }

    onSaveChanges = () => {
        const { planogram } = this.state;

        const callback = (response) => {
            if (response?.error) {
                toast.error('Sorry somthing went wrong,please try again later', { position: toast.POSITION.TOP_RIGHT })
                return false
            } else if (response?.response_code === 0) {
                this.props.getPlanogramDetail({}, this.planogramDetailCallback);
            } else {
                toast.error(response?.response_message, { position: toast.POSITION.TOP_RIGHT })
            }
            this.setState({ isEdit: false, isSavePlanogramLoading: false });
        }
        this.setState({ isSavePlanogramLoading: true }, () => {
            this.props.savePlanogramRack({ racks: planogram }, callback)
        })
    }

    onEditPlanogram = () => {
        this.setState({ isEdit: true })
    }

    onRemove = (i) => {
        const { planogram } = this.state;
        planogram.splice(i, 1);
        this.setState({ planogram })
    }

    onRotate = (rotateIndex, store, obj) => {
        let { planogram } = this.state;
        let box = document.querySelector('#storeContainer');
        let overallWidth = box.offsetWidth;
        let axis = this.getRackStyle(store, obj)
        let containerHeight = this.getContainerHeight(store)
        let finallRackXAxis, finallRackYAxis
        planogram.map((rack, rackIndex) => {
            let getRackwidth = rack?.xAxis + parseInt(axis?.height.slice(0, -2))
            let getRackHeight = rack?.yAxis + parseInt(axis?.width.slice(0, -2))
            let overAllContianerHeight = parseInt(containerHeight.slice(0, -2))
            if (getRackwidth > overallWidth && rackIndex === rotateIndex) {
                let rackXAxis = getRackwidth - overallWidth
                finallRackXAxis = rack?.xAxis - rackXAxis
                planogram[rotateIndex].xAxis = finallRackXAxis
            }
            if (getRackHeight > overAllContianerHeight && rackIndex === rotateIndex) {
                let rackYAxis = getRackHeight - overAllContianerHeight
                finallRackYAxis = rack?.yAxis - rackYAxis
                planogram[rotateIndex].yAxis = finallRackYAxis
            }
        })
        planogram[rotateIndex].rackPosition = ((planogram[rotateIndex].rackPosition || 1)) === 4 ? 1 : (planogram[rotateIndex].rackPosition || 1) + 1
        this.setState({ planogram })
    }

    onClickRackView = (e, id) => {
        const { isEdit } = this.state;

        if (isEdit) {
            e.preventDefault();
        } else {
            this.props.history.push(RoutePath.planogramView(id))
        }
    }

    getContainerHeight = ({ storeLength, storeWidth }, isNotPx) => {
        const lengthPercent = storeLength / (storeWidth / 100);
        const { percentagePx } = this.state;
        let lengthInPx = (percentagePx * lengthPercent);
        return isNotPx ? lengthInPx : `${lengthInPx}px`;
    }

    getContainerWidth = (isNotPx) => {
        const { percentagePx } = this.state;
        return isNotPx ? (percentagePx * 100) : `${(percentagePx * 100)}px`;
    }

    getStoreStaticHeight = ({ storeLength, storeWidth }) => {
        const lengthPercent = storeLength / (storeWidth / 100);
        let lengthInPx = ((Constants.PLANOGRAM.STORE_WIDTH / 100) * lengthPercent);
        return lengthInPx;
    }
    getRackTextStyle = (rackPosition) => {
        return { transform: `rotate(${-((parseInt(rackPosition || 1) - 1) * 270) - 180}deg)` };
    }

    getRackStyle = ({ storeWidth }, { width: rackWidth, depth: rackDepth, rackPosition = 1 } = {}) => {
        const rackWidthPercent = rackWidth / (storeWidth);
        const rackDepthPercent = rackDepth / (storeWidth);
        const { percentagePx } = this.state;
        let rackWidthInPx = (percentagePx * rackWidthPercent);
        let rackDepthInPx = (percentagePx * rackDepthPercent);
        switch (rackPosition) {
            case 4:
                return { height: `${rackWidthInPx}px`, width: `${rackDepthInPx}px` };
            case 3:
                return { height: `${rackDepthInPx}px`, width: `${rackWidthInPx}px` };
            case 2:
                return { height: `${rackWidthInPx}px`, width: `${rackDepthInPx}px` };
            default:
                return { height: `${rackDepthInPx}px`, width: `${rackWidthInPx}px` };
        }

    }

    getRackTypeClass = (type) => {
        switch (type) {
            case 1:
                return 'btn-rack-color btn btn-primary btn-planogram-rack';
            case 2:
                return 'btn-cooler-color btn btn-secondary btn-planogram-rack';
            case 3:
                return 'btn-rack-color btn btn-danger btn-planogram-rack';
            default:
                return '';
        }
    }


    openInventory() {
        this.props.history.push(RoutePath.PLANOGRAM_REPORT)
    }

    render() {
        const { isEdit, isRackModal, planogram, isSavePlanogramLoading, percentagePx, is3DMaximized } = this.state;
        const { planogramLoading, store = {} } = this.props
        return (
            <div className="col ps-lg-4">
                <div className="row mb-4">
                    <div className="col"> <h1 className="font20 fw-bold">Planogram</h1> </div>
                    <div className="col text-end">
                        <button className="btn btn-outline-dark" onClick={() => this.openInventory()}>
                            Inventory report
                        </button>
                        {(!isEdit && planogram.length > 0) && <button id="previewButton" className="btn btn-outline-dark ml-2" onClick={() => this.setState({ is3DMaximized: true })}>Preview In 3D</button>}
                        {(!isEdit && planogram.length > 0) && <button id="editButton" className="btn btn-outline-dark ml-2" onClick={this.onEditPlanogram}>Edit</button>}

                        {(planogram.length === 0) && <button id="addButton" className="btn btn-outline-dark" onClick={() => this.onAddNewRack()}>Add New</button>}
                    </div>
                </div>
                <div id="planogram">
                    <div className="row justify-content-center">
                        <div id="storeContainer" className="col-12 col-lg-6 p-2 store-location" style={{ height: this.getContainerHeight(store) }}>
                            {planogram.length === 0 && <div className='store-location-empty'><EmptyPage content="No Rack added yet" /></div>}
                            {
                                planogram.map((obj, i) => {
                                    const { xAxis, yAxis } = obj;
                                    const x = ((parseFloat(xAxis) * this.getContainerWidth(true))) / Constants.PLANOGRAM.STORE_WIDTH
                                    const y = (parseFloat(yAxis) * this.getContainerHeight(store, true)) / this.getStoreStaticHeight(store)
                                    return (<Draggable
                                        id="draggable"
                                        key={i}
                                        disabled={!isEdit}
                                        handle=".handle"
                                        bounds="parent"
                                        defaultPosition={{ x: 0, y: 0 }}
                                        position={{ x, y }}
                                        positionOffset={{ x: 0, y: 0 }}
                                        grid={[1, 1]}
                                        scale={1}
                                        onStart={this.eventLogger}
                                        onDrag={this.eventLogger}
                                        onStop={(e, d) => this.dragStop(e, d, i)}>
                                        <div id='onClickRackView' className='handle' onClick={(e) => this.onClickRackView(e, obj.id)}>
                                            <button type="button" className={this.getRackTypeClass(obj.rackType)} style={this.getRackStyle(store, obj)}>
                                                {isEdit && <div id='onRemove' className='close-icon' title="Remove Rack from the store" onClick={() => this.onRemove(i)}><span className='fas fa-times'></span></div>}
                                                {isEdit && <div id='onRotate' className='rotate-icon' title="Rotate 90 degree" onClick={() => this.onRotate(i, store, obj)}><span className="fas fa-undo" aria-hidden="true"></span></div>}
                                                <span className='rack-name' style={this.getRackTextStyle(obj.rackPosition)}>{obj.rackName}</span>
                                            </button>
                                        </div>
                                    </Draggable>)
                                })
                            }
                        </div>
                        {isEdit && <div className='col-12 col-lg-3'>
                            {isEdit && <PlanogramButton onSaveChanges={this.onSaveChanges} onCancel={this.onCancel} onAddNewRack={this.onAddNewRack} />}
                        </div>}
                        {/* <div className="col-12 col-lg-5 p-0 store-location ml-1" style={{ height: this.getContainerHeight(store) }}>
                            <div id='planogram-store-3d-view' className='rack-canvas p-2 mt-0' style={{ height: this.getContainerHeight(store) }}>
                                <div className="btn-maximize" onClick={() => this.setState({ is3DMaximized: true })}><span className="fa fa-arrows-alt" ></span></div>
                                <iframe id="iframe_store" src={PLANOGRAM_IFRAME_STORE_URL} style={{ width: '100%', height: '100%', boxShadow: 'rgb(0 0 0 / 16%) 0px 1px 4px' }}></iframe>
                            </div>
                        </div> */}
                    </div>
                    {isRackModal && <CreateRackModal id="createRackModal" isModalOpen={isRackModal} onCloseModal={() => this.setState({ isRackModal: false })} result={planogram} onAddRack={this.onAddRack} title="Create Rack" />}
                </div>
                {(planogramLoading || isSavePlanogramLoading) && <AppLoader />}
                {
                    (is3DMaximized && <Planogram3DModal isModalOpen={is3DMaximized} onCloseModal={() => this.setState({ is3DMaximized: false })} Store={store} srcUrl={PLANOGRAM_IFRAME_STORE_URL} />)
                }
            </div>
        )
    }

}

export const mapStateToProps = (state) => {
    return {
        planogram: getPlanogramSelector(state),
        store: getPlanogramStoreSelector(state),
        planogramLoading: getPlanogramLoading(state)
    };
};

export default withRouter(
    connect(mapStateToProps, {
        getPlanogramDetail: Action.getPlanogramAction,
        savePlanogramRack: Action.savePlanogramRackAction,
    })(ManagePlanogram)
);