import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import DeclineModal from '../../components/DeclineModal';
import { getRefundDetailsAction, getRefundDetailsSuccessAction, updateRefundStatusAction } from '../../redux/actions/RefundActions';
import Constants from '../../utils/Constants';
import moment from 'moment';
import Close from '../../../asset/new/img/modal-close.svg'
import Greentick from '../../../asset/new/img/green-tick.svg'
import Pending from '../../../asset/new/img/pending.svg'
import Declined from '../../../asset/new/img/red-cross.svg'
import AppLoader from '../../components/fields/AppLoader';
import ViewInvoiceModal from '../../components/ViewInvoiceModal';
import Actions from "../../redux/actions";
import { store } from '../../redux/store';
import {
    getRefundDetailSelector,
} from '../../redux/reducers/RefundReducer';
import RefundPartialPopUp from './RefundPartialPopUp';
import RefundPreview from './RefundPreview';
const RefundDetails = ({ onClose, onSuccessClose, refundOptions, refundId, isHideViewReceipt, invoiceId, onCloseViewRefundReceipt }) => {
    const dispatch = useDispatch();
    const refundDetails = useSelector(getRefundDetailSelector);
    const [showDeclineModal, setShowDeclineModal] = useState(false);
    const [viewInvoiceModal, setViewInvoiceModal] = useState(false)
    const [declineText, setDeclineText] = useState('');
    const queryParams = useParams();
    const [isSelectedProduct, setSelectedProduct] = useState([])
    const [selectedAll, setSelectAllKey] = useState(false)
    const [isLoading, setLoader] = useState(false)
    const [showRefund, setShowRefund] = useState(false)
    const [finalProductArray, setFinalProductArray] = useState([])
    const [isOpenPartialPopUp, setPartialPopUp] = useState(false)
    const [partialRefundComment, setPartialRefundAmount] = useState(null)
    const [refundImage, setRefundImage] = useState(null)
    const [isOpenImageModal, setOpenImageModal] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)
    const [isPartialCheck, setPartialCheckup] = useState(false)
    useEffect(() => {
        if (invoiceId) {
            dispatch(getRefundDetailsAction({ id: invoiceId }, initialCallBack));
        } else {
            dispatch(getRefundDetailsAction({ id: refundId ? refundId : refundOptions?.refundDetailsId }, initialCallBack));
        }
    }, [dispatch, queryParams]);

    useEffect(() => {
        if (finalProductArray.length !== 0) {
            if (isPartialCheck) {
                setPartialPopUp(true)
            } else {
                acceptOrDeclineOrder(12)
            }
        }
    }, [dispatch, isPartialCheck, finalProductArray])

    const initialCallBack = (res) => {
        if (res.response_code === 0) {
            setShowRefund(true)
        }
    }

    const handlePartialReason = (e) => {
        setPartialRefundAmount(e.target.value)
    }

    const acceptOrDeclineOrder = (statusType) => {
        const { refundDetailsId } = refundDetails;
        const params = {
            "status": statusType,
        }
        const callback = (resp) => {
            if (resp.response_code === 0) {
                if (statusType === Constants.REFUND_STATUS.REJECT) {
                    toast.success('Refund Declined Successfully.', { position: toast.POSITION.TOP_RIGHT })
                    setShowDeclineModal(false)
                    if (isHideViewReceipt) {
                        dispatch(getRefundDetailsSuccessAction(resp));
                        onSuccessClose(resp?.response?.invoiceId)
                    } else {
                        onSuccessClose('success')

                    }
                } else {
                    if (isHideViewReceipt) {
                        dispatch(getRefundDetailsSuccessAction(resp));
                        onSuccessClose(resp?.response?.invoiceId)
                    } else {
                        onSuccessClose('success')

                    }
                    setPartialPopUp(false)
                    toast.success('Refund Accepted Successfully.', { position: toast.POSITION.TOP_RIGHT })
                }
            } else {
                toast.error(`${resp}`, { position: toast.POSITION.TOP_RIGHT })
            }
            setLoader(false)
        }

        if (statusType === 13) {
            params.refundDetailsId = refundDetailsId
            params.partialRefundComment = declineText;
            if (!declineText) {
                setErrorMessage("Please enter the comments.")
            } else {
                dispatch(Actions.updateRefundStatusAction(params, callback))
                setLoader(true)
            }
        }

        if (statusType === 12) {
            params.refundDetailsId = refundDetailsId
            params.items = finalProductArray
            params.partialRefundComment = partialRefundComment
            if (!isPartialCheck) {
                dispatch(updateRefundStatusAction(params, callback))
                setLoader(true)
            } else if (isPartialCheck && !partialRefundComment) {
                setErrorMessage("Please enter the comments.")
            } else {
                dispatch(updateRefundStatusAction(params, callback))
                setLoader(true)
            }
        }

    }
    
    const RefundOptions = (type) => {
        switch (type) {
            case 0:
                return 'Wrong Product'
            case 1:
                return 'Damage Product'
            case 2:
                return 'Wrong amount'
            case 3:
                return 'Other'
            default:
                return null
        }
    }
    useLayoutEffect(() => {
        if (!showDeclineModal) {
            setDeclineText('');
        }
    }, [showDeclineModal]);



    const handelButtonClick = (type) => {
        if (type === 2) {
            setShowDeclineModal(true)
        }
        else {
            setPartialCheckup(false)
            let finalProductArray = []
            refundDetails?.invoiceProductDetailsList?.map((item) => {
                if (item.isSelectedCheck) {
                    finalProductArray.push({
                        invoiceOrderProductDetailsId: item?.invoiceProductDetailsId,
                        quantity: item?.newQuantity,
                        name: item?.name
                    });
                } else {
                    setPartialCheckup(true)
                }
            });

            finalProductArray.forEach((i) => {
                let index;
                index = refundDetails
                    ?.invoiceProductDetailsList.findIndex(
                        (item) =>
                            item.invoiceProductDetailsId ===
                            i.invoiceOrderProductDetailsId,
                    );
                if (index !== -1) {
                    if (refundDetails?.invoiceProductDetailsList[index]?.refundQuantity !== i?.quantity) {
                        setPartialCheckup(true)
                    }
                }
            });

            setFinalProductArray(finalProductArray)
        }
    }

    const confirmDecline = () => {
        acceptOrDeclineOrder(Constants.REFUND_STATUS.REJECT);
    }
    const ViewInvoice = (value) => {
        setViewInvoiceModal(value)
    }


    const _renderOrderDetails = () => {
        const { deliveryAddress, natureOfOrder, storeAddress } = refundDetails || {}
        let address = {};
        try {
            address = JSON.parse(deliveryAddress)
        } catch (e) {
            console.log(e)
        }
        let { formattedAddress, landmark, street } = address || {}

        let storeAddressData = {};
        try {
            storeAddressData = JSON.parse(storeAddress)
        } catch (e) {
            console.log(e)
        }
        return (<>
            <div className="row align-items-center mb-4">
                <div className="col-auto">
                    <div
                        className="p-3 font16 bg-lgrey text-center border border-dark rounded-circle">
                        {refundDetails?.customerFirstName?.charAt(0)?.toUpperCase()}
                        {refundDetails?.customerLastName?.charAt(0)?.toUpperCase()}
                    </div>
                </div>
                <div className="col text-uppercase">
                    {refundDetails?.customerFirstName}&nbsp;
                    {refundDetails?.customerLastName}<br />
                    <strong>{refundDetails?.mobileNumber}</strong>
                </div>
            </div>
            {natureOfOrder === 1 ? <div> <h3 className="font16 fw-bold mb-2">Delivery address</h3>
                <p className="font14 mb-2">{street ? `${street},` : ''} {landmark ? `${landmark},` : ''} {formattedAddress}</p></div>
                : <div> <h3 className="font16 fw-bold mb-2">Pickup Address:</h3>
                    <p className="font14 mb-2">  {storeAddressData?.branch_name ? `${storeAddressData?.branch_name},` : ''}
                        {storeAddressData?.address ? `${storeAddressData?.address} ` : ''}
                        {storeAddressData?.zip_code ? `${storeAddressData?.zip_code}.` : ''}</p>
                </div>}

            {refundDetails?.actionComment && <span>Declined Reason: <b>{refundDetails?.actionComment}</b></span>}

        </>)

    }
    const isSelectAll = (index, qty, event) => {
        if (event?.target?.checked) {
            let tempArray = store.getState()?.REFUNDS?.refundDetails
            if (tempArray?.invoiceProductDetailsList) {
                tempArray.invoiceProductDetailsList[index].isSelectedCheck = true;
                tempArray.invoiceProductDetailsList[index].newQuantity = qty;
                dispatch(Actions.updatedSelectedProduct(tempArray))
                isVerified();
            }
            setSelectAllKey(true)
            setPartialCheckup(false)
        } else {
            let tempArray = store.getState()?.REFUNDS?.refundDetails
            if (tempArray?.invoiceProductDetailsList) {
                tempArray.invoiceProductDetailsList[index].isSelectedCheck = false;
                tempArray.invoiceProductDetailsList[index].newQuantity = qty;
                dispatch(Actions.updatedSelectedProduct(tempArray))
                isVerified();
            }
            setSelectAllKey(false)
            setPartialCheckup(true)
        }

    };



    const isIconCheckedOrNot = (index, qty, select) => {
        let tempArray = store.getState()?.REFUNDS?.refundDetails
        if (tempArray?.invoiceProductDetailsList) {
            tempArray.invoiceProductDetailsList[index].isSelectedCheck = select;
            tempArray.invoiceProductDetailsList[index].newQuantity = qty;
            dispatch(Actions.updatedSelectedProduct(tempArray))
            setSelectAllKey(false)
            isVerified();
        }
        setPartialCheckup(true)

    };

    const decrementProduct = (index, qty, select) => {
        let tempArray = store.getState()?.REFUNDS?.refundDetails
        if (tempArray?.invoiceProductDetailsList) {
            if (qty > 0) {
                let quantity = qty - 1;
                tempArray.invoiceProductDetailsList[index].isSelectedCheck = select;
                tempArray.invoiceProductDetailsList[index].newQuantity = quantity;
            }
            dispatch(Actions.updatedSelectedProduct(tempArray))
            isVerified();
        }
    }

    const incrementProduct = (index, quantity, select) => {
        let tempArray = store.getState()?.REFUNDS?.refundDetails
        if (tempArray?.invoiceProductDetailsList) {
            if (quantity < refundDetails.invoiceProductDetailsList[index].quantity) {
                let qty = quantity + 1;
                tempArray.invoiceProductDetailsList[index].isSelectedCheck = select;
                tempArray.invoiceProductDetailsList[index].newQuantity = qty;
            }
            dispatch(Actions.updatedSelectedProduct(tempArray))
            isVerified();
        }
    }

    const isVerified = () => {
        let checker;
        checker = refundDetails?.invoiceProductDetailsList?.filter((v) => v.isSelectedCheck === true);
        let verifyCheck = refundDetails?.invoiceProductDetailsList?.every(choice => choice.isSelectedCheck === true)
        if (verifyCheck) {
            setSelectAllKey(true)
        }
        setSelectedProduct(checker)
    };

    const RefundButton = () => {
        return refundDetails?.action === Constants.REFUND_STATUS.PENDING &&
            <div className="row mt-5">
                <div className="col-7 pe-0">
                    <button
                        className="btn btn-success font14 w-100 p-3"
                        id="APPROVE"
                        onClick={() => handelButtonClick(1)}
                        disabled={isSelectedProduct?.length === 0 ? true : false}>
                        APPROVE
                    </button>
                </div>
                <div className="col-5 text-end">
                    <button disabled={isSelectedProduct?.length !== 0 ? true : false} className="btn btn-outline-danger font14 w-100 p-3"
                        id="DECLINE"
                        onClick={() => handelButtonClick(2)}> DECLINE </button>
                </div>
            </div>
    }
    const openPreview = (image) => {
        setOpenImageModal(true)
        setRefundImage(image)
    }
    return (<>
        {showRefund && <div className="modal d-block" id="order-details-modal"
            tabIndex="-1" aria-labelledby="login" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content rounded-0">
                    <img src={Close} alt="X" data-bs-dismiss="modal" aria-label="Close" id="Close"
                        className="modal-close" onClick={() => isHideViewReceipt ? onCloseViewRefundReceipt() : onClose()} />

                    <div className="modal-header rounded-0 align-items-center py-4">
                        <div className="col"> <h5 className="modal-title">Refund details</h5> </div>
                        <div className="col font13 text-end text-warning">
                            {refundDetails?.action === 12 && <div className="col font13 text-end text-success">
                                <img src={Greentick} alt="Yes" className="me-1" /> Refund Approved
                            </div>} {refundDetails?.action === 11 && <div className="col font13 text-end text-warning">
                                <img src={Pending} alt="Yes" className="me-1" /> Pending
                            </div>}
                            {refundDetails?.action === 13 && <div className="col font13 text-end text-danger">
                                <img src={Declined} alt="Yes" className="me-1" /> Refund Rejected
                            </div>}
                        </div>
                    </div>

                    <div className="modal-body font13 p-40">
                        {_renderOrderDetails()}
                        <hr className="thick" />
                        <div className="row mb-4">
                            <div className="col"> <h3 className="font16 fw-bold">Refund Items</h3></div>
                            <div className="col-auto text-end font14 fw-bold cursor-pointer">
                                {!isHideViewReceipt && <span className='cursor-pointer text-primary'
                                    data-bs-toggle="modal"
                                    data-bs-target="#invoice-modal"
                                    id="ViewReceipt"
                                    onClick={() => ViewInvoice(true)}>View Receipt</span>} </div>
                        </div>
                        {
                            refundDetails?.action === 11 && <div className="row">
                                <div className="col-sm-1">
                                    <input
                                        id='selectAll'
                                        type="checkbox"
                                        className='cursor-pointer'
                                        checked={selectedAll}
                                        onChange={(e) => {
                                            refundDetails?.invoiceProductDetailsList?.forEach(
                                                (item, index) => {
                                                    isSelectAll(
                                                        index,
                                                        item.refundQuantity,
                                                        e
                                                    );
                                                },
                                            );
                                        }}
                                    />
                                </div>
                                <div className='col-auto'>
                                    <p className='text-primary'><b>SELECT ALL</b></p>
                                </div>
                            </div>
                        }

                        {refundDetails?.invoiceProductDetailsList?.map((o, i) => {
                            let qtydiff = o?.refundQuantity - o?.refundApprovedQuantity;
                            return (<div className={`card card-body p-0 border-0 pb-2 mb-2
                             ${refundDetails?.invoiceProductDetailsList?.length > 1 ? 'border-bottom' : ''}`}>
                                <div className="row">
                                    {
                                        refundDetails?.action === 11 && <div className="col-sm-1">
                                            <input
                                                id='checkbox'
                                                type="checkbox"
                                                className='cursor-pointer'
                                                checked={o?.isSelectedCheck}
                                                onClick={() => isIconCheckedOrNot(
                                                    i,
                                                    o?.refundQuantity,
                                                    o?.isSelectedCheck ? !o?.isSelectedCheck : true)}
                                            />
                                        </div>
                                    }

                                    <div className={`${refundDetails?.action === 11 ? 'col-sm-7' : 'col-sm-8'}`}>
                                        <h3 className={`font13 mb-2  title-case ${(refundDetails?.action !== 11 && o?.refundApprovedQuantity === 0) ? 'strike' : ''}`}
                                            title={`${o?.refundQuantity} X ${o?.name} (SKU: ${o?.upc})`}>
                                            {refundDetails?.action === 11 || o?.refundApprovedQuantity === 0 ? o?.refundQuantity : o?.refundApprovedQuantity} x {o?.name?.toLowerCase()} (SKU: {o?.upc})
                                            {o?.invoiceProductAddOnMappingList?.length > 0 ? (
                                                <p className="mb-1 font-14" style={{ paddingLeft: '10px' }}>
                                                    <p className='font-13 ml-2'><b></b>YOUR CUSTOMIZATION:</p>
                                                    {o?.invoiceProductAddOnMappingList?.map((o) => o?.name).join(',')}
                                                </p>) : ''
                                            }
                                        </h3>


                                        <p style={{ paddingLeft: "10px" }}> Refund Reason: <b>{RefundOptions(o?.refundType)}</b></p>
                                        {
                                            o?.refundReason && <span style={{ paddingLeft: "10px" }}><b>Refund Comment:</b> {o?.refundReason}</span>
                                        }
                                        {
                                            (refundDetails?.action !== 11 && qtydiff !== 0 && o?.refundApprovedQuantity !== 0) ? <p style={{ color: 'red', paddingLeft: "10px" }}> Unfulfilled Refund Quantity: {qtydiff}</p> : null
                                        }
                                    </div>
                                    {
                                        refundDetails?.action === 11 && <div className='col-sm-2'>
                                            {
                                                o?.isSelectedCheck &&
                                                <div className="order-quantity">
                                                    <button className="quantity__minus cursor-pointer" id="decrementProduct"
                                                        disabled={o?.newQuantity === 1}
                                                        onClick={() => decrementProduct(
                                                            i,
                                                            o?.newQuantity,
                                                            o?.isSelectedCheck,
                                                        )}><span
                                                        >-</span></button>
                                                    <input name="quantity" type="text" className="quantity__input"
                                                        disabled value={o?.newQuantity} />
                                                    <button className="quantity__plus cursor-pointer" id="incrementProduct"
                                                        onClick={() => incrementProduct(
                                                            i,
                                                            o?.newQuantity,
                                                            o?.isSelectedCheck,
                                                        )}
                                                        disabled={o?.newQuantity === o?.refundQuantity} >
                                                        <span>+</span>
                                                    </button>
                                                </div>
                                            }
                                        </div>
                                    }


                                    {/* <div className={`${refundDetails?.action === 11 ? 'col-sm-2 text-end' : 'col-sm-4 text-end'}`}>
                                        <p className="mb-4">
                                            ${o?.invoiceProductAddOnMappingList.length > 0
                                                ? o.totalAmountExcludingTax.toFixed(2) : o?.amountExcludingTax.toFixed(2)} </p>
                                    </div> */}
                                </div>
                            </div>)
                        })}
                        {
                            refundDetails?.refundImageUrl?.length > 0 &&
                            <div className="mt-2">
                                {
                                    refundDetails?.refundImageUrl?.map((o) => {
                                        return (
                                            <span
                                                id='refundImg'
                                                style={{ marginLeft: '20px' }}
                                                onClick={() => openPreview(o?.imageUrl + '?$' + Math.random())}>
                                                <img
                                                    src={o?.imageUrl + '?$' + Math.random()}
                                                    alt="img"
                                                    style={{ width: '100px' }}
                                                    className="cursor-pointer"
                                                />
                                            </span>
                                        )
                                    })
                                }
                            </div>
                        }

                        <div className="dotted-divider pt-2 mt-2 mb-3">


                            <div className="row mb-2">
                                <div className="col"><strong>Receipt no:</strong ></div>
                                <div className="col-auto text-end"> {refundDetails?.invoiceId}</div>
                            </div>
                            <div className="row mb-2">
                                <div className="col"><strong>Refund no:</strong></div>
                                <div className="col-auto text-end"> {refundDetails?.refundDetailsId}</div>
                            </div>
                            <div className="row mb-2">
                                <div className="col"><strong>Requested date:</strong></div>
                                <div className="col-auto text-end">
                                    {moment(refundDetails?.createdDate).format('DD-MMM | h:mm A')}
                                </div>
                            </div>
                            {
                                refundDetails?.partialRefundComment && <div className="row mb-2">
                                    <div className="col"><strong>Refund comment:</strong ></div>
                                    <div className="col-auto"> {refundDetails?.partialRefundComment}</div>
                                </div>
                            }

                        </div>

                        <div className="row mb-2">
                            <div className="col fw-bold">Requested amount</div>
                            <div className="col-auto text-end fw-bold"> ${parseFloat(refundDetails?.totalAmount)?.toFixed(2)}</div>
                        </div>
                        {(refundDetails?.action === 12 || refundDetails?.action === 13) && <div className="row mb-1">
                            <div className="col fw-bold">Processed amount:</div>
                            <div className="col-auto text-end fw-bold">${refundDetails?.acceptedAmount?.toFixed(2)}</div>
                        </div>}
                        {RefundButton()}
                    </div>
                </div>
            </div>
            {
                showDeclineModal && <DeclineModal
                    id="declineModal"
                    show={showDeclineModal}
                    title={'Reason for decline'}
                    onClose={() => setShowDeclineModal(false)}
                    onChange={(value) => setDeclineText(value?.target?.value)}
                    errorMsg="Please enter reason to reject"
                    confirm="Reject"
                    cancel="Cancel"
                    onClick={(type) => {
                        if (type === 1) {
                            setShowDeclineModal(false)
                        } else {
                            confirmDecline()
                        }
                    }} />
            }
            {
                isOpenPartialPopUp && <RefundPartialPopUp
                    onFailure={() => setPartialPopUp(false)}
                    onSuccess={() => acceptOrDeclineOrder(12)}
                    productArray={finalProductArray}
                    handlePartialReason={(e) => handlePartialReason(e)}
                    selectedAll={isPartialCheck}
                    errorMessage={errorMessage}
                />
            }
        </div>
        }
        {
            isLoading && <AppLoader />
        }
        <div className="modal-backdrop show"></div>
        {
            viewInvoiceModal && <ViewInvoiceModal viewInvoiceModal={viewInvoiceModal}
                id="invoiceDetails"
                setViewInvoiceModal={() => setViewInvoiceModal(false)}
                ViewInvoiceDetailsId={refundDetails?.invoiceId}
                hideViewRefund={true}
            />
        }
        {
            isOpenImageModal && <RefundPreview
                onFailure={() => setOpenImageModal(false)}
                refundImage={refundImage}
            />
        }
    </>
    )
}


export default RefundDetails;