import axios from 'axios';
import { getClientCredentialsToken } from '../../redux/reducers/ClientCredentials';
import { store } from '../../redux/store';
import END_POINTS, { BASE_URL, CV_BASE_URL, PROMOTION_BASE_URL } from './Endpoints';
import Actions from '../../redux/actions/index';
import Constants from '../Constants';
import MomentFunctions from '../commonFunctions/MomentFunctions';
import { ClearCookie, getCookie } from '../commonFunctions/cookie';


const axiosInstance = axios.create({
    baseURL: BASE_URL,
});

const axiosPromotionInstance = axios.create({
    baseURL: PROMOTION_BASE_URL,
});

//CV axious Instance
// const axiosCvInstance = axios.create({
//     baseURL: CV_BASE_URL
// })

export const cookieSelector = () => {
    function getCookie(name) {
        let cookie = {};
        document.cookie.split(';').forEach(function (el) {
            let [k, v] = el.split('=');
            cookie[k.trim()] = v;
        })
        return cookie[name];
    }
    return getCookie('access_token') ? getCookie('access_token') : undefined
}




export const publicHeaders = () => {
    const selectedStoreId = store?.getState()?.STORE_LIST?.selectedStoreData
    return {
        'Authorization': "Basic dXN0and0Y2xpZW50aWQ6dXN0UmQ5M0NmNFdfRm13WiY=",
        "Content-Type": "multipart/form-data",
        'storeId': selectedStoreId?.value || 1,

    }
}

export const httpAuthHeader = () => {
    const acc_token = cookieSelector()
    const selectedStoreId = store?.getState()?.STORE_LIST?.selectedStoreData

    return {
        'Authorization': `bearer ${acc_token}`,
        'Content-Type': 'application/json',
        'storeId': selectedStoreId?.value || 1,

    }
}

export const refreshToken = (refresh_token) => {
    let data = new FormData()
    data.append('grant_type', 'refresh_token')
    data.append('refresh_token', refresh_token)
    const config = {
        headers: publicHeaders()
    };
    return axiosInstance.post(END_POINTS.CLIENT_CREDENTIALS, data, config)
        .then(function (response) {
            return response.data
        }).catch((error) => {
            throw error
        })
};

// added interceptors for refresh token strategy
axiosInstance.interceptors.request.use(
    async config => {
        return config;
    },
    error => {
        Promise.reject(error)
    });

axiosInstance.interceptors.response.use((response) => {
    return response
}, async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const token = getCookie('refresh_token') ? getCookie('refresh_token') : undefined
        if (token) {
            const refreshTokenObj = await refreshToken(token);
            if (refreshTokenObj && refreshTokenObj.access_token) {
                refreshTokenObj.expMilliseconds = MomentFunctions.convertExpirySecToMilliSec(refreshTokenObj.expires_in);
            }

            // Set login tokens in cookies
            let expiration_date = new Date();
            expiration_date.setMonth(expiration_date.getMonth() + 1);
            document.cookie = `token=${JSON.stringify(refreshTokenObj)};domain=${Constants.COMMON_APP_DOMAINS.cookieDomain};path=/;expires=${expiration_date.toGMTString()}`
            document.cookie = `access_token=${refreshTokenObj?.access_token};domain=${Constants.COMMON_APP_DOMAINS.cookieDomain};path=/;expires=${expiration_date.toGMTString()}`
            document.cookie = `refresh_token=${refreshTokenObj?.refresh_token};domain=${Constants.COMMON_APP_DOMAINS.cookieDomain};path=/;expires=${expiration_date.toGMTString()}`

            // Set login tokens in store

            store.dispatch(Actions.loginSuccessAction(refreshTokenObj))
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + refreshTokenObj?.access_token;
            originalRequest.headers.Authorization = `bearer ${refreshTokenObj?.access_token}`
            return axiosInstance(originalRequest);
        } else {
            // Empty cookies
            store.dispatch(Actions.logoutSuccessAction())
            //NavHomeScreen
            ClearCookie()
        }
    } else if (error.response.status > 401 && error.response.status <= 503) {
        // Empty cookies
        store.dispatch(Actions.logoutSuccessAction())
        //NavHomeScreen
        ClearCookie()

    }
    return Promise.reject(error);
});

export const clientCredentialsHeaders = () => {
    const token = getClientCredentialsToken(store?.getState())?.access_token;
    const selectedStoreId = store?.getState()?.STORE_LIST?.selectedStoreData

    return {
        'Authorization': `bearer ${token}`,
        'Content-Type': 'application/json',
        'storeId': selectedStoreId?.value || 1,
    }
}


export const checkResponse = (response) => {
    if (typeof response?.data === 'string') {
        return response.data;
    }
    if (response.status === 200 && response.data.response_code === 0) {
        return response.data;
    } else if (response.status === 200 && response.data.response_code !== 0) {
        throw response.data.response_message;
    }
    return response;
}

//generate client credentials
export const clientCredentials = (url, data) => {
    const config = {
        headers: publicHeaders()
    };
    return axiosInstance.post(url, data, config)
        .then(function (response) {
            return response.data
        }).catch((error) => {
            throw error
        })
}


// Access Request
export const doAuthGet = (url, params) => {
    const config = {
        params,
        headers: httpAuthHeader(),
    };
    return axiosInstance.get(url, config)
        .then(function (response) {
            return checkResponse(response)
        }).catch((error) => {
            throw error
        });
}


export const doAuthPost = (url, data) => {
    const config = {
        headers: httpAuthHeader()
    };
    return axiosInstance.post(url, data, config)
        .then(function (response) {
            return checkResponse(response)
        }).catch((error) => {
            throw error
        })
}

export const doAuthPut = (url, data) => {
    const config = {
        headers: httpAuthHeader()
    };
    return axiosInstance.put(url, data, config)
        .then(function (response) {
            return checkResponse(response)
        }).catch((error) => {
            throw error
        })
}


// Client Request


export const doClientGet = (url, params) => {
    const config = {
        params,
        headers: clientCredentialsHeaders(),
    };
    return axiosInstance.get(url, config)
        .then(function (response) {
            return checkResponse(response)
        }).catch((error) => {
            throw error
        })
}

export const doClientPost = (url, data) => {
    const config = {
        headers: clientCredentialsHeaders(),
    };
    return axiosInstance.post(url, data, config)
        .then(function (response) {
            return response.data
        }).catch((error) => {
            throw error
        })
}

export const doSignupPost = (url, data) => {
    const config = {
        headers: httpPromotionHeader(),
    };
    return axiosInstance.post(url, data, config)
        .then(function (response) {
            return checkResponse(response)
        }).catch((error) => {
            throw error
        })
}

//Promotion 

export const httpPromotionHeader = () => {
    const token = cookieSelector()
    const selectedStoreId = store?.getState()?.STORE_LIST?.selectedStoreData

    return {
        'Authorization': `bearer ${token}`,
        'Content-Type': 'application/json',
        'storeId': selectedStoreId?.value || 1,
    }
}

export const doPromotionGet = (url, params) => {
    const config = {
        params,
        headers: httpPromotionHeader(),
    };
    return axiosPromotionInstance.get(url, config)
        .then(function (response) {
            return response.data
        }).catch((error) => {
            throw error
        });
}


export const doPromotionPost = (url, data) => {
    const config = {
        headers: httpPromotionHeader()
    };
    return axiosPromotionInstance.post(url, data, config)
        .then(function (response) {
            return response.data
        }).catch((error) => {
            throw error
        })
}

export const doPromotionPut = (url, data) => {
    const config = {
        headers: httpPromotionHeader()
    };
    return axiosPromotionInstance.put(url, data, config)
        .then(function (response) {
            return response.data
        }).catch((error) => {
            throw error
        })
}
export const doPromotionDelete = (url, data) => {
    const config = {
        data,
        headers: httpPromotionHeader()
    };
    return axiosPromotionInstance.delete(url, config)
        .then(function (response) {
            return response.data
        }).catch((error) => {
            throw error
        })
}

export const doPost = (url, params) => {
    const config = {
        headers: httpAuthHeader()
    };
    return axiosInstance.post(url, params, config)
        .then(function (response) {
            return response.data
        }).catch((error) => {
            throw error
        })
}
export const doPut = (url, params) => {
    const config = {
        headers: httpAuthHeader()
    };
    return axiosInstance.put(url, params, config)
        .then(function (response) {

            return response.data
        }).catch((error) => {
            throw error
        })
}
export const doGET = (url, params) => {
    const config = {
        params,
        headers: httpAuthHeader()
    };
    return axiosInstance.get(url, config)
        .then(function (response) {
            return response.data
        }).catch((error) => {
            console.log('res')
            throw error
        });
}


export const doAuthDownload = (url, fileName = 'sm_reports') => {
    return new Promise((resolve, reject) => {
        fetch(BASE_URL + url, {
            method: 'GET',
            headers: {
                'Content-Type': 'text/xls',
                ...httpAuthHeader()
            },
        }).then((response) => response.blob()).then((blob) => {
            const objURL = window.URL.createObjectURL(
                new Blob([blob]),
            );
            const getFileFormat = url?.split('.')
            if (getFileFormat) {
                const fileFormat = getFileFormat?.length - 1
                const link = document.createElement('a');
                link.href = objURL;
                link.setAttribute(
                    'download',
                    `${fileName}.${getFileFormat && getFileFormat[fileFormat]}`,
                );
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                resolve()
            }

        }).catch(e => {
            reject(e);
            throw e;
        });
    })
};

export const doGet = (url, params) => {
    const config = {
        params,
        headers: httpAuthHeader(),
    };
    return axiosInstance.get(url, config)
        .then(function (response) {
            return response.data
        }).catch((error) => {
            throw error
        });
}

//SocketRefreshToken






export const getSocketRefreshToken = async () => {
    const refresh_token = store?.getState()?.LOGIN_CREDS?.token?.refresh_token;
    if (refresh_token) {
        let data = new FormData()
        data.append('grant_type', 'refresh_token')
        data.append('refresh_token', refresh_token)
        const config = { headers: publicHeaders() }
        return axiosInstance.post(END_POINTS.CLIENT_CREDENTIALS, data, config)
            .then(function (response) {
                const refreshTokenObj = response?.data
                if (refreshTokenObj && refreshTokenObj.access_token) {
                    refreshTokenObj.expMilliseconds = MomentFunctions.convertExpirySecToMilliSec(refreshTokenObj.expires_in);
                }

                // Set login tokens in cookies
                let expiration_date = new Date();
                expiration_date.setMonth(expiration_date.getMonth() + 1);
                document.cookie = `token=${JSON.stringify(refreshTokenObj)};domain=${Constants.COMMON_APP_DOMAINS.cookieDomain};path=/;expires=${expiration_date.toGMTString()}`
                document.cookie = `access_token=${refreshTokenObj?.access_token};domain=${Constants.COMMON_APP_DOMAINS.cookieDomain};path=/;expires=${expiration_date.toGMTString()}`
                document.cookie = `refresh_token=${refreshTokenObj?.refresh_token};domain=${Constants.COMMON_APP_DOMAINS.cookieDomain};path=/;expires=${expiration_date.toGMTString()}`

                // Set login tokens in store

                store.dispatch(Actions.loginSuccessAction(refreshTokenObj))


                return refreshTokenObj
            }).catch((error) => {
                console.log(error, 'err')
                throw error
            })
    }

}

//CV GET 
export const doCvGet = async (url, params) => {
    const config = {
        params,
        headers: httpAuthHeader(),
    };
    try {
        const response = await axiosInstance.get(url, config);
        return response.data;
    } catch (error) {
        throw error;
    }
};
//CV POST
export const doCvPost = async (url, params) => {
    const config = {
        headers: httpAuthHeader()
    };
    try {
        const response = await axiosInstance.post(url, params, config);
        return response.data;
    } catch (error) {
        throw error;
    }
}