import Actions from "../actions";

const initialState = {
    isLoading: false,
    invoiceList: [],
    invoiceCount: undefined,
    error: false,
}
const InvoiceListReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.INVOICE_LIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.INVOICE_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                invoiceList: action.data?.response || [],
                invoiceCount: action.data?.count,
                error: false
            };
        }
        case Actions.INVOICE_LIST_FAILURE: {
            return {
                ...state,
                invoiceList: [],
                isLoading: false
            };
        }
        case Actions.LOGOUT: {
            return {
                ...initialState
            };
        }
        default:
            return state;
    }
};

export default InvoiceListReducer;

export const getInvoiceListSelector = state => state?.INVOICE_LIST?.invoiceList;
export const getInvoiceListCountSelector = state => state?.INVOICE_LIST?.invoiceCount;
export const getInvoiceListLoading = state => state?.INVOICE_LIST?.isLoading;
