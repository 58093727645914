import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { Router } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { store, persistor, history } from './app/redux/store';
import 'rc-time-picker/assets/index.css';
import RoutePagesConfig from './RoutePagesConfig';
import { ToastContainer } from 'react-toastify';
function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedRouter history={history}>
          <Router history={history}>
            <RoutePagesConfig />
          </Router>
        </ConnectedRouter>
      </PersistGate>
      <ToastContainer />
    </Provider>
  )
}

export default App;
