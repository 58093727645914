import React, { useRef, useState, useEffect } from 'react'
import PolygonAnnotation from './PolygonAnnotation'
import { Stage, Layer, Image } from 'react-konva'
import AppLoader from '../fields/AppLoader'
const CreateCanva = (props) => {
    const [image, setImage] = useState()
    const [offsetSize, setoffsetSize] = useState({})
    const imageRef = useRef(null)
    const [points, setPoints] = useState([])
    const [editPrepopulateData, setEditPrepopulateData] = useState(false)
    const [size, setSize] = useState({})
    const [flattenedPoints, setFlattenedPoints] = useState()
    const [position, setPosition] = useState([0, 0])
    const [isPolyComplete, setPolyComplete] = useState(false)
    const [isloading, setLoading] = useState(false)
    const [nonDragable, setNonDraggable] = useState(false)
    useEffect(() => {
        if (props.prepopulate && !props.fetchNewFrame && !editPrepopulateData) {
            setNonDraggable(props.rackCoordinates ? true : false)
            setPoints(props.rackCoordinates)
        }
        setPolyComplete(props.rackCoordinates.length === 4)

        if (!!props.fetchNewFrame && points.length !== 4) {
            setNonDraggable(false)
            setPoints([])
        }
        const img = new window.Image();
        img.src = props.image
        setImage(img)
        img.onload = (data) => {
            setLoading(false)
            const offsetWidth = document.getElementsByClassName('subModel-body2')[0]?.offsetWidth;
            const percentageWidth = offsetWidth / 100
            const imageHeightPercent = (data.target.height / data.target.width) * 100
            const widthPercentage = (offsetWidth / offsetSize.width)
            const point = points.map((o) => {
                return [o[0] * widthPercentage, o[1] * widthPercentage]
            })
            if (!props.prepopulate) {
                setPoints(point)
            } else {
                if (widthPercentage) {
                    const orginalpoint = props.rackCoordinates.map((o) => {
                        return [o[0] * widthPercentage, o[1] * widthPercentage]
                    })
                    setPoints(orginalpoint)
                    // setPolyComplete(true)
                }
            }
            setoffsetSize({
                width: offsetWidth,
                height: (percentageWidth * imageHeightPercent),
            })
            setSize({
                width: data.target.width,
                height: data.target.height
            })
        }
        if (props.triggerDelete) {
            canvaDelete()
            props.changeDeleteState(false)
        }

    }, [props])

    //get the point x and y co-ordinates
    const getMousePos = (stage) => {
        return [stage.getPointerPosition().x, stage.getPointerPosition().y]
    }

    //drawing begins when mousedown event fires.
    const handleMouseDown = (e) => {
        setEditPrepopulateData(true)
        if (isPolyComplete) return
        const stage = e.target.getStage()
        const mousePos = getMousePos(stage)
        if (points.length === 4) {
            // setPolyComplete(true)
            props.handleSetRackData(points, size)
            // setPolyComplete(true)
        } else {
            setPoints([...points, mousePos])
        }
    }

    //Get mouse position
    const handleMouseMove = (e) => {
        const stage = e.target.getStage()
        const mousePos = getMousePos(stage)
        setPosition(mousePos)
    }

    //get the start position of the group
    const handleMouseOverStartPoint = (e) => {
        if (isPolyComplete || points.length < 3) return
        e.target.scale({ x: 3, y: 3 })
    }

    //get the out position of the group
    const handleMouseOutStartPoint = (e) => {
        e.target.scale({ x: 1, y: 1 })
    }

    //drag the point and get the position from it.
    const handlePointDragMove = (e) => {
        const stage = e.target.getStage()
        const index = e.target.index - 1
        const pos = [e.target._lastPos.x, e.target._lastPos.y]
        if (pos[0] < 0) pos[0] = 0          //to set the position of the point not to negative value
        if (pos[1] < 0) pos[1] = 0
        if (pos[0] > stage.width()) pos[0] = stage.width()  //to set the position of the point not to max then the  image width value
        if (pos[1] > stage.height()) pos[1] = stage.height()
        setPoints([...points.slice(0, index), pos, ...points.slice(index + 1)])
        props.handleSetRackData([...points.slice(0, index), pos, ...points.slice(index + 1)], size)
    }

    //It is line across the two point 
    useEffect(() => {
        setFlattenedPoints(
            points.concat(isPolyComplete ? [] : position).reduce((a, b) => a.concat(b), [])
        )
    }, [points])

    //drag the end point and get the position from it.
    const handleGroupDragEnd = (e) => {
        if (e.target.name() === 'polygon') {
            let result = []
            let copyPoints = [...points]
            copyPoints.map((point) => result.push([point[0] + e.target.x(), point[1] + e.target.y()]))
            e.target.position({ x: 0, y: 0 }) //needs for mouse position otherwise when click undo you will see that mouse click position is not normal:)
            setPoints(result)
            props.handleSetRackData(result, size)
        }
    }

    //delete the polygon points 
    const canvaDelete = () => {
        props.productData([])
        props.handleSetRackData([])
        setPoints([])
    }
    //to show the delete button in the image 
    const handleClick = () => {
        props.deleteButton(true)
    }

    return (
        <>

            {
                isloading && <AppLoader />
            }
            <div className='canvaContainer'>
                <Stage
                    width={offsetSize.width}
                    height={offsetSize.height}
                    onMouseMove={handleMouseMove}
                    onMouseDown={handleMouseDown}
                >
                    <Layer>
                        <Image ref={imageRef} image={image} x={0} y={0} width={offsetSize.width} height={offsetSize.height} />
                        <PolygonAnnotation
                            nonDraggable={nonDragable}
                            points={points}
                            flattenedPoints={flattenedPoints}
                            handlePointDragMove={handlePointDragMove}
                            handleGroupDragEnd={handleGroupDragEnd}
                            handleMouseOverStartPoint={handleMouseOverStartPoint}
                            handleMouseOutStartPoint={handleMouseOutStartPoint}
                            isFinished={isPolyComplete}
                            groupClick={handleClick}
                        />
                    </Layer>
                </Stage>
            </div>
        </>

    )
}

export default CreateCanva
