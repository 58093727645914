import { doAction, doResponseAction } from ".";

const FOOD_PRODUCTS = 'FOOD_PRODUCTS';
const FOOD_PRODUCTS_SUCCESS = 'FOOD_PRODUCTS_SUCCESS';
const FOOD_PRODUCTS_FAILURE = 'FOOD_PRODUCTS_FAILURE';


export {
    FOOD_PRODUCTS,
    FOOD_PRODUCTS_SUCCESS,
    FOOD_PRODUCTS_FAILURE
}


export const getAllFoodProductsListAction = (params, callback) => doAction(FOOD_PRODUCTS, params, callback);
export const getAllFoodProductsListSuccessAction = data => doResponseAction(FOOD_PRODUCTS_SUCCESS, data);
export const getAllFoodProductsListFailureAction = data => doResponseAction(FOOD_PRODUCTS_FAILURE, data);

