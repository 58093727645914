import { doAction, doResponseAction } from './index';

const GET_REFUNDS = 'GET_REFUNDS';
const GET_REFUNDS_SUCCESS = 'GET_REFUNDS_SUCCESS';
const GET_REFUNDS_FAILURE = 'GET_REFUNDS_FAILURE';
const GET_REFUND_DETAILS = 'GET_REFUND_DETAILS'
const GET_REFUND_DETAILS_SUCCESS = 'GET_REFUND_DETAILS_SUCCESS'
const GET_REFUND_DETAILS_FAILURE = 'GET_REFUND_DETAILS_FAILURE'

const UPDATE_REFUND_STATUS = 'UPDATE_REFUND_STATUS'
const UPDATE_SELECTED_PRODUCTS = 'UPDATE_SELECTED_PRODUCTS'

const GET_SOCKET_REFUND_LIST = 'GET_SOCKET_REFUND_LIST'

export {
    GET_REFUNDS,
    GET_REFUNDS_SUCCESS,
    GET_REFUNDS_FAILURE,
    GET_REFUND_DETAILS,
    UPDATE_REFUND_STATUS,
    GET_REFUND_DETAILS_SUCCESS,
    GET_REFUND_DETAILS_FAILURE,
    UPDATE_SELECTED_PRODUCTS,
    GET_SOCKET_REFUND_LIST
}

export const getAllRefundsListsAction = (params, callback) => doAction(GET_REFUNDS, params, callback);
export const getAllRefundsListsSuccessAction = (data) => doResponseAction(GET_REFUNDS_SUCCESS, data);
export const getAllRefundsListsFailureAction = (data) => doResponseAction(GET_REFUNDS_FAILURE, data);

export const getRefundDetailsAction = (params, callback) => doAction(GET_REFUND_DETAILS, params, callback);
export const getRefundDetailsSuccessAction = (data) => doResponseAction(GET_REFUND_DETAILS_SUCCESS, data);
export const getAllRefundDetailsFailureAction = (data) => doResponseAction(GET_REFUND_DETAILS_FAILURE, data);

export const updateRefundStatusAction = (params, callback) => doAction(UPDATE_REFUND_STATUS, params, callback);

export const updatedSelectedProduct = (data) => doResponseAction(UPDATE_SELECTED_PRODUCTS, data);

export const getSocketRefundList = (params, callback) => doAction(GET_SOCKET_REFUND_LIST, params, callback);
