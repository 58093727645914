import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Actions from "../../../redux/actions";
import {
  getDollarOfferListLoadingSelector,
  getDollarOfferListSelector,
  getDollarOfferCountSelector
} from "../../../redux/reducers/DollarOfferReducers";
import DollarOfferModal from "./DollarOfferModal";
import moment from "moment";
import { ManageDollarOfferURL } from "../../../utils/service/Endpoints";
import { UTCDateFormat, UTCEditFormat, UTCViewFormat } from "../../../utils/commonFunctions/dateFormat";
import DollarDetailModal from './DollarDetailModal'
import Pagination from "react-js-pagination";
import RoutePath from "../../../utils/routes/RoutePath";
import AppLoader from "../../../components/fields/AppLoader";
import { toast } from "react-toastify";

const defaultFilter = {
  page: 0,
  itemsPerPage: 10
};

const DollarOffer = () => {
  const dispatch = useDispatch();
  const productList = useSelector(getDollarOfferListSelector);
  const isLoading = useSelector(getDollarOfferListLoadingSelector);
  const [isOpenDollarModal, setOpenDollarModal] = useState(false);
  const [cartGrossValue, setCartGrossValue] = useState("");
  const [validFrom, setValidFrom] = useState("");
  const [expiryOn, setExpiryOn] = useState("");
  const [discount, setDiscount] = useState("");
  const [validationError, setFilterValidationError] = useState("");
  const [OpenEditDollarModal, setOpenEditDollarModal] = useState();
  const [productId, setProductId] = useState("")
  const [offerType, setOfferType] = useState("")
  const [dollarofferDetail, setDollarOfferDetail] = useState("")
  const [dollarOfferModal, setDollarOfferModal] = useState(false)
  const [maxDiscount, setmaximumDiscount] = useState("")
  const totalCount = useSelector(getDollarOfferCountSelector)
  const [activePage, setActivePage] = useState(1)
  const [loading, setLoader] = useState(false)

  const OpenDollarModal = (value) => {
    setOpenDollarModal(true);
  };
  const EditDollarModal = (value, data) => {
    setOpenEditDollarModal(value)
    setOfferType(data?.offerType)
    setProductId(data?.id)
    setmaximumDiscount(data?.maxDiscount)
    setCartGrossValue(data?.cartGrossValue)
    setDiscount(data?.discount)
    setValidFrom(UTCEditFormat(data?.startDateString))
    setExpiryOn(UTCEditFormat(data?.endDateString))
  }

  const openDollarOfferModal = (value) => {
    setDollarOfferModal(true)
    setDollarOfferDetail(value)
  }
  useEffect(() => {
    dispatch(Actions.getDollarOfferListAction(defaultFilter));
  }, [dispatch]);

  const handleChange = (pageNumber) => {
    setActivePage(pageNumber)
    dollarOfferPageChange(pageNumber)
  }
  const dollarOfferPageChange = (offsetValue) => {
    const filterParams = {
      page: offsetValue - 1,
      limit: 10
    }
    dispatch(Actions.getDollarOfferListAction(filterParams));
  }
  const callback = (res) => {
    const { response_code, response_message } = res
    if (response_code === 0) {
      setLoader(false)
      dispatch(Actions.getDollarOfferListAction(defaultFilter));
      setInitialState()
    } else {
      setLoader(false)
      setFilterValidationError(response_message)
    }
  }
  const initialState = () => {
    setOfferType("")
    setOpenDollarModal(false)
    setOpenEditDollarModal(false)
    setCartGrossValue('')
    setValidFrom('')
    setExpiryOn('')
    setDiscount('')
    setmaximumDiscount('')
    setFilterValidationError(null)
  }
  const setInitialState = () => {
    setOfferType("")
    setOpenDollarModal(false)
    setOpenEditDollarModal(false)
    setCartGrossValue('')
    setValidFrom('')
    setExpiryOn('')
    setDiscount('')
    setmaximumDiscount('')
    setFilterValidationError(null)
    setActivePage(1)
  }

  const manageProduct = (create) => {
    if (!offerType) {
      setFilterValidationError('Please enter offer type.');
      return;
    } else if (offerType === 'PERCENT' && maxDiscount === "") {
      setFilterValidationError('Maximum discount should be entered.');
      return;
    } else if (offerType === 'PERCENT' && maxDiscount <= 0) {
      setFilterValidationError('Maximum discount should not be 0and negative numbers');
      return;
    } else if (offerType === 'PERCENT' && maxDiscount > 100) {
      setFilterValidationError('Maximum discount value should be less than 100')
      return;
    }
    else if (!cartGrossValue) {
      setFilterValidationError('Please enter cart gross value.');
      return;

    }

    else if (cartGrossValue <= 0) {
      setFilterValidationError('Cart gross value should not be 0 and negative numbers.');
      return;

    } else if (!discount) {
      setFilterValidationError('Please enter discount.');
      return;
    } else if (discount <= 0) {
      setFilterValidationError('Discount should not be 0 and negative numbers.');
      return;

    } else if (offerType === 'PERCENT' && discount > 100) {
      setFilterValidationError('Discount value should be less than 100')
      return;
    }
    else if (!validFrom) {
      setFilterValidationError('Please choose start date.');
      return;
    } else if (!expiryOn) {
      setFilterValidationError('Please choose end date.');
      return;
    } else if (moment(validFrom).isAfter(moment(expiryOn))) {
      setFilterValidationError('Valid start date cannot be before end date.');
      return;
    }
    else if (moment(validFrom).isSameOrAfter(moment(expiryOn))) {
      setFilterValidationError('Valid start date time cannot be same as end date time.');
      return;
    }
    setLoader(true)
    let startDate = UTCViewFormat(validFrom)
    let endDate = UTCViewFormat(expiryOn)

    let params = {
      offerType,
      active: true,
      cartGrossValue,
      discount,
      validFrom,
      expiryOn,
      startDateTimeString: startDate,
      endDateTimeString: endDate,
    }

    if (offerType === 'PERCENT') {
      params['maxDiscount'] = maxDiscount
    }

    if (create) {
      dispatch(Actions.createDollarOfferAction(params, callback));
    } else {
      params['id'] = productId
      dispatch(Actions.updateDollarOfferAction(params, callback))
    }

  }
  //Delete
  const deleteCallback = (res) => {
    const prames = {
      page: activePage - 1,
      limit: 10
    }
    if (res && res.response_code === 0) {
      dispatch(Actions.getDollarOfferListAction(prames));
      toast.success(res?.response_message, { position: toast.POSITION.TOP_RIGHT })

    } else {
      toast.error(res?.response_message, { position: toast.POSITION.TOP_RIGHT })
    }

  }
  const disableDollarOfferList = (object, value) => {
    const URL = ManageDollarOfferURL(object?.id, value)
    dispatch(Actions.deleteDollarOfferAction(URL, deleteCallback));
  }


  let dollarOfferList = []

  if (productList && productList.length > 0) {
    dollarOfferList = productList
  } else {
    dollarOfferList = []
  }

  const data = React.useMemo(() => dollarOfferList, [dollarOfferList]);

  return (
    <div className="col ps-lg-4">
      {isLoading && <AppLoader />}
      <div className="row mb-1 align-items-center">
        <div className="col">
          <h1 className="font20 fw-bold mb-2">Dollar Offers <small className="fw-normal"></small></h1>
          <nav style={{ marginLeft: "--bs-breadcrumb-divider: '/';" }} aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href={RoutePath.DASHBOARD_SCREEN_PATH}>Home</a></li>
              <li className="breadcrumb-item"><a href={RoutePath.PROMOTION_DASHBOARD}>Promotions</a></li>
              <li className="breadcrumb-item active" aria-current="page">Dollar Offer</li>
            </ol>
          </nav>
        </div>
        <div className="col text-end"> <button name="" data-bs-toggle="modal" id="OpenDollarModal" data-bs-target="#add-dollar-modal" className="btn btn-dark" onClick={() => OpenDollarModal(true)}>Add New</button> </div>
      </div>


      <div className="row mb-4">
        <div className="col">


          <div id="all-offers" className="card card-body rounded-3 border-0 shadow p-4 mb-3 mb-xl-0">


            <div className="table-responsive mb-4">
              <table className="table table-hover table-striped table-borderless">
                <thead>
                  <tr>
                    <th>OfferId</th>
                    <th>Cart Value</th>
                    <th>Discount </th>
                    <th>Valid From</th>
                    <th>Valid To</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {!isLoading && data && data.map((o) => {
                    return <tr>
                      <td> {o?.id} </td>
                      <td> ${parseFloat(o?.cartGrossValue).toFixed(2)} </td>
                      <td> {o?.offerType === "PERCENT" ? `${o?.discount}%` : `$` + parseFloat(o?.discount)?.toFixed(2)} </td>
                      <td>{UTCDateFormat(o?.startDateString)} </td>
                      <td>{UTCDateFormat(o?.endDateString)}</td>
                      {!o.status ? <td>
                        <div className="form-check form-switch">
                          <input
                            onChange={(e) => {
                              e.stopPropagation();
                              disableDollarOfferList(o, e.target.checked)
                            }}
                            className="form-check-input" type="checkbox" role="switch" id="switch" checked={o?.active} />
                        </div></td> : <td className={o.status === 'Expired' ? "text-danger" : 'text-success'}>{o?.status}</td>
                      }
                      <td className="d-flex">
                        <div className="col-auto">
                          <button type="button"
                            data-bs-toggle="modal"
                            id="openDollarOfferModal"
                            data-bs-target="#view-dollar-modal"
                            className="btn btn-sm btn-outline-dark mb-3 mb-md-0"
                            onClick={() => openDollarOfferModal(o)}>View
                          </button>
                        </div>
                        <div className="col-auto">
                          <button type="button"
                            data-bs-toggle="modal"
                            id='EditDollarModal'
                            data-bs-target="#edit-dollar-modal"
                            className="btn btn-sm btn-outline-dark"
                            onClick={(e) => { e.stopPropagation(); EditDollarModal(true, o) }}>Edit
                          </button>
                        </div>
                      </td>
                    </tr>
                  })}
                </tbody>
              </table>
            </div>
            <div className="text-center">
              {
                (data && data.length > 0 && totalCount > 1) && <Pagination
                  id="pagination"
                  activePage={activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={totalCount * 10}
                  pageRangeDisplayed={5}
                  onChange={(e) => handleChange(e)}
                />
              }
            </div>

          </div>


        </div>


      </div>
      {isOpenDollarModal && <DollarOfferModal id='DollarOfferModal'
        manageProduct={(value) => manageProduct(value)}
        isCreate={true}
        isOpenDollarModal={isOpenDollarModal}
        cartGrossValue={cartGrossValue}
        validFrom={validFrom}
        expiryOn={expiryOn}
        discount={discount}
        setDiscount={setDiscount}
        setCartGrossValue={setCartGrossValue}
        setValidFrom={setValidFrom}
        setExpiryOn={setExpiryOn}
        setOpenDollarModal={() => initialState()}
        validationError={validationError}
        offerType={offerType}
        setOfferType={setOfferType}
        maximumDiscount={maxDiscount}
        setMaximumDiscount={setmaximumDiscount}
        setFilterValidationError={setFilterValidationError}
        isLoading={loading}
      />
      }
      {OpenEditDollarModal && <DollarOfferModal id='OpenEditDollarModal' isOpenDollarModal={OpenEditDollarModal}
        manageProduct={(value) => manageProduct(value)}
        cartGrossValue={cartGrossValue}
        validFrom={validFrom}
        expiryOn={expiryOn}
        discount={discount}
        setDiscount={setDiscount}
        setCartGrossValue={setCartGrossValue}
        setValidFrom={setValidFrom}
        setExpiryOn={setExpiryOn}
        setOpenDollarModal={() => initialState()}
        validationError={validationError}
        offerType={offerType}
        setOfferType={setOfferType}
        maximumDiscount={maxDiscount}
        setMaximumDiscount={setmaximumDiscount}
        setFilterValidationError={setFilterValidationError}
        isLoading={loading}
      />
      }
      {dollarOfferModal && <DollarDetailModal id='DollarDetailModal'
        isDollarDetailModal={dollarofferDetail}
        isCloseModal={() => setDollarOfferModal(false)}
        dollarOffeDetails={dollarofferDetail} />}
    </div>


  );
};

export default DollarOffer;