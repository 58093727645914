import React from "react";
import { withRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import ModalPopup, { ModalHeader } from "../components/modal";
import InputField from "./fields/InputField";
import RackInputField from './fields/RackInputField'
import { Label } from "./fields/AppComponents";
import CONSTANTS from "../utils/Constants";
import AppLoader from "./fields/AppLoader";
import { getPlanogramStoreSelector } from "../redux/reducers/PlanogramReducer";

class CreateRackModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {
                xAxis: 0,
                yAxis: 0,
                rackName: '',
                width: '',
                height: '',
                depth: '',
                rackPosition: 3,
                rackType: 1,
                shelves: []
            },
            error: {
                rackName: '',
                width: '',
                height: '',
                depth: '',
                shelve: '',
                shelves: []
            },
        };
    }

    componentDidMount() {
        const { isEdit, rack } = this.props;
        if (isEdit) {
            this.setState({
                fields: {
                    ...rack
                },

            })
        }
    }

    calculateMaxwidth = () => {
        const { fields } = this.state;
        let productWidth = []
        fields?.shelves?.forEach((details) => {
            let count = 0
            details?.products?.forEach((product) => {
                count += product?.productDetails?.widthInCm
            })
            productWidth.push(count)
        })
        return Math.ceil(Math.max(...productWidth))
    }

    calculateMaxDept = () => {
        const { fields } = this.state;
        let productHeight = []
        fields?.shelves?.forEach((details) => {
            let count = 0
            details?.products?.forEach((product) => {
                count += product?.productDetails?.depthInCm
            })
            productHeight.push(count)
        })
        return Math.ceil(Math.max(...productHeight))
    }
    handleChange = (value, name, maxWidth, maxLength, maxHeight) => {
        const { fields } = this.state;
        if (value < 0 && (name === "width" || name === "height" || name === "depth")) {
            this.setState({
                fields: {
                    ...fields,
                    [name]: 0
                }
            }, () => this.validateForm([name]))
        }
        else {
            let minWidth
            let minDepth
            if (name === "width") {
                minWidth = this.calculateMaxwidth()
            }
            else if (name === "depth") {
                minDepth = this.calculateMaxDept()
            }
            this.setState({
                fields: {
                    ...fields,
                    [name]: value
                }
            }, () => this.validateForm([name], minWidth, minDepth, value, maxWidth, maxLength, maxHeight))
        }


    }

    handleChangeShelves = (value, name, i, o) => {
        const { fields, fields: { shelves } } = this.state;
        let maxheight
        shelves[i][name] = value
        let productHeight = []
        if (o?.bays?.length !== 0) {
            o?.bays?.forEach((details) => {
                productHeight.push(details?.productDetails?.heightInCm)
            })
            maxheight = Math.ceil(Math.max(...productHeight))
        }
        else {
            o?.products?.forEach((details) => {
                productHeight.push(details?.productDetails?.heightInCm)
            })
            maxheight = Math.ceil(Math.max(...productHeight))
        }
        if (value < 0) {
            shelves[i][name] = 0
        }
        this.setState({
            fields: {
                ...fields,
                shelves,
            }
        }, () => this.validateSingleShelves(name, i, maxheight, value))
    }

    addShelves = () => {
        const { fields, fields: { shelves }, error = {} } = this.state;
        shelves.push({
            shelveName: '',
            shelveHeight: ''
        })
        error.shelve = ''
        this.setState({
            fields: {
                ...fields,
                shelves,
            }
        })
    }

    validateForm = (field, minWidth, minDepth, value, maxWidth, maxLength, maxHeight) => {
        let { error, fields } = this.state;
        let isFailed = false;
        field.forEach((a) => {
            switch (a) {
                case 'rackName':
                    if (!fields[a]) {
                        error[a] = 'Rack Name is Mandatory';
                        isFailed = true
                    } else
                        error[a] = '';
                    break;
                case 'height':
                    if (!fields[a]) {
                        isFailed = true
                        error[a] = 'Height is Mandatory';
                    }
                    else if (Math.ceil(parseInt(value)) === 0) {
                        error[a] = 'Rack height should not be zero';
                        isFailed = true
                    }
                    else if (value > maxHeight) {
                        error[a] = 'Rack height should not be greater then room height';
                        isFailed = true
                    }
                    else
                        error[a] = '';
                    break;
                case 'depth':
                    if (!fields[a]) {
                        isFailed = true
                        error[a] = 'Depth is Mandatory';
                    }
                    else if (value < minDepth) {
                        error[a] = 'Rack is less then the product depth';
                        isFailed = true
                    }
                    else if (value > maxLength && value > maxWidth) {
                        error[a] = `Rack can't be step out more then the room width and length`;
                        isFailed = true
                    }
                    else if (Math.ceil(parseInt(value)) === 0) {
                        error[a] = 'Rack depth should not be zero';
                        isFailed = true
                    }
                    else {
                        error[a] = '';
                    }

                    break;
                case 'width':
                    if (!fields[a]) {
                        isFailed = true
                        error[a] = 'Width is Mandatory';
                    }
                    else if (value < minWidth) {
                        error[a] = 'Rack is less then the product width';
                        isFailed = true
                    }
                    else if (value > maxLength && value > maxWidth) {
                        error[a] = `Rack can't be step out more then the room width and length`;
                        isFailed = true
                    }
                    else if (Math.ceil(parseInt(value)) === 0) {
                        error[a] = 'Rack width should not be zero';
                        isFailed = true
                    }
                    else {
                        error[a] = '';
                    }

                    break;
                case 'shelves':
                    if (fields[a].length === 0) {
                        isFailed = true
                        error['shelve'] = 'Please add shelves to proceed';
                    } else
                        error['shelve'] = '';
                    break;
                case 'rackType':
                    if (fields[a].length === 0) {
                        isFailed = true
                        error['rackType'] = 'Please select rack type to proceed';
                    } else
                        error['rackType'] = '';
                    break;
                default:
                    break;
            }
        })

        this.setState({ error })
        return isFailed
    }

    validateSingleShelves = (name, i, maxheight, value) => {
        let { error, fields: { shelves = [] } } = this.state;
        let isFailed = false;
        switch (name) {
            case 'shelveName':
                if (!shelves[i][name]) {
                    if (error.shelves[i]) {
                        error.shelves[i][name] = 'Shelve Name is Mandatory';
                    }
                    else
                        error.shelves[i] = { shelveName: 'Shelve Name is Mandatory' };
                    isFailed = true
                } else {
                    if (error.shelves[i])
                        error.shelves[i][name] = '';
                    else
                        error.shelves[i] = { shelveName: '' };
                }
                break;
            case 'shelveHeight':

                if (!shelves[i][name]) {
                    if (error.shelves[i])
                        error.shelves[i][name] = 'Shelve Height is Mandatory';

                    else
                        error.shelves[i] = { shelveHeight: 'Shelve Height is Mandatory' };
                    isFailed = true
                }
                else if (value < maxheight) {
                    error.shelves[i] = { shelveHeight: 'Shelve is less then the product height' };
                    isFailed = true
                }
                else if (Math.ceil(parseInt(value)) === 0) {
                    error.shelves[i] = { shelveHeight: 'Shelve height should not be zero' };
                    isFailed = true
                }
                else {
                    if (error.shelves[i]) {
                        error.shelves[i][name] = '';
                    }
                    else {
                        error.shelves[i] = { shelveHeight: '' };
                    }
                }
                break;

        }

        this.setState({ error })
        return isFailed
    }


    validateWholeShelves = () => {
        let { error, fields: { shelves = [] }, fields } = this.state;
        let isFailed = false;
        shelves.forEach((a, i) => {
            if (!a['shelveName']) {
                if (error.shelves[i])
                    error.shelves[i]['shelveName'] = 'Shelve Name is Mandatory';
                else
                    error.shelves[i] = { shelveName: 'Shelve Name is Mandatory' };
                isFailed = true
            }
            else {
                if (error.shelves[i])
                    error.shelves[i]['shelveName'] = '';
                else
                    error.shelves[i] = { shelveName: '' };
            }
            if (!a['shelveHeight']) {
                if (error.shelves[i])
                    error.shelves[i]['shelveHeight'] = 'Shelve Height is Mandatory';
                else
                    error.shelves[i] = { shelveHeight: 'Shelve Height is Mandatory' };
                isFailed = true
            }
            else {
                if (error.shelves[i])
                    error.shelves[i]['shelveHeight'] = '';
                else
                    error.shelves[i] = { shelveHeight: '' };
            }
        })
        const heightValue = shelves.reduce((pVal, cVal) => {
            return pVal + parseInt(cVal.shelveHeight)
        }, 0);
        if (heightValue > fields.height) {
            error['shelve'] = `Consolidated shelves height should be below ${fields.height}${CONSTANTS.PLANOGRAM.UNIT}`;
            isFailed = true
        }

        this.setState({ error })
        return isFailed
    }

    removeShelve = (i) => {
        const { fields, fields: { shelves } } = this.state;
        shelves.splice(i, 1)
        this.setState({
            fields: {
                ...fields,
                shelves,
            }
        })
    }

    getShelveCount = () => {
        const { fields: { shelves = [] } } = this.state;
        return shelves?.length > 0 ? `(${shelves?.length})` : ''
    }

    onSubmitForm = () => {
        const { result, onAddRack } = this.props;
        const { fields } = this.state;
        if (this.validateForm(['rackName', 'height', 'width', 'depth', 'rackType', 'shelves'])) {
            return false;
        }
        if (this.validateWholeShelves()) {
            return false;
        }
        if (result.filter((d) => (d?.rackName === fields?.rackName && d?.id !== fields?.id)).length > 0) {
            const { error } = this.state
            error['rackName'] = 'Rack Name already exists'
            this.setState({ error })
            return false;
        }
        onAddRack(fields)
    }

    getRemainingShelfHeight = () => {
        const { fields: { shelves = [], height } } = this.state;
        const heightValue = shelves.reduce((pVal, cVal) => {
            return pVal + (parseInt(cVal.shelveHeight) ? parseInt(cVal.shelveHeight) : 0)
        }, 0);
        return parseInt(height) - heightValue;
    }

    render() {
        const { isModalOpen, onCloseModal, isLoading, title, store } = this.props;
        const { errorMessage, fields, error } = this.state;
        const remainingHeight = this.getRemainingShelfHeight();
        const ifErrorFound = error?.shelves.some((element) => {
            if (element.shelveHeight !== "") {
                return true
            }
            return false
        })
        let roomWidth = Math.ceil(store?.storeLength * 100);
        let roomLength = Math.ceil(store?.storeWidth * 100);
        let roomHeigth = Math.ceil(store?.storeHeight * 100);
        return (
            <React.Fragment>
                {isModalOpen && <ModalPopup className="modal-lg" onCloseModal={onCloseModal}>
                    <ModalHeader title={title} />
                    {errorMessage && (
                        <div className="alert alert-danger" role="alert">
                            {errorMessage}
                        </div>
                    )}
                    <div className="row mb-4">
                        <div className="col-sm-6">
                            <div style={{ display: 'flex', marginTop: '10px', flexDirection: 'column' }}>
                                <Label style={{ minWidth: '100px' }}>Rack Name</Label>
                                <InputField
                                    id="rackName"
                                    style={{ marginTop: '10px' }}
                                    placeholder="Enter Rack Name"
                                    value={fields['rackName']}
                                    name="rackName"
                                    onChange={(value, name) => this.handleChange(value, name)}
                                    error={error['rackName']}
                                    maxLength={6}
                                />
                            </div>
                            <div style={{ display: 'flex', marginTop: '10px', flexDirection: 'column' }}>
                                <Label style={{ minWidth: '100px' }}>Width (in {CONSTANTS.PLANOGRAM.UNIT})</Label>
                                <RackInputField
                                    id="width"
                                    type={'number'}
                                    style={{ marginTop: '10px' }}
                                    placeholder="Enter Width"
                                    value={fields['width']}
                                    name="width"
                                    onChange={(value, name) => this.handleChange(value, name, roomWidth, roomLength)}
                                    error={error['width']}
                                />
                            </div>
                            <div style={{ display: 'flex', marginTop: '10px', flexDirection: 'column' }}>
                                <Label style={{ minWidth: '100px' }}>Height (in {CONSTANTS.PLANOGRAM.UNIT})</Label>
                                <RackInputField
                                    id="height"
                                    type={'number'}
                                    style={{ marginTop: '10px' }}
                                    placeholder="Enter Height"
                                    value={fields['height']}
                                    name="height"
                                    onChange={(value, name) => this.handleChange(value, name, roomWidth, roomLength, roomHeigth)}
                                    error={error['height']}
                                />
                            </div>
                            <div style={{ display: 'flex', marginTop: '10px', flexDirection: 'column' }}>
                                <Label style={{ minWidth: '100px' }}>Depth (in {CONSTANTS.PLANOGRAM.UNIT})</Label>
                                <RackInputField
                                    id="depth"
                                    type={'number'}
                                    style={{ marginTop: '10px' }}
                                    placeholder="Enter depth"
                                    value={fields['depth']}
                                    name="depth"
                                    onChange={(value, name) => this.handleChange(value, name, roomWidth, roomLength)}
                                    error={error['depth']}
                                />
                            </div>
                            <div style={{ display: 'flex', marginTop: '10px', flexDirection: 'column' }}>
                                <Label style={{ minWidth: '100px' }}>Rack Type</Label>
                                <select className="form-control" onChange={(e) => this.handleChange(parseInt(e.target.value), 'rackType')} value={fields['rackType']} disabled={title === "Update Rack" ? true : false}>
                                    <option value={1}>Rack</option>
                                    <option value={2}>Cooler</option>
                                    <option value={3}>Fruit & Vegetable</option>
                                </select>
                                {error['rackType'] && <p style={{ color: 'red' }}>{error['rackType']}</p>}
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="row mt-3">
                                <div className="col shelve-padding"> <h5 className="font20 fw-bold">Shelves {this.getShelveCount()}</h5> </div>
                                <div className="col text-end">
                                    <button
                                        id="addcombooffer"
                                        name=""
                                        data-bs-toggle="modal"
                                        data-bs-target="#add-dollar-modal"
                                        className="btn btn-outline-dark btn-sm"
                                        disabled={(fields['height'] === "" || remainingHeight === 0) || (fields['width'] === "" || Math.ceil(parseInt(fields['width'])) === 0) || (fields['depth'] === "" || Math.ceil(parseInt(fields['depth'])) === 0)}
                                        onClick={() => this.addShelves()}>+ Add
                                    </button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 shelve-padding"> <p className="fw-bold">Overall Bay Height: {fields['height'] ? `${fields['height']}${CONSTANTS.PLANOGRAM.UNIT}` : `-`}</p> </div>
                                <div className="col-12 shelve-padding"> <p className={`fw-bold ${remainingHeight < 0 ? 'text-danger' : ''}`}>Remaining Bay Height: {remainingHeight ? `${remainingHeight}${CONSTANTS.PLANOGRAM.UNIT}` : `0cm`}</p> </div>
                            </div>
                            {error.shelve && <p style={{ color: 'red', textAlign: 'center', width: '100%' }}>{error.shelve}</p>}
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Shelves Name</th>
                                        <th>Height(in {CONSTANTS.PLANOGRAM.UNIT})</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {fields?.shelves?.map((o, i) => {
                                        return (<tr className="text-center" key={i}>
                                            <td className="text-center">
                                                <InputField
                                                    id={error?.shelves[i]?.shelveHeight ? "shelveName1" : "shelveName"}
                                                    style={{ marginTop: '10px' }}
                                                    placeholder="Shelve Name"
                                                    value={o['shelveName']}
                                                    name="shelveName"
                                                    onChange={(value, name) => this.handleChangeShelves(value, name, i)}
                                                    error={error?.shelves[i]?.shelveName}
                                                /></td>
                                            <td><RackInputField
                                                id={error?.shelves[i]?.shelveName ? "shelveHeight1" : "shelveHeight"}
                                                type={'number'}
                                                style={{ marginTop: '10px' }}
                                                placeholder="Shelve Height"
                                                value={o['shelveHeight']}
                                                name="shelveHeight"
                                                onChange={(value, name) => this.handleChangeShelves(value, name, i, o)}
                                                error={error?.shelves[i]?.shelveHeight}
                                            /></td>
                                            <td><div id="removeShelve" className="c-pointer" onClick={() => this.removeShelve(i)}><span className="fas fa-trash" ></span></div></td>
                                        </tr>)
                                    })}
                                    {fields?.shelves?.length === 0 && <tr><td className="text-center" colSpan={3}>No Shelves added yet</td></tr>}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col">
                            <button
                                id="onCloseModal"
                                type="button"
                                className="btn btn-outline-dark big-btn w-100 px-5 text-uppercase"
                                onClick={() => onCloseModal()}
                            // disabled={buttonDisable}
                            >
                                Cancel
                            </button>
                        </div>
                        <div className="col">
                            <button
                                id="onSubmitForm"
                                type="button"
                                className="btn btn-dark big-btn w-100 px-5 text-uppercase"
                                onClick={() => this.onSubmitForm()}
                                disabled={(error.depth !== "") || (error.height !== "") || (error.width !== "") || (error.rackName !== "") || ifErrorFound}
                            >
                                Proceed
                            </button>
                        </div>
                    </div>
                    {isLoading && <AppLoader />}
                </ModalPopup>
                }
            </React.Fragment>
        );
    }
}

export const mapStateToProps = (state) => {
    return {
        store: getPlanogramStoreSelector(state)
    };
};

export default withRouter(connect(mapStateToProps, {})(CreateRackModal));