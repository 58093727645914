import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Actions from "../../../redux/actions";
import {
  getComboOfferListLoadingSelector,
  getComboOfferListSelector,
  getComboOfferCountSelector
} from "../../../redux/reducers/ComboOfferReducer";
import RoutePath from "../../../utils/routes/RoutePath";
import AppLoader from "../../../components/fields/AppLoader";
import ComboOfferModal from "./ComboOfferModal";
import { ManageComboOfferURL } from "../../../utils/service/Endpoints";
import { cloneDeep } from 'lodash';
import { UTCDateFormat, UTCEditFormat, UTCViewFormat } from "../../../utils/commonFunctions/dateFormat";
import Pagination from "react-js-pagination";
import ComboOfferDetailModal from "./ComboOfferDetailModal";
import { toast } from "react-toastify";

const defaultFilter = {
  storeId: 1,
  page: 0,
  itemsPerPage: 10,
};

const ComboOffer = () => {
  const dispatch = useDispatch();
  const comboOfferList = useSelector(getComboOfferListSelector);
  const isLoading = useSelector(getComboOfferListLoadingSelector);
  const [isOpenEditComboOfferModal, setOpenEditComboOfferModal] = useState(null);
  const [activePage, setActivePage] = useState(1)
  const [isOpenComboOfferDetailModal, setOpenComboOfferDetailModal] = useState(false)
  const totalCount = useSelector(getComboOfferCountSelector)
  const fetchComboList = useCallback(() => {
    dispatch(Actions.getComboOfferList(defaultFilter));
  }, [dispatch]);

  const handleChange = (pageNumber) => {
    setActivePage(pageNumber)
    comboOfferPageChange(pageNumber)
  }

  const comboOfferPageChange = (offsetValue) => {
    const defaultFilter = {
      page: offsetValue - 1,
      limit: 10
    }
    dispatch(Actions.getComboOfferList(defaultFilter));
  }

  useEffect(() => {
    fetchComboList()
  }, [fetchComboList]);

  const deleteCallback = (res) => {
    const params = {
      page: activePage - 1,
      limit: 10
    }
    if (res && res.response_code === 0) {
      dispatch(Actions.getComboOfferList(params));
      toast.success(res?.response_message, { position: toast.POSITION.TOP_RIGHT })
    } else {
      toast.error(res?.response_message, { position: toast.POSITION.TOP_RIGHT })
    }
  }
  const onDisableComboOfferAction = (object, value) => {
    let URL = ManageComboOfferURL(object?.id, value)
    dispatch(Actions.deleteComboOfferAction(URL, deleteCallback))
  }

  let comboOfferLists = []

  if (comboOfferList && comboOfferList?.length > 0) {
    comboOfferLists = comboOfferList
  } else {
    comboOfferLists = []
  }

  const data = React.useMemo(() => comboOfferLists, [comboOfferLists]);

  const onEdit = (rowData) => {
    const data = cloneDeep({ ...rowData });
    data.editMode = true
    data.validFrom = UTCEditFormat(data?.startDateString);
    data.expiryOn = UTCEditFormat(data?.endDateString);
    data.offer.validFrom = UTCEditFormat(data?.offer?.validFrom);
    data.productSkus = data.comboItems;
    delete data.comboItems;
    dispatch(Actions.getComboOfferByIdAction({ id: rowData.id }))
    setOpenEditComboOfferModal(data);
  }

  const viewComboOfferDetails =
    (rowData) => {
      const data = cloneDeep({ ...rowData });
      data.viewMode = true
      data.validFrom = data?.startDateString;
      data.expiryOn = data?.endDateString;
      data.offer.validFrom = UTCViewFormat(data.offer.validFrom);
      data.productSkus = data.productDetails;
      delete data.productDetails;
      dispatch(Actions.getComboOfferByIdAction({ id: rowData.id }))
      setOpenComboOfferDetailModal(data);
    }

  return (
    <div className="col ps-lg-4">
      {isLoading && <AppLoader />}
      <div className="row mb-1 align-items-center">
        <div className="col">
          <h1 className="font20 fw-bold mb-2">Combo Offers <small className="fw-normal"></small></h1>
          <nav style={{ marginLeft: "--bs-breadcrumb-divider: '/';" }} aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href={RoutePath.DASHBOARD_SCREEN_PATH}>Home</a></li>
              <li className="breadcrumb-item"><a href={RoutePath.PROMOTION_DASHBOARD}>Promotions</a></li>
              <li className="breadcrumb-item active" aria-current="page">Combo Offers</li>
            </ol>
          </nav>
        </div>
        <div className="col text-end">
          <button
            id="addcombooffer"
            name=""
            data-bs-toggle="modal"
            data-bs-target="#add-dollar-modal"
            className="btn btn-dark"
            onClick={() => setOpenEditComboOfferModal({})}>Add New
          </button>
        </div>
      </div>


      <div className="row mb-4">
        <div className="col">
          <div id="all-offers" className="card card-body rounded-3 border-0 shadow p-4 mb-3 mb-xl-0">
            <div className="table-responsive mb-4">
              <table className="table table-hover table-striped table-borderless">
                <thead>
                  <tr>
                    <th>Offer ID</th>
                    <th>Offer Price</th>
                    {/* <th>Tax Percentage </th> */}
                    <th>Valid From</th>
                    <th>Valid To</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {!isLoading && data && data.map((o) => {
                    return <tr>
                      <td> {o?.offer?.id} </td>
                      <td> {o?.offerType === "PERCENT" ? `${o?.offerPrice}%` : `$ ${parseFloat(o?.offerPrice)?.toFixed(2)}`} </td>
                      {/* <td> {o?.taxPercentage}% </td> */}
                      <td>{UTCDateFormat(o?.startDateString)} </td>
                      <td>{UTCDateFormat(o?.endDateString)}</td>
                      {!o.status ? <td>
                        <div className="form-check form-switch">
                          <input
                            id="comboofferstatus"
                            onChange={(e) => {
                              e.stopPropagation();
                              onDisableComboOfferAction(o, e.target.checked)
                            }}
                            className="form-check-input cursor-pointer"
                            type="checkbox" role="switch"
                            checked={o?.active} />
                        </div></td> : <td className={o.status === 'Expired' ? "text-danger" : 'text-success'}>{o?.status}</td>
                      }
                      <td className="d-flex">
                        <div className="col-auto">
                          <button type="button"
                            data-bs-toggle="modal"
                            id="editcombooffer"
                            data-bs-target="#view-dollar-modal"
                            className="btn btn-sm btn-outline-dark mb-3 mb-md-0"
                            onClick={() => viewComboOfferDetails(o)}
                          >
                            View
                          </button>
                        </div>
                        <div className="col-auto">
                          <button type="button"
                            data-bs-toggle="modal"
                            id="editcombo"
                            data-bs-target="#edit-dollar-modal"
                            className="btn btn-sm btn-outline-dark"
                            onClick={(e) => { e.stopPropagation(); onEdit(o) }}
                          >
                            Edit
                          </button>
                        </div>
                      </td>
                    </tr>
                  })}
                </tbody>
              </table>
            </div>
            <div className="text-center">
              {
                (data && data.length > 0 && totalCount > 1) && <Pagination
                  id="pagination"
                  activePage={activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={totalCount * 10}
                  pageRangeDisplayed={5}
                  onChange={(e) => handleChange(e)}
                />
              }
            </div>

          </div>


        </div>
      </div>

      {isOpenComboOfferDetailModal && (
        <ComboOfferDetailModal
          id="ComboOfferDetail"
          modalData={isOpenComboOfferDetailModal}
          isCloseModal={() => {
            setOpenComboOfferDetailModal(false);
          }}
        />
      )}

      {isOpenEditComboOfferModal && (
        <ComboOfferModal
          id="ComboOfferModal"
          modalData={isOpenEditComboOfferModal}
          onClose={(isrefresh) => {
            setOpenEditComboOfferModal(null);
            setActivePage(1)
            fetchComboList();
            if (isrefresh) {
              fetchComboList();
            }
          }}
        />
      )}

    </div>
  );
};

export default ComboOffer;

