import { doAction, doResponseAction } from ".";

const BAY_SAVE_PRODUCTLIST = 'BAY_SAVE_PRODUCTLIST';
const BAY_SAVE_PRODUCTLIST_SUCCESS = 'BAY_SAVE_PRODUCTLIST_SUCCESS';
const BAY_SAVE_PRODUCTLIST_FAILURE = 'BAY_SAVE_PRODUCTLIST_FAILURE'

export {
    BAY_SAVE_PRODUCTLIST,
    BAY_SAVE_PRODUCTLIST_SUCCESS,
    BAY_SAVE_PRODUCTLIST_FAILURE,
}

export const getbayList = (params, callback) => doAction(BAY_SAVE_PRODUCTLIST, params, callback);
export const getbayListSuccess = (data) => doResponseAction(BAY_SAVE_PRODUCTLIST_SUCCESS, data);
export const getbayListFailure = (data) => doResponseAction(BAY_SAVE_PRODUCTLIST_FAILURE, data);
