import { doAction, doResponseAction } from ".";

const GET_DASHBOARD_DETAILS = 'GET_DASHBOARD_DETAILS';
const GET_DASHBOARD_DETAILS_SUCCESS = 'GET_DASHBOARD_DETAILS_SUCCESS';
const GET_DASHBOARD_DETAILS_FAILURE = 'GET_DASHBOARD_DETAILS_FAILURE'

const GET_DASHBOARD_CONTENT = 'GET_DASHBOARD_CONTENT'

export {
    GET_DASHBOARD_DETAILS,
    GET_DASHBOARD_DETAILS_SUCCESS,
    GET_DASHBOARD_DETAILS_FAILURE,
    GET_DASHBOARD_CONTENT
}

export const getDashboardDetailsAction = (params, callback) => doAction(GET_DASHBOARD_DETAILS, params, callback);
export const getDashboardDetailsActionSuccess = (data) => doResponseAction(GET_DASHBOARD_DETAILS_SUCCESS, data);
export const getDashboardDetailsActionFailure = (data) => doResponseAction(GET_DASHBOARD_DETAILS_FAILURE, data);


export const getDashboardContent = (data) => ({
    type: GET_DASHBOARD_CONTENT,
    data,
});
