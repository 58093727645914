import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllRefundsListsAction } from '../../redux/actions/RefundActions';
import {
    getRefundsListLoadingSelector,
    getRefundsListSelector,
    getRefundsCountSelector
} from '../../redux/reducers/RefundReducer';
import Constants from '../../utils/Constants';
import { useLocation } from 'react-router';
import { getQueryParams } from '../../utils/commonFunctions/EncodeDecodeBase64';
import moment from 'moment'
import Pagination from "react-js-pagination";
import Search from '../../../asset/new/img/search.svg'
import Greentick from '../../../asset/new/img/green-tick.svg'
import Pending from '../../../asset/new/img/pending.svg'
import Declined from '../../../asset/new/img/red-cross.svg'
import AppLoader from '../../components/fields/AppLoader';
import RefundDetails from './RefundDetails';
import EmptyPage from '../../components/emptyPage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import DateFilter from '../../components/DateFilter';
import { ShowDateMoment } from '../../utils/commonComponents/showDate';
import { history } from '../../redux/store';
import RoutePath from '../../utils/routes/RoutePath';

const REFUND_FILTERS = [
    {
        id: Constants.REFUND_STATUS.PENDING,
        name: 'Requested'
    },
    {
        id: Constants.REFUND_STATUS.APPROVED,
        name: 'Accepted'
    },
    {
        id: Constants.REFUND_STATUS.REJECT,
        name: 'Declined'
    },
    {
        id: Constants.REFUND_STATUS.ALL,
        name: 'All'
    }
]
const DATE_FORMAT = 'YYYY-MM-DD';

const DEFAULT_FILTERS = {
    status: Constants.REFUND_STATUS.PENDING, offset: 0, limit: 5,
}
const RefundScreen = () => {
    const queryParams = getQueryParams(useLocation().search);
    const refundIdQ = queryParams.get('refundId');
    const searchId = queryParams.get('searchId');
    const statusQ = queryParams.get('action');
    const intStatus = typeof statusQ !== 'number' ? Constants.REFUND_STATUS.PENDING : parseInt(statusQ, 10)
    const [apiFilters, setApiFilters] = useState({
        ...DEFAULT_FILTERS, status: intStatus,
        startDate: '', endDate: ''
    });

    const [dateFilters, setDateFilters] = useState({
        startDate: '', endDate: ''
    });

    const totalCount = useSelector(getRefundsCountSelector)
    const dispatch = useDispatch();
    const refundList = useSelector(getRefundsListSelector);
    const isLoading = useSelector(getRefundsListLoadingSelector);
    const [activePage, setActivePage] = useState(1)
    const [refundFilter, setRefundFilter] = useState('')
    const [isOpenRefundModal, setOpenRefundModal] = useState(false)
    const [refundData, setRefundData] = useState('')
    const [openDateFilterModal, setDateFilterModal] = useState(false)
    const [filterValidationError, setFilterValidationError] = useState(null);

    const fetchRefundList = useCallback((id) => {
        const queryParams = { ...apiFilters };
        if (id) {
            queryParams.refundDetailsId = id;
        }
        dispatch(getAllRefundsListsAction(queryParams));
    }, [dispatch, apiFilters]);
    console.log(refundFilter);
    const onChangeSearchInput = (value) => {
        const filterParams = { ...apiFilters };
        filterParams.refundDetailsId = value;
        filterParams.offset = 0
        setActivePage(1)
        setApiFilters(filterParams)
    }

    useEffect(() => {
        fetchRefundList(refundIdQ);
        if (searchId) {
            setOpenRefundModal(true)
        }
    }, [fetchRefundList, refundIdQ, searchId])  //search Id added

    const handleChange = (pageNumber) => {
        setActivePage(pageNumber)
        refundPageChange(pageNumber * 5 - 5)
    }
    const refundPageChange = (pageNumber) => {
        const queryParams = { ...apiFilters };
        queryParams.offset = pageNumber
        queryParams.startDate = dateFilters?.startDate ? moment(dateFilters?.startDate).format(DATE_FORMAT) : apiFilters?.startDate
        queryParams.endDate = dateFilters?.endDate ? moment(dateFilters?.endDate).format(DATE_FORMAT) : apiFilters?.endDate
        dispatch(getAllRefundsListsAction(queryParams));
    }
    const data = React.useMemo(
        () => refundList,
        [refundList]
    );

    const onStatusTypeChange = (id) => {
        setActivePage(1)
        setApiFilters(s => ({
            ...s,
            status: id,
            offset: 0,
            startDate: dateFilters?.startDate ? moment(dateFilters?.startDate).format(DATE_FORMAT) : apiFilters?.startDate,
            endDate: dateFilters?.endDate ? moment(dateFilters?.endDate).format(DATE_FORMAT) : apiFilters?.endDate,
        }))
    }

    const onRowClick = rowData => {
        setOpenRefundModal(true)
        setRefundData(rowData)
    }

    const clearFilter = () => {
        setApiFilters(s => ({
            ...s,
            startDate: null,
            endDate: null,
            offset: 0,
            status: Constants.REFUND_STATUS.ALL
        }))
        setDateFilters(s => ({
            ...s,
            startDate: null,
            endDate: null
        }))
        setActivePage(1)
        setDateFilterModal(false)
    }

    const closeModal = () => {
        setFilterValidationError('')
        setDateFilterModal(false)
    }

    const openModal = () => {
        setDateFilterModal(true)
    }

    const onCloseRefundModal = () => {
        setOpenRefundModal(false)
        if (searchId) {
            history.replace(RoutePath.REFUND_SCREEN_PATH)
        }
    }

    const onDateChange = (date, type) => {
        if (type === 1) {
            setDateFilters(s => ({
                ...s,
                startDate: date,
                offset: 0
            }))
        } else {
            setDateFilters(s => ({
                ...s,
                endDate: date,
                offset: 0
            }))
        }
        setActivePage(1)
    }

    const onFilterDone = (id) => {
        setActivePage(1)

        if ((!dateFilters.startDate && !dateFilters.endDate) || (dateFilters.startDate === "" && dateFilters.endDate === '')) {
            setFilterValidationError('Please select date.');
            return;
        }


        if ((!dateFilters.startDate && dateFilters.endDate) || (dateFilters.startDate === "")) {
            setFilterValidationError('Please select start date.');
            return;
        }

        if ((dateFilters.startDate && !dateFilters.endDate) || (dateFilters.endDate === '')) {
            setFilterValidationError('Please select end date.');
            return;
        }

        if (moment(dateFilters.startDate).isAfter(moment(dateFilters.endDate))) {
            setFilterValidationError('End date cannot be before start date.');
            return;
        }
        if (searchId) {
            history.replace(RoutePath.REFUND_SCREEN_PATH)
        }
        const params = {
            status: apiFilters?.status,
            startDate: dateFilters?.startDate ? moment(dateFilters?.startDate).format(DATE_FORMAT) : moment(apiFilters?.startDate).format(DATE_FORMAT),
            endDate: dateFilters?.endDate ? moment(dateFilters?.endDate).format(DATE_FORMAT) : moment(apiFilters?.endDate).format(DATE_FORMAT),
            offset: 0,
            limit: 5
        }
        setDateFilterModal(false)
        setFilterValidationError('')
        dispatch(getAllRefundsListsAction(params))
    }

    const onSuccessClose = () => {
        window.location.reload()
    }
    return (
        <>
            <div className="col ps-lg-4">
                <div>
                    <div className="row mb-3">
                        <div className="col"> <h1 className="font20 fw-bold">Refund requests</h1> </div>
                    </div>
                    <div id="refund-options-bar" className="row mb-3">
                        <div className="col-12 col-lg-4">
                            <div className="input-group mb-3 mb-lg-0">
                                <input
                                    id='refundinput'
                                    type="number"
                                    className="form-control border-end-0"
                                    placeholder="Search by Receipt no" value={refundIdQ}
                                    onChange={(value) => onChangeSearchInput(value?.target?.value)}
                                />
                                <button className="btn btn-outline-dark border-start-0" type="button" id="search-btn"><img src={Search} alt="Go" /></button>
                            </div>
                        </div>
                        <div className="col-9 col-sm-4 col-lg-2">
                            <select className="form-select downfield mb-lg-0" id='selectRefund' onChange={(e) => {
                                setRefundFilter(e?.target?.value)
                                onStatusTypeChange(e?.target?.value)
                            }}>
                                {REFUND_FILTERS && REFUND_FILTERS.map((o) => {
                                    return (
                                        <option value={o?.id}>{o?.name}</option>
                                    )
                                })}
                            </select>
                        </div>
                        <div className="d-flex align-items-center justify-content-end col-3 col-sm-3 col-lg-6 text-end cursor-pointer">
                            <div className=''>
                                <span>Filter</span>
                                <FontAwesomeIcon
                                    id="FontAwesomeIcon"
                                    onClick={() => openModal()}
                                    icon={faFilter} size="10m" />
                            </div>
                        </div>
                    </div >
                    <div className="row mb-4">
                        <div className="col">
                            <div id="orders-list" className="card card-body rounded-3 border-0 shadow p-4 mb-3 mb-xl-0">
                                {refundList?.map((o) => {
                                    const { invoiceProductDetailsList } = o
                                    const productName = invoiceProductDetailsList?.map((o) => o.name)
                                    return (<span id='rowClick' className="single-order cursor-pointer" data-bs-toggle="modal" data-bs-target="#approved-refund-modal" onClick={() => onRowClick(o)} >
                                        <div className="row align-items-center">
                                            <div className="col">
                                                <h2 className="font13 mb-2 text-uppercase"><strong>{o?.customerFirstName} {o?.customerLastName}</strong></h2>
                                                <h3 className="font13 mb-2">Receipt no: <strong>{o?.invoiceId}</strong></h3>
                                                <p className="font14 mb-2 desc-wrap title-case" title={productName?.join(",")}>{productName?.join(",")?.toLowerCase()}</p>
                                                <div className="row font12 fw-bold">
                                                    <div className="col-6 col-lg">
                                                        {ShowDateMoment(o?.createdDate)}
                                                    </div>
                                                    <div className="col-12 col-lg pt-2 pt-lg-0"> Total: ${o?.action === 12 || o?.action === 13 ? o?.acceptedAmount?.toFixed(2) : o?.totalAmount?.toFixed(2)} </div>
                                                </div>
                                            </div>
                                            {o?.action === 12 && <div className="col-12 col-lg-4 font13 text-center text-lg-end text-success">
                                                <img src={Greentick} alt="Yes" className="me-1" /> Refund Approved
                                            </div>} {o?.action === 11 && <div className="col-12 col-lg-4 font13 text-center text-lg-end text-warning">
                                                <img src={Pending} alt="Yes" className="me-1" /> Pending
                                            </div>}
                                            {o?.action === 13 && <div className="col-12 col-lg-4 font13 text-center text-lg-end text-danger">
                                                <img src={Declined} alt="Yes" className="me-1" /> Refund Rejected
                                            </div>}
                                        </div><hr className="bg-ltgrey-1 mb-4" />
                                    </span>
                                    )
                                })}
                                <div className="row align-items-center">
                                    <div className="col">
                                        {
                                            data && data.length !== 0 && totalCount > 5 && <Pagination
                                                id="Pagination"
                                                activePage={activePage}
                                                itemsCountPerPage={5}
                                                totalItemsCount={totalCount}
                                                pageRangeDisplayed={5}
                                                onChange={(e) => handleChange(e)}
                                            />
                                        }
                                    </div>
                                </div>
                                {
                                    (data && data.length === 0 && !isLoading) && (
                                        <EmptyPage
                                            content="No refunds found"
                                        />
                                    )
                                }
                            </div>
                            {isLoading && <AppLoader />}
                        </div>
                    </div>
                </div >
            </div >
            {isOpenRefundModal && <RefundDetails
                id="approvedmodal"
                onClose={() => onCloseRefundModal()}
                refundOptions={refundData}
                onSuccessClose={() => onSuccessClose()}
                refundId={searchId}
                isHideViewReceipt={false}
            />}

            {
                openDateFilterModal && <DateFilter
                    id="DateFilter"
                    onDateChange={(date, option) => onDateChange(date, option)}
                    startDate={dateFilters?.startDate}
                    endDate={dateFilters?.endDate}
                    title="DATE RANGE"
                    proceed="Apply Filter"
                    cancel="Close"
                    clearFilter={() => clearFilter()}
                    onSuccess={() => onFilterDone()}
                    onFailure={() => closeModal()}
                    errorMessage={filterValidationError}
                    renderStatus={() => { }}
                />
            }

        </>
    )

}


export default RefundScreen;