export const getPlanogramDimensions = (rack, bay, productsData) => {
    const getShelveArea = parseInt(rack?.depth) * parseInt(bay?.bayWidth)
    const getProductArea = (parseInt(productsData?.lengthInCm) ? parseInt(productsData?.lengthInCm) : 1) * (parseInt(productsData?.widthInCm) ? parseInt(productsData?.widthInCm) : 1)
    return {
        getShelveArea,
        getProductArea
    }
}

export const getFrontCapacity=(shelve, bay, productsData)=>{
    const getFrontArea=parseInt(shelve?.shelveHeight) * parseInt(bay?.bayWidth)
    const getProductFrontArea = parseInt(productsData?.heightInCm) * parseInt(productsData?.widthInCm)
    return {
        getFrontArea,
        getProductFrontArea
    }
}


