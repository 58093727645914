import React from "react";
import Close from '../../../../asset/new/img/modal-close.svg'
import placeholderImage from '../../../../asset/images/placeholder.jpg';


const ProductDetails = ({ title, isCloseModal, productDetails }) => {
    return <React.Fragment>
        <div className="modal d-block" id="product-details-modal" tabIndex="-1" aria-labelledby="product-details" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content rounded-0">

                    <img src={Close} alt="X" data-bs-dismiss="modal" aria-label="Close" id="close" className="modal-close" onClick={() => isCloseModal(false)} />

                    <div className="modal-header rounded-0 align-items-center py-4">
                        <div className="col"> <h5 className="modal-title">{title}</h5> </div>
                    </div>

                    <div className="modal-body p-40">

                        <div className="text-center">
                            <img src={productDetails?.imageUrl ? productDetails?.imageUrl + '?$' + Math.random() : placeholderImage} alt="1" className="product-preview img-fluid" />
                        </div>
                        <h3 className="font13 mb-2 title-case">{productDetails?.productName?.toLowerCase()}</h3>
                        <span className="font12 text-dark fw-bold">SKU - {productDetails?.sku}</span>
                        <hr className="thick" />

                        {productDetails.addOnList?.length > 0 && <>
                            <h3 className="font13 mb-2  fw-bold">CUSTOMIZATION</h3>
                            {productDetails?.addOnList.map((o, index) => <span className="font12 text-dark">{o?.productName}{(index < productDetails?.addOnList?.length - 1) ? ', ' : ''}</span>)}
                            <hr className="thick" />
                        </>}

                        <h3 className="font16 text-uppercase fw-bold mb-4">DESCRIPTION</h3>
                        <p className=" mb-3 fw-bold">{productDetails?.productDescription}</p>

                        <div className="row mb-2">
                            <div className="col">Height:</div>
                            <div className="col-auto text-end">{productDetails?.heightInCm?.toFixed(2)}cm</div>
                        </div>

                        <div className="row mb-2">
                            <div className="col">Width:</div>
                            <div className="col-auto text-end">{productDetails?.widthInCm?.toFixed(2)}cm</div>
                        </div>

                        <div className="row mb-2">
                            <div className="col">Length:</div>
                            <div className="col-auto text-end">{productDetails?.lengthInCm?.toFixed(2)}cm</div>
                        </div>

                        <div className="row mb-2">
                            <div className="col">Weight:</div>
                            <div className="col-auto text-end">{productDetails?.weightInGrams?.toFixed(2)}gms</div>
                        </div>

                        <hr className="thick" />

                        <div className="row mb-2">
                            <div className="col">Price:</div>
                            <div className="col-auto text-end">{productDetails?.price?.toFixed(2)} </div>
                        </div>

                        <div className="row mb-2">
                            <div className="col">Tax Percentage:</div>
                            <div className="col-auto text-end">{productDetails?.taxPercentage?.toFixed(2)}%</div>
                        </div>

                        <div className="row mb-2">
                            <div className="col">Selling Price:</div>
                            <div className="col-auto text-end">${productDetails?.sellingPrice?.toFixed(2)}</div>
                        </div>
                        {productDetails?.nutritionalInformation &&
                            <>
                                {(productDetails?.nutritionalInformation?.displayEcommerceCalories || productDetails?.nutritionalInformation?.displayEcommerceCarbohydrates ||
                                    productDetails?.nutritionalInformation?.displayEcommerceFats || productDetails?.nutritionalInformation?.displayEcommerceProtein || productDetails?.nutritionalInformation?.displayEcommerceSugar
                                ) && <div className="borderLine"><div className="card order-summary-nutrition  card-body  ">
                                    <h2 className="font16 fw-bold ">Nutritions Facts</h2>
                                    <div className="lineNutri" />
                                    {productDetails?.nutritionalInformation?.displayEcommerceCalories && <><div className="row">
                                        <div
                                            className="col">Calories</div>
                                        <div
                                            className="col text-end">{productDetails && productDetails?.nutritionalInformation?.calories?.toFixed(2)}%</div>
                                    </div>
                                        <div className="smalllineNutri" /></>}
                                    {productDetails?.nutritionalInformation?.displayEcommerceCarbohydrates && <><div className="row">
                                        <div
                                            className="col">Carbohydrates</div>
                                        <div
                                            className="col text-end">{productDetails && productDetails?.nutritionalInformation?.carbohydrates?.toFixed(2)}%</div>
                                    </div>
                                        <div className="smalllineNutri" /></>}
                                    {productDetails?.nutritionalInformation?.displayEcommerceFats && <><div className="row">
                                        <div
                                            className="col">Fats</div>
                                        <div
                                            className="col text-end">{productDetails && productDetails?.nutritionalInformation?.fats?.toFixed(2)}%</div>
                                    </div>
                                        <div className="smalllineNutri" /></>}
                                    {productDetails?.nutritionalInformation?.displayEcommerceProtein && <><div className="row">
                                        <div
                                            className="col">Protein</div>
                                        <div
                                            className="col text-end">{productDetails && productDetails?.nutritionalInformation?.protein?.toFixed(2)}%</div>
                                    </div>
                                        <div className="smalllineNutri" /></>}
                                    {productDetails?.nutritionalInformation?.displayEcommerceSugar && <><div className="row ">
                                        <div
                                            className="col">Sugar</div>
                                        <div
                                            className="col text-end">{productDetails && productDetails?.nutritionalInformation?.sugar?.toFixed(2)}%</div>
                                    </div></>}

                                </div></div>}</>}
                    </div>

                </div>
            </div>
        </div>
        <div className="modal-backdrop show"></div>

    </React.Fragment >
};

export default ProductDetails;