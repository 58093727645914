import moment from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectedChatAction } from '../../redux/actions/ChatsAction';
import { getSelectedChatSelector } from '../../redux/reducers/ChatsReducer';
import Constants from '../../utils/Constants';

const ChatListItems = ({ chat }) => {
    const { customer, chatMessage, invoiceId, lastModified } = chat;
    const { message } = chatMessage || {};
    const dispatch = useDispatch();
    const selectedChat = useSelector(getSelectedChatSelector);

    const onClick = () => {
        dispatch(selectedChatAction(chat))
    }

    return <div className={`tab-pane fade show active"
        id="active-content" role="tabpanel"
        aria-labelledby="active-tab`}>
        <div className={`border-bottom p-2 cursor-pointer ${chat.id === selectedChat?.id ? 'bg-cream' : ''} `} onClick={() => onClick()}>
            <div className='row'><div className='col-4'>{` ${customer?.firstName ? customer?.firstName :''  } `}</div><div className=' text-end col-8 '>{`Receipt no: ${invoiceId}  `}</div></div> <br />
            <p><small className="fw-light">{message}</small></p>
            <p className="text-end mt-1 font11 text-secondary">
                {(moment(moment().format('YYYY-MM-DD')).isSame(
                    moment(lastModified).format('YYYY-MM-DD'),
                ) && moment.utc(lastModified).local().startOf('seconds').fromNow()) ||
                    (moment(moment().subtract(1, 'day').format('YYYY-MM-DD'),).
                        isSame(moment(lastModified).format('YYYY-MM-DD'))
                        && Constants.CHAT.STRING_YESTERDAY) ||
                    moment(lastModified).format('MM/DD/YYYY')}
            </p>
            
        </div>
    </div>




}

export default ChatListItems;