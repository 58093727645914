import React, { useState } from 'react';
import Close from '../../../../asset/new/img/modal-close.svg'
import { PlaceholderImage } from '../../../components/fields/AppComponents';
import { UTCDateFormat } from '../../../utils/commonFunctions/dateFormat';

export const DEFAULT_FREE_ITEM = { sku: '', editQuantity: 1 };

const defaultState = {
    sku: 0,
    freeItemSkusWithQuantities: [
        { ...DEFAULT_FREE_ITEM }
    ],
    offer: {
        offerType: 'FREE_ITEMS',
        offerValue: 1,
        expiryOn: null,
        validFrom: null
    }
}

const FreeItemsDetailModal = ({ isCloseModal, modalData }) => {
    const inititlizeData = modalData?.sku ? modalData : defaultState;
    const [freeItemsObject] = useState(inititlizeData)
    return <><div className="modal d-block"
        id="unblock-modal"
        tabIndex="-1"
        aria-labelledby="unblock"
        aria-hidden="true"><div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content rounded-0">
                <img id='close' src={Close} alt="X" data-bs-dismiss="modal" aria-label="Close" className="modal-close" onClick={
                    () => isCloseModal(false)
                } />
                <div className="modal-header rounded-0 align-items-center py-4">
                    <div className="col">
                        <h5 className="modal-title">Free Items Offer - ID #{modalData?.id}</h5>
                    </div>
                </div >
                <div className="modal-body font14 p-40" >
                    <div className="row mb-3" >
                        <div className="col-3 fw-bold">Product name</div >
                        <div className="col-9 title-case" >{modalData?.productName?.toLowerCase()}</div >
                    </div >
                    <div className="row mb-3" >
                        <div className="col-3 fw-bold">Valid From</div >
                        <div className="col-9" >{UTCDateFormat(freeItemsObject?.startDateString)}</div >
                    </div >
                    <div className="row mb-3" >
                        <div className="col-3 fw-bold" >Valid To</div >
                        <div className="col-9" >{UTCDateFormat(freeItemsObject?.endDateString)} </div >
                    </div >

                    <div className="fw-bold pt-4">Free Items</div>
                    <hr className="thick" />

                    {freeItemsObject?.freeItemSkusWithQuantities?.map((products, index) => {
                        return (<>

                            {(modalData.id) &&
                                <div className="row align-items-center mb-3">
                                    <div className="col-auto fw-bold">{index + 1}.</div>
                                    {/* <div className="col-auto">
                                        <PlaceholderImage alt="product" className='freeImg'
                                            src={products.imageUrl ? `${products.imageUrl}` : products?.imageUrl} />
                                    </div> */}
                                    <div className="col fw-bold title-case"> {products?.productName?.toLowerCase()} <small className="fw-normal">(SKU {products?.sku})</small> - {products?.editQuantity}</div>
                                </div>
                            }</>)
                    })}

                    <div className="row my-4" >
                        <div className="col" > <span id='onClose' data-bs-dismiss="modal" aria-label="Close" className="btn btn-dark font14 w-100 p-2" onClick={
                            () => isCloseModal(false)
                        } > Close</span > </div >
                    </div >

                </div >

            </div>
        </div>
    </div>
        <div className="modal-backdrop show"></div>
    </>

};

export default FreeItemsDetailModal;