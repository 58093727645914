import { all, put, takeLatest } from "redux-saga/effects";
import { doAuthPost } from "../../utils/service";
import END_POINTS from "../../utils/service/Endpoints";
import Actions from "../actions";

export function* QrCodeSaga({ params, callback }) {
    try {
        const response = yield doAuthPost(END_POINTS.QR_CODE_SCANNER, params);
        if (response) {
            yield put(Actions.qrCodeScannerActionSuccess(response));
        }
        callback && callback(response);
    }
    catch (error) {
        callback && callback(error);
        yield put(Actions.qrCodeScannerActionFailure({ error }));
    }
}

export default function* QrCodeAWatcher() {
    yield all([
        takeLatest(Actions.QR_CODE_SCANNER, QrCodeSaga),
    ]);
}
