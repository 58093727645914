import Actions from "../actions";

const initialState = {
    isLoading: false,
    dollarOfferList: [],
    createDollarOffer: [],
    updateDollarOffer: [],
    deleteDollarOffer: [],
    error: false,
    createLoading: false,
    updateLoading: false,
    deleteLoading: false,
    createError: false,
    updateError: false,
    deleteError: false,
    totalCount: undefined
}
const DollarOfferReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.DOLLAR_OFFER_LIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.DOLLAR_OFFER_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                dollarOfferList: action?.data?.response || [],
                totalCount: action.data?.totalPages,
                error: false
            };
        }
        case Actions.DOLLAR_OFFER_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                dollarOfferList: [],
                error: action.error,
            };
        }
        case Actions.CREATE_DOLLAR_OFFER: {
            return {
                ...state,
                createLoading: true,
            };
        }
        case Actions.CREATE_DOLLAR_OFFER_SUCCESS: {
            return {
                ...state,
                createLoading: false,
                createDollarOffer: action.data,
                createError: false
            };
        }
        case Actions.CREATE_DOLLAR_OFFER_FAILURE: {
            return {
                ...state,
                createLoading: false,
                createError: action.data,
            };
        }
        case Actions.UPDATE_DOLLAR_OFFER: {
            return {
                ...state,
                updateLoading: true,
            };
        }
        case Actions.UPDATE_DOLLAR_OFFER_SUCCESS: {
            return {
                ...state,
                updateLoading: false,
                updateDollarOffer: action.data.content,
                updateError: false
            };
        }
        case Actions.UPDATE_DOLLAR_OFFER_FAILURE: {
            return {
                ...state,
                updateLoading: false,
                updateError: action.data,
            };
        }
        case Actions.DELETE_DOLLAR_OFFER: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.DELETE_DOLLAR_OFFER_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                deleteDollarOffer: action.data.content,
                deleteError: false
            };
        }
        case Actions.DELETE_DOLLAR_OFFER_FAILURE: {
            return {
                ...state,
                isLoading: false,
                deleteError: action.error,
            };
        }
        default:
            return state;
    }
};

export default DollarOfferReducer;

export const getDollarOfferListSelector = state => state?.DOLLAR_OFFER?.dollarOfferList;
export const getDollarOfferCountSelector = state => state?.DOLLAR_OFFER?.totalCount;
export const getDollarOfferListLoadingSelector = state => state?.DOLLAR_OFFER?.isLoading;