import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import Action from '../../redux/actions';
import { getPlanogramAnalytics, getRackDetailsLoader } from '../../redux/reducers/planogramCV/GetPlanogramDetailsReducer';
import Refresh from '../../../asset/images/refresh.svg'
import AppLoader from '../../components/fields/AppLoader';
import { DateTimeFormat } from '../../utils/commonFunctions/dateFormat';
import EmptyPage from '../../components/emptyPage';
import Tippy from '@tippyjs/react';
import PlanogramReportPopUp from './PlanogramReportPopUp';

let timeInterval;
class PlanogramReport extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            currentDate: null,
            refreshLoading: false,
            isOpenModal: false,
            isOutOfStock: false,
            items: [],
            rackName: null
        }
    }
    componentDidMount() {
        this.setState({ currentDate: new Date() })
        this.fetchAPICall()
    }

    fetchAPICall() {
        this.props.getPlanogramAnalytics()
        if (timeInterval) {
            clearInterval(timeInterval)
        }
        timeInterval = setInterval(() => {
            this.setState({ currentDate: new Date() })
            this.props.getPlanogramAnalytics()
        }, 60000);
    }

    componentWillUnmount() {
        clearInterval(timeInterval)
    }

    refreshPage() {
        this.setState({ refreshLoading: true, currentDate: new Date() })
        this.fetchAPICall()
    }

    isOpenPopUp(params, obj, arr, rackName) {
        if (obj > 0) {
            this.setState({ isOutOfStock: params, isOpenModal: true, items: arr, rackName })
        }
    }

    closePopUp() {
        this.setState({ isOpenModal: false })
    }

    render() {
        const { isLoading, planogramAnalytics } = this.props || {}
        const { rackWise = [], skuWise = [] } = planogramAnalytics || {}
        const { misplaced = [], outOfStock = [], partialOutOfStock = [] } = skuWise || {}
        const { currentDate, refreshLoading, isOutOfStock, isOpenModal, items, rackName } = this.state

        return (
            <div className="col ps-lg-4">
                <div className="row mb-4">
                    <div className="col">
                        <div className='btn btn-dark' onClick={() => this.props.history.goBack()}>
                            <i className="fa-regular fas fa-arrow-left mx-1"></i>Back
                        </div>
                    </div>
                    <div className="col text-end">
                        <span id='Receipt'>
                            Last Updated : {DateTimeFormat(currentDate)}
                        </span>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col"> </div>
                    <div className="col text-end">
                        <span id='Receipt' className="cursor-pointer" onClick={() => this.refreshPage()}>
                            <img src={Refresh} alt="orders" className="me-1 sideNav-img" />Refresh
                        </span>
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className="col-sm-6 col-12 mt-2">
                        <div style={{ pointerEvents: "none" }} className="btn-outline-dark planogram-report p-2 px-5 w-100 text-center" id='onUpdateVisitor'
                        >Rack Wise</div>
                        <div className="row g-2 font11 text-center mt-3 rack-wise">

                            {rackWise?.map((rackObj) => {
                                return (
                                    <React.Fragment>
                                        <Tippy content={<span>{rackObj?.rackName}</span>}>
                                            <div id='Requested' className="col-6 col-md-3">
                                                <div className="card card-body border-0 bg-dgrey px-2 rack_wise_custom " >
                                                    <h3 className="font20 fw-bold text-truncate" style={{ maxWidth: "75px" }}>{rackObj?.rackName}</h3>
                                                </div>
                                            </div>
                                        </Tippy>
                                        <div id='Requested' className="col-6 col-md-3 cursor-pointer" onClick={() => this.isOpenPopUp(true, rackObj?.outOfStock, rackObj?.outOfStockItems, rackObj?.rackName)}>
                                            <div className="card card-body border-0 bg-dorange px-2">
                                                <h3 className="font20 fw-bold">{rackObj?.outOfStock} Items</h3> <br /> <b>Out of Stock</b>
                                            </div>
                                        </div>

                                        <div id='Requested' className="col-6 col-md-3 cursor-pointer" onClick={() => this.isOpenPopUp(false, rackObj?.partialOutOfStock, rackObj?.partialStockItems, rackObj?.rackName)}>
                                            <div className="card card-body border-0 bg-dyellow px-2">
                                                <h3 className="font20 fw-bold ">{rackObj?.partialOutOfStock} Items</h3><br /> <b>Partial Stock</b>
                                            </div>
                                        </div>
                                        <div id='in-progress' className="col-6 col-md-3" >
                                            <div className="card card-body border-0 bg-dgreen px-2">
                                                <h3 className="font20 fw-bold">{rackObj?.fullyStock} Items</h3> <br /> <b>Fully Stock</b>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )
                            })}


                        </div>
                    </div>

                    <div className="col-sm-6 col-12 mt-2">
                        <div style={{ pointerEvents: "none" }} className="btn-outline-dark planogram-report text-center p-2 px-5 w-100" id='onUpdateVisitor'
                            name="" >SKUs Wise</div>

                        <div className="g-2 font11 mt-3">
                            <div id='Requested' >
                                {outOfStock?.length > 0 &&
                                    <div className="card card-body border-0 bg-dblue px-2 plano_custom">
                                        <h3 className="font20 fw-bold">Out of Stock:</h3>
                                        {
                                            outOfStock?.map((outObj) => {
                                                return (
                                                    <React.Fragment>
                                                        <div className='row mt-2'>
                                                            <div className='col-sm-6'>
                                                                <span style={{ color: 'black' }} className="fw-bold font12 p-1">{outObj?.name}</span>
                                                            </div>
                                                            <div className='col-sm-3'>
                                                                <h6 style={{ color: 'black' }} className="fw-bold font12 p-1">{outObj?.upc}</h6>
                                                            </div>
                                                            <div className='col-sm-3'>
                                                                <h6 style={{ color: 'black' }} className="fw-bold font12 p-1">{outObj?.rackName}</h6>
                                                            </div>
                                                        </div>
                                                        <hr className='m-0 p-0 mt-1' />
                                                    </React.Fragment>

                                                )
                                            })

                                        }
                                    </div>
                                }
                                {misplaced?.length > 0 &&
                                    <div className="card card-body border-0 bg-dblue px-2 mt-2 plano_custom">
                                        <h3 className="font20 fw-bold">Missing Item:</h3>
                                        {
                                            misplaced?.map((misObj) => {
                                                return (
                                                    <React.Fragment>
                                                        <div className='row mt-2'>
                                                            <div className='col-sm-6'>
                                                                <span style={{ color: 'black' }} className="fw-bold font12 p-1">{misObj?.name}</span>
                                                            </div>
                                                            <div className='col-sm-3'>
                                                                <h6 style={{ color: 'black' }} className="fw-bold font12 p-1">{misObj?.upc}</h6>
                                                            </div>
                                                            <div className='col-sm-3'>
                                                                <h6 style={{ color: 'black' }} className="fw-bold font12 p-1">{misObj?.rackName}</h6>
                                                            </div>
                                                        </div>
                                                        <hr className='m-0 p-0 mt-1' />
                                                    </React.Fragment>

                                                )
                                            })
                                        }
                                    </div>
                                }
                                {partialOutOfStock?.length > 0 &&
                                    <div className="card card-body border-0 bg-dblue px-2 mt-2 plano_custom">
                                        <h3 className="font20 fw-bold mb-2">Partial Stock:</h3>
                                        {partialOutOfStock?.map((parObj) => {
                                            return (
                                                <React.Fragment>
                                                    <div className='row mt-2'>
                                                        <div className='col-sm-6'>
                                                            <span style={{ color: 'black' }} className="fw-bold font12 p-1">{parObj?.name}</span>
                                                        </div>
                                                        <div className='col-sm-3'>
                                                            <h6 style={{ color: 'black' }} className="fw-bold font12 p-1">{parObj?.upc}</h6>
                                                        </div>
                                                        <div className='col-sm-3'>
                                                            <h6 style={{ color: 'black' }} className="fw-bold font12 p-1">{parObj?.rackName}</h6>
                                                        </div>
                                                    </div>
                                                    <hr className='m-0 p-0 mt-1' />
                                                </React.Fragment>
                                            )
                                        })}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {(isLoading && refreshLoading) && <AppLoader />}

                <div className='mt-5'>
                    {(planogramAnalytics && Object.keys(planogramAnalytics)?.length === 0 && !isLoading) && <EmptyPage
                        content="Rack details not found on selected store"
                    />}
                </div>
                {
                    isOpenModal && <PlanogramReportPopUp
                        items={items}
                        isOutOfStock={isOutOfStock}
                        closePopUp={() => this.closePopUp()}
                        rackName={rackName}
                    />
                }
            </div>
        )
    }
}


export const mapStateToProps = (state) => {
    return {
        planogramAnalytics: getPlanogramAnalytics(state),
        isLoading: getRackDetailsLoader(state)
    };
};

export default withRouter(connect(mapStateToProps, {
    getPlanogramAnalytics: Action.getPlanogramAnalytics,
})(PlanogramReport));
