import moment from 'moment'
export const COLORS = {
    appThemeHex: '#1e1f29',
    appThemeHover: 'rgba(30, 31, 41, .5)',
    loadingBackround: 'rgba(255, 255, 255, .4)',
    listBackground: 'rgba(30, 31, 41, .2)',

}

const Constants = {
    CHAT: {
        STRING_YESTERDAY: 'Yesterday'
    },
    DATE_FORMAT: 'YYYY-MM-DD',
    CROSS_TABS_LOGOUT: 'CROSS_TABS_LOGOUT',
    CROSS_TABS_LOGIN: 'CROSS_TABS_LOGIN',
    ORDER_STATUS: {
        COMPLETED: 2,
        PENDING: 1,
        PROCESSING: 0,
        DECLINED: 3,
        READYTOPICKUP: 4,
        REFUND_INIT: 11,
        DELIVERED: 5,
        REFUND_COMPLETED: 6,
        REFUND_FAILED: 13,
        REFUND: 15,
    },
    ORDER_TYPES: {
        CREATED_BY_HITACHI: 1,
        CREATED_BY_USER_ONLINE_ORDER: 2,
        CREATED_BY_VISION_CHECKOUT: 3,
        CREATED_BY_SCAN_AND_GO: 4
    },
    ORDER_SUMMARY: {
        title: 'My Orders',
        description: 'Below is the summary of all orders you have made in the past. Click on each order to receive detailed information and status of the order.',
        view: 'View',
        date: 'Date',
        orderNumber: 'Receipt no',
        price: 'Price',
        orderSummary: 'Order Summary',
        deliveryAddress: 'Delivery Address:',
        pickupAddress: 'Pickup Address:',
        orderNo: 'Order no: #',
        noAddons: 'No Customization',
        orderTypes: {
            orderPlaced: 'Order placed',
            packing: 'Packing',
            preparing: 'Preparing',
            readyToPickup: 'Ready to Pickup',
            delivered: 'Delivered',
            declined: 'Not Available',
            outforDelivery: 'Out for delivery'
        },
        paymentType: {
            completed: 'Completed',
            pending: 'Pending',
            processing: 'Processing',
            failed: 'Failed',
            pendingFailed: 'Payment Failed',
            refundInitiated: 'Refund Initiated',
            refundApproved: 'Refund Approved',
            refundDenied: 'Refund Declined'
        },
        orderDetails: {
            title: 'ORDER DETAILS',
            invoiceNo: 'Receipt no: #',
            orderDate: 'Order Date:',
            orderStatus: 'Payment Status:'
        },
        tableColumns: {
            image: 'Product Image',
            name: 'Name',
            sku: 'SKU',
            desc: 'Description',
            addons: 'Add ons',
            quantity: 'Quantity',
            price: 'Price',
            subTotal: 'Sub Total',
            salesTax: 'Sales Tax',
            tax: 'Tax',
            totalAmount: 'Total Amount',
            refundInformation: 'Refund Information'

        },
        net: 'Sub Total',
        vat: 'Sales tax',
        total: 'Total',
        thankyou: 'THANK YOU FOR YOUR PURCHASE',
        refund: 'Refund',
        emailInvoice: 'Email Invoice',
        refundText: 'Kindly handover the product to respective store for processing the refund'
    },
    REFUND_STATUS: {
        REJECT: 13,
        PENDING: 11,
        PROCESSING: 0,
        APPROVED: 12,
        REFUND_INIT: 1,
        INPROCESS: 5,
        REFUND_COMPLETED: 10,
        REFUND_FAILED: 16,
        REFUND: 15,
        ALL: 14
    },
    API: {
        something: 'Something went wrong, please try again after sometime.'
    },
    HEADER: {
        appName: 'UST',
        logout: 'Log out',
        login: `Login / `,
        register: 'Register',
        search: 'Search'
    },
    LOGIN: {
        title: "Login",
        dontHaveAccount: `Don't have an account?`,
        signup: "SignUp",
        forgotPassword: "Forgot Your Password",
        error: {
            emailId: 'Please enter a valid Email Id.',
            emailIdEmpty: 'Please enter Email Id.',
            password: 'Please enter Password.',
            passwordLimit: 'Password needs to be at least 8 characters.',
        }
    },
    FOOTER: {
        bistro: 'Bistro',
        groceries: 'Grocery'
    },
    FORGET_PASSWORD: {
        title: "Forgot Password",
        sendButton: "Send",
        error: {
            emailId: 'Please enter valid Email Id.',
            emailIdEmpty: 'Please enter Email Id.'
        }
    },
    REGISTER: {
        title: 'SignUp',
        error: {
            firstName: 'First Name must be atleast 3 characters.',
            lastName: 'Please enter last name.',
            password: 'Passowrd does not match the requirements.',
            email: 'Email Id, is already registered, please try to login.',
            passwordMismatch: 'Password and Re-type passowrd does not match.',
            enterEmailId: 'Please enter Email Id.',
            validEmail: 'Email Id is not valid',
            passwordNotMatch: 'Password does not match.',
        }
    },
    UPDATE_EMAIL: {
        title: 'Update Email Id'
    },
    STORE_ADDRESS: {
        "city": 'Viejo',
        "landmark": 'Aliso',
        "street": '5 Polaris Way',
        "zip": 'CA 92656'
    },
    FREE_ITEMS: [{
        label: "FREE_ITEMS",
        value: "FREE_ITEMS"
    }],
    PRODUCT_DISCOUNTS: [{
        key: "PERCENTAGE",
        value: "PERCENT"
    },
    {
        key: "DOLLAR",
        value: "DOLLAR"
    }],
    PRODUCT_TYPE: [{
        KEY: 'Bistro',
        value: 'bistro'
    }, {
        KEY: 'Grocery',
        value: 'grocery'
    }],
    SUBSCRIPTION_TYPE: [{
        key: "DELIVERY",
        value: "DELIVERY"
    },
    {
        key: "COFFEE",
        value: "COFFEE"
    },
    {
        key: "BISTRO",
        value: "BISTRO"
    },

    ],
    PERIOD_TYPE: [{
        key: "YEARLY",
        value: "YEARLY"
    },
    {
        key: "MONTHLY",
        value: "MONTHLY"
    },

    ],

    HOURS_OPTION: [
        { value: '00:00:00', label: '00:00 AM' },
        { value: '01:00:00', label: '01:00 AM' },
        { value: '02:00:00', label: '02:00 AM' },
        { value: '03:00:00', label: '03:00 AM' },
        { value: '04:00:00', label: '04:00 AM' },
        { value: '05:00:00', label: '05:00 AM' },
        { value: '06:00:00', label: '06:00 AM' },
        { value: '07:00:00', label: '07:00 AM' },
        { value: '08:00:00', label: '08:00 AM' },
        { value: '09:00:00', label: '09:00 AM' },
        { value: '10:00:00', label: '10:00 AM' },
        { value: '11:00:00', label: '11:00 AM' },
        { value: '12:00:00', label: '12:00 PM' },
        { value: '13:00:00', label: '1:00 PM' },
        { value: '14:00:00', label: '2:00 PM' },
        { value: '15:00:00', label: '3:00 PM' },
        { value: '16:00:00', label: '4:00 PM' },
        { value: '17:00:00', label: '5:00 PM' },
        { value: '18:00:00', label: '6:00 PM' },
        { value: '19:00:00', label: '7:00 PM' },
        { value: '20:00:00', label: '8:00 PM' },
        { value: '21:00:00', label: '9:00 PM' },
        { value: '22:00:00', label: '10:00 PM' },
        { value: '23:00:00', label: '11:00 PM' },
    ],

    PAGE_LIMIT: 60,

    CUSTOMER_COUPON_DISCOUNTS: [{
        key: "PERCENTAGE",
        value: "PERCENTAGE"
    }, {
        key: "DOLLAR",
        value: "DOLLAR"
    }],

    CUSTOMER_COUPON_ERROR: {
        label: 'N/A'
    },

    PROMOTION_STATUS: {
        Success: 'green',
        Error: 'red'
    },
    // TODO Copyright changes
    FOOTER_TEXT: {
        text: `Copyright © ${moment().format('YYYY')}, Computer Vision`
    },
    NILGIRIS_FOOTER_TEXT: {
        text: `Copyright © ${moment().format('YYYY')}, Proglint`
    },
    LOG_OUT: {
        desc: "Do you want to logout?",
        proceed: "Yes",
        cancel: "Cancel",
    },
    GUEST_USER: {
        guest: "Guest",
        user: "User"
    },
    MULTI_STORE: {
        STORE_PRODUCT_NOT_FOUND: 'Product not found',
        TITLE: "Please choose the store, to proceed shopping in E- commerce",
        STORE_TITLE: 'Items already in cart',
        SUB_TITLE_STORE: 'Your cart contains items from another store.Would you like to reset your cart by switching to this store?',
        ITEM_ALREADY: 'Items already in cart',
        ITEM_ALREADY_BUTTON: "Yes, I’ll start fresh",
        CANCEL_BUTTON: "No, I’ll keep my cart",
    },
    ROLES: {
        ADMIN: 0,
        STORE_MANAGER: 1,
        STORE_ASSOCIATE: 3,
        BISTRO_ASSOCIATE: 2,
        BACK_KITCHEN: 11,
        BISTRO_DISPLAY: 12,
        FRONT_KITCHEN: 13
    },
    PLANOGRAM: {
        UNIT: 'cm',
        STORE_WIDTH: 650,
        HEADER_UNASSIGN: "Unassign Product",
        HEADER_PRODUCT_REPLACE: "Product Replace",
        TITLE_UNASSIGN: "Are you sure to unassign product?",
        TITLE_PRODUCT_REPLACE: "Are you sure to Replace the product?",
        HEADER_REMOVE_PRODUCT: "Remove Product",
        DESCRIPTION_REMOVE_PRODUCT: "Are you sure to remove product?",
    },
    COMMON_APP_DOMAINS: {
        commonLogin: 'https://shop-login.proglint.com',           // TODO: ADD SSO DOMAIN
        storeManager: 'https://shop-admin.proglint.com',
        cookieDomain: '.proglint.com',
        PIMlogin: 'https://shop-pim.proglint.com/'

    }
}

export default Constants;

export const RefundOptions = (type) => {
    switch (type) {
        case 0:
            return 'Wrong Product'
        case 1:
            return 'Damaged Product'
        case 2:
            return 'Wrong amount'
        case 3:
            return 'Other'
        default:
            return null
    }
}