import Actions from '../../actions';
const initialState = {
    isLoading: false,
    RackCaptureDetails: {},
    error: false,
}
const rackCaptureReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.RACK_CAPTURE: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.RACK_CAPTURE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                RackCaptureDetails: action.data,
                error: false
            };
        }
        case Actions.RACK_CAPTURE_FAILURE: {
            return {
                ...state,
                isLoading: false,
                RackCaptureDetails: {},
                error: action.error,
            };
        }
        default:
            return state;
    }
};
export const getRackInformation = (state) => state?.RACK_CAPTURE?.RackCaptureDetails
export default rackCaptureReducer;