import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import LoginReducer from "./LoginReducer";
import ClientCredentialsReducer from './ClientCredentials';
import OrderReducer from './OrderReducer';
import ProductsReducer from './ProductsReducer';
import FoodProductsReducer from './FoodProductsReducer';
import RefundsReducer from './RefundReducer';
import ForgotPassword from './forgotPassword'
import ComboOfferReducer from './ComboOfferReducer';
import DollarReducers from './DollarOfferReducers';
import OfferReducer from './OfferReducer'
import multiBuyReducer from './MultiBuyReducer';
import freeItemList from './FreeItemListReducer'
import customerCouponReducer from './CustomerCouponReducer';
import happyHoursItem from './HappyHoursItemReducer';
import ChatsReducer from './ChatsReducer';
import NavigationDrawerReducer from './NavigationDrawerReducer';
import StoreReducer from './StoreReducer';
import DahboardOfferReducer from './PromotionDashboardReducer';
import GetDashboardDetailsReducer from './GetDashboardDetailsReducer';
import ReportTypeListReducers from './ReportTypeListReducers';
import CustomerUnBlockingReducer from './CustomerUnBlockingReducer';
import NotificationsReducer from './NotificationsReducer';
import SubscriptionReducer from './SubscriptionReducer';
import GetDaxbotReducers from './GetDaxbotReducers'
import BistroProductListReducer from './BistroProductListReducers';
import InvoiceDetailsReducers from './InvoiceDetailsReducers'
import ProductGroupInformationReducers from './ProductGroupInformationReducers';
import BistroDisplayReducer from './BistroDisplayReducer'
import changePasswordReducers from './ChangePasswordReducers';
import InvoiceListReducer from './InvoiceListReducer';
import HitachiMonitoring from './HitachiMonitoringReducer'
import QrCodeReducers from './QrCodeReducers'
import StoreAvailability from './StoreAvailabilityReducers'
import BranchListReducer from './BranchListReducer'
import Planogram from './PlanogramReducer'
import PlanogramDetail from './PlanogramDetailReducer'
import PlanogramProduct from './PlanogramProductReducer'
import CameraListsReducer from './planogramCV/GetCameraListReducer'
import getCvPlanogramDetails from './planogramCV/GetPlanogramDetailsReducer'
import rackCaptureReducer from './planogramCV/RackCaptureReducer'
import autoFillReducer from './planogramCV/AutoFillReducer'
import updatePlanogramProductReducer from './planogramCV/UpdatePlanogramProductReducer';
import BaySaveProductListReducer from './BaySaveProductListReducer';
import Actions from '../actions';

const appReducer = routeHistory => combineReducers({
    router: connectRouter(routeHistory),
    LOGIN_CREDS: LoginReducer,
    CLIENT_CREDS: ClientCredentialsReducer,
    ORDERS: OrderReducer,
    PRODUCTS: ProductsReducer,
    FOOD_PRODUCTS: FoodProductsReducer,
    REFUNDS: RefundsReducer,
    FORGOT_PASSWORD: ForgotPassword,
    COMBO_OFFER: ComboOfferReducer,
    FREE_ITEMS: freeItemList,
    DOLLAR_OFFER: DollarReducers,
    OFFER_LIST: OfferReducer,
    MULTI_BUY: multiBuyReducer,
    CUSTOMER_COUPON: customerCouponReducer,
    HAPPY_HOURS_ITEM: happyHoursItem,
    CHATS: ChatsReducer,
    NAV_BAR: NavigationDrawerReducer,
    STORE_LIST: StoreReducer,
    DASHBOARD_OFFER_LIST: DahboardOfferReducer,
    GET_DASHBOARD_DETAILS: GetDashboardDetailsReducer,
    REPORT_TYPE_LIST: ReportTypeListReducers,
    INACTIVE_USERS: CustomerUnBlockingReducer,
    NOTIFICATIONS: NotificationsReducer,
    SUBSCRIPTION: SubscriptionReducer,
    GET_DAXBOT: GetDaxbotReducers,
    BISTRO_PRODUCT_LIST: BistroProductListReducer,
    INVOICE_DETAILS: InvoiceDetailsReducers,
    PRODUCT_GROUP_INFORMATIONS: ProductGroupInformationReducers,
    BISTRO_DISPLAY_REDUCER: BistroDisplayReducer,
    CHANGE_PASSWORD: changePasswordReducers,
    INVOICE_LIST: InvoiceListReducer,
    HITACHI_MONITORING: HitachiMonitoring,
    QR_CODE_SCANNER: QrCodeReducers,
    STORE_AVAILABILITY: StoreAvailability,
    BRANCH_LIST_REDUCER: BranchListReducer,
    PLANOGRAM: Planogram,
    PLANOGRAM_DETAIL: PlanogramDetail,
    PLANOGRAM_PRODUCT_LIST: PlanogramProduct,
    GET_CAMERA_LIST: CameraListsReducer,
    GET_CV_PLANOGRAM_DETAILS: getCvPlanogramDetails,
    RACK_CAPTURE: rackCaptureReducer,
    AUTOFILL_REDUCER: autoFillReducer,
    UPDATE_PLANOGRAM_PRODUCT: updatePlanogramProductReducer,
    SAVE_BAY_PRODUCTLIST :BaySaveProductListReducer
});


const rootReducer = (routeHistory) => (state, action) => {
    if (action.type === Actions.LOGOUT) {
        localStorage.clear();
    }
    return appReducer(routeHistory)(state, action)
}


export default rootReducer;