import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import React, { useRef, useState } from 'react'
import Close from '../../../asset/new/img/modal-close.svg'
import AssignedStore from '../../components/fields/AssignedStore'
import BoldError from '../../components/fields/BoldError'
import PasswordCheckPopover from '../../components/fields/PasswordCheckPopover';
import Constants from '../../utils/Constants';


export const BranchModal = (props) => {
    const { value, setState, isCreate, manageUserList, validationError,
        setEditMemberDetails, initialState, selectedStore, setSelectedStore, password, setPassword,
    } = props
    const handleChange = (e) => {
        setState({ ...value, [e.target.name]: e.target.value })
    }
    const inputRef = useRef()
    const [showPassword, setShowPassword] = useState(false)
    const [showPopover, setShowPopover] = useState(false)
    const handleCancel = () => {
        props.setShow(false)
        setEditMemberDetails(false)
        setState(initialState)
        setSelectedStore([])
        setPassword('')
    }

    const handleStoreIds = (val) => {
        if (Array.isArray(val)) {
            setSelectedStore(val)
        } else {
            setSelectedStore([val])
        }
    }
    const handlePassword = (e) => {
        setPassword(e.target.value)
    }
    return (
        <>
            <div className="modal d-block"
                id="add-multi-modal"
                tabIndex="-1"
                aria-labelledby="unblock"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content rounded-0">
                        <img id="close" src={Close} alt="X" data-bs-dismiss="modal"
                            aria-label="Close" className="modal-close" onClick={() => handleCancel()} />
                        <div className="modal-header rounded-0 align-items-center py-4">
                            <div className="col">
                                <h5 className="modal-title">
                                    {isCreate ? 'Add Member' : 'Edit Member'}</h5>
                            </div>
                        </div>
                        <div className="modal-body font13 p-40" >
                            {validationError && <BoldError message={validationError} />}
                            {
                                <div className="row mb-4">
                                    <div className="col-6">
                                        <label for="category" className="form-label fw-bold">First Name</label>
                                        <div className="input-group">
                                            <input id='onInput' type='text' name='firstName' value={value.firstName} className='form-control'
                                                placeholder="Enter Your First Name" onChange={handleChange}
                                                pattern='[A-Za-z\\s]*'
                                                onInput={(e) => {
                                                    e.target.value = e.target.value?.replace(/[^\w\s]/gi, "")?.replace(/[^a-z]/gi, '').slice(0, 30)
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <label for="category" className="form-label fw-bold">Last Name</label>
                                        <div className="input-group">

                                            <input type='text' name='lastName' value={value.lastName} className='form-control'
                                                placeholder="Enter Your Last Name" onChange={handleChange}
                                                pattern='[A-Za-z\\s]*'
                                                onInput={(e) => {
                                                    e.target.value = e.target.value?.replace(/[^\w\s]/gi, "")?.replace(/[^a-z]/gi, '')?.trim()?.slice(0, 30)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>}
                            <div className="row mb-4">
                                {isCreate && <div className={isCreate ? "col-6" : "col-12"}>
                                    <label for="offer-type" className="form-label fw-bold">Email</label>
                                    <div className="input-group">
                                        <input id='emailId' type='email' name='emailId' value={value.emailId} className='form-control'
                                            placeholder="Enter Your Email" onChange={handleChange}
                                            onInput={(e) => {
                                                e.target.value = e.target.value?.trim().slice(0, 30)
                                            }}
                                        />
                                    </div>
                                </div>}
                                {isCreate && <div className="col-6">
                                    <label for="" className="form-label fw-bold">Password</label>
                                    <div className="input-group">
                                        {showPopover && <PasswordCheckPopover value={password} />}
                                        <input id='password' ref={inputRef} type={showPassword ? 'text' : 'password'} name='password' autoComplete={false} value={password} className="form-control"
                                            placeholder="Enter Your Password " onFocus={() => setShowPopover(true)}
                                            onBlur={() => setShowPopover(false)} onChange={(e) => handlePassword(e)}
                                        />
                                        <button className="btn border-dark"
                                            onClick={() => setShowPassword(!showPassword)}
                                            type="button"
                                            id="view-pass">
                                            <FontAwesomeIcon
                                                style={{ cursor: 'pointer' }}
                                                icon={showPassword ? faEye : faEyeSlash} />
                                        </button>
                                    </div>
                                </div>
                                }
                            </div>
                            <div className="row mb-4">
                                <div className="col-6">
                                    <label className="form-label fw-bold">Employee ID</label>
                                    <div className="input-group">
                                        <input id="Employee" type="text" name='employeeId' value={value.employeeId} className="form-control"
                                            placeholder="Enter Employee Id " onChange={handleChange}
                                            onInput={(e) => {
                                                e.target.value = e.target.value?.replace(/[^\w\s]/gi, "")?.trim()?.toString()?.slice(0, 10)
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="col-6">
                                    <label for="to-date" className="form-label fw-bold">Select Role</label>
                                    <select
                                        id="role-id"
                                        name="roleId"
                                        value={value?.roleId}
                                        className="form-select"
                                        onChange={handleChange}
                                    >
                                        <option value="">Select Role</option>
                                        <option value={0}>Admin</option>
                                        <option value={1}>Store Manager</option>
                                        <option value={2}>Bistro Associate</option>
                                        <option value={3}>Store Associate</option>
                                        <option value={11}>Back Kitchen</option>
                                        <option value={12}>Bistro Display</option>
                                        <option value={13}>Front Kitchen</option>
                                    </select>
                                </div>
                                {value?.roleId == Constants.ROLES.STORE_MANAGER && <div className="d-flex col-6 mt-4">
                                    <label for="product-list" className="form-label fw-bold">PO Approve :</label>
                                    <div className="form-check form-switch mx-3">
                                        <input className="form-check-input" name='poApprover' onChange={(e)=>setState({ ...value, [e.target.name]: e.target.checked })}
                                        checked={value?.poApprover} type="checkbox" role="switch" id="switch" />
                                    </div>
                                </div>}
                            </div>

                            {(String(value?.roleId) !== '0') ? (<div className="row mb-4">
                                <div className="row mb-6">
                                    <div className="col">
                                        <label for="product-list" className="form-label fw-bold">Select Stores</label>
                                        <AssignedStore
                                            id="assignedStore"
                                            style={{ margin: '10px' }}
                                            placeholder="Select Store"
                                            value={selectedStore}
                                            isMulti={(value?.roleId == 11 || value?.roleId == 12 || value?.roleId == 13) ? false : true}
                                            onChange={(val) => handleStoreIds(val)}
                                        />
                                    </div>
                                </div>
                            </div>
                            ) : null}
                            <div className="row mb-4">
                                <div className="col-6 pe-0"> <button className="btn btn-success font14 w-100 p-3 "
                                    id="manageClick" onClick={() => manageUserList(isCreate)}>{isCreate ? 'Create' : 'Update'}</button> </div>
                                <div className="col-6 text-end">
                                    <button id="openClick" className="btn btn-outline-danger font14 w-100 p-3" onClick={() => handleCancel()}>
                                        Cancel</button> </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop show"></div>
        </>
    )
}
