import { all, put, takeLatest } from "redux-saga/effects";
import { doAuthGet, doAuthPut } from "../../utils/service";
import END_POINTS from "../../utils/service/Endpoints";
import Actions from "../actions";

export function* getAllRefundsListsSaga({ params, callback }) {
    try {
        const response = yield doAuthGet(END_POINTS.GET_ALL_REFUND_API, params);
        if (response && response.response_code === 0) {
            yield put(Actions.getAllRefundsListsSuccessAction(response));
        }
        callback && callback(response);
    }
    catch (error) {
        callback && callback(error);
        yield put(Actions.getAllRefundsListsFailureAction({ error }));
    }
}

export function* getRefundsDetailsSaga({ params, callback }) {
    try {
        const response = yield doAuthGet(END_POINTS.GET_REFUND_DETAILS_API, params);
        yield put(Actions.getRefundDetailsSuccessAction(response))
        callback && callback(response);
    }
    catch (error) {
        callback && callback(error);
        yield put(Actions.getAllRefundDetailsFailureAction({ error }))
    }
}

export function* updateRefundStatusSaga({ params, callback }) {
    try {
        const response = yield doAuthPut(END_POINTS.PARTIAL_REFUND, params);
        callback && callback(response);
    }
    catch (error) {
        callback && callback(error);
    }
}

export function* getSocketRefundList({ params, callback }) {
    try {
        let response = yield doAuthGet(END_POINTS.GET_REFUND_DETAILS_API, params)
        if (response?.response_code === 0) {
            callback && callback(response);
        }
    } catch (error) {
        callback && callback({ error });
    }
}

export default function* refundsListener() {
    yield all([
        takeLatest(Actions.GET_REFUNDS, getAllRefundsListsSaga),
        takeLatest(Actions.GET_REFUND_DETAILS, getRefundsDetailsSaga),
        takeLatest(Actions.UPDATE_REFUND_STATUS, updateRefundStatusSaga),
        takeLatest(Actions.GET_SOCKET_REFUND_LIST, getSocketRefundList)
    ]);
}
