import { doAction, doResponseAction } from ".";

const PLANOGRAM = 'PLANOGRAM';
const PLANOGRAM_SUCCESS = 'PLANOGRAM_SUCCESS';
const PLANOGRAM_FAILURE = 'PLANOGRAM_FAILURE';

const PLANOGRAM_ADD_RACK = 'PLANOGRAM_ADD_RACK';

const PLANOGRAM_DETAIL = 'PLANOGRAM_DETAIL';
const PLANOGRAM_DETAIL_SUCCESS = 'PLANOGRAM_DETAIL_SUCCESS';
const PLANOGRAM_DETAIL_FAILURE = 'PLANOGRAM_DETAIL_FAILURE';

const PLANOGRAM_ADD_BAY = 'PLANOGRAM_ADD_BAY';

const PLANOGRAM_PRODUCT_LIST = 'PLANOGRAM_PRODUCT_LIST';
const PLANOGRAM_PRODUCT_LIST_SUCCESS = 'PLANOGRAM_PRODUCT_LIST_SUCCESS';
const PLANOGRAM_PRODUCT_LIST_FAILURE = 'PLANOGRAM_PRODUCT_LIST_FAILURE';

const PLANOGRAM_ADD_PRODUCT_TO_BAY = 'PLANOGRAM_ADD_PRODUCT_TO_BAY';
const PLANOGRAM_REMOVE_PRODUCT_TO_BAY = 'PLANOGRAM_REMOVE_PRODUCT_TO_BAY';

const PLANOGRAM_ADD_PRODUCT_TO_SHELVES = 'PLANOGRAM_ADD_PRODUCT_TO_SHELVES';

const PLANOGRAM_UPDATE_RACK = 'PLANOGRAM_UPDATE_RACK'

export {
    PLANOGRAM,
    PLANOGRAM_SUCCESS,
    PLANOGRAM_FAILURE,
    PLANOGRAM_ADD_RACK,
    PLANOGRAM_DETAIL,
    PLANOGRAM_DETAIL_SUCCESS,
    PLANOGRAM_DETAIL_FAILURE,
    PLANOGRAM_ADD_BAY,
    PLANOGRAM_PRODUCT_LIST,
    PLANOGRAM_PRODUCT_LIST_SUCCESS,
    PLANOGRAM_PRODUCT_LIST_FAILURE,
    PLANOGRAM_ADD_PRODUCT_TO_BAY,
    PLANOGRAM_ADD_PRODUCT_TO_SHELVES,
    PLANOGRAM_REMOVE_PRODUCT_TO_BAY,
    PLANOGRAM_UPDATE_RACK,
}

export const getPlanogramAction = (params, callback) => doAction(PLANOGRAM, params, callback);

export const getPlanogramSuccessAction = data => doResponseAction(PLANOGRAM_SUCCESS, data);
export const getPlanogramFailureAction = data => doResponseAction(PLANOGRAM_FAILURE, data);


export const savePlanogramRackAction = (params, callback) => doAction(PLANOGRAM_ADD_RACK, params, callback);

export const getPlanogramDetail = (params, callback) => doAction(PLANOGRAM_DETAIL, params, callback);

export const getPlanogramDetailSuccess = data => doResponseAction(PLANOGRAM_DETAIL_SUCCESS, data);
export const getPlanogramDetailFailure = data => doResponseAction(PLANOGRAM_DETAIL_FAILURE, data);

export const savePlanogramBayAction = (params, callback) => doAction(PLANOGRAM_ADD_BAY, params, callback);

export const getPlanogramProductList = (params, callback) => doAction(PLANOGRAM_PRODUCT_LIST, params, callback);

export const getPlanogramProductListSuccess = data => doResponseAction(PLANOGRAM_PRODUCT_LIST_SUCCESS, data);
export const getPlanogramProductListFailure = data => doResponseAction(PLANOGRAM_PRODUCT_LIST_FAILURE, data);

export const assignProductToBay = (params, callback) => doAction(PLANOGRAM_ADD_PRODUCT_TO_BAY, params, callback);

export const assignProductToShelve = (params, callback) => doAction(PLANOGRAM_ADD_PRODUCT_TO_SHELVES, params, callback);

export const unAssignProductToBay = (params, callback) => doAction(PLANOGRAM_REMOVE_PRODUCT_TO_BAY, params, callback);

export const updatePlanogramRackAction = (params, callback) => doAction(PLANOGRAM_UPDATE_RACK, params, callback);
