import DashboardScreen from "../../containers/dashboard/DashboardScreen";
import LoginScreen from "../../containers/login/LoginScreen";
import ProductsScreen from "../../containers/manage/products/Products";
import OrderDetails from "../../containers/order/OrderDetails";
import OrderScreen from "../../containers/order/OrderScreen";
import RoutePath from "./RoutePath";
import Freeitems from "../../containers/promotion/freeItems/FreeItems";
import ComboOffer from "../../containers/promotion/comboOffer/ComboOffer";
import Customercoupon from "../../containers/promotion/customerCoupon/CustomerCouponList";
import HappyHoursItem from "../../containers/promotion/happyHours/HappyHoursList";
import RefundScreen from "../../containers/refund/RefundScreen";
import MultiBuy from "../../containers/promotion/multiBuy/MultiBuy";
import Dollar from "../../containers/promotion/dollarOffer/DollarOffer";
import ChatsScreen from "../../containers/chats/ChatsScreen";
import PromotionDashboard from "../../containers/promotion/dashboard/PromotionDashboard";
import ReportScreen from '../../containers/report/ReportScreen'
import CustomerUnBlocking from '../../containers/customerUnBlocking/CustomerUnBlocking'
import ManagePlanogram from "../../containers/planogram/ManagePlanogram";
import PlanogramDetail from "../../containers/planogram/PlanogramDetail";
import NotificationScreen from "../../containers/notification/NotificationScreen";
import DaxbotStatus from "../../containers/daxbotStaus/daxbotStatus";
import RefundDetails from "../../containers/refund/RefundDetails";
import MapView from "../../containers/daxbotStaus/mapViewModal";
import BistroChef from "../../containers/bistroChef";
import BistroDisplay from "../../containers/bistroDisplay";
import ChangePassword from "../../containers/changePassword/changePassword";
import InvoiceList from "../../containers/invoiceList/InvoiceList";
import InvoiceDetails from "../../containers/invoiceList/InvoiceDetails";
import Hitachi from "../../containers/monitoring/Hitachi";
import BranchDetails from "../../containers/Branches/BranchDetails";
import StoreAvailabilityScreen from "../../containers/StoreAvailability/StoreAvailability";
import PlanogramReport from "../../containers/planogram/PlanogramReport";

const ROUTES = [
    {
        screenName: 'Login',
        path: RoutePath.LOGIN_SCREEN_PATH,
        component: LoginScreen,
        exact: true,
        isAuthenticated: false,
    },
    {
        screenName: 'Home',
        path: RoutePath.DASHBOARD_SCREEN_PATH,
        component: DashboardScreen,
        exact: true,
        isAuthenticated: true,
    },

    {
        screenName: 'BistroOrders',
        path: RoutePath.BISTRO_ORDERS,
        component: BistroChef,
        exact: true,
        isAuthenticated: true,
        isExcludeBistro: true,
        isExcludedSM: true
    },
    {
        screenName: 'BistroCustomerOrders',
        path: RoutePath.BISTRO_CUSTOMER_ORDERS,
        component: BistroDisplay,
        exact: true,
        isAuthenticated: true,
        isExcludeBistro: true,
        isExcludedSM: true
    },
    {
        screenName: 'Orders',
        path: RoutePath.ORDERS_PATH,
        component: OrderScreen,
        exact: true,
        isAuthenticated: true,
    },
    {
        screenName: 'Order Details',
        path: RoutePath.ORDERS_DETAILS,
        component: OrderDetails,
        exact: true,
        isAuthenticated: true,
    },
    {
        screenName: 'Refund',
        path: RoutePath.REFUND_SCREEN_PATH,
        component: RefundScreen,
        exact: true,
        isAuthenticated: true,
        isExcludeBistro: true
    },
    {
        screenName: 'Reports',
        path: RoutePath.REPORT,
        component: ReportScreen,
        exact: true,
        isAuthenticated: true,
        isExcludeBistro: true
    },
    {
        screenName: 'Planogram',
        path: RoutePath.PLANOGRAM,
        component: ManagePlanogram,
        exact: true,
        isAuthenticated: true,
        isExcludeBistro: true
    },
    {
        screenName: 'PlanogramDetail',
        path: RoutePath.PLANOGRAM_VIEW,
        component: PlanogramDetail,
        exact: true,
        isAuthenticated: true,
        isExcludeBistro: true
    },
    {
        screenName: 'Daxbot status',
        path: RoutePath.DAXBOT_STATUS,
        component: DaxbotStatus,
        exact: true,
        isAuthenticated: true,
        isExcludeBistro: true
    },
    {
        screenName: 'Map view',
        path: RoutePath.VIEW_MAP,
        component: MapView,
        exact: true,
        isAuthenticated: true,
        isExcludeBistro: true
    },
    {
        screenName: 'Products screen',
        path: RoutePath.MANAGE_PRODUCTS,
        component: ProductsScreen,
        exact: true,
        isAuthenticated: true,
        isExcludeBistro: true
    },
    {
        screenName: 'Dashboard',
        path: RoutePath.PROMOTION_DASHBOARD,
        component: PromotionDashboard,
        isAuthenticated: true,
        isExcludeBistro: true
    },
    {
        screenName: 'Customer Coupon',
        path: RoutePath.CUSTOMER_COUPON,
        component: Customercoupon,
        exact: true,
        isAuthenticated: true,
        isExcludeBistro: true
    },
    {
        screenName: 'Free Items',
        path: RoutePath.FREE_ITEMS,
        component: Freeitems,
        exact: true,
        isAuthenticated: true,
        isExcludeBistro: true
    },
    {
        screenName: 'Happy Hours',
        path: RoutePath.HAPPY_HOURS_ITEM,
        component: HappyHoursItem,
        exact: true,
        isAuthenticated: true,
        isExcludeBistro: true
    },
    {
        screenName: 'Combo offer',
        path: RoutePath.COMBO_OFFER,
        component: ComboOffer,
        exact: true,
        isAuthenticated: true,
        isExcludeBistro: true
    },
    {
        screenName: 'Multi Buy',
        path: RoutePath.MULTI_BUY,
        component: MultiBuy,
        exact: true,
        isAuthenticated: true,
        isExcludeBistro: true
    },
    {
        screenName: 'Dollar Offers',
        path: RoutePath.DOLLAR,
        component: Dollar,
        exact: true,
        isAuthenticated: true,
        isExcludeBistro: true
    },
    {
        screenName: 'Chats',
        path: RoutePath.CHATS_SCREEN,
        component: ChatsScreen,
        exact: true,
        isAuthenticated: true,
    },
    {
        screenName: 'Customer Management',
        path: RoutePath.CUSTOMER_UNBLOCKING,
        component: CustomerUnBlocking,
        exact: true,
        isAuthenticated: true,
        isExcludeBistro: true
    }, {
        screenName: 'Notification',
        path: RoutePath.NOTIFICATION,
        component: NotificationScreen,
        exact: true,
        isAuthenticated: true,
        isExcludeBistro: true
    },
    {
        screenName: 'Refund Details',
        path: RoutePath.REFUND_DETAILS,
        component: RefundDetails,
        exact: true,
        isAuthenticated: true,
        isExcludeBistro: true
    }, {
        screenName: 'Settings',
        path: RoutePath.CHANGE_PASSWORD,
        component: ChangePassword,
        exact: true,
        isAuthenticated: true,
    },
    {
        screenName: 'Invoices',
        path: RoutePath.INVOICE_LIST,
        component: InvoiceList,
        exact: true,
        isAuthenticated: true,
    },
    {
        screenName: 'InvoicesDetails',
        path: RoutePath.INVOICE_DETAILS,
        component: InvoiceDetails,
        exact: true,
        isAuthenticated: true,
    },
    {
        screenName: 'HitachiMonitoring',
        path: RoutePath.HITACHI_MONITORING,
        component: Hitachi,
        exact: true,
        isAuthenticated: true,
        isExcludeBistro: true
    },
    {
        screenName: 'Storeavailability',
        path: RoutePath.STORE_AVAILABILITY,
        component: StoreAvailabilityScreen,
        exact: true,
        isAuthenticated: true,
        isExcludeBistro: true
    },
    {
        screenName: 'Branches',
        path: RoutePath.MEMBERS,
        component: BranchDetails,
        exact: true,
        isAuthenticated: true,
        isExcludeBistro: true,
        isExcludedSM: true
    },
    {
        screenName: 'Branches',
        path: RoutePath.PLANOGRAM_REPORT,
        component: PlanogramReport,
        exact: true,
        isAuthenticated: true,
        isExcludeBistro: true,
    },
];

export default ROUTES;