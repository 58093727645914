import Actions from "../actions";

const initialState = {
    isLoading: false,
    inactiveUsersList: [],
    error: false,
    createCustomerUnBlockList: [],
    customerUnBlockingError: false,
    customerUnBlockingLoading: false,
    totalCount: undefined
}

const CustomerUnBlockingReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.INACTIVE_USERS: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.INACTIVE_USERS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                inactiveUsersList: action?.data?.content || [],
                totalCount: action.data.totalCount,
                error: false
            };

        }

        case Actions.INACTIVE_USERS_FAILURE: {
            return {
                ...state,
                isLoading: false,
                inactiveUsersList: [],
                error: action.error,
            };
        }
        case Actions.CUSTOMER_UNBLOCKING_CREATE: {
            return {
                ...state,
                customerUnBlockingLoading: true,
            };
        }
        case Actions.CUSTOMER_UNBLOCKING_CREATE_SUCCESS: {
            return {
                ...state,
                customerUnBlockingLoading: false,
                createCustomerUnBlockList: action?.data?.response || [],
                customerUnBlockingError: false
            };

        }

        case Actions.CUSTOMER_UNBLOCKING_CREATE_FAILURE: {
            return {
                ...state,
                customerUnBlockingLoading: false,
                createCustomerUnBlockList: [],
                customerUnBlockingError: action.data,
            };
        }

        default:
            return state;
    }
};

export default CustomerUnBlockingReducer;

export const getCustomerUnBlockListSelector = state => state?.INACTIVE_USERS?.inactiveUsersList;
export const getCustomerUnBlockCountSelector = state => state?.INACTIVE_USERS?.totalCount;
export const getCustomerUnBlockListLoadingSelector = state => state?.INACTIVE_USERS?.isLoading;