
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const PROMOTION_BASE_URL = process.env.REACT_APP_PROMOTION_BASE_URL
export const BASE_URL_SOCKET_IO = process.env.REACT_APP_SOCKET_URL;
export const SOCKET_IO_PATH = process.env.REACT_APP_SOKENT_PATH;
export const MAP_API_KEY = 'AIzaSyCq4bnWtoKb5qVePwcBwxb6kglg_5J6shc';
export const PLANOGRAM_IFRAME_URL = 'https://shop-planogram.proglint.com/frame';
export const PLANOGRAM_IFRAME_STORE_URL = 'https://shop-planogram.proglint.com/store';
export const CV_BASE_URL = "http://192.168.2.167:8662";

const END_POINTS = {
    CLIENT_CREDENTIALS: '/oauth/token',
    LOGIN_API: '/api/signin',
    SIGN_OUT_API: '/api/signout',
    GET_ALL_ORDERS_API: '/resource/storemanager/orderDetails/all',
    GET_ALL_INVOICE_API: '/resource/storemanager/invoiceList',
    GET_ORDER_DETAILS_BY_ID_API: '/resource/storemanager/orderDetails',
    UPDATE_ORDER_DETAILS_API: '/resource/storemanager/orderDetails',
    GET_ALL_PRODUCTS_API: '/resource/storemanager/product/all',
    GET_ALL_REFUND_API: '/resource/storemanager/refund/all',
    UPDATE_REFUND_API: '/resource/storemanager/refund',
    GET_REFUND_DETAILS_API: '/resource/storemanager/refund',
    FORGET_PASSWORD: '/forgotPassword/emailId/otp',
    FORGOT_PASSWORD_VERIFY_OTP: '/forgotPassword/verify',
    UPDATE_FORGOT_PASSWORD: '/user/forgot/password',
    COMBO_OFFERS_LIST: '/combo-offer/list',
    CREATE_COMBO_OFFERS_LIST: '/combo-offer/create',
    UPDATE_COMBO_OFFERS_LIST: '/combo-offer/update',
    PRODUCT_LIST: '/resource/product/pim/product/stores',
    CREATE_PRODUCT: '/product/create',
    UPDATE_PRODUCT: '/product/update',
    FREE_ITEMS: '/free-items/list',
    DOLLAR_OFFER_LIST: '/dollar-off/list',
    CREATE_DOLLAR_OFFER: "/dollar-off/create",
    UPDATE_DOLLAR_OFFER: "/dollar-off/update",
    OFFER_LIST: "/offer/list",
    STORE_AVAILABILITY: 'resource/storemanager/store/available/time',
    UPDATE_AVAILABILITY: 'resource/storemanager/store/time/update',

    INVOICE_DETAIL: 'resource/storemanager/invoiceDetails',
    MULTI_BUY_LIST: '/multi-buy/list',
    CREATE_MULTI_BUY_LIST: '/multi-buy/create',
    UPDATE_MULTI_BUY_LIST: '/multi-buy/update',
    BISTRO_PRODUCT_LIST: 'resource/product/pim/bistro/product/stores',
    CHANGE_PASSWORD: 'user/password',
    // DELETE_MULTI_BUY_LIST: '/multi-buy/',
    CREATE_FREE_ITEMS_LIST: '/free-items/create',
    UPDATE_FREE_ITEMS_LIST: '/free-items/update',
    DELETE_FREE_ITEMS_LIST: '/free-items/',
    // DELETE_COMBO_OFFER_API: '/combo-offer/',
    COUPON_LIST: '/customer-coupon/list',
    PRODUCT_DISCOUNTS: '/product-discount/list',
    CREATE_PRODUCT_DISCOUNT: '/product-discount/create',
    UPDATE_PRODUCT_DISCOUNT: '/product-discount/update',
    // DELETE_PRODUCT_DISCOUNT: "/product-discount/",
    HAPPY_HOURS_ITEM: '/happy-hours/list',
    CREATE_HAPPY_HOURS_ITEM: '/happy-hours/create',
    UPDATE_HAPPY_HOURS_ITEM: '/happy-hours/update',
    // DELETE_HAPPY_HOURS_ITEM: '/happy-hours/',
    CUSTOMER_LIST: '/customer/list',
    CUSTOMER_COUPON_CREATE: '/customer-coupon/create',
    CUSTOMER_COUPON_UPDATE: '/customer-coupon/update',
    DELETE_CUSTOMER_COUPON: '/customer-coupon/',
    COMBO_OFFER_DETAIL: '/combo-offer/',
    GET_ALL_CHATS: '/resource/chat/chatlist',
    GET_CHAT_DETAILS: '/resource/chat/chatDetails',
    SEND_CHAT_REPLY: '/resource/chat/reply',
    STORE_LIST: '/resource/storemanager/store/list',
    UPDATE_CUSTOMER_COUNT_API: '/resource/storemanager/store/update',
    UPDATE_STORE_OPEN_CLOSE_API: '/resource/storemanager/store/update',
    PROMOTION_DASHBOARD_OFFER_LIST: '/offer/count/list',
    GET_DAXBOT: 'resource/storemanager/order/botStatus',
    TRACK_DAXBOT: 'resource/daxbot/delivery/',
    SALES_REPORT: 'resource/storemanager/sales/report',
    REPORT_TYPE_LIST: '/sm-report/report-types',
    DOWNLOAD_REPORT: '/sm-report/report',
    INACTIVE_CUSTOMER_LIST: '/resource/storemanager/inactiveUsers',
    UNBLOCKING_USER: '/resource/storemanager/unblockUser',
    GET_NOTIFICATION_LIST: '/resource/storemanager/notification/get',
    RESOLVE_NOTIFICATION: '/resource/storemanager/notification/resolve',
    GET_NOTIFICATION_BY_ID: '/resource/storemanager/notificationDetails',
    GET_SUBSCRIPTION_LIST: '/resource/storemanager/subscription/List',
    CREATE_SUBSCRIPTION_LIST: '/resource/storemanager/subscription/save',
    PRODUCT_GROUP_INFORMATION: "resource/product/pim/product-group/information",
    BISTRO_DISPLAY_CHEF_LIST: "/resource/storemanager/chef-list",
    BISTRO_DISPLAY_CUSTOMER_LIST: "/resource/storemanager/order-status/customer",
    CHEF_COMPLETE_ORDER: '/resource/storemanager/food-prepared/chef',
    MANAUAL_REFUND: '/resource/storemanager/manual-refund',
    HITACHI_MONITORING_LIST: '/resource/storemanager/wiwo-session/all',
    HITACHI_MONITORING_ACTIVE_LIST: '/resource/storemanager/wiwo-session/active',
    QR_CODE_SCANNER: '/resource/storemanager/check-qrcode/order/deliver',
    PARTIAL_ORDER: 'resource/storemanager/partial/orderDetails',
    PARTIAL_REFUND: 'resource/storemanager/partial/refund-accept',

    //MemberList
    GET_ADMIN_USERS: 'resource/storemanager/get/adminUser',
    ADD_ADMIN_USER: 'resource/multiadmin/add/adminUser',
    UPDATE_ADMIN_USER: 'resource/multiadmin/update/adminUser/storeId',
    UPDATE_ADMIN_ACTIVE_STATUS: 'resource/multiadmin/update/adminUser',

    GET_ADMIN_STORE_LIST: 'resource/storemanager/get/storeList',
    UPDATE_ADMIN_PASSWORD: 'resource/multiadmin/update/admin/password',
    //Planogram
    PLANOGRAM_DETAIL: '/resource/planogram/view',
    PLANOGRAM_ADD_RACK: '/resource/planogram/add-rack',
    VIEW_RACK: '/resource/planogram/view-rack',
    PLANOGRAM_ADD_BAY: '/resource/planogram/save/bay-info',
    PLANOGRAM_PRODUCT_LIST: '/resource/planogram/pim/product/stores',
    PLANOGRAM_PRODUCT_ASSIGN: '/resource/planogram/assign/product',
    PLANOGRAM_PRODUCT_ASSIGN_TO_SHELVE: '/resource/planogram/assign/product/shelve',
    PLANOGRAM_PRODUCT_UNASSIGN: '/resource/planogram/product/remove',
    PLANOGRAM_UPDATE_RACK: '/resource/planogram/update-rack',
    //CV Planogram
    GET_CAMERA_LIST: "/resource/planogram/camera/list",
    GET_PLANOGRAM_DETAILS: "/resource/planogram/camera/details",
    ASSIGN_RACK_ID: "/resource/planogram/rack/assign",
    RACK_AUTOFILL: "/resource/planogram/rack/autofill",
    UPDATE_PLANOGRAM: "/resource/planogram/update/planogram",
    GET_PRODUCT_DETAILS: "/resource/planogram/cvproduct/details",
    PLANOGRAM_ANALYTICS: '/resource/planogram/get/analytics'

};

export default END_POINTS;

export const ManageMultiBuyURL = (id, active) => {
    return `/multi-buy/${id}/updateActive?active=${active}`
}
export const ManageCustomerCouponURL = (id, active) => {
    return `/customer-coupon/${id}/updateActive?active=${active}`
}
export const ManageFreeItemURL = (id, active) => {
    return `/free-items/${id}/updateActive?active=${active}`
}
export const ManageProductDiscountURL = (id, active) => {
    return `/product-discount/${id}/updateActive?active=${active}`
}
export const ManageHappyHoursURL = (id, active) => {
    return `/happy-hours/${id}/updateActive?active=${active}`
}
export const ManageDollarOfferURL = (id, active) => {
    return `/dollar-off/${id}/updateActive?active=${active}`
}
export const ManageComboOfferURL = (id, active) => {
    return `/combo-offer/${id}/updateActive?active=${active}`
}
