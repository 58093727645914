import { all, put, takeLatest } from "redux-saga/effects";
import { doPromotionGet, doPromotionPost } from "../../utils/service";
import END_POINTS from "../../utils/service/Endpoints";
import Actions from "../actions";

export function* DollarOfferList({ params }) {
    try {
        const response = yield doPromotionGet(END_POINTS.DOLLAR_OFFER_LIST, params);
        if (response) {
            yield put(Actions.getDollarOfferListSuccessAction(response));
        }
    }
    catch (error) {
        yield put(Actions.getDollarListFailureAction({ error }));
    }
}
export function* createDollarOffer({ params, callback }) {
    try {
        const response = yield doPromotionPost(END_POINTS.CREATE_DOLLAR_OFFER, params);
        if (response) {
            yield put(Actions.createDollarOfferActionSuccess(response));
        }
        callback && callback(response);
    }
    catch (error) {
        callback && callback(error);
        yield put(Actions.createDollarOfferActionFailure({ error }));
    }
}
export function* updateDollarOffer({ params, callback }) {
    try {
        const response = yield doPromotionPost(END_POINTS.UPDATE_DOLLAR_OFFER, params);
        if (response) {
            yield put(Actions.updateDollarOfferActionSuccess(response));
        }
        callback && callback(response);
    }
    catch (error) {
        callback && callback(error);
        yield put(Actions.updateDollarOfferActionFailure({ error }));
    }
}
export function* deleteDollarOffer({ params, callback }) {
    try {
        const response = yield doPromotionPost(params);
        if (response) {
            yield put(Actions.deleteDollarOfferActionSuccess(response));
        }
        callback && callback(response);
    }
    catch (error) {
        callback && callback(error);
        yield put(Actions.deleteDollarOfferActionFailure({ error }));
    }
}
export default function* DollarOfferListener() {
    yield all([
        takeLatest(Actions.DOLLAR_OFFER_LIST, DollarOfferList),
        takeLatest(Actions.CREATE_DOLLAR_OFFER, createDollarOffer),
        takeLatest(Actions.UPDATE_DOLLAR_OFFER, updateDollarOffer),
        takeLatest(Actions.DELETE_DOLLAR_OFFER, deleteDollarOffer),

    ]);
}