import { doAction, doResponseAction } from ".";

const OFFER_LIST = 'OFFER_LIST';
const OFFER_LIST_SUCCESS = 'OFFER_LIST_SUCCESS';
const OFFER_LIST_FAILURE = 'OFFER_LIST_FAILURE'

export {
    OFFER_LIST,
    OFFER_LIST_SUCCESS,
    OFFER_LIST_FAILURE,
}

export const  getOfferListAction  = (params, callback) => doAction(OFFER_LIST, params, callback);
export const getOfferListActionSuccess= (data) => doResponseAction(OFFER_LIST_SUCCESS, data);
export const getOfferActionFailure = (data) => doResponseAction(OFFER_LIST_FAILURE, data);
