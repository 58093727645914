import Actions from "../actions";

const initialState = {
    isLoading: false,
    customerCouponList: [],
    customerList: [],
    deleteCustomersList: [],
    error: false,
    createLoading: false,
    updateLoading: false,
    createError: false,
    updateError: false,
    // deleteLoading: false,
    deleteError: false,
    listLoading: false,
    listError: false,
    totalCount: undefined
}
const CustomerCouponReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.CUSTOMER_COUPON: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.CUSTOMER_COUPON_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                customerCouponList: action?.data?.response || [],
                totalCount: action.data?.totalPages,
                error: false
            };
        }
        case Actions.CUSTOMER_COUPON_FAILURE: {
            return {
                ...state,
                customerCouponList: [],
                isLoading: false
            };
        }

        case Actions.CREATE_CUSTOMER_COUPON: {
            return {
                ...state,
                createLoading: true,
            }
        }

        case Actions.CREATE_CUSTOMER_COUPON_SUCCESS: {
            return {
                ...state,
                createLoading: false,
                createError: false
            }
        }

        case Actions.CREATE_CUSTOMER_COUPON_FAILURE: {
            return {
                ...state,
                createLoading: false,
                createError: action.data
            }
        }

        case Actions.UPDATE_CUSTOMER_COUPON: {
            return {
                ...state,
                updateLoading: true,
            }
        }
        case Actions.UPDATE_CUSTOMER_COUPON_SUCCESS: {
            return {
                ...state,
                updateLoading: false,
                updateError: false
            }
        }
        case Actions.UPDATE_CUSTOMER_COUPON_FAILURE: {
            return {
                ...state,
                updateLoading: false,
                updateError: action.data
            }
        }


        case Actions.CUSTOMERS_LIST: {
            return {
                ...state,
                listLoading: true,
            }
        }
        case Actions.CUSTOMERS_LIST_SUCCESS: {
            return {
                ...state,
                listLoading: false,
                customerList: action?.data?.response
            }
        }
        case Actions.CUSTOMERS_LIST_FAILURE: {
            return {
                ...state,
                listLoading: false,
                customerList: [],
                listError: action.data
            }
        }
        case Actions.DELETE_CUSTOMERS_COUPON_LIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.DELETE_CUSTOMERS_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                deleteCustomersList: action?.data?.response,
                deleteError: false
            };
        }
        case Actions.DELETE_CUSTOMERS_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                deleteError: action.data,
            };
        }
        default:
            return state;
    }
};

export default CustomerCouponReducer;

export const getCustomerCouponListSelector = state => state?.CUSTOMER_COUPON?.customerCouponList;
export const getCustomerCouponListCountSelector = state => state?.CUSTOMER_COUPON?.totalCount
export const getCustomerCouponListLoading = state => state?.CUSTOMER_COUPON?.isLoading;
export const getCustomerListSelector = state => state?.CUSTOMER_COUPON?.customerList;
