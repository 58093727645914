import Constants from "../utils/Constants"
import FooterLogo from '../../asset/cv.png' //TODO Footer logo change
import NilgirisLogo  from '../../asset/images/nilgiris_logo_green.png'
import { useEffect, useState } from "react";
import { getCookie, isJSON } from "../utils/commonFunctions/cookie";
export const Footer = () => {
    const[isNilgirisUser,setIsNilgirisUser]=useState(false);
    useEffect(() => {
        const tokenDetails = isJSON(getCookie('token')) ? JSON.parse(getCookie('token')) : undefined
       
        setIsNilgirisUser(tokenDetails?.customer?.nilgirisUser);
    }, [])

    return (
        <footer id="footer" className={`bg-cream`}>
            <div className="wrap">
                <div className="text-center">
                    <img src={isNilgirisUser? NilgirisLogo :FooterLogo} alt="UST Logo" className={` ${isNilgirisUser ? 'proglint-footer-logo' : 'footer-logo'}   mb-4 `} />
                    <div
                        className="font-weight-normal">
                      {isNilgirisUser ? Constants.NILGIRIS_FOOTER_TEXT.text :  Constants.FOOTER_TEXT.text}
                    </div>
                </div>
            </div>
        </footer>
    )
}