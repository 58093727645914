import Actions from "../actions";

const initialState = {
    isLoading: false,
    multiBuyList: [],
    createMultiBuyList: [],
    updateMultiBuyList: [],
    multiBuyListDelete: [],
    error: false,
    createLoading: false,
    createError: false,
    updateLoading: false,
    updateError: false,
    // deleteLoading: false,
    deleteError: false,
    totalCount: undefined
}
const multiBuyReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.MULTI_BUY: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.MULTI_BUY_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                multiBuyList: action.data?.response || [],
                totalCount: action.data?.totalPages,
                error: false
            };
        }
        case Actions.MULTI_BUY_FAILURE: {
            return {
                ...state,
                isLoading: false,
                multiBuyList: [],
                error: action.data,
            };
        }
        case Actions.CREATE_MULTI_BUY: {
            return {
                ...state,
                createLoading: true,
            };
        }
        case Actions.CREATE_MULTI_BUY_SUCCESS: {
            return {
                ...state,
                createLoading: false,
                createMultiBuyList: action?.data?.response || [],
                createError: false
            };
        }
        case Actions.CREATE_MULTI_BUY_FAILURE: {
            return {
                ...state,
                createLoading: false,
                createError: action.data,
            };
        }
        case Actions.UPDATE_MULTI_BUY: {
            return {
                ...state,
                updateLoading: true,
            };
        }
        case Actions.UPDATE_MULTI_BUY_SUCCESS: {
            return {
                ...state,
                updateLoading: false,
                updateMultiBuyList: action?.data?.response || [],
                updateError: false
            };
        }
        case Actions.UPDATE_MULTI_BUY_FAILURE: {
            return {
                ...state,
                updateLoading: false,
                updateError: action.data,
            };

        }
        case Actions.DELETE_MULTI_BUY: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.DELETE_MULTI_BUY_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                multiBuyListDelete: action?.data?.response || [],
                deleteError: false
            };
        }
        case Actions.DELETE_MULTI_BUY_FAILURE: {
            return {
                ...state,
                isLoading: false,
                deleteError: action.data,
            };
        }
        default:
            return state;
    }
};

export default multiBuyReducer;

export const getMultiBuyListSelector = state => state?.MULTI_BUY?.multiBuyList;
export const getMultiBuyCountSelector = state => state?.MULTI_BUY?.totalCount;
export const getMultiBuyListLoadingSelector = state => state?.MULTI_BUY?.isLoading;