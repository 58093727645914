import React from 'react';

const AppButton = ({ title, disabled, color, ...props }) => {
    return <button
        type="button"
        className={`btn btn-dark big-btn w-100 px-5 text-uppercase`}
        {...props}
    >{title}</button>

}

export default AppButton;