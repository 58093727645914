import { doAction, doResponseAction } from ".";
import { GET_ADMIN_STORE_LIST } from "./BranchListActions";

const PROCUTS_SELECT_SEARCH = 'PROCUTS_SELECT_SEARCH';

const PRODUCTS = 'PRODUCTS';
const PRODUCTS_SUCCESS = 'PRODUCTS_SUCCESS';
const PRODUCTS_FAILURE = 'PRODUCTS_FAILURE';

//CREATE
const CREATE_PRODUCT = 'CREATE_PRODUCT';
const CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS';
const CREATE_PRODUCT_FAILURE = 'CREATE_PRODUCT_FAILURE'

//UPDATE
const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS';
const UPDATE_PRODUCT_FAILURE = 'UPDATE_PRODUCT_FAILURE'

//DELETE
const DELETE_PRODUCTS_LIST = 'DELETE_PRODUCTS_LIST';

export {
    PRODUCTS,
    PRODUCTS_SUCCESS,
    PRODUCTS_FAILURE,
    CREATE_PRODUCT,
    CREATE_PRODUCT_SUCCESS,
    CREATE_PRODUCT_FAILURE,
    UPDATE_PRODUCT,
    UPDATE_PRODUCT_SUCCESS,
    UPDATE_PRODUCT_FAILURE,
    PROCUTS_SELECT_SEARCH,
    DELETE_PRODUCTS_LIST
}

export const getProductsListForSelectDropdownAction = (params, callback) => doAction(PROCUTS_SELECT_SEARCH, params, callback);
export const getStoreListForSelectDropdownAction = (params, callback) => doAction(GET_ADMIN_STORE_LIST, params, callback);


export const getAllProductsListAction = (params, callback) => doAction(PRODUCTS, params, callback);
export const getAllProductsListSuccessAction = data => doResponseAction(PRODUCTS_SUCCESS, data);
export const getAllProductsListFailureAction = data => doResponseAction(PRODUCTS_FAILURE, data);

// CREATE
export const createProductAction = (params, callback) => doAction(CREATE_PRODUCT, params, callback);
export const createProductActionSuccess = (data) => doResponseAction(CREATE_PRODUCT_SUCCESS, data);
export const createProductActionFailure = (data) => doResponseAction(CREATE_PRODUCT_FAILURE, data);

//UPDATE
export const updatProductAction = (params, callback) => doAction(UPDATE_PRODUCT, params, callback);
export const updateProductActionSuccess = (data) => doResponseAction(UPDATE_PRODUCT_SUCCESS, data);
export const updateProductActionFailure = (data) => doResponseAction(UPDATE_PRODUCT_FAILURE, data);

//Delete
export const deleteCustomersCouponAction  = (params, callback) => doAction(DELETE_PRODUCTS_LIST, params, callback);

