import Actions from "../actions";

const initialState = {
    data: [],
    isLoading: false,
    totalPageCount: 1,
    memberCount:undefined
}

const BranchListReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.BRANCH_LIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.BRANCH_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                data: action?.data?.response,
                totalPageCount: action?.data?.totalPages,
                memberCount:action?.data?.count
            };
        }
        case Actions.BRANCH_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case Actions.CREATE_BRANCH_LIST: {
            return {
                ...state,
                createLoading: true,
                isLoading: true
            };
        }
        case Actions.CREATE_BRANCH_LIST_SUCCESS: {
            return {
                ...state,
                createLoading: false,
                isLoading: false
            };
        }
        case Actions.CREATE_BRANCH_LIST_FAILURE: {
            return {
                ...state,
                createLoading: false,
                isLoading: false
            };
        }
        case Actions.UPDATE_BRANCH_LIST: {
            return {
                ...state,
                updateLoading: true,
                isLoading: true
            };
        }
        case Actions.UPDATE_BRANCH_LIST_SUCCESS: {
            return {
                ...state,
                updateLoading: false,
                isLoading: false
            };
        }
        case Actions.UPDATE_BRANCH_LIST_FAILURE: {
            return {
                ...state,
                updateLoading: false,
                isLoading: false
            };

        }
        case Actions.UPDATE_ADMIN_ACTIVE_STATUS: {
            return {
                ...state,
                updateLoading: true,
                isLoading: true
            };
        }
        case Actions.UPDATE_ADMIN_ACTIVE_STATUS_SUCCESS: {
            return {
                ...state,
                updateLoading: false,
                isLoading: false
            };
        }
        case Actions.UPDATE_ADMIN_ACTIVE_STATUS_FAILURE: {
            return {
                ...state,
                updateLoading: false,
                isLoading: false
            };

        }
        case Actions.UPDATE_ADMIN_PASSWORD:{
            return{
                isLoading:true
            }
        }
        case Actions.UPDATE_ADMIN_PASSWORD_SUCCESS:{
            return{
                isLoading:false
            }
        }
        case Actions.UPDATE_ADMIN_PASSWORD_FAILURE:{
            return{
                isLoading:false
            }
        }
        default:
            return state;
    }
}


export default BranchListReducer;

export const BranchListData = state => state?.BRANCH_LIST_REDUCER?.data
export const totalMemberCount = state => state?.BRANCH_LIST_REDUCER?.totalPageCount
export const memberListLoader = state => state?.BRANCH_LIST_REDUCER?.isLoading
export const memberCount = state => state?.BRANCH_LIST_REDUCER?.memberCount
// export const memberId=state=>state?.BRANCH_LIST_REDUCER?.