import Actions from "../actions";

const initialState = {
    isLoading: false,
    reportTypeList: [],
    salesReport: [],
    downloadReport: [],
    getReport: [],
    error: false,
    downloadLoading: false,
    downLoadError: false,
    getReportLoading: false,
    getReportError: false
}
const ReportTypeListReducers = (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.REPORT_LIST_TYPE: {
            return {
                ...state,
                isLoading: true,
                salesReport: [],
            };
        }
        case Actions.REPORT_LIST_TYPE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                salesReport: action?.data?.response || [],
                error: false
            };
        }
        case Actions.REPORT_LIST_TYPE_FAILURE: {
            return {
                ...state,
                isLoading: false,
                salesReport: [],
                error: action.error,
            };
        }
        case Actions.GET_SALES_REPORT: {
            return {
                ...state,
                isLoading: true,
                reportTypeList: []
            };
        }
        case Actions.GET_SALES_REPORT_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                reportTypeList: action?.data?.response || [],
                error: false
            };
        }
        case Actions.GET_SALES_REPORT_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case Actions.DOWNLOAD_REPORT: {
            return {
                ...state,
                downloadLoading: true,
            };
        }
        case Actions.DOWNLOAD_REPORT_SUCCESS: {
            return {
                ...state,
                downloadLoading: false,
                downloadReport: action?.data?.response,
                downLoadError: false
            };
        }
        case Actions.DOWNLOAD_REPORT_FAILURE: {
            return {
                ...state,
                downloadLoading: false,
                downLoadError: action.error,
            };
        }
        case Actions.GET_REPORT: {
            return {
                ...state,
                getReportLoading: true,
            };
        }
        case Actions.GET_REPORT_SUCCESS: {
            return {
                ...state,
                getReportLoading: false,
                getReport: action?.data,
                getReportError: false
            };
        }
        case Actions.GET_REPORT_FAILURE: {
            return {
                ...state,
                getReportLoading: false,
                getReportError: action.error,
            };
        }
        default:
            return state;
    }
};

export default ReportTypeListReducers;

export const getReportTypeListSelector = state => state?.REPORT_TYPE_LIST?.salesReport;
export const getSalesReportSelector = state => state?.REPORT_TYPE_LIST?.reportTypeList;
export const getDownloadReportSelector = state => state?.REPORT_TYPE_LIST?.downloadReport;
export const getReportSelector = state => state?.REPORT_TYPE_LIST?.getReport;
export const getReportTypeListLoadingSelector = state => state?.REPORT_TYPE_LIST?.downloadLoading;
export const getViewReportListLoadingSelector = state => state?.REPORT_TYPE_LIST?.isLoading;
