import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Actions from "../../../redux/actions";
import {
  getFreeItemsLoadingSelector,
  getFreeItemsListSelector,
  getFreeItemsCountSelector
} from "../../../redux/reducers/FreeItemListReducer";

import FreeItemsModal from "./FreeItemsModal";
import { ManageFreeItemURL } from "../../../utils/service/Endpoints";
import { cloneDeep } from 'lodash';
import { UTCDateFormat, UTCEditFormat, UTCViewFormat } from "../../../utils/commonFunctions/dateFormat";
import Pagination from "react-js-pagination";
import RoutePath from "../../../utils/routes/RoutePath";
import AppLoader from "../../../components/fields/AppLoader";
import FreeItemsDetailModal from "./FreeItemsDetailModal";
import { toast } from "react-toastify";
const defaultFilter = {
  page: 0,
  itemsPerPage: 10
}

const FreeItems = () => {
  const dispatch = useDispatch();
  const FreeItemsList = useSelector(getFreeItemsListSelector);
  const isLoading = useSelector(getFreeItemsLoadingSelector);
  const [isOpenFreeItemsModal, setOpenFreeItemsModal] = useState();
  const [isOpenFreeItemsModalDetailModal, setOpenFreeItemsModalDetailModal] = useState();
  const [activePage, setActivePage] = useState(1)
  const totalCount = useSelector(getFreeItemsCountSelector)

  const fetchFreeList = useCallback(() => {
    dispatch(Actions.getFreeItemsListAction(defaultFilter));
  }, [dispatch])


  const handleChange = (pageNumber) => {
    setActivePage(pageNumber)
    freeItemsPageChange(pageNumber)
  }

  useEffect(() => {
    fetchFreeList()
  }, [fetchFreeList]);

  const freeItemsPageChange = (page) => {
    const defaultFilter = {
      page: page - 1,
      limit: 10
    }
    dispatch(Actions.getFreeItemsListAction(defaultFilter));
  }

  const deleteCallback = (res) => {
    const params = {
      page: activePage - 1,
      limit: 10
    }
    if (res && res.response_code === 0) {
      dispatch(Actions.getFreeItemsListAction(params));
      toast.success(res?.response_message, { position: toast.POSITION.TOP_RIGHT })

    } else {
      toast.error(res?.response_message, { position: toast.POSITION.TOP_RIGHT })
    }
  }
  const disableFreeItems = (object, value) => {
    let URL = ManageFreeItemURL(object?.id, value)
    dispatch(Actions.deleteFreeItemsList(URL, deleteCallback))
  }

  let freeItems = []

  if (FreeItemsList && FreeItemsList.length > 0) {
    freeItems = FreeItemsList
  } else {
    freeItems = []
  }

  const data = React.useMemo(() => freeItems, [freeItems]);
  const onEdit = (rowData) => {
    const data = { ...rowData };
    data.offer.expiryOn = UTCEditFormat(data?.endDateString);
    data.offer.validFrom = UTCEditFormat(data?.startDateString);
    data.sku = data.paidItemSku;
    data.freeItemSkusWithQuantities = data.freeItems.map(d => ({
      sku: d.sku,
      editQuantity: d.quantity,
      imageUrl: d.imageUrl,
      productName: d.productName
    }));
    setOpenFreeItemsModal(data);
  }

  const viewFreeItemDetails = (rowData) => {
    const data = cloneDeep({ ...rowData });
    data.viewMode = true
    data.offer.expiryOn = UTCViewFormat(data.offer.expiryOn);
    data.offer.validFrom = UTCViewFormat(data.offer.validFrom);
    data.sku = data.paidItemSku;
    data.freeItemSkusWithQuantities = data.freeItems.map(d => ({
      sku: d.sku,
      editQuantity: d.quantity,
      productName: d.productName
    }));
    setOpenFreeItemsModalDetailModal(data);
  }
  return (<div className="col ps-lg-4">
    {isLoading && <AppLoader />}
    <div className="row mb-1 align-items-center">
      <div className="col">
        <h1 className="font20 fw-bold mb-2">Free Items <small className="fw-normal"></small></h1>
        <nav style={{ marginLeft: "--bs-breadcrumb-divider: '/';" }} aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a href={RoutePath.DASHBOARD_SCREEN_PATH}>Home</a></li>
            <li className="breadcrumb-item"><a href={RoutePath.PROMOTION_DASHBOARD}>Promotions</a></li>
            <li className="breadcrumb-item active" aria-current="page">Free Item</li>
          </ol>
        </nav>
      </div>
      <div className="col text-end">
        <button
          id="addFreeItem"
          name=""
          data-bs-toggle="modal"
          data-bs-target="#add-dollar-modal"
          className="btn btn-dark"
          onClick={() => setOpenFreeItemsModal(true)}>Add New
        </button>
      </div>
    </div>


    <div className="row mb-4">
      <div className="col">
        <div id="all-offers" className="card card-body rounded-3 border-0 shadow p-4 mb-3 mb-xl-0">
          <div className="table-responsive mb-4">
            <table className="table table-hover table-striped table-borderless">
              <thead>
                <tr>
                  <th>OfferId</th>
                  <th>Product Name</th>
                  <th>Valid From</th>
                  <th>Valid To</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {!isLoading && data && data.map((o) => {
                  return <tr>
                    <td> {o?.offer?.id} </td>
                    <td className="cursor-pointer"> <p title={o?.productName} className="promotion-desc-wrap title-case">{o?.productName?.toLowerCase()}</p> </td>
                    <td>{UTCDateFormat(o?.startDateString)} </td>
                    <td>{UTCDateFormat(o?.endDateString)} </td>
                    {!o.status ? <td>
                      <div className="form-check form-switch">
                        <input
                          onChange={(e) => {
                            e.stopPropagation();
                            disableFreeItems(o, e.target.checked)
                          }}
                          className="form-check-input"
                          type="checkbox" role="switch"
                          id="switch" checked={o?.active} />
                      </div></td> : <td className={o.status === 'Expired' ? "text-danger" : 'text-success'}>{o?.status}</td>
                    }
                    <td className="d-flex">
                      <div className="col-auto">
                        <button type="button"
                          id="editFree"
                          data-bs-toggle="modal"
                          data-bs-target="#view-dollar-modal"
                          className="btn btn-sm btn-outline-dark mb-3 mb-md-0"
                          onClick={() => viewFreeItemDetails(o)}
                        >
                          View
                        </button>
                      </div>
                      <div className="col-auto">
                        <button type="button"
                          data-bs-toggle="modal"
                          id="editFreeItem"
                          data-bs-target="#edit-dollar-modal"
                          className="btn btn-sm btn-outline-dark"
                          onClick={() => onEdit(o)}
                        >
                          Edit
                        </button>
                      </div>
                    </td>
                  </tr>
                })}
              </tbody >
            </table >
          </div >
          <div className="text-center">
            {
              (data && data.length > 0 && totalCount > 1) && <Pagination
                id="pagination"
                activePage={activePage}
                itemsCountPerPage={10}
                totalItemsCount={totalCount * 10}
                pageRangeDisplayed={5}
                onChange={(e) => handleChange(e)}
              />
            }
          </div>

        </div >


      </div >
    </div >
    {
      isOpenFreeItemsModal &&
      <FreeItemsModal id="OpenFreeItemsModal" modalData={isOpenFreeItemsModal}
        onClose={(isrefresh) => {
          setOpenFreeItemsModal(null);
          fetchFreeList()
          setActivePage(1)
          if (isrefresh) {
            fetchFreeList()
          }
        }} />
    }
    {
      isOpenFreeItemsModalDetailModal && (
        <FreeItemsDetailModal
          id="ComboOfferModal"
          modalData={isOpenFreeItemsModalDetailModal}
          isCloseModal={() => {
            setOpenFreeItemsModalDetailModal(false);
          }}
        />
      )
    }
  </div >
  );
};

export default FreeItems;