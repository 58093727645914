import { all, put, takeLatest } from "redux-saga/effects";
import { doPromotionPost } from "../../utils/service";
import END_POINTS from "../../utils/service/Endpoints";
import Actions from "../actions";

export function* OfferList({ params, callback }) {
    try {
        const response = yield doPromotionPost(END_POINTS.OFFER_LIST, params);
        if (response) {
            yield put(Actions.getOfferListActionSuccess(response));
        }
        callback && callback(response);
    }
    catch (error) {
        callback && callback(error);
        yield put(Actions.getOfferActionFailure({ error }));
    }
}

export default function* OfferListListener() {
    yield all([
        takeLatest(Actions.OFFER_LIST, OfferList),
    ]);
}
