import React from 'react'
import { withRouter } from "react-router-dom";
import Actions from '../../redux/actions';
import { connect } from "react-redux";
import Home from '../../../asset/new/img/menu/home-active.svg'
import Orders from '../../../asset/new/img/menu/orders.svg'
import Promotions from '../../../asset/new/img/menu/promotions.svg'
import Reports from '../../../asset/new/img/menu/reports.svg'
import PlanogramIcon from '../../../asset/new/img/menu/planogram.svg'
import Refund from '../../../asset/new/img/menu/refund.svg'
import Blocked from '../../../asset/new/img/menu/blocked.svg'
import Chat from '../../../asset/new/img/menu/chat.svg'
import emailInvoice from '../../../asset/new/img/menu/emailInvoice.svg'
import Settings from '../../../asset/new/img/menu/settings.svg'
import { history } from '../../redux/store';
import RoutePath from '../../utils/routes/RoutePath';
import Constants from '../../utils/Constants'
import Bell from '../../../asset/new/img/bell.svg'
import search from '../../../asset/new/img/search.svg';
import Timer from '../../../asset/new/img/timer.svg'
import AppRoutes from '../../utils/routes/AppRoutes';
import { Footer } from '../Footer'
import { getUserRoleSelector } from '../../redux/reducers/LoginReducer';

class Navigation extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            alertModal: false
        }
    }
    handleActiveChange(params) {
        const token = this.isJSON(this.getCookie('token')) ? JSON.parse(this.getCookie('token')) : undefined
        if (token?.customer?.roleId !== this.props.getStoreRoleId) {
            window.location.reload()
        }
        switch (params) {
            case 'home':
                history.replace(RoutePath.DASHBOARD_SCREEN_PATH)
                break;
            case 'storeavailability':
                history.replace(RoutePath.STORE_AVAILABILITY)
                break;
            case 'orders':
                history.replace(RoutePath.ORDERS_PATH)
                break;
            case 'refund':
                history.replace(RoutePath.REFUND_SCREEN_PATH)
                break;
            case 'products':
                history.replace(RoutePath.MANAGE_PRODUCTS)
                break;
            case 'reports':
                history.replace(RoutePath.REPORT)
                break;
            case 'notification':
                history.replace(RoutePath.NOTIFICATION)
                break;
            case 'planogram':
                history.replace(RoutePath.PLANOGRAM)
                break;
            case 'daxbotstatus':
                history.replace(RoutePath.DAXBOT_STATUS)
                break;
            case 'customerunblocking':
                history.replace(RoutePath.CUSTOMER_UNBLOCKING)
                break;
            case 'promotionDashboard':
                history.replace(RoutePath.PROMOTION_DASHBOARD)
                break;
            case 'chat':
                history.replace(RoutePath.CHATS_SCREEN)
                break;
            case 'invoice':
                history.replace(RoutePath.INVOICE_LIST)
                break;
            case 'settings':
                history.replace(RoutePath.CHANGE_PASSWORD)
                break;
            case 'monitor':
                history.replace(RoutePath.HITACHI_MONITORING)
                break;
            case 'members':
                history.replace(RoutePath.MEMBERS)
                break;
            default:
                history.replace(RoutePath.DASHBOARD_SCREEN_PATH)
                break;
        }
    }

    getCookie(name) {
        let cookie = {};
        document.cookie.split(';').forEach(function (el) {
            let [k, v] = el.split('=');
            cookie[k.trim()] = v;
        })
        return cookie[name];
    }
    isJSON(str) {
        try {
            return (JSON.parse(str) && !!str);
        } catch (e) {
            return false;
        }
    }



    renderUserRoleBase() {
        const token = this.isJSON(this.getCookie('token')) ? JSON.parse(this.getCookie('token')) : undefined
        const userInfo = token?.customer
        if ((userInfo?.roleId === Constants.ROLES.ADMIN) ||
            (userInfo?.roleId === Constants.ROLES.STORE_MANAGER)
        ) {
            if (userInfo?.nilgirisUser) {
                return this.renderNilgrisUser()
            } else {
                return this.renderAdmin()
            }
        } else if (userInfo?.roleId == Constants.ROLES.BISTRO_ASSOCIATE) {
            return this.renderBistroAdmin()
        } else if (userInfo?.roleId == Constants.ROLES.STORE_ASSOCIATE) {
            return this.renderStoreAssociate()
        }
        else {
            return null
        }

    }
    renderStoreAssociate() {
        const { location } = history
        const { pathname } = location || {}
        return (
            <React.Fragment>
                <li> <span id='BistroAdminHome' className={`${pathname === RoutePath.DASHBOARD_SCREEN_PATH || pathname === RoutePath.LOGIN_SCREEN_PATH ? 'menu-active' : ''} cursor-pointer`} onClick={() => this.handleActiveChange('home')}>
                    <img src={Home} alt="home" className="me-3" /> Home</span></li>
                <li> <span id='BistroAdminOrders' className={`${pathname === RoutePath.ORDERS_PATH ? 'menu-active' : ''} cursor-pointer`} onClick={() => this.handleActiveChange('orders')}>
                    <img src={Orders} alt="orders" className="me-3" /> Orders</span></li>
                <li>
                    <span id='Receipt' className={`${pathname === RoutePath.INVOICE_LIST ? 'menu-active' : ''} cursor-pointer`}
                        onClick={() => this.handleActiveChange('invoice')}>
                        <img src={emailInvoice} alt="orders" className="me-3 sideNav-img" /> Receipt list
                    </span>
                </li>
                <li> <span id='Planogram' className={`${(pathname === RoutePath.PLANOGRAM || pathname === RoutePath.PLANOGRAM_VIEW) ? 'menu-active' : ''} cursor-pointer`} onClick={() => this.handleActiveChange('planogram')}>
                    <img src={PlanogramIcon} alt="planogram" className="me-3" /> Planogram</span></li>
                <li> <span id='BistroAdminChat' className={`${pathname === RoutePath.CHATS_SCREEN ? 'menu-active' : ''} cursor-pointer`} onClick={() => this.handleActiveChange('chat')}>
                    <img src={Chat} alt="chat" className="me-3" /> Chat</span></li>
                <li> <span id='Notifications' className={`${pathname === RoutePath.NOTIFICATION ? 'menu-active' : ''} cursor-pointer`} onClick={() => this.handleActiveChange('notification')}>
                    <img src={Bell} alt="legal" className="me-3" /> Notifications</span></li>
                <li> <span id='BistroAdminSettings' className={`${pathname === RoutePath.CHANGE_PASSWORD ? 'menu-active' : ''} cursor-pointer`} onClick={() => this.handleActiveChange('settings')}>
                    <img src={Settings} alt="settings" className="me-3" /> Settings</span></li>

            </React.Fragment>
        )
    }
    renderBistroAdmin() {
        const { location } = history
        const { pathname } = location || {}
        return (
            <React.Fragment>
                <li> <span id='BistroAdminHome' className={`${pathname === RoutePath.DASHBOARD_SCREEN_PATH || pathname === RoutePath.LOGIN_SCREEN_PATH ? 'menu-active' : ''} cursor-pointer`} onClick={() => this.handleActiveChange('home')}>
                    <img src={Home} alt="home" className="me-3" /> Home</span></li>
                <li> <span id='BistroAdminOrders' className={`${pathname === RoutePath.ORDERS_PATH ? 'menu-active' : ''} cursor-pointer`} onClick={() => this.handleActiveChange('orders')}>
                    <img src={Orders} alt="orders" className="me-3" /> Orders</span></li>
                <li>
                    <span id='Receipt' className={`${pathname === RoutePath.INVOICE_LIST ? 'menu-active' : ''} cursor-pointer`}
                        onClick={() => this.handleActiveChange('invoice')}>
                        <img src={emailInvoice} alt="orders" className="me-3 sideNav-img" /> Receipt list
                    </span>
                </li>
                <li> <span id='BistroAdminChat' className={`${pathname === RoutePath.CHATS_SCREEN ? 'menu-active' : ''} cursor-pointer`} onClick={() => this.handleActiveChange('chat')}>
                    <img src={Chat} alt="chat" className="me-3" /> Chat</span></li>
                <li> <span id='BistroAdminSettings' className={`${pathname === RoutePath.CHANGE_PASSWORD ? 'menu-active' : ''} cursor-pointer`} onClick={() => this.handleActiveChange('settings')}>
                    <img src={Settings} alt="settings" className="me-3" /> Settings</span></li>

            </React.Fragment>
        )
    }

    renderNilgrisUser() {
        console.log('hey its nilgiris user')
        const { location } = history
        const { pathname } = location || {}
        return (
            <React.Fragment>
                <li> <span id='BistroAdminHome' className={`${pathname === RoutePath.DASHBOARD_SCREEN_PATH || pathname === RoutePath.LOGIN_SCREEN_PATH ? 'menu-active' : ''} cursor-pointer`} onClick={() => this.handleActiveChange('home')}>
                    <img src={Home} alt="home" className="me-3" /> Home</span>
                </li>
                <li>
                    <span id='Product' className={`${pathname === RoutePath.MANAGE_PRODUCTS ? 'menu-active' : ''} cursor-pointer`}
                        onClick={() => this.handleActiveChange('products')}>
                        <img src={search} alt="products" className="me-3" /> Find product
                    </span>
                </li>
                <li> <span id='Planogram' className={`${(pathname === RoutePath.PLANOGRAM || pathname === RoutePath.PLANOGRAM_VIEW) ? 'menu-active' : ''} cursor-pointer`} onClick={() => this.handleActiveChange('planogram')}>
                    <img src={PlanogramIcon} alt="planogram" className="me-3" /> Planogram</span>
                </li>
                <li> <span id='Notifications' className={`${pathname === RoutePath.NOTIFICATION ? 'menu-active' : ''} cursor-pointer`} onClick={() => this.handleActiveChange('notification')}>
                    <img src={Bell} alt="legal" className="me-3" /> Notifications</span>
                </li>
                <li> <span id='BistroAdminSettings' className={`${pathname === RoutePath.CHANGE_PASSWORD ? 'menu-active' : ''} cursor-pointer`} onClick={() => this.handleActiveChange('settings')}>
                    <img src={Settings} alt="settings" className="me-3" /> Settings</span>
                </li>
            </React.Fragment>)
    }
    renderAdmin() {
        console.log('hey its user')
        const { location } = history
        const { pathname } = location || {}
        const token = this.isJSON(this.getCookie('token')) ? JSON.parse(this.getCookie('token')) : undefined
        const userInfo = token?.customer
        return (
            <React.Fragment>
                <li>
                    <span id='Home' className={`${pathname === RoutePath.DASHBOARD_SCREEN_PATH ? 'menu-active' : ''} cursor-pointer`}
                        onClick={() => this.handleActiveChange('home')}>
                        <img src={Home} alt="home" className="me-3" /> Home
                    </span>
                </li>
                <li>
                    <span id='Orders' className={`${pathname === RoutePath.ORDERS_PATH ? 'menu-active' : ''} cursor-pointer`}
                        onClick={() => this.handleActiveChange('orders')}>
                        <img src={Orders} alt="orders" className="me-3" /> Orders
                    </span>
                </li>
                <li>
                    <span id='Receipt' className={`${pathname === RoutePath.INVOICE_LIST ? 'menu-active' : ''} cursor-pointer`}
                        onClick={() => this.handleActiveChange('invoice')}>
                        <img src={emailInvoice} alt="orders" className="me-3 sideNav-img" /> Receipt list
                    </span>
                </li>
                <li>
                    <span id='Product' className={`${pathname === RoutePath.MANAGE_PRODUCTS ? 'menu-active' : ''} cursor-pointer`}
                        onClick={() => this.handleActiveChange('products')}>
                        <img src={search} alt="products" className="me-3" /> Find product
                    </span>
                </li>
                <li> <span id='Timing' className={`${pathname === RoutePath.STORE_AVAILABILITY ? 'menu-active' : ''} cursor-pointer`} onClick={() => this.handleActiveChange('storeavailability')}>
                    <img src={Timer} alt="storeavailability" className="me-3 sideNav-img" /> Store timing</span></li>
                <li> <span id='Promotions' className={`${pathname === RoutePath.PROMOTION_DASHBOARD || pathname === RoutePath.CUSTOMER_COUPON || pathname === RoutePath.FREE_ITEMS || pathname === RoutePath.HAPPY_HOURS_ITEM
                    || pathname === RoutePath.COMBO_OFFER || pathname === RoutePath.PRODUCT_DISCOUNT || pathname === RoutePath.MULTI_BUY ? 'menu-active' : ''} cursor-pointer`} onClick={() => this.handleActiveChange('promotionDashboard')}>
                    <img src={Promotions} alt="promotions" className="me-3" /> Promotions</span></li>
                <li> <span id='Reports' className={`${pathname === RoutePath.REPORT ? 'menu-active' : ''} cursor-pointer`} onClick={() => this.handleActiveChange('reports')}>
                    <img src={Reports} alt="reports" className="me-3" /> Reports</span></li>
                <li> <span id='Planogram' className={`${(pathname === RoutePath.PLANOGRAM || pathname === RoutePath.PLANOGRAM_VIEW) ? 'menu-active' : ''} cursor-pointer`} onClick={() => this.handleActiveChange('planogram')}>
                    <img src={PlanogramIcon} alt="planogram" className="me-3" /> Planogram</span></li>

                <li> <span id='Refund' className={`${pathname === RoutePath.REFUND_SCREEN_PATH ? 'menu-active' : ''} cursor-pointer`} onClick={() => this.handleActiveChange('refund')}>
                    <img src={Refund} alt="refunds" className="me-3" />  Refund requests</span></li>
                <li> <span id='Customers' className={`${pathname === RoutePath.CUSTOMER_UNBLOCKING ? 'menu-active' : ''} cursor-pointer`} onClick={() => this.handleActiveChange('customerunblocking')}>
                    <img src={Blocked} alt="blocked" className="me-3" /> Blocked customers</span></li>
                <li> <span id='Chat' className={`${pathname === RoutePath.CHATS_SCREEN ? 'menu-active' : ''} cursor-pointer`} onClick={() => this.handleActiveChange('chat')}>
                    <img src={Chat} alt="chat" className="me-3" /> Chat</span></li>
                <li> <span id='Notifications' className={`${pathname === RoutePath.NOTIFICATION ? 'menu-active' : ''} cursor-pointer`} onClick={() => this.handleActiveChange('notification')}>
                    <img src={Bell} alt="legal" className="me-3" /> Notifications</span></li>
                <li> <span id='Settings' className={`${pathname === RoutePath.CHANGE_PASSWORD ? 'menu-active' : ''} cursor-pointer`} onClick={() => this.handleActiveChange('settings')}>
                    <img src={Settings} alt="settings" className="me-3" /> Settings</span></li>
                {userInfo?.roleId === Constants.ROLES.ADMIN &&
                    <li> <span id='Branches' className={`${pathname === RoutePath.BRANCHES ? 'menu-active' : ''} cursor-pointer`}
                        onClick={() => this.handleActiveChange('members')}>
                        <img src={Blocked} alt="settings" className="me-3" /> Members
                    </span>
                    </li>}

                <hr className="me-md-3 mb-2" />
            </React.Fragment>
        )
    }

    render() {
        const { pathname } = this.props.location;
        const token = this.isJSON(this.getCookie('token')) ? JSON.parse(this.getCookie('token')) : undefined
        if (!token) {
            return null
        }
        return (
            <React.Fragment>
                <div className={`${(pathname == "/planogram/rack" || pathname == "/planogram") ? 'planogram-wrap my-5 body-content' : 'wrap my-5 body-content'}`}>
                    <div className="row">
                        <div id="account-nav" className="col-2 d-none d-lg-block">
                            <ul>
                                {this.renderUserRoleBase()}
                            </ul>
                        </div>
                        <AppRoutes />
                    </div>
                </div>
                <Footer />

            </React.Fragment>
        )
    }
}
export const mapStateToProps = (state) => {
    return {
        getUserDetails: Actions.getForgetEmail(state),
        getStoreRoleId: getUserRoleSelector(state)
    };
};

export default withRouter(connect(mapStateToProps, {
    logoutAction: Actions.logoutAction,
    loginSuccessAction: Actions.loginSuccessAction
})(Navigation))