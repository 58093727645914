import Actions from "../actions";

const initialState = {
    isLoading: false,
    bistroDisplayList: [],
    error: false,
    isCustomerDisplayLoading: false,
    isCustomerDisplayList: [],
    isCustomerDisplayError: false
}
const bistroDisplayLists = (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.BISTRO_DISPLAY_ORDER_LIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.BISTRO_DISPLAY_ORDER_LIST_SUCCESS: {
            let currentData = [...state.bistroDisplayList]
            let responseData = action.data?.response || []
            let tempData = []
            responseData.forEach((o) => {
                let index = currentData?.findIndex(obj => obj?.id === o?.id)
                if (index === -1) {
                    currentData.push(o)
                }
            })
            tempData = [...currentData]
            tempData.forEach((o, i) => {
                let index = responseData?.findIndex(obj => obj?.id === o?.id)
                if (index === -1) {
                    currentData.splice(i, 1)
                }
            })
            return {
                ...state,
                isLoading: false,
                bistroDisplayList: currentData || [],
                error: false
            };
        }
        case Actions.BISTRO_DISPLAY_ORDER_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                bistroDisplayList: [],
                error: action.error,
            };
        }
        case Actions.BISTRO_DISPLAY_CUSTOMER_ORDERS: {
            return {
                ...state,
                isCustomerDisplayLoading: true,
            };
        }
        case Actions.BISTRO_DISPLAY_CUSTOMER_ORDERS_SUCCESS: {
            return {
                ...state,
                isCustomerDisplayLoading: false,
                isCustomerDisplayList: action.data?.response || [],
                isCustomerDisplayError: false
            };
        }
        case Actions.BISTRO_DISPLAY_CUSTOMER_ORDERS_FAILURE: {
            return {
                ...state,
                isCustomerDisplayLoading: false,
                isCustomerDisplayList: [],
                isCustomerDisplayError: action.error,
            };
        }
        case Actions.BISTRO_DISPLAY_SOCKET_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                bistroDisplayList: action.data,
                error: action.error,
            };
        }

        case Actions.MODIFY_BISTRO_PRODUCT: {
            let currentData = [...state.bistroDisplayList]
            currentData[action.data.index].invoiceOrderProductDetailsList[action.data.selectedProduct].isBaked = action.data.checked
            return {
                ...state,
                bistroDisplayList: currentData
            };
        }
        case Actions.LOGOUT: {
            return {
                ...initialState
            };
        }

        default:
            return state;
    }
};

export default bistroDisplayLists;

export const bistroDisplayListSelector = state => state?.BISTRO_DISPLAY_REDUCER?.bistroDisplayList;
export const bistroChefDisplayLoader = state => state?.BISTRO_DISPLAY_REDUCER?.isLoading;
export const bistroCustomerDisplayListSelector = state => state?.BISTRO_DISPLAY_REDUCER?.isCustomerDisplayList;
export const bistroCustomerDisplayLoader = state => state?.BISTRO_DISPLAY_REDUCER?.isCustomerDisplayLoading;
