import Actions from "../actions";

const initialState = {
    isLoading: false,
    bistroProductList: [],
    error: false
}
const bistroProductList = (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.BISTRO_PRODUCT_LIST: {
            return {
                ...state,
                isLoading: true,
                bistroProductList: []
            };
        }
        case Actions.BISTRO_PRODUCT_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                bistroProductList: action.data || [],
                error: false
            };
        }
        case Actions.BISTRO_PRODUCT_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                bistroProductList: [],
                error: action.error,
            };
        }
        default:
            return state;
    }
};

export default bistroProductList;

export const bistroProductListSelector = state => state?.BISTRO_PRODUCT_LIST?.bistroProductList;
export const bistroProductListLoadingSelector = state => state?.BISTRO_PRODUCT_LIST?.isLoading;