import React from 'react';
import Close from '../../../asset/new/img/modal-close.svg'

export default (props) => {
    const { onCloseModal, children, isRefund, className = '' } = props;
    return (
        <>
            <div className="modal d-block" id={`${isRefund ? "refund-modal" : "feedback-modal"}`} tabindex="-1" aria-labelledby="feedback" aria-hidden="true">
                <div className={`modal-dialog modal-dialog-centered ${className}`}>
                    <div className="modal-content">
                        <div className="modal-body p-50">
                            <img
                                src={Close}
                                alt="X"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                className="modal-close"
                                onClick={onCloseModal}
                            />
                            {children}
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop show"></div>
        </>
    )
}

export const ModalHeader = (props) => {
    const { title, onCloseModal, isSkip } = props;
    return (
        <div className="row align-items-center mb-5">
            <div className="col">
                <h2 className="font28 fw-bold">{title}</h2>
            </div>
            {isSkip && <div className="col-3 text-end">
                <span className="font16 fw-light text-dark text-link cursor-pointer" onClick={onCloseModal}>Skip</span>
            </div>}
        </div>
    )
}

export const ModalFooter = (props) => {
    const { onSuccess, buttonName, buttonDisable } = props;
    return (
        <div className="text-center pt-4 mb-5">
            <button
                type="button"
                className="btn btn-dark big-btn w-100 px-5 text-uppercase"
                onClick={() => onSuccess()}
                disabled={buttonDisable}
            >
                {buttonName}
            </button>
        </div>
    )
}