import { doAction, doResponseAction } from ".";

const DOLLAR_OFFER_LIST = 'DOLLAR_OFFER_LIST';
const DOLLAR_OFFER_LIST_SUCCESS = 'DOLLAR_OFFER_LIST_SUCCESS';
const DOLLAR_OFFER_LIST_FAILURE = 'DOLLAR_OFFER_LIST_FAILURE';

//CREATE
const CREATE_DOLLAR_OFFER = 'CREATE_DOLLAR_OFFER';
const CREATE_DOLLAR_OFFER_SUCCESS = 'CREATE_DOLLAR_OFFER_SUCCESS';
const CREATE_DOLLAR_OFFER_FAILURE = 'CREATE_DOLLAR_OFFER_FAILURE'

//UPDATE
const UPDATE_DOLLAR_OFFER = 'UPDATE_DOLLAR_OFFER';
const UPDATE_DOLLAR_OFFER_SUCCESS = 'UPDATE_DOLLAR_OFFER_SUCCESS';
const UPDATE_DOLLAR_OFFER_FAILURE = 'UPDATE_DOLLAR_OFFER_FAILURE'

//UPDATE
const DELETE_DOLLAR_OFFER = 'DELETE_DOLLAR_OFFER';
const DELETE_DOLLAR_OFFER_SUCCESS = 'DELETE_DOLLAR_OFFER_SUCCESS';
const DELETE_DOLLAR_OFFER_FAILURE = 'DELETE_DOLLAR_OFFER_FAILURE'
export {
    DOLLAR_OFFER_LIST,
    DOLLAR_OFFER_LIST_SUCCESS,
    DOLLAR_OFFER_LIST_FAILURE,
    CREATE_DOLLAR_OFFER,
    CREATE_DOLLAR_OFFER_SUCCESS,
    CREATE_DOLLAR_OFFER_FAILURE,
    UPDATE_DOLLAR_OFFER,
    UPDATE_DOLLAR_OFFER_SUCCESS,
    UPDATE_DOLLAR_OFFER_FAILURE,
    DELETE_DOLLAR_OFFER,
    DELETE_DOLLAR_OFFER_SUCCESS,
    DELETE_DOLLAR_OFFER_FAILURE
}


export const getDollarOfferListAction = (params, callback) => doAction(DOLLAR_OFFER_LIST, params, callback);
export const getDollarOfferListSuccessAction = data => doResponseAction(DOLLAR_OFFER_LIST_SUCCESS, data);
export const getDollarListFailureAction = data => doResponseAction(DOLLAR_OFFER_LIST_FAILURE, data);

// CREATE
export const createDollarOfferAction  = (params, callback) => doAction(CREATE_DOLLAR_OFFER, params, callback);
export const createDollarOfferActionSuccess= (data) => doResponseAction(CREATE_DOLLAR_OFFER_SUCCESS, data);
export const createDollarOfferActionFailure = (data) => doResponseAction(CREATE_DOLLAR_OFFER_FAILURE, data);

//UPDATE
export const updateDollarOfferAction  = (params, callback) => doAction(UPDATE_DOLLAR_OFFER, params, callback);
export const updateDollarOfferActionSuccess= (data) => doResponseAction(UPDATE_DOLLAR_OFFER_SUCCESS, data);
export const updateDollarOfferActionFailure = (data) => doResponseAction(UPDATE_DOLLAR_OFFER_FAILURE, data);

//DELETE
export const deleteDollarOfferAction  = (params, callback) => doAction(DELETE_DOLLAR_OFFER, params, callback);
export const deleteDollarOfferActionSuccess= (data) => doResponseAction(DELETE_DOLLAR_OFFER_SUCCESS, data);
export const deleteDollarOfferActionFailure = (data) => doResponseAction(DELETE_DOLLAR_OFFER_FAILURE, data);