import React from 'react'
import { withRouter } from 'react-router-dom'
import Close from '../../../asset/new/img/modal-close.svg'
class RefundPreview extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    render() {
        const { refundImage, onFailure } = this.props
        return (
            <React.Fragment>
                <div className="modal d-block"
                    id="unblock-modal"
                    tabIndex="-1"
                    aria-labelledby="unblock"
                    aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-body p-50">
                                <img
                                    src={Close}
                                    id="close"
                                    alt="X"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    className="modal-close"
                                    onClick={() => onFailure()} />
                                <div
                                    className="row mb-4">
                                    <div className="col text-center">
                                        <h3 className="font18 fw-bold mb-3">Preview</h3>
                                        <div>
                                            <img style={{ width: '100%', height: '100%' }} src={refundImage} alt="img" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="modal-backdrop show"></div>

            </React.Fragment>
        )
    }
}

export default withRouter(RefundPreview)