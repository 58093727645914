import Actions from "../actions";

const initialState = {
    isLoading: false,
    freeItemList: [],
    error: false,
    createFreeItemList: [],
    deleteFreeItems: [],
    createLoading: false,
    updateLoading: false,
    createError: false,
    updateError: false,
    deleteLoading: false,
    deleteError: false,
    totalCount: undefined
}
const FreeItemReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.FREE_ITEMS: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.FREE_ITEMS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                freeItemList: action?.data?.response || [],
                totalCount: action.data?.totalPages,
                error: false
            };
        }
        case Actions.FREE_ITEMS_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.data,
            };
        }
        case Actions.FREE_ITEMS_CREATE: {
            return {
                ...state,
                createLoading: true,
            };
        }
        case Actions.FREE_ITEMS_CREATE_SUCCESS: {
            return {
                ...state,
                createLoading: false,
                createFreeItemList: action.data.response,
                error: false
            };
        }
        case Actions.FREE_ITEMS_CREATE_FAILURE: {
            return {
                ...state,
                createLoading: false,
                createFreeItemList: [],
                error: action.data,
            };
        }
        case Actions.FREE_ITEMS_UPDATE: {
            return {
                ...state,
                updateLoading: true,
            }
        }
        case Actions.FREE_ITEMS_UPDATE_SUCCESS: {
            return {
                ...state,
                updateLoading: false,
            }
        }
        case Actions.FREE_ITEMS_UPDATE_FAILURE: {
            return {
                ...state,
                updateLoading: false,
                updateError: action.data
            }
        }
        case Actions.DELETE_FREE_ITEMS: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.DELETE_FREE_ITEMS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                deleteFreeItems: action.data.response,
                deleteError: false
            };
        }
        case Actions.DELETE_FREE_ITEMS_FAILURE: {
            return {
                ...state,
                isLoading: false,
                deleteError: action.data,
            };
        }
        default:
            return state;
    }
};

export default FreeItemReducer;

export const getFreeItemsListSelector = state => state?.FREE_ITEMS?.freeItemList;
export const getFreeItemsCountSelector = state => state?.FREE_ITEMS?.totalCount;
export const getFreeItemsLoadingSelector = state => state?.FREE_ITEMS?.isLoading;