import React from "react";
import AppDatePicker from '../../../components/fields/AppDatePicker';
import Constants, { COLORS } from "../../../utils/Constants";
import BoldError from "../../../components/fields/BoldError";
import { faDollarSign, faPercent } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProductsTypeAhead from "../../../components/fields/ProductsTypeAhead";
import Close from '../../../../asset/new/img/modal-close.svg'
import AppLoader from "../../../components/fields/AppLoader";

const HappyHoursModal = ({ setOpenHappyHoursItemModal, setOfferType,
    setProducts, isCreate, startTime, endTime, setStartTime, setEndTime,
    manage, offerType, setStartDate, setEndDate, startDate, endDate,
    hoursOption, productName, offerValue, setOfferValue, validationError, productType, setProductType, isLoading }) => {
    const endHoursOption = hoursOption.filter((hours) => hours.value > startTime)
    return <React.Fragment>
        <div className="modal d-block"
            id="view-multi-modal"
            tabIndex="-1"
            aria-labelledby="view-multi-modal"
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                {isLoading && <AppLoader />}
                <div className="modal-content rounded-0">
                    <img id="close" src={Close} alt="X" data-bs-dismiss="modal" aria-label="Close" className="modal-close"
                        onClick={() => setOpenHappyHoursItemModal(false)}
                    />
                    <div className="modal-header rounded-0 align-items-center py-4">
                        <div className="col"> <h5 className="modal-title">{isCreate ? 'Add Happy Hours' : 'Edit Happy Hours'}</h5> </div>
                    </div>

                    <div className="modal-body font13 p-40">
                        {validationError && <BoldError message={validationError} />}
                        {isCreate && <div className="row mb-4">
                            <div className="col">
                                <label for="category" className="form-label fw-bold">Select Product Category</label>
                                <select
                                    name=""
                                    className="form-select"
                                    id="setProductType"
                                    value={productType}
                                    onChange={(e) => {
                                        setProductType(e?.target?.value)
                                    }
                                    }>
                                    <option value="">Select Product Type</option>
                                    {
                                        Constants.PRODUCT_TYPE.map((o) => {
                                            return (
                                                <option key={''} value={o.value}>{o.KEY}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>}
                        {
                            (
                                <>
                                    {isCreate ? <div div className="row mb-4">
                                        <div className="col">
                                            <label for="product" className="form-label fw-bold">Products</label>
                                            <ProductsTypeAhead
                                                productType={productType}
                                                key={productType}
                                                style={{ margin: '10px' }}
                                                placeholder="Select Products"
                                                onChange={setProducts}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                        :
                                        <div className="row mb-3">
                                            <div className="col-4 fw-bold"> Product Name </div>
                                            <div className="col-8 title-case"> {productName?.toLowerCase()} </div>
                                        </div>
                                    }



                                </>
                            )
                        }


                        <div className="row mb-4">
                            <div className="col-6">
                                <label for="offer-type" className="form-label fw-bold">Offer Type</label>
                                <select
                                    id="setOfferType"
                                    className="form-select"
                                    value={offerType}
                                    style={{
                                        border: `1px solid ${COLORS.appThemeHex}`,
                                        minWidth: '90px',
                                        flex: 1,
                                    }}
                                    onChange={(e) => setOfferType(e?.target?.value)}>
                                    <option >Select offer type</option>
                                    {
                                        Constants.PRODUCT_DISCOUNTS.map((o) => {
                                            return (
                                                <option value={o.value} key={''}>{o.key}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className="col-6">
                                <label for="" className="form-label fw-bold">Offer Value</label>
                                <div className="input-group">
                                    {offerType === 'PERCENT' ? <span className="input-group-text border-dark">%</span> : <span className="input-group-text border-dark">$</span>}
                                    <input type="number" className="form-control"
                                        id="Offer"
                                        defaultValue={offerValue}
                                        placeholder="Enter Offer Value "
                                        onChange={(e) => setOfferValue(e?.target?.value)}
                                        suffixIcon={<FontAwesomeIcon
                                            icon={offerType === 'PERCENT' ?
                                                faPercent : offerType === 'DOLLAR' ? faDollarSign : null} />}
                                        onInput={offerType === 'PERCENT' ? (e) => {
                                            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 3)
                                        } : ""}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-12 col-sm-6">
                                <label for="from-date" className="form-label fw-bold">Valid from</label>
                                <AppDatePicker
                                    notValidTime={true}
                                    minDate={new Date()}
                                    defaultValue={startDate}
                                    selected={startDate}
                                    placeholder="Valid From"
                                    id="startDate"
                                    onChange={(date) => setStartDate(date)}
                                />
                            </div>
                            <div className="col-12 col-sm-6">
                                <label for="from-date" className="form-label fw-bold">Valid To</label>
                                <AppDatePicker
                                    notValidTime={true}
                                    minDate={startDate ? startDate : new Date()}
                                    defaultValue={endDate}
                                    selected={endDate}
                                    id="endDate"
                                    placeholder="Valid To"
                                    onChange={(date) => setEndDate(date)}
                                />
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-12 col-sm-6">
                                <label for="hour-start" className="form-label fw-bold">Begining Time</label>
                                <select id="hour-start" name="" className="form-select"
                                    onChange={(e) => setStartTime(e)}
                                    value={startTime}>
                                    <option value=""> Select your start time </option>
                                    {hoursOption.map((o) => {
                                        return (
                                            <option key={''} value={o.value}>{o?.label}</option>)
                                    })}
                                </select>
                            </div>
                            <div className="col-6">
                                {
                                    startTime && (
                                        <>
                                            <label for="hour-stop" className="form-label fw-bold">End Time :</label>
                                            <select id="hour-stop" name="" className="form-select"
                                                onChange={(e) => setEndTime(e)}
                                                value={endTime}>
                                                <option value=""> Select Your end time </option>
                                                {endHoursOption.map((o) => {
                                                    return (
                                                        <option key={''} value={o.value}>{o.label}</option>)
                                                })}
                                            </select>
                                        </>)}
                            </div>

                        </div>
                        <div className="row mb-4">
                            <div className="col-7 pe-0">
                                <span id="manageClick" className="btn btn-success font14 w-100 p-3 cursor-pointer"
                                    onClick={() => manage(isCreate)} > {isCreate ? "Create" : "Update"}</span>
                            </div>
                            <div className="col-5 text-end">
                                <span id="manageModal" className="btn btn-outline-danger font14 w-100 p-3 cursor-pointer"
                                    onClick={() => setOpenHappyHoursItemModal(false)}> Cancel</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="modal-backdrop show"></div>
    </React.Fragment >
};

export default HappyHoursModal;

