import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Actions from '../../redux/actions';
import moment from 'moment';
import { getStoreAvailabilitySelector, getStoreAvailabilityLoading } from '../../redux/reducers/StoreAvailabilityReducers';
import 'react-datepicker/dist/react-datepicker.css'
import AppLoader from '../../components/fields/AppLoader';
import AppTimePicker from '../../components/fields/AppTimePicker';
import BoldError from '../../components/fields/BoldError';
const StoreAvailabilityScreen = () => {
    const storeAvailability = useSelector(getStoreAvailabilitySelector)
    const isLoading = useSelector(getStoreAvailabilityLoading)
    const [storeEdit, setStoreEdit] = useState(false)
    const [error, setError] = useState()
    const [storeAvailabilityState, setStoreAvailabilityState] = useState([])
    const dispatch = useDispatch()

    const fetchList = () => {
        dispatch(Actions.getStoreAvailability())
    }
    useEffect(() => {
        fetchList()
    }, [dispatch]);
    const data = React.useMemo(() => storeAvailability, [storeAvailability]);

    const onUpdate = () => {
        const callback = (res) => {
            if (res?.response_code === 0) {
                setError(false)
                setStoreEdit(false)
                dispatch(Actions.getStoreAvailability())
            } else {
                setError(res)
            }
        }
        const params = {
            // "storeId": 1,
            storeTimeUpdate: storeAvailabilityState
        }
        dispatch(Actions.updateStoreAvailable(params, callback))
    }



    const isStoreEdit = (o, index) => {
        setStoreEdit(true)
        setStoreAvailabilityState(JSON.parse(JSON.stringify(data)))
    }


    const startUpdateDate = (index, start_time) => {
        let storeData = [...storeAvailabilityState]
        storeData[index].startTime = moment(start_time, "hh:mm A").format("hh:mm A")
        setStoreAvailabilityState(storeData)
    }
    const endUpdateDate = (index, end_time) => {
        let storeData = [...storeAvailabilityState]
        storeData[index].endTime = moment(end_time, "hh:mm A").format("hh:mm A")
        setStoreAvailabilityState(storeData)
    }

    return <div className="col ps-lg-4">
        <div className="row mb-4">
            {isLoading && <AppLoader />}
            <h2 className="font15 fw-bold mb-3">Store hours</h2>

            <div className="col-12 col-lg-6 col-xl-7">
                <div key={''} id="store-info" className="card card-body rounded-3 border-0 shadow p-4 mb-3 mb-xl-0">
                    {storeEdit && storeAvailabilityState?.map((o, index) => {
                        let startTiming = moment(o?.startTime, "hh:mm A").toDate();
                        let endTiming = moment(o?.endTime, "hh:mm A").toDate();
                        return <div key={''} className="row mb-2">
                            <div className="col">{o?.dayOfWeek}</div>
                            <div className="col"><AppTimePicker id='startUpdateDate' defaultValue={moment(startTiming)} onChange={(date) => {
                                startUpdateDate(index, date)
                            }} /> </div>
                            <div className="col"><AppTimePicker id='endUpdateDate' defaultValue={moment(endTiming)} onChange={(date) => {
                                endUpdateDate(index, date)
                            }} />
                            </div>
                        </div>
                    })}
                    {
                        !storeEdit && data?.map((o, index) => {
                            return <div className="row mb-2">
                                <div className="col">{o?.dayOfWeek}</div>
                                <div className="col">{o?.startTime} - {o?.endTime} </div>
                            </div>
                        })
                    }
                    <div className="row mb-2">
                    </div>
                    <div className="row mb-2">
                        <div className="col"></div>
                        <div className="col"> </div>
                    </div>
                    {error && <BoldError message={error} />}

                    <br />
                    <div className="col-auto">
                        {storeEdit ? <><button id='onUpdate' className="btn btn-dark p-2 px-5 text-uppercase" name="" onClick={() => onUpdate()}>Update</button>&nbsp;
                            <button id='Cancel' className="btn btn-outline-dark p-2 px-5 text-uppercase" name="" onClick={() => {
                                setStoreEdit(false)
                                setError(false)
                            }}>Cancel</button> </> :
                            <button id='isStoreEdit' className="btn btn-dark p-2 px-5 text-uppercase" name="" onClick={isStoreEdit}>Edit</button>}
                    </div>
                </div>
            </div>
        </div>

    </div >
};


export default StoreAvailabilityScreen;