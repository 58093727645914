import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import Actions from '../../redux/actions';
import { getCookie, isJSON } from '../commonFunctions/cookie';
import Constants from '../Constants';
import RoutePath from './RoutePath';
import ROUTES from './Routes';

export const ProtectedRoute = (props) => {
    const routePath = useSelector(s => s?.router?.location.pathname);
    const token = isJSON(getCookie('token')) ? JSON.parse(getCookie('token')) : undefined
    const dispatch = useDispatch()
    useEffect(() => {
        const customerData = isJSON(getCookie('token')) ? JSON.parse(getCookie('token')) : undefined
        if (customerData) {
            dispatch(Actions.loginSuccessAction(customerData))
        }
    }, [dispatch])

    if (token) {
        const { roleId } = token?.customer || {}
        if (roleId === Constants.ROLES.BACK_KITCHEN || roleId === Constants.ROLES.FRONT_KITCHEN) {
            return routePath === RoutePath.BISTRO_ORDERS ? <Route path={RoutePath.BISTRO_ORDERS} component={ROUTES[2].component} exact={true} /> : <Redirect to={RoutePath.BISTRO_ORDERS} />
        } else if (roleId === Constants.ROLES.BISTRO_DISPLAY) {
            return routePath === RoutePath.BISTRO_CUSTOMER_ORDERS ? <Route path={RoutePath.BISTRO_CUSTOMER_ORDERS} component={ROUTES[3].component} exact={true} /> : <Redirect to={RoutePath.BISTRO_CUSTOMER_ORDERS} />
        } else if (routePath === RoutePath.LOGIN_SCREEN_PATH) {
            return <Redirect to={RoutePath.DASHBOARD_SCREEN_PATH} />
        } else {

            if (roleId === Constants.ROLES.BISTRO_ASSOCIATE) {
                return props.isExcludeBistro ? <Redirect to={RoutePath.DASHBOARD_SCREEN_PATH} /> : <Route {...props} />
            } else if ((roleId === Constants.ROLES.STORE_MANAGER || roleId === Constants.ROLES.STORE_ASSOCIATE)) {
                return props.isExcludedSM ? <Redirect to={RoutePath.DASHBOARD_SCREEN_PATH} /> : <Route {...props} />
            } else if ((roleId === Constants.ROLES.ADMIN)) {
                return (routePath === RoutePath.BISTRO_ORDERS || routePath === RoutePath.BISTRO_CUSTOMER_ORDERS) ? <Redirect to={RoutePath.DASHBOARD_SCREEN_PATH} /> : <Route {...props} />
            }
            else {
                <Route {...props} />
            }
        }
    } else {
        return null
    }

}


const AppRoutes = () => {
    return (
        <Switch>
            {ROUTES.map(route => <ProtectedRoute key={route.path} {...route} />)}
        </Switch>
    );
}

export default AppRoutes;