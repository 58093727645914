
import moment from 'moment';
import IO from 'socket.io-client';
import { getSocketRefreshToken } from '..';
import { getLastLoginTime, getTokenSelector } from '../../../redux/reducers/LoginReducer';
import { store } from '../../../redux/store';
import { BASE_URL_SOCKET_IO, SOCKET_IO_PATH } from '../Endpoints';

let socket;


const destroySocket = () => {
  if (socket) {
    socket.disconnect();
    socket = null;
  }
}

const dispatchToStore = (fn) => store.dispatch(fn)


/**
 * Start socket conection
 */
export const StartSocketConnect = (callback) => {
  if (socket) {
    destroySocket()
  }

  const socketToken = 'bearer ' + getTokenSelector(store?.getState())?.access_token;
  const loginUserInfo = getTokenSelector(store?.getState());
  const { customer } = loginUserInfo || {}
  const getLastLogin = getLastLoginTime(store?.getState())

  // connect to socket
  socket = IO(BASE_URL_SOCKET_IO, {
    path: SOCKET_IO_PATH,
    autoConnect: true,
    forceNew: true,
    verify: true,
    transports: ['websocket'],
    jsonp: false,
    query: {
      authorization: socketToken
    },
    extraHeaders: {
      Accept: 'application/json; charset=utf-8',
    },
  });
  socket.on('connect', () => {
    console.log('************ APP Connected ************');
  });
  socket.on('disconnect', () => {
    console.log('************ _onDisConnect ************');
  });
  socket.on('socket_connected', () => {
    console.log('************ socket_connected ************');
  });
  socket.on('socket_disconnected', () => {
    console.log('************ socket_disconnected ************');
  });

  console.log(getLastLogin,'getLastLogin')
  socket.on('connect_error', data => {
    if (getLastLogin && moment().isAfter(getLastLogin)) {
      console.log('************ error - Entered error condition ************', data);

      getSocketRefreshToken()
    }
    console.log('************ error ************', data);
  });
  socket.on('connect_timeout', data => {
    console.log('************ connect_timeout ************', data);
  });
  if (customer && customer?.roleId !== 12 && customer?.roleId !== 11) { // bistro display
    if (loginUserInfo?.customerId) {
      console.log(socket, '====> connection')
      socket.on(
        `notification/sm/${loginUserInfo?.customerId}`,
        data => {
          console.log('------>>>> Socket notification', data);
          callback(data)
        })
    } else {
      destroySocket()
    }
  }

}
/**
 * Stop socket conection
 */
export const token = () => {
  return {
  };
};
export const stopSocketConnect = () => {
  destroySocket()
};

