import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { history } from '../../redux/store';
import { getQueryParams } from '../../utils/commonFunctions/EncodeDecodeBase64';
import Action from '../../redux/actions';
import bayimage from '../../../asset/images/placeholder.jpg';
import CreateShelfModal from '../../components/CreateShelfModal';
import { getPlanogramDetailSelector, getPlanogramDetailLoading } from '../../redux/reducers/PlanogramDetailReducer'
import Constants from '../../utils/Constants';
import AssignProductModal from '../../components/AssignProductModal';
import UnAssignProductModal from '../../components/UnAssignProductModal';
import CreateRackModal from '../../components/CreateRackModal';
import { getPlanogramSelector } from '../../redux/reducers/PlanogramReducer';
import AppLoader from '../../components/fields/AppLoader';
import { toast } from "react-toastify";
import { Draggable, Droppable } from 'react-drag-and-drop'
import { getProductListLoadingSelector, getProductListSelector } from '../../redux/reducers/ProductsReducer';
import DragProductModal from '../../components/DragProductModal';
import search from '../../../asset/new/img/search.svg';
import loader from '../../../asset/images/loader.gif'
import { PlanogramProduct } from '../../components/PlanogramProduct';
import { PlanogramDraggableList } from '../../components/PlanogramDraggableList';
import { convertPlanogramFormat } from '../../utils/commonFunctions/Planogram';
import Planogram3DModal from '../../components/Planogram3DModal';
import { PLANOGRAM_IFRAME_URL } from '../../utils/service/Endpoints';
import { Layout, PlanogramPopup } from '../../components/PlanogramPopup';
import Actions from '../../redux/actions';
import { getBayProductListSelector } from '../../redux/reducers/BaySaveProductListReducer';
import cloneDeep from 'lodash.clonedeep';
class PlanogramDetail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isEdit: false,
            isShelfModal: false,
            planogram: [],
            shelveId: '',
            isShelfUpdateModal: false,
            shelve: undefined,
            isProductModal: false,
            bay: undefined,
            unAssignId: undefined,
            unAssignModal: false,
            isRackModal: false,
            editShelvesLoading: false,
            isAssignLoading: false,
            isUnAssignLoading: false,
            isAddRackLoading: false,
            productType: 2,
            isOpenDraggedProductModal: false,
            productsData: {},
            search: '',
            percentagePx: 0,
            is3DMaximized: false,
            showPopup: false,
            isConfigureLoading: false,
            rackId: "",
            isIframeLoaded: false,
            bayProductReplace: false,
            isBayProductReplaceLoading: false,
            removeProductDetail: undefined,
            removeProductModal: false,
            isPageLoading: false
        }
    }
    componentDidMount() {
        let params = {
            storeId: 1,
            size: 100,
            type: 2,
            searchKey: '',
        }
        this.props.getAllProductsListAction(params)
        this.getPlanogramDetail()
        this.resizeHeight();
        window.addEventListener('resize', this.resizeHeight)
    }

    resizeHeight = () => {
        setTimeout(() => {
            const shelveWidth = document.getElementById("shelvesContainer").offsetWidth
            this.setState({ percentagePx: ((shelveWidth - 90) / 100) })
        }, 200)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeHeight)
    }

    getPlanogramDetail = () => {
        const { location } = history;
        this.setState({ isPageLoading: true})
        if (getQueryParams(location.search).get('id')) {
            const callback = (response) => {
                this.setState({ isPageLoading: false})
                if (response.error) {
                    return false
                }
                const { isIframeLoaded } = this.state;
                if (isIframeLoaded) {
                    setTimeout(() => {
                        document.getElementById('iframe_rack').contentWindow.postMessage({ message: convertPlanogramFormat(response.response) }, "*")
                    }, 1000)
                }
            }
            this.props.getPlanogramDetail({ id: getQueryParams(location.search).get('id') }, callback);
        }
    }
    closeEditPopup = () => {
        this.setState({ isRackModal: false })
    }
    onIframeLoaded = () => {
        const { rack } = this.props;
        const { location } = history;
        if (rack) {
            // alert(rack.id === getQueryParams(location.search).get('id'))
            if (rack.id === parseInt(getQueryParams(location.search).get('id')))
                document.getElementById('iframe_rack').contentWindow.postMessage({ message: convertPlanogramFormat(rack) }, "*")
            this.setState({ isIframeLoaded: true })
        }
    }

    openConfigureModal = (shelveId) => {
        this.setState({ isShelfModal: true, shelveId })
    }

    openUpdateConfigureModal = (shelve) => {
        this.setState({ isShelfUpdateModal: true, shelve, shelveId: shelve.id })
    }

    onCloseShelfModal = () => {
        this.setState({ isShelfModal: false })
    }

    onCloseUpdateShelfModal = () => {
        this.setState({ isShelfUpdateModal: false, shelve: undefined })
    }

    openProductModal = (bay) => {
        if (bay?.productDetails) {
            this.props.getProductList({ size: 100, storeId: 1, searchKey: '' })
        }
        this.setState({ isProductModal: true, bay })
    }

    onCloseProductModal = () => {
        this.setState({ isProductModal: false, bay: undefined })
    }

    onCloseUnAssignModal = () => {
        this.setState({ unAssignModal: false, unAssignId: undefined })
    }
    onCloseBayReplaceProductModal = () => {
        this.setState({ bayProductReplace: false })
    }

    onSubmitForm = ({ bays }, isDrag) => {
        const callback = (response) => {
            if (response?.error) {
                toast.error('Sorry somthing went wrong,please try again later', { position: toast.POSITION.TOP_RIGHT })
            } else if (response?.response_code === 0) {
                this.getPlanogramDetail();
            } else {
                toast.error(response?.response_message, { position: toast.POSITION.TOP_RIGHT })
            }
            this.setState({
                isShelfModal: false, isShelfUpdateModal: false,
                isProductModal: false, editShelvesLoading: false,
            });

        }
        const { shelveId } = this.state;
        this.setState({ editShelvesLoading: true }, () => {
            this.props.savePlanogramBay({ bays, id: shelveId }, callback)
        })

    }

    onCloseDraggedModal = () => {
        this.setState({ isOpenDraggedProductModal: false })
    }

    onSubmitProductForm = ({ fields, bay }) => {
        const callback = (response) => {
            if (response?.error) {
                toast.error('Sorry somthing went wrong,please try again later', { position: toast.POSITION.TOP_RIGHT })
            } else if (response?.response_code === 0) {
                this.getPlanogramDetail();
            } else {
                toast.error(response?.response_message, { position: toast.POSITION.TOP_RIGHT })
            }
            this.setState({
                isShelfModal: false, isShelfUpdateModal: false,
                isProductModal: false, isAssignLoading: false,
                isOpenDraggedProductModal: false
            });
        }
        const payload = [{
            id: bay.id,
            sku: fields.product.value,
            quantity: fields.quantity,
            frontCapacity: fields.frontCapacity,
            currentItemCount: fields.currentItemCount,
            minStock: fields.minStock,
        }]
        this.setState({ isAssignLoading: true }, () => {
            this.props.assignProductToBay(payload, callback)
        })

    }

    getBayWidthPercentage = (bay, width) => {
        const percent = (bay.bayWidth / width) * 100
        return { width: `${percent}%` }
    }

    unAssignProduct = () => {
        const callback = (response) => {
            if (response?.error) {
                toast.error('Sorry somthing went wrong,please try again later', { position: toast.POSITION.TOP_RIGHT })
            } else if (response?.response_code === 0) {
                this.getPlanogramDetail();
            } else {
                toast.error(response?.response_message, { position: toast.POSITION.TOP_RIGHT })
            }
            this.setState({
                isShelfModal: false, isShelfUpdateModal: false,
                isProductModal: false, unAssignModal: false, isUnAssignLoading: false
            });
        }
        const { unAssignId } = this.state;
        this.setState({ isUnAssignLoading: true }, () => {
            this.props.unAssignProductToBay({ id: unAssignId }, callback)
        })
    }
    onClickUnAssignProduct = (id) => {
        this.setState({ unAssignId: id, unAssignModal: true })
    }

    getProductAvailabilityTag = (bay) => {
        const { outOfStock, partiallyOutOfStock } = bay;
        if (outOfStock) {
            return (<div className="btn btn-bay-card-left btn-red text-center pe-none"><span>Out Of Stock</span></div>)
        }
        if (partiallyOutOfStock) {
            return (<div className="btn btn-bay-card-left btn-partial-stock text-center pe-none"><span>Partially Out Of Stock</span></div>)
        }

        return null
    }

    onEditRack = () => {
        this.setState({ isRackModal: true, })
    }

    onAddRack = (rack) => {
        const callback = (response) => {
            if (response?.error) {
                toast.error('Sorry somthing went wrong,please try again later', { position: toast.POSITION.TOP_RIGHT })
            } else if (response?.response_code === 0) {
                this.getPlanogramDetail();
            } else {
                toast.error(response?.response_message, { position: toast.POSITION.TOP_RIGHT })
            }
            this.setState({ isRackModal: false, isAddRackLoading: false })
        }
        this.setState({ isAddRackLoading: true }, () => {
            this.props.updatePlanogramRack(rack, callback)
        })
    }
    handleTypeChange = (value) => {
        this.setState({ productType: value })
        let params = {
            storeId: 1,
            size: 100,
            type: value,
            searchKey: '',
        }
        if (parseInt(value) === 1) {
            this.props.bistroProductListAction(params)
        } else {
            this.props.getAllProductsListAction(params)
        }
    }
    onDropProducts(stringfyProductsData, bay, rack, shelve) {
        const parsedProductData = JSON.parse(stringfyProductsData?.products)
        this.setState({ isOpenDraggedProductModal: true, productsData: parsedProductData, bay, rack, shelve })
    }

    onDropProducttoShelf(stringfyProductsData, shelve) {
        const parsedProductData = JSON.parse(stringfyProductsData?.products)
        const { rack = {} } = this.props;
        const { width = '' } = rack;
        const { products = [] } = shelve
        const fillingPercent = this.getProgressValue(width, [...products, { productDetails: parsedProductData }])
        if (fillingPercent > 100) {
            toast.error('Shelve is full', { position: toast.POSITION.TOP_RIGHT })
            return false
        }
        if (parsedProductData.heightInCm > shelve.shelveHeight) {
            toast.error('Product height is above shelve height', { position: toast.POSITION.TOP_RIGHT })
            return false
        }
        if (parsedProductData.lengthInCm > rack.depth) {
            toast.error('Product depth is above shelve depth', { position: toast.POSITION.TOP_RIGHT })
            return false
        }
        const shelveProductsProducts = shelve.products?.map((o) => ({ id: o.id, position: o.position, showView: o.showView, sku: o.productDetails.upc })) || [];
        shelveProductsProducts.push({
            sku: parsedProductData.sku,
            position: shelveProductsProducts[shelveProductsProducts.length - 1] ? shelveProductsProducts[shelveProductsProducts.length - 1].position + 1 : 1,
            showView: 1
        })
        const callback = (response) => {
            if (response.error) {
                return false
            }
            this.getPlanogramDetail();
            return;
        }
        const params = {
            shelveId: shelve.id,
            products: shelveProductsProducts
        }
        this.props.assignProductToShelve(params, callback);
    }

    onCloseRemoveProductModal = () => {
        this.setState({ removeProductModal: false, removeProductDetail: undefined })
    }
    onRemoveProduct = (shelve, shelveIndex,  i) => {
        this.setState({ removeProductDetail: {shelve, shelveIndex, i}, removeProductModal: true })
    }

    removeProduct = () => {
        const { shelves = [] } = this.props.rack || {};
        const { removeProductDetail } = this.state;
        if(!removeProductDetail) return false;
        shelves[removeProductDetail.shelveIndex].products.splice(removeProductDetail.i, 1);
        const shelveProductsProducts = shelves[removeProductDetail.shelveIndex].products?.map((o, i) => ({ id: o.id, position: i + 1, showView: o.showView, sku: o.productDetails.upc })) || [];
        const callback = (response) => {
            this.setState({ removeProductModal: false, removeProductDetail: undefined, isPageLoading: false })
            if (response.error) {
                return false
            }
            this.getPlanogramDetail();
            return;
        }
        const params = {
            shelveId: shelves[removeProductDetail.shelveIndex].id,
            products: shelveProductsProducts
        }
        this.setState({ isPageLoading: true})
        this.props.assignProductToShelve(params, callback);
    }
    handleSearchChange = (e) => {
        let params = {
            storeId: 1,
            size: 100,
            type: this.state.productType,
            searchKey: e ? e : ''
        }
        this.setState({ search: e })
        if (this.state.productType == 1) {
            this.props.bistroProductListAction(params)

        } if (this.state.productType == 2) {
            this.props.getAllProductsListAction(params)
        }
    }

    getContainerHeight = (height, width, isOverall) => {
        const heightPercent = height / (width / 100);
        const { percentagePx } = this.state;
        const { rack = {} } = this.props;
        const { shelves = [] } = rack;
        let heightInPx = (percentagePx * heightPercent);
        if (isOverall) {
            // Adjustment: + (shelves.length * 20) + 20 adding this because of design matching for products
            heightInPx = (percentagePx * heightPercent) + (shelves.length * 20) + 20 + 20;
        }
        return `${heightInPx}px`;
    }

    getContainerHeightForFruit = (height, width, isOverall) => {
        const heightPercent = height / (width / 100);
        const { percentagePx } = this.state;
        const { rack = {} } = this.props;
        let heightInPx = (percentagePx * heightPercent);
        return `${heightInPx * 0.7}px`;
    }

    getImageHeightWidth = (product, width) => {
        const widthPercent = product.widthInCm / (width / 100);
        const { percentagePx } = this.state;
        let widthInPx = (percentagePx * widthPercent);
        const heightPercent = product.heightInCm / (width / 100);
        let heightInPx = (percentagePx * heightPercent);
        // Adjustment: width and height value decreased 1 due to design border
        return { width: `${widthInPx - 1}px`, height: `${heightInPx - 1}px` };
    }

    getImageHeightWidthForFruit = (shelveHeight, width) => {
        return { objectFit: 'contain', maxWidth: '80%', width: 'auto', height: this.getContainerHeightForFruit(shelveHeight, width) };
    }
    getProgressValue = (width, products) => {
        const productPercentage = products.reduce((total, product) => {
            return total + product.productDetails.widthInCm;
        }, 0)
        const percentage = productPercentage / (width / 100)
        return parseFloat(parseInt(percentage * 100) / 100);
    }
    getProgressValueBay = (width, products) => {
        const productPercentage = products.reduce((total, product) => {
            return total + parseInt(product?.bayWidth);
        }, 0)
        const percentage = productPercentage / (width / 100)
        return parseFloat(parseInt(percentage * 100) / 100);
    }
    updateOrderOfTheItem = (items, shelve) => {
        const shelveProductsProducts = items.map((o, i) => ({ id: o.id, position: i + 1, showView: o.showView, sku: o.productDetails.upc })) || [];
        const callback = (response) => {
            if (response.error) {
                return false
            }
            this.getPlanogramDetail();
            return;
        }
        const params = {
            shelveId: shelve.id,
            products: shelveProductsProducts
        }
        this.props.assignProductToShelve(params, callback);
    }
    onConfigure = () => {
        const callback = (response) => {
            this.setState({ isConfigureLoading: false })
            if (response?.response_code === 0) {
                if (response?.response?.cameraList?.length > 0) {
                    this.setState({ showPopup: true })
                }
            }
            else {
                toast.error(response?.response_message)
            }
        }
        const productDetailsCallback = (productResponse) => {
            if (productResponse?.response_code === 0) {
                console.log(productResponse, "The product data is defined")
            }
        }
        const { location } = history;
        this.setState({ rackId: getQueryParams(location.search).get('id') })
        this.props.getCameraList({ rackId: getQueryParams(location.search).get('id') }, callback)
        this.props.getProductDetails('', productDetailsCallback)
        this.setState({ isConfigureLoading: true })
    }
    onDropProducttoBay(stringfyProductsData, bays) {
        const parsedProductData = JSON.parse(stringfyProductsData?.products)
        if (bays?.productDetails?.id && bays?.productDetails?.upc !== parsedProductData?.sku) {
            this.setState({ bayProductReplace: true })
            this.props.saveBayProductList({ parsedProductData, bays })
            return
        } else {
            const callback = (response) => {
                if (response?.error) {
                    toast.error('Sorry somthing went wrong,please try again later', { position: toast.POSITION.TOP_RIGHT })
                } else if (response?.response_code === 0) {
                    this.getPlanogramDetail();
                } else {
                    toast.error(response?.response_message, { position: toast.POSITION.TOP_RIGHT })
                }
                this.setState({
                    isShelfModal: false, isShelfUpdateModal: false,
                    isProductModal: false, isAssignLoading: false,
                    isOpenDraggedProductModal: false, isBayProductReplaceLoading: false
                });
            }
            const payload = [{
                id: bays?.id,
                sku: parsedProductData?.sku,
            }]
            this.setState({ isBayProductReplaceLoading: true }, () => {
                this.props.assignProductToBay(payload, callback)
            })
        }
    }
    rePlaceBayProduct = () => {
        const { bayProductList = {} } = this.props
        const { bays, parsedProductData } = bayProductList
        const callback = (response) => {
            if (response?.error) {
                toast.error('Sorry somthing went wrong,please try again later', { position: toast.POSITION.TOP_RIGHT })
            } else if (response?.response_code === 0) {
                this.getPlanogramDetail();
            } else {
                toast.error(response?.response_message, { position: toast.POSITION.TOP_RIGHT })
            }
            this.setState({
                isShelfModal: false, isShelfUpdateModal: false,
                isProductModal: false, isAssignLoading: false,
                isOpenDraggedProductModal: false, bayProductReplace: false, isBayProductReplaceLoading: false
            });
        }
        const payload = [{
            id: bays?.id,
            sku: parsedProductData?.sku,
        }]
        this.setState({ isBayProductReplaceLoading: true }, () => {
            this.props.assignProductToBay(payload, callback)
        })

    }
    render() {
        const { isShelfModal, isShelfUpdateModal, shelve, isProductModal, bay, isAddRackLoading,
            unAssignModal, removeProductModal, isRackModal, editShelvesLoading, isAssignLoading, isUnAssignLoading,
            isOpenDraggedProductModal, productsData, is3DMaximized, showPopup, isConfigureLoading, rackId, bayProductReplace, isBayProductReplaceLoading, isPageLoading } = this.state;
        const { rack = {}, planogram, planogramDetailLoading, productsList, productLoading, cameraListLoading, bayProductList } = this.props;
        const { width = '', height = '', shelves = [], rackName = '', misplacedItems = [] } = rack;
        const rackData = cloneDeep(rack)
        return (
            <>
                <div className='col-lg-6 planogram'>
                    <div className='planogram-header'>
                        <div className='btn btn-dark' onClick={() => this.props.history.goBack()}>
                            <i className="fa-regular fas fa-arrow-left mx-1"></i>Back
                        </div>
                        <div id='gobackHistory'>
                            <h5>Rack Name: {rackName}</h5>
                        </div>
                        <div id='editShelve' className='btn btn-outline-dark' onClick={() => this.onConfigure()}>
                            <i className="fas fa-cogs"></i><span className='ml-2'>Configure</span>
                        </div>
                        <div id='editShelve' className='btn btn-outline-dark' onClick={() => this.onEditRack()}>
                            <i className="fas fa-cogs"></i><span className='ml-2'>Edit Shelves</span>
                        </div>
                    </div>

                    <div className="row row-text">
                        <p className="w-text fw-bold">{`${width}${Constants.PLANOGRAM.UNIT}`}</p>
                    </div>
                    {
                        misplacedItems?.length > 0 && <div class="alert alert-danger ms-4" role="alert">
                            Misplaced Product:
                            <ol className='misplacedBullet'>
                                {misplacedItems.map((questionlist, index) =>
                                    <li key={index}>{questionlist}</li>
                                )}
                            </ol>
                        </div>
                    }
                    <div className="d-flex shelves-container">
                        <div className="text-vert" style={{ height: this.getContainerHeight(height, width, true) }}>
                            <p className="h-ver fw-bold">{`${height}${Constants.PLANOGRAM.UNIT}`}</p>
                        </div>
                        <div id="shelvesContainer" className="col-border" style={{ height: this.getContainerHeight(height, width, true) }}>
                            {rack?.rackType !== 3 &&
                                shelves.map((shelve, i) => {
                                    const { products = [] } = shelve;
                                    const progressPercent = this.getProgressValue(width, products)
                                    return (<div key={i} className="planogram-outer-box" style={{ height: this.getContainerHeight(shelve.shelveHeight, width) }}>
                                        <div className='shelve-name'><p className='shelve-name-text'>{shelve.shelveName}</p></div>
                                        <div className={`planogram-card-container`} key={i}>
                                            <div className={`planogram-card-content ${products.length === 0 ? 'drop-product' : 'drop-product-left'}`}>
                                                <Droppable
                                                    className={`${products.length === 0 ? 'assign-align' : 'drop-product-left'}`}
                                                    types={['products']} // <= allowed drop types
                                                    onDrop={(dragProducts) => this.onDropProducttoShelf(dragProducts, shelve)}
                                                >
                                                    {products.length === 0 ? (<p className="assign-text">Drop your product</p>)
                                                        : <PlanogramDraggableList list={products} ProductComponent={(productDetails, i, provided) => {
                                                            // if (products[i]?.misplaced) {
                                                            //     misplacedProduct.push(products[i])
                                                            // }
                                                            return (<PlanogramProduct products={products[i]} key={i} productDetails={productDetails} styles={this.getImageHeightWidth(productDetails, width)} />)
                                                        }}
                                                            updateOrderOfTheItem={(items) => this.updateOrderOfTheItem(items, shelve)}
                                                            onRemoveProduct={(index) => this.onRemoveProduct(shelve, i, index)} />}
                                                    {/* <PlanogramDraggableList list={products} ProductComponent={(productDetails, i) => {
                                                        return (<PlanogramProduct key={i} productDetails={productDetails} styles={this.getImageHeightWidth(productDetails, width)} onRemoveProduct={() => this.onRemoveProduct(shelve, i)} />)
                                                    }} /> */}
                                                </Droppable>
                                            </div>

                                            <div className='arrow-container'>
                                                <i className="fa-regular fas fa-angle-up"></i>
                                                <p className='arrow-height'></p>
                                                <i className="fa-regular fas fa-angle-down"></i>

                                            </div>
                                            <span className='arrow-text'><p className='arrow-rotate'>{`${shelve.shelveHeight}${Constants.PLANOGRAM.UNIT}`}</p></span>
                                        </div>
                                        {products.length > 0 && <div><span className='planogram-progress-percent'>{progressPercent}%</span><progress id="file" value={progressPercent} max="100" className='w-100'> 60% </progress></div>}
                                    </div>)
                                })
                            }
                            {rack?.rackType === 3 &&
                                shelves.map((shelve, i) => {
                                    const { bays = [] } = shelve;
                                    const { products = [] } = shelve;
                                    const progressPercent = this.getProgressValueBay(width, bays)
                                    return (<div key={i} className="planogram-outer-box" style={{ height: this.getContainerHeight(shelve.shelveHeight, width) }}>
                                        <div className='shelve-name'><p className='shelve-name-text'>{shelve.shelveName}</p></div>
                                        {bays?.length === 0 ? (<div>
                                            <div className='configure-section my-20 d-flex' style={{ height: this.getContainerHeight(shelve.shelveHeight, width) }}>
                                                <div className="config-content">
                                                    <div className="config-add" onClick={() => this.openConfigureModal(shelve.id)}>
                                                        <i className="fas fa-cogs fa-lg"></i>
                                                        <p className="mb-0 config-text">Configure Bay</p>
                                                    </div>
                                                </div>
                                                <div className='arrow-container'>
                                                    <i className="fa-regular fas fa-angle-up"></i>
                                                    <p className='arrow-height'></p>
                                                    <i className="fa-regular fas fa-angle-down"></i>

                                                </div>
                                                <span className='arrow-text'><p className='arrow-rotate'>{`${shelve.shelveHeight}${Constants.PLANOGRAM.UNIT}`}</p></span>
                                            </div>
                                        </div>) :
                                            (<>
                                                <div className={`${bays.length > 0 ? 'planogram-card-container' : ''}`} key={i} style={{ height: this.getContainerHeight(shelve.shelveHeight, width) }}>
                                                    <div className='planogram-card-content' style={{ height: this.getContainerHeight(shelve.shelveHeight, width) }} >
                                                        {bays.map((bay, index) => {
                                                            const coponent = this.getProductAvailabilityTag(bay);
                                                            return (
                                                                <div className="card bay-card" style={this.getBayWidthPercentage(bay, width)} key={index}>
                                                                    {bay?.productDetails && (
                                                                        <>
                                                                            <div className="planogram-unassign-btn" onClick={() => this.onClickUnAssignProduct(bay?.id)}><i className="fas fa-trash cursor-pointer"></i></div>
                                                                            <div className="card-header ">
                                                                                <p className={`${coponent ? 'text-end' : 'text-center'}`}>{coponent}</p>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                    <Droppable
                                                                        className={`${bays.length ? 'assign-align' : 'drop-product-left'}`}
                                                                        types={['products']} // <= allowed drop types
                                                                        onDrop={(dragProducts) => this.onDropProducttoBay(dragProducts, bay)}
                                                                    >
                                                                        {!bay?.productDetails?.id ? (<p className="assign-text">Drop your product</p>)
                                                                            : <PlanogramProduct
                                                                                bay={bay?.productDetails ? true : false}
                                                                                styles={this.getImageHeightWidthForFruit(shelve?.shelveHeight, width)}
                                                                                products={products[i]} key={i} productDetails={bay?.productDetails}
                                                                            />
                                                                        }
                                                                    </Droppable>
                                                                </div>)
                                                        })}
                                                    </div>
                                                    <div className='arrow-container'>
                                                        <i class="fa-regular fas fa-angle-up"></i>
                                                        <p className='arrow-height'></p>
                                                        <i class="fa-regular fas fa-angle-down"></i>

                                                    </div>
                                                    <span className='arrow-text'><p className='arrow-rotate'>{`${shelve.shelveHeight}${Constants.PLANOGRAM.UNIT}`}</p></span>
                                                </div>
                                            </>)
                                        }
                                        {bays.length > 0 && (<>
                                            <div><span className='planogram-progress-percent'>{progressPercent}%</span><progress id="file" value={progressPercent} max="100" className='w-100'> 60% </progress></div>
                                            <div className="d-flex justify-content-end align-items-baseline edit-bay m-1" onClick={() => this.openUpdateConfigureModal(shelve)}>
                                                <i className="fas fa-edit fa-sm "></i>
                                                <h6 className="m-3 mx-2 edit-font">Edit Bay</h6>
                                            </div>
                                        </>)}
                                    </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    {(isConfigureLoading) && <AppLoader />}
                    {isShelfModal && <CreateShelfModal isModalOpen={isShelfModal} onCloseModal={this.onCloseShelfModal} onSubmitForm={this.onSubmitForm} width={width} isLoading={editShelvesLoading} />}
                    {isShelfUpdateModal && <CreateShelfModal isModalOpen={isShelfUpdateModal} onCloseModal={this.onCloseUpdateShelfModal} onSubmitForm={this.onSubmitForm} width={width} isEdit={true} shelve={shelve} isLoading={editShelvesLoading} />}
                    {isProductModal && <AssignProductModal isLoading={isAssignLoading} isModalOpen={isProductModal} onCloseModal={this.onCloseProductModal} onSubmitForm={this.onSubmitProductForm} bay={bay} />}
                    {isOpenDraggedProductModal && <DragProductModal
                        isLoading={isAssignLoading}
                        isModalOpen={isOpenDraggedProductModal}
                        onCloseModal={this.onCloseDraggedModal}
                        onSubmitForm={this.onSubmitProductForm}
                        productsData={productsData}
                        shelve={shelve}
                        rack={rack}
                        bay={bay} />}
                    {unAssignModal && <UnAssignProductModal header={Constants.PLANOGRAM.HEADER_UNASSIGN} title={Constants.PLANOGRAM.TITLE_UNASSIGN} isLoading={isUnAssignLoading} isModalOpen={unAssignModal} onCloseModal={this.onCloseUnAssignModal} onSubmitForm={this.unAssignProduct} />}
                    {removeProductModal && <UnAssignProductModal header={Constants.PLANOGRAM.HEADER_REMOVE_PRODUCT} title={Constants.PLANOGRAM.DESCRIPTION_REMOVE_PRODUCT} isLoading={isUnAssignLoading} isModalOpen={removeProductModal} onCloseModal={this.onCloseRemoveProductModal} onSubmitForm={this.removeProduct} />}
                    {bayProductReplace && <UnAssignProductModal header={Constants.PLANOGRAM.HEADER_PRODUCT_REPLACE} title={Constants.PLANOGRAM.TITLE_PRODUCT_REPLACE} isLoading={isBayProductReplaceLoading} isModalOpen={bayProductReplace} onCloseModal={this.onCloseBayReplaceProductModal} onSubmitForm={this.rePlaceBayProduct} />}
                    {isRackModal && <CreateRackModal
                        isLoading={isAddRackLoading}
                        isModalOpen={isRackModal}
                        onCloseModal={() => this.setState({ isRackModal: false })}
                        result={planogram}
                        onAddRack={this.onAddRack}
                        isEdit={true}
                        rack={rackData} title="Update Rack" />}
                </div>
                <div className='col-lg-4 planogram-search'>
                    {/* <select className="form-select form-select-lg mb-2" //for no category is there
                        onChange={(e) => this.handleTypeChange(e?.target?.value)}
                        value={this.state.productType}
                    >
                        <option value={2}>Grocery</option>
                    </select> */}
                    <div className="col-12">
                        <div className="input-group mb-3 mb-lg-0">
                            <input
                                id='searchChange'
                                type="text"
                                value={this.state.search}
                                onChange={(e) => this.handleSearchChange(e?.target?.value)}
                                className="form-control border-end-0"
                                placeholder="Search by Product Name" />
                            <button className="btn btn-outline-dark border-start-0"
                                type="button" id="search-btn"><img src={search} alt="Go" /></button>
                        </div>
                    </div>

                    {
                        productsList && productsList?.length > 0 && <div>
                            <div className='planogram-search-product-list' id={'planogram-search-height'}>
                                {
                                    productsList?.map((productObj, index) => {
                                        const stringfyProducts = JSON.stringify(productObj)
                                        return (
                                            <Draggable type="products" data={stringfyProducts} key={index}>
                                                <div className='planogram-search-card-content'>
                                                    <div className='card planogram-search-card' title={`${productObj?.productName}(SKU: ${productObj?.sku})`}>
                                                        <img className="planogram-search-img"
                                                            src={productObj?.imageUrl ? productObj?.imageUrl : bayimage}
                                                            alt="Card image" />
                                                        <p>{productObj?.productName}</p>
                                                        <p>{productObj?.sku}</p>
                                                    </div>
                                                </div>
                                            </Draggable>
                                        )

                                    })
                                }
                            </div>
                        </div>
                    }
                    {
                        (!productLoading && productsList && productsList.length === 0) && <div className='text-center mt-3'>No products found</div>
                    }
                    {
                        (productsList && productsList.length === 0 && productLoading) && <div className="modal-body text-center">
                            Loading <img src={loader} width="30px" alt="loading..." />
                        </div>}
                    <div id='planogram-3d-view' className='rack-canvas'>
                        <div className="btn-maximize" onClick={() => this.setState({ is3DMaximized: true })}><span className="fas fa-arrows-alt" ></span></div>
                        <iframe id="iframe_rack" src={PLANOGRAM_IFRAME_URL} style={{ width: '100%', height: '100%', boxShadow: 'rgb(0 0 0 / 16%) 0px 1px 4px' }} onLoad={() => this.onIframeLoaded()}></iframe>
                    </div>
                    {
                        (is3DMaximized && <Planogram3DModal isModalOpen={is3DMaximized} onCloseModal={() => this.setState({ is3DMaximized: false })} Rack={rack} srcUrl={PLANOGRAM_IFRAME_URL} />)
                    }



                </div>
                {
                    showPopup && < PlanogramPopup close={() => this.setState({ showPopup: false })} rackId={rackId} />
                }
                {
                    isPageLoading && <AppLoader />
                }
            </>
        )
    }

}

export const mapStateToProps = (state) => {
    return {
        rack: getPlanogramDetailSelector(state),
        planogram: getPlanogramSelector(state),
        planogramDetailLoading: getPlanogramDetailLoading(state),
        productsList: getProductListSelector(state),
        productLoading: getProductListLoadingSelector(state),
        bayProductList: getBayProductListSelector(state)
    };
};

export default withRouter(
    connect(mapStateToProps, {
        getPlanogramDetail: Action.getPlanogramDetail,
        savePlanogramBay: Action.savePlanogramBayAction,
        assignProductToBay: Action.assignProductToBay,
        assignProductToShelve: Action.assignProductToShelve,
        unAssignProductToBay: Action.unAssignProductToBay,
        updatePlanogramRack: Action.updatePlanogramRackAction,
        getProductList: Action.getPlanogramProductList,
        getAllProductsListAction: Action.getAllProductsListAction,
        bistroProductListAction: Action.bistroProductListAction,
        getCameraList: Actions.Cv_CameraListAction,
        getProductDetails: Actions.Cv_Product_Details,
        saveBayProductList: Action.getbayListSuccess,

    })(PlanogramDetail)
);