import moment from 'moment';
import Actions from '../actions';

const initialState = {
    isLoading: false,
    token: null,
    userInfo: null,
    error: false,
    currentScreen: 'Login',
    forgetEmail: '',
    loggedInTokenExpires: null
}

const LoginReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case Actions.LOGIN: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.LOGIN_SUCCESS: {
            const tokenInfo = action.data;
            const customer = tokenInfo?.customer;
            return {
                ...state,
                isLoading: false,
                token: tokenInfo,
                userInfo: customer,
                loggedInTokenExpires: moment().add(tokenInfo?.expires_in - 10, 'seconds'),
                error: false
            };
        }
        case Actions.LOGIN_FAILURE: {
            return {
                ...state,
                isLoading: false,
                token: null,
                userInfo: null,
                error: action.error,
            };
        }
        case Actions.LOGOUT: {
            return {
                ...state,
                isLoading: true,
            }
        }
        case Actions.LOGOUT_SUCCESS: {
            localStorage.clear()
            return {
                ...initialState,
            };
        }
        case Actions.LOGOUT_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case Actions.UPDATE_CURRENT_SCREEN_PATH: {
            return {
                ...state,
                isLoading: false,
                currentScreen: action.data
            };
        }
        case Actions.UPDATE_FORGET_EMAIL: {
            return {
                ...state,
                isLoading: false,
                forgetEmail: action.data
            };
        }
        default:
            return state;
    }
};


export default LoginReducer;

export const getTokenSelector = state => state?.LOGIN_CREDS?.token;
export const getAccessTokenSelector = state => state?.LOGIN_CREDS?.token?.access_token;
export const getLoginLoadingSelector = state => state?.LOGIN_CREDS?.isLoading;

export const getUserInfoSelector = state => state?.LOGIN_CREDS?.userInfo;
export const getUserRoleSelector = state => state?.LOGIN_CREDS?.userInfo?.roleId;

export const currentScreenPath = state => state?.LOGIN_CREDS.currentScreen

export const getLastLoginTime = state => state?.LOGIN_CREDS.loggedInTokenExpires
