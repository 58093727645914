import { all, put, takeLatest } from "@redux-saga/core/effects";
import { doAuthGet } from "../../utils/service";
import END_POINTS from "../../utils/service/Endpoints";
import Actions from "../actions";


export function* getInvoiceListSaga({ params, callback }) {
    try {
        let response = yield doAuthGet(END_POINTS.GET_ALL_INVOICE_API, params)
        if (response?.response_code === 0) {
            yield put(Actions.getInvoiceListSuccess(response));
        } else {
            put(Actions.getInvoiceListFailure())
        }
        if (callback) {
            callback(response);
        }
    } catch (error) {
        yield put(Actions.getInvoiceListFailure({ error }))
    }
}

export default function* invoiceListListner() {
    yield all([
        takeLatest(Actions.INVOICE_LIST, getInvoiceListSaga),
    ]);
}
